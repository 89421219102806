<template lang="html">
  <div class="row">
    <div v-if="!loader" class="col-md-12">
      <div class="row">
        <div class="col-md-12 text-left">
          <h5>Configuración del monitoreo</h5>
        </div>
        <div class="col-md-4 text-left">
          <base-date-picker
            v-model="model.date"
            label="Fecha del monitoreo"
            name="date"
            :required="true"
          >
          </base-date-picker>
        </div>
        <div class="col-md-4 text-left">
          <base-select
            v-model="model.workflow.assignee.$oid"
            name="assignee"
            label="Responsable"
            :required="true"
            placeholder="Seleccione usuario que realizará el monitoreo"
            :options="users"
            item-value="_id.$oid"
            item-text="full_name"
          >
          </base-select>
        </div>
        <div class="col-md-4 text-left">
          <base-select
            v-model="model.tracking_type"
            name="tracking_type"
            label="Tipo de monitoreo"
            placeholder="Tipo de monitoreo"
            :required="true"
            :options="trackingOptions"
          >
          </base-select>
        </div>
      </div>

      <div v-if="model.tracking_type == 'random'" class="row">
        <div class="col-md-4 text-left">
          <base-input
            :value="settings.phenologicalTracking.numOfObservations.value"
            label="Observaciones por cuartel"
            disabled
          />
        </div>
        <div class="col-md-8 text-left">
          <sectors-picker
            v-model="model.sector_ids"
            name="sectors"
            placeholder="Cuarteles a monitorear"
            required
            multiple
            no-parents
          ></sectors-picker>
        </div>
      </div>

      <div
        v-if="
          $HELPERS.isPast(model.date) & (model.date != undefined) ||
          model.workflow.state == 'reporting'
        "
        class="row"
      >
        <div class="col-md-12 text-left">
          <h5>Registro de observaciones</h5>
        </div>
        <div class="col-md-12">
          <el-tabs v-if="model.sector_ids.length > 0" v-model="current_sector">
            <el-tab-pane
              v-for="sector in model.sector_ids"
              :key="sector"
              :name="sector"
              :label="sector"
            >
              <span slot="label">
                <span
                  :class="validationIcon(sector).class"
                  v-html="validationIcon(sector).icon"
                />
                {{ $HELPERS.getSectorNames(sector, sectors) }}
              </span>
              <div class="row">
                <div class="col-md-12">
                  <div
                    v-for="(ob, index) in model.observations.filter(
                      (i) => i.sector_id.$oid == sector
                    )"
                    :key="sector + '_' + index"
                    class="row observation"
                  >
                    <div class="col-md-3 text-left">
                      <fg-input
                        v-validate="{
                          required: true,
                          min_value: 1,
                          decimal: false,
                        }"
                        v-model.number="ob.line_number"
                        :error="
                          errors.first(
                            'sector_' + sector + '_line_number_' + index
                          )
                        "
                        :name="'sector_' + sector + '_line_number_' + index"
                        :disabled="isPredefinedTracking"
                        required
                        type="number"
                        label="Número de hilera"
                        placeholder="Número de hilera"
                        data-vv-as="Número de hilera"
                      />
                    </div>
                    <div class="col-md-3 text-left">
                      <fg-input
                        v-validate="{
                          required: true,
                          min_value: 1,
                          decimal: false,
                        }"
                        v-model.number="ob.plant_number"
                        :error="
                          errors.first(
                            'sector_' + sector + '_plant_number_' + index
                          )
                        "
                        :name="'sector_' + sector + '_plant_number_' + index"
                        :disabled="isPredefinedTracking"
                        required
                        type="number"
                        label="Número de la planta"
                        placeholder="Número de la planta"
                        data-vv-as="Número de la planta"
                      />
                    </div>
                    <div class="col-md-3 text-left">
                      <fg-input
                        :error="
                          errors.first(
                            'sector_' + sector + '_phenological_state_' + index
                          )
                        "
                        label="Estado Fenológico"
                        required
                      >
                        <el-select
                          v-validate="{ required: true }"
                          v-model="ob.phenological_stage.$oid"
                          :name="
                            'sector_' + sector + '_phenological_state_' + index
                          "
                          data-vv-as="Estado Fenológico"
                          class="select-primary"
                          placeholder="Estado Fenológico"
                          @change="
                            initExtraMeasures(
                              sector,
                              index,
                              ob.phenological_stage
                            )
                          "
                        >
                          <el-option
                            v-for="(state, key) in getPhenologicalStates(
                              sector
                            )"
                            :value="state._id.$oid"
                            :key="key"
                            :label="state.value"
                            class="select-danger"
                          />
                        </el-select>
                      </fg-input>
                    </div>
                    <div class="col-md-3">
                      <picture-uploader
                        :key="'sector_' + sector + '_observation_' + index"
                        :sector_id="sector"
                        :index="index"
                        :photo="ob.photo"
                        @pictureProcessed="attachPicture"
                      />
                    </div>
                    <div
                      v-for="(extra, extra_index) in ob.extra_measures"
                      :key="
                        'sector_' +
                        sector +
                        '_observation_' +
                        index +
                        '_extra_measure_' +
                        extra_index
                      "
                      class="col-3 text-left"
                    >
                      <fg-input
                        v-validate="{ required: true, min_value: 1 }"
                        v-model.number="ob.extra_measures[extra_index].value"
                        :error="
                          errors.first(
                            'sector_' +
                              sector +
                              '_observation_' +
                              index +
                              '_extra_measure_' +
                              extra_index
                          )
                        "
                        :name="
                          'sector_' +
                          sector +
                          '_observation_' +
                          index +
                          '_extra_measure_' +
                          extra_index
                        "
                        :label="extra.variable_name"
                        :placeholder="extra.variable_name"
                        :data-vv-as="extra.variable_name"
                        required
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
          <el-alert
            v-else
            :closable="false"
            title="Selecione al menos un cuartel y el tipo de monitoreo"
            type="warning"
            show-icon
          />
        </div>
      </div>
    </div>

    <div class="col-md-12 mt-3">
      <slot name="actions" class="actions" />
    </div>

    <div class="col-md-12">
      <notification :messages="messages" :close="true" type="error" />
    </div>
  </div>
</template>

<script>
import { Tabs, TabPane, Alert } from "element-ui";
import PictureUploader from "./PictureUploaderPhenologicalTracking";
import BaseDatePicker from "../../../Components/BaseDatePicker.vue";
import SectorsPicker from "../../../Components/SectorsPicker.vue";
import { mapState } from "vuex";

export default {
  components: {
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Alert.name]: Alert,
    PictureUploader,
    BaseDatePicker,
    SectorsPicker,
  },
  props: {
    initModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    runValidations: {
      type: Number,
      default: 1,
    },
    messages: {
      type: Object,
      default: () => {
        return {};
      },
    },
    validateModel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loader: true,
      users: [],
      settings: {},
      current_sector: "",
      validation_performed: false,
      trackingOptions: [
        { value: "random", text: "Aleatorio" },
        { value: "predefined", text: "Predefinido" },
      ],
      model: {
        date: undefined,
        tracking_type: "",
        sector_ids: [],
        observations: [],
        workflow: {
          state: "new",
          assignee: {
            $oid: "",
          },
        },
      },
    };
  },
  computed: {
    ...mapState("Farms", ["sectors"]),
    isPredefinedTracking() {
      return this.model.tracking_type == "predefined" ? true : false;
    },
  },
  watch: {
    runValidations: function (newValue, oldValue) {
      this.validate();
    },
    model: {
      handler: function (newValue, oldValue) {
        this.$emit("modelUpdated", newValue);
      },
      deep: true,
    },
    "model.sector_ids": function (newValue, oldValue) {
      let vm = this;
      if (this.model.tracking_type == "random" && !this.loader) {
        this.model.observations = [];
        let times = this.settings.phenologicalTracking.numOfObservations.value;

        newValue.forEach((sector) => {
          let i = 0;
          while (i < times) {
            let schema = {
              sector_id: { $oid: sector },
              plant_number: "",
              line_number: "",
              phenological_stage: { $oid: "" },
              photo: "",
              extra_measures: [],
            };
            vm.model.observations.push(schema);
            i += 1;
          }
        });
        this.current_sector = newValue[0];
      }
    },
  },
  created() {
    if (Object.keys(this.initModel).length > 0) {
      this.model = this.$COPY.copy(this.initModel);
      this.current_sector = this.model.sector_ids[0];
    }
    this.getSettings();
  },
  methods: {
    getSettings() {
      this.settings = this.$COPY.copy(
        this.$store.getters["Settings/GET_SETTINGS"]
      );
      this.getUsers();
    },
    getUsers() {
      let vm = this;
      this.Services.Users.getUsers()
        .then((response) => {
          vm.users = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    getSectorName(sector) {
      return this.$HELPERS.getSectorNames(sector, this.sectors);
    },
    validationIcon(sector) {
      let validation = {
        icon: "<i class='fal fa-pencil'></i>",
        class: "",
      };

      if (this.validation_performed) {
        validation.icon = "<i class='fal fa-check-circle success'></i>";
        validation.class = "success";

        if (this.errors.items.length > 0) {
          let sectors_with_errors = this.errors.items.map(
            (i) => i.field.split("_")[1]
          );
          if (sectors_with_errors.includes(sector)) {
            validation.icon =
              "<i class='fal fa-exclamation-circle danger'></i>";
            validation.class = "danger";
          }
        }
      }
      return validation;
    },
    setInitialObservations(initializer) {
      if (this.model.tracking_type == "predefined") {
        this.model.observations = [];
        let predefined =
          this.settings.phenologicalTracking.predefinedPhenologicalTracking.map(
            (i) => i.value
          );
        this.model.observations = predefined.reduce((out, i) => {
          out.push({
            sector_id: { $oid: i.sector },
            line_number: i.lineNumber,
            plant_number: i.plantNumber,
            phenological_stage: { $oid: i.phenologicalStage },
            photo: "",
            extra_measures: [],
          });
          return out;
        }, []);

        this.model.sector_ids = this.model.observations.reduce((uniq, i) => {
          if (uniq == undefined) {
            uniq = [];
          }
          if (uniq.indexOf(i.sector_id.$oid) == -1) {
            uniq.push(i.sector_id.$oid);
          }
          return uniq;
        }, []);
        this.current_sector = this.model.sector_ids[0];
      }

      if (this.model.tracking_type == "random") {
        this.model.sector_ids = [];
      }
    },
    getPhenologicalStates(sector_id) {
      let specie_id = this.sectors.find((i) => i._id.$oid == sector_id).specie;
      return this.settings.phenologicalStages[specie_id];
    },
    attachPicture(sector_id, index, picture) {
      this.model.observations.filter((i) => i.sector_id.$oid == sector_id)[
        index
      ].photo = picture;
    },
    initExtraMeasures(sector_id, index, phenological_stage) {
      const { conditionalFields = [] } = this.settings.phenologicalTracking;
      let extra = conditionalFields.filter(
        (i) => i.value.phenologicalStage == phenological_stage.$oid
      );
      let ob = this.model.observations.filter(
        (i) => i.sector_id.$oid == sector_id
      )[index];
      ob.extra_measures = [];
      if (extra.length > 0) {
        extra.forEach((i) => {
          ob.extra_measures.push({
            extra_measure_id: i._id,
            variable_name: i.value.variableName,
            value: "",
          });
        });
      }
    },
    validate() {
      let vm = this;
      let model = this.$COPY.copy(vm.model);
      vm.validation_performed = true;

      if (
        vm.$HELPERS.isFuture(model.date) ||
        (vm.$HELPERS.isToday(model.date) && model.workflow.state == "new")
      ) {
        delete model.observations;
      }

      if (this.validateModel) {
        this.$validator.validateAll().then((isValid) => {
          vm.$emit("isValid", isValid, model);
        });
      } else {
        vm.$emit("isValid", true, model);
      }
    },
  },
};
</script>
