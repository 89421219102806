import { ceres_api } from "@/axios-config.js"

const PackageFormats = {
    getRawMaterials(farm_id) {
        let endpoint = "/farms/" + farm_id + "/package_formats/raw_materials"
        return ceres_api.get(endpoint)
    },
    createPackageFormat(farm_id, model) {
        let endpoint = "/farms/" + farm_id + "/package_formats"
        return ceres_api.post(endpoint, model)
    },
    getPackageFormats(farm_id, filter) {
        let endpoint = "/farms/" + farm_id + "/package_formats"
        if (filter == undefined) {
            return ceres_api.get(endpoint)
        } else {
            return ceres_api.post(endpoint + "/filter", { filter: filter })
        }
    },
    getPackageFormat(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/package_formats/" + id
        return ceres_api.get(endpoint)
    },
    updatePackageFormat(farm_id, model) {
        let endpoint =
            "/farms/" + farm_id + "/package_formats/" + model._id.$oid
        return ceres_api.put(endpoint, model)
    },
    deletePackageFormat(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/package_formats/" + id
        return ceres_api.delete(endpoint)
    }
}

export default PackageFormats
