<template lang="html">
  <div class="row report-body">
    <div
      v-loading="loaders.report"
      class="col-md-12">
      <div
        v-loading="loaders.form"
        class="row">
        <div
          v-if="!loaders.form"
          class="col-md-12">
          <yr-form
            :config="config"
            @data="setData"/>
        </div>
      </div>

      <div class="row">
        <div
          class="col-md-12">
          <yr-report
            v-if="data.length > 0"
            :data="data"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import YrForm from "./YrForm.vue"
import YrReport from "./YrReport.vue"

export default {
    components: {
        YrForm,
        YrReport
    },
    data() {
        return {
            loaders: {
                form: false,
                report: false
            },
            data: [],
            config: {}
        }
    },
    created() {
        this.$emit("setTitle", "Pronóstico del Clima por YR.no")
        this.getConfig()
    },
    methods: {
        getConfig() {
            this.loaders.form = true
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Reports.getConfig(farm_id, "yr")
                .then(response => {
                    vm.config = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loaders.form = false
                })
        },
        setData(value) {
            this.data = [...value]
        }
    }
}
</script>

<style lang="sass" scoped>
    .report-body
        min-height: 200px
</style>
