<template lang="html">
  <div
    class="row">
    <tabs
      :value="activeTab"
      type="primary"
      class="col-md-12">
      <tab-pane label="En Curso">
        <span slot="label">
          En curso
          <badge
            :type="'default'"
            :content="groupRecords.in_progress.length | toString "/>
        </span>
        <record-table
          :data="groupRecords.in_progress"
          :show-status="true"/>
      </tab-pane>
      <tab-pane label="Programado">
        <span slot="label">
          Programado
          <badge
            :type="'default'"
            :content="groupRecords.scheduled.length | toString "/>
        </span>
        <record-table
          :data="groupRecords.scheduled"/>
      </tab-pane>
      <tab-pane label="Realizado">
        <span slot="label">
          Realizado
          <badge
            :type="'default'"
            :content="groupRecords.done.length | toString "/>
        </span>
        <record-table
          :data="groupRecords.done"/>
      </tab-pane>
      <tab-pane label="Todos">
        <span slot="label">
          Todas
          <badge
            :type="'default'"
            :content="records.length | toString "/>
        </span>
        <record-table
          :show-status="true"
          :data="records"/>
      </tab-pane>
    </tabs>
  </div>
</template>

<script>
import { Tabs, TabPane } from "nowui/components"
import RecordTable from "./PruningTable"
export default {
    components: {
        Tabs,
        TabPane,
        RecordTable
    },
    props: {
        records: {
            type: Array,
            default: () => {
                return []
            }
        },
        showAllTab: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        activeTab() {
            return this.showAllTab ? "Todos" : ""
        },
        groupRecords() {
            return this.$HELPERS.groupLaborRecords(this.records)
        }
    }
}
</script>

<style lang="css" scoped>
</style>
