<template lang="html">
  <card v-loading="loader">
    <template slot="header">
      <h4 class="card-title"><b>Sensores</b></h4>
    </template>

    <div
      v-if="weather.data.length > 0"
      class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4 text-left">
            <fg-input
              :error="errors.first('country')"
              label="País"
              required>
              <el-select
                v-validate="{required: true}"
                v-model="weather.parameters.country"
                data-vv-as="País"
                placeholder="Seleccione el país"
                class="select-primary"
                name="country"
                filterable>
                <el-option
                  value="Chile"
                  label="Chile"
                  class="select-danger"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-4 text-left">
            <fg-input
              :error="errors.first('region')"
              label="Región"
              required>
              <el-select
                v-validate="{required: true}"
                v-model="weather.parameters.region"
                data-vv-as="Región"
                placeholder="Seleccione la región"
                class="select-primary"
                name="region"
                filterable>
                <el-option
                  value="Coquimbo"
                  label="Coquimbo"
                  class="select-danger"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-4 text-left">
            <fg-input
              :error="errors.first('city')"
              label="Ciudad"
              required>
              <el-select
                v-validate="{required: true}"
                v-model="weather.parameters.city"
                data-vv-as="Ciudad"
                placeholder="Seleccione la ciudad"
                class="select-primary"
                name="city"
                filterable>
                <el-option
                  value="Illapel"
                  label="Illapel"
                  class="select-danger"/>
                <el-option
                  value="Ovalle"
                  label="Ovalle"
                  class="select-danger"/>
              </el-select>
            </fg-input>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <apex-charts
          :options="temperatureChartData('Temperature').options"
          :series="temperatureChartData('Temperature').series"
          type="line"
          height="300"/>
      </div>
      <div class="col-md-12">
        <apex-charts
          :options="temperatureChartData('Precipitation').options"
          :series="temperatureChartData('Precipitation').series"
          type="line"
          height="300"/>
      </div>
      <div class="col-md-12">
        <apex-charts
          :options="temperatureChartData('Pressure').options"
          :series="temperatureChartData('Pressure').series"
          type="line"
          height="300"/>
      </div>
    </div>
  </card>
</template>

<script>
import ApexCharts from "vue-apexcharts"
export default {
    components: {
        ApexCharts
    },
    data() {
        return {
            loader: true,
            weather: {
                parameters: {
                    country: "Chile",
                    region: "Coquimbo",
                    city: "Illapel"
                },
                data: []
            }
        }
    },
    watch: {
        "weather.parameters": {
            handler: function(newValue, oldValue) {
                this.loader = true
                this.getYRData()
            },
            deep: true
        }
    },
    created() {
        this.getYRData()
    },
    methods: {
        getYRData() {
            let vm = this
            let url =
                "http://34.230.85.50:8080/data?city=" +
                this.weather.parameters.city +
                "&country=" +
                this.weather.parameters.country +
                "&region=" +
                this.weather.parameters.region
            this.$store
                .dispatch("Sensors/getYRData", { url })
                .then(response => {
                    vm.weather.data = vm.$EJSON.parse(
                        response.data.variables.data.value,
                        { relaxed: true }
                    )
                    console.log(vm.weather.data)
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        temperatureChartData(variable) {
            let payload = {}
            let data = this.weather.data.reduce((out, i) => {
                if (Object.keys(i.Forecast[variable]).length == 0) {
                    out[i.Forecast.Period] = i.Forecast[variable]
                } else {
                    out[i.Forecast.Period] = i.Forecast[variable].value
                }
                return out
            }, {})

            let label = ""
            if (variable == "Temperature") {
                label = "Temperatura (C)"
            } else if (variable == "Precipitation") {
                label = "Precipitación"
            } else if (variable == "Pressure") {
                label = "Presión atmosférica"
            }

            payload = {
                options: {
                    title: {
                        text: label + " de " + this.weather.parameters.city,
                        align: "center"
                    },
                    xaxis: {
                        title: {
                            text: "Fecha"
                        },
                        categories: Object.keys(data),
                        labels: {
                            datetimeFormatter: {
                                year: "yyyy",
                                month: "MMM 'yy",
                                day: "dd MMM",
                                hour: "HH:mm"
                            }
                        }
                    },
                    yaxis: {
                        title: {
                            text: label
                        },
                        decimalsInFloat: 2
                    },
                    dataLabels: {
                        formatter: function(val, opts) {
                            return val.toFixed(2)
                        }
                    }
                },
                series: [
                    {
                        name: label,
                        data: Object.values(data)
                    }
                ]
            }

            return payload
        }
    }
}
</script>

<style lang="css" scoped>
</style>
