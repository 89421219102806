<template>
  <div
    :data-color="backgroundColor"
    class="sidebar">
    <div class="logo">
      <a
        href="#"
        class="simple-text logo-mini">
        <div class="logo-image">
          <img :src="logo">
        </div>
      </a>
      <div class="navbar-minimize">
        <button
          id="minimizeSidebar"
          class="btn btn-simple btn-icon btn-neutral btn-round"
          @click="minimizeSidebar">
          <i class="now-ui-icons text_align-center visible-on-sidebar-regular"/>
          <i class="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini"/>
        </button>
      </div>
    </div>
    <div
      ref="sidebarScrollArea"
      class="sidebar-wrapper">
      <slot/>
      <ul class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link">

            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"/>
          </sidebar-item>
        </slot>

      </ul>
    </div>
  </div>
</template>
<script>
export default {
    name: "Sidebar",
    props: {
        title: {
            type: String,
            default: "Vue NUD PRO"
        },
        backgroundColor: {
            type: String,
            default: "black",
            validator: value => {
                let acceptedValues = [
                    "",
                    "blue",
                    "azure",
                    "green",
                    "orange",
                    "red",
                    "purple",
                    "black"
                ]
                return acceptedValues.indexOf(value) !== -1
            }
        },
        sidebarLinks: {
            type: Array,
            default: () => []
        },
        autoClose: {
            type: Boolean,
            default: true
        },
        settingsReady: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            logo: "",
            loaders: {
                logo: true
            }
        }
    },
    provide() {
        return {
            autoClose: this.autoClose
        }
    },
    watch: {
        settingsReady: function(newVal) {
            if (newVal == true) this.getLogo()
        }
    },
    beforeDestroy() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false
        }
    },
    created() {
        if (this.settingsReady) this.getLogo()
    },
    methods: {
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize()
            }
        },
        getLogo() {
            let default_logo = require("@/assets/img/ceres_logo.png")
            let vm = this

            try {
                let file_id = this.$store.getters["Settings/GET_SETTINGS"]
                    .general.logo.value
                this.Services.Files.getFile(file_id)
                    .then(response => {
                        vm.logo = vm.$HELPERS.contentToFile(response.data)
                    })
                    .catch(error => {
                        vm.logo = default_logo
                    })
                    .finally(() => {
                        vm.logoLoader = false
                    })
            } catch (e) {
                this.logo = default_logo
            }
        }
    }
}
</script>
<style>
@media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
        display: none;
    }
}
</style>
