<template lang="html">
  <card class="records-view">
    <template slot="header">
      <card-controls :back="false">
        <span slot="actions">
          <n-button
            v-if="canCreate"
            type="primary"
            simple=""
            @click.native="newHarvest">
            <i class="fas fa-plus-circle" />
            Registrar Cosecha
          </n-button>
          <n-button
            v-if="!components.globalFilter"
            type="primary"
            simple=""
            @click.native="components.globalFilter = true">
            <i class="fal fa-filter"/>
          </n-button>
          <n-button
            v-if="components.globalFilter"
            type="danger"
            simple=""
            @click.native="handleCloseFilter()">
            <i class="fal fa-filter"/>
          </n-button>
          <n-button
            type="primary"
            simple=""
            @click.native="goToDiagram">
            <i class="fal fa-project-diagram"/>
          </n-button>
        </span>
      </card-controls>

      <h4 class="card-title"><b>Cosechas</b></h4>
    </template>

    <div
      v-loading="loader"
      class="col-md-12 col-sm-12 pull-center">

      <global-filter
        v-if="components.globalFilter"
        :records="records"
        @close="handleCloseFilter"
        @applyFilter="applyFilter"/>
        
      <record-groups
        v-if="!loader"
        :records="records"
        :show-all-tab="showAllTab"/>
    </div>
  </card>
</template>

<script>
import GlobalFilter from "./Components/GlobalFilter"
import RecordGroups from "./Components/HarvestGroups"
export default {
    components: {
        GlobalFilter,
        RecordGroups
    },
    data() {
        return {
            title: "Cosechas",
            farm_id: "",
            showBackButton: false,
            components: {
                newHarvest: false,
                deleteHarvest: false,
                globalFilter: false
            },
            loader: true,
            records: [],
            showAllTab: false,
            canCreate: false
        }
    },
    created() {
        this.farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
        this.current_user = this.$store.getters["Auth/GET_USER"]
        this.canCreate = this.$HELPERS.hasRole(
            this.current_user,
            this.$CERES_VARS.data.permissions.supervisors
        )
        this.getHarvests()
    },
    methods: {
        getHarvests(filter) {
            let vm = this
            vm.showAllTab = false
            this.Services.Harvests.getHarvests(this.farm_id, filter)
                .then(response => {
                    vm.records = response.data
                    vm.showAllTab = filter != undefined ? true : false
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        newHarvest() {
            this.$router.push({
                name: "tasklist-start-process",
                params: {
                    id: "harvest"
                }
            })
        },
        handleCloseFilter() {
            this.components.globalFilter = false
            this.getHarvests()
        },
        applyFilter(query) {
            this.loader = true
            this.getHarvests(query)
        },
        goToDiagram() {
            this.$router.push({
                name: "diagram",
                params: {
                    processKey: "harvest",
                    state: "new",
                    backTarget: "harvests"
                }
            })
        }
    }
}
</script>

<style lang="sass" scoped>
	.records-view
		min-height: 200px
</style>
