var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 pull-center desktop-table"},[_c('el-table',{staticClass:"table-striped",attrs:{"data":_vm.data,"empty-text":"No hay seguimientos de estado fenológico registrados"}},[(_vm.showStatus)?_c('el-table-column',{attrs:{"prop":"status","label":"Estado","align":"center","name":"status","column-key":"status","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-tag',{staticClass:"used-product-tag",attrs:{"closable":false,"close-transition":false,"type":_vm.$TASK_STATES.getColor(row.workflow.state),"size":"small"}},[_vm._v(" "+_vm._s(_vm.$t(row.workflow.state))+" ")])],1)}}],null,false,1504213643)}):_vm._e(),_c('el-table-column',{attrs:{"label":"Última actualización","align":"center","header-align":"center","name":"updated_at","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('i',{staticClass:"el-icon-time"}),_vm._v(" "+_vm._s(_vm._f("moment")(row.updated_at,"DD-MM-YYYY"))+" "),_c('br'),_vm._v(" ("+_vm._s(_vm._f("daysFromToday")(_vm._f("moment")(row.updated_at,"YYYY-MM-DD"),"startOf"))+") ")])}}])}),_c('el-table-column',{attrs:{"prop":"customer_name","label":"Cliente","align":"center"}}),_c('el-table-column',{attrs:{"label":"Valor (IVA incl.)","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm._f("toLocaleStringNumber")(row.total)))+" ")])}}])}),(_vm.showStockIndicator)?_c('el-table-column',{attrs:{"label":"Stock disponible","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[(row.sale_items.every(function (i) { return i['has_stock?']; }))?_c('i',{staticClass:"fas fa-thumbs-up success"}):_c('i',{staticClass:"fas fa-exclamation-circle danger"})])}}],null,false,119015560)}):_vm._e(),_c('el-table-column',{attrs:{"label":"Acciones","align":"center","class-name":"table-category actions-column"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-center"},[_c('n-button',{attrs:{"type":"primary","size":"sm"},nativeOn:{"click":function($event){return _vm.view(row)}}},[_c('i',{staticClass:"fal fa-eye"})])],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }