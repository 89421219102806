<template lang="html">
  <div
    v-loading="loaders.form"
    element-loading-text="Esperando respuesta de YR.no"
    class="row"
  >
    <div class="col-md-12 global-filter">
      <div class="row">
        <div class="col-md-6 text-left">
          <fg-input :error="errors.first('search')" label="Ciudad" required>
            <el-select
              v-model="model.place"
              :remote-method="getPlaces"
              :loading="loaders.places"
              filterable
              remote
              reserve-keyword
              placeholder="Buscar ciudad..."
              class="no-box-shadow"
            >
              <el-option
                v-for="item in options"
                :key="item.url"
                :value="item.url"
                :label="getPlaceLabel(item)"
              >
                {{ getPlaceLabel(item) }}
              </el-option>
            </el-select>
          </fg-input>
        </div>

        <div class="col-md-6 text-right mt-auto mb-auto">
          <n-button type="primary" size="md" @click.native="addCity()">
            <i class="fas fa-plus-circle" />
            Agregar ciudad
          </n-button>
        </div>
      </div>

      <div v-if="model.places.length > 0" class="row mt-3">
        <div class="col-md-12 text-left">
          <p>Ciudades agregadas:</p>
        </div>

        <div class="col-md-12 text-left">
          <el-table
            :data="model.places"
            empty-text="Todavía no se ha agregado ni una ciudad."
          >
            <el-table-column label="Ciudad">
              <div slot-scope="{ row }">
                {{ getPlaceLabel(row) }}
              </div>
            </el-table-column>
            <el-table-column label="Acciones" align="center" width="200">
              <div slot-scope="scope">
                <n-button
                  type="danger"
                  size="sm"
                  @click.native="rmCity(scope.$index)"
                >
                  <i class="fas fa-minus-circle" />
                </n-button>
                <n-button
                  type="primary"
                  size="sm"
                  class="ms-1"
                  @click.native="getReport(scope.$index)"
                >
                  <i class="fal fa-sun-cloud me-1" /> Ver
                </n-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-else class="row mt-3">
        <div class="col-md-12">
          <p class="text-muted text-center">
            Todavía no se ha agregado ni una ciudad.
          </p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12 text-center">
          <n-button
            :disabled="model.places.length == 0"
            type="primary"
            class="pull-left"
            @click.native="saveConfig()"
          >
            <i class="fas fa-save me-1" />
            Guardar configuración
          </n-button>
          <n-button
            :disabled="model.places.length == 0"
            type="primary"
            class="pull-right"
            @click.native="getReport()"
          >
            <i class="fal fa-sun-cloud me-1" />
            Ver todos
          </n-button>
        </div>
      </div>

      <div v-if="Object.keys(messages).length > 0" class="row mt-3">
        <div class="col-md-12">
          <notification :messages="messages" :close="true" type="error" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loaders: {
        places: false,
        form: false,
      },
      options: [],
      model: {
        place: "",
        places: [],
      },
      messages: {},
    };
  },
  created() {
    this.loadConfig();
  },
  methods: {
    loadConfig() {
      if (this.config.config != undefined) {
        this.model.places = this.$COPY.copy(this.config.config.places);
      }
    },
    getPlaces(query) {
      if (query != "") {
        this.loaders.places = true;
        let vm = this;
        let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
        this.Services.Reports.getPlaces(farm_id, query)
          .then((response) => {
            vm.options = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            vm.loaders.places = false;
          });
      } else {
        this.options = [];
      }
    },
    getPlaceLabel(item) {
      return item.name + " / " + item.description + " / " + item.country;
    },
    addCity() {
      let city = this.options.find((i) => i.url == this.model.place);
      this.model.places.push(city);
      this.model.place = "";
    },
    rmCity(index) {
      this.model.places.splice(index, 1);
    },
    saveConfig() {
      let vm = this;
      vm.messages = {};
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      let config = {
        report_key: "yr",
        farm_id: farm_id,
        config: {
          places: this.model.places,
        },
      };
      this.Services.Reports.saveConfig(farm_id, "yr", config)
        .then((response) => {
          vm.$HELPERS.processServerMessage(response.data.messages);
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response.data != "string") {
            vm.messages = error.response.data;
          }
        })
        .finally(() => {});
    },
    getReport(index) {
      let queries;
      if (index != undefined) {
        queries = [this.model.places[index]];
      } else {
        queries = this.model.places;
      }
      let payload = {
        queries: queries,
      };
      let vm = this;
      vm.loaders.form = true;
      vm.$emit("loader", true);
      vm.$emit("showErrors", {});

      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Reports.getReport(farm_id, "yr", payload)
        .then((response) => {
          vm.$emit("data", response.data);
        })
        .catch((error) => {
          console.log(error);
          vm.$emit("showErrors", error.response.data);
        })
        .finally(() => {
          vm.loaders.form = false;
          vm.$emit("loader", false);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.global-filter
  margin-bottom: 0px !important
</style>
