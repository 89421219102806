<template lang="html">
  <div class="col-md-12 pull-center desktop-table">
    <el-table
      :data="data"
      :empty-text="'No hay podas registradas'"
      class="table-striped">

      <el-table-column
        v-if="showStatus"
        prop="status"
        label="Estado"
        align="center"
        name="status"
        column-key="status"
        class-name="table-category">
        <div
          slot-scope="{row}">
          <el-tag
            :closable="false"
            :close-transition="false"
            :type="$TASK_STATES.getColor(row.workflow.state)"
            size="small"
            class="used-product-tag">
            {{ $t(row.workflow.state) }}
          </el-tag>
        </div>
      </el-table-column>

      <el-table-column
        prop="date"
        label="Fecha"
        align="center"
        name="date"
        column-key="date"
        class-name="table-category"
        sortable>
        <div
          slot-scope="{row}">
          <i class="el-icon-time"/> {{ row.date | moment("DD-MM-YYYY") }} <br> ({{ row.date | moment("dddd") }})
        </div>
      </el-table-column>

      <el-table-column
        label="Cuarteles"
        align="center"
        class-name="table-category">

        <div
          slot-scope="{row}">
          <sector-tags
            :current_sectors="row.show_sectors"
            :sectors="sectors"/>
        </div>
      </el-table-column>

      <el-table-column
        label="Tipo de monitoreo"
        align="center"
        class-name="table-category">

        <div
          slot-scope="{row}">
          {{ $t(row.tracking_type) }}
        </div>
      </el-table-column>

      <el-table-column
        label="¿Tolerancia excedida?"
        align="center"
        class-name="table-category">

        <div
          slot-scope="{row}">
          <i :class="'fas fa-bug '+getClass(row)"/>
        </div>
      </el-table-column>

      <el-table-column
        label="Acciones"
        align="center"
        class-name="table-category actions-column">

        <div
          slot-scope="{row}"
          class="text-center">
          <n-button
            type="primary"
            size="sm"
            @click.native="view(row)">
            <i class="fal fa-eye"/>
          </n-button>
        </div>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui"
import SectorTags from "@/views/Labors/Components/SectorTags"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        SectorTags
    },
    props: {
        data: {
            type: Array,
            default: () => {
                return []
            }
        },
        sectors: {
            type: Array,
            default: () => {
                return []
            }
        },
        showStatus: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        filterSectors(row) {
            return this.sectors.filter(s => {
                let rowSectors = row.sectors.map(i => i.$oid)
                return rowSectors.includes(s._id.$oid)
            })
        },
        view(row) {
            this.$router.push({
                name: "view-plague-monitoring",
                params: {
                    id: row._id.$oid
                }
            })
        },
        getClass(row) {
            if (row.workflow.state == "scheduled") {
                return "label-color"
            }
            switch (row["any_tolerance_exceeded?"]) {
                case true:
                    return "danger"
                case false:
                    return "success"
                default:
                    return "label-color"
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
