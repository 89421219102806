const UsdaService = {
    parseUsdaReportConfig(thisInstance, options, reportConfig) {
        const customTranslation = {
            lastX: "Last X Days",
            dateRangeType: "Date Range Type"
        }
        const convertToDate = ["repDate", "endDate"]
        let result = []
        let parsed = null
        for (let key in reportConfig) {
            parsed = thisInstance.$HELPERS.deepSearch(
                options,
                "value",
                reportConfig[key],
                "text"
            )

            if (!parsed) {
                parsed = reportConfig[key]
            }

            if (Object.keys(customTranslation).includes(key)) {
                parsed = customTranslation[key] + ": " + parsed
            }

            if (convertToDate.includes(key)) {
                parsed = thisInstance.$moment(parsed).format("DD-MM-YYYY")
            }
            result.push(parsed)
        }
        return result.join(" | ")
    }
}

export default UsdaService
