<template lang="html">
  <div class="col-12 picture-upload-wrapper">
    <div class="row">
      <div class="col-12">
        <el-upload
          v-loading="loader"
          :show-file-list="false"
          :http-request="() => {}"
          :on-change="onPictureChange"
          :before-upload="beforePictureUpload"
          :on-error="onPictureError"
          class="picture-uploader"
          action=""
        >
          <img
            v-if="picture != ''"
            :src="picture"
            class="picture">
          <div
            v-else
            class="picture-upload-text">
            <i class="el-icon-plus logo-uploader-icon"/>
            <br>
            <label>Subir una foto</label>
          </div>
        </el-upload>
      </div>
    </div>
    <div
      v-if="picture != ''"
      class="row">
      <div class="col-12 text-center text-muted small">
        <i
          class="fal fa-search-plus picture-control"
          @click="modal.previewPicture = true"/>
        <i
          class="fal fa-trash picture-control ps-2"
          @click="onPictureRemove"/>
      </div>
    </div>

    <modal
      :show.sync="modal.previewPicture"
      header-classes="justify-content-center">
      <h4
        slot="header"
        class="title title-up">Registro fotográfico</h4>

      <img :src="picture" >
    </modal>

  </div>
</template>

<script>
export default {
  props: {
    sector_id: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    photo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loader: false,
      modal: {
        previewPicture: false,
      },
      model: {
        picture: undefined,
        pictureUrl: undefined,
      },
      picture: "",
    };
  },
  created() {
    let vm = this;
    this.picture = this.photo;

    if (
      this.picture != undefined &&
      this.picture != "" &&
      !this.picture.startsWith("data:image")
    ) {
      this.loader = true;
      this.Services.Files.getFile(this.picture)
        .then((response) => {
          vm.picture = vm.$HELPERS.contentToFile(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    }
  },
  methods: {
    beforePictureUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      const isPNG = file.type === "image/png";

      this.loader = true;

      return (isJPG || isPNG) && isLt2M;
    },
    onPictureChange(file) {
      let vm = this;
      let reader = new FileReader();
      this.model.pictureUrl = URL.createObjectURL(file.raw);
      reader.onload = function (event) {
        vm.model.picture = event.target.result;
        vm.picture = vm.model.picture;
        vm.loader = false;

        vm.$emit("pictureProcessed", vm.sector_id, vm.index, vm.model.picture);
      };
      reader.readAsDataURL(file.raw);
    },
    onPictureError(err) {
      console.log(err);
    },
    onPictureRemove() {
      this.model.picture = "";
      this.model.pictureUrl = "";
      this.$emit(
        "pictureProcessed",
        this.sector_id,
        this.index,
        this.model.picture
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.picture-upload-wrapper
    margin-top: 10px

    .picture-control
        cursor: pointer

        .picture-uploader
            height: 65px
            cursor: pointer
            width: 100%
            border: 1px dashed #d9d9d9
            border-radius: 6px
            cursor: pointer
            position: relative
            overflow: hidden
            .el-upload
                padding: 5% 0

        .picture-upload-text
            padding: 10% 0

        .picture-uploader:hover
            border-color: #409EFF

        .picture-uploader-icon
            font-size: 28px
            color: #8c939d
            width: 200px
            min-height: 80px
            line-height: 80px
            text-align: center
        .picture
            height: 65px
            object-fit: cover
            object-position: 50% 50%
            cursor: pointer
</style>
