<template lang="html">
  <div>
    <card class="col-md-12">
      <template slot="header">
        <card-controls :back="false">
          <span slot="actions">
            <n-button
              v-if="canCreate"
              type="primary"
              simple=""
              @click.native="components.processList = true">
              <i class="fas fa-plus-circle"/>
              Iniciar actividad
            </n-button>
            <n-button
              type="primary"
              simple=""
              @click.native="switchView">
              <i :class="['fas', {'fa-calendar': !calendarMode }, {'fa-tasks': calendarMode}, 'me-1']"/> {{ calendarMode ? "Lista" : "Calendario" }}
            </n-button>
          </span>
        </card-controls>

        <h4 class="card-title"><b>{{ title }}</b></h4>
      </template>

      <process-list
        v-if="components.processList"
        @close="components.processList = false"/>

      <component 
        :is="taskComponent" 
        @setTitle="title = $event" />

    </card>
  </div>
</template>

<script>
import ProcessList from "./ProcessList"
import ListView from "./ActiveProcess"
import CalendarView from "./CalendarWidget"

export default {
    components: {
        ProcessList,
        ListView,
        CalendarView
    },
    data() {
        return {
            title: "",
            currentTask: undefined,
            components: {
                processList: false
            },
            canCreate: false
        }
    },
    computed: {
        calendarMode() {
            return this.$route.params.view === "calendar-view"
        },
        taskComponent() {
            return this.calendarMode ? "calendar-view" : "list-view"
        }
    },
    async created() {
        let current_user = this.$store.getters["Auth/GET_USER"]
        this.canCreate = this.$HELPERS.hasRole(
            current_user,
            this.$CERES_VARS.data.permissions.supervisors
        )
        await this.$store.dispatch("Processes/getProcesses")
        await this.$store.dispatch("Users/getUsers")
    },
    methods: {
        switchView() {
            this.$router.push({
                name: "tasklist",
                params: {
                    view: this.calendarMode ? "list-view" : "calendar-view"
                }
            })
        }
    }
}
</script>

<style lang="css">
</style>
