<template lang="html">
  <card>
    <template slot="header">
      <card-controls
        :back="true"
        :back-custom-target="backCustomTarget"/>

      <h4 class="card-title"><b>{{ computedTitle }}</b></h4>
    </template>
    <div class="row">
      <div class="col-md-12 diagram-wrapper">
        <div
          v-loading="loader"
          id="bpmn-process"/>
      </div>
    </div>
  </card>
</template>

<script>
import BpmnJS from "bpmn-js"
import Modal from "nowui/components/Modal"

export default {
    components: {
        Modal
    },
    props: {
        processKey: {
            type: String,
            default: ""
        },
        backTarget: {
            type: String,
            default: ""
        },
        customBackTarget: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            title: "Diagrama",
            loader: true,
            processXml: "",
            viewer: undefined,
            task: {},
            modals: {
                bpmnModel: true
            }
        }
    },
    computed: {
        computedTitle() {
            return this.title
        },
        backCustomTarget() {
            if (Object.keys(this.customBackTarget).length > 0) {
                return this.customBackTarget
            }

            if (this.backTarget == "") {
                if (this.$route.params.state != "done") {
                    return {
                        name: "continue-process",
                        params: {
                            id: this.$route.params.id,
                            processKey: this.$route.params.processKey,
                            state: this.$route.params.state
                        }
                    }
                } else {
                    return {
                        name: "view-" + this.$route.params.processKey,
                        params: {
                            id: this.$route.params.id
                        }
                    }
                }
            } else {
                return {
                    name: this.backTarget
                }
            }
        }
    },
    created() {
        this.getDiagram()
    },
    methods: {
        getDiagram() {
            let vm = this
            let processKey = this.$HELPERS.stringToClassName(
                this.$route.params.processKey
            )
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid

            this.Services.Processes.getProcessDefinition(farm_id, processKey)
                .then(response => {
                    vm.processXml = response.data.diagram
                    vm.renderBPMN()
                    vm.loader = false
                })
                .catch(error => {
                    console.log(error)
                })
        },
        renderBPMN() {
            let vm = this
            this.viewer = new BpmnJS({
                container: "#bpmn-process"
            })

            this.viewer.importXML(vm.processXml, function(err) {
                if (err) {
                    console.log("error rendering", err)
                } else {
                    var canvas = vm.viewer.get("canvas")
                    canvas.zoom("fit-viewport")
                    if (vm.task.taskDefinitionKey != undefined)
                        canvas.addMarker(vm.task.taskDefinitionKey, "highlight")
                    if (vm.$route.params.state != undefined)
                        canvas.addMarker(vm.$route.params.state, "highlight")
                }
            })
        }
    }
}
</script>

<style lang="sass">
	.diagram-wrapper
		min-height: 400px
		#bpmn-process
			height: 600px
	.bpmn-diagram-modal
		.modal-dialog
			max-width: 700px
	.djs-container
		.highlight
			.djs-outline
				stroke-width: 2px !important

	.djs-container
		.highlight
			.djs-visual>:nth-child(1)
				fill: #bce2f5 !important
</style>
