<template lang="html">
  <div v-loading="!render" class="col-md-12">
    <div v-if="render" class="row">
      <div class="col-md-12 text-center">
        <el-alert
          v-if="!isMyTask"
          :closable="false"
          :title="
            'Esta tarea está asignada a: ' +
            getUserName(record.workflow.assignee)
          "
          name="isMayTask"
          type="warning"
          center
          show-icon
        />
      </div>
      <div v-if="record.workflow.state == 'done'" class="col-md-12 text-center">
        <n-button type="danger" @click.native="confirmDelete">
          Eliminar
        </n-button>
        <n-button type="primary" @click.native="goToEdit"> Editar </n-button>
      </div>
      <div v-else class="col-md-12 text-center">
        <n-button
          :disabled="disableGoToTask"
          type="primary"
          @click.native="goToTask"
        >
          Ir a la tarea
        </n-button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <notification :messages="messages" :close="true" type="error" />
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { Alert } from "element-ui";

export default {
  components: {
    [Alert.name]: Alert,
  },
  props: {
    processId: {
      type: String,
      default: "",
    },
    processKey: {
      type: String,
      default: "",
    },
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
    messages: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      users: [],
      current_user: {},
      render: false,
    };
  },
  computed: {
    isMyTask() {
      return this.record.workflow.assignee.$oid == this.current_user._id.$oid;
    },
    isSupervisor() {
      return this.$HELPERS.hasRole(
        this.current_user,
        this.$CERES_VARS.data.permissions.supervisors
      );
    },
    disableGoToTask() {
      if (this.isSupervisor) return false;
      if (!this.isMyTask) return true;

      return false;
    },
  },
  created() {
    this.getUsers();
    this.current_user = this.$store.getters["Auth/GET_USER"];
  },
  methods: {
    goToTask() {
      this.$router.push({
        name: "continue-process",
        params: {
          id: this.record._id.$oid,
          processKey: this.processKey,
          state: this.record.workflow.state,
        },
      });
    },
    confirmDelete() {
      let vm = this;
      Swal.fire({
        title: "¿Estás seguro de eliminar esta labor?",
        text: "¡La accción no podrá revertirse!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No,<br/>Quiero conservarla",
        confirmButtonText: "Si,<br/>Quiero eliminarla",
        reverseButtons: true,
        focusCancel: true,
        cancelButtonClass: "primary",
        confirmButtonClass: "danger",
      }).then((result) => {
        if (result.value) vm.$emit("delete");
      });
    },
    goToEdit() {
      this.$router.push({
        name: "edit-" + this.processKey,
        params: {
          id: this.record._id.$oid,
          record: this.record,
        },
      });
    },
    getUsers() {
      let vm = this;
      this.Services.Users.getUsers()
        .then((response) => {
          vm.users = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.render = true;
        });
    },
    getUserName(userId) {
      let user = this.users.find((i) => i._id.$oid == userId.$oid);
      return user != undefined ? user.full_name : "CERES";
    },
  },
};
</script>

<style lang="sass" scoped>
.el-alert
  margin-top: 10px
</style>
