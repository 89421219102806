<template lang="html">
  <modal
    :show.sync="modals.newSector"
    class=""
    header-classes="justify-content-center"
    @close="$emit('close')"
  >
    <h4 slot="header" class="title title-up">{{ title }}</h4>
    <div v-loading="loader" class="row">
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true }"
          v-model="model.name"
          :error="errors.first('name')"
          data-vv-as="Nombre"
          name="name"
          label="Nombre"
          type="text"
          placeholder="Nombre"
          required
        />
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 0, numeric: true }"
          v-model.number="model.establishment_year"
          :error="errors.first('establishment_year')"
          data-vv-as="Año plantación"
          name="establishment_year"
          label="Año plantación"
          type="number"
          placeholder="Año plantación"
          required
        />
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          :error="errors.first('specie')"
          label="Especie"
          required
          filterable
        >
          <el-select
            v-validate="{ required: true }"
            v-model="model.specie"
            data-vv-as="Especie"
            placeholder="Especie"
            class="select-primary"
            name="specie"
            @change="clearVariety"
          >
            <el-option
              v-for="(specie, key) in settings.general.species"
              :value="specie._id.$oid.toString()"
              :label="specie.value"
              :key="key"
              class="select-danger"
            />
          </el-select>
        </fg-input>
      </div>
      <div class="col-md-6 text-left">
        <fg-input :error="errors.first('variety')" label="Variedad" required>
          <el-select
            v-validate="{ required: true }"
            v-model="model.variety"
            data-vv-as="Variedad"
            placeholder="Variedad"
            class="select-primary"
            name="variety"
            collapse-tags=""
            filterable
          >
            <el-option
              v-for="(variety, key) in computedVariety"
              :value="variety._id.$oid.toString()"
              :label="variety.value"
              :key="key"
              class="select-danger"
            />
          </el-select>
        </fg-input>
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 0, decimal: true }"
          v-model.number="model.area"
          :error="errors.first('area')"
          data-vv-as="Superficie (ha)"
          name="area"
          label="Superficie (ha)"
          type="number"
          placeholder="Superficie (ha)"
          required
        />
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 0, numeric: true }"
          v-model.number="model.num_of_plants"
          :error="errors.first('num_of_plants')"
          data-vv-as="Número de plantas"
          name="num_of_plants"
          label="Número de plantas"
          type="number"
          placeholder="Número de plantas"
          required
        />
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 0, decimal: true }"
          v-model.number="model.distance_between_lines"
          :error="errors.first('distance_between_lines')"
          data-vv-as="Distancia entre hileras (m)"
          name="distance_between_lines"
          label="Distancia entre hileras (m)"
          type="number"
          placeholder="Distancia entre hileras (m)"
          required
        />
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 0, decimal: true }"
          v-model.number="model.distance_between_plants"
          :error="errors.first('distance_between_plants')"
          data-vv-as="Distancia entre plantas (m)"
          name="distance_between_plants"
          label="Distancia entre plantas (m)"
          type="number"
          placeholder="Distancia entre plantas (m)"
          required
        />
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 0, numeric: true }"
          v-model.number="model.droppers_per_plant"
          :error="errors.first('droppers_per_plant')"
          data-vv-as="Goteros por planta"
          name="droppers_per_plant"
          label="Goteros por planta"
          type="number"
          placeholder="Goteros por planta"
          required
        />
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 0, decimal: true }"
          v-model.number="model.caudal_per_dropper"
          :error="errors.first('caudal_per_dropper')"
          data-vv-as="Caudal por gotero (L)"
          name="caudal_per_dropper"
          label="Caudal por gotero (L)"
          type="number"
          placeholder="Caudal por gotero (L)"
          required
        />
      </div>
      <div class="col-md-12">
        <hr />
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div class="text-left me-3">
          <n-checkbox
            v-model="model.subsector"
            @click.native="model.parent_sector = ''"
            >Es sub-cuartel</n-checkbox
          >
        </div>
        <div v-if="model.subsector" class="text-left">
          <fg-input
            :error="errors.first('sector')"
            label="Cuartel padre"
            required
          >
            <el-select
              v-validate="{ required: true }"
              v-model="model.parent_sector"
              :class="model.subsector ? 'select-primary' : 'select-default'"
              :disabled="!model.subsector"
              data-vv-as="Cuartel"
              placeholder="Cuartel"
              name="sector"
            >
              <el-option
                v-for="(sector, key) in sectors"
                :value="sector._id.$oid.toString()"
                :label="sector.name"
                :key="key"
                class="select-danger"
              />
            </el-select>
          </fg-input>
        </div>
      </div>
    </div>
    <div v-if="messages.length > 0" class="col-md-12 text-left">
      <notification
        v-for="(message, key) in messages"
        :key="key"
        :message="message.message"
        :type="message.type"
        :close="true"
      />
    </div>
    <template slot="footer">
      <n-button @click.native="$emit('close')">Cerrar</n-button>
      <n-button type="primary" @click.native="validate()">Guardar</n-button>
    </template>
  </modal>
</template>

<script>
import Swal from "sweetalert2";
import Modal from "nowui/components/Modal";

export default {
  components: {
    Modal,
  },
  props: {
    farm: {
      type: Object,
      default: () => {
        return {};
      },
    },
    sectors: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      title: "Nuevo sector",
      modals: {
        newSector: false,
      },
      settings: {},
      model: {},
      notification: {
        show: false,
        message: "",
        type: "danger",
      },
      loader: false,
      messages: [],
    };
  },
  computed: {
    computedVariety() {
      let variety = [];

      if (this.settings.varieties[this.model.specie] != undefined) {
        variety = this.settings.varieties[this.model.specie];
      }

      return variety;
    },
  },
  created() {
    this.settings = this.$store.getters["Settings/GET_SETTINGS"];
    this.model.farm_id = this.farm._id;
  },
  mounted() {
    this.modals.newSector = true;
  },
  methods: {
    clearVariety() {
      this.model.variety = "";
    },
    async validate() {
      const isValid = await this.$validator.validateAll();
      if (isValid) this.newSector();
    },
    newSector() {
      let vm = this;
      let farm_id = this.farm._id.$oid.toString();
      let payload = vm.model;

      this.loader = true;
      this.disableSubmit = true;
      this.messages = [];
      this.Services.Sectors.createSector({ farm_id, payload })
        .then((response) => {
          // vm.processMessages(response.data.messages,"success")
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          }).then((result) => {});

          vm.$emit("close");
          vm.$emit("newSectorAdded");
          vm.$emit("updateFarm");
        })
        .catch((error) => {
          vm.processMessages(error.response.data.errors, "danger");
        })
        .finally(() => {
          vm.loader = false;
        });

      // let payload = {
      // 	sector: this.$COPY.copy(vm.model),
      // 	farm_id: this.farm._id.toString(),
      // 	farm: this.farm
      // }
      //
      // if (
      // 	payload.sector.parent_sector != "" &&
      // 	payload.sector.parent_sector != undefined
      // ) {
      // 	payload.sector.parent_sector = this.$EJSON.BSON.ObjectID(
      // 		payload.sector.parent_sector
      // 	)
      // }
      // vm.loader = true
      // this.$store
      // 	.dispatch("Sectors/addSector", { payload })
      // 	.then(response => {
      // 		console.log(response)
      // 		swal({
      // 			title: "Nuevo cuartel agregado exitósamente",
      // 			type: "success"
      // 		})
      //
      // 		vm.$emit("close")
      // 		vm.$emit("newSectorAdded")
      // 		vm.$emit("updateFarm")
      // 	})
      // 	.catch(error => {
      // 		console.log(error)
      // 	})
      // 	.finally(() => {
      // 		vm.loader = false
      // 	})
    },
  },
};
</script>

<style lang="sass">
.modal
  .modal-dialog
    max-width: 700px
</style>
