<template lang="html">
  <div v-loading="loader" :class="landingClass">
    <div v-if="!loader" class="col-md-12 no-padding">
      <landing-page-nav-bar
        :data="data"
        @showBackDrop="showBackDrop = true"
        @hideBackDrop="showBackDrop = false"
      />
      <landing-page-header :data="data" />
      <main class="main-content">
        <landing-page-features :data="data" />
        <landing-page-service :data="data" />
        <landing-page-roadmap :data="data" />
        <landing-page-pricing :data="data" />
        <landing-page-faq :data="data" />
        <landing-page-contact :data="data" />
        <landing-page-footer :data="data" />
      </main>
    </div>
  </div>
</template>

<script>
// import "thesaas/js/page.js"
// import "thesaas/js/script.js"

import LandingPageNavBar from "./Components/NavBar";
import LandingPageHeader from "./Components/Header";
import LandingPageFeatures from "./Components/Features";
import LandingPageService from "./Components/Service";
import LandingPageRoadmap from "./Components/Roadmap";
import LandingPagePricing from "./Components/Pricing";
import LandingPageFaq from "./Components/Faq";
import LandingPageFooter from "./Components/Footer";
import LandingPageContact from "./Components/Contact";

export default {
  components: {
    LandingPageHeader,
    LandingPageNavBar,
    LandingPageFeatures,
    LandingPageService,
    LandingPageRoadmap,
    LandingPagePricing,
    LandingPageFaq,
    LandingPageFooter,
    LandingPageContact,
  },
  metaInfo() {
    const meta = this.metaInfo;
    return { meta };
  },
  data() {
    return {
      loader: true,
      data: {},
      showBackDrop: false,
      metaInfo: [],
    };
  },
  computed: {
    landingClass() {
      if (this.showBackDrop) return "landing navbar-open";
      return "landing";
    },
  },
  created() {
    let vm = this;
    this.Services.Content.getPage("homepage")
      .then((response) => {
        vm.data = response.data;
        vm.addMetaTags();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        vm.loader = false;
      });
  },
  methods: {
    addMetaTags() {
      document.title = this.data.meta_title;
      this.metaInfo.push({
        name: "description",
        content: this.data.meta_description,
      });
    },
  },
};
</script>

<style lang="sass"></style>
