<template lang="html">
  <card>
    <template slot="header">
      <card-controls :back="true" />

      <h4 class="card-title">
        <b>Editar Monitoreo de Plagas y Enfermedades</b>
      </h4>
    </template>

    <plague-monitoring-form
      v-if="Object.keys(model).length > 0"
      v-loading="loader"
      :messages="messages"
      :run-validations="runValidations"
      :record="model"
      @isValid="isValid"
    >
      <div slot="actions">
        <n-button simple="" @click.native="goToViewRecord()"> Cerrar </n-button>
        <n-button type="primary" @click.native="validate()">
          Guardar cambios
        </n-button>
      </div>
    </plague-monitoring-form>
  </card>
</template>

<script>
import PlagueMonitoringForm from "./Components/PlagueMonitoringForm";
import Swal from "sweetalert2";

export default {
  components: {
    PlagueMonitoringForm,
  },
  props: {
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loader: false,
      messages: {},
      runValidations: 1,
      model: {},
    };
  },
  created() {
    if (Object.keys(this.record).length > 0) {
      this.model = this.$COPY.copy(this.record);
    } else {
      this.goToViewRecord();
    }
  },
  methods: {
    updateModel(newModel) {
      this.model = newModel;
    },
    validate() {
      this.loader = true;
      this.messages = {};
      this.runValidations += 1;
    },
    isValid(result, data) {
      result ? this.save(data) : (this.loader = false);
    },
    goToViewRecord() {
      this.$router.push({
        name: "view-plague-monitoring",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    save(data) {
      let vm = this;
      this.loader = false;
      Swal.fire({
        title:
          "Esta acción actualizará los valores de tolerancias, ¿desea continuar?",
        text: "Se utilizarán los valores más actuales registrados en Configuración > Monitoreo de plagas",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No,<br/>Abortar misión",
        confirmButtonText: "Si,<br/>Quiero actualizarla",
        reverseButtons: true,
        focusCancel: true,
        cancelButtonClass: "primary",
        confirmButtonClass: "danger",
      }).then((result) => {
        if (result.value) {
          vm.loader = true;
          vm.Services.PlagueMonitorings.updatePlagueMonitoring(
            data.farm_id,
            data._id.$oid,
            data
          )
            .then((response) => {
              Swal.fire({
                title: response.data.messages,
                icon: "success",
              }).then(() => {
                vm.goToViewRecord();
              });
            })
            .catch((error) => {
              console.log(error);
              vm.messages = error.response.data;
            })
            .finally(() => {
              vm.loader = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped></style>
