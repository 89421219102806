<template lang="html">
  <card class="col-md-12 px-3 pb-5">
    <div slot="header">
      <h4 class="card-title"><b>Informes</b></h4>
    </div>

    <div class="row search-box">
      <div class="col-md-12 col-sm-12 text-left">
        <fg-input
          v-model="search"
          type="text"
          placeholder="Filtra por nombre o descripción del reporte"
        />
      </div>
    </div>

    <div v-for="reportCategory in reportList" :key="reportCategory.name">
      <div class="row mt-4">
        <div class="col-md-12 col-sm-12 text-left">
          <h5>{{ reportCategory.name }}</h5>
          <hr />
        </div>
      </div>

      <div v-if="filteredReports(reportCategory.reports).length" class="row">
        <div
          v-for="(report, key) in filteredReports(reportCategory.reports)"
          :key="key"
          class="col-md-4 col-sm-12 mt-3"
        >
          <a class="report" @click="viewReport(report.path)">
            <el-card shadow="hover">
              <h6 class="card-subtitle mb-2 text-muted">{{ report.name }}</h6>
              <p class="card-text">{{ report.description }}</p>
            </el-card>
          </a>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-sm-12 mt-3">
          <span class="el-table__empty-text"
            >No hay informes que conicidan con la búsqueda</span
          >
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { Card } from "element-ui";
import { mapState } from "vuex";

export default {
  components: {
    [Card.name]: Card,
  },
  data() {
    return {
      search: "",
      reportList: {
        labors: {
          name: "Labores",
          reports: [
            {
              name: "Resumen de cosecha",
              processes: ["FarmHarvest"],
              description: "Informe de resumen de cosechas",
              path: {
                name: "farm-harvests-summary",
                params: {
                  title: "Resumen de cosechas",
                },
              },
            },
            {
              name: "Cajas por Cosechero",
              processes: ["Harvest"],
              description:
                "Explora la cantidad de cajas/potes cosechadas por año, mes y cosechador",
              path: {
                name: "boxes-per-worker",
                params: {
                  title: "Informe de Cajas por Cosechero",
                },
              },
            },
            {
              name: "Cosecha del Campo",
              processes: ["Harvest"],
              description: "Informe con la cosecha global del campo",
              path: {
                name: "harvest-farm-stats",
                params: {
                  title: "Informe de Cosecha del Campo",
                },
              },
            },
            {
              name: "Estados Fenológicos",
              processes: ["PhenologicalTracking"],
              description: "Avance de estados fenológicos",
              path: {
                name: "phenological-stages-report",
                params: {
                  title: "Informe de Estados Fenológicos",
                },
              },
            },
            {
              name: "Historial de uso de productos",
              process: ["ProductApplication", "Inventory"],
              description:
                "Historial de uso de productos fertilizantes y fitosanitarios en aplicaciones",
              path: {
                name: "product-applications-by-product",
                params: {
                  title: "Informe de uso de productos",
                },
              },
            },
          ],
        },
        warehouse: {
          name: "Inventario",
          reports: [
            {
              name: "Stock de productos",
              process: ["ProductApplication", "Inventory"],
              description:
                "Resumen de los stocks actuales y futuros por producto",
              path: {
                name: "product-stocks",
                params: {
                  title: "Informe de Stock de productos",
                },
              },
            },
            // {
            //     name: "Consumo Anual por Producto",
            //     description:
            //         "Explora la cantidad de producto utilizada por año",
            //     urlName: "product-uses"
            // },
            {
              name: "Quiebres de Stock",
              process: ["ProductApplication", "Inventory"],
              description: "Explora los quiebres de stock a gestionar",
              path: {
                name: "product-stock-shortage",
                params: {
                  title: "Informe de Quiebres de Stock",
                },
              },
            },
          ],
        },
        weather: {
          name: "Meteorología y clima",
          reports: [
            {
              name: "Boletines CEAZEMET",
              description: "Boletines meteorológicos generados por CEAZEMET",
              path: {
                name: "weather-ceazemet",
                params: {
                  title: "Boletines CEAZEMET",
                },
              },
            },
            {
              name: "Pronóstico YR",
              description:
                "Pronóstico de clima de los próximos 7 días por Yr.no",
              path: {
                name: "report",
                params: {
                  id: "yr",
                },
              },
            },
          ],
        },
        finances: {
          name: "Finanzas",
          reports: [
            {
              name: "Flujo de caja",
              admin: true,
              description: "Flujo de caja en base a las labores",
              path: {
                name: "report",
                params: {
                  id: "cashflow",
                },
              },
            },
          ],
        },
        // market: {
        //   name: "Mercado",
        //   reports: [
        //     {
        //       name: "Informes desde el USDA",
        //       description:
        //         "Shipping Point, Retail, Movements and Terminal Market reports from USDA",
        //       path: {
        //         name: "report",
        //         params: {
        //           id: "usda",
        //         },
        //       },
        //     },
        //   ],
        // },
      },
    };
  },
  computed: {
    ...mapState("Processes", ["processes"]),
    ...mapState("Auth", ["isAdmin"]),
    allowedProcessKeys() {
      return this.processes.map((i) => i.model);
    },
  },
  methods: {
    filteredReports(reportList) {
      let search = this.search.toLowerCase();
      return reportList.filter((data) => {
        if (data.admin == true && !this.isAdmin) {
          return false;
        }

        let allowed = true;
        if (data.processes != undefined) {
          allowed = data.processes.every((i) =>
            this.allowedProcessKeys.includes(i)
          );
        }

        if (!allowed) return false;

        let obj = Object.values(data);
        return (
          !this.search || JSON.stringify(obj).toLowerCase().includes(search)
        );
      });
    },
    viewReport(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="sass" scoped>
.search-box
  margin: 0px 0px 10px 0px !important
.report
  cursor: pointer
.el-card
  min-height: 120px
</style>
