<template lang="html">
  <div>
    <tabs :value="showAllTab" type="primary" class="col-md-12">
      <tab-pane>
        <span slot="label">
          Mis tareas en curso
          <badge
            :type="'default'"
            :content="filteredTasks.currentTasks.length | toString"
          />
        </span>

        <tasks
          :tasks="filteredTasks.currentTasks"
          :task-filter="taskFilter"
          :users="users"
        />
      </tab-pane>
      <tab-pane>
        <span slot="label"> Mis tareas programadas </span>
        <!-- <n-button
          v-if="$HELPERS.isAdmin && filteredTasks.futureTasks.some(i => !$HELPERS.isFuture(i.date))"
          type="primary"
          simple=""
          class="pull-right"
          @click.native="processTimers">
          <i class="fas fa-stream" />
          Procesar timers
        </n-button> -->
        <tasks
          v-loading="loader"
          :tasks="filteredTasks.futureTasks"
          :task-filter="taskFilter"
          :users="users"
        />
      </tab-pane>
      <tab-pane v-if="isSupervisor" label="Todos">
        <span slot="label"> Todas </span>
        <tasks
          :tasks="tasks"
          :is-admin="isAdmin"
          :is-supervisor="isSupervisor"
          :task-filter="taskFilter"
          :users="users"
          @updateTaskList="updateTaskList"
        />
      </tab-pane>
    </tabs>
  </div>
</template>

<script>
import Tasks from "./Components/Tasks";
import { Tabs, TabPane } from "nowui/components";

export default {
  components: {
    Tasks,
    Tabs,
    TabPane,
  },
  props: {
    tasks: {
      type: Array,
      default: () => {
        return [];
      },
    },
    taskFilter: {
      type: String,
      default: "own",
    },
    users: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isSupervisor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loader: false,
      filteredTasks: {
        currentTasks: [],
        futureTasks: [],
        toAssign: [],
      },
      showAllTab: "",
    };
  },
  created() {
    if (this.tasks != undefined) this.processTasks();
  },
  methods: {
    processTasks() {
      let vm = this;
      let tasks = this.$COPY.copy(this.tasks);
      if (this.isSupervisor) {
        let current_user_id = this.$store.getters["Auth/GET_USER"]._id.$oid;
        tasks = tasks.filter(
          (i) => i.workflow.assignee.$oid == current_user_id
        );
      }

      vm.filteredTasks.currentTasks = tasks.filter(
        (i) => i.workflow.state != "scheduled"
      );
      vm.filteredTasks.futureTasks = tasks.filter(
        (i) => i.workflow.state == "scheduled"
      );
    },
    updateTaskList() {
      this.showAllTab = "Todas";
      this.$emit("updateTaskList");
    },
  },
};
</script>

<style lang="sass" scoped>
.capitalize
  text-transform: capitalize
</style>
