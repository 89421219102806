import Vue from "vue"
import moment from "moment"

Vue.filter("sanitizeNumber", function(value) {
    if (value == "NaN" || value == "Infinity") return 0
    return value
})

Vue.filter("normalizeKey", function(value) {
    let normalizedName = value.replace(/-/g, " ")
    normalizedName = normalizedName.replace(/_/g, " ")
    normalizedName = normalizedName.split(" ")

    for (let i = 0; i < normalizedName.length; i++) {
        normalizedName[i] =
            normalizedName[i].charAt(0).toUpperCase() +
            normalizedName[i].slice(1)
    }

    return normalizedName.join("")
})

Vue.filter("emptyText", function(value) {
    if (
        value == undefined ||
        value.length == 0 ||
        Object.keys(value).length == 0
    ) {
        return "-"
    } else {
        return value
    }
})

Vue.filter("convertToFloat", function(value) {
    if (typeof value == "number") {
        return value.toFixed(2)
    } else if (typeof value == "string") {
        let val = value * 1.0
        return val.toFixed(2)
    } else {
        return "NumberError"
    }
})

Vue.filter("toString", function(value) {
    return String(value)
})

Vue.filter("dosesFormatter", function(value, doses_unit) {
    return value.replace("/", "/" + doses_unit)
})

Vue.filter("toLocaleStringNumber", function(value, style) {
    if (value != undefined && typeof value == "number") {
        switch (style) {
            case "decimal":
                return value.format(2, 3, ".", ",")
            case "currency":
                return "$ " + value.format(0, 3, ".", ",")
            case "percent":
                return (value * 100).format(0, 3, ".", ",") + "%"
            default:
                return value.format(0, 3, ".", ",")
        }
    } else {
        return value
    }
})

Vue.filter("currency", function(value) {
    return "$ " + String(value)
})

Vue.filter("capitalize", function(value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
})

Vue.filter("daysFromToday", function(value, type = "endOf") {
    if (type == "endOf") {
        return moment(value)
            .endOf("day")
            .fromNow()
    }
    if (type == "startOf") {
        return moment(value)
            .startOf("day")
            .fromNow()
    }
})
