import { uniqueId } from "lodash";

const Layout = {
  namespaced: true,
  state: {
    alerts: [],
  },
  mutations: {
    addAlert: (state, item) => {
      state.alerts.push(
        item.id == undefined ? { ...item, id: uniqueId("alert") } : item
      );
    },
    removeAlert: (state, alert) =>
      (state.alerts = state.alerts.filter((i) => i.id != alert.id)),
    setAlerts: (state, alerts) => (state.alerts = alerts),
  },
  getters: {},
  actions: {},
};

export default Layout;
