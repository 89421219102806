<template lang="html">
  <card v-loading="loader" :style="continueProcessCss">
    <template slot="header">
      <card-controls
        :back="true"
        :back-text="backText"
        :back-target-name="backTargetName"
        :back-custom-target="backCustomTarget"
      >
        <span slot="actions">
          <n-button type="primary" simple="" @click.native="goToDiagram">
            <i class="fal fa-project-diagram" />
          </n-button>
          <n-button
            v-if="manageControls && isUpdateTaskAllowed"
            type="primary"
            simple=""
            @click.native="components.setAssignee = true"
          >
            <i class="fal fa-user" />
          </n-button>
          <n-button
            type="primary"
            simple=""
            @click.native="goToWorkflowHistory"
          >
            <i class="fal fa-history" />
          </n-button>
        </span>
      </card-controls>

      <h4 class="card-title">
        <b><span v-html="title" /></b>
      </h4>
      <h5 v-if="subtitle != ''" class="card-subtitle mb-3">
        <span v-html="subtitle" />
      </h5>
    </template>

    <div class="row">
      <div class="offset-lg-1 col-lg-10">
        <generic-form
          v-if="formKey != ''"
          :key="key"
          :form-key="formKey"
          :process-key="processKey"
          :task-id="taskId"
          @variables="setVariables"
          @newTask="initForm"
          @setTitle="setTitle"
          @setSubtitle="setSubtitle"
          @setBackText="setBackText"
          @setBackCustomTarget="setBackCustomTarget"
        />
      </div>
    </div>

    <set-assignee
      v-if="components.setAssignee"
      :task-id="taskId"
      :assignee="assignee.$oid"
      :process-key="processKey"
      @updateTaskList="key += 1"
      @close="components.setAssignee = false"
    />
  </card>
</template>

<script>
import GenericForm from "./GenericForm";
import SetAssignee from "./Components/SetAssignee";

export default {
  components: {
    "generic-form": GenericForm,
    SetAssignee,
  },
  data() {
    return {
      title: "",
      subtitle: "",
      key: 1,
      processKey: undefined,
      formKey: undefined,
      taskId: undefined,
      task: undefined,
      starter: undefined,
      assignee: undefined,
      currentUser: undefined,
      components: {
        bpmnDiagram: false,
        setAssignee: false,
      },
      backText: "Mis Actividades",
      backTargetName: undefined,
      backCustomTarget: {
        name: "tasklist",
        params: { view: "list-view" },
      },
      variables: {},
      actions: [],
    };
  },
  computed: {
    loader() {
      return this.title.length == 0 ? true : false;
    },
    manageControls() {
      let current_user = this.$store.getters["Auth/GET_USER"];
      return this.$HELPERS.hasRole(
        current_user,
        this.$CERES_VARS.data.permissions.supervisors
      );
    },
    isUpdateTaskAllowed() {
      return this.actions.includes("update_task");
    },
    continueProcessCss() {
      let height = this.subtitle == "" ? "200px" : "250px";
      return {
        "min-height": height,
      };
    },
  },
  created() {
    this.currentUser = this.$store.getters["Auth/GET_USER"]._id.$oid;
    this.initForm();
  },
  methods: {
    initForm() {
      this.taskId = this.$route.params.id;
      this.processKey = this.$route.params.processKey;
      this.formKey =
        this.$HELPERS.stringToClassName(this.$route.params.state) +
        this.$HELPERS.stringToClassName(this.$route.params.processKey);
      this.key += 1;
    },
    setTitle(title) {
      this.title = title;
    },
    setSubtitle(text) {
      this.subtitle = text;
    },
    setBackText(value) {
      if (this.$route.params.backText != undefined) {
        this.backText = this.$route.params.backText;
      } else {
        this.backText = value;
      }
    },
    setBackCustomTarget(value) {
      if (this.$route.params.backCustomTarget != undefined) {
        this.backCustomTarget = this.$route.params.backCustomTarget;
      } else {
        this.backCustomTarget = value;
      }
    },
    setVariables(value) {
      this.variables = this.$COPY.copy(value);
      this.starter = this.variables.workflow.starter;
      this.assignee = this.variables.workflow.assignee;
      this.getEvents();
    },
    getEvents() {
      let vm = this;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Processes.getEvents(
        farm_id,
        this.$route.params.processKey,
        this.$route.params.id
      )
        .then((response) => {
          vm.actions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToDiagram() {
      if (this.$route.params.state != undefined) {
        this.$router.push({
          name: "diagram",
          params: {
            processKey: this.$route.params.processKey,
            id: this.$route.params.id,
            state: this.$route.params.state,
            customBackTarget: {
              name: "continue-process",
              params: {
                id: this.$route.params.id,
                processKey: this.$route.params.processKey,
                state: this.$route.params.state,
              },
            },
          },
        });
      } else {
        this.$router.push({
          name: "diagram",
          params: {
            id: this.taskId,
          },
        });
      }
    },
    goToWorkflowHistory() {
      this.$router.push({
        name: "workflow-history",
        params: {
          id: this.$route.params.id,
          processKey: this.$route.params.processKey,
          record: this.variables,
        },
      });
    },
  },
};
</script>

<style lang="sass" scoped></style>
