<template>
  <div class="d-flex flex-column px-md-5 px-sm-1">
    <fg-input
      v-validate="{ required: true }"
      :error="errors.first('name')"
      v-model="model.value.name"
      type="text"
      name="name"
      addon-left-icon="fal fa-building"
      data-vv-as="Nombre del proveedor"
      placeholder="Nombre del proveedor"
    />
    <fg-input
      v-model="model.value.contact_name"
      type="text"
      addon-left-icon="fal fa-user"
      placeholder="Nombre del contacto"
    />
    <fg-input
      v-validate="{ email: true }"
      :error="errors.first('email')"
      v-model="model.value.email"
      name="email"
      type="text"
      addon-left-icon="fal fa-at"
      data-vv-as="Email"
      placeholder="Email"
    />
    <fg-input
      v-model="model.value.phone"
      type="text"
      addon-left-icon="fal fa-phone"
      placeholder="Teléfono"
    />
    <fg-input
      v-validate="{ max: 77 }"
      :error="errors.first('address')"
      v-model="model.value.address"
      name="address"
      type="text"
      addon-left-icon="fal fa-address-card"
      data-vv-as="Dirección"
      placeholder="Dirección"
    />
    <fg-input :error="errors.first('type')" required>
      <el-select
        v-validate="{ required: true }"
        v-model="model.value.category"
        data-vv-as="Categoría"
        placeholder="Seleccione la categoría"
        class="select-primary"
        name="category"
        filterable
      >
        <el-option
          v-for="type in contactTypes"
          :value="type.value"
          :label="type.text"
          :key="type.value"
          class="select-danger"
        />
      </el-select>
    </fg-input>
    <n-button simple type="primary" @click.native="validate">
      <i class="fas fa-save me-1" /> {{ mode == "edit" ? "Guardar" : "Crear" }}
    </n-button>
  </div>
</template>

<script>
import { contactTypes } from "@/constants";

export default {
  props: {
    contact: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: "new",
      validator: (value) => {
        return ["new", "edit"].includes(value);
      },
    },
    newContactSchema: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contactTypes: contactTypes,
      model: {},
    };
  },
  watch: {
    contact: {
      handler: function (newValue, oldValue) {
        this.fillForm(newValue);
      },
      deep: true,
    },
  },
  created() {
    this.fillForm();
  },
  methods: {
    fillForm(contact) {
      if (this.mode == "edit") {
        this.model = contact ? { ...contact } : { ...this.contact };
      } else {
        this.model = { ...this.newContactSchema };
      }
    },
    validate() {
      const vm = this;
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          if (vm.mode == "edit") {
            vm.$emit("updateContact", vm.model);
          } else {
            vm.$emit("createContact", vm.model);
          }
          vm.fillForm();
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.invalid-feedback
  text-align: "left"
  padding-left: 20px
</style>
