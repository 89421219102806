<template lang="html">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-md-6 text-left">
          <fg-input :error="errors.first('date')" class="has-label">
            <label>Fecha de la cosecha<span>*</span></label>
            <el-date-picker
              v-validate="{ required: true }"
              type="date"
              v-model="model.date"
              placeholder="Fecha de la cosecha"
              name="date"
              format="dd-MM-yyyy"
              data-vv-as="Fecha de la cosecha"
              disabled-date="disabledDate"
              :picker-options="{
                disabledDate,
                firstDayOfWeek: 1,
              }"
            />
          </fg-input>
          <div class="fs-sm text-muted mb-3">
            Semana {{ model.date | moment("W") }}
          </div>
        </div>
        <div class="col-md-6 text-left">
          <users-picker v-model="model.workflow.assignee.$oid"></users-picker>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-left">
          <label>Detalle por sector</label>
        </div>
      </div>
      <div
        v-for="(sector, index) in model.sector_harvests"
        :key="index"
        class="row"
      >
        <div class="col-6 text-left">
          <fg-input
            :error="errors.first('sector_harvest_' + index)"
            label="Sector"
            required
          >
            <el-select
              v-validate="{ required: true }"
              v-model="sector.sector_id.$oid"
              data-vv-as="Sector"
              placeholder="Sector"
              :name="'sector_harvest_' + index"
              class="select-primary"
              filterable
              @change="$emit('change', $event)"
            >
              <el-option
                v-for="item in sectors"
                :key="item._id.$oid"
                :value="item._id.$oid"
                :label="getSectorFullName(item)"
                class="select-danger"
              />
            </el-select>
          </fg-input>
        </div>
        <div class="col-5 text-left">
          <fg-input
            v-validate="{ required: true, min_value: 1, decimal: false }"
            v-model.number="sector.qty"
            :name="'qty' + index"
            :error="errors.first('qty' + index)"
            type="number"
            label="Cantidad (kg)"
            placeholder="Cantidad (kg)"
            data-vv-as="Cantidad (kg)"
          />
        </div>
        <div class="col-1 d-flex align-items-center justify-content-end mt-2">
          <n-button
            simple
            size="sm"
            type="danger"
            @click.native="rmSectorHarvest(index)"
          >
            <i class="fas fa-minus-circle" />
          </n-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-right">
          <n-button
            simple=""
            size="sm"
            type="primary"
            @click.native="addSectorHarvest()"
          >
            <i class="fas fa-plus-circle" />
          </n-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <notification :messages="form_messages" :close="true" type="error" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapState } from "vuex";
import { SectorUtils } from "../../../../mixins";

export default {
  mixins: [SectorUtils],
  props: {
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
    mode: {
      type: String,
      default: "workflow",
    },
    messages: {
      type: Object,
      default: () => {
        return {};
      },
    },
    runValidations: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form_messages: {},
      model: {
        date: null,
        sector_harvests: [
          {
            sector_id: {
              $oid: "",
            },
            qty: null,
          },
        ],
        workflow: {
          state: "new",
          starter: {
            $oid: "",
          },
          assignee: {
            $oid: "",
          },
        },
      },
    };
  },
  computed: {
    ...mapState("Farms", { sectors: "sector_options" }),
  },
  watch: {
    runValidations: async function (newValue, oldValue) {
      this.form_messages = {};
      const isValid = await this.$validator.validateAll();
      this.$emit("isValid", isValid, this.model);
    },
  },
  created() {
    if (Object.keys(this.record).length > 0) {
      let model = cloneDeep(this.record);
      if (model.sector_harvests == undefined) {
        model.sector_harvests = [];
      }

      this.model = model;
    } else {
      this.currentUser = this.$store.getters["Auth/GET_USER"];
      this.model.workflow.starter.$oid = this.currentUser._id.$oid;
      this.model.workflow.assignee.$oid = this.currentUser._id.$oid;
    }
  },
  methods: {
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
    async validate() {
      try {
        const isValid = await this.$validator.validateAll();
        if (isValid) this.$emit("save", this.model);
      } catch (error) {
        console.log(error);
      }
    },
    rmSectorHarvest(index) {
      this.model.sector_harvests.splice(index, 1);
    },
    addSectorHarvest() {
      this.model.sector_harvests.push({
        sector_id: { $oid: "" },
        qty: "",
      });
    },
  },
};
</script>
