import Vue from "vue";
import VueI18n from "vue-i18n";
import esLang from "./lang/es.json";
import enLang from "./lang/en.json";

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "es",
  fallbackLocale: "es",
  messages: {
    en: enLang,
    es: esLang,
  },
});

export default i18n;
