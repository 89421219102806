<template lang="html">
  <modal
    :show.sync="modals.resetUserPassword"
    header-classes="justify-content-center"
    @close="$emit('close')"
  >
    <h4 slot="header" class="title title-up">Resetear constraseña</h4>

    <div v-loading="loader">
      <div class="row password">
        <div class="col-md-12 text-left">
          <b
            >Nueva Contraseña para el usuario "{{ model.profile.name }}
            {{ model.profile.lastname }}"</b
          >
        </div>
      </div>
      <user-password-form
        ref="userPasswordForm"
        :strength-validation="true"
        @securePassword="checkSecurePassword"
        @userCredentialsChanged="udpateCredentials"
      />

      <div class="row">
        <div class="col-md-12">
          <div v-if="messages.length > 0" class="text-left">
            <notification
              v-for="(message, key) in messages"
              :key="key"
              :message="message.message"
              :type="message.type"
              :close="true"
            />
          </div>
          <n-button type="primary" @click.native="validate()">
            Resetear contraseña
          </n-button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "nowui/components/Modal";
import Swal from "sweetalert2";
import UserPasswordForm from "./UserPasswordForm";
export default {
  components: {
    Modal,
    UserPasswordForm,
  },
  props: {
    profile: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      modals: {
        resetUserPassword: true,
      },
      loader: false,
      model: {
        profile: {
          id: "",
          firstName: "",
          lastName: "",
          email: "",
        },
        credentials: {
          password: "",
        },
      },
      securePassword: false,
      messages: [],
    };
  },
  created() {
    if (Object.keys(this.profile).length > 0) {
      this.model.profile = this.$COPY.copy(this.profile);
    }
  },
  methods: {
    udpateCredentials(userCredentials) {
      this.model.credentials = userCredentials;
    },
    udpateLoggedUserCredentials(userCredentials) {
      this.loggedUser.credentials = userCredentials;
    },
    validate() {
      let vm = this;
      let validations = {
        userPasswordForm: undefined,
        loggedUserPasswordForm: undefined,
      };
      this.$refs.userPasswordForm.$validator
        .validateAll()
        .then((isValid) => {
          validations.userPasswordForm = isValid;
        })
        .finally(() => {
          vm.resetUserPassword();
        });
    },
    resetUserPassword() {
      let vm = this;
      let payload = this.model.credentials;
      let id = this.model.profile._id.$oid.toString();

      this.loader = true;
      this.messages = [];

      this.Services.Users.updateUser({ id, payload })
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          });
          vm.$emit("close");
        })
        .catch((error) => {
          vm.processMessages(error.response.data.errors, "danger");
        })
        .finally(() => {
          this.loader = false;
        });
    },
    createUser() {
      let vm = this;
      let payload = this.model;
      this.$store
        .dispatch("Users/createUser", { payload })
        .then((response) => {
          console.log(response);
          Swal.fire({
            title: "Usuario creado correctamente",
            icon: "success",
          });
          vm.$emit("updateUserList");
          vm.$emit("close");
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "Ha ocurrido un error, intente nuevamente",
            icon: "danger",
          });
        });
    },
    checkSecurePassword(isSecure) {
      this.securePassword = isSecure;
    },
  },
};
</script>

<style lang="sass" scoped>
.password
  margin-top: 20px
</style>
