<template lang="html">
  <section
    id="contact"
    class="section">
    <div class="container">
      <header class="section-header">
        <small v-html="data.contact_us_title"/>
        <h2 v-html="data.contact_us_description"/>

        <hr >
        <span v-html="data.contact_us_details"/>
      </header>


      <div
        v-loading="loader"
        class="row gap-y">
        <div class="col-md-8 offset-md-2">
          <form
            class="col-lg-10 mx-auto p-6 bg-gray rounded"
            action="../assets/php/sendmail.php"
            method="POST"
            data-form="mailer">

            <div class="form-row">
              <div class="form-group col-md-6">
                <input
                  v-validate="{required: true}"
                  v-model="model.name"
                  data-vv-as="Nombre"
                  class="form-control form-control-lg"
                  type="text"
                  name="name"
                  placeholder="Tu nombre">

                <span
                  v-if="errors.first('name') != ''"
                  class="message-error">{{ errors.first('name') }}</span>
              </div>
              <div class="form-group col-md-6">
                <input
                  v-validate="{required: true,email: true}"
                  v-model="model.email"
                  data-vv-as="Email"
                  class="form-control form-control-lg"
                  type="email"
                  name="email"
                  placeholder="Tu email">
                <span
                  v-if="errors.first('email') != ''"
                  class="message-error">{{ errors.first('email') }}</span>
              </div>
            </div>


            <div class="form-group">
              <textarea
                v-validate="{required: true}"
                v-model="model.message"
                data-vv-as="Mensaje"
                class="form-control form-control-lg"
                rows="4"
                placeholder="Mensaje"
                name="message"/>
              <span
                v-if="errors.first('message') != ''"
                class="message-error">{{ errors.first('message') }}</span>
            </div>
            <div>
              <notification
                v-if="message != ''"
                :message="message"
                :type="type"/>
            </div>
            <div class="text-center">
              <n-button
                type="info"
                @click.native="validate">
                Enviar
              </n-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            loader: false,
            model: {
                name: "",
                email: "",
                message: "",
                recaptcha: undefined
            },
            message: "",
            type: ""
        }
    },
    methods: {
        recaptcha() {
            let vm = this
            this.loader = true
            this.$recaptcha("contact")
                .then(token => {
                    vm.model.recaptcha = token
                    vm.sendContact()
                })
                .catch(error => {
                    vm.loader = false
                })
        },
        validate() {
            let vm = this
            this.$validator.validateAll().then(isValid => {
                if (isValid) vm.recaptcha()
            })
        },
        sendContact() {
            let vm = this
            this.loader = true
            this.Services.Contact.sendContact(this.model)
                .then(response => {
                    vm.message = response.data.message
                    vm.type = "success"
                })
                .catch(error => {
                    vm.type = "error"
                    vm.message = error.response.data.message
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        }
    }
}
</script>

<style lang="css" scoped>
</style>
