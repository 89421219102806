<template lang="html">
  <card v-loading="loader">
    <template slot="header">
      <card-controls :back="true" :back-custom-target="viewRoute">
        <span slot="actions">
          <template v-if="Object.keys(model).length > 0">
            <n-button
              v-if="manageControls"
              type="primary"
              simple=""
              @click.native="components.setAssignee = true"
            >
              <i class="fal fa-user" />
            </n-button>
          </template>
        </span>
      </card-controls>

      <h4 class="card-title">
        <b>Editar Tarea de Mantenimiento del Sistema de Riego</b>
      </h4>
    </template>

    <div class="row">
      <div class="col-md-10 offset-md-1">
        <irrigation-upkeep-form
          v-if="Object.keys(model).length > 0"
          :record="model"
          :record-id="$route.params.id"
          :view-mode="false"
          :messages="messages"
          :run-validations="runValidations"
          @isValid="isValid"
        >
          <template slot="actions">
  <n-button simple="" @click.native="$router.push(viewRoute)">
    Cerrar
  </n-button>
  <n-button type="primary" @click.native="validate()">
    Guardar cambios
  </n-button>
</template>
        </irrigation-upkeep-form>
      </div>
    </div>

    <set-assignee
      v-if="components.setAssignee"
      :task-id="$route.params.id"
      :assignee="model.workflow.assignee.$oid"
      process-key="IrrigationUpkeep"
      @updateTaskList="getRecord"
      @close="components.setAssignee = false"
    />
  </card>
</template>

<script>
import IrrigationUpkeepForm from "@/views/Labors/IrrigationUpkeeps/Components/IrrigationUpkeepForm";
import SetAssignee from "@/views/TaskList/Components/SetAssignee";
import Swal from "sweetalert2";

export default {
  components: {
    IrrigationUpkeepForm,
    SetAssignee,
  },
  props: {
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loader: true,
      farm_id: "",
      model: {},
      messages: {},
      components: {
        setAssignee: false,
      },
      viewRoute: {
        name: "view-irrigation-upkeep",
        params: {
          id: this.$route.params.id,
        },
      },
      runValidations: 0,
    };
  },
  computed: {
    manageControls() {
      return this.$HELPERS.showOptionalControls(
        this.model.workflow.starter.$oid
      );
    },
  },
  created() {
    this.farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
    if (Object.keys(this.record).length > 0) {
      this.model = this.$COPY.copy(this.record);
      this.loader = false;
    } else {
      this.$router.push(this.viewRoute);
    }
  },
  methods: {
    getRecord() {
      let vm = this;
      vm.loader = true;
      vm.model = {};
      this.Services.IrrigationUpkeeps.getIrrigationUpkeep(
        this.farm_id,
        this.$route.params.id
      )
        .then((response) => {
          vm.model = response.data;
        })
        .catch((error) => {
          console.log(error);
          vm.$router.push({ name: "not-found" });
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    validate() {
      this.loader = true;
      this.messages = {};
      this.runValidations += 1;
    },
    isValid(isValid, updatedModel) {
      isValid ? this.updateRecord(updatedModel) : (this.loader = false);
    },
    updateRecord(data) {
      let vm = this;
      this.Services.IrrigationUpkeeps.updateIrrigationUpkeep(
        this.farm_id,
        data._id.$oid,
        data
      )
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          }).then(() => {
            vm.$router.push(vm.viewRoute);
          });
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response.data != "string") {
            vm.messages = error.response.data;
          }
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped></style>
