import Species from "./defines/Species";
import PhenologicalStages from "./defines/PhenologicalStages";
import ServerMessages from "./defines/ServerMessages";
import TaskStates from "./defines/taskStates";
import CeresVariables from "./defines/CeresVariables";

const Defines = {
  install(Vue) {
    Vue.use(Species);
    Vue.use(PhenologicalStages);
    Vue.use(ServerMessages);
    Vue.use(TaskStates);
    Vue.use(CeresVariables);
  },
};

export default Defines;
