import { ceres_api } from "@/axios-config.js"

const PlagueMonitorings = {
    getPlagueMonitorings(farm_id, filter) {
        let endpoint = "/farms/" + farm_id + "/plague_monitorings"
        if (filter == undefined) {
            return ceres_api.get(endpoint)
        } else {
            return ceres_api.post(endpoint + "/filter", { filter: filter })
        }
    },
    createPlagueMonitoring(farm_id, plague_monitoring) {
        let endpoint = "/farms/" + farm_id + "/plague_monitorings"
        return ceres_api.post(endpoint, plague_monitoring)
    },
    getPlagueMonitoring(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/plague_monitorings/"
        return ceres_api.get(endpoint + id)
    },
    updatePlagueMonitoring(farm_id, id, model) {
        let endpoint = "/farms/" + farm_id + "/plague_monitorings/"
        return ceres_api.put(endpoint + id, model)
    },
    deletePlagueMonitoring(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/plague_monitorings/"
        return ceres_api.delete(endpoint + id)
    }
}

export default PlagueMonitorings
