<template lang="html">
  <div class="">
    <div class="col-md-12 col-sm-12 pull-center desktop-table">
      <el-table
        :data="data"
        :empty-text="'No hay cosechas registradas'"
        class="table-striped">

        <el-table-column
          v-if="showStatus"
          prop="status"
          label="Estado"
          align="center"
          name="status"
          column-key="status"
          class-name="table-category">
          <div
            slot-scope="{row}">
            <el-tag
              :closable="false"
              :close-transition="false"
              :type="$TASK_STATES.getColor(row.workflow.state)"
              size="small"
              class="used-product-tag">
              {{ $t(row.workflow.state) }}
            </el-tag>
          </div>
        </el-table-column>

        <el-table-column
          prop="date"
          label="Fecha"
          align="left"
          name="date"
          column-key="date"
          class-name="table-category"
          sortable>

          <div
            slot-scope="{row}">
            <i class="el-icon-time"/> {{ row.date | moment("DD-MM-YYYY") }} ({{ row.date | moment("dddd") }})
          </div>
        </el-table-column>

        <el-table-column
          label="Cuartel"
          name="sector"
          align="center"
          class-name="table-category">

          <div
            slot-scope="{row}">
            <el-tag
              :closable="false"
              :close-transition="false"
              size="small"
              type="primary"
              class="used-product-tag">
              {{ row.sector.name }}
            </el-tag>
          </div>
        </el-table-column>

        <el-table-column
          prop="kg"
          label="Cosecha Estimada (kg)"
          name="kg"
          header-align="center"
          align="right"
          class-name="table-category">
          <div
            slot-scope="{row}">
            {{ row.total_harvest | toLocaleStringNumber("decimal") }}
          </div>
        </el-table-column>

        <el-table-column
          label="Acciones"
          align="center"
          class-name="table-category">
          <div slot-scope="{row}">
            <n-button
              type="primary"
              size="sm"
              @click.native="viewHarvest(row)">
              <i class="fal fa-eye"/>
            </n-button>
          </div>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
import { Table, TableColumn, Scrollbar } from "element-ui"
export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Scrollbar.name]: Scrollbar
    },
    props: {
        data: {
            type: Array,
            default: () => {
                return []
            }
        },
        sectors: {
            type: Array,
            default: () => {
                return []
            }
        },
        showStatus: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            components: {
                deleteHarvest: false,
                sectors: false
            }
        }
    },
    methods: {
        viewHarvest(doc) {
            this.$router.push({
                name: "view-harvest",
                params: {
                    id: doc._id.$oid
                }
            })
        }
    }
}
</script>

<style lang="css">
</style>
