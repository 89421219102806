<template>
  <card>
    <div slot="header">
      <h4 class="card-title"><b>Registro de cambios</b></h4>
    </div>

    <div
      v-for="(version, versionIndex) in changelogs.filter(i => i.releaseDate)"
      :key="versionIndex"
      class="col-lg-10 offset-lg-1 text-left version">
      <h5><b class="version-number">Versión {{ version.versionName }}</b>
      <span class="version-date">({{ version.releaseDate | moment("DD-MM-YYYY") }})</span></h5>
      <div class="logs-wrapper">
        <div 
          v-for="(issues, issueType) in version.issues" 
          :key="issueType" 
          class="logs text-left">
          <template v-if="issues.length > 0">
            <b>{{ $t(issueType) }}</b>
            <ul class="log-list">
              <li
                v-for="(log, logIndex) in issues"
                :key="logIndex"
                class="col-md-12 log">
                {{ log.log }}
              </li>
            </ul>
          </template>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import changelogs from "./logs"

export default {
    data() {
        return {
            changelogs: changelogs
        }
    }
}
</script>

<style lang="sass">
	h5
		padding-top: 10px
		font-size: 20px !important
	.version-number
		padding-right: 10px
	.version-date
		font-size: 18px !important
	.version
		margin-bottom: 10px
		.logs-wrapper
			background-color: #f5f5f5
			padding: 10px
			.logs
				.log
					margin: 5px 0px
	.log-list
		list-style-position: inside
		padding-inline-start: 0px
</style>
