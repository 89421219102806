<template lang="html">
  <card
    v-loading="loader"
  >
    <div slot="header">
      <card-controls
        :back="true"
        :back-target="'/dashboard/product/view/'+$route.params['id']"/>
      <h4 class="card-title"><b>{{ title }}</b></h4>
    </div>
    <product-form
      v-if="dependencies"
      :new-product="false"
      :warehouses="warehouses"
      :product="product"
      :messages="messages"
      @update="update" />

  </card>
</template>

<script>
import ProductForm from "./Components/ProductForm"

export default {
    components: {
        ProductForm
    },
    props: {
        create: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            product: {},
            warehouses: [],
            messages: [],
            title: "Editar Producto",
            loader: true,
            dependencies: false,
            farm: undefined
        }
    },
    created() {
        this.farm = this.$store.getters["Farms/GET_FARM"]
        this.getWarehouses()
    },
    methods: {
        getProduct() {
            let vm = this
            let product_id = this.$route.params.id
            let farm_id = this.farm._id.$oid.toString()

            this.Services.Products.getProduct({ farm_id, product_id })
                .then(response => {
                    vm.product = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                    vm.dependencies = true
                })
        },
        getWarehouses() {
            let vm = this
            let farm_id = this.farm._id.$oid.toString()
            this.Services.Warehouses.getWarehouses(farm_id)
                .then(response => {
                    vm.warehouses = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.getProduct()
                })
        },
        update(payload) {
            let vm = this
            let farm_id = this.farm._id.$oid.toString()
            let product_id = payload.product._id.$oid

            vm.loader = true
            vm.messages = []

            this.Services.Products.updateProduct({
                farm_id,
                product_id,
                payload
            })
                .then(response => {
                    console.log(response)
                    vm.processMessages(response.data.messages, "success")
                })
                .catch(error => {
                    console.log(error)
                    vm.processMessages(error.response.data.errors, "danger")
                })
                .then(() => {
                    vm.loader = false
                })
        }
    }
}
</script>

<style lang="sass">
	.product
		.active-ingredient-control
			cursor: pointer
			font-size: 25px
			margin: 10px 0px 0px 10px
		.form-group
			text-align: left !important

</style>
