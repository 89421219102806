var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loader),expression:"loader"}],staticClass:"col-md-12"},[(!_vm.loader)?_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-12 col-sm-12 pull-center desktop-table"},[_c('el-table',{staticClass:"table-striped",attrs:{"data":_vm.data,"empty-text":'No hay registros',"row-class-name":""}},[_c('el-table-column',{attrs:{"prop":"category","label":"Categoría","name":"category","align":"center","class-name":"capitalize"}}),_c('el-table-column',{attrs:{"prop":"name","label":"Producto","name":"product","align":"center"}}),_c('el-table-column',{attrs:{"label":"Stock Actual","name":"computed_stock","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("toLocaleStringNumber")(row.computed_stock,"decimal"))+" "+_vm._s(row.measure_unit)+" ")])}}],null,false,2130796226)}),_c('el-table-column',{attrs:{"label":"Reservas por aplicaciones programadas","name":"computed_reservations","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("toLocaleStringNumber")(row.computed_reservations,"decimal"))+" "+_vm._s(row.measure_unit)+" ")])}}],null,false,3341882825)}),_c('el-table-column',{attrs:{"label":"Stock Futuro","name":"computed_future_stock","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("toLocaleStringNumber")(row.computed_future_stock,"decimal"))+" "+_vm._s(row.measure_unit)+" ")])}}],null,false,296214904)}),_c('el-table-column',{attrs:{"formatter":_vm.stockShortageFormatter,"label":"Fecha del quiebre de stock","name":"break_date","align":"center","class-name":"table-category"}})],1)],1),_vm._m(1),_c('div',{staticClass:"col-md-12"},[_c('el-table',{staticClass:"table-striped",attrs:{"data":_vm.concatProductApplications,"empty-text":"No hay aplicaciones registradas"}},[_c('el-table-column',{attrs:{"prop":"status","label":"Estado","align":"center","name":"status","column-key":"status","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-tag',{staticClass:"used-product-tag",attrs:{"closable":false,"close-transition":false,"type":_vm.$TASK_STATES.getColor(row.workflow.state),"size":"small"}},[_vm._v(" "+_vm._s(_vm.$t(row.workflow.state))+" ")])],1)}}],null,false,1504213643)}),_c('el-table-column',{attrs:{"prop":"type","label":"Tipo de aplicación","align":"left"}}),_c('el-table-column',{attrs:{"prop":"date","label":"Fecha","align":"center","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('i',{staticClass:"el-icon-time"}),_vm._v(" "+_vm._s(_vm._f("moment")(row.date,"DD-MM-YYYY"))+" "),_c('br'),_vm._v(" ("+_vm._s(_vm._f("moment")(row.date,"dddd"))+") ")])}}],null,false,2657188315)}),_c('el-table-column',{attrs:{"label":"Uso de productos en quiebre de stock futuro","align":"left","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(row.product.name)+" ("+_vm._s(_vm._f("toLocaleStringNumber")(row.used_product.use,"decimal"))+" "+_vm._s(row.product.measure_unit)+") ")])}}],null,false,3551682586)}),_c('el-table-column',{attrs:{"label":"Acciones","align":"center","class-name":"table-category actions-column","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-center"},[_c('n-button',{attrs:{"type":"primary","size":"sm"},nativeOn:{"click":function($event){return _vm.view(row)}}},[_c('i',{staticClass:"fal fa-eye"})])],1)}}],null,false,2552708492)})],1)],1),_vm._m(2),_c('div',{staticClass:"col-md-12"},[_c('el-table',{staticClass:"table-striped",attrs:{"data":_vm.concatPurchases,"empty-text":"No hay compras creadas para éstos productos"}},[_c('el-table-column',{attrs:{"prop":"status","label":"Estado","align":"center","name":"status","column-key":"status","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-tag',{staticClass:"used-product-tag",attrs:{"closable":false,"close-transition":false,"type":_vm.$TASK_STATES.getColor(row.workflow.state),"size":"small"}},[_vm._v(" "+_vm._s(_vm.$t(row.workflow.state))+" ")])],1)}}],null,false,1504213643)}),_c('el-table-column',{attrs:{"prop":"date","label":"Fecha de la compra","align":"center","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('i',{staticClass:"el-icon-time"}),_vm._v(" "+_vm._s(_vm._f("moment")(row.date,"DD-MM-YYYY"))+" "),_c('br'),_vm._v(" ("+_vm._s(_vm._f("moment")(row.date,"dddd"))+") ")])}}],null,false,2657188315)}),_c('el-table-column',{attrs:{"prop":"date","label":"Fecha de entrega","align":"center","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('i',{staticClass:"el-icon-time"}),_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm._f("daysFromToday")(row.arrival_date)))+" ("+_vm._s(_vm._f("moment")(row.date,"DD-MM-YYYY"))+") ")])}}],null,false,1590964829)}),_c('el-table-column',{attrs:{"label":"Producto de interés","name":"products","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.product.name)+" ("+_vm._s(row.purchase_item.qty)+" "+_vm._s(row.product.measure_unit)+") ")])}}],null,false,309100491)}),_c('el-table-column',{attrs:{"label":"Acciones","align":"center","class-name":"table-category actions-column","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-center"},[_c('n-button',{attrs:{"type":"primary","size":"sm"},nativeOn:{"click":function($event){return _vm.view(row)}}},[_c('i',{staticClass:"fal fa-eye"})])],1)}}],null,false,2552708492)})],1)],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 text-left section-title"},[_c('h6',[_vm._v("Productos que presentarán quiebres de stock")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 text-left section-title"},[_c('h6',[_vm._v("Aplicaciones que producirán quiebres de stock")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 text-left section-title"},[_c('h6',[_vm._v("Compras futuras o en proceso de los productos de interés")])])}]

export { render, staticRenderFns }