<template lang="html">
  <div v-loading="loader">
    <product-application-details
      v-if="view_mode"
      :model="init_model">

      <template slot="actions">
        <process-controls
          v-if="Object.keys(variables).length > 0"
          :record="variables"
          @action="processAction"/>
      </template>
    </product-application-details>

    <product-application-form
      v-if="!view_mode"
      ref="InventoryForm"
      :messages="messages"
      :init-model="init_model"
      :run-validations="runValidations"
      @isValid="isValid"
      @dateUpdated="updateDate">
      <template slot="actions">
        <process-controls
          v-if="Object.keys(variables).length > 0"
          :record="variables"
          :is-form="true"
          @action="processAction">
          <template slot="extra-buttons">
            <n-button
              v-if="Object.keys(variables).length == 0 || transitions_with_update.includes(decision)"
              simple=""
              @click.native="view_mode = true">
              Cerrar
            </n-button>
          </template>
        </process-controls>
        <n-button
          v-if="Object.keys(variables).length == 0"
          simple=""
          @click.native="view_mode = true">
          Cerrar
        </n-button>
        <n-button
          v-if="Object.keys(variables).length == 0"
          type="primary"
          @click.native="validate()">
          {{ saveText }}
        </n-button>
      </template>
    </product-application-form>

  </div>
</template>

<script>
import ProductApplicationForm from "@/views/Labors/ProductApplications/ProductApplicationForm"
import ProductApplicationDetails from "@/views/Labors/ProductApplications/ProductApplicationDetails"
import ProcessControls from "@/views/TaskList/Components/ProcessControls"
import ProductApplicationsData from "@/defines/ProductApplications"

export default {
    components: {
        ProductApplicationForm,
        ProductApplicationDetails,
        ProcessControls
    },
    props: {
        variables: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            ProductApplicationsData: ProductApplicationsData,
            title: "Detalles de la aplicación de productos",
            back_text: "Aplicaciones",
            back_custom_target: {
                name: "records-view",
                params: {
                    processKey: "product-applications"
                }
            },
            view_mode: true,
            loader: false,
            runValidations: 1,
            messages: {},
            init_model: {},
            model: {
                date: "",
                observations: {},
                workflow: {
                    state: "new"
                }
            },
            decision: "",
            transitions_with_update: ["update_task"],
            states_which_start_with_form: ["reporting", "new"]
        }
    },
    computed: {
        saveText() {
            if (this.model.workflow.state != "new") {
                return "Guardar cambios"
            } else {
                if (this.$HELPERS.isFuture(this.model.date)) {
                    return "Programar"
                } else if (this.$HELPERS.isPast(this.model.date)) {
                    return "Registrar"
                } else {
                    return "Iniciar"
                }
            }
        }
    },
    created() {
        if (Object.keys(this.variables).length > 0) {
            this.model = this.preProcessVariables()
            this.init_model = this.$COPY.copy(this.model)
        }
        if (
            this.states_which_start_with_form.includes(
                this.model.workflow.state
            )
        ) {
            this.view_mode = false
        }
        this.$emit("setBackText", this.back_text)
        this.$emit("setBackCustomTarget", this.back_custom_target)
        this.$emit("setTitle", this.title)
    },
    methods: {
        preProcessVariables() {
            let settings = this.$store.getters["Settings/GET_SETTINGS"]
            let init_model = this.$COPY.copy(this.variables)
            init_model.sector_ids = init_model.sector_ids.map(i => i.$oid)
            init_model.used_products.forEach((item, index) => {
                init_model.used_products[index].category_id =
                    item.product.category
                init_model.used_products[index].product_measure_unit =
                    item.product.measure_unit

                // calcular input_dose e input_dose_unit en base a los settings. dose y use no deberían cambiar
                let input_dose = item.dose * settings.general.doses_unit.value
                if (["kg", "L"].includes(item.product.measure_unit)) {
                    input_dose *= 1000
                }

                init_model.used_products[index].input_dose = input_dose

                let input_dose_unit = this.$options.filters.dosesFormatter(
                    this.ProductApplicationsData.valid_doses[
                        item.product.measure_unit
                    ],
                    settings.general.doses_unit.value
                )

                init_model.used_products[
                    index
                ].input_dose_unit = input_dose_unit
            })
            return init_model
        },
        processAction(action) {
            this.decision = action
            if (
                this.transitions_with_update.includes(action) &&
                this.view_mode
            ) {
                this.view_mode = false
            } else if (this.view_mode) {
                this.startOrCompleteTask()
            } else {
                this.validate()
            }
        },
        updateDate(date) {
            this.model.date = date
        },
        validate() {
            this.loader = true
            this.messages = {}
            this.runValidations += 1
        },
        isValid(isValid, model) {
            this.model = this.$COPY.copy(model)
            if (this.model.farm_id.$oid == "") {
                this.model.farm_id.$oid = this.$store.getters[
                    "Farms/GET_FARM"
                ]._id.$oid
            }
            isValid ? this.startOrCompleteTask() : (this.loader = false)
        },
        startOrCompleteTask() {
            if (this.model.workflow.hasOwnProperty("state")) {
                if (this.model.workflow.state != "new") {
                    this.completeTask()
                } else {
                    this.start()
                }
            } else {
                this.start()
            }
        },
        start() {
            let vm = this
            this.loader = false
            this.Services.ProductApplications.createProductApplication(
                this.model.farm_id.$oid,
                this.model
            )
                .then(response => {
                    vm.$HELPERS
                        .processServerMessage(response.data.messages)
                        .then(() => {
                            vm.$router.push({
                                name: "records-view",
                                params: {
                                    processKey: "product-applications"
                                }
                            })
                        })
                })
                .catch(error => {
                    console.log(error)
                    if (typeof error.response.data != "string") {
                        vm.messages = error.response.data
                    }
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        completeTask() {
            this.$emit(
                "completeTask",
                this.$route.params.processKey,
                this.$route.params.id,
                this.model,
                this.decision
            )
            this.loader = false
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
