<template lang="html">
  <irrigation-upkeep-form
    v-loading="loader"
    :messages="messages"
    :run-validations="runValidations"
    @isFuture="updateTexts"
    @isValid="isValid"
  >
    <div slot="actions">
      <n-button simple="" @click.native="$router.go(-1)"> Cerrar </n-button>
      <n-button type="primary" @click.native="validate()">
        {{ saveText }}
      </n-button>
    </div>
  </irrigation-upkeep-form>
</template>

<script>
import Swal from "sweetalert2";
import IrrigationUpkeepForm from "@/views/Labors/IrrigationUpkeeps/Components/IrrigationUpkeepStartForm";

export default {
  components: {
    IrrigationUpkeepForm,
  },
  props: {
    processKey: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      loader: false,
      saveText: "Registrar",
      messages: {},
      runValidations: 0,
      model: {},
    };
  },
  created() {
    this.updateTexts(false);
  },
  methods: {
    updateTexts(isFuture) {
      if (isFuture) {
        this.$emit("setTitle", "Programar Mantención Sistema de Riego");
        this.saveText = "Programar";
      } else {
        this.$emit("setTitle", "Registrar Mantención Sistema de Riego");
        this.saveText = "Registrar";
      }
    },
    validate() {
      this.loader = true;
      this.messages = {};
      this.runValidations += 1;
    },
    isValid(result, model) {
      result ? this.start(model) : (this.loader = false);
    },
    start(data) {
      let vm = this;
      let model = this.$COPY.copy(data);

      // if (!vm.$HELPERS.isFuture(model.date)) {
      //     model.irrigation_upkeep_sectors = model.irrigation_upkeep_sectors.reduce(
      //         (out, i) => {
      //             // i.checked = true
      //             out.push(i)
      //             return out
      //         },
      //         []
      //     )
      // }

      this.Services.IrrigationUpkeeps.createIrrigationUpkeep(
        model.farm_id,
        model
      )
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          }).then(() => {
            vm.$router.push({ name: "irrigation-upkeeps" });
          });
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response.data != "string") {
            vm.messages = error.response.data;
          }
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
};
</script>

<style lang="css" scoped></style>
