<template lang="html">
  <div>
    <div class="row">
      <div class="col-md-12 text-left setting-subsection">Generales</div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 1, decimal: false }"
          v-model.number="model.numOfObservations.value"
          :error="errors.first('numOfObservations')"
          name="numOfObservations"
          required
          type="number"
          label="Número de observaciones por cuartel"
          placeholder="Número de observaciones por cuartel"
          data-vv-as="Número de observaciones por cuartel"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-left setting-subsection">
        Plagas y enfermedades
      </div>
      <div class="col-md-12">
        <el-table :data="activePlages" class="products-table">
          <el-table-column
            label="Nombre científico"
            prop="value.scientificName"
          >
            <div slot-scope="scope">
              <fg-input
                v-validate="{
                  required: true,
                  unique: inputs.plagues.uniqueCount,
                }"
                v-model="scope.row.value.scientificName"
                :name="'scientificName' + scope.$index"
                :error="errors.first('scientificName' + scope.$index)"
                type="text"
                data-vv-as="Nombre científico"
                placeholder="Nombre científico"
                class="italic"
              />
            </div>
          </el-table-column>
          <el-table-column label="Nombre común" prop="value.commonName">
            <div slot-scope="scope">
              <fg-input
                v-model="scope.row.value.commonName"
                type="text"
                placeholder="Nombre Común"
              />
            </div>
          </el-table-column>
          <el-table-column label="Tolerancia" prop="value.tolerance">
            <div slot-scope="scope">
              <fg-input
                v-validate="{ required: true, min_value: 0, decimal: false }"
                v-model.number="scope.row.value.tolerance"
                :error="errors.first('tolerance_' + scope.$index)"
                :name="'tolerance_' + scope.$index"
                :key="'tolerance_' + scope.$index"
                required
                type="number"
                placeholder="Tolerancia"
                data-vv-as="Tolerancia"
              />
            </div>
          </el-table-column>
          <el-table-column label="Acciones" align="right" fixed="right">
            <div slot-scope="scope" class="controls">
              <n-button
                simple=""
                type="danger"
                size="sm"
                @click.native="
                  handleDelete('plagues', scope.row, 'scientificName')
                "
              >
                <i class="fas fa-minus-circle" />
              </n-button>
              <n-button
                v-if="isLastRow(scope, 'plagues')"
                simple=""
                size="sm"
                type="primary"
                class="ms-1"
                @click.native="addLine('plagues')"
              >
                <i class="fas fa-plus-circle" />
              </n-button>
            </div>
          </el-table-column>
        </el-table>
      </div>
      <div class="col-md-12 text-right">
        <n-button
          v-if="activePlages.length == 0"
          simple=""
          size="sm"
          type="primary"
          @click.native="addLine('plagues')"
        >
          <i class="fas fa-plus-circle" />
        </n-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-left setting-subsection">
        Monitoreo de trampas
      </div>
      <predefinedTracking
        key="predefinedPlagueTracking"
        :settings="settings"
        :sectors="sectors"
        :run-validations="runValidations"
        class="col-md-12"
        setting-category="plaguesTracking"
        setting-key="predefinedPlagueTracking"
        @updatedModel="updateModel"
        @isValid="updateValidation"
      />
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import { Validator } from "vee-validate";
import PredefinedTracking from "./Components/PredefinedTracking";
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    PredefinedTracking,
  },
  props: {
    settings: {
      type: Object,
      default: () => {
        return {};
      },
    },
    sectors: {
      type: Array,
      default: () => {
        return [];
      },
    },
    runValidations: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        plagues: {
          uniqueCount: [],
          config: {
            uniqueKey: "scientificName",
            focusRef: "scientificName",
            category: "plaguesTracking",
            key: "plagues",
            type: "array",
          },
          value: {
            scientificName: "",
            commonName: "",
            tolerance: "",
          },
        },
      },
      model: {
        numOfObservations: {
          value: 10,
          key: "numOfObservations",
          category: "plaguesTracking",
          global: true,
          type: "value",
        },
        plagues: [],
        predefinedPlagueTracking: [],
      },
      validations: {
        plaguesTracking: "",
        predefinedTracking: "",
      },
    };
  },
  computed: {
    activePlages() {
      return this.model.plagues.filter((i) => i.delete == undefined);
    },
  },
  watch: {
    runValidations: function (newValue, oldValue) {
      let vm = this;
      if (newValue) {
        Object.keys(this.validations).forEach((key) => {
          vm.validations[key] = "";
        });
        this.$validator.validateAll().then((isValid) => {
          vm.validations.plaguesTracking = isValid;
        });
      }
    },
    validations: {
      handler: function (newValue, oldValue) {
        let vm = this;
        if (Object.values(newValue).every((i) => typeof i == "boolean")) {
          let isValid = Object.values(newValue).every((i) => i);
          vm.$emit("updatedModel", vm.model);
          vm.$emit("updateValidation", isValid, "plaguesTracking");
        }
      },
      deep: true,
    },
    model: {
      handler: function (newValue, oldValue) {
        let values = newValue.plagues.map((i) => {
          return i.value.scientificName.toLowerCase().trim();
        });
        this.inputs.plagues.uniqueCount = values.reduce((agg, i) => {
          agg[i] = (agg[i] || 0) + 1;
          return agg;
        }, {});
      },
      deep: true,
    },
  },
  created() {
    if (this.settings.plaguesTracking != undefined) {
      if (this.settings.plaguesTracking.plagues) {
        this.model.plagues = this.$COPY.copy(
          this.settings.plaguesTracking.plagues
        );
      }

      if (this.settings.plaguesTracking.numOfObservations) {
        this.model.numOfObservations = this.$COPY.copy(
          this.settings.plaguesTracking.numOfObservations
        );
      }
    }
  },
  mounted() {
    const isUnique = (value, uniqueCount) => {
      let isValid = uniqueCount[value.toLowerCase().trim()] == 1 ? true : false;
      return {
        valid: isValid,
        data: {
          message: "El nombre científico debe ser único",
        },
      };
    };
    Validator.extend("unique", {
      validate: isUnique,
      getMessage: (field, params, data) => {
        return data.message;
      },
    });
  },
  methods: {
    addLine(form) {
      this.model[form].push({
        value: this.$COPY.copy(this.inputs[form].value),
        global: true,
        category: this.inputs[form].config.category,
        key: this.inputs[form].config.key,
        type: this.inputs[form].config.type,
      });
    },
    isLastRow(scope, form) {
      return this.model[form].length - 1 == scope.$index ? true : false;
    },
    handleDelete(form, item, uniqueKey) {
      let model = this.$COPY.copy(this.model[form]);
      let newData = model.filter((i) => {
        if (uniqueKey != undefined) {
          return i.value[uniqueKey] != item.value[uniqueKey];
        } else {
          return i.value != item.value;
        }
      });
      if (item._id != undefined) {
        item.delete = true;
        newData.push(item);
      }
      this.model[form] = this.$COPY.copy(newData);
    },
    updateModel(newModel, formKey) {
      this.model[formKey] = this.$COPY.copy(newModel);
    },
    updateValidation(isValid, formKey) {
      this.validations[formKey] = isValid;
    },
  },
};
</script>

<style lang="sass" scoped>
.input-after-table
    margin-top: 10px
.italic
input
    font-style: italic !important
</style>
