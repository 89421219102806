import moment from "moment";
import store from "@/store";
import Swal from "sweetalert2";

let Helpers = {
  computeSectors(sectors) {
    for (let i in sectors) {
      if (sectors[i].subsector != true) {
        sectors[i].subsectors = this.findSubSectors(sectors[i], sectors);
      }
    }

    return sectors;
  },
  findSubSectors(sector, sectors) {
    let subsectors = [];
    for (let i in sectors) {
      if (sectors[i].parent_sector != undefined) {
        if (
          sectors[i].parent_sector.$oid.toString() == sector._id.$oid.toString()
        ) {
          subsectors.push(sectors[i]);
        }
      }
    }

    return subsectors;
  },
  generateFileUrl(file) {
    let base64 = new Buffer(file, "binary").toString("base64");
    base64 = "data:;base64," + base64;
    return base64;
  },
  pick(obj, keys) {
    return keys
      .map((k) => (k in obj ? { [k]: obj[k] } : {}))
      .reduce((res, o) => Object.assign(res, o), {});
  },
  isFuture(date) {
    let today = new Date();
    return moment(date).isAfter(today, "day");
  },
  isPast(date) {
    let today = new Date();
    return moment(date).isBefore(today, "day");
  },
  isToday(date) {
    let today = new Date();
    return moment(date).isSame(today, "day");
  },
  getMinMaxDates(myArray, dateProp) {
    return myArray.reduce((out, item) => {
      out.min =
        out.min === undefined || new Date(item[dateProp]) < new Date(out.min)
          ? item[dateProp]
          : out.min;
      out.max =
        out.max === undefined || new Date(item[dateProp]) > new Date(out.max)
          ? item[dateProp]
          : out.max;
      return out;
    }, {});
  },
  getSectorNames(modelSectors, sectors) {
    let targetSectors;
    if (Array.isArray(modelSectors)) {
      targetSectors = modelSectors.map((i) => i.toString());
    } else {
      targetSectors = modelSectors.toString();
    }
    return sectors
      .reduce((sectorList, sector) => {
        if (targetSectors.includes(sector._id.$oid)) {
          sectorList.push(sector.name);
        }
        return sectorList;
      }, [])
      .join(", ");
  },
  getSeasonDates(today, target) {
    let modifier = undefined;
    if (target == "current") {
      modifier = 1;
    } else if (target == "last") {
      modifier = 2;
    }
    const start = new Date();
    start.setMonth(8, 1);
    if (start > today) {
      start.setFullYear(start.getFullYear() - modifier);
    }
    const end = new Date();
    end.setFullYear(start.getFullYear() + 1);
    end.setMonth(7, 31);
    return {
      start: start,
      end: end,
    };
  },
  beforeImageUpload(file) {
    const isJPG = file.type === "image/jpeg";
    const isLt2M = file.size / 1024 / 1024 < 2;
    const isPNG = file.type === "image/png";

    if (!isJPG && !isPNG) {
      // this.$message.error('La imagen debe estar en formato JPG o PNG');
    }
    if (!isLt2M) {
      // this.$message.error('La imagen excede los 2MB!');
    }

    return (isJPG || isPNG) && isLt2M;
  },
  compressSettings(settings) {
    return settings.reduce((out, item) => {
      if (out[item.category] == undefined) out[item.category] = {};
      switch (item.type) {
        case "array":
          if (out[item.category][item.key] != undefined) {
            out[item.category][item.key].push(item);
          } else {
            out[item.category][item.key] = [item];
          }
          try {
            out[item.category][item.key] = out[item.category][item.key].sort(
              (a, b) => {
                if (a.attr.order > b.attr.order) return -1;
                if (a.attr.order < b.attr.order) return 1;
                if (a.attr.order == b.attr.order) return 0;
              }
            );
          } catch (error) {
            // empty
          }
          break;
        default:
          // == "value"
          out[item.category][item.key] = item;
      }
      return out;
    }, {});
  },
  decompressSettings(settings) {
    return Object.values(settings).reduce((out, item) => {
      if (Array.isArray(item)) {
        item.forEach((i) => {
          out.push(i);
        });
      } else {
        out.push(item);
      }
      return out;
    }, []);
  },
  composeMongoQuery(query) {
    Object.keys(query).forEach((key) => {
      let deleteKey = false;
      if (query[key].dateRange != undefined) {
        if (query[key].dateRange[0] != undefined) {
          query[key] = {
            $gte: query[key].dateRange[0],
            $lte: query[key].dateRange[1],
          };
        } else {
          deleteKey = true;
        }
      }
      if (query[key].$in != undefined) {
        if (query[key].$in.length == 0) {
          deleteKey = true;
        }
      }
      if (query[key].dateRange == undefined && query[key].$gte != undefined) {
        if (query[key].$gte == "") {
          Object.keys(query[key]).length > 1
            ? delete query[key].$gte
            : (deleteKey = true);
        }
      }
      if (query[key].dateRange == undefined && query[key].$lte != undefined) {
        if (query[key].$lte == "") {
          Object.keys(query[key]).length > 1
            ? delete query[key].$lte
            : (deleteKey = true);
        }
      }
      if (deleteKey) delete query[key];
    });
    return query;
  },
  findAndgetUserFullName(userId, userList) {
    let full_name = "CERES";
    if (userList != undefined && userList.length > 0) {
      let user = userList.find((i) => i._id.$oid == userId);

      if (user != undefined) full_name = user.full_name;
    }
    return full_name;
  },
  contentToFile(file) {
    let base64URL = "data:";
    base64URL += file.content_type + ";";
    base64URL += "base64,";
    base64URL += file.content;

    return base64URL;
  },
  hasRole(user, roles) {
    if (!Array.isArray(roles)) roles = [roles];
    return user.roles.some((i) => roles.includes(i.role_id));
  },
  classNameToString(workflow_name) {
    let url_name = "";
    let isUppercase = (char) => (char == char.toUpperCase() ? true : false);
    workflow_name.split("").forEach((char) => {
      if (url_name.length == 0 && isUppercase(char)) {
        url_name += char.toLowerCase();
      } else if (isUppercase(char)) {
        url_name += "-" + char.toLowerCase();
      } else {
        url_name += char;
      }
    });
    return url_name;
  },
  stringToClassName(url_name) {
    let workflow_name = "";
    let nextToUppercase = false;
    url_name.split("").forEach((char) => {
      if (workflow_name.length == 0) {
        workflow_name += char.toUpperCase();
      } else if (["-", "_"].includes(char)) {
        nextToUppercase = true;
      } else if (nextToUppercase) {
        workflow_name += char.toUpperCase();
        nextToUppercase = false;
      } else {
        workflow_name += char;
      }
    });
    return workflow_name;
  },
  groupLaborRecords(records) {
    const doneStates = ["done", "approved", "rejected"];
    let groups = {
      in_progress: records.filter(
        (i) => ["scheduled", ...doneStates].indexOf(i.workflow.state) == -1
      ),
      scheduled: records.filter((i) => i.workflow.state == "scheduled"),
      done: records.filter((i) => doneStates.includes(i.workflow.state)),
    };
    return groups;
  },
  showOptionalControls(starter) {
    let user = store.getters["Auth/GET_USER"];
    let isAdmin = store.getters["Auth/IS_ADMIN"];
    return starter == user._id.$oid || isAdmin;
  },
  isSupervisor() {
    let isSupervisor = store.getters["Auth/IS_SUPERVISOR"];
    return isSupervisor;
  },
  isAdmin() {
    return store.getters["Auth/IS_ADMIN"];
  },
  processServerMessage(data, type = "success") {
    const schema = {
      title: "",
      icon: type,
    };
    if (data.hasOwnProperty("title")) {
      schema.title = data.title;
      schema.text = data.description;
    } else {
      schema.title = data;
    }
    return Swal.fire(schema);
  },
  deepSearch(theObject, targetProp, targetValue, outputProp) {
    const getObject = (theObject, targetProp, targetValue, outputProp) => {
      let result = null;
      if (theObject instanceof Array) {
        for (let i = 0; i < theObject.length; i++) {
          result = getObject(theObject[i], targetProp, targetValue, outputProp);
          if (result) {
            break;
          }
        }
      } else {
        for (let prop in theObject) {
          if (prop == targetProp) {
            if (theObject[prop] == targetValue) {
              return theObject[outputProp];
            }
          }
          if (
            theObject[prop] instanceof Object ||
            theObject[prop] instanceof Array
          ) {
            result = getObject(
              theObject[prop],
              targetProp,
              targetValue,
              outputProp
            );
            if (result) {
              break;
            }
          }
        }
      }
      return result;
    };

    return getObject(theObject, targetProp, targetValue, outputProp);
  },
  sortByDate(object, field, dateFormat, returnMoment = true) {
    let objectCopy = [...object];
    objectCopy.forEach((item) => {
      if (!moment.isDate(item[field]) || !moment.isMoment(item[field])) {
        if (dateFormat != undefined) {
          item[field] = moment(item[field], dateFormat);
        } else {
          item[field] = moment(item[field]);
        }
      }

      if (returnMoment) {
        item[field] = moment.isMoment(item[field])
          ? item[field]
          : moment(item[field]);
      } else {
        item[field] = item[field].toDate();
      }
    });

    return objectCopy.sort((a, b) => a[field] - b[field]);
  },
  downloadFile({ filename, content, content_type }) {
    const linkSource = "data:" + content_type + ";base64," + content;
    const downloadLink = document.createElement("a");
    const fileName = filename;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  },
};
export default Helpers;
