<template lang="html">
  <div>
    <card v-loading="loader" class="col-md-12">
      <div slot="header">
        <h4 class="card-title">
          <b>{{ title }}</b>
        </h4>
      </div>

      <tabs
        type="primary"
        tab-content-classes="col-md-12 px-5"
        tab-nav-wrapper-classes="col-md-12 settings-nav"
        square
        centered
        class="row settings"
      >
        <tab-pane class="col-md-12" label="general">
          <span slot="label">
            <i class="now-ui-icons ui-2_settings-90" />General
          </span>
          <general-settings
            v-if="showComponents"
            :settings="settings"
            :messages="messages"
            @save="setSettings"
          />
        </tab-pane>
        <tab-pane class="col-md-12" label="workflows">
          <span slot="label"> <i class="fal fa-cogs" />Actividades </span>
          <activities-settings
            v-if="showComponents"
            :messages="messages"
            @save="setSettings"
          ></activities-settings>
        </tab-pane>
        <tab-pane class="col-md-12" label="contacts">
          <span slot="label"> <i class="fal fa-handshake" />Contactos </span>
          <contact-settings
            v-if="showComponents"
            :settings="settings"
            :messages="messages"
            @save="setSettings"
            @loader="setLoader"
          />
        </tab-pane>
        <tab-pane class="col-md-12" label="parameters">
          <span slot="label"> <i class="fal fa-sliders-h" />Parámetros </span>
          <parameter-settings
            v-if="showComponents"
            :settings="settings"
            :messages="messages"
            @save="setSettings"
            @loader="setLoader"
          />
        </tab-pane>
        <tab-pane class="col-md-12" label="users">
          <span slot="label"> <i class="fal fa-users" />Usuarios </span>
          <user-management
            v-if="showComponents"
            :settings="settings"
            :messages="messages"
            :farms="farms"
            @save="setSettings"
          />
        </tab-pane>
        <tab-pane class="col-md-12" label="users">
          <span slot="label"> <i class="fal fa-ballot-check" />Permisos </span>
          <permissions-settings
            v-if="showComponents"
            :settings="settings"
            :messages="messages"
            :farms="farms"
            @save="setSettings"
          />
        </tab-pane>
      </tabs>
    </card>
  </div>
</template>

<script>
import { Tabs, TabPane } from "nowui/components";
import GeneralSettings from "./GeneralSettings";
import ParameterSettings from "./ParameterSettings";
import ContactSettings from "./Contacts";
import UserManagement from "./Users/UserManagement";
import PermissionsSettings from "./Permissions/PermissionsSettings.vue";
import ActivitiesSettings from "./ActivitiesSettings.vue";

export default {
  components: {
    Tabs,
    TabPane,
    GeneralSettings,
    ParameterSettings,
    ContactSettings,
    UserManagement,
    PermissionsSettings,
    ActivitiesSettings,
  },
  data() {
    return {
      title: "Configuración",
      loader: true,
      settings: {},
      showComponents: false,
      messages: [],
      farms: [],
    };
  },
  created() {
    this.getSettings();
  },
  methods: {
    setLoader(value) {
      this.loader = value;
    },
    getFarms() {
      let vm = this;
      this.Services.Farms.getFarms()
        .then((response) => {
          vm.farms = response.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.showComponents = true;
          vm.loader = false;
        });
    },
    getSettings() {
      let vm = this;
      this.showComponents = false;

      this.Services.Settings.getSettings()
        .then((response) => {
          let settings = response.data;

          if (settings == null) {
            vm.settings = {};
          } else {
            vm.settings = vm.$HELPERS.compressSettings(settings);
            vm.$store.commit("Settings/SET_SETTINGS", vm.settings);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.getFarms();
        });
    },
    setSettings(settings) {
      let vm = this;
      let payload = {};

      payload.setting = this.$HELPERS.decompressSettings(settings);

      vm.loader = true;
      vm.messages = [];

      this.Services.Settings.setSettings(payload)
        .then((response) => {
          console.log(response);
          vm.$HELPERS.processServerMessage(response.data.messages);
          vm.getSettings();
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          vm.processMessages(error.response.data.errors, "danger");
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
};
</script>

<style lang="sass">
.settings
  .settings-nav
    padding-bottom: 20px
    border-bottom: 1px solid #ebecf1
    .nav-item
      .nav-link
        height: unset !important
    .nav-item a
      min-width: 130px !important
.top-border
  padding-top: 20px !important
  margin-top: 20px
  border-top: 1px solid #efefefef
.settings-actions
  margin-top: 30px
  @extend .top-border
.setting-section
  text-transform: uppercase
  font-weight: bold !important
  font-size: 1.2em !important
  margin-bottom: 16px
.setting-subsection
  font-size: 1em !important
  font-weight: bold
  margin: 30px 0px 10px 0px !important
</style>
