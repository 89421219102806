<template lang="html">
  <div v-loading="loaders.farm">
    <card>
      <div slot="header">
        <h4 class="card-title text-left">Campo: {{ farm.name }}</h4>
        <div class="pull-right header-button">
          <n-button
            type="default"
            simple=""
            @click.native="$router.push('/dashboard/farms')"
          >
            <i class="fa fa-chevron-left" />
            Volver
          </n-button>
          <n-button
            v-if="$store.getters['Auth/IS_ADMIN']"
            type="primary"
            @click.native="
              $router.push('/dashboard/farm/edit/' + farm._id.$oid.toString())
            "
          >
            <i class="fas fa-edit" />
            Editar Campo
          </n-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 text-left">
          Ubicación del campo
          <br />
          <br />
          <google-maps
            v-if="components.googleMaps"
            :polygon="computedGeoreference"
            :editable="false"
          />
        </div>
        <div class="col-md-6 text-left">
          <div class="row">
            <div class="col-md-12 text-left farm-logo">
              <img :src="farm_logo" width="200px" />
            </div>
            <div class="col-md-12 text-left">
              <label>Nombre del campo</label>
              <br />
              <label
                ><b>{{ farm.name }}</b></label
              >
            </div>

            <div class="col-md-6 text-left">
              <label>Razón social</label>
              <br />
              <label
                ><b>{{ farm.business_name }}</b></label
              >
            </div>

            <div class="col-md-6 text-left">
              <label>RUT</label>
              <br />
              <label
                ><b>{{ farm.business_id }}</b></label
              >
            </div>

            <div class="col-md-12 text-left">
              <label>Dirección del campo</label>
              <br />
              <label
                ><b>{{ farm.address }}</b></label
              >
            </div>

            <div class="col-md-6 text-left">
              <label>Tamaño del campo (ha)</label>
              <br />
              <label v-if="farm.area != undefined"
                ><b>{{ farm.area }}</b></label
              >
            </div>

            <div class="col-md-6 text-left">
              <label>Cantidad de cuarteles</label>
              <br />
              <label
                ><b>{{ num_of_sectors }}</b></label
              >
            </div>
            <div class="col-md-6 text-left">
              <label>Prepicitación total de la finca</label>
              <br />
              <label v-if="farm.pp_farm_sum != undefined"
                ><b>{{ farm.pp_farm_sum.toFixed(2) }} m<sup>3</sup>/h</b></label
              >
            </div>
            <div class="col-md-6 text-left">
              <label>Prepicitación Promedio de la finca</label>
              <br />
              <label v-if="farm.pp_farm_avg != undefined"
                ><b>{{ farm.pp_farm_avg.toFixed(2) }} m<sup>3</sup>/h</b></label
              >
            </div>
          </div>
        </div>
      </div>
    </card>

    <card>
      <tabs type="primary">
        <tab-pane label="Cuarteles">
          <list-sectors
            v-if="components.listSectors"
            :farm="farm"
            @setNumOfSectors="setNumOfSectors"
            @updateFarm="getFarm()"
          />
        </tab-pane>
        <tab-pane label="Bodegas">
          <warehouses v-if="components.listWarehouses" :farm="farm" />
        </tab-pane>
      </tabs>
    </card>

    <!-- Edit farm modal -->
    <!-- <modal
      v-if="modals.editFarm"
      :show.sync="modals.editFarm"
      header-classes="justify-content-center"
      class="farm-modal">
      <h4
        slot="header"
        class="title title-up">Editar "{{ farm.name }}"</h4>
      <edit-farm
        ref="editFarm"
        :farm="farm"
        :show-footer="false"
        class="no-card-style"
        @farmUpdate="updateFarm"/>
      <template slot="footer">
        <n-button @click.native="modals.editFarm = false">Cerrar</n-button>

        <div>
          <n-button
            type="danger"
            @click.native="deleteFarm()">
            Eliminar
          </n-button>
          &nbsp;
          <n-button
            type="primary"
            @click.native="$refs.editFarm.validate()">Actualizar</n-button>
        </div>
      </template>
    </modal> -->

    <delete-farm
      v-if="components.deleteFarm"
      :farm="farm"
      @close="components.deleteFarm = false"
      @farmDeleted="$router.push('/dashboard/farms')"
    />
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import { Tabs, TabPane } from "nowui/components";
import GoogleMaps from "@/Components/GoogleMaps";
import EditFarm from "./EditFarm";
import DeleteFarm from "./DeleteFarm";
import ListSectors from "./Sectors/ListSectors";
import Warehouses from "./Warehouses/Warehouses";
import Modal from "nowui/components/Modal";

export default {
  components: {
    EditFarm,
    DeleteFarm,
    ListSectors,
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    GoogleMaps,
    Tabs,
    TabPane,
    Warehouses,
  },
  data() {
    return {
      components: {
        deleteFarm: false,
        googleMaps: false,
        sectors: true,
        listSectors: false,
        listWarehouses: false,
      },
      modals: {
        editFarm: false,
      },
      loaders: {
        farm: true,
      },
      farm: {},
      farm_logo: undefined,
      num_of_sectors: 0,
    };
  },
  computed: {
    computedFarmLogo() {
      return "background-image: url('" + this.farm_logo + "')";
    },
    computedGeoreference() {
      if (this.farm.georeference != undefined) {
        return this.farm.georeference;
      } else if (this.farm.map != undefined) {
        if (this.farm.map.polygon != undefined) {
          return this.farm.map.polygon;
        }
      }

      return [];
    },
  },
  created() {
    this.getFarm();
  },
  methods: {
    editSector() {
      // this.modals.sector = true
    },
    updateSector(sector) {
      console.log(sector);
    },
    updateFarm() {
      this.modals.editFarm = false;
      this.getFarm();
    },
    getFarm() {
      let vm = this;

      this.loaders.farm = true;
      this.components.listSectors = false;
      this.components.listWarehouses = false;

      this.Services.Farms.getFarm(this.$route.params.id)
        .then((response) => {
          vm.farm = response.data;
          vm.components.googleMaps = true;
          vm.components.listSectors = true;
          vm.components.listWarehouses = true;
          vm.getFarmLogo();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.loaders.farm = false;
        });
    },
    deleteFarm() {
      this.modals.editFarm = false;
      this.components.deleteFarm = true;
    },
    getFarmLogo() {
      let vm = this;
      let file_id = this.farm.logo;

      if (file_id != undefined) {
        this.Services.Files.getFile(file_id)
          .then((response) => {
            vm.farm_logo = vm.$HELPERS.contentToFile(response.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    },
    setNumOfSectors(value) {
      let counter = 0;
      for (let i in value) {
        if (value[i].subsector != true) {
          counter++;
        }
      }

      this.num_of_sectors = counter;
    },
  },
};
</script>

<style lang="sass">
.sector-modal,.farm-modal
  .modal-dialog
    max-width: 700px
.section-header
  margin-bottom: 10px
  h5
    margin-top: 20px
.farm-logo
  margin-bottom: 20px
</style>
