var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"offset-md-8 col-md-4 col-sm-12 text-left"},[_c('fg-input',{attrs:{"type":"text","placeholder":"Buscar..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('el-table',{staticClass:"table-striped products-table",attrs:{"data":_vm.filteredProducts,"empty-text":'Sin productos',"row-class-name":_vm.productRowClassName}},[_c('el-table-column',{attrs:{"filters":_vm.computedBrands,"filter-method":_vm.filterBrand,"prop":"brand","label":"Marca","sortable":""}}),_c('el-table-column',{staticClass:"text-left",attrs:{"prop":"name","label":"Nombre comercial","sortable":""}}),_c('el-table-column',{staticClass:"text-left",attrs:{"prop":"use_mode","label":"Modo de uso","sortable":""}}),_c('el-table-column',{attrs:{"prop":"active_ingredient","label":"Ingrediente activo","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-left"},_vm._l((row.active_ingredient),function(active_ingredient,key){return _c('span',{key:key},[_vm._v(" "+_vm._s(active_ingredient.value)+" "),_c('br')])}),0)}}])}),_c('el-table-column',{attrs:{"label":"Stock real","sortable":"","align":"right","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("toLocaleStringNumber")(row.computed_stock,"decimal"))+" "+_vm._s(row.measure_unit)+" ")])}}])}),_c('el-table-column',{attrs:{"label":"Stock reservado","align":"right","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("toLocaleStringNumber")(row.computed_reservations,"decimal"))+" "+_vm._s(row.measure_unit)+" ")])}}])}),_c('el-table-column',{attrs:{"label":"Stock Futuro","align":"right","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-tooltip',{attrs:{"open-delay":300,"content":"Stock Futuro = Stock real + Compras - Stock reservado ","placement":"top","effect":"dark"}},[_c('div',[_vm._v(" "+_vm._s(_vm._f("toLocaleStringNumber")(row.computed_future_stock,"decimal"))+" "+_vm._s(row.measure_unit)+" ")])])],1)}}])}),_c('el-table-column',{attrs:{"label":"Acciones","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"controls"},[_c('n-button',{attrs:{"type":"primary","size":"sm"},nativeOn:{"click":function($event){return _vm.viewProduct(row)}}},[_c('i',{staticClass:"fal fa-eye"})])],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }