import VueI18n from "vue-i18n";
import Cookies from "vue-cookie";
import {
  Input,
  InputNumber,
  Tooltip,
  Popover,
  Option,
  OptionGroup,
  Select,
  Upload,
  DatePicker,
  TimeSelect,
  Tag,
  Table,
  TableColumn,
  Scrollbar,
  Button,
  ButtonGroup,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
} from "element-ui";

import fgInput from "../vendors/vue-nud-pro-html-v1.0.0/src/components/Inputs/formGroupInput.vue";
import DropDown from "../vendors/vue-nud-pro-html-v1.0.0/src/components/Dropdown";
import Card from "../vendors/vue-nud-pro-html-v1.0.0/src/components/Cards/Card";
import StatsCard from "../vendors/vue-nud-pro-html-v1.0.0/src/components/Cards/StatsCard";
import NowUIButton from "../vendors/vue-nud-pro-html-v1.0.0/src/components/Button";
import Checkbox from "../vendors/vue-nud-pro-html-v1.0.0/src/components/Inputs/Checkbox";
import BarChart from "../vendors/vue-nud-pro-html-v1.0.0/src/components/Charts/BarChart";
import Modal from "../vendors/vue-nud-pro-html-v1.0.0/src/components/Modal";
import SideBar from "@/Components/SidebarPlugin";
import vClickOutside from "v-click-outside";
import Radio from "../vendors/vue-nud-pro-html-v1.0.0/src/components/Inputs/Radio";
import Badge from "@/Components/Badge";
import VueRut from "vue-rut";
import VueMoment from "vue-moment";
import momentTimezone from "moment-timezone";

// Element Ui Config
import lang from "element-ui/lib/locale/lang/es";
import locale from "element-ui/lib/locale";
locale.use(lang);

// CERES Components
import Notification from "@/Components/Notification";
import CardControls from "@/Components/CardControls";
import BaseDatePicker from "./Components/BaseDatePicker.vue";
import BaseSelect from "./Components/BaseSelect.vue";
import BaseInput from "./Components/BaseInput.vue";
import UsersPicker from "./Components/UsersPicker.vue";
import SectorsPicker from "./Components/SectorsPicker.vue";

// Moment Config
const moment = require("moment");
require("moment/locale/es");

// GlobalCompoentns
const GlobalComponents = {
  install(Vue) {
    Vue.component("fg-input", fgInput);
    Vue.component("drop-down", DropDown);
    Vue.component("card", Card);
    Vue.component("stats-card", StatsCard);
    Vue.component("n-button", NowUIButton);
    Vue.component(Input.name, Input);
    Vue.component(InputNumber.name, InputNumber);
    Vue.component(Select.name, Select);
    Vue.component(Option.name, Option);
    Vue.component(OptionGroup.name, OptionGroup);
    Vue.component(Checkbox.name, Checkbox);
    Vue.component(BarChart.name, BarChart);
    Vue.component(Radio.name, Radio);
    Vue.component(Upload.name, Upload);
    Vue.component("notification", Notification);
    Vue.component("card-controls", CardControls);
    Vue.component(Badge.name, Badge);
    Vue.component(Table.name, Table);
    Vue.component(TableColumn.name, TableColumn);
    Vue.component(Scrollbar.name, Scrollbar);
    Vue.component(Modal.name, Modal);
    Vue.component(Tooltip.name, Tooltip);
    Vue.component(Button.name, Button);
    Vue.component(ButtonGroup.name, ButtonGroup);
    Vue.component(Carousel.name, Carousel);
    Vue.component(CarouselItem.name, CarouselItem);
    Vue.component(Collapse.name, Collapse);
    Vue.component(CollapseItem.name, CollapseItem);
    Vue.component("base-date-picker", BaseDatePicker);
    Vue.component("base-select", BaseSelect);
    Vue.component("base-input", BaseInput);
    Vue.component("users-picker", UsersPicker);
    Vue.component("sectors-picker", SectorsPicker);
    Vue.use(Popover);
    Vue.use(VueI18n);
    Vue.use(SideBar);
    Vue.use(vClickOutside);
    Vue.use(DatePicker);
    Vue.use(TimeSelect);
    Vue.use(Tag);
    Vue.use(VueRut);
    Vue.use(Cookies);
    Vue.use(VueMoment, { moment, momentTimezone });
  },
};

export default GlobalComponents;
