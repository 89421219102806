import { mapGetters } from "vuex"

const workflowFormHandler = {
    props: {
        record: {
            type: Object,
            default: () => {}
        },
        runValidations: {
            type: Boolean,
            required: true
        }
    },
    computed: { ...mapGetters({ currentUserId: "Auth/GET_USER_ID" }) },
    watch: {
        runValidations: function(newValue, oldValue) {
            if (newValue) this.validate()
        }
    },
    created() {
        this.$emit("setTitle", this.title)
        if (Object.keys(this.record).length > 1) {
            this.model = this.$clone(this.record)
        }
    },
    methods: {
        async validate() {
            const isValid = await this.$validator.validateAll()
            const processedModel = await this.beforeSubmit()
            this.$emit("isValid", isValid, processedModel)
        },
        async beforeSubmit() {
            return this.model
        }
    }
}

export default workflowFormHandler
