import Vue from "vue";
import axios from "@/axios-config.js";
import Vuex from "vuex";

import Auth from "./Auth";
import Farms from "./Farms";
import Settings from "./Settings";
import Users from "./Users";
import Processes from "./Processes";
import Layout from "./Layout";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    version: "",
    browser: "",
  },
  mutations: {
    SET_USER: function (state, value) {
      console.log(value);
      state.user = value;
    },
    SET_VERSION: function (state, value) {
      state.version = value;
    },
    SET_BROWSER(state, value) {
      state.browser = value;
    },
  },
  getters: {
    GET_VERSION(state) {
      return state.version;
    },
    BROWSER_IS_SUPPORTED(state) {
      return ["chrome", "firefox"].includes(state.browser);
    },
  },
  actions: {
    getVariable(context, payload) {
      let endpoint =
        "/history/variable-instance?processInstanceId=" +
        payload["process_id"] +
        "&variableName=" +
        payload["variable_name"] +
        "&deserializeValues=false";
      return axios.get(endpoint);
    },
    getFile(context, payload) {
      return axios.get(
        "/history/variable-instance/" + payload.file_id + "/data",
        {
          responseType: "arraybuffer",
        }
      );
    },
    getTaskFile(context, payload) {
      let endpoint;

      if (payload.taskType == "history") {
        endpoint =
          "/history/task/" +
          payload.taskId +
          "/variables/" +
          payload.fileName +
          "/data";
      } else {
        endpoint =
          "/task/" +
          payload.taskId +
          "/variables/" +
          payload.fileName +
          "/data";
      }

      return axios.get(endpoint, {
        responseType: "arraybuffer",
      });
    },
  },
  modules: {
    Auth,
    Farms,
    Settings,
    Users,
    Processes,
    Layout,
  },
});
