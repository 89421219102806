const countries = [
    { value: "AF", name: "Afganistán" },
    { value: "AL", name: "Albania" },
    { value: "DZ", name: "Argelia" },
    { value: "AS", name: "Samoa Americana" },
    { value: "AD", name: "Andorra" },
    { value: "AO", name: "Angola" },
    { value: "AI", name: "Anguilla" },
    { value: "AQ", name: "Antártida" },
    { value: "AG", name: "Antigua y Barbuda" },
    { value: "AR", name: "Argentina" },
    { value: "AM", name: "Armenia" },
    { value: "AW", name: "Aruba" },
    { value: "AU", name: "Australia" },
    { value: "AT", name: "Austria" },
    { value: "AZ", name: "Azerbaiyán" },
    { value: "BS", name: "Bahamas" },
    { value: "BH", name: "Bahrein" },
    { value: "BD", name: "Bangladesh" },
    { value: "BB", name: "Barbados" },
    { value: "BY", name: "Bielorrusia" },
    { value: "BE", name: "Bélgica" },
    { value: "BZ", name: "Belice" },
    { value: "BJ", name: "Benin" },
    { value: "BM", name: "Bermudas" },
    { value: "BT", name: "Bután" },
    { value: "BO", name: "Bolivia" },
    { value: "BA", name: "Bosnia y Herzegovina" },
    { value: "BW", name: "Botswana" },
    { value: "BV", name: "Isla Bouvet" },
    { value: "BR", name: "Brasil" },
    { value: "IO", name: "Territorios británicos del océano Índico" },
    { value: "BN", name: "Brunei" },
    { value: "BG", name: "Bulgaria" },
    { value: "BF", name: "Burkina Faso" },
    { value: "BI", name: "Burundi" },
    { value: "KH", name: "Camboya" },
    { value: "CM", name: "Camerún" },
    { value: "CA", name: "Canadá" },
    { value: "CV", name: "Cabo Verde" },
    { value: "KY", name: "Islas Caimán" },
    { value: "CF", name: "República Centroafricana" },
    { value: "TD", name: "Chad" },
    { value: "CL", name: "Chile" },
    { value: "CN", name: "China" },
    { value: "CX", name: "Isla de Christmas" },
    { value: "CC", name: "Islas de Cocos o Keeling" },
    { value: "CO", name: "Colombia" },
    { value: "KM", name: "Comores" },
    { value: "CG", name: "Congo" },
    { value: "CD", name: "Congo, República Democrática del" },
    { value: "CK", name: "Islas Cook" },
    { value: "CR", name: "Costa Rica" },
    { value: "CI", name: "Costa de Marfíl" },
    { value: "HR", name: "Croacia (Hrvatska)" },
    { value: "CU", name: "Cuba" },
    { value: "CY", name: "Chipre" },
    { value: "CZ", name: "República Checa" },
    { value: "DK", name: "Dinamarca" },
    { value: "DJ", name: "Djibouti" },
    { value: "DM", name: "Dominica" },
    { value: "DO", name: "República Dominicana" },
    { value: "TP", name: "Timor Oriental" },
    { value: "EC", name: "Ecuador" },
    { value: "EG", name: "Egipto" },
    { value: "SV", name: "El Salvador" },
    { value: "GQ", name: "Guinea Ecuatorial" },
    { value: "ER", name: "Eritrea" },
    { value: "EE", name: "Estonia" },
    { value: "ET", name: "Etiopía" },
    { value: "FK", name: "Islas Malvinas" },
    { value: "FO", name: "Islas Faroe" },
    { value: "FJ", name: "Fiji" },
    { value: "FI", name: "Finlandia" },
    { value: "FR", name: "Francia" },
    { value: "GF", name: "Guayana Francesa" },
    { value: "PF", name: "Polinesia Francesa" },
    { value: "TF", name: "Territorios franceses del Sur" },
    { value: "GA", name: "Gabón" },
    { value: "GM", name: "Gambia" },
    { value: "GE", name: "Georgia" },
    { value: "DE", name: "Alemania" },
    { value: "GH", name: "Ghana" },
    { value: "GI", name: "Gibraltar" },
    { value: "GR", name: "Grecia" },
    { value: "GL", name: "Groenlandia" },
    { value: "GD", name: "Granada" },
    { value: "GP", name: "Guadalupe" },
    { value: "GU", name: "Guam" },
    { value: "GT", name: "Guatemala" },
    { value: "GN", name: "Guinea" },
    { value: "GW", name: "Guinea-Bissau" },
    { value: "GY", name: "Guayana" },
    { value: "HT", name: "Haití" },
    { value: "HM", name: "Islas Heard y McDonald" },
    { value: "HN” selected >Honduras" },
    { value: "HK", name: "Hong Kong" },
    { value: "HU", name: "Hungría" },
    { value: "IS", name: "Islandia" },
    { value: "IN", name: "India" },
    { value: "ID", name: "Indonesia" },
    { value: "IR", name: "Irán" },
    { value: "IQ", name: "Irak" },
    { value: "IE", name: "Irlanda" },
    { value: "IL", name: "Israel" },
    { value: "IT", name: "Italia" },
    { value: "JM", name: "Jamaica" },
    { value: "JP", name: "Japón" },
    { value: "JO", name: "Jordania" },
    { value: "KZ", name: "Kazajistán" },
    { value: "KE", name: "Kenia" },
    { value: "KI", name: "Kiribati" },
    { value: "KR", name: "Corea" },
    { value: "KP", name: "Corea del Norte" },
    { value: "KW", name: "Kuwait" },
    { value: "KG", name: "Kirguizistán" },
    { value: "LA", name: "Laos" },
    { value: "LV", name: "Letonia" },
    { value: "LB", name: "Líbano" },
    { value: "LS", name: "Lesotho" },
    { value: "LR", name: "Liberia" },
    { value: "LY", name: "Libia" },
    { value: "LI", name: "Liechtenstein" },
    { value: "LT", name: "Lituania" },
    { value: "LU", name: "Luxemburgo" },
    { value: "MO", name: "Macao" },
    { value: "MG", name: "Madagascar" },
    { value: "MW", name: "Malawi" },
    { value: "MY", name: "Malasia" },
    { value: "MV", name: "Maldivas" },
    { value: "ML", name: "Malí" },
    { value: "MT", name: "Malta" },
    { value: "MH", name: "Islas Marshall" },
    { value: "MQ", name: "Martinica" },
    { value: "MR", name: "Mauritania" },
    { value: "MU", name: "Mauricio" },
    { value: "YT", name: "Mayotte" },
    { value: "MX", name: "México" },
    { value: "FM", name: "Micronesia" },
    { value: "MD", name: "Moldavia" },
    { value: "MC", name: "Mónaco" },
    { value: "MN", name: "Mongolia" },
    { value: "MS", name: "Montserrat" },
    { value: "MA", name: "Marruecos" },
    { value: "MZ", name: "Mozambique" },
    { value: "MM", name: "Birmania" },
    { value: "NA", name: "Namibia" },
    { value: "NR", name: "Nauru" },
    { value: "NP", name: "Nepal" },
    { value: "AN", name: "Antillas Holandesas" },
    { value: "NL", name: "Países Bajos" },
    { value: "NC", name: "Nueva Caledonia" },
    { value: "NZ", name: "Nueva Zelanda" },
    { value: "NI", name: "Nicaragua" },
    { value: "NE", name: "Níger" },
    { value: "NG", name: "Nigeria" },
    { value: "NU", name: "Niue" },
    { value: "NF", name: "Norfolk" },
    { value: "MP", name: "Islas Marianas del Norte" },
    { value: "NO", name: "Noruega" },
    { value: "OM", name: "Omán" },
    { value: "PK", name: "Paquistán" },
    { value: "PW", name: "Islas Palau" },
    { value: "PA", name: "Panamá" },
    { value: "PG", name: "Papúa Nueva Guinea" },
    { value: "PY", name: "Paraguay" },
    { value: "PE", name: "Perú" },
    { value: "PH", name: "Filipinas" },
    { value: "PN", name: "Pitcairn" },
    { value: "PL", name: "Polonia" },
    { value: "PT", name: "Portugal" },
    { value: "PR", name: "Puerto Rico" },
    { value: "QA", name: "Qatar" },
    { value: "RE", name: "Reunión" },
    { value: "RO", name: "Rumania" },
    { value: "RU", name: "Rusia" },
    { value: "RW", name: "Ruanda" },
    { value: "SH", name: "Santa Helena" },
    { value: "KN", name: "Saint Kitts y Nevis" },
    { value: "LC", name: "Santa Lucía" },
    { value: "PM", name: "St. Pierre y Miquelon" },
    { value: "VC", name: "San Vicente y Granadinas" },
    { value: "WS", name: "Samoa" },
    { value: "SM", name: "San Marino" },
    { value: "ST", name: "Santo Tomé y Príncipe" },
    { value: "SA", name: "Arabia Saudí" },
    { value: "SN", name: "Senegal" },
    { value: "SC", name: "Seychelles" },
    { value: "SL", name: "Sierra Leona" },
    { value: "SG", name: "Singapur" },
    { value: "SK", name: "República Eslovaca" },
    { value: "SI", name: "Eslovenia" },
    { value: "SB", name: "Islas Salomón" },
    { value: "SO", name: "Somalia" },
    { value: "ZA", name: "República de Sudáfrica" },
    { value: "ES", name: "España" },
    { value: "LK", name: "Sri Lanka" },
    { value: "SD", name: "Sudán" },
    { value: "SR", name: "Surinam" },
    { value: "SJ", name: "Islas Svalbard y Jan Mayen" },
    { value: "SZ", name: "Suazilandia" },
    { value: "SE", name: "Suecia" },
    { value: "CH", name: "Suiza" },
    { value: "SY", name: "Siria" },
    { value: "TW", name: "Taiwán" },
    { value: "TJ", name: "Tayikistán" },
    { value: "TZ", name: "Tanzania" },
    { value: "TH", name: "Tailandia" },
    { value: "TG", name: "Togo" },
    { value: "TK", name: "Islas Tokelau" },
    { value: "TO", name: "Tonga" },
    { value: "TT", name: "Trinidad y Tobago" },
    { value: "TN", name: "Túnez" },
    { value: "TR", name: "Turquía" },
    { value: "TM", name: "Turkmenistán" },
    { value: "TC", name: "Islas Turks y Caicos" },
    { value: "TV", name: "Tuvalu" },
    { value: "UG", name: "Uganda" },
    { value: "UA", name: "Ucrania" },
    { value: "AE", name: "Emiratos Árabes Unidos" },
    { value: "UK", name: "Reino Unido" },
    { value: "US", name: "Estados Unidos" },
    { value: "UM", name: "Islas menores de Estados Unidos" },
    { value: "UY", name: "Uruguay" },
    { value: "UZ", name: "Uzbekistán" },
    { value: "VU", name: "Vanuatu" },
    { value: "VA", name: "Ciudad del Vaticano (Santa Sede)" },
    { value: "VE", name: "Venezuela" },
    { value: "VN", name: "Vietnam" },
    { value: "VG", name: "Islas Vírgenes (Reino Unido)" },
    { value: "VI", name: "Islas Vírgenes (EE.UU.)" },
    { value: "WF", name: "Islas Wallis y Futuna" },
    { value: "YE", name: "Yemen" },
    { value: "YU", name: "Yugoslavia" },
    { value: "ZM", name: "Zambia" },
    { value: "ZW", name: "Zimbabue" }
]

export default countries
