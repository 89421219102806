<template lang="html">
  <irrigation-upkeep-form
    v-if="Object.keys(model).length > 0"
    :record="model"
    :record-id="$route.params.id"
    :view-mode="false"
    :messages="messages"
    :get-model="getModel"
    @updateModel="updateModel">
    <template slot="actions">
      <process-controls
        v-if="Object.keys(model).length > 0"
        :record="model"
        :is-form="false"
        @action="submit"/>
    </template>
  </irrigation-upkeep-form>
</template>

<script>
import IrrigationUpkeepForm from "@/views/Labors/IrrigationUpkeeps/Components/IrrigationUpkeepForm"
import ProcessControls from "@/views/TaskList/Components/ProcessControls"

export default {
    components: {
        IrrigationUpkeepForm,
        ProcessControls
    },
    props: {
        variables: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            loader: false,
            messages: {},
            getModel: 1,
            model: {},
            decision: ""
        }
    },
    created() {
        this.$emit(
            "setTitle",
            "Realizar tarea de mantenimiento del sistema de riego"
        )
        this.model = this.$COPY.copy(this.variables)
    },
    methods: {
        updateModel(newModel) {
            this.messages = {}
            this.model = this.$COPY.copy(newModel)
        },
        submit(decision) {
            this.loader = true
            this.getModel += 1
            this.$emit(
                "completeTask",
                this.$route.params.processKey,
                this.$route.params.id,
                this.model,
                decision
            )
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
