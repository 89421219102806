import Vue from "vue"

const TaskStates = {
    getColor(state) {
        switch (state) {
            case "done":
                return "success"
            case "scheduled":
                return "default"
            case "waiting_supervisor":
                return "warning"
            default:
                return "primary"
        }
    },
    install() {
        Object.defineProperty(Vue.prototype, "$TASK_STATES", {
            get() {
                return TaskStates
            }
        })
    }
}

export default TaskStates
