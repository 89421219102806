<template lang="html">
  <modal
    :show.sync="modals.newWorker"
    header-classes="justify-content-center"
    class="newWorkerModal"
    @close="close"
  >
    <h4 v-if="!edit" slot="header" class="title title-up">
      Nuevo trabajador(a)
    </h4>
    <h4 v-else slot="header" class="title title-up">Modificar trabajador(a)</h4>
    <div v-loading="loaders.newWorker" class="row">
      <div class="col-md-4 text-left">
        <div class="profile-picture">
          <picture-uploader
            :picture="models.worker.profile_picture"
            preview-picture-title="Fotografía del trabajador(a)"
            @pictureProcessed="attachPicture"
          />
        </div>
        <div class="col-md-12 text-center" />
      </div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-6">
            <fg-input
              v-validate="{ required: true }"
              :error="errors.first('name')"
              v-model.number="models.worker.name"
              label="Nombre"
              placeholder="Nombre"
              name="name"
              class="text-left"
            />
          </div>
          <div class="col-md-6">
            <fg-input
              v-validate="{ required: true }"
              :error="errors.first('lastname')"
              v-model.number="models.worker.lastname"
              label="Apellidos"
              placeholder="Apellidos"
              name="lastname"
              class="text-left"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group text-left">
              <label>Nacionalidad</label>
              <el-select
                v-model="models.worker.country"
                class="select-primary"
                placeholder="País de origen"
                filterable
              >
                <el-option
                  v-for="(country, index) in countries"
                  :key="index"
                  :value="country.value"
                  :label="country.name"
                  class="select-primary"
                />
              </el-select>
            </div>
          </div>
          <div class="col-md-6">
            <fg-input
              v-model="models.worker.national_id"
              :label="'Rut'"
              placeholder="Rut"
              class="text-left"
            />
          </div>
          <div class="col-md-6">
            <div class="form-group text-left">
              <label> Tipo de contrato</label>
              <el-select
                v-model="models.worker.contract"
                class="select-primary"
                collapse-tags=""
                placeholder="Tipo de contrato"
              >
                <el-option
                  v-for="(type, key) in contract_types"
                  :value="type"
                  :label="type"
                  :key="key"
                  class="select-primary"
                />
              </el-select>
            </div>
          </div>

          <div class="col-md-6 text-left">
            <fg-input :label="'Fecha de nacimiento'">
              <el-date-picker
                v-model.number="models.worker.birthday"
                format="dd-MM-yyyy"
                type="date"
                placeholder="Fecha de nacimiento"
                class="text-left"
              />
            </fg-input>
          </div>
          <div class="col-md-12">
            <fg-input
              v-model.number="models.worker.job_position"
              :label="'Cargo'"
              placeholder="Cargo"
              class="text-left"
            />
          </div>
          <div class="col-md-12">
            <fg-input
              v-model.number="models.worker.address"
              :label="'Dirección'"
              placeholder="Dirección"
              class="text-left"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group text-left">
              <label>Habilidades</label>
              <el-select
                v-model="models.worker.skills"
                multiple=""
                class="select-primary"
                placeholder="Seleccionar habilidades"
              >
                <el-option
                  v-for="(skill, key) in skills"
                  :value="skill"
                  :label="skill"
                  :key="key"
                  class="select-primary"
                />
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <notification :messages="messages" :close="true" type="error" />
      </div>
    </div>

    <template slot="footer">
      <n-button simple="" @click.native="close()"> Cerrar </n-button>
      <n-button
        v-if="!edit"
        :disabled="disableNewWorker"
        type="primary"
        @click.native="validate()"
        >Nuevo trabajador(a)</n-button
      >

      <div v-if="edit">
        <n-button type="danger" @click.native="deleteWorker()"
          >Dar de baja</n-button
        >
        &nbsp;
        <n-button type="primary" @click.native="editWorker()"
          >Modificar trabajador(a)</n-button
        >
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "nowui/components/Modal";
import Swal from "sweetalert2";
import PictureUploader from "@/Components/PictureUploader";
import { countries } from "@/constants";

export default {
  components: {
    Modal,
    PictureUploader,
  },
  props: {
    worker: {
      type: Object,
      default: () => {
        return undefined;
      },
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countries: countries,
      loaders: {
        newWorker: false,
      },
      modals: {
        newWorker: true,
      },
      components: {
        deleteWorker: false,
      },
      skills: [
        "Podador",
        "Injertador",
        "Tractorista",
        "Aplicador con bomba espalda",
        "Desmalezador",
        "Cosechador",
        "Seleccionador post cosecha",
      ],
      contract_types: ["Planta", "Medio Tiempo", "Honorarios", "Jornalero"],
      models: {
        worker: {
          name: "",
          lastname: "",
          country: "",
          national_id: "",
          contract: "",
          birthday: "",
          address: "",
          profile_picture: "",
          job_position: "",
          skills: [],
        },
      },
      messages: {},
    };
  },
  computed: {
    disableNewWorker() {
      return this.loaders.newWorker;
    },
  },
  created() {
    if (this.worker != undefined)
      this.models.worker = this.$COPY.copy(this.worker);
  },
  methods: {
    close() {
      this.modals.newWorker = false;
      this.$emit("close");
    },
    attachPicture(picture) {
      this.models.worker.profile_picture = picture;
    },
    validate() {
      let vm = this;
      this.loaders.newWorker = true;
      this.messages = {};
      this.$validator.validateAll().then((isValid) => {
        isValid ? vm.newWorker() : (vm.loaders.newWorker = false);
      });
    },
    newWorker() {
      let vm = this;
      let worker = this.models.worker;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Workers.createWorker({ farm_id, worker })
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          });
          vm.close();
          vm.$emit("newWorker");
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response.data != "string") {
            vm.messages = error.response.data.errors;
          }
        })
        .finally(() => {
          vm.loaders.newWorker = false;
        });
    },
    editWorker() {
      let vm = this;
      vm.loaders.newWorker = true;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      let worker = this.models.worker;
      this.Services.Workers.updateWorker({ farm_id, worker })
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          });
          vm.close();
          vm.$emit("editWorker");
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response.data != "string") {
            vm.messages = error.response.data.errors;
          }
        })
        .finally(() => {
          vm.loaders.newWorker = false;
        });
    },
    deleteWorker() {
      let vm = this;
      Swal.fire({
        title:
          "¿Estás seguro de dar de baja al trabajador(a) " +
          this.worker.full_name +
          "?",
        text: "¡La accción no podrá revertirse!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No,<br/>Quiero conservarl@",
        confirmButtonText: "Si,<br/>Quiero darle de baja",
        reverseButtons: true,
        focusCancel: true,
        cancelButtonClass: "primary",
        confirmButtonClass: "danger",
      }).then((result) => {
        if (result.value) vm.removeWorker();
      });
    },
    removeWorker() {
      let vm = this;
      vm.loaders.newWorker = true;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      let worker_id = this.worker._id.$oid;
      this.Services.Workers.deleteWorker({ farm_id, worker_id })
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          });
          vm.$emit("workerDeleted");
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response.data != "string") {
            vm.messages = error.response.data.errors;
          }
        })
        .finally(() => {
          vm.loaders.newWorker;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.newWorkerModal
  .modal-dialog
    max-width: 750px
</style>
