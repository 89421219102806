<template>
  <div class="text-left">
    <fg-input :error="errors.first('assignee')" :label="label" required>
      <el-select
        v-validate="{ required: true }"
        v-if="users.length > 0"
        v-model="selectedUser"
        :data-vv-as="label"
        :placeholder="label"
        name="assignee"
        class="select-primary"
        filterable
        @change="$emit('onChange', $event)"
      >
        <el-option
          v-for="(user, index) in users"
          :value="user._id.$oid"
          :label="user.full_name"
          :key="index"
          class="select-danger"
        />
      </el-select>
    </fg-input>
  </div>
</template>

<script>
import { getUsers } from "../../../mixins";
export default {
  name: "UserSelect",
  mixins: [getUsers],
  props: {
    defaultUser: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Responsable",
    },
  },
  data() {
    return {
      selectedUser: "",
    };
  },
  created() {
    if (this.defaultUser) this.selectedUser = this.defaultUser;
    if (this.selectedUser) this.$emit("onChange", this.selectedUser);
  },
};
</script>

<style></style>
