<template lang="html">
  <div class="row">
    <div class="col-md-12">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-info-circle"/></div>
                <h5 class="info-title"><span>{{ $t(model.workflow.state) }}</span></h5>
                <h6 class="stats-title">Estado</h6>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-calendar"/></div>
                <h5
                  v-if="model.hasOwnProperty('date')"
                  class="info-title">
                  <span>{{ model.date | moment("DD-MM-YYYY") }}</span>
                </h5>
                <h6 class="stats-title">Fecha</h6>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-leaf"/></div>
                <h5 class="info-title"><span>{{ model.work_coverage | toLocaleStringNumber }}</span></h5>
                <h6 class="stats-title">Plantas desmalezadas</h6>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-coins"/></div>
                <h5 class="info-title"><span>{{ model.total | toLocaleStringNumber | currency }}</span></h5>
                <h6 class="stats-title">Costo CLP (IVA INCL.) </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div
          class="col-md-12 col-sm-12 text-left">
          <h5>Detalles del control de malezas</h5>
          <p>Cuarteles: <el-tag
            v-for="sector in model.sectors"
            :key="sector._id.$oid"
            :closable="false"
            :close-transition="false"
            size="small"
            type="primary"
            class="used-product-tag">
            {{ sector.name }}
          </el-tag></p>
          <p>Costo/planta: <b>{{ model.cost_per_unit | toLocaleStringNumber | currency }}</b></p>
        </div>
      </div>


      <div class="row">
        <div class="col-md-12 col-sm-12 text-left">
          <h5>Colaboradores</h5>
          <div>
            <el-tag
              v-for="worker in model.workers"
              :key="worker._id.$oid"
              :closable="false"
              :close-transition="false"
              size="small"
              type="primary"
              class="used-product-tag">
              {{ worker.full_name }}
            </el-tag>
          </div>
          <div
            v-if="model.workers.length == 0"
            class="col-md-12 text-center text-muted">
            <p>Aún no se han agregado desmalezadores</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <cost-summary :record="model" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-center mt-3">
          <slot name="actions"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CostSummary from "@/views/Labors/Components/CostSummary"

export default {
    components: {
        CostSummary
    },
    props: {
        model: {
            type: Object,
            default: () => {
                return {}
            }
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
