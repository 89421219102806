import { ceres_api } from "@/axios-config.js"

const LabReports = {
    getLabReports(farm_id, filter) {
        let endpoint = "/farms/" + farm_id + "/lab_reports"
        if (filter == undefined) {
            return ceres_api.get(endpoint)
        } else {
            return ceres_api.post(endpoint + "/filter", { filter: filter })
        }
    },
    getLabReport(farm_id, lab_report_id) {
        let endpoint = "/farms/" + farm_id + "/lab_reports/" + lab_report_id
        return ceres_api.get(endpoint)
    },
    getCategories(farm_id) {
        const endpoint = "/farms/" + farm_id + "/lab_reports/categories"
        return ceres_api.get(endpoint)
    },
    createLabReport({ farm_id, payload }) {
        let endpoint = "/farms/" + farm_id + "/lab_reports"
        return ceres_api.post(endpoint, payload)
    }
}

export default LabReports
