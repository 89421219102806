<template lang="html">
  <div
    v-loading="loader">
    <div
      v-if="render & settings != undefined"
      class="col-md-12">
      <div class="row">
        <div class="col-md-6 offset-md-3 text-left">
          <fg-input
            :error="errors.first('date')"
            required
            label="Fecha del monitoreo">
            <el-date-picker
              v-validate="{required: true}"
              v-model="model.date"
              data-vv-as="Fecha del monitoreo"
              name="date"
              format="dd-MM-yyyy"
              type="date"
              placeholder="Fecha del monitoreo"/>
          </fg-input>
        </div>
        <div class="col-md-6 offset-md-3 text-left">
          <div class="form-group has-label">
            <div class="row">
              <div class="col-md-5">
                <label>Cuartel(es) <span>*</span></label>
              </div>
              <div class="col-md-7 text-right">
                <label>
                  <a
                    class="show-pointer"
                    @click="selectAllSectors()">Seleccionar todos</a> | <a
                      class="show-pointer"
                      @click="clearSectorSelection()">Limpiar selección</a>
                </label>
              </div>
            </div>
          </div>
          <fg-input
            :error="errors.first('sector')"
            required>
            <el-select
              v-validate="{required: true}"
              v-model="model.sector_ids"
              data-vv-as="Cuarteles"
              name="sector"
              multiple=""
              class="select-primary"
              placeholder="Cuarteles"
              filterable>
              <el-option
                v-for="(sector,key) in sectors"
                :value="sector._id.$oid"
                :label="sector.name"
                :key="key"
                class="select-danger"/>
            </el-select>
          </fg-input>
        </div>
        <div class="col-md-6 offset-md-3 text-left">
          <div class="form-group has-label">
            <div class="row">
              <div class="col-md-5">
                <label>Labores de mantención <span>*</span></label>
              </div>
              <div class="col-md-7 text-right">
                <label>
                  <a
                    class="show-pointer"
                    @click="selectAllLabors()">Seleccionar todas</a> | <a
                      class="show-pointer"
                      @click="clearLaborsSelection()">Limpiar selección</a>
                </label>
              </div>
            </div>
          </div>
          <fg-input
            :error="errors.first('irrigation_upkeep_labors')"
            required>
            <el-select
              v-validate="{required: true}"
              v-model="model.irrigation_upkeep_labors"
              data-vv-as="Labores de mantención a realizar"
              name="irrigation_upkeep_labors"
              multiple=""
              class="select-primary"
              placeholder="Labores de mantención a realizar"
              filterable>
              <el-option
                v-for="(labor,key) in settings.waterSystem.maintenanceLabors"
                :value="labor._id.$oid"
                :label="labor.value"
                :key="key"
                class="select-danger"/>
            </el-select>
          </fg-input>
        </div>

        <div
          v-if="model.hasOwnProperty('irrigation_upkeep_parameters')"
          class="col-md-6 offset-md-3 text-left">
          <div class="form-group has-label">
            <div class="row">
              <div class="col-md-12">
                <label>Parámetros adicionales <span>*</span></label>
              </div>
            </div>
            <div
              v-for="(parameter, index) in model.irrigation_upkeep_parameters"
              :key="index"
              class="row">
              <div class="col-md-12">
                <fg-input
                  v-validate="{required: true}"
                  v-model.number="model.irrigation_upkeep_parameters[index].value"
                  :error="errors.first('parameter'+index)"
                  :data-vv-as="getParameterName(parameter)"
                  :name="'parameter'+index"
                  :label="getParameterName(parameter)"
                  :placeholder="getParameterName(parameter)"
                  type="number"
                  required/>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 offset-md-3 text-left">
          <fg-input
            :error="errors.first('assignee')"
            class="has-label">
            <label>Asignar a: <span>*</span></label>
            <el-select
              v-validate="{required: true}"
              v-model="model.workflow.assignee.$oid"
              filterable
              class="select-primary"
              placeholder="Seleccione usuario que realizará el monitoreo"
              name="assignee"
              vv-data-as="Asignar a"
            >
              <el-option
                v-for="(user,key) in users"
                :value="user._id.$oid"
                :label="user.full_name"
                :key="key"
                class="select-danger"/>
            </el-select>
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 action-footer">
          <slot name="actions"/>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <notification
            :messages="messages"
            :close="true"
            type="error"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
    props: {
        record: {
            type: Object,
            default: () => {
                return {}
            }
        },
        runValidations: {
            type: Number,
            default: 0
        },
        messages: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            loader: true,
            render: false,
            settings: undefined,
            saveText: "Registrar",
            sectors: [],
            firstAssign: true,
            model: {
                farm_id: "",
                date: "",
                sector_ids: [],
                irrigation_upkeep_labors: [],
                irrigation_upkeep_parameters: [],
                workflow: {
                    assignee: {
                        $oid: ""
                    }
                }
            }
        }
    },
    watch: {
        "model.date": function(newValue, oldValue) {
            if (newValue != "") {
                this.$emit("isFuture", this.$HELPERS.isFuture(newValue))
            }
        },
        "model.irrigation_upkeep_labors": function(newValue, oldValue) {
            if (!this.firstAssign) {
                this.processLabors(newValue, oldValue)
            }
            this.firstAssign = false
        },
        runValidations: function(newValue, oldValue) {
            let vm = this
            this.$validator.validateAll().then(isValid => {
                let model = vm.packLabors(vm.model)
                model = vm.packSectors(model)
                vm.$emit("isValid", isValid, model)
            })
        }
    },
    created() {
        if (Object.keys(this.record).length > 0) {
            this.model = this.$COPY.copy(this.record)
        } else {
            this.model.farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
        }
        this.getSettings()
    },
    methods: {
        getSettings() {
            this.settings = this.$store.getters["Settings/GET_SETTINGS"]
            this.getSectors()
        },
        getSectors() {
            let vm = this
            this.Services.Sectors.getSectors(this.model.farm_id)
                .then(response => {
                    vm.sectors = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.getUsers()
                })
        },
        getUsers() {
            let vm = this
            this.Services.Users.getUsers()
                .then(response => {
                    vm.users = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                    vm.render = true
                })
        },
        selectAllSectors() {
            this.model.sector_ids = this.sectors.map(i => i._id.$oid)
        },
        clearSectorSelection() {
            this.model.sectors = []
        },
        selectAllLabors() {
            this.model.irrigation_upkeep_labors = this.settings.waterSystem.maintenanceLabors.map(
                i => i._id.$oid
            )
        },
        clearLaborsSelection() {
            this.model.irrigation_upkeep_labors = []
        },
        getParameterName(parameter) {
            return this.settings.waterSystem.maintenanceLaborsConditionalFields.find(
                i => {
                    return i._id.$oid == parameter.parameter_id
                }
            ).value.variableName
        },
        processLabors(newValue, oldValue) {
            let vm = this

            let laborsToAdd = newValue.reduce((toAdd, labor) => {
                if (oldValue.indexOf(labor) == -1) toAdd.push(labor)
                return toAdd
            }, [])

            let laborsToDelete = oldValue.reduce((toDelete, labor) => {
                if (newValue.indexOf(labor) == -1) toDelete.push(labor)
                return toDelete
            }, [])

            if (laborsToAdd.length > 0) {
                laborsToAdd.forEach(labor => {
                    let additionalFields = vm.settings.waterSystem.maintenanceLaborsConditionalFields.filter(
                        i => {
                            return i.value.attachedTo == labor
                        }
                    )
                    additionalFields.forEach(field => {
                        if (
                            !vm.model.hasOwnProperty(
                                "irrigation_upkeep_parameters"
                            )
                        ) {
                            vm.model.irrigation_upkeep_parameters = []
                        }
                        vm.model.irrigation_upkeep_parameters.push({
                            labor_id: labor,
                            parameter_id: field._id.$oid,
                            name: field.value.variableName,
                            value: ""
                        })
                    })
                })
            }

            if (
                laborsToDelete.length > 0 &&
                vm.model.hasOwnProperty("irrigation_upkeep_parameters")
            ) {
                vm.model.irrigation_upkeep_parameters = vm.model.irrigation_upkeep_parameters.filter(
                    i => {
                        return laborsToDelete.indexOf(i.labor_id) == -1
                    }
                )
            }
        },
        unpackLabors() {
            console.log("unpackLabors")
        },
        packLabors(initModel) {
            let vm = this
            let model = vm.$COPY.copy(initModel)
            model.irrigation_upkeep_labors = model.irrigation_upkeep_labors.reduce(
                (out, i) => {
                    let labor = vm.settings.waterSystem.maintenanceLabors.find(
                        l => l._id.$oid == i
                    )
                    out.push({ labor_id: i, name: labor.value })
                    return out
                },
                []
            )
            return model
        },
        packSectors(initModel) {
            let vm = this
            let model = vm.$COPY.copy(initModel)
            model.irrigation_upkeep_sectors = model.sector_ids.map(i => {
                return { sector_id: i, checked: false }
            })
            return model
        }
    }
}
</script>

<style lang="css" scoped>
</style>
