<template lang="html">
  <div class="row">
    <div class="col-md-12">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-info-circle"/></div>
                <h5 class="info-title"><span>{{ $t(model.workflow.state) }}</span></h5>
                <h6 class="stats-title">Estado</h6>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-calendar"/></div>
                <h5
                  v-if="model.hasOwnProperty('date')"
                  class="info-title">
                  <span>{{ model.date | moment("DD-MM-YYYY") }}</span>
                </h5>
                <h6 class="stats-title">Fecha</h6>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-coins"/></div>
                <h5 class="info-title"><span>{{ model.total | toLocaleStringNumber("currency") }}</span></h5>
                <h6 class="stats-title">Costo CLP (IVA incl.)</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div
          class="col-md-12 col-sm-12 text-left">
          <h5>Detalles de la polinización</h5>
          <p>Cuarteles: <el-tag
            v-for="sector in model.sectors"
            :key="sector._id.$oid"
            :closable="false"
            :close-transition="false"
            size="small"
            type="primary"
            class="used-product-tag">
            {{ sector.name }}
          </el-tag></p>
          <p>Flores/Planta: {{ model.flowers_per_plant | toLocaleStringNumber }}</p>
          <p>{{ 'Plantas ' + getWorkCoverageTitle }}: {{ model.work_coverage | toLocaleStringNumber }}</p>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12 text-left">
          <h5>{{ 'Detalle de colmenas ' + getHiveTitle }}</h5>
        </div>
        <div class="col-md-12 text-left pull-center desktop-table">
          <el-table
            ref="hives"
            :data="model.hives"
            empty-text="¿No se usaron colmenas?"
            class="table-striped">

            <el-table-column
              prop="name"
              label="Nombre"
              align="left"
              class-name="table-category"/>

            <el-table-column
              prop="flower_capacity"
              label="Cubrimiento de flores/Colmena"
              align="right"
              header-align="center">
              <div
                slot-scope="{row}"
                class="text-right">
                {{ row.flower_capacity | toLocaleStringNumber }}
              </div>
            </el-table-column>

            <el-table-column
              prop="cost_per_hive"
              label="Costo por Colmena (CLP)"
              align="right"
              header-align="center">
              <div
                slot-scope="{row}"
                class="text-right">
                {{ row.cost_per_hive | toLocaleStringNumber("currency") }}
              </div>
            </el-table-column>

            <el-table-column
              prop="qty"
              name="qty"
              label="Cantidad"
              align="right"
              header-align="center">
              <div
                slot-scope="{row}"
                class="text-right">
                {{ row.qty | toLocaleStringNumber }}
              </div>
            </el-table-column>

            <el-table-column
              name="subtotal"
              prop="qty"
              label="Subtotal (CLP sin IVA)"
              align="right"
              header-align="center">
              <div
                slot-scope="{row}"
                class="text-right">
                {{ row.qty * row.cost_per_hive | toLocaleStringNumber("currency") }}
              </div>
            </el-table-column>

          </el-table>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <cost-summary :record="model" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-center mt-3">
          <slot name="actions"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui"
import CostSummary from "@/views/Labors/Components/CostSummary"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        CostSummary
    },
    props: {
        model: {
            type: Object,
            default: () => {
                return {}
            }
        },
        sectors: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            elements: {
                sectors: false
            },
            sectorList: []
        }
    },
    computed: {
        recordSectors() {
            let recordSectors = this.model.sectors.map(i => i.toString())
            return this.sectors.filter(sector => {
                return recordSectors.includes(sector._id.toString())
            })
        },
        getWorkCoverageTitle() {
            return this.model.workflow.state == "done"
                ? "Polinizadas"
                : "a Polinizar"
        },
        getHiveTitle() {
            return this.model.workflow.state == "done"
                ? "utilizadas"
                : "a utilizar"
        }
    }
}
</script>

<style lang="css" scoped>
</style>
