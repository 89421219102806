<template lang="html">
  <div v-loading="loader">
    <div class="row section-header">
      <div class="col-md-6 text-left">
        <h5>
          <b>{{ title }}</b>
        </h5>
      </div>
      <div class="col-md-6 text-right">
        <n-button
          v-if="$store.getters['Auth/IS_ADMIN']"
          type="primary"
          @click.native="components.newSector = true"
        >
          <i class="fas fa-plus" />
          Agregar cuartel
        </n-button>
      </div>
    </div>
    <template v-for="(item, index) in sectors">
      <sector
        v-if="item.subsector != true"
        :key="index"
        :farm="farm"
        :sector="item"
        :sectors="sectors"
        class="col-md-12"
        @sectorUpdated="getSectorsWrapper()"
      />
    </template>

    <new-sector
      v-if="components.newSector"
      :sectors="sectors"
      :farm="farm"
      @close="components.newSector = false"
      @newSectorAdded="getSectors()"
      @updateFarm="$emit('updateFarm')"
    />
  </div>
</template>

<script>
import Sector from "./Sector";
import NewSector from "./NewSector";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    Sector,
    NewSector,
  },
  props: {
    farm: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      title: "Cuarteles",
      sectors: [],
      components: {
        newSector: false,
      },
      modals: {
        newSector: false,
      },
      loader: false,
    };
  },
  computed: {
    ...mapGetters({ currentFarmId: "Farms/GET_FARM_ID" }),
  },
  created() {
    this.getSectors();
  },
  methods: {
    ...mapActions("Farms", ["getCurrentFarmData"]),
    async getSectors() {
      try {
        this.loader = true;
        const farmId = this.farm._id.$oid;
        const r = await this.Services.Sectors.getSectors(farmId);
        this.sectors = this.$HELPERS.computeSectors(r.data);
        this.$emit("setNumOfSectors", this.sectors);
        if (this.currentFarmId == farmId) {
          await this.getCurrentFarmData();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader = false;
      }
    },
    getSectorsWrapper() {
      this.getSectors();
      this.$emit("updateFarm");
    },
  },
};
</script>

<style lang="sass"></style>
