<template lang="html">
  <div class="">
    <div class="col-md-12 col-sm-12 pull-center desktop-table">
      <el-table
        :data="data"
        :empty-text="'No hay cosechas registradas'"
        class="table-striped"
      >
        <el-table-column
          v-if="showStatus"
          prop="status"
          label="Estado"
          align="center"
          name="status"
          column-key="status"
          class-name="table-category"
        >
          <div slot-scope="{ row }">
            <el-tag
              :closable="false"
              :close-transition="false"
              :type="$TASK_STATES.getColor(row.workflow.state)"
              size="small"
            >
              {{ $t(row.workflow.state) }}
            </el-tag>
          </div>
        </el-table-column>

        <el-table-column label="Año" align="left" name="date" width="100">
          <div slot-scope="{ row }">
            {{ row.date | moment("YYYY") }}
          </div>
        </el-table-column>

        <el-table-column label="Mes" align="left" name="date" width="100">
          <div slot-scope="{ row }">
            {{ row.date | moment("MM") }}
          </div>
        </el-table-column>

        <el-table-column label="Semana" align="left" name="date" width="100">
          <div slot-scope="{ row }">
            {{ row.date | moment("WW") }}
          </div>
        </el-table-column>

        <el-table-column
          prop="date"
          label="Fecha"
          align="left"
          name="date"
          column-key="date"
          class-name="table-category"
          sortable
        >
          <div slot-scope="{ row }">
            <i class="el-icon-time" /> {{ row.date | moment("DD-MM-YYYY") }} ({{
              row.date | moment("dddd")
            }})
          </div>
        </el-table-column>

        <el-table-column
          label="Cuarteles"
          name="sectors"
          align="center"
          class-name="table-category"
        >
          <div slot-scope="{ row }">
            <div
              v-for="(item, index) in row.sector_harvests"
              :key="'sector_name_' + index + '_' + item.sector._id.$oid"
            >
              {{ getSectorName(item.sector) }}
            </div>
          </div>
        </el-table-column>

        <el-table-column
          prop="kg"
          label="Cosecha Total (kg)"
          name="kg"
          header-align="center"
          align="right"
          class-name="table-category"
        >
          <div slot-scope="{ row }">
            {{ row.total_harvest | toLocaleStringNumber("decimal") }}
          </div>
        </el-table-column>

        <el-table-column
          label="Acciones"
          align="center"
          class-name="table-category"
        >
          <div slot-scope="{ row }">
            <n-button type="primary" size="sm" @click.native="viewHarvest(row)">
              <i class="fal fa-eye" />
            </n-button>
          </div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Scrollbar } from "element-ui";
import pluralize from "pluralize";
import { SectorUtils } from "../../../../mixins";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Scrollbar.name]: Scrollbar,
  },
  mixins: [SectorUtils],
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sectors: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      components: {
        deleteHarvest: false,
        sectors: false,
      },
    };
  },
  methods: {
    // UGLY: Should be wrapped in another component, mixin or other
    viewHarvest(doc) {
      this.$router.push({
        name: "continue-process",
        params: {
          id: doc._id.$oid,
          processKey: pluralize.singular(this.$route.params.processKey),
          state: doc.workflow.state,
        },
      });
    },
  },
};
</script>
