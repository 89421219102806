import { ceres_api } from "@/axios-config.js"

const Settings = {
    getSettings() {
        return ceres_api.get("/settings")
    },
    setSettings(payload) {
        return ceres_api.put("/settings/set", payload)
    }
}

export default Settings
