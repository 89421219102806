<template lang="html">
  <el-table
    v-loading="loaders.workers"
    :data="workers"
    empty-text="No hay podas registradas"
    class="table-striped">

    <el-table-column
      prop="full_name"
      label="Nombre completo"
      align="left" />
    <el-table-column
      prop="job_position"
      label="Cargo"
      align="left" />
    <el-table-column
      prop="skills"
      label="Habilidades"
      align="left">
      <div slot-scope="{row}">
        {{ row.skills.join(", ") }}
      </div>
    </el-table-column>

    <el-table-column
      label="Acciones"
      align="center"
      class-name="table-category actions-column">

      <div
        slot-scope="{row}"
        class="text-center">
        <n-button
          type="primary"
          size="sm"
          @click.native="editWorker(row)">
          <i class="fal fa-eye"/>
        </n-button>
      </div>
    </el-table-column>
  </el-table>
</template>

<script>
import { Table, TableColumn } from "element-ui"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    data() {
        return {
            workers: [],
            defaults: {
                picture: require("@/assets/img/default_avatar.png")
            },
            loaders: {
                workers: true
            }
        }
    },
    created() {
        this.getWorkers()
    },
    methods: {
        editWorker(worker) {
            this.$emit("editWorker")
            this.$emit("setCurrentWorker", worker)
        },
        getWorkers() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Workers.getWorkers({ farm_id })
                .then(response => {
                    vm.workers = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loaders.workers = false
                })
        }
    }
}
</script>

<style lang="sass">
	@import '../../../assets/sass/now-ui-dashboard/variables'

	.worker-card
		cursor: pointer
		background-color: $primary-color
		color: white
		border: 1px solid $primary-color
		border-radius: 15px
		width: 165px
		margin: 10px auto
		.profile-picture
			width: 100%
			height: 130px
			display: inline-block
			background-size: cover
			border-top-right-radius: 15px
			border-top-left-radius: 15px
		.worker-name
			height: 40px
			font-size: 12px
			padding-top: 5px
			text-transform: uppercase
			font-weight: bold
		.worker-position
			font-size: 12px
			text-transform: uppercase
			padding: 5px 0px 10px 0px

</style>
