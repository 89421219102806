<template lang="html">
  <div class="col-md-10 offset-md-1">
    <div v-if="Object.keys(model).length > 0" class="row">
      <div class="col-md-3">
        <div class="statistics">
          <div class="info">
            <div class="icon icon-info"><i class="far fa-calendar-alt" /></div>
            <h5 class="info-title">
              <span>{{ model.date | moment("DD-MM-YYYY") }}</span>
            </h5>
            <h6 class="stats-title">Fecha</h6>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="statistics">
          <div class="info">
            <div class="icon icon-info"><i class="fal fa-map-marked" /></div>
            <h5 class="info-title">
              <span>{{ model.sector.name }}</span>
            </h5>
            <h6 class="stats-title">Cuartel</h6>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="statistics">
          <div class="info">
            <div class="icon icon-success"><i class="far fa-box-open" /></div>
            <h5 class="info-title">
              <span
                >{{ model.harvest_unit }} de
                {{
                  parseFloat(model.harvest_unit_weight)
                    | toLocaleStringNumber("decimal")
                }}
                {{ model.harvest_unit_weight_unit }}</span
              >
            </h5>
            <h6 class="stats-title">Unidad de Cosecha</h6>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="statistics">
          <div class="info">
            <div class="icon icon-success">
              <i class="far fa-weight-hanging" />
            </div>
            <h5 class="info-title">
              <span>{{ model.total_harvest | toLocaleStringNumber }}</span>
            </h5>
            <h6 class="stats-title">Kg Cosechados Approx.</h6>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div class="row">
      <div class="col-md-12 text-left">
        <h5>Detalle por cosecher@</h5>
      </div>

      <div class="col-md-12 col-sm-12 pull-center desktop-table">
        <el-table
          :data="model.harvest_workers"
          :empty-text="'No hay cosecheros asociados'"
          class="table-striped"
        >
          <el-table-column
            label="Cosecher@"
            align="left"
            class-name="table-category"
          >
            <div slot-scope="{ row }" class="text-left">
              {{ row.worker.full_name }}
            </div>
          </el-table-column>

          <el-table-column
            :formatter="boxesFormatter"
            prop="boxes"
            label="Unidades Cosechadas"
            header-align="center"
            align="right"
            class-name="table-category"
          />

          <el-table-column
            label="Kg Cosechados Approx."
            header-align="center"
            align="right"
            class-name="table-category"
          >
            <div slot-scope="{ row }">
              {{
                (row.boxes * model.harvest_unit_weight)
                  | toLocaleStringNumber("decimal")
              }}
            </div>
          </el-table-column>

          <el-table-column
            label="Pago por Unidad"
            header-align="center"
            align="right"
            class-name="table-category"
          >
            <div slot-scope="{ row }">
              {{ model.cost_per_unit | toLocaleStringNumber("decimal") }}
            </div>
          </el-table-column>

          <el-table-column
            label="Pago Cosechero"
            header-align="center"
            align="right"
            class-name="table-category"
          >
            <div slot-scope="{ row }">
              {{
                (model.cost_per_unit * row.boxes)
                  | toLocaleStringNumber("decimal")
              }}
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <cost-summary :record="model" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import CostSummary from "@/views/Labors/Components/CostSummary";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    CostSummary,
  },
  data() {
    return {
      isAdmin: false,
      farm: {},
      components: {
        setAssignee: false,
      },
      loader: true,
      model: {},
    };
  },
  created() {
    this.farm = this.$store.getters["Farms/GET_FARM"];
    this.getHarvest();
  },
  methods: {
    getHarvest() {
      let vm = this;
      vm.loader = true;
      this.Services.Harvests.getHarvest(
        this.farm._id.$oid,
        this.$route.params.id
      )
        .then((response) => {
          vm.model = response.data;
          vm.$emit("updateModel", vm.model);
        })
        .catch((error) => {
          console.log(error);
          vm.$router.push({ name: "not-found" });
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    boxesFormatter(row, column) {
      return row.boxes + " " + this.model.harvest_unit + "s";
    },
  },
};
</script>

<style lang="sass" scoped></style>
