<template lang="html">
  <div class="col-md-12 col-sm-12 pull-center desktop-table">
    <el-table
      :data="data"
      empty-text="No hay aplicaciones registradas"
      class="table-striped">

      <el-table-column
        v-if="showStatus"
        prop="status"
        label="Estado"
        align="center"
        name="status"
        column-key="status"
        class-name="table-category">
        <div
          slot-scope="{row}">
          <el-tag
            :closable="false"
            :close-transition="false"
            :type="$TASK_STATES.getColor(row.workflow.state)"
            size="small"
            class="used-product-tag">
            {{ $t(row.workflow.state) }}
          </el-tag>
        </div>
      </el-table-column>

      <el-table-column
        prop="type"
        label="Tipo de aplicación"
        align="left"/>

      <el-table-column
        prop="date"
        label="Fecha"
        align="center"
        sortable>
        <div slot-scope="{row}">
          <i class="el-icon-time"/> {{ row.date | moment("DD-MM-YYYY") }} <br> ({{ row.date | moment("dddd") }})
        </div>
      </el-table-column>

      <el-table-column
        label="Cuarteles"
        align="center">
        <div
          slot-scope="{row}">
          <template
            v-if="hasAllSectors(row.sectors)">
            <el-tag
              :closable="false"
              :close-transition="false"
              size="small"
              type="primary"
              class="used-product-tag">
              Todos
            </el-tag>
          </template>
          <template
            v-else>
            <el-tag
              v-for="(sector,index) in row.sectors"
              :key="index"
              :closable="false"
              :close-transition="false"
              size="small"
              type="primary"
              class="used-product-tag">
              {{ sector.name }}
            </el-tag>
          </template>
        </div>
      </el-table-column>

      <el-table-column
        label="Productos utilizados"
        align="left"
        min-width="150">

        <div
          slot-scope="{row}"
          class="text-left">
          <div
            v-for="used_product in row.used_products"
            :key="row._id.$oid+'_'+used_product.product._id.$oid">
            {{ used_product.product.name }} ({{ used_product.use | toLocaleStringNumber("decimal") }} {{ used_product.product.measure_unit }})
          </div>
        </div>
      </el-table-column>

      <el-table-column
        label="Acciones"
        align="center"
        class-name="table-category actions-column"
        width="150">

        <div
          slot-scope="{row}"
          class="text-center">
          <n-button
            type="primary"
            size="sm"
            @click.native="view(row)">
            <i class="fal fa-eye"/>
          </n-button>
        </div>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui"
import pluralize from "pluralize"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    props: {
        data: {
            type: Array,
            default: () => {
                return []
            }
        },
        showStatus: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            sectors: []
        }
    },
    created() {
        this.getSectors()
    },
    methods: {
        getSectors() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Sectors.getSectors(farm_id)
                .then(response => {
                    vm.sectors = response.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        hasAllSectors(current_sectors) {
            let ids = current_sectors.map(i => i._id.$oid)
            return this.sectors.every(i => ids.includes(i._id.$oid))
        },
        view(row) {
            this.$router.push({
                name: "continue-process",
                params: {
                    id: row._id.$oid,
                    processKey: pluralize.singular(
                        this.$route.params.processKey
                    ),
                    state: row.workflow.state
                }
            })
        }
    }
}
</script>

<style lang="css">
</style>
