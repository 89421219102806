<template lang="html">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12 text-left setting-subsection">
        Labores de mantenimiento
      </div>
    </div>
    <div class="row">
      <el-table
        :data="model.labors.data"
        empty-text="No hay labores configuradas aún"
        class="products-table"
      >
        <el-table-column label="Labor de mantenimiento">
          <div slot-scope="scope">
            <fg-input
              v-validate="{ required: true }"
              v-model="scope.row.value"
              :name="'labor' + scope.$index"
              :error="errors.first('labor' + scope.$index)"
              type="text"
              data-vv-as="Labor de mantenimiento"
              placeholder="Labor de mantenimiento"
            />
          </div>
        </el-table-column>
        <el-table-column label="Acciones" align="right" fixed="right">
          <div slot-scope="scope" class="controls">
            <n-button
              simple=""
              type="danger"
              size="sm"
              @click.native="handleDelete('labors', scope.row, scope.$index)"
            >
              <i class="fas fa-minus-circle" />
            </n-button>
            <n-button
              v-if="isLastRow(scope, 'labors')"
              simple=""
              size="sm"
              class="ms-1"
              type="primary"
              @click.native="addLine('labors')"
            >
              <i class="fas fa-plus-circle" />
            </n-button>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="row">
      <div class="col-md-12 text-left setting-subsection">
        Campos condicionales por labor de mantenimiento
      </div>
      <general-conditional-fields
        key="WaterConditionalFields"
        :settings="settings"
        class="col-md-12"
        setting-category="waterSystem"
        setting-key="maintenanceLaborsConditionalFields"
        @updatedModel="updateModel"
      >
        <span slot="customColumns">
          <el-table-column label="Labor de mantenimiento" prop="value">
            <div slot-scope="scope">
              <fg-input
                :error="errors.first('laborConditionalField' + scope.$index)"
              >
                <el-select
                  v-validate="{ required: true }"
                  v-model="scope.row.value.attachedTo"
                  :name="'laborConditionalField' + scope.$index"
                  data-vv-as="Labor de mantenimiento"
                  class="select-primary"
                  placeholder="Labor de mantenimiento"
                  filterable
                >
                  <el-option
                    v-for="(item, itemKey) in settings.waterSystem
                      .maintenanceLabors"
                    :value="item._id.$oid.toString()"
                    :label="item.value"
                    :key="itemKey"
                    class="select-danger"
                  />
                </el-select>
              </fg-input>
            </div>
          </el-table-column>
        </span>

        <span slot="bottom">
          <el-alert
            v-if="savedLabors.length != model.labors.data.length"
            :closable="false"
            title="Primero debe guardar los cambios para utilizar las nuevas labores de mantenimiento"
            type="warning"
          />
        </span>
      </general-conditional-fields>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Checkbox, Alert } from "element-ui";
import GeneralConditionalFields from "./Components/GeneralConditionalFields";
import { get, cloneDeep } from "lodash";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Checkbox.name]: Checkbox,
    [Alert.name]: Alert,
    GeneralConditionalFields,
  },
  props: {
    settings: {
      type: Object,
      default: () => {
        return {};
      },
    },
    runValidations: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      schemas: {
        labor: {
          value: "",
          key: "maintenanceLabors",
          category: "waterSystem",
          type: "array",
          attr: {
            hasAdditionalFields: false,
          },
        },
        additionalField: {
          value: "",
          key: "",
          category: "waterSystem",
          type: "array",
        },
      },
      model: {
        labors: {
          data: [],
          toDelete: [],
        },
        maintenanceLaborsConditionalFields: [],
      },
    };
  },
  computed: {
    savedLabors() {
      return get(this.settings, "waterSystem.maintenanceLabors", []);
    },
  },
  watch: {
    model: {
      handler: function (newValue, oldValue) {
        let out = cloneDeep(newValue);
        out.waterSystemMaintenance = newValue.labors.data.concat(
          newValue.labors.toDelete
        );
        delete out.labors;
        this.$emit("updateModel", out);
      },
      deep: true,
    },
    runValidations: function (newValue, oldValue) {
      if (newValue) {
        let vm = this;
        this.$validator.validateAll().then((isValid) => {
          vm.$emit("updateValidation", isValid, "WaterSystem");
        });
      }
    },
  },
  created() {
    try {
      this.model.labors.data = cloneDeep(this.savedLabors);
    } catch (error) {
      let schema = cloneDeep(this.schemas.labor);
      this.model.labors.data.push(schema);
    }
  },
  methods: {
    addLine(form) {
      let schema = cloneDeep(this.schemas.labor);
      this.model[form].data.push(schema);
    },
    isLastRow(scope, form) {
      return this.model[form].data.length - 1 == scope.$index ? true : false;
    },
    handleDelete(form, item, index) {
      if (item._id != undefined) {
        item.delete = true;
        this.model[form].toDelete.push(item);
      }
      this.model[form].data.splice(index, 1);
    },
    updateModel(newModel, formKey) {
      this.model[formKey] = cloneDeep(newModel);
    },
  },
};
</script>

<style lang="css" scoped></style>
