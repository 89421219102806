<template lang="html">
  <div class="row mt-4 mb-4">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12 text-left">
          <h5>{{ title }}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 text-left">
          <label>Subtotal</label>
          <el-alert
            :title="record.sub_total | toLocaleStringNumber('currency')"
            :closable="false"
            name="sub_total"
            type="warning"
            center/>
        </div>
        <div class="col-md-4 text-left">
          <label>Impuestos (IVA)</label>
          <el-alert
            :title="record.taxes | toLocaleStringNumber('currency')"
            :closable="false"
            name="taxes"
            type="warning"
            center/>
        </div>
        <div class="col-md-4 text-left">
          <label>Total</label>
          <el-alert
            :title="record.total | toLocaleStringNumber('currency')"
            :closable="false"
            name="total"
            type="warning"
            center/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert } from "element-ui"

export default {
    components: {
        [Alert.name]: Alert
    },
    props: {
        title: {
            type: String,
            default: "Costos"
        },
        record: {
            type: Object,
            default: () => {
                return {}
            }
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
