var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{staticClass:"table-striped products-table",attrs:{"data":_vm.tasks,"empty-text":'No hay actividades pendientes'}},[_c('el-table-column',{attrs:{"min-width":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-left process-icon"},[_c('i',{class:_vm.$CERES_VARS.getProcessIcon(row.workflow._type)})])}}])}),(_vm.taskFilter == 'own' && _vm.isSupervisor)?_c('el-table-column',{attrs:{"label":"Responsable","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$HELPERS.findAndgetUserFullName(row.workflow.assignee.$oid, _vm.users))+" ")])}}],null,false,3787833441)}):_vm._e(),_c('el-table-column',{attrs:{"label":"Estado","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm.$t(row.workflow.state))+" ")])}}])}),_c('el-table-column',{attrs:{"filters":_vm.processKeyFilters,"filter-method":_vm.processKeyFilterHandler,"prop":"processName","label":"Proceso","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-left capitalize"},[_vm._v(" "+_vm._s(_vm.$t(row.workflow._type))+" ")])}}])}),_c('el-table-column',{attrs:{"formatter":_vm.dateFomatter,"prop":"date","name":"due","label":"Vencimiento","align":"center","sortable":""}}),_c('el-table-column',{attrs:{"label":"Acciones","align":"center","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"controls"},[_c('n-button',{attrs:{"type":"primary","size":"sm"},nativeOn:{"click":function($event){return _vm.goToTask(row)}}},[_c('i',{staticClass:"fal fa-eye"})]),(_vm.isSupervisor || _vm.allowSetAssignee)?_c('n-button',{staticClass:"ms-1",attrs:{"type":"warning","size":"sm"},nativeOn:{"click":function($event){return _vm.openSetAssignee(row)}}},[_c('i',{staticClass:"fal fa-user"})]):_vm._e()],1)}}])})],1),(_vm.components.setAssignee)?_c('set-assignee',{attrs:{"users":_vm.users,"task-id":_vm.currentTaskId,"assignee":_vm.currentAssignee,"process-key":_vm.currentProcessKey},on:{"updateTaskList":function($event){return _vm.$emit('updateTaskList')},"close":function($event){_vm.components.setAssignee = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }