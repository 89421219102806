<template lang="html">
  <div
    v-if="Object.keys(model).length > 0">
    <plague-monitoring-form
      v-loading="loader"
      :messages="messages"
      :run-validations="runValidations"
      :record="model"
      @isValid="isValid">

      <div slot="actions">
        <process-controls
          v-if="Object.keys(model).length > 0"
          :record="model"
          @action="validate"/>
      </div>
    </plague-monitoring-form>
  </div>
</template>

<script>
import PlagueMonitoringForm from "@/views/Labors/PlagueMonitorings/Components/PlagueMonitoringForm"
import ProcessControls from "@/views/TaskList/Components/ProcessControls"

export default {
    components: {
        PlagueMonitoringForm,
        ProcessControls
    },
    props: {
        variables: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            loader: false,
            messages: {},
            runValidations: 1,
            model: {},
            decision: ""
        }
    },
    created() {
        this.$emit("setTitle", "Realizar monitoreo programado")
        this.model = this.$COPY.copy(this.variables)
    },
    methods: {
        updateModel(newModel) {
            this.model = newModel
        },
        validate(decision) {
            // this.loader = true
            this.messages = {}
            this.decision = decision
            this.runValidations += 1
        },
        isValid(result, data) {
            result ? this.submit(data) : (this.loader = false)
        },
        goToViewRecord() {
            this.$router.push({
                name: "view-plague-monitoring",
                params: {
                    id: this.$route.params.id
                }
            })
        },
        submit(model) {
            this.$emit(
                "completeTask",
                this.$route.params.processKey,
                this.$route.params.id,
                model,
                this.decision
            )
        }
    }
}
</script>

<style lang="css" scoped>
</style>
