<template lang="html">
  <div v-loading="loader">
    <div
      v-if="!loader && Object.keys(settings).length > 0"
      class="col-md-12">
      <div class="row">
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info"><i class="fal fa-info-circle"/></div>
              <h5 class="info-title"><span>{{ $t(model.workflow.state) }}</span></h5>
              <h6 class="stats-title">Estado</h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info"><i class="fal fa-calendar"/></div>
              <h5
                v-if="model.hasOwnProperty('date')"
                class="info-title">
                <span>{{ model.date | moment("DD-MM-YYYY") }}</span>
              </h5>
              <h6 class="stats-title">Fecha</h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info"><i class="fal fa-user"/></div>
              <h5 class="info-title"><span>{{ user_full_name }}</span></h5>
              <h6 class="stats-title">Responsable</h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info"><i class="fal fa-bullseye"/></div>
              <h5 class="info-title"><span>{{ $t(model.tracking_type) }}</span></h5>
              <h6 class="stats-title">Tipo de Monitoreo</h6>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <el-tabs
          type="border-card"
          class="col-md-12 no-box-shadow">
          <el-tab-pane
            label="Resumen">
            <template v-if="model.summary != null">
              <div
                v-for="(sector_summary, index) in model.summary"
                :key="index"
                class="row">

                <div class="col-md-12 text-left">
                  <h5>{{ model.sectors.find(i => i._id.$oid == sector_summary.sector_id.$oid).name }}</h5>
                  <el-tag
                    type="info"
                    class="clean-tag me-2">
                    Umbral de cambio: <b>{{ model.validation_schema.change_threshold | toLocaleStringNumber('percent') }}</b>
                  </el-tag>
                  <el-tag
                    type="success"
                    class="clean-tag">
                    Estado predominante: <b>{{ getDominanPhenologicalStage(sector_summary) }}</b>
                  </el-tag>
                </div>

                <el-table
                  :data="sector_summary.counts"
                  class="col-md-12"
                  show-summary>
                  <el-table-column
                    label="Especie">
                    <div slot-scope="scope">
                      {{ getSpecieName(sector_summary.sector_id) }}
                    </div>
                  </el-table-column>
                  <el-table-column
                    label="Variedad">
                    <div slot-scope="scope">
                      {{ getVarietyName(sector_summary.sector_id) }}
                    </div>
                  </el-table-column>
                  <el-table-column
                    label="Estado Fenológico"
                    prop="state">
                    <div slot-scope="scope">
                      {{ getPhenologicalStageName(scope.row.phenological_stage) }}
                    </div>
                  </el-table-column>
                  <el-table-column
                    label="Conteo"
                    prop="count"
                    align="center"/>
                  <el-table-column
                    label="Porcentaje (%)"
                    align="center">
                    <div slot-scope="scope">
                      {{ scope.row.count / sector_summary.counts.reduce((total, i) => total += i.count, 0) * 100 | toLocaleStringNumber('decimal') }}
                    </div>
                  </el-table-column>
                </el-table>
              </div>
            </template>
            <div
              v-else
              class="row">
              <div class="col-12 text-muted text-center">
                Aún no se han registrado observaciones
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="Detalle">
            <div
              v-for="sector in model.sectors"
              :key="sector._id.$oid"
              class="row">
              <div class="col-md-12 text-left">
                <h5>{{ sector.name }}</h5>
              </div>

              <el-table
                :data="model.observations.filter(i => i.sector_id.$oid == sector._id.$oid)"
                class="col-md-12"
                empty-text="Aún no se han registrado observaciones">
                <el-table-column
                  label="Hilera"
                  prop="line_number"
                  align="center"
                  width="100"/>
                <el-table-column
                  label="Planta"
                  prop="plant_number"
                  align="center"
                  width="100"/>
                <el-table-column
                  label="Estado Fenológico"
                  prop="phenological_stage"
                  align="center">
                  <div slot-scope="scope">
                    {{ getPhenologicalStageName(scope.row.phenological_stage) }}
                  </div>
                </el-table-column>
                <el-table-column
                  label="Mediciones adicionales"
                  prop="phenologicalState"
                  align="center">
                  <div slot-scope="scope">
                    <span
                      v-for="(extra, extra_index) in scope.row.extra_measures"
                      :key="extra_index">
                      {{ extra.variable_name }}: {{ extra.value }} <br>
                    </span>
                  </div>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="Registro fotográfico">
                  <div slot-scope="scope">
                    <picture-viewer :picture="scope.row.photo"/>
                  </div>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <div class="row">
        <div class="col-md-12 mt-3">
          <slot name="actions"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Helpers from "@/utils/Helpers";
import PictureViewer from "@/Components/PictureViewer";
import { Table, TableColumn, Tabs, TabPane, Tag } from "element-ui";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Tag.name]: Tag,
    PictureViewer,
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
    phenologicalTrackingId: {
      type: String,
      default: "",
    },
    taskId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      Helpers: Helpers,
      loader: true,
      settings: {},
      user_full_name: "No encontrado",
      modals: {
        preview: false,
      },
      current_photo_url: undefined,
    };
  },
  created() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      this.settings = this.$store.getters["Settings/GET_SETTINGS"];
      this.getUser();
    },
    getUser() {
      let vm = this;
      this.Services.Users.getUser(this.model.workflow.assignee.$oid)
        .then((response) => {
          vm.user_full_name = response.data.full_name;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    getPhenologicalStageName(phenological_stage) {
      if (phenological_stage != null) {
        if (phenological_stage.hasOwnProperty("$oid")) {
          let temp = Object.values(this.settings.phenologicalStages);
          let stages = temp[0].concat(temp.slice(1));
          return stages.find((i) => i._id.$oid == phenological_stage.$oid)
            .value;
        }
      } else {
        return "";
      }
    },
    getSpecieName(sector_id) {
      let specie = this.model.sectors.find(
        (i) => i._id.$oid == sector_id.$oid
      ).specie;
      return this.settings.general.species.find((i) => i._id.$oid == specie)
        .value;
    },
    getVarietyName(sector_id) {
      let sector = this.model.sectors.find((i) => i._id.$oid == sector_id.$oid);
      return this.settings.varieties[sector.specie].find(
        (i) => i._id.$oid == sector.variety
      ).value;
    },
    processExtraMeasures(extraMeasures) {
      let vm = this;
      let data = Object.entries(extraMeasures).reduce((out, item) => {
        out.push({
          field: vm.settings.phenologicalTracking.conditionalFields.find(
            (i) => i._id.toString() == item[0]
          ).value.variableName,
          count: item[1],
        });
        return out;
      }, []);
      let result;
      if (data.length > 0) {
        result = data;
      }
      return result;
    },
    previewPhoto(variableName) {
      let vm = this;
      let payload = {
        taskId: this.taskId,
        fileName: variableName,
      };

      this.modals.preview = true;
      this.current_photo_url = undefined;

      if (variableName == "" || variableName == undefined) return;

      this.$store
        .dispatch("getTaskFile", payload)
        .then((response) => {
          vm.current_photo_url = vm.$HELPERS.generateFileUrl(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {});
    },
    getDominanPhenologicalStage(sector_summary) {
      let dominant = sector_summary.dominant
        .map((i) => this.getPhenologicalStageName(i))
        .join(", ");
      return dominant == "" ? "Ninguno" : dominant;
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
