<template lang="html">
  <div class="row data">
    <div
      v-if="data.length > 0"
      class="col-md-12">
      <div class="row">
        <div class="col-md-12 text-left">
          <h5>Pronósticos de YR</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <el-tabs type="border-card">
            <el-tab-pane
              v-for="(place, index) in data"
              :key="index"
              :label="place.name + ', ' + place.country">
              <div class="row">
                <div class="col-md-12 text-right">
                  <n-button
                    simple
                    type="primary"
                    size="sm"
                    @click.native="downloadData()">
                    <i class="fal fa-download"/> Descargar data
                  </n-button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <h5>Temperatura</h5>
                </div>
                <div class="col-md-12">
                  <apex-charts
                    :height="400"
                    :options="temperature(place).options"
                    :series="temperature(place).series"
                    type="line"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <h5>Precipitación</h5>
                </div>
                <div class="col-md-12">
                  <apex-charts
                    :height="400"
                    :options="precipitation(place).options"
                    :series="precipitation(place).series"
                    type="line"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <h5>Presión atmosférica</h5>
                </div>
                <div class="col-md-12">
                  <apex-charts
                    :height="400"
                    :options="pressure(place).options"
                    :series="pressure(place).series"
                    type="line"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <h5>Viento</h5>
                </div>
                <div class="col-md-12">
                  <apex-charts
                    :height="400"
                    :options="wind(place).options"
                    :series="wind(place).series"
                    type="line"/>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert, Tabs, TabPane } from "element-ui"
import ApexCharts from "vue-apexcharts"
import es from "@/assets/apex-charts/es.json"

export default {
    components: {
        [Alert.name]: Alert,
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
        ApexCharts
    },
    props: {
        data: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            render: {
                data: false
            },
            options: [],
            model: "",
            chart_data_schema: {
                options: {
                    chart: {
                        locales: [es],
                        defaultLocale: "es"
                    },
                    xaxis: {
                        title: {
                            text: "Fecha/Hora"
                        },
                        type: "datetime",
                        categories: "",
                        labels: {
                            datetimeFormatter: {
                                year: "yyyy",
                                month: "MMM 'yy",
                                day: "dd MMM",
                                hour: "HH:mm"
                            }
                        }
                    },
                    yaxis: {
                        title: {
                            text: ""
                        },
                        labels: {
                            formatter: value => {
                                return value
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false,
                        formatter: function(value) {
                            return value
                        }
                    },
                    tooltip: {
                        x: {
                            format: "dd MMM yyyy HH:MM"
                        }
                    }
                },
                series: []
            }
        }
    },
    methods: {
        temperature(place) {
            let payload = this.$COPY.copy(this.chart_data_schema)
            payload.options.xaxis.categories = place.forecast.map(i => i.from)
            payload.options.yaxis.title.text =
                "Temperatura (" + place.forecast[0].temperature.unit + ")"
            payload.series.push({
                name: "Temperatura",
                type: "line",
                data: place.forecast.map(i => parseFloat(i.temperature.value))
            })

            return payload
        },
        precipitation(place) {
            let payload = this.$COPY.copy(this.chart_data_schema)
            payload.options.xaxis.categories = place.forecast.map(i => i.from)
            payload.options.yaxis.title.text = "Preciptación (mm)"
            payload.series.push({
                name: "Precipitación",
                type: "line",
                data: place.forecast.map(i => parseFloat(i.precipitation.value))
            })

            return payload
        },
        pressure(place) {
            let payload = this.$COPY.copy(this.chart_data_schema)
            payload.options.xaxis.categories = place.forecast.map(i => i.from)
            payload.options.yaxis.title.text =
                "Presión atmosférica (" + place.forecast[0].pressure.unit + ")"
            payload.series.push({
                name: "Presión atmosférica",
                type: "line",
                data: place.forecast.map(i => parseFloat(i.pressure.value))
            })

            return payload
        },
        wind(place) {
            let payload = this.$COPY.copy(this.chart_data_schema)
            payload.options.xaxis.categories = place.forecast.map(i => i.from)
            payload.options.yaxis.title.text = "Velocidad del viento (mps)"
            payload.options.tooltip.y = {
                formatter: (
                    value,
                    { series, seriesIndex, dataPointIndex, w }
                ) => {
                    let item = place.forecast[dataPointIndex]
                    return (
                        "Velocidad del viento: " +
                        value +
                        " mps <br />" +
                        "Clasificación: " +
                        item.wind_speed.name +
                        " <br />" +
                        "Dirección: " +
                        item.wind_direction.name +
                        " (" +
                        item.wind_direction.code +
                        "; " +
                        item.wind_direction.deg +
                        " grados)"
                    )
                },
                title: {
                    formatter: seriesName => ""
                }
            }
            payload.series.push({
                name: "Velocidad del viento",
                type: "line",
                data: place.forecast.map(i => parseFloat(i.wind_speed.mps))
            })

            return payload
        },
        downloadData() {
            this.Services.Utils.downloadJsonAsCsv(this.data)
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {})
        }
    }
}
</script>

<style lang="sass" scoped>
    .data
        min-height: 100px
</style>
