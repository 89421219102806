<template lang="html">
  <div v-loading="loader" class="row">
    <div v-if="!loader && hasRecord" class="col-md-12 text-center">
      <el-alert
        v-if="isFuture"
        :closable="false"
        name="isFuture"
        title="No se puede completar una tarea futura"
        type="info"
        center
        show-icon
      />

      <el-alert
        v-if="isFuture && record.workflow.state == 'scheduled'"
        :closable="false"
        name="timer"
        title="Completar esta tarea hará que la tarea pase al siguiente estado"
        type="info"
        center
        show-icon
      />

      <el-alert
        v-if="!isMyTask && !isNew"
        :closable="false"
        :title="
          'Esta tarea está asignada a: ' +
          getUserName(record.workflow.assignee.$oid)
        "
        :description="getDescription"
        name="isMyTask"
        type="warning"
        center
        show-icon
      />
    </div>
    <div v-if="!loader" class="col-md-12">
      <span class="ms-2 me-2">
        <n-button
          v-if="isNew || transitionsWithUpdate.includes(decision)"
          simple=""
          @click.native="$emit('close')"
        >
          Cerrar
        </n-button>
      </span>
      <n-button
        v-for="(action, key) in actions"
        :key="key"
        :type="getType(action)"
        :disabled="disabledAction(action)"
        class="ms-2 me-2"
        @click.native="sendAction(action)"
      >
        {{ $t(getActionName(action)) }}
      </n-button>
      <n-button v-if="isNew" type="primary" @click.native="$emit('save')">
        {{ saveText }}
      </n-button>
    </div>
    <div v-if="isSupervisor" class="col-md-12">
      <span class="text-muted small">{{
        $t("processControlsEnabledForSupervisors")
      }}</span>
    </div>
  </div>
</template>

<script>
import { Alert } from "element-ui";
import Swal from "sweetalert2";
import { mapGetters, mapState } from "vuex";
import { get } from "lodash";

export default {
  components: {
    [Alert.name]: Alert,
  },
  props: {
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isForm: {
      type: Boolean,
      default: false,
    },
    decision: {
      type: String,
    },
    transitionsWithUpdate: {
      type: Array,
      default: () => {
        return [];
      },
    },
    btnSaveLabel: { type: String },
  },
  data() {
    return {
      loader: true,
      actions: [],
      currentUser: undefined,
      isSupervisor: false,
    };
  },
  computed: {
    ...mapState("Users", ["users"]),
    ...mapGetters({ farmId: "Farms/GET_FARM_ID" }),
    isFuture() {
      return this.$moment(this.record.date) > this.$moment();
    },
    isNew() {
      return this.record.workflow && this.record.workflow.state == "new";
    },
    isScheduled() {
      return this.record.workflow && this.record.workflow.state == "scheduled";
    },
    isMyTask() {
      return this.record.workflow.assignee.$oid == this.currentUser;
    },
    getDescription() {
      return this.record.workflow.starter == this.currentUser
        ? "Esta tarea fue iniciada por ti, por lo que puedes reasignar el responsable"
        : "";
    },
    hasRecord() {
      if (typeof this.record != "object") return false;

      return Object.keys(this.record).length > 0;
    },
    saveText() {
      if (this.btnSaveLabel) return this.btnSaveLabel;

      if (get(this.record, "workflow.state") != "new") {
        return "Guardar cambios";
      } else {
        if (this.$HELPERS.isFuture(this.record.date)) {
          return "Programar";
        } else if (this.$HELPERS.isPast(this.record.date)) {
          return "Registrar";
        } else {
          return "Iniciar";
        }
      }
    },
  },
  created() {
    this.currentUser = this.$store.getters["Auth/GET_USER"]._id.$oid;
    this.isSupervisor = this.$store.getters["Auth/IS_SUPERVISOR"];
    if (!this.isNew) {
      this.getEvents();
    } else {
      this.loader = false;
    }
  },
  methods: {
    async getEvents() {
      try {
        let processKey = this.$route.params.processKey;
        // UGLY: Unificar
        if (this.$route.name == "tasklist-start-process") {
          processKey = this.$route.params.id;
        }
        const r = await this.Services.Processes.getEvents(
          this.farmId,
          processKey,
          this.$route.params.id
        );
        let actions = this.sortEvents(r.data);

        if (this.$route.params.state == "scheduled") {
          actions = actions.filter((i) => i != "complete");
        }

        this.actions = actions;
      } catch (error) {
        console.log(error);
      } finally {
        this.loader = false;
      }
    },
    getUserName(userId) {
      let user = this.users.find((i) => i._id.$oid == userId);

      if (user == undefined) return "CERES";
      else return user.full_name;
    },
    getType(value) {
      return this.$CERES_VARS.data.processEvents.dangerEvents.includes(value)
        ? "danger"
        : "primary";
    },
    getActionName(action) {
      return (action == "update_task") & this.isForm
        ? action + "_save"
        : action;
    },
    disabledAction(action) {
      if (this.isSupervisor) {
        return false;
      } else if (["cancel", "update_task"].includes(action) && this.isMyTask) {
        return false;
      } else {
        return this.isFuture || !this.isMyTask;
      }
    },
    sendAction(action) {
      let vm = this;
      if (action == "cancel") {
        Swal.fire({
          title: "¿Estás seguro de cancelar esta labor?",
          text: "¡La accción no podrá revertirse!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "No,<br/>Quiero conservarla",
          confirmButtonText: "Si,<br/>Quiero cancelarla",
          reverseButtons: true,
          focusCancel: true,
          cancelButtonClass: "primary",
          confirmButtonClass: "danger",
        }).then((result) => {
          if (result.value) vm.$emit("action", action);
        });
      } else {
        this.$emit("action", action);
      }
    },
    sortEvents(events) {
      let sortedEvents = this.$COPY.copy(events);
      let firstEvents = this.$CERES_VARS.data.processEvents.eventOrder.first;
      let lastEvents = this.$CERES_VARS.data.processEvents.eventOrder.last;
      firstEvents.forEach((i) => {
        let index = sortedEvents.indexOf(i);
        if (index > -1) {
          sortedEvents.splice(index, 1);
          sortedEvents.splice(0, 0, i);
        }
      });

      lastEvents.forEach((i) => {
        let index = sortedEvents.indexOf(i);
        if (index > -1) {
          sortedEvents.splice(index, 1);
          sortedEvents.splice(sortedEvents.length, 0, i);
        }
      });
      return sortedEvents;
    },
  },
};
</script>

<style lang="sass" scoped>
.el-alert + .el-alert
  margin-top: 10px
</style>
