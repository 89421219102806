<template lang="html">
  <card
    v-loading="loader">
    <template slot="header">
      <card-controls
        :back="true"
        back-target-name="reports"/>

      <h4 class="card-title"><b>Informe<br>Estados Fenológicos</b></h4>
    </template>

    <div class="row">
      <div class="offset-md-1 col-md-10">
        <div
          v-if="render"
          class="global-filter">
          <div
            class="row">
            <div class="col-md-12 text-left">
              <div class="form-group has-label">
                <label>Seleccione una especie:</label>
              </div>
              <el-radio-group v-model="current_specie">
                <el-radio-button
                  v-for="(specie, index) in report.species"
                  :key="index"
                  :label="specie._id.$oid">
                  {{ specie.value }}
                </el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>

        <template v-if="render && current_specie != ''">
          <div
            v-for="(sector, index) in report.sectors.filter(i => i.specie == current_specie)"
            :key="'sector_'+index"
            class="row">
            <div class="col-md-12 text-left">
              <h5>{{ sector.name }}</h5>
            </div>
            <div class="col-md-12">
              <el-table
                :data="report.data.filter(i => i.sector_id.$oid == sector._id.$oid)">
                <el-table-column
                  label="Fecha"
                  prop="date">
                  <div slot-scope="{row}">
                    {{ row.date | moment("DD-MM-YYYY") }}
                  </div>
                </el-table-column>
                <el-table-column
                  label="Estado Fenológico">
                  <div slot-scope="{row}">
                    {{ report.phenological_stages.find(i => i._id.$oid == row.dominant.$oid).value }}
                  </div>
                </el-table-column>
                <el-table-column
                  label="Días transcurridos"
                  prop="diff_days"
                  align="center"/>
              </el-table>
            </div>
          </div>
        </template>
      </div>
    </div>
  </card>
</template>

<script>
import { Table, TableColumn, RadioGroup, RadioButton } from "element-ui"
export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [RadioGroup.name]: RadioGroup,
        [RadioButton.name]: RadioButton
    },
    data() {
        return {
            loader: true,
            render: false,
            current_specie: "",
            report: {}
        }
    },
    created() {
        this.getReport()
    },
    methods: {
        getReport() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Reports.getReport(farm_id, "phenological_stages")
                .then(response => {
                    vm.report = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                    vm.render = true
                })
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
