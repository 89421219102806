<template lang="html">
  <div
    v-loading="loader"
    class="col-md-12">

    <template
      v-if="!loader">

      <div class="row">
        <div class="col-md-12 text-left">
          <h5>Datos generales</h5>
        </div>
        <div class="col-md-4 text-left">
          <fg-input
            :error="errors.first('date')"
            required
            label="Fecha de la compra">
            <el-date-picker
              v-validate="{required: true}"
              v-model="model.date"
              :disabled="model.workflow.state == 'reporting'"
              data-vv-as="Fecha de la compra"
              name="date"
              format="dd-MM-yyyy"
              type="date"
              placeholder="Fecha de la compra"/>
          </fg-input>
        </div>
        <div class="col-md-4 text-left">
          <fg-input
            :error="errors.first('assignee')"
            class="has-label">
            <label>Responsable <span>*</span></label>
            <el-select
              v-validate="{required: true}"
              v-model="model.workflow.assignee.$oid"
              :disabled="model.workflow.state == 'reporting'"
              name="assignee"
              placeholder="Responsable"
              data-vv-as="Responsable"
              class="select-primary"
              filterable>
              <el-option
                v-for="(user,key) in users"
                :value="user._id.$oid"
                :label="user.full_name"
                :key="key"
                class="select-danger"/>
            </el-select>
          </fg-input>
        </div>
        <div class="col-md-4 text-left">
          <fg-input
            :error="errors.first('supplier_id')"
            class="has-label">
            <label>Proveedor <span>*</span></label>
            <el-select
              v-validate="{required: true}"
              v-model="model.supplier_id.$oid"
              :disabled="model.workflow.state == 'reporting'"
              name="supplier_id"
              placeholder="Proveedor"
              data-vv-as="Proveedor"
              class="select-primary"
              filterable>
              <el-option
                v-for="(supplier,index) in suppliers"
                :key="index"
                :value="supplier._id.$oid"
                :label="supplier.value.name"
                class="select-danger"/>
            </el-select>
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-left">
          <h5>Detalle de productos</h5>
        </div>
        <div class="col-md-12">
          <el-table
            v-validate="{atLeastOnePurchasItem: model.purchase_items}"
            :data="model.purchase_items"
            :error="errors.first('purchase_items')"
            name="purchase_items"
            data-vv-as="Detalle de productos"
            empty-text="No se han agregado ítems aún">
            <el-table-column
              label="Producto">
              <template slot-scope="scope">
                <fg-input
                  v-if="model.workflow.state != 'reporting'"
                  :error="errors.first('product_id')">
                  <el-select
                    v-validate="{required: true}"
                    v-model="model.purchase_items[scope.$index].product_id.$oid"
                    name="product_id"
                    placeholder="Producto"
                    vv-data-as="Producto"
                    class="select-primary"
                    filterable>
                    <el-option-group
                      v-for="group in product_options"
                      :key="group.value"
                      :label="group.label">
                      <el-option
                        v-for="(product,index) in products.filter(i => i.category == group.value)"
                        :key="index"
                        :value="product._id.$oid"
                        :label="getProductLabel(product)"
                        class="select-danger"/>
                    </el-option-group>
                  </el-select>
                </fg-input>
                <span v-else>
                  {{ getProductLabel(scope.row.product) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              header-align="center"
              label="Precio unitario sin IVA (CLP)"
              align="right"
              width="150">
              <template slot-scope="scope">
  <fg-input
    v-validate="{ required: true, min_value: 0 }"
    v-if="model.workflow.state != 'reporting'"
    v-model.number="model.purchase_items[scope.$index].price_per_unit"
    :error="errors.first('price_per_unit_' + scope.$index)"
    :name="'price_per_unit_' + scope.$index"
    type="number"
    data-vv-as="Precio unitario"
  />
  <span v-else>
    {{ scope.row.price_per_unit | toLocaleStringNumber | currency }}
  </span>
</template>
            </el-table-column>
            <el-table-column
              label="Cantidad"
              header-align="center"
              align="right">
              <template slot-scope="scope">
  <template v-if="model.workflow.state != 'reporting'">
    <div class="row">
      <div class="col-8 mt-auto mb-auto">
        <fg-input
          v-validate="{ required: true, min_value: 0 }"
          v-model.number="model.purchase_items[scope.$index].containers"
          :error="errors.first('containers_' + scope.$index)"
          :name="'containers_' + scope.$index"
          type="number"
          data-vv-as="Cantidad"
        />
      </div>
      <div class="col-4 ps-0 mt-2 mb-auto text-left qty-label">
        <span
          v-if="
            (scope.row.product_id.$oid != '') & (scope.row.containers != '')
          "
        >
          = {{ productToAdd(scope) | toLocaleStringNumber("decimal") }}
          {{ findProduct(scope.row.product_id).measure_unit }}
        </span>
        <span v-else> = ?</span>
      </div>
    </div>
  </template>
  <template v-else>
    {{ scope.row.containers | toLocaleStringNumber }}
  </template>
</template>
            </el-table-column>
            <el-table-column
              label="Subtotal"
              header-align="center"
              align="right"
              width="100">
              <div slot-scope="scope">
                {{ itemCost(scope) | toLocaleStringNumber | currency }}
              </div>
            </el-table-column>
            <el-table-column
              v-if="model.workflow.state != 'reporting'"
              label="Acciones"
              align="center"
              header-align="center"
              width="100">
              <template slot-scope="scope">
  <n-button type="danger" size="sm" simple @click.native="rmItem(scope.$index)">
    <i class="fal fa-trash" />
  </n-button>
</template>
            </el-table-column>
          </el-table>
        </div>
        <div
          v-if="model.workflow.state != 'reporting'"
          class="col-md-12 text-right">
          <n-button
            simple=""
            size="sm"
            type="primary"
            @click.native="addItem()">
            <i class="fas fa-plus-circle" />
          </n-button>
        </div>
      </div>

      <div class="row mt-3 subtotal-purchase">
        <div class="col-md-12 text-right">
          <span>
            <b>Total sin IVA:</b>
          </span>
          <span>
            CLP {{ subTotal | toLocaleStringNumber | currency }}
          </span>
        </div>
      </div>

      <div class="row mt-3 subtotal-purchase">
        <div class="col-md-12 text-right">
          <span>
            <b>Impuestos (IVA):  </b>
          </span>
          <span>
            CLP {{ taxes | toLocaleStringNumber | currency }}
          </span>
        </div>
      </div>
      <div class="row mt-3 total-purchase subtotal-purchase">
        <div class="col-md-12 text-right">
          <span>
            <b>Total con IVA:</b>
          </span>
          <span>
            CLP {{ total | toLocaleStringNumber | currency }}
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-left">
          <h5>Documentos asociados</h5>
        </div>
        <div class="col-md-12 text-left text-muted small mb-3">
          <el-alert 
            title="Los ID de éstos documentos se utilizarán para futuras integraciones con sistema ERP/Contables" 
            type="info"
            show-icon />
        </div>
        <div class="col-md-4 col-sm-12 text-left">
          <fg-input
            v-validate="{ required: false, min_value: 1, decimal: false }"
            v-model.number="model.purchase_order_id"
            :error="errors.first('purchase_order_id')"
            name="purchase_order_id"
            type="number"
            label="ID Orden de compra"
            placeholder="ID Orden de compra"
            data-vv-as="ID Orden de compra"/>
        </div>
        <div class="col-md-4 col-sm-12 text-left">
          <fg-input
            v-validate="{ required: false, min_value: 1, decimal: false }"
            v-model.number="model.invoice_id"
            :error="errors.first('invoice_id')"
            name="invoice_id"
            type="number"
            label="ID Factura de compra"
            placeholder="ID Factura de compra"
            data-vv-as="ID Factura de compra"/>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-left">
          <h5>Recepción de productos</h5>
        </div>
        <div class="col-md-4 text-left">
          <fg-input
            :error="errors.first('arrival_date')"
            :label="getArrivalDateLabel"
            required>
            <el-date-picker
              v-validate="{required: true}"
              v-model="model.arrival_date"
              :data-vv-as="getArrivalDateLabel"
              :placeholder="getArrivalDateLabel"
              name="arrival_date"
              format="dd-MM-yyyy"
              type="date"/>
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <slot name="actions"/>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <notification
            :messages="messages"
            :close="true"
            type="error"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Table, TableColumn, OptionGroup, Alert } from "element-ui";
import { Validator } from "vee-validate";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [OptionGroup.name]: OptionGroup,
    [Alert.name]: Alert,
  },
  props: {
    runValidations: {
      type: Number,
      default: 0,
    },
    messages: {
      type: Object,
      default: () => {
        return {};
      },
    },
    initModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loader: true,
      users: [],
      suppliers: [],
      products: [],
      product_options: [
        {
          label: "Fertilizantes",
          value: "fertilizante",
        },
        {
          label: "Fitosanitarios",
          value: "fitosanitario",
        },
      ],
      form_messages: {},
      model: {
        date: "",
        farm_id: "",
        supplier_id: {
          $oid: "",
        },
        purchase_items: [],
        purchase_order_id: "",
        invoice_id: "",
        workflow: {
          state: "new",
          assignee: {
            $oid: "",
          },
        },
      },
      validation_perfomed: false,
    };
  },
  computed: {
    subTotal() {
      return this.model.purchase_items.reduce(
        (acc, i) => acc + i.containers * i.price_per_unit,
        0
      );
    },
    taxes() {
      return Math.round(this.subTotal * 0.19);
    },
    total() {
      return this.subTotal + this.taxes;
    },
    getArrivalDateLabel() {
      return this.$HELPERS.isFuture(this.model.date)
        ? "Fecha de entrega estimada"
        : "Fecha de recepción";
    },
  },
  watch: {
    "model.date": function (newValue, oldValue) {
      this.$emit("dateUpdated", newValue);
    },
    runValidations: function (newValue, oldValue) {
      let vm = this;
      this.$validator.validateAll().then((isValid) => {
        vm.$emit("isValid", isValid, vm.model);
        vm.validation_perfomed = true;
      });
    },
  },
  created() {
    if (Object.keys(this.initModel).length > 0) {
      this.model = this.$COPY.copy(this.initModel);
    }

    if (this.model.farm_id == undefined || this.model.farm_id == "") {
      this.model.farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
    }
    this.getUsers();
  },
  mounted() {
    const hasAtLeastOnePurchaseItem = {
      validate: (value, items) => {
        console.log(items);
        let isValid = items.length > 0 ? false : true;
        return {
          valid: isValid,
          data: {
            message:
              "El detalles de producto debe contener al menos 1 producto",
          },
        };
      },
      getMessage: (field, params, data) => {
        return data.message;
      },
    };
    Validator.extend("atLeastOnePurchasItem", hasAtLeastOnePurchaseItem);
  },
  methods: {
    getUsers() {
      let vm = this;
      this.Services.Users.getUsers()
        .then((response) => {
          vm.users = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.getProducts();
        });
    },
    getProducts() {
      let vm = this;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Products.getProducts(farm_id)
        .then((response) => {
          vm.products = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.getSuppliers();
        });
    },
    getSuppliers() {
      let settings = this.$store.getters["Settings/GET_SETTINGS"];
      if (settings.contacts != undefined) {
        if (settings.contacts.suppliers != undefined) {
          this.suppliers = this.$COPY.copy(settings.contacts.suppliers);
        }
      }
      this.loader = false;
    },
    addItem() {
      this.model.purchase_items.push({
        product_id: {
          $oid: "",
        },
        containers: "",
        qty: "",
        price_per_unit: "",
      });
    },
    rmItem(index) {
      this.model.purchase_items.splice(index, 1);
    },
    getProductLabel(product) {
      let format =
        product.format +
        " de " +
        product.units_per_format +
        " " +
        product.measure_unit;
      return [product.name, format, product.brand].join(" / ");
    },
    findProduct(product_id) {
      return this.products.find((i) => i._id.$oid == product_id.$oid);
    },
    productToAdd(scope) {
      let product = this.findProduct(scope.row.product_id);
      let qty = scope.row.containers * product.units_per_format;
      this.model.purchase_items[scope.$index].qty = qty;
      this.model.purchase_items[scope.$index].format = product.format;
      this.model.purchase_items[scope.$index].units_per_format =
        product.units_per_format;
      return qty;
    },
    itemCost(scope) {
      let cost = scope.row.containers * scope.row.price_per_unit;
      this.model.purchase_items[scope.$index].cost = cost;
      return cost;
    },
  },
};
</script>

<style lang="sass" scoped>
.subtotal-purchase
  font-size: 14px
  span
    min-width: 100px
    display: inline-block
    margin: 0px 10px
    span:first-child
      min-width: 200px
  .total-purchase
    font-size: 14px

  .qty-label
    font-size: 12px
    font-weight: bold
</style>
