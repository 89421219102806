<template lang="html">
  <div v-loading="loaders.warehouse" class="row text-left">
    <div class="col-md-12">
      <fg-input
        v-validate="validations.name"
        v-model="models.warehouse.name"
        :error="errors.first('name')"
        data-vv-as="Nombre de la bodega"
        name="name"
        label="Nombre de la bodega"
        type="text"
        placeholder="Nombre de la bodega"
        required
      />
    </div>
    <div class="col-md-12">
      <div class="form-group has-label">
        <label>Usado para</label>
        <el-select
          v-model="models.warehouse.used_for"
          class="select-primary"
          placeholder="Usado para"
          required
        >
          <el-option
            v-for="(value, key) in selects.usedFor"
            :value="value.value"
            :label="value.name"
            :key="key"
            class="select-danger"
          />
        </el-select>
      </div>
    </div>
    <div v-if="messages.length > 0" class="col-md-12">
      <notification
        v-for="(message, key) in messages"
        :key="key"
        :message="message.message"
        :type="message.type"
        :close="true"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  props: {
    warehouse: {
      type: Object,
      default: () => {
        return {};
      },
    },
    farm: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loaders: {
        warehouse: false,
      },
      messages: [],
      notification: {
        show: false,
        message: "",
        type: "success",
      },
      selects: {
        usedFor: [
          {
            value: "Fertilizantes",
            label: "Fertilizantes",
          },
          {
            value: "Fitosanitarios",
            label: "Fitosanitarios",
          },
          {
            value: "Envases vacíos",
            label: "Envases vacíos",
          },
        ],
      },
      models: {
        warehouse: {
          name: "",
          used_for: "Fertilizantes",
          farm_id: {},
        },
      },
      validations: {
        name: {
          required: true,
        },
      },
    };
  },
  created() {
    if (Object.keys(this.warehouse).length > 0)
      this.models.warehouse = JSON.parse(JSON.stringify(this.warehouse));
  },
  methods: {
    validate(event) {
      let vm = this;
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          if (event == "create") {
            vm.create();
          } else if (event == "update") {
            vm.update();
          }
        }
      });
    },
    create() {
      let vm = this;
      let payload = this.models.warehouse;
      let farm_id = this.farm._id.$oid.toString();

      vm.loaders.warehouse = true;
      vm.messages = [];

      this.Services.Warehouses.createWarehouse({ farm_id, payload })
        .then((response) => {
          vm.$emit("warehouseAdded", vm.models.warehouse);
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          });
        })
        .catch((error) => {
          vm.processMessages(error.response.data.errors, "danger");
        })
        .finally(() => {
          vm.loaders.warehouse = false;
        });
    },
    update() {
      let vm = this;
      let payload = this.models.warehouse;
      let farm_id = this.farm._id.$oid.toString();
      let warehouse_id = this.models.warehouse._id.$oid.toString();
      vm.loaders.warehouse = true;
      vm.messages = [];

      this.Services.Warehouses.updateWarehouse({
        farm_id,
        warehouse_id,
        payload,
      })
        .then((response) => {
          vm.$emit("warehouseAdded", vm.models.warehouse);
        })
        .catch((error) => {
          console.log(error);
          vm.processMessages(error.response.data.errors, "danger");
        })
        .finally(() => {
          vm.loaders.warehouse = false;
        });
    },
  },
};
</script>

<style lang="css"></style>
