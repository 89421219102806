<template lang="html">
  <div v-loading="loader">
    <process-details-view v-if="view_mode" :model="model">
    </process-details-view>
    <process-form
      v-else
      ref="InventoryForm"
      :messages="messages"
      :record="model"
      :run-validations="runValidations"
      @isValid="isValid"
      @dateUpdated="updateDate"
    >
    </process-form>
    <!-- UGLY: Should be in GenericForm or similar -->
    <process-controls-extended
      :record="model"
      :is-form="true"
      :decision="decision"
      :transitions-with-update="transitions_with_update"
      btn-save-label="Registrar"
      @action="processAction"
      @save="validate()"
      @close="closeHandler()"
    >
    </process-controls-extended>
  </div>
</template>

<script>
import ProcessForm from "@/views/Labors/FarmHarvests/Components/FarmHarvestForm";
import ProcessDetailsView from "@/views/Labors/FarmHarvests/Components/FarmHarvestDetailsView";
import ProcessControlsExtended from "@/views/TaskList/Components/ProcessControlsExtended";
import { cloneDeep } from "lodash";
import FarmHarvestService from "@/services/FarmHarvests";
import { getFarmId } from "../../../../mixins";

export default {
  components: {
    ProcessForm,
    ProcessDetailsView,
    ProcessControlsExtended,
  },
  props: {
    variables: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      title: "Resumen de la cosecha",
      back_text: "Cosechas",
      back_custom_target: {
        name: "records-view",
        params: {
          processKey: "farm-harvests",
        },
      },
      view_mode: true,
      loader: false,
      runValidations: 1,
      messages: {},
      model: {
        date: "",
        sector_harvests: [
          {
            sector_id: {
              $oid: "",
            },
            qty: null,
          },
        ],
        workflow: {
          state: "new",
          assignee: {
            $oid: "",
          },
        },
      },
      decision: "",
      transitions_with_update: ["update_task"],
      states_which_start_with_form: ["new"],
    };
  },
  mixins: [getFarmId],
  computed: {
    saveText() {
      return "Guardar cambios";
    },
  },
  created() {
    if (Object.keys(this.variables).length > 0) {
      this.model = this.preProcessVariables();
    }
    this.view_mode = false;
    this.$emit("setBackText", this.back_text);
    this.$emit("setBackCustomTarget", this.back_custom_target);
    this.$emit("setTitle", this.title);
  },
  methods: {
    preProcessVariables() {
      const model = cloneDeep(this.variables);
      return model;
    },
    processAction(action) {
      this.decision = action;
      if (this.transitions_with_update.includes(action) && this.view_mode) {
        this.view_mode = false;
      } else if (this.view_mode) {
        this.startOrCompleteTask();
      } else {
        this.validate();
      }
    },
    updateDate(date) {
      this.model.date = date;
    },
    validate() {
      this.loader = true;
      this.messages = {};
      this.runValidations += 1;
    },
    isValid(isValid, model) {
      this.model = cloneDeep(model);
      isValid ? this.startOrCompleteTask() : (this.loader = false);
    },
    startOrCompleteTask() {
      if (this.model.workflow.hasOwnProperty("state")) {
        if (this.model.workflow.state != "new") {
          this.completeTask();
        } else {
          this.start();
        }
      } else {
        this.start();
      }
    },
    async start() {
      console.log("start", this.model);
      try {
        this.loader = true;
        const r = await FarmHarvestService.createFarmHarvest(
          this.farm_id,
          this.model
        );
        this.$HELPERS.processServerMessage(r.data.messages).then(() => {
          this.$router.push({
            name: "records-view",
            params: {
              processKey: "farm-harvests",
            },
          });
        });
      } catch (error) {
        if (typeof error.response.data != "string") {
          this.messages = error.response.data;
        }
      } finally {
        this.loader = false;
      }
    },
    completeTask() {
      this.$emit(
        "completeTask",
        this.$route.params.processKey,
        this.$route.params.id,
        this.model,
        this.decision
      );
      this.loader = false;
    },
    closeHandler() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="sass" scoped></style>
