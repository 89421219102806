<template lang="html">
  <div class="">
    <card>
      <template slot="header">
        <card-controls
          :back="true"
          :back-custom-target="{
            name: 'view-harvest',
            params: {
              id: $route.params.id,
            },
          }"
        />

        <h4 class="card-title"><b>Editar Cosecha</b></h4>
      </template>

      <div class="row">
        <div class="col-md-10 offset-md-1">
          <harvest-form
            v-loading="loader"
            v-if="Object.keys(model).length > 0"
            :record="model"
            mode="edit"
            @modelUpdated="updateModel"
            @save="updateHarvest"
          />
        </div>
      </div>
      <div v-if="messages.length > 0" class="col-md-12 text-left">
        <notification
          v-for="(message, key) in messages"
          :key="key"
          :message="message.message"
          :type="message.type"
          :close="true"
        />
      </div>
    </card>
  </div>
</template>

<script>
import HarvestForm from "./Components/HarvestForm";
import Swal from "sweetalert2";

export default {
  components: {
    HarvestForm,
  },
  props: {
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      title: "Editar Cosecha",
      model: {},
      loader: false,
      messages: [],
    };
  },
  created() {
    if (Object.keys(this.record).length > 0) {
      this.model = this.$COPY.copy(this.record);
    } else {
      this.$router.push({
        name: "view-harvest",
        params: {
          id: this.$route.params.id,
        },
      });
    }
  },
  methods: {
    updateModel(newModel) {
      this.model = newModel;
    },
    updateHarvest() {
      let vm = this;
      vm.loader = true;
      vm.messages = [];
      let farm = this.$store.getters["Farms/GET_FARM"];
      this.Services.Harvests.updateHarvest(
        farm._id.$oid,
        this.$route.params.id,
        this.model
      )
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          }).then(() => {
            vm.$router.push({
              name: "view-harvest",
              params: {
                id: vm.$route.params.id,
              },
            });
          });
        })
        .catch((error) => {
          console.log(error);
          vm.processMessages(error.response.data, "error");
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped></style>
