<template lang="html">
  <div class="col-md-12 pull-center desktop-table">
    <el-table
      :data="data"
      empty-text="No hay seguimientos de estado fenológico registrados"
      class="table-striped">

      <el-table-column
        v-if="showStatus"
        prop="status"
        label="Estado"
        align="center"
        name="status"
        column-key="status"
        class-name="table-category">
        <div
          slot-scope="{row}">
          <el-tag
            :closable="false"
            :close-transition="false"
            :type="$TASK_STATES.getColor(row.workflow.state)"
            size="small"
            class="used-product-tag">
            {{ $t(row.workflow.state) }}
          </el-tag>
        </div>
      </el-table-column>

      <el-table-column
        prop="date"
        label="Fecha"
        align="center"
        name="date"
        column-key="date"
        class-name="table-category"
        sortable>
        <div
          slot-scope="{row}">
          <i class="el-icon-time"/> {{ row.date | moment("DD-MM-YYYY") }} <br> ({{ row.date | moment("dddd") }})
        </div>
      </el-table-column>

      <el-table-column
        label="Bodegas inventariadas"
        align="center"
        width="250">

        <div
          slot-scope="{row}">
          {{ row.warehouses.map(i => i.name).join(", ") }}
        </div>
      </el-table-column>

      <el-table-column
        label="¿Se detectaron pérdidas?"
        align="center">

        <div
          slot-scope="{row}">
          {{ (row.has_losses) ? "Si" : "No" }}
        </div>
      </el-table-column>

      <el-table-column
        label="Acciones"
        align="center"
        class-name="table-category actions-column">

        <div
          slot-scope="{row}"
          class="text-center">
          <n-button
            type="primary"
            size="sm"
            @click.native="view(row)">
            <i class="fal fa-eye"/>
          </n-button>
        </div>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui"
import pluralize from "pluralize"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    props: {
        data: {
            type: Array,
            default: () => {
                return []
            }
        },
        showStatus: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        view(row) {
            this.$router.push({
                name: "continue-process",
                params: {
                    id: row._id.$oid,
                    processKey: pluralize.singular(
                        this.$route.params.processKey
                    ),
                    state: row.workflow.state
                }
            })
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
