<template lang="html">
  <modal
    :show.sync="modal"
    header-classes="justify-content-center"
    @close="$emit('close')">

    <h4
      slot="header"
      class="title title-up">Calculadora Costo por Cuartel</h4>

    <cost-per-sector-calc-form/>

  </modal>
</template>

<script>
import Modal from "nowui/components/Modal"
import CostPerSectorCalcForm from "./CostPerSectorCalcForm"
export default {
    components: {
        Modal,
        CostPerSectorCalcForm
    },
    data() {
        return {
            modal: true
        }
    }
}
</script>

<style lang="css" scoped>
</style>
