<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row add-space-between-flex-items">
      <div class="text-left flex-1">
        <fg-input :error="errors.first('date')" class="has-label">
          <label>Fecha de la solicitud <span>*</span></label>
          <el-date-picker
            v-validate="{ required: true }"
            v-model="model.date"
            type="date"
            placeholder="Seleccionar fecha"
            name="date"
            format="dd-MM-yyyy"
            data-vv-as="Fecha de la solicitud"
          />
        </fg-input>
      </div>
      <users-select
        :default-user="currentUserId"
        class="flex-1"
        @onChange="model.workflow.assignee.$oid = $event"
      />
    </div>
    <div class="d-flex flex-row add-space-between-flex-items">
      <div class="text-left flex-1">
        <fg-input :error="errors.first('lab_id')" label="Laboratorio" required>
          <el-select
            v-validate="{ required: true }"
            v-if="laboratories.length > 0"
            v-model="model.lab_id"
            :clearable="true"
            data-vv-as="Cuarteles"
            placeholder="Seleccione el laboratorio"
            class="select-primary"
            name="lab_id"
            filterable
          >
            <el-option
              v-for="lab in laboratories"
              :value="lab._id.$oid"
              :label="lab.value.name"
              :key="lab._id.$oid"
              class="select-danger"
            />
          </el-select>
        </fg-input>
      </div>
      <div class="text-left flex-1">
        <fg-input :error="errors.first('category')" label="Categoría" required>
          <el-select
            v-validate="{ required: true }"
            v-model="model.category"
            data-vv-as="Categoría"
            placeholder="Seleccione la categoría"
            class="select-primary"
            name="category"
            no-data-text="Escribe para ingresar una categoría"
            filterable
            allow-create
          >
            <el-option
              v-for="category in categories"
              :value="category"
              :label="category"
              :key="category"
              class="select-danger"
            />
          </el-select>
        </fg-input>
      </div>
    </div>
    <sectors-select
      :default-sectors="model.sector_ids"
      label="Cuarteles asociados"
      @onChange="model.sector_ids = $event"
    />
    <div class="d-flex flex-row add-space-between-flex-items">
      <div class="text-left flex-1">
        <fg-input :error="errors.first('deliver_date')" class="has-label">
          <label>Fecha de entrega <span>*</span></label>
          <el-date-picker
            v-validate="{ required: true }"
            v-model="model.deliver_date"
            type="date"
            placeholder="Seleccionar fecha"
            name="date"
            format="dd-MM-yyyy"
            data-vv-as="Fecha de entrega"
          />
        </fg-input>
      </div>
      <div class="text-left flex-1">
        <fg-input
          v-validate="{ required: true, min_value: 1, decimal: true }"
          v-model.number="model.net_cost"
          :error="errors.first('net_cost')"
          label="Costo neto (sin IVA)"
          name="net_cost"
          type="number"
          placeholder="Valor"
          data-vv-as="Costo neto (sin IVA)"
        />
      </div>
    </div>

    <el-upload
      ref="upload"
      :auto-upload="false"
      :on-preview="handlePreview"
      :on-remove="onRemoveHandler"
      :file-list="currentFiles"
      action="https://jsonplaceholder.typicode.com/posts/"
      class="text-left col-md-4 col-sm-12"
    >
      <n-button slot="trigger" simple type="primary">
        <i class="fas fa-plus-circle" /> Agregar archivo
      </n-button>
      <!-- <div 
        slot="tip" 
        class="el-upload__tip">Solo archivos con un tamaño menor de 500kb</div> -->
    </el-upload>
    <slot name="actions" />
    <slot name="messages" />
  </div>
</template>

<script>
import { Upload } from "element-ui";

import { workflowFormHandler, getFarmId } from "@/mixins/";
import UsersSelect from "@/views/Labors/Components/UsersSelect";
import SectorsSelect from "@/views/Labors/Components/SectorsSelect";

export default {
  components: {
    [Upload.name]: Upload,
    UsersSelect,
    SectorsSelect,
  },
  mixins: [workflowFormHandler, getFarmId],
  data() {
    return {
      title: "Informe de Laboratorio",
      labNames: [],
      categories: [],
      currentFiles: [],
      filesToDelete: [],
      model: {
        date: "",
        category: "",
        lab_id: "",
        deliver_date: "",
        net_cost: "",
        sector_ids: [],
        lab_report_files: [],
        workflow: {
          state: "new",
          assignee: {
            $oid: "",
          },
        },
      },
    };
  },
  computed: {
    laboratories() {
      return this.$store.getters["Settings/GET_CONTACTS_BY_TYPE"]("laboratory");
    },
  },
  created() {
    this.getCategories();
    if (this.model.lab_report_files.length > 0) {
      this.currentFiles = this.model.lab_report_files.map((i) => {
        return { ...i, name: i.filename };
      });
    }
  },
  methods: {
    async getCategories() {
      try {
        const response = await this.Services.LabReports.getCategories(
          this.farm_id
        );
        this.categories = [...response.data];
      } catch (error) {
        console.log(error);
      }
    },
    onRemoveHandler(file, fileList) {
      this.filesToDelete.push(file);
      this.currentFiles = [...fileList];
    },
    async handlePreview(file) {
      try {
        if (file.file_id) {
          const response = await this.Services.Files.getFile(file.file_id);
          this.$HELPERS.downloadFile({
            filename: file.filename,
            content: response.data.content,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = (event) => {
          resolve(event.target.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    },
    async processFiles() {
      const files = this.$refs.upload.uploadFiles;
      let filesToAdd = [];

      for (const file of files) {
        if (!file.file_id) {
          let content = await this.readFile(file.raw);
          filesToAdd.push({
            filename: file.name,
            type: file.raw.type,
            content: content,
          });
        } else {
          filesToAdd.push(file);
        }
      }

      return filesToAdd;
    },
    async beforeSubmit() {
      this.$emit("setLoadingText", "Procesando archivos...");
      const filesToAdd = await this.processFiles();
      this.$set(this.model, "files_to_add", filesToAdd);
      this.$set(this.model, "files_to_delete", this.filesToDelete);
      this.$emit("setLoadingText", "Guardando información...");
      return this.model;
    },
  },
};
</script>

<style lang="sass" scoped></style>
