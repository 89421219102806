<template lang="html">
  <card v-loading="loader">
    <template slot="header">
      <card-controls
        :back="true"
        back-target-name="packages"/>

      <h4 class="card-title"><b>Crear un nuevo formato de venta</b></h4>
    </template>

    <div class="row">
      <div class="col-md-10 offset-md-1">
        <package-format-form
          :run-validations="runValidations"
          :messages="messages"
          @isValid="isValid">

          <template slot="actions">
            <n-button
              type="primary"
              @click.native="validate()">
              Crear formato
            </n-button>
          </template>
        </package-format-form>
      </div>
    </div>

  </card>
</template>

<script>
import { Table, TableColumn } from "element-ui"
import PackageFormatForm from "./PackageFormatForm"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        PackageFormatForm
    },
    data() {
        return {
            loader: false,
            runValidations: 1,
            messages: {},
            model: {
                farm_id: undefined,
                code: undefined,
                name: undefined,
                additional_description: undefined,
                qty_per_format: undefined,
                package_characteristics: []
            }
        }
    },
    methods: {
        validate() {
            let vm = this
            vm.loader = true
            this.runValidations += 1
        },
        isValid(isValid, model) {
            isValid ? this.save(model) : (this.loader = false)
        },
        save(model) {
            let vm = this
            vm.loader = true
            vm.messages = {}
            this.Services.PackageFormats.createPackageFormat(
                model.farm_id,
                model
            )
                .then(response => {
                    vm.$HELPERS
                        .processServerMessage(response.data.messages)
                        .then(() => {
                            vm.$router.push({ name: "package-formats" })
                        })
                })
                .catch(error => {
                    console.log(error)
                    vm.messages = error.response.data
                })
                .finally(() => {
                    vm.loader = false
                })
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
