<template lang="html">
  <div class="d-flex flex-column">
    <contacts-widget
      :contacts="model.suppliers"
      :new-contact-schema="newSupplierSchema"
      schema-key="suppliers"
      section-title="Proveedores"
      section-icon="fas fa-truck-moving"
      @createContact="createContact"
      @updateContact="updateContact"
      @deleteContact="deleteContact"
    />
    <contacts-widget
      :contacts="model.customers"
      :new-contact-schema="newCustomerSchema"
      schema-key="customers"
      section-title="Clientes"
      section-icon="fas fa-handshake"
      @createContact="createContact"
      @updateContact="updateContact"
      @deleteContact="deleteContact"
    />
    <div class="col-md-12 settings-actions">
      <n-button type="primary" class="mt-5" @click.native="validate"
        >Guardar Cambios de la sección</n-button
      >
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Card, Dialog } from "element-ui";
import ContactsWidget from "./Components/Contacts/ContactsWidget";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Card.name]: Card,
    [Dialog.name]: Dialog,
    ContactsWidget,
  },
  props: {
    settings: {
      type: Object,
      default: () => {
        return {};
      },
    },
    messages: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      model: {
        suppliers: [],
        customers: [],
        records_to_delete: [],
      },
      toggleDialog: false,
      supplierTextSearch: "",
      currentContact: {},
      contactFormMode: "new",
      newContactSchema: {
        value: {
          name: "",
          contact_name: "",
          email: "",
          phone: "",
          address: "",
        },
        global: true,
        category: "contacts",
        key: "",
        type: "array",
      },
    };
  },
  computed: {
    hasSuppliers() {
      return this.model.suppliers.length == 0 ? false : true;
    },
    newSupplierSchema() {
      return { ...this.newContactSchema, key: "suppliers" };
    },
    newCustomerSchema() {
      return { ...this.newContactSchema, key: "customers" };
    },
  },
  created() {
    const vm = this;
    if (this.settings.contacts != undefined) {
      Object.keys(this.settings.contacts).forEach((key) => {
        if (vm.settings.contacts[key] != undefined) {
          vm.model[key] = vm
            .$clone(vm.settings.contacts[key])
            .filter((i) => i.value.name != vm.$t("undefined"));
        }
      });
    }
  },
  methods: {
    createContact(type, payload) {
      this.model[type].push(payload);
    },
    updateContact(type, payload) {
      this.model[type][payload.index] = this.$clone(payload.updatedContact);
    },
    deleteContact(type, index) {
      let to_delete = this.model[type].splice(index, 1)[0];
      to_delete.delete = true;
      if (to_delete._id != undefined) {
        this.model.records_to_delete.push(to_delete);
      }
    },
    validate() {
      this.$emit("loader", true);
      let vm = this;
      this.$validator.validateAll().then((isValid) => {
        isValid ? vm.save() : vm.$emit("loader", false);
      });
    },
    save() {
      let out = [];
      Object.keys(this.model).forEach((key) => {
        out = out.concat(this.model[key]);
      });
      this.$emit("save", out);
      this.$emit("loader", false);
    },
  },
};
</script>

<style lang="sass" scoped>
.contact-card
  height: 200px
.cards-container
  overflow-x: auto
</style>
