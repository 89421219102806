<template lang="html">
  <card
    v-loading="loader">
    <template slot="header">
      <card-controls
        :back="true"
        back-target-name="reports"/>

      <h4 class="card-title"><b>Informe de Cosecha Global</b></h4>
    </template>

    <div
      v-if="!loader"
      class="row">
      <div class="col-md-10 offset-md-1">
        <div
          v-if="!loader"
          class="row stat-cards">
          <div class="col-md-6 col-sm-12">
            <stats-card
              :title="currentSeasonHarvest.harvest | toLocaleStringNumber('decimal') + ' Kg'"
              :sub-title="'Kg Cosechados esta temporada (' + currentSeasonHarvest.season + ')'"
              class="report-card"
              type="success"
              icon="far fa-weight-hanging"/>
          </div>
          <div class="col-md-6 col-sm-12">
            <stats-card
              :title="computeBestSeasonTitle"
              class="report-card"
              type="success"
              sub-title="Mejor temporada a la fecha"
              icon="fal fa-chart-line"/>
          </div>
        </div>

        <div
          v-if="!loader"
          class="row charts">
          <div
            class="col-md-12 col-sm-12">
            <apex-charts
              :height="350"
              :options="harvestBySeasonChartData.options"
              :series="harvestBySeasonChartData.series"
              type="bar"/>
          </div>

          <div
            class="col-md-12 col-sm-12">
            <apex-charts
              :height="350"
              :options="harvestBySectorChartData.options"
              :series="harvestBySectorChartData.series"/>
          </div>

          <div
            class="col-md-12 col-sm-12">
            <apex-charts
              :height="350"
              :options="yieldBySectorChartData.options"
              :series="yieldBySectorChartData.series"
              type="bar"/>
          </div>

          <div
            class="col-md-12 col-sm-12">
            <apex-charts
              :height="350"
              :options="harvestByWeekChartData.options"
              :series="harvestByWeekChartData.series"/>
          </div>
        </div>
      </div>
    </div>




  </card>
</template>

<script>
import { Table, TableColumn } from "element-ui"
import ApexCharts from "vue-apexcharts"
export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        ApexCharts
    },
    props: {
        title: {
            type: String,
            default: () => {
                return ""
            }
        }
    },
    data() {
        return {
            loader: true,
            report: {}
        }
    },
    computed: {
        computeBestSeasonTitle() {
            return (
                this.report.best_season.season +
                " (" +
                this.$options.filters.toLocaleStringNumber(
                    this.report.best_season.harvest,
                    "decimal"
                ) +
                " Kg)"
            )
        },
        currentSeasonHarvest() {
            return this.report.harvest_by_season.slice(-1)[0]
        },
        kgPerSectorData() {
            let vm = this
            let payload = {}
            let sectors = [
                ...new Set(
                    this.report.bySector.map(item => item._id.sector.toString())
                )
            ]
            let sector_names = sectors.map(item => {
                return this.sectors.filter(s => s._id.toString() == item)[0]
                    .name
            })

            payload = {
                options: {
                    title: {
                        text: "Kg totales cosechados por cuartel",
                        align: "center"
                    },
                    xaxis: {
                        title: {
                            text: "Cuartel"
                        },
                        categories: sector_names
                    },
                    yaxis: {
                        title: {
                            text: "Kg cosechados"
                        }
                    }
                },
                series: []
            }

            let years = [
                ...new Set(this.report.bySector.map(item => item._id.year))
            ].sort()

            years.forEach(year => {
                let schema = {
                    name: year,
                    data: []
                }
                sectors.forEach(sector => {
                    vm.report.bySector.forEach(item => {
                        if (
                            item._id.year == year &&
                            item._id.sector.toString() == sector
                        ) {
                            schema.data.push(item.kg)
                        } else {
                            schema.data.push(0)
                        }
                    })
                })
                payload.series.push(schema)
            })

            return payload
        },
        kgPerWeek() {
            let vm = this
            let payload = {}
            let xaxisCategories = vm.report.byWeek.map(item => {
                return (
                    item._id.year +
                    "-W" +
                    String(item._id.week).padStart(2, "0")
                )
            })

            payload = {
                options: {
                    title: {
                        text: "Kg totales cosechados por semana y cuartel",
                        align: "center"
                    },
                    xaxis: {
                        title: {
                            text: "Año-Semana"
                        },
                        categories: xaxisCategories
                    },
                    yaxis: {
                        title: {
                            text: "Kg cosechados"
                        }
                    }
                },
                series: [
                    {
                        name: "Kg",
                        type: "column",
                        data: vm.report.byWeek.map(i => i.kg)
                    }
                ]
            }

            // add sector series
            let sectorsId = [
                ...new Set(
                    this.report.byWeekSector.map(item =>
                        item._id.sector.toString()
                    )
                )
            ]

            sectorsId.forEach(sectorId => {
                let seriesSchema = {
                    name: vm.sectors.filter(
                        s => s._id.toString() == sectorId
                    )[0].name,
                    data: []
                }
                vm.report.byWeek.forEach(week => {
                    let data = vm.report.byWeekSector.filter(i => {
                        return (
                            i._id.sector.toString() == sectorId &&
                            i._id.year == week._id.year &&
                            i._id.week == week._id.week
                        )
                    })
                    if (data != undefined && data.length > 0) {
                        seriesSchema.data.push(data[0].kg)
                    } else {
                        seriesSchema.data.push(0)
                    }
                })
                payload.series.push(seriesSchema)
            })

            return payload
        },
        harvestBySectorChartData() {
            let vm = this
            let seasons = this.report.harvest_by_season.map(i => i.season)
            let sectors = this.report.sectors.map(i => i._id.$oid)
            let series = seasons.reduce((series, season) => {
                let schema = {
                    name: season,
                    data: []
                }
                sectors.forEach(k => {
                    let sector_data = vm.report.harvest_by_sector.find(
                        l => (l.season == season) & (l.sector_id.$oid == k)
                    )
                    if (sector_data != undefined) {
                        schema.data.push(sector_data.harvest)
                    } else {
                        schema.data.push(null)
                    }
                })
                series.push(schema)
                return series
            }, [])

            let payload = {
                series: series,
                options: {
                    title: {
                        text: "Kg totales cosechados por cuartel y temporada",
                        align: "center"
                    },
                    xaxis: {
                        title: {
                            text: "Cuartel"
                        },
                        categories: this.report.sectors.map(i => i.name)
                    },
                    yaxis: {
                        title: {
                            text: "Kg cosechados"
                        },
                        decimalsInFloat: 2
                    }
                }
            }

            return payload
        },
        yieldBySectorChartData() {
            let vm = this
            let seasons = this.report.harvest_by_season.map(i => i.season)
            let sectors = this.report.sectors.map(i => i._id.$oid)
            let series = seasons.reduce((series, season) => {
                let schema = {
                    name: season,
                    data: []
                }
                sectors.forEach(k => {
                    let sector_data = vm.report.yield_by_sector.find(
                        l => (l.season == season) & (l.sector_id.$oid == k)
                    )
                    if (sector_data != undefined) {
                        schema.data.push(sector_data.season_yield)
                    } else {
                        schema.data.push(null)
                    }
                })
                series.push(schema)
                return series
            }, [])

            let payload = {
                series: series,
                options: {
                    title: {
                        text: "Rendimiento por cuartel",
                        align: "center"
                    },
                    xaxis: {
                        title: {
                            text: "Cuartel"
                        },
                        categories: this.report.sectors.map(i => i.name)
                    },
                    yaxis: {
                        title: {
                            text: "Rendimiento (kg/planta)"
                        },
                        decimalsInFloat: 2
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function(val) {
                            return val.toFixed(2)
                        }
                    }
                }
            }

            return payload
        },
        harvestBySeasonChartData() {
            let payload = {}

            payload = {
                options: {
                    title: {
                        text: "Kg totales cosechados por temporada",
                        align: "center"
                    },
                    xaxis: {
                        title: {
                            text: "Temporada"
                        },
                        categories: this.report.harvest_by_season.map(
                            item => item.season
                        )
                    },
                    yaxis: {
                        title: {
                            text: "Kg cosechados"
                        },
                        decimalsInFloat: 2
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function(val) {
                            return val.toFixed(2)
                        }
                    }
                },
                series: [
                    {
                        name: "Cosecha",
                        data: this.report.harvest_by_season.map(i => i.harvest)
                    }
                ]
            }

            return payload
        },
        harvestByWeekChartData() {
            let vm = this
            let weeks = [...Array(52).keys()]
            weeks = weeks.reduce((acc, i) => {
                acc.push(i + 1)
                return acc
            }, [])

            let series = this.report.harvest_by_season.reduce(
                (series, season) => {
                    let schema = {
                        name: season.season,
                        data: []
                    }
                    weeks.forEach(i => {
                        let data = vm.report.harvest_by_week.find(
                            k => (k.season == season.season) & (i == k.week)
                        )
                        let value = data != undefined ? data.harvest : null
                        schema.data.push(value)
                    })
                    series.push(schema)
                    return series
                },
                []
            )

            let payload = {
                series: series,
                options: {
                    title: {
                        text: "Kg totales cosechados semana",
                        align: "center"
                    },
                    xaxis: {
                        title: {
                            text: "Semana"
                        },
                        categories: this.report.harvest_by_week.map(i => i.week)
                    },
                    yaxis: {
                        title: {
                            text: "Kg cosechados"
                        },
                        decimalsInFloat: 2
                    }
                }
            }

            return payload
        }
    },
    created() {
        this.getReport()
    },
    methods: {
        getReport() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Reports.getReport(farm_id, "farm_harvest")
                .then(response => {
                    vm.report = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                    vm.render = true
                })
        }
    }
}
</script>

<style lang="sass">
  .card-title
    margin-top: 20px !important
  .charts
    margin: 50px 0px
  .section-title, .stat-cards
    margin-top: 50px
</style>
