<template lang="html">
  <card v-loading="formKey == ''" class="start-process">
    <template slot="header">
      <card-controls :back="true" back-target-name="tasklist" />

      <h4 class="card-title">
        <b>{{ title }}</b>
      </h4>
    </template>

    <div class="row">
      <div class="offset-lg-1 col-lg-10">
        <generic-form
          v-if="formKey != ''"
          :form-key="formKey"
          :process-key="processKey"
          :start-variables="startVariables"
          :is-start-form="true"
          @setTitle="setTitle"
        />
      </div>
    </div>
  </card>
</template>

<script>
import GenericForm from "./GenericForm";
export default {
  components: {
    "generic-form": GenericForm,
  },
  props: {
    startVariables: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      title: "Iniciar una nueva actividad",
      processKey: undefined,
      formKey: "",
    };
  },
  watch: {
    "$route.params": function (to, from) {
      const newProcessKey = this.$HELPERS.stringToClassName(to.id);
      if (newProcessKey !== this.formKey) {
        this.formKey = "";
        this.processKey = newProcessKey;
        this.getProcessDefinition();
      }
    },
  },
  created() {
    this.processKey = this.$HELPERS.stringToClassName(this.$route.params.id);
    this.getProcessDefinition();
  },
  methods: {
    setTitle(title) {
      this.title = title;
    },
    getProcessDefinition() {
      let vm = this;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Processes.getProcessDefinition(farm_id, this.processKey)
        .then((response) => {
          vm.title = response.data.name;
          vm.formKey = "New" + response.data.model;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.start-process
  min-height: 200px
</style>
