<template lang="html">
  <div>
    <div
      v-if="Object.keys(messages).length > 0">
      <template
        v-for="(value, key) in messages">
        <template v-if="!Array.isArray(value)">
          <el-alert
            :key="key"
            :title="value"
            :type="getType"
            show-icon
            class="mt-1"/>
        </template>
        <template v-else>
          <template
            v-for="(sub_msg, sub_key) in value">
            <el-alert
              v-if="sub_msg.hasOwnProperty('description')"
              :key="key + '_' + sub_key"
              :title="sub_msg.title"
              :description="sub_msg.description"
              :type="getType"
              show-icon
              class="mt-1 text-center"/>
            <el-alert
              v-else
              :key="key + '_' + sub_key"
              :title="sub_msg"
              :type="getType"
              show-icon
              class="mt-1"/>
          </template>
        </template>
      </template>
    </div>
    <div
      v-if="show && message.length > 0 && Object.keys(messages).length == 0"
      class="mt-1">
      <el-alert
        :title="message"
        :type="getType"
        show-icon/>
    </div>
  </div>
</template>

<script>
import { FadeTransition } from "vue2-transitions"
import { Alert } from "element-ui"

export default {
    components: {
        FadeTransition,
        [Alert.name]: Alert
    },
    props: {
        close: {
            type: Boolean,
            required: false,
            default: false
        },
        message: {
            type: String,
            default: ""
        },
        messages: {
            type: Object,
            default: () => {
                return {}
            }
        },
        type: {
            type: String,
            required: false,
            default: undefined,
            validator: function(value) {
                return (
                    [
                        "success",
                        "warning",
                        "danger",
                        "info",
                        "error",
                        undefined
                    ].indexOf(value) !== -1
                )
            }
        }
    },
    data() {
        return {
            notification_class: "",
            show: true
        }
    },
    computed: {
        getType() {
            return this.type == "danger" ? "error" : this.type
        }
    },
    created() {
        this.show = close
    },
    methods: {
        closeNotification() {
            this.show = false
            this.$emit("onClose")
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
