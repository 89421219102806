<template lang="html">
  <div v-loading="loading" class="row">
    <div class="col-md-12 text-left setting-section">
      <i class="fas fa-leaf" /> Monitoreo de Estado Fenológico
    </div>
    <phenological-stage-tracking
      v-if="sectors.length > 0"
      :sectors="sectors"
      :settings="settings"
      :run-validations="validations.runValidations"
      class="col-md-12"
      @updateValidation="updateValidation"
      @updatedModel="updateModel"
    />

    <div v-if="sectors.length == 0" class="col-md-12 text-left my-3">
      Debe haber al menos 1 sector para editar esta sección. Puede realizarlo en
      el menú "Mis Campos" de la barra lateral izquierda.
    </div>

    <div class="col-md-12 text-left setting-section top-border">
      <i class="fas fa-bug" /> Monitoreo de Plagas y Enfermedades
    </div>
    <plagues-tracking
      v-if="sectors.length > 0"
      :sectors="sectors"
      :settings="settings"
      :run-validations="validations.runValidations"
      class="col-md-12"
      @updateValidation="updateValidation"
      @updatedModel="updateModel"
    />

    <div v-if="sectors.length == 0" class="col-md-12 text-left my-3">
      Debe haber al menos 1 sector para editar esta sección. Puede realizarlo en
      el menú "Mis Campos" de la barra lateral izquierda.
    </div>

    <div class="col-md-12 text-left setting-section top-border">
      <i class="fas fa-tint" /> Mantención del Sistema de Riego
    </div>
    <WaterSystemMaintenance
      :settings="settings"
      :run-validations="validations.runValidations"
      @updateModel="updateModel"
      @updateValidation="updateValidation"
    />

    <div class="col-md-12 settings-actions">
      <div v-if="messages.length > 0" class="col-md-12 text-left">
        <notification
          v-for="(message, key) in messages"
          :key="key"
          :message="message.message"
          :type="message.type"
          :close="true"
        />
      </div>

      <n-button type="primary" @click.native="validate()">
        Guardar Cambios de la sección
      </n-button>
    </div>
  </div>
</template>

<script>
import PhenologicalStageTracking from "./Activities/PhenologicalStageTracking";
import PlaguesTracking from "./Activities/PlaguesTracking";
import WaterSystemMaintenance from "./Activities/WaterSystemMaintenance";
import Swal from "sweetalert2";
export default {
  components: {
    PhenologicalStageTracking,
    PlaguesTracking,
    WaterSystemMaintenance,
  },
  props: {
    settings: {
      type: Object,
      default: () => {
        return {};
      },
    },
    messages: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      sectors: [],
      species: [],
      validations: {
        runValidations: false,
        forms: {
          phenologicalStageTracking: "",
          plaguesTracking: "",
          WaterSystem: "",
        },
      },
      model: {},
      loading: true,
    };
  },
  watch: {
    "validations.forms": {
      handler: function (newValue, oldValue) {
        if (Object.values(newValue).every((i) => typeof i == "boolean")) {
          if (Object.values(newValue).every((i) => i)) {
            this.save();
          } else {
            this.$emit("loader", false);
            Swal.fire({
              title: "Algunos campos presentan errores",
              text: "Por favor revise más arriba los mensajes",
              icon: "warning",
            });
          }
          this.validations.runValidations = false;
        }
      },
      deep: true,
    },
  },
  created() {
    this.getSectors();
  },
  methods: {
    getSectors() {
      let vm = this;
      let farm_id = vm.$store.getters["Farms/GET_FARM"]._id.$oid.toString();

      this.Services.Sectors.getSectors(farm_id)
        .then((response) => {
          vm.sectors = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validate() {
      this.$emit("loader", true);
      let vm = this;
      Object.keys(this.validations.forms).forEach((form) => {
        vm.validations.forms[form] = "";
      });
      this.validations.runValidations = true;
    },
    updateValidation(isValid, formKey) {
      this.validations.forms[formKey] = isValid;
    },
    updateModel(model) {
      let vm = this;

      Object.keys(model).forEach((key) => {
        vm.model[key] = model[key];
      });
    },
    save() {
      this.$emit("loader", false);
      this.$emit("save", this.model);
    },
  },
};
</script>

<style lang="sass" scoped></style>
