<template lang="html">
  <div
    v-loading="loader"
    class="col-12">
    <div
      v-if="pictureType != 'blank'"
      class="row">
      <div class="col-12">
        <img
          :src="picture_url"
          class="picture"
          @click="modal.previewPicture = true">
      </div>
      <div class="col-12 text-center text-muted small">
        <i
          class="fal fa-search-plus picture-zoom"
          @click="modal.previewPicture = true"/>
      </div>
    </div>
    <div
      v-else
      class="row">
      <div class="col-12 text-muted">
        {{ noPictureText }}
      </div>
    </div>

    <modal
      :show.sync="modal.previewPicture"
      header-classes="justify-content-center">
      <h4
        slot="header"
        class="title title-up">{{ previewPictureTitle }}</h4>

      <img :src="picture_url" >
    </modal>

  </div>
</template>

<script>
export default {
    props: {
        picture: {
            type: String,
            default: undefined
        },
        noPictureText: {
            type: String,
            default: "No se ha subido una fotografía"
        },
        previewPictureTitle: {
            type: String,
            default: "Registro fotográfico"
        }
    },
    data() {
        return {
            loader: true,
            modal: {
                previewPicture: false
            },
            picture_url: ""
        }
    },
    computed: {
        pictureType() {
            if (
                this.picture == undefined ||
                this.picture == "" ||
                this.picture == null
            ) {
                return "blank"
            } else if (this.picture.startsWith("data:image")) {
                return "binary"
            } else {
                return "aws"
            }
        }
    },
    created() {
        if (this.pictureType == "aws") {
            let vm = this
            this.Services.Files.getFile(this.picture)
                .then(response => {
                    vm.picture_url = vm.$HELPERS.contentToFile(response.data)
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        } else {
            this.loader = false
        }
    }
}
</script>

<style lang="sass" scoped>
    .picture
        object-fit: contain
        object-position: 50% 50%
        cursor: pointer
        max-height: 100px
    .picture-zoom
        cursor: pointer
</style>
