<template lang="html">
  <div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info"><i class="fal fa-info-circle" /></div>
              <h5 class="info-title">
                <span>{{ $t(model.workflow.state) }}</span>
              </h5>
              <h6 class="stats-title">Estado</h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info">
                <i class="far fa-calendar-alt" />
              </div>
              <h5 class="info-title">
                <span>{{ model.date | moment("DD-MM-YYYY") }}</span>
              </h5>
              <h6 class="stats-title">Fecha</h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info"><i class="far fa-eye-dropper" /></div>
              <h5 class="info-title">
                <span>{{ model.type }}</span>
              </h5>
              <h6 class="stats-title">Tipo de Aplicación</h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info"><i class="far fa-raindrops" /></div>
              <h5 class="info-title">
                <span>{{ model.wetting | toLocaleStringNumber }}</span>
              </h5>
              <h6 class="stats-title">Mojamiento (L)</h6>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-sm-12 text-left">
        <h5>Cuarteles</h5>
        <el-tag
          v-for="sector in model.sectors"
          :key="sector._id.$oid"
          :closable="false"
          :close-transition="false"
          size="small"
          type="primary"
          class="used-product-tag"
        >
          {{ sector.name }}
        </el-tag>
        <p class="mt-2">
          Superficie total:
          {{ model.total_area | toLocaleStringNumber("decimal") }} Ha
        </p>
      </div>

      <div class="col-md-6 col-sm-12 text-left">
        <h5>Características de la solución</h5>
        pH: {{ model.ph | toLocaleStringNumber("decimal") }}
        <br />
        CE (mmhos/cm): {{ model.ce | toLocaleStringNumber("decimal") }}
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-12 text-left">
        <h5>Detalle de productos utilizados</h5>
      </div>

      <div class="col-md-12 col-sm-12 pull-center desktop-table">
        <el-table
          :data="model.used_products"
          empty-text="¿No se usaron productos?"
          class="table-striped"
        >
          <el-table-column
            prop="category_id"
            label="Categoría"
            align="left"
            class-name="table-category"
          >
            <div slot-scope="{ row }" class="text-left capitalize">
              {{ row.category_id }}
            </div>
          </el-table-column>

          <el-table-column
            prop="product_id"
            label="Producto"
            align="left"
            class-name="table-category"
          >
            <div slot-scope="{ row }" class="text-left">
              {{ row.product.name }}
            </div>
          </el-table-column>

          <el-table-column
            label="Dosis"
            align="right"
            header-align="center"
            class-name="table-category"
          >
            <div slot-scope="{ row }" class="text-align">
              {{ dose(row) }}
            </div>
          </el-table-column>

          <el-table-column
            label="Dosis/Ha"
            align="right"
            header-align="center"
            class-name="table-category"
          >
            <div slot-scope="{ row }" class="text-align">
              {{ dosePerHa(model, row) }}
            </div>
          </el-table-column>

          <el-table-column
            label="Cantidad"
            align="right"
            header-align="center"
            class-name="table-category"
          >
            <div slot-scope="{ row }" class="text-align">
              {{ productUseQuantity(row) }}
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-12 text-left">
        <h5>Detalle por cuartel</h5>
      </div>

      <div class="col-md-12 col-sm-12 pull-center desktop-table">
        <el-table
          :data="model.sectors"
          empty-text="¿No se usaron productos?"
          class="table-striped"
        >
          <el-table-column
            prop="name"
            label="Cuartel"
            align="left"
            class-name="table-category"
          />

          <el-table-column
            prop="area"
            label="Superficie (Ha)"
            align="center"
            header-align="center"
            class-name="table-category"
          >
            <div slot-scope="{ row }">
              {{ row.area | toLocaleStringNumber("decimal") }}
            </div>
          </el-table-column>

          <el-table-column
            v-for="(used_product, index) in model.used_products"
            :key="index"
            :label="used_product.product.name"
            align="center"
            header-align="center"
            class-name="table-category"
          >
            <div slot-scope="{ row }">
              {{ productUseQuantityPerArea(model, used_product, row.area) }}
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="row comments">
      <div class="col-md-12 text-left">
        <h5>Comentarios</h5>
        <p>{{ model.comments | emptyText }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mt-3">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import { ProductApplicationUtils } from "../../../mixins";
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  mixins: [ProductApplicationUtils],
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="css">
.comments {
  margin-top: 30px;
}
</style>
