var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"data-color":'orange',"title":'Agricola Los Silos',"settings-ready":_vm.settingsReady}},[_c('template',{slot:"links"},[_c('user-menu',{attrs:{"title":_vm.user.full_name}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Mis actividades',
          path: '/tasklist/list-view',
          icon: 'fas fa-tasks',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'calendario',
          path: '/tasklist/calendar-view',
          icon: 'fas fa-calendar',
        }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Actividades', icon: 'fas fa-tasks-alt' }}},_vm._l((_vm.workflows),function(value,key){return _c('sidebar-item',{key:key,attrs:{"link":{
            name: value.sidebarName,
            path: value.route,
          }}})}),1),_c('sidebar-item',{attrs:{"link":{ name: 'Recursos', icon: 'fas fa-boxes' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Productos', path: '/dashboard/products' }}}),_vm._l((_vm.resources),function(value,key){return _c('sidebar-item',{key:key,attrs:{"link":{
            name: value.sidebarName,
            path: value.route,
          }}})}),_c('sidebar-item',{attrs:{"link":{
            name: 'Trabajadores',
            path: '/dashboard/human-resources/workers',
          }}})],2),_c('sidebar-item',{attrs:{"link":{
          name: 'Informes',
          path: '/reports',
          icon: 'fas fa-chart-pie',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Calculadoras',
          path: '/tools',
          icon: 'fas fa-calculator-alt ',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Mis Campos',
          path: '/dashboard/farms',
          icon: 'fas fa-globe-americas',
        }}}),(_vm.isAdmin)?_c('sidebar-item',{attrs:{"link":{
          name: 'Configuración',
          path: '/dashboard/settings',
          icon: 'fas fa-cog',
        }}}):_vm._e(),_c('app-info')],1)],2),_c('div',{staticClass:"main-panel"},[(!_vm.BROWSER_IS_SUPPORTED)?_c('el-alert',{staticClass:"alert-fixed",attrs:{"closable":false,"title":"Este navegador no está soportado, prefiera Chrome o Firefox para que Ceres funcione correctamente","type":"error","effect":"dark"}}):_vm._e(),_c('top-navbar',{class:[{ 'browser-not-supported': !_vm.BROWSER_IS_SUPPORTED }]}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.ready),expression:"!ready"}],class:['content', { 'browser-not-supported': !_vm.BROWSER_IS_SUPPORTED }]},[(_vm.ready)?[_c('global-alerts'),_c('zoom-center-transition',{attrs:{"duration":_vm.pageTransitionDuration,"mode":"out-in"}},[_c('router-view',{on:{"setWindowTitle":_vm.setWindowTitle}})],1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }