<template lang="html">
  <card
    v-loading="loader">

    <template slot="header">
      <card-controls
        :back="true"
        back-target-name="reports"/>

      <h4 class="card-title"><b>Informe de Cajas por Cosechero</b></h4>
    </template>

    <!-- <div
      v-if="!loaders.sectors && !loaders.workers && !loaders.report"
      class="row stat-cards">
      <div class="col-md-4 col-sm-12">
        <stats-card
          :title="computeTotalKg | convertToFloat | toString"
          class="report-card"
          type="success"
          sub-title="Kg cosechados"
          icon="far fa-weight-hanging"/>
      </div>
      <div class="col-md-4 col-sm-12">
        <stats-card
          :title="getBestWorker"
          class="report-card"
          type="info"
          sub-title="Mejor cosechero"
          icon="fal fa-thumbs-up"/>
      </div>
      <div class="col-md-4 col-sm-12">
        <stats-card
          :title="getBestYear | toString"
          class="report-card"
          type="warning"
          sub-title="Mejor Año"
          icon="fal fa-chart-line"/>
      </div>
    </div> -->

    <!-- <div
      v-if="!loader && dependencies.sectorsData && dependencies.workersData"
      class="row">
      <div class="col-md-4 text-left">
        <h6>Filtros</h6>
        <fg-input
          label="Cuartel(es)">
          <el-select
            v-model="filters.sectors"
            placeholder="Selecccione uno o más cuarteles"
            class="select-primary"
            name="sectorsFilter"
            multiple>
            <el-option
              v-for="sector in sectorsToFilter"
              :value="sector.value"
              :label="sector.text"
              :key="sector.value"
              class="select-danger"/>

          </el-select>
        </fg-input>
      </div>
    </div> -->

    <!-- <div
      v-if="renderChart"
      class="row charts">
      <div
        class="col-md-12 col-sm-12 limit-height">
        <apex-charts
          :options="kgPerWorkerChartData.options"
          :series="kgPerWorkerChartData.series"
          type="bar"
          height="300"/>
      </div>
    </div> -->


    <!-- <div
      v-if="!loaders.sectors && !loaders.workers && !loaders.report"
      class="row">

      <div class="col-md-12 text-left">
        <h6>Detalles por cosechero
          <n-button
            simple=""
            size="sm"
            type="primary"
            @click.native="boxesThresholdFilter.show = !boxesThresholdFilter.show"
          >
            <i class="fal fa-filter" />
          </n-button>
        </h6>
      </div>

      <div
        v-if="boxesThresholdFilter.show"
        class="col-md-10 global-filter offset-md-1">
        <div class="row">
          <div class="col-md-4 text-left offset-md-2">
            <fg-input
              v-model.number="boxesPerWorkerFilter.min"
              type="number"
              name="boxesThresholdFilter"
              label="Mínimo umbral de potes/cajas mensuales cosechadas"
              placeholder="Umbral mínimo"/>
          </div>

          <div class="col-md-4 text-left">
            <fg-input
              v-model.number="boxesPerWorkerFilter.max"
              type="number"
              name="boxesThresholdFilter"
              label="Máximo umbral de potes/cajas mensuales cosechadas"
              placeholder="Umbral máximo"/>
          </div>
        </div>
      </div>



      <div class="col-md-12 col-sm-12 pull-center desktop-table">
        <el-table
          ref="workerTable"
          :data="filteredReportWithoutSector"
          :empty-text="'No hay registros'"
          class="table-striped">

          <el-table-column
            :filters="yearsToFilter"
            :filter-method="filterByYear"
            label="Año"
            name="year"
            prop="_id.year"
            align="center"
            class-name="table-category"/>

          <el-table-column
            :filters="monthsToFilter"
            :filter-method="filterByMonth"
            label="Mes"
            name="month"
            prop="_id.month"
            align="center"
            class-name="table-category"/>

          <el-table-column
            :formatter="workerFormatter"
            :filters="workersToFilter"
            :filter-method="filterByWorker"
            label="Cosechero"
            name="worker"
            prop="_id.worker"
            align="left"
            class-name="table-category"/>

          <el-table-column
            :formatter="harvestUnitFormatter"
            label="Unidades cosechadas"
            name="boxes_qty"
            header-align="center"
            align="center"
            class-name="table-category"/>

          <el-table-column
            :formatter="harvestTotalFormatter"
            label="Kg cosechados"
            name="kg_qty"
            header-align="center"
            align="right"
            class-name="table-category"/>
        </el-table>
      </div>

      <div class="col-md-12 text-left section-title">
        <h6>Detalle por Cuartel y Cosechero</h6>
      </div>

      <div class="col-md-12 col-sm-12 pull-center desktop-table">
        <el-table
          ref="filterTable"
          :data="filteredReport"
          :empty-text="'No hay registros'"
          class="table-striped">

          <el-table-column
            :formatter="sectorFormatter"
            :filters="sectorsToFilter"
            :filter-method="filterBySector"
            label="Cuartel"
            name="sector"
            prop="_id.sector"
            align="center"
            class-name="table-category"/>

          <el-table-column
            :filters="yearsToFilter"
            :filter-method="filterByYear"
            label="Año"
            name="year"
            prop="_id.year"
            align="center"
            class-name="table-category"/>

          <el-table-column
            :filters="monthsToFilter"
            :filter-method="filterByMonth"
            label="Mes"
            name="month"
            prop="_id.month"
            align="center"
            class-name="table-category"/>

          <el-table-column
            :formatter="workerFormatter"
            :filters="workersToFilter"
            :filter-method="filterByWorker"
            label="Cosechero"
            name="worker"
            prop="_id.worker"
            align="left"
            class-name="table-category"/>

          <el-table-column
            :formatter="harvestUnitFormatter"
            label="Unidades cosechadas"
            name="boxes_qty"
            header-align="center"
            align="center"
            class-name="table-category"/>

          <el-table-column
            :formatter="harvestTotalFormatter"
            label="Kg cosechados"
            name="kg_qty"
            header-align="center"
            align="right"
            class-name="table-category"/>
        </el-table>
      </div>
    </div> -->

    <div
      v-if="!loader"
      class="row">
      <div class="col-md-12 text-right">
        <n-button
          type="danger"
          simple=""
          @click.native="clearFilter()">
          Limpiar filtros
        </n-button>
      </div>
      <div class="col-md-12 col-sm-12 pull-center desktop-table">
        <el-table
          ref="workerTable"
          :data="report.data"
          :empty-text="'No hay registros'"
          class="table-striped">

          <el-table-column
            :filters="yearsToFilter"
            :filter-method="filterByYear"
            prop="year"
            label="Año"
            name="year"
            align="center"
            sortable/>

          <el-table-column
            :filters="monthsToFilter"
            :filter-method="filterByMonth"
            prop="month"
            label="Mes"
            name="month"
            align="center"
            sortable/>

          <el-table-column
            :formatter="workerFormatter"
            :filters="workersToFilter"
            :filter-method="filterByWorker"
            label="Cosechero"
            name="worker"
            prop="worker_id.$oid"
            align="left"
            sortable/>

          <el-table-column
            :formatter="harvestUnitFormatter"
            label="Unidades cosechadas"
            name="boxes_qty"
            header-align="center"
            align="center"/>

          <el-table-column
            label="Kg cosechados"
            name="kg_qty"
            header-align="center"
            align="right"
            sortable>
            <div slot-scope="{row}">
              {{ row.harvest | toLocaleStringNumber("decimal") }}
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>


  </card>
</template>

<script>
import { Table, TableColumn, Switch } from "element-ui"
import ApexCharts from "vue-apexcharts"
export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Switch.name]: Switch,
        ApexCharts
    },
    props: {
        title: {
            type: String,
            default: () => {
                return ""
            }
        }
    },
    data() {
        return {
            loader: true,
            // loaders: {
            //     sectors: true,
            //     workers: true,
            //     report: true
            // },
            // sectors: [],
            // workers: [],
            report: {}
            // currentData: [],
            // filters: {
            //     sectors: [],
            //     years: []
            // },
            // boxesThresholdFilter: {
            //     show: false,
            //     operation: "min",
            //     value: ""
            // },
            // boxesPerWorkerFilter: {
            //     min: "",
            //     max: ""
            // },
            // stats: {
            //     totalKg: 0
            // }
        }
    },
    computed: {
        yearsToFilter() {
            let years = [...new Set(this.report.data.map(item => item.year))]
            return years.map(item => {
                return {
                    value: item,
                    text: item
                }
            })
        },
        monthsToFilter() {
            let months = [...new Set(this.report.data.map(item => item.month))]
            return months.map(item => {
                return {
                    value: item,
                    text: item
                }
            })
        },
        //     sectorsToFilter() {
        //         let sectors = [
        //             ...new Set(
        //                 this.report.details.map(item => item._id.sector.toString())
        //             )
        //         ]
        //         return sectors.map(item => {
        //             return {
        //                 value: item,
        //                 text: this.sectors.filter(s => s._id.toString() == item)[0]
        //                     .name
        //             }
        //         })
        //     },
        workersToFilter() {
            return this.report.workers.map(item => {
                return {
                    value: item._id.$oid,
                    text: item.full_name
                }
            })
        }
        //     computeTotalKg() {
        //         return this.report.details.reduce(
        //             (total, item) =>
        //                 total + item.boxes * item._id.harvest_unit_weight,
        //             0
        //         )
        //     },
        //     getBestWorker() {
        //         let worker_id = this.report.workersRanking[0]._id.worker.toString()
        //         return (
        //             this.workers.filter(w => w._id.toString() == worker_id)[0]
        //                 .name +
        //             " (" +
        //             this.report.workersRanking[0].kg +
        //             " kg)"
        //         )
        //     },
        //     getBestYear() {
        //         let report = this.$COPY.copy(this.report)
        //         let years = report.byYear.sort(
        //             (a, b) => (a.kg < b.kg ? 1 : b.kg < a.kg ? -1 : 0)
        //         )
        //         return years[0]._id.year + " (" + years[0].kg + " kg)"
        //     },
        //     filteredReport() {
        //         if (this.filters.sectors.length > 0) {
        //             return this.report.details.filter(data =>
        //                 this.filters.sectors.includes(data._id.sector.toString())
        //             )
        //         } else {
        //             return this.report.details
        //         }
        //     },
        //     renderChart() {
        //         if (
        //             !this.loaders.report &&
        //             !this.loaders.sectors &&
        //             !this.loaders.workers
        //         ) {
        //             return true
        //         } else {
        //             return false
        //         }
        //     },
        //     kgPerWorkerChartData() {
        //         let payload = {}
        //         let data = this.report.details.reduce((agg, item) => {
        //             let worker = this.workers.filter(
        //                 s => s._id.toString() == item._id.worker.toString()
        //             )[0].name
        //             agg[worker] =
        //                 (agg[worker] || 0) +
        //                 item.boxes * item._id.harvest_unit_weight
        //             return agg
        //         }, {})
        //
        //         payload = {
        //             options: {
        //                 title: {
        //                     text: "Kg Totales Cosechados por Cosechero",
        //                     align: "center"
        //                 },
        //                 xaxis: {
        //                     title: {
        //                         text: "Cosecheros"
        //                     },
        //                     categories: Object.keys(data)
        //                 },
        //                 yaxis: {
        //                     title: {
        //                         text: "Kg Cosechados"
        //                     },
        //                     decimalsInFloat: 2
        //                 },
        //                 dataLabels: {
        //                     formatter: function(val, opts) {
        //                         return val.toFixed(2)
        //                     }
        //                 }
        //             },
        //             series: [
        //                 {
        //                     name: "Cosecheros",
        //                     data: Object.values(data)
        //                 }
        //             ]
        //         }
        //
        //         return payload
        //     },
        //     filteredReportWithoutSector() {
        //         if (this.boxesThresholdFilter.show) {
        //             let min = this.boxesPerWorkerFilter.min
        //             let max = this.boxesPerWorkerFilter.max
        //
        //             return this.report.withoutSector.filter(i => {
        //                 if (min != "" && max != "") {
        //                     return i.boxes >= min && i.boxes <= max
        //                 } else if (min != "") {
        //                     return i.boxes >= min
        //                 } else if (max != "") {
        //                     return i.boxes <= max
        //                 } else {
        //                     return true
        //                 }
        //             })
        //         } else {
        //             return this.report.withoutSector
        //         }
        //     }
        // },
        // watch: {
        //     "boxesThresholdFilter.show": function(newValue, oldValue) {
        //         if (!newValue) {
        //             this.boxesThresholdFilter.value = ""
        //             this.boxesThresholdFilter.operation = "min"
        //         }
        //     }
    },
    created() {
        // this.getSectors()
        // this.getWorkers()
        // this.getResults()
        this.getReport()
    },
    methods: {
        getReport() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Reports.getReport(farm_id, "workers_harvest")
                .then(response => {
                    vm.report = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                    vm.render = true
                })
        },
        // init Report
        // getResults() {
        //     let vm = this
        //     this.$store
        //         .dispatch("Reports/boxesPerWorker")
        //         .then(response => {
        //             let data = response.data.variables.report.value
        //             vm.report = this.$EJSON.parse(data, { relaxed: true })
        //             console.log(vm.report)
        //         })
        //         .catch(error => {
        //             console.log(error)
        //         })
        //         .finally(() => {
        //             vm.loaders.report = false
        //         })
        // },
        // getSectors() {
        //     let vm = this
        //     let payload = {
        //         farm_id: vm.$store.getters["Farms/GET_FARM"]._id.toString()
        //     }
        //
        //     this.$store
        //         .dispatch("Sectors/getSectors", { payload })
        //         .then(response => {
        //             vm.sectors = vm.$EJSON.parse(
        //                 response.data.variables.sectors.value,
        //                 {
        //                     strict: false
        //                 }
        //             )
        //         })
        //         .catch(error => {
        //             console.log(error)
        //         })
        //         .finally(() => {
        //             vm.loaders.sectors = false
        //         })
        // },
        // getWorkers() {
        //     let vm = this
        //     this.$store
        //         .dispatch("Workers/getWorkers")
        //         .then(response => {
        //             vm.workers = vm.$EJSON.parse(
        //                 response.data.variables.workers.value,
        //                 {
        //                     strict: false
        //                 }
        //             )
        //         })
        //         .catch(error => {
        //             console.log(error)
        //         })
        //         .finally(() => {
        //             vm.loaders.workers = false
        //         })
        // },
        // column Formatters
        workerFormatter(row, column) {
            return this.report.workers.find(
                w => w._id.$oid === row.worker_id.$oid
            ).full_name
        },
        harvestUnitFormatter(row, column) {
            let text = ""
            if (row.boxes > 1) {
                text = " unidades de "
            } else {
                text = " unidad de "
            }
            return row.boxes + text + row.harvest_unit_weight + " kg"
        },
        sectorFormatter(row, column) {
            return this.sectors.filter(
                s => s._id.toString() == row._id.sector.toString()
            )[0].name
        },
        // columns filters
        clearFilter() {
            this.$refs.workerTable.clearFilter()
        },
        filterByYear(value, row, column) {
            return row.year == value
        },
        filterByMonth(value, row, column) {
            return row.month == value
        },
        filterBySector(value, row, column) {
            return row._id.sector.toString() == value
        },
        filterByWorker(value, row, column) {
            return row.worker_id.$oid == value
        }
    }
}
</script>

<style lang="sass">
	.card-title
		margin-top: 20px !important
	.charts
		margin: 50px 0px
	.section-title, .stat-cards
		margin-top: 50px
</style>
