<template lang="html">
  <nav
    :class="navBarClass+' navbar navbar-expand-lg navbar-' + navbarType"
    data-navbar="fixed"
  >
    <div 
      v-if="showBackDrop" 
      class="backdrop backdrop-navbar" 
      @click="toggleMenu"/>
    <div class="container">
      <div class="navbar-left">

        <button 
          class="navbar-toggler" 
          type="button" 
          @click="toggleMenu">&#9776;</button>
        <a 
          class="navbar-brand" 
          href="#">
          <img 
            :src="logo.light" 
            class="logo-light" >
          <img 
            :src="logo.dark" 
            class="logo-dark" >
        </a>
      </div>

      <section 
        v-if="navbarType == 'light'" 
        class="navbar-mobile">
        <nav class="nav nav-navbar ms-auto">
          <a 
            class="nav-link" 
            @click="goToSection('home')">Inicio</a>
          <a 
            class="nav-link" 
            @click="goToSection('features')" >Características</a>
          <a 
            class="nav-link" 
            @click="goToSection('pricing')">Precios</a>
          <a 
            class="nav-link" 
            @click="goToSection('faq')" >FAQ</a>
          <a 
            class="nav-link" 
            @click="goToSection('contact')">Contacto</a>
        </nav>
      </section>
      <a 
        class="btn btn-xs btn-round btn-primary signin"
        @click="$router.push('/auth/login')">
        <i class="fal fa-sign-in-alt"/>
        Acceder
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    navbarType: {
      type: String,
      default: "light",
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      logo: {
        light: require("@/assets/img/ceres_logo.png"),
        dark: require("@/assets/img/ceres_logo_dark.png"),
      },
      showBackDrop: false,
      navBarClass: "",
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    toggleMenu() {
      this.showBackDrop = !this.showBackDrop;
      if (this.showBackDrop) this.$emit("showBackDrop");
      else this.$emit("hideBackDrop");
    },
    goToSection(section) {
      this.showBackDrop = false;
      document.getElementById(section).scrollIntoView();
      this.$emit("hideBackDrop");
    },
    handleScroll(e) {
      if (window.scrollY > 20) this.navBarClass = "stick";
      else this.navBarClass = "";
    },
  },
};
</script>

<style lang="sass" scoped>
.navbar-scrolled
  .nav-link
    color: unset
    .logo-dark
      display: inline-block
    .logo-light
      display: none
</style>
