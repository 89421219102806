<template lang="html">
  <div>
    <div class="row">
      <div class="col-md-6 col-sm-6 text-left">
        <fg-input
          v-validate="{required: true,strength: true}"
          v-if="strengthValidation"
          ref="new_password"
          v-model="model.credentials.password"
          :error="errors.first('new_password')"
          data-vv-as="Contraseña"
          name="new_password"
          type="password"
          label="Contraseña"/>

        <fg-input
          v-validate="{required: true}"
          v-else
          ref="new_password"
          v-model="model.credentials.password"
          :error="errors.first('new_password')"
          data-vv-as="Contraseña"
          name="new_password"
          type="password"
          label="Contraseña"/>

        <password-strength
          v-if="strengthValidation"
          :secure-length="passwordStrengthConfig.secureLength"
          v-model="model.credentials.password"
          :strength-meter-only="true"
          @score="handlePasswordScore"/>
      </div>

      <div class="col-md-6 col-sm-6 text-left">
        <fg-input
          v-validate="'required|confirmed:new_password'"
          ref="password_confirmation"
          v-model="model.credentials.password_confirmation"
          :error="errors.first('password_confirmation')"
          data-vv-as="Confirmar Contraseña"
          name="password_confirmation"
          type="password"
          label="Confirmar Contraseña"/>
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from "vee-validate"
import PasswordStrength from "vue-password-strength-meter"
import zxcvbn from "zxcvbn"

export default {
    components: {
        PasswordStrength
        // [Password.name]: Password
    },
    props: {
        strengthValidation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            model: {
                credentials: {
                    password: "",
                    password_confirmation: ""
                }
            },
            passwordStrengthConfig: {
                secureLength: 7
            },
            passwordScore: 0,
            passwordIsSecure: false
        }
    },
    watch: {
        "model.credentials": {
            handler: function() {
                this.$emit("userCredentialsChanged", this.model.credentials)
            },
            deep: true
        }
    },
    beforeMount() {
        Validator.extend("strength", {
            getMessage: field => "Contraseña poco segura",
            validate: value => {
                let score = zxcvbn(value).score
                return score >= 3
            }
        })
    },
    methods: {
        handlePasswordScore(score) {
            if (score >= 3) {
                this.$emit("securePassword", true)
            } else {
                this.$emit("securePassword", false)
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
