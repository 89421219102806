import { ceres_api } from "@/axios-config.js"
import CopyObject from "@/utils/CopyObject.js"

const Polinizations = {
    createPolinization(farm_id, model) {
        let endpoint = "/farms/" + farm_id + "/polinizations"
        return ceres_api.post(endpoint, model)
    },
    getPolinizations(farm_id, filter) {
        let endpoint = "/farms/" + farm_id + "/polinizations"
        if (filter == undefined) {
            return ceres_api.get(endpoint)
        } else {
            return ceres_api.post(endpoint + "/filter", { filter: filter })
        }
    },
    getPolinization(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/polinizations/"
        return ceres_api.get(endpoint + id)
    },
    deletePolinization(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/polinizations/"
        return ceres_api.delete(endpoint + id)
    },
    updatePolinization(farm_id, id, model) {
        let endpoint = "/farms/" + farm_id + "/polinizations/"
        return ceres_api.put(endpoint + id, model)
    },
    simplifyPolinization(data) {
        let model = CopyObject.copy(data)
        model.sector_ids = model.sector_ids.map(i => i.$oid)
        model.worker_ids = model.worker_ids.map(i => i.$oid)
        delete model.sectors
        delete model.workers
        delete model.updated_at
        delete model.created_at
        return model
    }
}

export default Polinizations
