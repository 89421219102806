var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 pull-center desktop-table"},[_c('el-table',{staticClass:"table-striped",attrs:{"data":_vm.data,"empty-text":"No hay seguimientos de estado fenológico registrados"}},[(_vm.showStatus)?_c('el-table-column',{attrs:{"prop":"status","label":"Estado","align":"center","name":"status","column-key":"status","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-tag',{staticClass:"used-product-tag",attrs:{"closable":false,"close-transition":false,"type":_vm.$TASK_STATES.getColor(row.workflow.state),"size":"small"}},[_vm._v(" "+_vm._s(_vm.$t(row.workflow.state))+" ")])],1)}}],null,false,1504213643)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"date","label":"Fecha del empaquetamiento","align":"center","header-align":"center","name":"date","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('i',{staticClass:"el-icon-time"}),_vm._v(" "+_vm._s(_vm._f("moment")(row.date,"DD-MM-YYYY"))+" "),_c('br'),_vm._v(" ("+_vm._s(_vm._f("moment")(row.date,"dddd"))+") ")])}}])}),_c('el-table-column',{attrs:{"prop":"due_date","label":"Fecha de vencimiento","align":"center","header-align":"center","name":"date","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('i',{staticClass:"el-icon-time"}),_vm._v(" "+_vm._s(_vm._f("moment")(row.due_date,"DD-MM-YYYY"))+" "),_c('br'),_vm._v(" ("+_vm._s(_vm._f("moment")(row.date,"dddd"))+") ")])}}])}),_c('el-table-column',{attrs:{"label":"Formato de venta","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" ("+_vm._s(row.package_format.code)+") "+_vm._s(row.package_format.name)+" ")])}}])}),_c('el-table-column',{attrs:{"label":"Material embalado","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.allowed_raw_material.specie_name)+" "+_vm._s(row.allowed_raw_material.variety_name)+" "),_c('br'),_vm._v(" "+_vm._s(row.packages)+" Unidades = "+_vm._s(row.raw_material_qty)+" "+_vm._s(_vm.$store.getters["Farms/GET_YIELD_TYPE"])+" ")])}}])}),_c('el-table-column',{attrs:{"label":"Acciones","align":"center","class-name":"table-category actions-column"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-center"},[_c('n-button',{attrs:{"type":"primary","size":"sm"},nativeOn:{"click":function($event){return _vm.view(row)}}},[_c('i',{staticClass:"fal fa-eye"})])],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }