<template lang="html">
  <modal :show.sync="modals.deleteSector" @close="$emit('close')">
    <h4 slot="header" class="title title-up center">¡Cuidado!</h4>

    ¿Estás seguro de eliminar el cuartel "{{ sector.name }}"?

    <br />
    <div v-loading="loader">
      <n-button class="big-btn" type="primary" @click.native="deleteSector">
        <div class="btn-label">Sí</div>

        <div class="btn-help"><br />Eliminar<br /></div>
      </n-button>
      <n-button class="big-btn" type="primary" @click.native="$emit('close')">
        <div class="btn-label">No</div>
        <div class="btn-help">
          <br />
          Mantenerlo
        </div>
      </n-button>
    </div>
  </modal>
</template>

<script>
import Modal from "nowui/components/Modal";
import Swal from "sweetalert2";

export default {
  components: {
    Modal,
  },
  props: {
    sector: {
      type: Object,
      default: () => {
        return {};
      },
    },
    farm: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loader: false,
      modals: {
        deleteSector: true,
      },
    };
  },
  methods: {
    deleteSector() {
      let vm = this;
      let sector_id = this.sector._id.$oid.toString();
      let farm_id = this.farm._id.$oid.toString();

      vm.loader = true;

      this.Services.Sectors.deleteSector({ farm_id, sector_id })
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          });

          this.$emit("deleted");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
};
</script>
