<template lang="html">
  <cost-per-sector-calc-form
    ref="costPerSectorCalcForm"
    :init-model="initModel"
    :show-cost-per-unit="true"
    :messages="messages"
    default-cost-variable="num_of_plants"
    @modelUpdated="updateModel"
    @render="render = true"
  >
    <span slot="atTheBeginning">
      <div class="row">
        <div class="col-md-6">
          <fg-input :error="errors.first('date')" class="has-label">
            <label>Fecha del control de malezas <span>*</span></label>
            <el-date-picker
              v-validate="{ required: true }"
              v-model="model.date"
              type="date"
              placeholder="Seleccionar fecha"
              name="date"
              data-vv-as="Fecha del control de malezas"
            />
          </fg-input>
        </div>
        <div class="col-md-6">
          <fg-input
            :error="errors.first('assignee')"
            label="Responsable"
            required
          >
            <el-select
              v-validate="{ required: true }"
              v-model="model.workflow.assignee.$oid"
              name="assignee"
              class="select-primary"
              data-vv-as="Responsable"
              filterable
            >
              <el-option
                v-for="(user, key) in users"
                :value="user._id.$oid"
                :label="user.full_name"
                :key="key"
                class="select-danger"
              />
            </el-select>
          </fg-input>
        </div>
      </div>
    </span>

    <span slot="afterTotalCost">
      <fg-input
        :error="errors.first('worker_id')"
        :required="areWorkersRequired == 'required' ? true : false"
        label="Desmalezadores"
      >
        <el-select
          v-validate="areWorkersRequired"
          v-model="model.worker_ids"
          :multiple="true"
          name="worker_id"
          class="select-primary"
          data-vv-as="Desmalezador(a)"
          filterable
        >
          <el-option
            v-for="(worker, key) in workers"
            :value="worker._id.$oid"
            :label="worker.full_name"
            :key="key"
            class="select-danger"
          />
        </el-select>
      </fg-input>
    </span>

    <span slot="actions">
      <slot name="actions" />
    </span>
  </cost-per-sector-calc-form>
</template>

<script>
import CostPerSectorCalcForm from "@/views/Tools/CostPerSectorCalc/CostPerSectorCalcForm";
export default {
  components: {
    CostPerSectorCalcForm,
  },
  props: {
    runValidation: {
      type: Boolean,
      default: false,
    },
    initModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    messages: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      requestChildModel: false,
      users: [],
      workers: [],
      model: {
        date: "",
        cost_variable: "num_of_plants",
        workflow: {
          assignee: {
            $oid: "",
          },
        },
        worker_ids: [],
      },
    };
  },
  computed: {
    areWorkersRequired() {
      if (
        (Object.keys(this.initModel).length == 0) &
        this.$HELPERS.isFuture(this.model.date)
      ) {
        return "";
      } else {
        return "required";
      }
    },
  },
  watch: {
    model: {
      handler: function () {
        this.requestChildModel = true;
        let model = this.$COPY.copy(this.model);
        this.$emit("modelUpdated", model);
      },
      deep: true,
    },
    runValidation: function (newValue, oldValue) {
      if (newValue) this.validateForm();
    },
  },
  created() {
    if (Object.keys(this.initModel).length > 0) {
      let vm = this;
      Object.keys(this.initModel).forEach((key) => {
        if (key in vm.model) vm.model[key] = this.initModel[key];
      });
    }
    this.getUsers();
  },
  methods: {
    getUsers() {
      let vm = this;
      this.Services.Users.getUsers()
        .then((response) => {
          vm.users = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.getWorkers();
        });
    },
    getWorkers() {
      let vm = this;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      let fields = ["_id", "full_name", "skills"];
      this.Services.Workers.getWorkers({ farm_id, fields })
        .then((response) => {
          vm.workers = response.data.filter((w) =>
            w.skills.includes("Desmalezador")
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    updateModel(model) {
      this.requestChildModel = false;
      this.model = Object.assign(this.model, model.calculator);
      this.$emit("modelUpdated", this.model);
    },
    validateForm() {
      let vm = this;
      let validations = {
        weedingForm: undefined,
        costPerSectorCalcForm: undefined,
      };
      vm.$validator
        .validateAll()
        .then((isValid) => {
          validations.weedingForm = isValid;
        })
        .finally(() => {
          secondValidation();
        });

      function secondValidation() {
        vm.$refs.costPerSectorCalcForm.$validator
          .validateAll()
          .then((isValid) => {
            validations.costPerSectorCalcForm = isValid;
          })
          .finally(() => {
            finalValidation();
          });
      }

      function finalValidation() {
        let isValid = Object.values(validations).every((i) => i);
        vm.$emit("validForm", isValid);
      }
    },
  },
};
</script>

<style lang="sass" scoped></style>
