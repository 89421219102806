<template>
  <div>
    <process-controls
      v-if="!emptyVariables"
      :record="variables"
      :is-form="true"
      @action="$emit('executeDecision', $event)"
    >
      <template slot="extra-buttons">
        <n-button
          v-if="transitionsWithUpdate.includes(decision)"
          simple
          @click.native="discard"
        >
          Cerrar
        </n-button>
      </template>
    </process-controls>
    <template v-else>
      <n-button simple @click.native="discard"> Cerrar </n-button>
      <n-button type="primary" @click.native="$emit('validate')">
        {{ saveText }}
      </n-button>
    </template>
  </div>
</template>

<script>
import ProcessControls from "@/views/TaskList/Components/ProcessControls";

export default {
  components: {
    ProcessControls,
  },
  props: {
    variables: {
      type: Object,
      default: () => {
        return {};
      },
    },
    transitionsWithUpdate: {
      type: Array,
      default: () => {
        return [];
      },
    },
    decision: {
      type: String,
    },
    saveText: {
      type: String,
    },
  },
  computed: {
    emptyVariables() {
      return Object.keys(this.variables).length == 0;
    },
  },
  methods: {
    discard() {
      this.$emit("discard");
    },
  },
};
</script>
