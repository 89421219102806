<template lang="html">
  <section 
    id="roadmap" 
    class="section bg-gray">
    <div class="container">
      <header class="section-header">
        <h2 v-html="data.roadmap_title"/>
        <hr >
      </header>

      <ol class="timeline timeline-horizontal">
        <li
          v-for="(item, index) in data.roadmap_milestones"
          :key="index"
          class="timeline-item">
          <h6><strong>{{ item.name }}</strong></h6>
          <span v-html="item.description"/>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
