<template>
  <div v-loading="loading" class="row">
    <div v-if="ready" class="col-12">
      <div class="row">
        <div class="col-12 text-left setting-subsection">
          Actividades habilitadas
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-left">
          <el-checkbox-group
            v-model="model.enabledActivities.value"
            class="vertical"
          >
            <el-checkbox
              v-for="item in allProcesses"
              :key="item.model"
              :label="item.model"
            >
              {{ $t(item.workflow) }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12 text-left text-muted">
          Nota: Se borrarán los datos de las actividades deshabilitadas,
          simplemente se ocultarán para todos los usuarios.
        </div>
      </div>
      <div class="row settings-actions">
        <div v-if="messages.length > 0" class="col-md-12 text-left">
          <notification
            v-for="(message, key) in messages"
            :key="key"
            :message="message.message"
            :type="message.type"
            :close="true"
          />
        </div>

        <div class="col-md-12 text-center">
          <n-button type="primary" @click.native="saveChanges()">
            Guardar Cambios de la sección
          </n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Checkbox, CheckboxGroup } from "element-ui";
import { mapActions, mapState } from "vuex";
import { has, cloneDeep } from "lodash";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  props: {
    messages: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      loading: true,
      ready: false,
      model: {
        enabledActivities: {
          value: [],
          category: "activities",
          key: "enabled",
          type: "value",
        },
      },
    };
  },
  created() {
    this.setup();
  },
  computed: {
    ...mapState("Processes", ["allProcesses"]),
    ...mapState("Settings", ["settings"]),
  },
  methods: {
    ...mapActions("Processes", ["getProcesses"]),
    async setup() {
      await this.getProcesses();
      if (!has(this.settings, "activities.enabled")) {
        this.model.enabledActivities.value = this.allProcesses.map(
          (i) => i.model
        );
      } else {
        this.model.enabledActivities = cloneDeep(
          this.settings.activities.enabled
        );
      }
      this.loading = false;
      this.ready = true;
    },
    async saveChanges() {
      this.$emit("save", this.model);
    },
  },
};
</script>
