<template lang="html">
  <div class="row">

    <div
      v-if="subsector"
      class="col-md-12 text-left">
      <b>{{ sector.name }}</b>
    </div>

    <div class="col-md-6">
      <label class="col-md-8 text-left">Especie:</label>
      <label class="col-md-4 text-left"><b>{{ computedSpecie }}</b></label>
    </div>
    <div class="col-md-6">
      <label class="col-md-8 text-left">Variedad:</label>
      <label class="col-md-4 text-left">
        <b>{{ computedVariety }}</b>
      </label>
    </div>
    <div class="col-md-6">
      <label class="col-md-8 text-left">Año de plantación:</label>
      <label class="col-md-4 text-left"><b>{{ sector.establishment_year }}</b></label>
    </div>
    <div class="col-md-6">
      <label class="col-md-8 text-left">Superficie:</label>
      <label class="col-md-4 text-left"><b>{{ sector.area }} ha</b></label>
    </div>
    <div class="col-md-6">
      <label class="col-md-8 text-left">Nº de plantas:</label>
      <label class="col-md-4 text-left"><b>{{ sector.num_of_plants }}</b></label>
    </div>
    <div class="col-md-6">
      <label class="col-md-8 text-left">Densidad de Plantación (Plantas/Ha):</label>
      <label class="col-md-4 text-left"><b>{{ sector.num_of_plants / sector.area | convertToFloat | sanitizeNumber }}</b></label>
    </div>
    <div class="col-md-6">
      <label class="col-md-8 text-left">Distancia entre hileras:</label>
      <label class="col-md-4 text-left"><b>{{ sector.distance_between_lines.toFixed(2) }} m</b></label>
    </div>
    <div class="col-md-6">
      <label class="col-md-8 text-left">Distancia entre plantas:</label>
      <label class="col-md-4 text-left"><b>{{ sector.distance_between_plants.toFixed(2) }} m</b></label>
    </div>
    <div class="col-md-6">
      <label class="col-md-8 text-left">Área Aparente/Planta:</label>
      <label class="col-md-4 text-left"><b>{{ seemingArea.toFixed(2) | sanitizeNumber }} m<sup>2</sup> </b></label>
    </div>
    <div class="col-md-6">
      <label class="col-md-8 text-left">Elipse Aparente/Planta:</label>
      <label class="col-md-4 text-left"><b>{{ seemingAreaElipse.toFixed(2) }} m<sup>2</sup> </b></label>
    </div>
    <div class="col-md-6">
      <label class="col-md-8 text-left">Goteros por planta:</label>
      <label class="col-md-4 text-left"><b>{{ sector.droppers_per_plant }}</b></label>
    </div>
    <div class="col-md-6">
      <label class="col-md-8 text-left">Caudal por gotero:</label>
      <label class="col-md-4 text-left"><b>{{ sector.caudal_per_dropper.toFixed(2) }} L/h</b></label>
    </div>
    <div class="col-md-6">
      <label class="col-md-8 text-left">Precipitación del cuartel:</label>
      <label class="col-md-4 text-left"><b>{{ sector.num_of_plants*sector.droppers_per_plant*sector.caudal_per_dropper/1000 | convertToFloat }} m<sup>3</sup>/h</b></label>
    </div>
    <div class="col-md-6">
      <label class="col-md-8 text-left">Precipitación por planta:</label>
      <label class="col-md-4 text-left"><b>{{ sector.droppers_per_plant*sector.caudal_per_dropper | sanitizeNumber | convertToFloat }} L/h</b></label>
    </div>
    <div class="col-md-12 text-right">
      <n-button
        v-if="$store.getters['Auth/IS_ADMIN']"
        type="primary"
        size="sm"
        class="sm-button"
        @click.native="components.editSector = true">
        <i class="fas fa-edit"/>
        Editar
      </n-button>
    </div>

    <edit-sector
      v-if="components.editSector"
      :sector="sector"
      :farm="farm"
      @close="components.editSector = false"
      @sectorUpdated="$emit('sectorUpdated')"/>

  </div>
</template>

<script>
import EditSector from "./EditSector"

export default {
    components: {
        EditSector
    },
    props: {
        sector: {
            type: Object,
            default: () => {
                return {}
            }
        },
        subsector: {
            type: Boolean,
            default: false
        },
        farm: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            settings: {},
            components: {
                editSector: false
            }
        }
    },
    computed: {
        computedSpecie() {
            let specie = this.settings.general.species.find(
                i => i._id.$oid.toString() == this.sector.specie
            )
            return specie != undefined ? specie.value : ""
        },
        computedVariety() {
            let varieties = this.settings.varieties[this.sector.specie]
            let variety = undefined
            if (varieties != undefined) {
                variety = this.settings.varieties[this.sector.specie].find(
                    i => i._id.$oid.toString() == this.sector.variety
                )
            }
            return variety != undefined ? variety.value : ""
        },
        seemingArea() {
            return (this.sector.area / this.sector.num_of_plants) * 10000
        },
        seemingAreaElipse() {
            return (
                this.sector.distance_between_lines *
                this.sector.distance_between_plants *
                Math.PI
            )
        }
    },
    created() {
        this.settings = this.$store.getters["Settings/GET_SETTINGS"]
    }
}
</script>

<style lang="css">
</style>
