import Swal from "sweetalert2";
import pluralize from "pluralize";
import { mapGetters } from "vuex";

const workflowHandler = {
  props: {
    variables: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      title: "Iniciar proceso",
      backText: "Volver",
      viewMode: true,
      runValidations: false,
      messages: {},
      model: {
        workflow: {
          state: "new",
        },
      },
      decision: "",
      transitionsWithUpdate: ["update_task"],
      stateWhichStartWithForm: ["reporting", "new"],
      startMethod() {},
    };
  },
  computed: {
    ...mapGetters({ farm_id: "Farms/GET_FARM_ID" }),
    showSaveButtons() {
      const test =
        this.decision == "update_task" || this.model.workflow.state == "new";
      return test ? true : false;
    },
    saveText() {
      if (this.model.workflow.hasOwnProperty("state")) {
        if (this.model.workflow.state != "new") {
          return "Guardar cambios";
        } else {
          return "Registrar";
        }
      } else {
        if (this.$HELPERS.isFuture(this.model.date)) {
          return "Programar";
        } else if (this.$HELPERS.isPast(this.model.date)) {
          return "Registrar";
        } else {
          return "Iniciar";
        }
      }
    },
    backRoute() {
      let processKey;
      if (this.$route.params.processKey) {
        processKey = pluralize(this.$route.params.processKey);
      } else {
        processKey = pluralize(this.$route.params.id);
      }
      return {
        name: "records-view",
        params: {
          processKey: processKey,
        },
      };
    },
  },
  created() {
    if (Object.keys(this.variables).length > 0) {
      this.model = this.standardModelPreProcess();
    }
    if (this.stateWhichStartWithForm.includes(this.model.workflow.state)) {
      this.viewMode = false;
    }
    this.$emit("setBackText", this.backText);
    this.$emit("setBackCustomTarget", this.backRoute);
  },
  methods: {
    setTitle(value) {
      this.$emit("setTitle", value);
    },
    standardModelPreProcess() {
      let modelProcessed = this.$clone(this.variables);
      if (modelProcessed.sector_ids) {
        modelProcessed.sector_ids = modelProcessed.sector_ids.map(
          (i) => i.$oid
        );
      }
      return modelProcessed;
    },
    processAction(action) {
      this.decision = action;
      if (this.transitionsWithUpdate.includes(action) && this.viewMode) {
        this.viewMode = false;
      } else {
        this.startOrCompleteTask();
      }
    },
    validate() {
      this.$emit("setLoader", true);
      this.messages = {};
      this.runValidations = true;
    },
    validForm(isValid, modifiedModel) {
      this.model = Object.assign({}, modifiedModel);
      this.runValidations = false;
      isValid ? this.startOrCompleteTask() : this.$emit("setLoader", false);
    },
    startOrCompleteTask() {
      if (this.model.workflow.state) {
        if (this.model.workflow.state != "new") {
          this.completeTask();
        } else {
          this.start();
        }
      } else {
        this.start();
      }
    },
    async start() {
      let vm = this;
      try {
        const response = await this.startMethod({
          farm_id: this.farm_id,
          payload: this.model,
        });
        Swal.fire({
          title: response.data.messages,
          icon: "success",
        }).then(() => {
          vm.$router.push(vm.backRoute);
        });
      } catch (error) {
        console.log(error);
        if (typeof error.response.data != "string") {
          vm.messages = error.response.data;
        }
      } finally {
        vm.$emit("setLoader", false);
      }
    },
    completeTask() {
      this.$emit(
        "completeTask",
        this.$route.params.processKey,
        this.$route.params.id,
        this.model,
        this.decision
      );
    },
  },
};

export default workflowHandler;
