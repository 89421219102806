<template lang="html">
  <div
    v-loading="loader"
    class="row report-body">
    <div class="col-md-12">

      <div class="row">
        <div class="col-md-12">
          <usda-form
            v-if="config"
            :config="config"
            @loader="setLoader"
            @data="setData"
            @cleanData="cleanData"
            @setOptions="setOptions"/>
        </div>
      </div>

      <div
        class="row">
        <div class="col-md-12">
          <usda-report
            v-if="data.length > 0"
            :data="data"
            :options="options"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import UsdaForm from "./UsdaForm.vue"
import UsdaReport from "./UsdaReport.vue"

export default {
    components: {
        UsdaForm,
        UsdaReport
    },
    data() {
        return {
            loader: false,
            config: null,
            data: [],
            options: []
        }
    },
    created() {
        this.$emit("setTitle", "USDA")
        this.getConfig()
    },
    methods: {
        getConfig() {
            this.loader = true
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Reports.getConfig(farm_id, "usda")
                .then(response => {
                    vm.config = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        setLoader(value) {
            this.loader = value
        },
        setData(value) {
            this.data = [...value]
        },
        cleanData() {
            this.data = []
        },
        setOptions(value) {
            this.options = { ...value }
        }
    }
}
</script>

<style lang="sass" scoped>
    .report-body
        min-height: 200px
</style>
