<template lang="html">
  <div class="">
    <weeding-details v-if="view_mode" :model="variables">
      <template slot="actions">
        <process-controls
          v-if="Object.keys(variables).length > 0"
          :record="variables"
          @action="processAction"
        />
      </template>
    </weeding-details>

    <weeding-form
      v-if="!view_mode"
      ref="weedingForm"
      :messages="messages"
      :init-model="init_model"
      :run-validation="runValidation"
      class="col-md-10 offset-md-1"
      @validForm="validForm"
      @modelUpdated="updateModel"
    >
      <template slot="actions">
  <process-controls
    v-if="Object.keys(variables).length > 0"
    :record="variables"
    @action="processAction"
  />
  <n-button
    v-if="Object.keys(variables).length == 0"
    simple=""
    @click.native="view_mode = true"
  >
    Cerrar
  </n-button>
  <n-button
    v-if="Object.keys(variables).length == 0"
    type="primary"
    @click.native="validate()"
  >
    {{ saveText }}
  </n-button>
</template>
    </weeding-form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import WeedingDetails from "@/views/Labors/Weedings/WeedingDetails";
import WeedingForm from "@/views/Labors/Weedings/WeedingForm";
import ProcessControls from "@/views/TaskList/Components/ProcessControls";

export default {
  components: {
    WeedingDetails,
    WeedingForm,
    ProcessControls,
  },
  props: {
    variables: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      title: "Detalles del control de malezas",
      back_text: "Control de malezas",
      back_custom_target: {
        name: "records-view",
        params: {
          processKey: "weedings",
        },
      },
      view_mode: true,
      runValidation: false,
      messages: {},
      init_model: {},
      model: {
        workflow: {
          state: "new",
        },
      },
      decision: "",
      transitions_with_update: ["update_task"],
      states_which_start_with_form: ["reporting", "new"],
    };
  },
  computed: {
    saveText() {
      if (this.model.workflow.hasOwnProperty("state")) {
        if (this.model.workflow.state != "new") {
          return "Guardar cambios";
        } else {
          return "Registrar";
        }
      } else {
        if (this.$HELPERS.isFuture(this.model.date)) {
          return "Programar";
        } else if (this.$HELPERS.isPast(this.model.date)) {
          return "Registrar";
        } else {
          return "Iniciar";
        }
      }
    },
  },
  created() {
    if (Object.keys(this.variables).length > 0) {
      this.model = this.preProcessVariables();
      this.init_model = this.$COPY.copy(this.model);
    }
    if (this.states_which_start_with_form.includes(this.model.workflow.state)) {
      this.view_mode = false;
    }
    this.$emit("setBackText", this.back_text);
    this.$emit("setBackCustomTarget", this.back_custom_target);
    this.$emit("setTitle", this.title);
  },
  methods: {
    preProcessVariables() {
      let init_model = this.$COPY.copy(this.variables);
      init_model.sector_ids = init_model.sector_ids.map((i) => i.$oid);
      init_model.worker_ids = init_model.worker_ids.map((i) => i.$oid);

      return init_model;
    },
    processAction(action) {
      this.decision = action;
      if (this.transitions_with_update.includes(action) && this.view_mode) {
        this.view_mode = false;
      } else {
        this.startOrCompleteTask();
      }
    },
    updateModel(model) {
      this.model = this.$COPY.copy(model);
      if (!this.model.hasOwnProperty("farm_id")) {
        this.model.farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      }
    },
    validate() {
      this.loader = true;
      this.messages = {};
      this.runValidation = true;
    },
    validForm(isValid) {
      this.runValidation = false;
      isValid ? this.startOrCompleteTask() : (this.loader = false);
    },
    startOrCompleteTask() {
      if (this.model.workflow.hasOwnProperty("state")) {
        if (this.model.workflow.state != "new") {
          this.completeTask();
        } else {
          this.start();
        }
      } else {
        this.start();
      }
    },
    start() {
      let vm = this;
      this.Services.Weedings.createWeeding(this.model.farm_id, this.model)
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          }).then(() => {
            vm.$router.push({
              name: "records-view",
              params: {
                processKey: "weedings",
              },
            });
          });
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response.data != "string") {
            vm.messages = error.response.data;
          }
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    completeTask() {
      this.$emit(
        "completeTask",
        this.$route.params.processKey,
        this.$route.params.id,
        this.model,
        this.decision
      );
    },
  },
};
</script>

<style lang="sass" scoped></style>
