<template lang="html">
  <div class="map-container">
    <GmapMap
      ref="gmap"
      :center="center"
      :zoom="12"
      map-type-id="roadmap"
      style="height: 100%"
      @click="addPointToPolygon"
    >
      <gmap-polygon
        :paths="paths"
        :editable="editable"
        :options="options"
        :deep-watch="deepWatch"
        @paths_changed="pathChanged"
        @dblclick="deletePoint"
      />
    </GmapMap>
  </div>
</template>

<script>
let google;

export default {
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    polygon: {
      type: Array,
      default: () => {
        return undefined;
      },
    },
    deepWatch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      paths: [],
      center: { lat: 0, lng: 0 },
      options: {
        strokeColor: "#E90707",
        fillColor: "#E90707",
      },
    };
  },
  created() {
    this.$emit("create");
  },
  mounted() {
    let vm = this;
    if (this.polygon != undefined) {
      this.$refs.gmap.$mapPromise.then((map) => {
        let bounds = new google.maps.LatLngBounds();
        for (let index in vm.polygon) {
          vm.paths.push(vm.polygon[index]);
          bounds.extend(vm.polygon[index]);
        }
        map.fitBounds(bounds);
      });
    } else {
      navigator.geolocation.getCurrentPosition(this.setCenter);
    }
  },
  methods: {
    addPointToPolygon(event) {
      this.paths.push(event.latLng);
      console.log(this.paths);
      this.$emit("areaUpdated", this.paths);
    },
    deletePoint(event) {
      if (event.vertex != undefined) {
        this.paths.splice(event.vertex, 1);
        this.$emit("areaUpdated", this.paths);
      }
    },
    setCenter(position) {
      this.center.lat = position.coords.latitude;
      this.center.lng = position.coords.longitude;
    },
    pathChanged(mvcArray) {
      let paths = [];
      for (let i = 0; i < mvcArray.getLength(); i++) {
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          let point = mvcArray.getAt(i).getAt(j);
          paths.push({ lat: point.lat(), lng: point.lng() });
        }
      }

      this.$emit("areaUpdated", paths);
    },
  },
};
</script>

<style lang="sass">
.map-container
    width: 100%
    height: 300px
</style>
