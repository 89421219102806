<template>
  <div>
    <div class="row">
      <div class="col-md-3 col-sm-12">
        <div class="statistics">
          <div class="info">
            <div class="icon icon-info"><i class="fal fa-info-circle"/></div>
            <h5 class="info-title"><span>{{ $t(model.workflow.state) }}</span></h5>
            <h6 class="stats-title">Estado</h6>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12">
        <div class="statistics">
          <div class="info">
            <div class="icon icon-info"><i class="fal fa-calendar"/></div>
            <h5
              v-if="model.date"
              class="info-title">
              <span>{{ model.date | moment("DD-MM-YYYY") }}</span>
            </h5>
            <h6 class="stats-title">Fecha de solicitud</h6>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12">
        <div class="statistics">
          <div class="info">
            <div class="icon icon-info"><i class="fal fa-calendar"/></div>
            <h5
              v-if="model.deliver_date"
              class="info-title">
              <span>{{ model.deliver_date | moment("DD-MM-YYYY") }}</span>
            </h5>
            <h6 class="stats-title">Fecha de entrega</h6>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12">
        <div class="statistics">
          <div class="info">
            <div class="icon icon-info"><i class="fal fa-coins"/></div>
            <h5 class="info-title"><span>{{ model.total | toLocaleStringNumber("currency") }}</span></h5>
            <h6 class="stats-title">Costo CLP (IVA incl.)</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6 col-sm-12">
        <h6 class="text-left mb-3">Laboratorio</h6>
        <contact-card :contact="currentLab" />
      </div>
      <div class="col-md-6 col-sm-12">
        <h6 class="mb-3">Archivos entregados</h6>
        <files :files="model.lab_report_files" />
      </div>
    </div>
    <cost-summary :record="model" />
    <slot name="actions"/>
  </div>
</template>

<script>
import CostSummary from "@/views/Labors/Components/CostSummary"
import { ContactCard, Files } from "@/Components/"

export default {
    components: {
        CostSummary,
        ContactCard,
        Files
    },
    props: {
        model: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        currentLab() {
            return this.$store.getters["Settings/GET_CONTACT"](
                "suppliers",
                this.model.lab_id.$oid
            )
        }
    },
    created() {
        this.$emit("setTitle", "Informe de Laboratorio")
    }
}
</script>

<style>
</style>
