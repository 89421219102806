<template lang="html">
  <div
    v-loading="loader"
    class="col-md-12">

    <template
      v-if="!loader">
      <div class="row">
        <div class="col-md-12 text-left">
          <h5>Datos generales</h5>
        </div>
        <div class="col-md-6 text-left">
          <fg-input
            :error="errors.first('date')"
            required
            label="Fecha del empaquetamiento">
            <el-date-picker
              v-validate="{required: true}"
              v-model="model.date"
              :disabled="model.workflow.state == 'reporting'"
              data-vv-as="Fecha del empaquetamiento"
              name="date"
              format="dd-MM-yyyy"
              type="date"
              placeholder="Fecha del empaquetamiento"/>
          </fg-input>
        </div>
        <div class="col-md-6 text-left">
          <fg-input
            :error="errors.first('assignee')"
            class="has-label">
            <label>Responsable <span>*</span></label>
            <el-select
              v-validate="{required: true}"
              v-model="model.workflow.assignee.$oid"
              :disabled="model.workflow.state == 'reporting'"
              name="assignee"
              placeholder="Responsable"
              data-vv-as="Responsable"
              class="select-primary"
              filterable>
              <el-option
                v-for="(user,key) in users"
                :value="user._id.$oid"
                :label="user.full_name"
                :key="key"
                class="select-danger"/>
            </el-select>
          </fg-input>
        </div>
        <div class="col-md-6 text-left">
          <fg-input
            :error="errors.first('package_format_id')"
            class="has-label">
            <label>Formato de venta <span>*</span></label>
            <el-select
              v-validate="{required: true}"
              v-model="model.package_format_id.$oid"
              :disabled="model.workflow.state == 'reporting'"
              name="package_format_id"
              placeholder="Formato de venta"
              data-vv-as="Formato de venta"
              class="select-primary"
              filterable
              @change="model.allowed_raw_material_id.$oid = ''">
              <el-option
                v-for="(package_format,index) in package_formats"
                :key="index"
                :value="package_format._id.$oid"
                :label="package_format.name"
                class="select-danger"/>
            </el-select>
          </fg-input>
        </div>
        <div class="col-md-6 text-left">
          <fg-input
            :error="errors.first('allowed_raw_material_id')"
            class="has-label">
            <label>Variedad <span>*</span></label>
            <el-select
              v-validate="{required: true}"
              v-model="model.allowed_raw_material_id.$oid"
              :disabled="model.workflow.state == 'reporting'"
              name="allowed_raw_material_id"
              placeholder="Variedad"
              data-vv-as="Variedad"
              class="select-primary"
              no-data-text="Debe seleccionar un formato de ventas primero"
              filterable>
              <template
                v-if="model.package_format_id.$oid != ''">
                <el-option
                  v-for="(raw_material,index) in package_formats.find(i => i._id.$oid == model.package_format_id.$oid).allowed_raw_materials"
                  :key="index"
                  :value="raw_material._id.$oid"
                  :label="raw_material.specie_name+' '+raw_material.variety_name"
                  class="select-danger"/>
              </template>
            </el-select>
          </fg-input>
        </div>

        <div class="col-md-6 text-left">
          <fg-input
            v-validate="{ required: true, min_value: 1, decimal: true }"
            v-model.number="model.raw_material_qty"
            :error="errors.first('raw_material_qty')"
            :label="'Cantidad de materia prima a embalar ('+yield_type+')'"
            :placeholder="'Cantidad de materia prima a embalar ('+yield_type+')'"
            :data-vv-as="'Cantidad de materia prima a embalar ('+yield_type+')'"
            name="raw_material_qty"
            type="number"/>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-left">
          <h5>Resultado</h5>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-left">
          <p>Total de paquetes finales: {{ computeTotalPackages }}</p>
          <p>Vida últil del formato de venta: {{ showLifetime }} días</p>
          <p>Fecha de vencimiento: {{ computeDueDate }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <slot name="actions"/>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <notification
            :messages="messages"
            :close="true"
            type="error"/>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
export default {
    props: {
        runValidations: {
            type: Number,
            default: 0
        },
        messages: {
            type: Object,
            default: () => {
                return {}
            }
        },
        initModel: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            loader: true,
            users: [],
            package_formats: [],
            yield_type: "",
            model: {
                date: "",
                farm_id: { $oid: "" },
                package_format_id: { $oid: "" },
                allowed_raw_material_id: { $oid: "" },
                raw_material_qty: "",
                packages: undefined,
                due_date: "",
                workflow: {
                    state: "new",
                    assignee: {
                        $oid: ""
                    }
                }
            }
        }
    },
    computed: {
        computeTotalPackages() {
            let packages = this.getTotalPackages()
            return packages == undefined ? "?" : packages
        },
        computeDueDate() {
            let due_date = this.getDueDate()
            return due_date == undefined ? "?" : due_date.format("DD-MM-YYYY")
        },
        currentPackageFormat() {
            return this.package_formats.find(
                i => i._id.$oid == this.model.package_format_id.$oid
            )
        },
        showLifetime() {
            let package_format = this.currentPackageFormat
            if (package_format == undefined) {
                return "?"
            } else {
                return package_format.lifetime
            }
        }
    },
    watch: {
        "model.date": function(newValue, oldValue) {
            this.$emit("dateUpdated", newValue)
        },
        runValidations: function(newValue, oldValue) {
            let vm = this
            this.$validator.validateAll().then(isValid => {
                console.log(vm.model)
                vm.$emit("isValid", isValid, vm.model)
            })
        }
    },
    created() {
        this.yield_type = this.$store.getters["Farms/GET_YIELD_TYPE"]

        if (Object.keys(this.initModel).length > 0) {
            this.model = this.$COPY.copy(this.initModel)
        }

        if (
            this.model.farm_id.$oid == undefined ||
            this.model.farm_id.$oid == ""
        ) {
            this.model.farm_id.$oid = this.$store.getters[
                "Farms/GET_FARM"
            ]._id.$oid
        }
        this.getUsers()
    },
    methods: {
        getUsers() {
            let vm = this
            this.Services.Users.getUsers()
                .then(response => {
                    vm.users = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.getPackageFormats()
                })
        },
        getPackageFormats() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.PackageFormats.getPackageFormats(farm_id)
                .then(response => {
                    vm.package_formats = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        getTotalPackages() {
            let package_format = this.package_formats.find(
                i => i._id.$oid == this.model.package_format_id.$oid
            )
            if (package_format == undefined) {
                return undefined
            } else {
                this.model.packages =
                    this.model.raw_material_qty / package_format.qty_per_format
                return this.model.packages
            }
        },
        getDueDate() {
            let package_format = this.currentPackageFormat
            if (package_format == undefined || this.model.date == "") {
                return undefined
            } else {
                let date = this.$moment(this.model.date)
                this.model.due_date = date.add(package_format.lifetime, "days")
                return this.model.due_date
            }
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
