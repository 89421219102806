<template>
  <div class="text-left">
    <base-select
      v-if="sectors"
      :value="value"
      :label="smartLabel"
      :name="name"
      :required="true"
      :placeholder="placeholder"
      :options="options"
      item-value="_id.$oid"
      item-text="name"
      :multiple="multiple"
      @input="$emit('input', $event)"
    ></base-select>
  </div>
</template>

<script>
import { mapState } from "vuex";
import formControls from "../mixins/formControl";

export default {
  mixins: [formControls],
  props: {
    value: { type: [String, Array] },
    label: {
      type: String,
      default: "Cuartel",
    },
    name: {
      type: String,
      default: "sectors",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    noParents: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("Farms", ["sectors", "sector_options"]),
    options() {
      return this.noParents ? this.sector_options : this.sectors;
    },
    smartLabel() {
      if (this.label) return this.label;
      return this.multiple ? "Cuarteles" : "Cuartel";
    },
  },
};
</script>
