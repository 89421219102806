<template>
  <fg-input :error="errors.first('date')" class="has-label">
    <label>{{ label }} <span v-if="isRequired">*</span></label>
    <el-date-picker
      v-validate="rules"
      type="date"
      :value="value"
      :placeholder="placeholder"
      :name="name"
      format="dd-MM-yyyy"
      :data-vv-as="label"
      disabled-date="disabledDate"
      :picker-options="{
        disabledDate,
      }"
      @input="$emit('input', $event)"
    />
  </fg-input>
</template>

<script>
import formControls from "../mixins/formControl";

export default {
  mixins: [formControls],
  props: {
    value: { type: [Date, String] },
    placeholder: { type: String, default: "Seleccionar fecha" },
    notFuture: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return this.disabledDate();
        },
      },
    };
  },
  methods: {
    disabledDate(time) {
      if (this.notFuture) {
        return time.getTime() > Date.now();
      } else {
        return false;
      }
    },
  },
};
</script>
