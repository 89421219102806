<template lang="html">
  <modal
    :show.sync="modals.editUser"
    header-classes="justify-content-center"
    @close="$emit('close')"
  >
    <h4 slot="header" class="title title-up">Editar usuario</h4>
    <div v-loading="loader">
      <user-form
        ref="userForm"
        :user-profile="model.profile"
        :edit-mode="true"
        @userDataChanged="updateUserModel"
      />

      <div class="row">
        <div class="col-md-12">
          <div v-if="messages.length > 0" class="text-left">
            <notification
              v-for="(message, key) in messages"
              :key="key"
              :message="message.message"
              :type="message.type"
              :close="true"
            />
          </div>
          <n-button type="primary" @click.native="validate()">
            Modificar usuario
          </n-button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "nowui/components/Modal";
import UserForm from "./UserForm";
import Swal from "sweetalert2";
export default {
  components: {
    Modal,
    UserForm,
  },
  props: {
    profile: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      modals: {
        editUser: true,
      },
      loader: false,
      model: {
        profile: {
          name: "",
          lastname: "",
          email: "",
        },
      },
      messages: [],
    };
  },
  created() {
    if (Object.keys(this.profile).length > 0) {
      this.model.profile = this.$COPY.copy(this.profile);
    }
  },
  methods: {
    updateUserModel(newUserModel) {
      this.model = newUserModel;
    },
    validate() {
      let vm = this;
      this.$refs.userForm.$refs.userProfileForm.$validator
        .validateAll()
        .then((isValid) => {
          if (isValid) vm.editUser();
        });
    },
    editUser() {
      let vm = this;
      let payload = this.model.profile;
      let id = this.profile._id.$oid.toString();

      this.loader = true;
      this.messages = [];
      this.Services.Users.updateUser({ id, payload })
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          });
          vm.$emit("updateUserList");
          vm.$emit("close");
        })
        .catch((error) => {
          vm.processMessages(error.response.data.errors, "danger");
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
};
</script>

<style lang="css" scoped></style>
