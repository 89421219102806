<template>
  <div class="auth-layout">
    <div class="wrapper wrapper-full-page">
      <div
        :class="pageClass"
        class="full-page section-image"
        filter-color="black">
        <div class="content">
          <div class="container">
            <zoom-center-transition
              :duration="pageTransitionDuration"
              mode="out-in">
              <router-view/>
            </zoom-center-transition>
          </div>
        </div>
        <footer class="footer">
          <div class="container-fluid">
            <div class="copyright">
              &copy; {{ year }} Desarrollado por
              <a
                href="http://www.dynamiclab.cl"
                target="_blank">DynamicLab</a>
            </div>
          </div>
        </footer>

        <div
          :style="imageStyles"
          class="full-page-background"/>
      </div>
    </div>
  </div>
</template>
<script>
import { ZoomCenterTransition } from "vue2-transitions"

export default {
    components: {
        // Navbar,
        // NavbarToggleButton,
        ZoomCenterTransition
    },
    props: {
        backgroundColor: {
            type: String,
            default: "black"
        }
    },
    data() {
        return {
            showMenu: false,
            menuTransitionDuration: 250,
            pageTransitionDuration: 200,
            year: new Date().getFullYear(),
            pageClass: "login-page"
        }
    },
    computed: {
        imageStyles() {
            let img = require("@/assets/img/login_background.jpg")
            return {
                backgroundImage: `url(${img})`
            }
        }
    },
    watch: {
        $route() {
            this.setPageClass()
        }
    },
    beforeDestroy() {
        this.closeMenu()
    },
    methods: {
        toggleNavbar() {
            document.body.classList.toggle("nav-open")
            this.showMenu = !this.showMenu
        },
        closeMenu() {
            document.body.classList.remove("nav-open")
            this.showMenu = false
        },
        setPageClass() {
            this.pageClass = `${this.$route.name}-page`.toLowerCase()
        }
    },
    beforeRouteUpdate(to, from, next) {
        // Close the mobile menu first then transition to next page
        if (this.showMenu) {
            this.closeMenu()
            setTimeout(() => {
                next()
            }, this.menuTransitionDuration)
        } else {
            next()
        }
    }
}
</script>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
    from {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    100% {
        opacity: 1;
    }
}
.wrapper-full-page .zoomIn {
    animation-name: zoomIn8;
}
@keyframes zoomOut8 {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
}
.wrapper-full-page .zoomOut {
    animation-name: zoomOut8;
}
</style>
