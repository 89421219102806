<template lang="html">
  <div v-loading="loader">
    <div class="col-md-10 offset-md-1">
      <pruning-form
        ref="pruningForm"
        :messages="messages"
        :run-validation="runValidation"
        @validForm="validForm"
        @modelUpdated="updateModel"
      >
        <template slot="actions">
          <n-button simple="" @click.native="$router.go(-1)"> Cerrar </n-button>
          <n-button type="primary" @click.native="validate()">
            {{ saveText }}
          </n-button>
        </template>
      </pruning-form>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import PruningForm from "@/views/Labors/Prunings/Components/PruningForm";
export default {
  components: {
    PruningForm,
  },
  props: {
    processKey: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      loader: false,
      saveText: "Registrar",
      runValidation: false,
      sectorList: [],
      model: {},
      messages: {},
    };
  },
  watch: {
    model: {
      handler: function () {
        if (this.$HELPERS.isFuture(this.model.date)) {
          this.saveText = "Programar";
          this.$emit("setTitle", "Programar Poda");
        } else if (this.$HELPERS.isPast(this.model.date)) {
          this.saveText = "Registrar";
          this.$emit("setTitle", "Registrar Poda");
        } else {
          this.saveText = "Iniciar";
          this.$emit("setTitle", "Iniciar Poda");
        }
      },
      deep: true,
    },
  },
  created() {
    this.$emit("setTitle", "Registrar Poda");
    this.getSectors();
  },
  methods: {
    updateModel(model) {
      this.model = this.$COPY.copy(model);
    },
    validate() {
      this.loader = true;
      this.messages = {};
      this.runValidation = true;
    },
    validForm(isValid) {
      this.runValidation = false;
      isValid ? this.start() : (this.loader = false);
    },
    start() {
      let vm = this;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Prunings.createPruning(farm_id, this.model)
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          }).then(() => {
            vm.$router.push({
              name: "prunings",
            });
          });
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response.data != "string") {
            vm.messages = error.response.data;
          }
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    getSectors() {
      let vm = this;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Sectors.getSectors(farm_id)
        .then((response) => {
          vm.sectorList = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped></style>
