const getUsers = {
    data() {
        return {
            users: []
        }
    },
    created() {
        this.getUsers()
    },
    methods: {
        async getUsers() {
            try {
                const response = await this.Services.Users.getUsers()
                this.users = response.data
            } catch (error) {
                console.log(error)
            }
        }
    }
}

export default getUsers
