<template lang="html">
  <div v-loading="loaders.form" class="col-md-12">
    <template v-if="!loaders.form">
      <div class="row">
        <div class="col-md-12 text-left">
          <h5>Configuración del inventario</h5>
        </div>
        <div class="col-md-6 text-left">
          <fg-input
            :error="errors.first('date')"
            required
            label="Fecha del inventario"
          >
            <el-date-picker
              v-validate="{ required: true }"
              v-model="model.date"
              data-vv-as="Fecha del inventario"
              name="date"
              format="dd-MM-yyyy"
              type="date"
              placeholder="Fecha del inventario"
            />
          </fg-input>
        </div>
        <div class="col-md-6 text-left">
          <fg-input :error="errors.first('assignee')" class="has-label">
            <label>Responsable <span>*</span></label>
            <el-select
              v-validate="{ required: true }"
              v-model="model.workflow.assignee.$oid"
              name="assignee"
              placeholder="Responsable"
              vv-data-as="Responsable"
              class="select-primary"
              filterable
            >
              <el-option
                v-for="(user, key) in users"
                :value="user._id.$oid"
                :label="user.full_name"
                :key="key"
                class="select-danger"
              />
            </el-select>
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8 text-left">
          <fg-input :error="errors.first('warehouse_ids')" class="has-label">
            <label>Bodegas a inventariar <span>*</span></label>
            <el-select
              v-validate="{ required: true }"
              v-model="model.warehouse_ids"
              filterable
              class="select-primary"
              placeholder="Bodegas a inventariar"
              name="warehouse_ids"
              vv-data-as="Bodegas a inventariar"
              multiple
            >
              <el-option
                v-for="warehouse in warehouses"
                :key="warehouse._id.$oid"
                :value="warehouse._id.$oid"
                :label="warehouse.name"
                class="select-danger"
              />
            </el-select>
          </fg-input>
        </div>
        <div class="col-md-4 mt-auto">
          <n-button simple="" @click.native="getWarehousesItems">
            Obtener items
          </n-button>
        </div>
      </div>

      <div v-loading="loaders.items" class="row text-left">
        <div class="col-md-12">
          <h5>Inventario por bodega</h5>
        </div>
        <div v-if="model.inventory_items.length > 0" class="col-md-12">
          <el-collapse accordion>
            <el-collapse-item
              v-for="(warehouse_id, index) in model.warehouse_ids"
              :key="index"
            >
              <template slot="title">
                <span
                  :class="validationIcon(warehouse_id).class + ' pe-1'"
                  v-html="validationIcon(warehouse_id).icon"
                />
                {{ getWarehouseTitle(warehouse_id) }}
              </template>
              <el-alert
                :closable="true"
                title="Por favor descarte los productos que no serán inventariados"
                type="info"
                center
                show-icon
              />
              <el-table
                :data="
                  model.inventory_items.filter(
                    (i) => i.warehouse_id.$oid == warehouse_id
                  )
                "
                empty-text="No hay productos o herramientas registradas en esta bodega"
              >
                <!-- <el-table-column
                  prop="brand"
                  label="Marca"/> -->
                <el-table-column label="Producto">
                  <div slot-scope="{ row }">
                    {{ getProductLabel(row) }}
                  </div>
                </el-table-column>
                <!-- <el-table-column
                  label="Formato">
                  <div slot-scope="{row}">
                    {{ row.format }} de {{ row.units_per_format }} {{ row.measure_unit }}
                  </div>
                </el-table-column> -->
                <el-table-column
                  prop="computed_stock"
                  label="Stock en sistema"
                  align="right"
                  header-align="center"
                  width="150"
                >
                  <div slot-scope="{ row }">
                    {{ row.computed_stock | toLocaleStringNumber("decimal") }}
                    {{ row.measure_unit }}
                  </div>
                </el-table-column>
                <el-table-column
                  v-if="
                    !$HELPERS.isFuture(model.date) ||
                    model.workflow.state == 'reporting'
                  "
                  label="Stock real"
                  header-align="center"
                  width="180"
                >
                  <div slot-scope="{ row }">
                    <el-input-number
                      v-validate="{ required: true, min_value: 0 }"
                      size="small"
                      :min="0"
                      v-model.number="
                        model.inventory_items.find(
                          (i) => i.product_id.$oid == row.product_id.$oid
                        ).actual_stock
                      "
                      :error="
                        errors.first(warehouse_id + '_' + row.product_id.$oid)
                      "
                      :name="warehouse_id + '_' + row.product_id.$oid"
                      :precision="2"
                      :step="0.1"
                      data-vv-as="Stock real"
                    />
                  </div>
                </el-table-column>
                <el-table-column
                  label="Diferencia"
                  header-align="center"
                  width="150"
                >
                  <div slot-scope="scope" class="text-center">
                    <div v-if="typeof scope.row.actual_stock == 'number'">
                      {{
                        getStockDiff(
                          scope.row.actual_stock,
                          scope.row.computed_stock
                        ) | toLocaleStringNumber("decimal")
                      }}
                      {{ scope.row.measure_unit }}
                    </div>
                    <div v-else>? {{ scope.row.measure_unit }}</div>
                    <div>
                      <n-button
                        type="danger"
                        size="sm"
                        simple
                        class="pull-right"
                        @click.native="discardItem(scope.$index)"
                      >
                        Descartar <i class="fal fa-trash" />
                      </n-button>
                    </div>
                  </div>
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div v-else class="col-md-12">
          <el-alert
            :closable="false"
            title="Selecione al menos una bodega a inventariar y presione el botón 'Obtener items'"
            type="warning"
            show-icon
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <slot name="actions" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <notification :messages="form_messages" :close="true" type="error" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Collapse, CollapseItem, Table, TableColumn, Alert } from "element-ui";
import Swal from "sweetalert2";

export default {
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Alert.name]: Alert,
  },
  props: {
    runValidations: {
      type: Number,
      default: 0,
    },
    messages: {
      type: Object,
      default: () => {
        return {};
      },
    },
    initModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loaders: {
        form: true,
        items: false,
      },
      users: [],
      form_messages: {},
      model: {
        date: "",
        farm_id: "",
        warehouse_ids: [],
        inventory_items: [],
        workflow: {
          state: "new",
          assignee: {
            $oid: "",
          },
        },
      },
      validation_perfomed: false,
      purchase_alert_showed: false,
    };
  },
  watch: {
    "model.date": function (newValue, oldValue) {
      this.$emit("dateUpdated", newValue);
    },
    messages: function (newValue, oldValue) {
      if (Object.keys(newValue).length > 0) {
        this.form_messages = this.$COPY.copy(newValue);
      }
    },
    runValidations: function (newValue, oldValue) {
      let vm = this;
      this.form_messages = {};
      this.$validator.validateAll().then((isValid) => {
        vm.setLosses();
        vm.$emit("isValid", isValid, vm.model);
        vm.validation_perfomed = true;
      });
    },
  },
  created() {
    if (Object.keys(this.initModel).length > 0) {
      this.model = this.$COPY.copy(this.initModel);
    }
    if (this.model.farm_id == undefined || this.model.farm_id == "") {
      this.model.farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
    }
    this.getUsers();
  },
  methods: {
    getUsers() {
      let vm = this;
      this.Services.Users.getUsers()
        .then((response) => {
          vm.users = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.getWarehouses();
        });
    },
    getWarehouses() {
      let vm = this;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Warehouses.getWarehouses(farm_id)
        .then((response) => {
          vm.warehouses = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.loaders.form = false;
        });
    },
    getWarehousesItems() {
      let vm = this;
      vm.loaders.items = true;
      vm.form_messages = {};
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      let warehouse_ids = this.model.warehouse_ids;
      this.Services.Warehouses.getWarehousesItems({
        farm_id,
        warehouse_ids,
      })
        .then((response) => {
          let inventory_items = response.data.reduce((acc, i) => {
            acc = acc.concat(i.products);
            return acc;
          }, []);

          vm.model.inventory_items = inventory_items.reduce((acc, i) => {
            i["product_id"] = i._id;
            delete i._id;
            acc.push(i);
            return acc;
          }, []);
        })
        .catch((error) => {
          console.log(error);
          vm.form_messages = error.response.data;
        })
        .finally(() => {
          vm.loaders.items = false;
        });
    },
    getWarehouseTitle(warehouse_id) {
      let name = this.warehouses.find((i) => i._id.$oid == warehouse_id).name;
      let count_of_products = this.model.inventory_items.filter(
        (i) => i.warehouse_id.$oid == warehouse_id
      ).length;
      let item_or_items = count_of_products > 1 ? "items" : "item";
      return name + " (" + count_of_products + " " + item_or_items + ")";
    },
    getProductLabel(product) {
      let format =
        product.format +
        " de " +
        product.units_per_format +
        " " +
        product.measure_unit;
      return [product.name, format, product.brand].join(" / ");
    },
    getStockDiff(actual_stock, computed_stock) {
      const vm = this;
      const diff = actual_stock - computed_stock;
      if ((diff > 0) & !this.purchase_alert_showed) {
        this.purchase_alert_showed = true;
        Swal.fire({
          title: "¿Está registrando un compra?",
          text: "Para hacer un incremento de stock se recomienda registrar una compra",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Continuar con el inventario",
          cancelButtonText: "Ir a Registrar compras",
        }).then((response) => {
          if (response.dismiss) {
            if (response.dismiss === "cancel") {
              vm.$router.replace({
                name: "tasklist-start-process",
                params: {
                  id: "purchase",
                },
              });
            }
          }
        });
      }
      return diff;
    },
    discardItem(index) {
      this.model.inventory_items.splice(index, 1);
    },
    validationIcon(warehouse_id) {
      let validation = {
        icon: "<i class='fal fa-check-circle success'></i>",
        class: "success",
      };

      if (this.validation_perfomed) {
        if (
          this.errors.items.some((i) => i.field.split("_")[0] == warehouse_id)
        ) {
          validation.icon = "<i class='fal fa-exclamation-circle danger'></i>";
          validation.class = "danger";
        }
      } else {
        validation.icon = "<i class='fal fa-pencil'></i>";
        validation.class = "";
      }

      return validation;
    },
    setLosses() {
      let vm = this;
      this.model.inventory_items.forEach((item, index) => {
        vm.model.inventory_items[index].system_stock = item.computed_stock;
        vm.model.inventory_items[index].loss =
          item.actual_stock - item.computed_stock;
      });
    },
  },
};
</script>

<style lang="sass" scoped></style>
