<template lang="html">
  <header id="home" :style="backgroundImage" class="header">
    <div class="container">
      <div class="row align-items-center h-100">
        <div class="col-lg-6">
          <h1 class="display-4">
            <strong v-html="data.main_title" />
            <br />
            <span v-html="data.secondary_title" />
          </h1>
          <span v-html="data.header_description" />

          <hr class="w-10 ms-0 my-7" />

          <p class="gap-xy">
            <a
              class="btn btn-lg btn-round btn-primary mw-200"
              href="#pricing"
              >{{ data.header_button_1_text }}</a
            >
            <a
              class="
                btn btn-lg btn-round btn-primary btn-outline-primary
                mw-200
              "
              href="#features"
              >{{ data.header_button_2_text }}</a
            >
          </p>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    backgroundImage() {
      return {
        "background-image":
          "url(" +
          this.data.header_image.main.url +
          "), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5))",
        "background-blend-mode": "overlay",
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  color: white
  h1
    color: white
</style>
