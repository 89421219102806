<template lang="html">
  <footer class="footer">
    <div class="container">
      <div class="row gap-y align-items-center">
        <div class="col-md-6 text-center text-md-left">
          <small v-html="data.footer_text"/>
        </div>

        <!-- <div class="col-md-6 text-center text-md-right">
              <div class="social">
                <a class="social-facebook" href="#"><i class="fa fa-facebook"></i></a>
                <a class="social-twitter" href="#"><i class="fa fa-twitter"></i></a>
                <a class="social-instagram" href="#"><i class="fa fa-instagram"></i></a>
                <a class="social-youtube" href="#"><i class="fa fa-youtube"></i></a>
              </div>
            </div> -->
      </div>
    </div>
  </footer>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        }
    }
}
</script>

<style lang="sass" scoped></style>
