<template lang="html">
  <div class="col-md-12">
    <plague-monitoring-form
      v-loading="loader"
      :messages="messages"
      :run-validations="runValidations"
      @isValid="isValid"
      @modelUpdated="updateModel"
    >
      <div slot="actions">
        <n-button simple="" @click.native="$router.go(-1)"> Cerrar </n-button>
        <n-button type="primary" @click.native="validate()">
          {{ saveText }}
        </n-button>
      </div>
    </plague-monitoring-form>
  </div>
</template>

<script>
import PlagueMonitoringForm from "@/views/Labors/PlagueMonitorings/Components/PlagueMonitoringForm";
import Swal from "sweetalert2";

export default {
  components: {
    PlagueMonitoringForm,
  },
  props: {
    processKey: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      loader: false,
      messages: {},
      runValidations: 0,
      model: {
        date: "",
      },
      saveText: "Iniciar",
    };
  },
  watch: {
    "model.date": function (newValue, oldValue) {
      if (newValue != "") {
        if (this.$HELPERS.isPast(newValue)) {
          this.$emit(
            "setTitle",
            "Registrar Monitoreo de Plagas y Enfermedades"
          );
          this.saveText = "Registrar";
        } else if (this.$HELPERS.isFuture(newValue)) {
          this.$emit(
            "setTitle",
            "Programar Monitoreo de Plagas y Enfermedades"
          );
          this.saveText = "Programar";
        } else {
          this.$emit("setTitle", "Iniciar Monitoreo de Plagas y Enfermedades");
          this.saveText = "Iniciar";
        }
      }
    },
  },
  created() {
    this.$emit("setTitle", "Configurar Monitoreo de Plagas y Enfermedades");
  },
  methods: {
    updateModel(newModel) {
      this.model = newModel;
    },
    validate() {
      this.loader = true;
      this.messages = {};
      this.runValidations += 1;
    },
    isValid(result, data) {
      result ? this.start(data) : (this.loader = false);
    },
    start(data) {
      let vm = this;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.PlagueMonitorings.createPlagueMonitoring(farm_id, data)
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          }).then(() => {
            vm.$router.push({ name: "plague-monitorings" });
          });
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response.data != "string") {
            vm.messages = error.response.data;
          }
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped></style>
