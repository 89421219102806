<template>
  <div class="d-flex flex-wrap">
    <template v-if="files.length > 0">
      <n-button
        v-for="(file, index) in files"
        :key="index"
        simple
        type="primary"
        size="sm"
        class="mt-0 mb-2 me-2"
        @click.native="download(file, index)"
      >
        <i :class="[getIconClass(file.type, index), 'me-1']" />
        {{ file.filename }}
      </n-button>
    </template>
    <div v-else class="muted text-left">
      <p>No hay archivos asociados</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      downloadingFileIndex: undefined,
    };
  },
  methods: {
    getIconClass(mimeType, index) {
      if (this.downloadingFileIndex == index) {
        return "fas fa-spinner fa-pulse";
      } else {
        switch (mimeType) {
          case "image/jpeg":
            return "fal fa-file-image";
          case "image/png":
            return "fal fa-file-image";
          case "application/pdf":
            return "fal fa-file-pdf";
          case "text/csv":
            return "fal fa-file-csv";
          case "application/msword":
            return "fal fa-file-word";
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            return "fal fa-file-word";
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return "fal fa-file-spreadsheet";
          case "application/zip":
            return "fal fa-file-archive";
          default:
            return "fal fa-file";
        }
      }
    },
    async download(file, index) {
      this.downloadingFileIndex = index;
      try {
        const response = await this.Services.Files.getFile(file.file_id);
        this.$HELPERS.downloadFile({
          filename: file.filename,
          content: response.data.content,
          content_type: response.data.content_type,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.downloadingFileIndex = undefined;
      }
    },
  },
};
</script>
