import { ceres_api } from "@/axios-config.js"

const Sales = {
    createSale(farm_id, model) {
        let endpoint = "/farms/" + farm_id + "/sales"
        return ceres_api.post(endpoint, model)
    },
    getSales(farm_id, filter) {
        let endpoint = "/farms/" + farm_id + "/sales"
        if (filter == undefined) {
            return ceres_api.get(endpoint)
        } else {
            return ceres_api.post(endpoint + "/filter", { filter: filter })
        }
    },
    getSale(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/sales/"
        return ceres_api.get(endpoint + id)
    }
}

export default Sales
