import Vue from "vue"

const Species = {
    data: {
        arandanos: {
            name: "Arándanos",
            variety: [
                "Biloxi",
                "Emerald",
                "Jewel",
                "O’neal",
                "Primadonna",
                "Snowchaser",
                "Star",
                "Ventur"
            ]
        }
    },
    install() {
        Object.defineProperty(Vue.prototype, "$SPECIES", {
            get() {
                return Species
            }
        })
    }
}

export default Species
