import { ceres_api } from "@/axios-config.js"

const Harvests = {
    getHarvests(farm_id, filter) {
        let endpoint = "/farms/" + farm_id + "/harvests"
        if (filter == undefined) {
            return ceres_api.get(endpoint)
        } else {
            return ceres_api.post(endpoint + "/filter", { filter: filter })
        }
    },
    getHarvest(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/harvests/"
        return ceres_api.get(endpoint + id)
    },
    createHarvest(farm_id, harvest) {
        let endpoint = "/farms/" + farm_id + "/harvests"
        return ceres_api.post(endpoint, harvest)
    },
    updateHarvest(farm_id, id, harvest) {
        let endpoint = "/farms/" + farm_id + "/harvests/"
        return ceres_api.put(endpoint + id, harvest)
    },
    deleteHarvest(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/harvests/"
        return ceres_api.delete(endpoint + id)
    }
}

export default Harvests
