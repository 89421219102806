<template lang="html">
  <div v-loading="loader">
    <purchase-details
      v-if="view_mode"
      :model="init_model">

      <template slot="actions">
        <process-controls
          v-if="Object.keys(variables).length > 0"
          :record="variables"
          @action="processAction"/>
      </template>
    </purchase-details>

    <purchase-form
      v-if="!view_mode"
      ref="purchaseForm"
      :messages="messages"
      :init-model="init_model"
      :run-validations="runValidations"
      @isValid="isValid"
      @dateUpdated="updateDate">
      <template slot="actions">
        <process-controls
          v-if="Object.keys(variables).length > 0"
          :record="variables"
          @action="processAction">
          <template slot="extra-buttons">
            <n-button
              v-if="Object.keys(variables).length == 0 || transitions_with_update.includes(decision)"
              simple=""
              @click.native="view_mode = true">
              Cerrar
            </n-button>
          </template>
        </process-controls>
        <n-button
          v-if="Object.keys(variables).length == 0"
          simple=""
          @click.native="view_mode = true">
          Cerrar
        </n-button>
        <n-button
          v-if="Object.keys(variables).length == 0"
          type="primary"
          @click.native="validate()">
          {{ saveText }}
        </n-button>
      </template>
    </purchase-form>

  </div>
</template>

<script>
import PurchaseForm from "@/views/Labors/Purchases/PurchaseForm"
import PurchaseDetails from "@/views/Labors/Purchases/PurchaseDetails"
import ProcessControls from "@/views/TaskList/Components/ProcessControls"

export default {
    components: {
        PurchaseForm,
        PurchaseDetails,
        ProcessControls
    },
    props: {
        variables: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            title: "Detalles de la compra",
            back_text: "Compras",
            back_custom_target: {
                name: "records-view",
                params: {
                    processKey: "purchases"
                }
            },
            view_mode: true,
            loader: false,
            runValidations: 1,
            messages: {},
            init_model: {},
            model: {
                date: "",
                observations: {},
                workflow: {
                    state: "new"
                }
            },
            decision: "",
            transitions_with_update: ["update_task"],
            states_which_start_with_form: ["reporting", "new"]
        }
    },
    computed: {
        saveText() {
            if (this.model.workflow.state != "new") {
                return "Guardar cambios"
            } else {
                if (this.$HELPERS.isFuture(this.model.date)) {
                    return "Programar"
                } else if (this.$HELPERS.isPast(this.model.date)) {
                    return "Registrar"
                } else {
                    return "Iniciar"
                }
            }
        }
    },
    created() {
        if (Object.keys(this.variables).length > 0) {
            this.model = this.preProcessVariables()
            this.init_model = this.$COPY.copy(this.model)
        }
        if (
            this.states_which_start_with_form.includes(
                this.model.workflow.state
            )
        ) {
            this.view_mode = false
        }

        this.setTexts()
    },
    methods: {
        preProcessVariables() {
            return this.$COPY.copy(this.variables)
        },
        setTexts() {
            if (this.model.workflow.state == "reporting") {
                this.$emit(
                    "setSubtitle",
                    "Confirmar fecha de recepción de los productos"
                )
            }

            this.$emit("setBackText", this.back_text)
            this.$emit("setBackCustomTarget", this.back_custom_target)
            this.$emit("setTitle", this.title)
        },
        processAction(action) {
            this.decision = action
            if (
                this.transitions_with_update.includes(action) &&
                this.view_mode
            ) {
                this.view_mode = false
            } else if (this.view_mode) {
                this.startOrCompleteTask()
            } else {
                this.validate()
            }
        },
        updateDate(date) {
            this.model.date = date
        },
        validate() {
            this.loader = true
            this.messages = {}
            this.runValidations += 1
        },
        isValid(isValid, model) {
            this.model = this.$COPY.copy(model)
            isValid ? this.startOrCompleteTask() : (this.loader = false)
        },
        startOrCompleteTask() {
            if (this.model.workflow.hasOwnProperty("state")) {
                if (this.model.workflow.state != "new") {
                    this.completeTask()
                } else {
                    this.start()
                }
            } else {
                this.start()
            }
        },
        start() {
            let vm = this
            this.loader = true

            this.Services.Purchases.createPurchase(
                this.model.farm_id,
                this.model
            )
                .then(response => {
                    vm.$HELPERS
                        .processServerMessage(response.data.messages)
                        .then(() => {
                            vm.$router.push({
                                name: "records-view",
                                params: {
                                    processKey: "purchases"
                                }
                            })
                        })
                })
                .catch(error => {
                    console.log(error)
                    if (typeof error.response.data != "string") {
                        vm.messages = error.response.data
                    }
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        completeTask() {
            this.$emit(
                "completeTask",
                this.$route.params.processKey,
                this.$route.params.id,
                this.model,
                this.decision
            )
            this.loader = false
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
