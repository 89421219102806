<template lang="html">
  <div>
    <el-card shadow="hover">
      <div slot="header" class="text-left phenological-states-card">
        {{ specie.value }}
      </div>
      <ol class="text-left">
        <draggable :list="model.data">
          <li
            v-for="(element, index) in model.data"
            :key="element.value"
            class="draggable-item"
          >
            <div class="row">
              <div class="col-md-10">
                {{ element.value }} <br />
                <span class="description">
                  {{ element.attr.description }}
                </span>
              </div>
              <div class="col-md-2 text-right icon-controls">
                <i
                  class="fal fa-trash icon-control danger"
                  @click="handleDelete(index)"
                />
                <i
                  class="fal fa-pencil icon-control primary ms-3"
                  @click="handleEdit(element, index)"
                />
              </div>
            </div>
          </li>
        </draggable>
      </ol>
      <div class="row">
        <div class="col-md-12 text-right">
          <n-button
            v-if="!inputVisible"
            simple=""
            size="sm"
            type="primary"
            class="ms-1"
            @click.native="showInput"
          >
            <i class="fas fa-plus-circle" />
          </n-button>
          <fg-input v-if="inputVisible" :error="errors.first('stateName')">
            <el-input
              v-validate="{
                required: true,
                uniquePhenologicalState: model.data.map((i) =>
                  i.value.toLowerCase().trim()
                ),
              }"
              ref="saveStateInput"
              v-model="inputValue"
              name="stateName"
              data-vv-as="Estado Fenológico"
              placeholder="Estado Fenológico"
              size="small"
              @keyup.escape.native="handleClose"
              @keyup.enter.native="validate"
            />
          </fg-input>
          <fg-input v-if="inputVisible">
            <el-input
              v-model="textAreaValue"
              placeholder="Descripción"
              type="textarea"
              autosize
              size="small"
              @keyup.escape.native="handleClose"
              @keyup.enter.native="validate"
            />
          </fg-input>
          <label v-if="inputVisible" class="text-left">
            Presiona Enter para guardar los cambios o Escape para cancelar
          </label>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { Card } from "element-ui";
import Draggable from "vuedraggable";
import { Validator } from "vee-validate";
export default {
  components: {
    [Card.name]: Card,
    Draggable,
  },
  props: {
    sectors: {
      type: Array,
      default: () => {
        return [];
      },
    },
    settings: {
      type: Object,
      default: () => {
        return {};
      },
    },
    specie: {
      type: Object,
      default: () => {
        return {};
      },
    },
    runValidations: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      species: [],
      model: {
        data: [
          {
            value: "Floración",
            global: true,
            category: "phenologicalStages",
            key: this.specie._id.$oid.toString(),
            type: "array",
            attr: {
              order: 1,
              description: "",
            },
          },
        ],
        toDelete: [],
      },
      inputVisible: false,
      inputValue: "",
      textAreaValue: "",
      editMode: false,
      currentItem: {},
      currentItemIndex: "",
    };
  },
  watch: {
    "model.data": {
      handler: function (newValue, oldValue) {
        this.emitModel();
      },
      deep: true,
    },
    runValidations: function (newValue, oldValue) {
      let vm = this;
      if (newValue) {
        this.$validator.validateAll().then((isValid) => {
          vm.emitModel();
          vm.$emit("isValid", isValid, this.name);
        });
      }
    },
  },
  created() {
    if (this.settings.phenologicalStages != undefined) {
      let specieStates =
        this.settings.phenologicalStages[this.specie._id.$oid.toString()];
      if (specieStates != undefined) {
        specieStates = specieStates.sort((a, b) => {
          let diff = a.attr.order - b.attr.order;
          if (diff > 0) {
            return 1;
          } else if (diff < 0) {
            return -1;
          } else {
            return 0;
          }
        });
        this.model.data = this.$COPY.copy(specieStates);
      }
    }
    this.emitModel();
  },
  mounted() {
    const isUniquePheonlogicalState = {
      validate: (value, uniqueList) => {
        let isValid = uniqueList.includes(value.toLowerCase().trim())
          ? false
          : true;
        return {
          valid: isValid,
          data: {
            message: "El Estado Fenológico debe ser único",
          },
        };
      },
      getMessage: (field, params, data) => {
        return data.message;
      },
    };
    Validator.extend("uniquePhenologicalState", isUniquePheonlogicalState);
  },
  methods: {
    emitModel() {
      let order = 1;
      this.model.data.forEach((item) => {
        item.attr.order = order;
        order += 1;
      });
      this.$emit(
        "modelUpdated",
        this.model.data.concat(this.model.toDelete),
        "phenologicalStages"
      );
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveStateInput.$refs.input.focus();
      });
    },
    handleClose() {
      this.inputVisible = false;
    },
    handleDelete(index) {
      let item = this.model.data[index];
      if (item._id != undefined) {
        item.delete = true;
        this.model.toDelete.push(item);
      }
      this.model.data.splice(index, 1);

      // let model = this.$COPY.copy(this.model.data)
      // let newStages = model.filter(i => i.value != item.value)
      // if (item._id != undefined) {
      //   item.delete = true
      //   newStages.push(item)
      // }
      // this.model.data = this.$COPY.copy(newStages)
    },
    handleEdit(item, index) {
      this.inputValue = item.value;
      this.textAreaValue = item.attr.description;
      this.editMode = true;
      this.currentItem = item;
      this.currentItemIndex = index;
      this.showInput();
    },
    validate() {
      let vm = this;
      this.$validator.validateAll().then((isValid) => {
        if (isValid) vm.addState();
      });
    },
    addState() {
      if (this.inputValue) {
        if (!this.editMode) {
          this.model.data.push({
            value: this.inputValue,
            global: true,
            category: "phenologicalStages",
            key: this.specie._id.$oid.toString(),
            type: "array",
            attr: {
              order: "",
              description: this.textAreaValue.trim(),
            },
          });
        } else {
          this.currentItem.value = this.inputValue;
          this.currentItem.attr.description = this.textAreaValue;
          this.model.data.splice(this.currentItemIndex, 1, this.currentItem);
          this.editMode = false;
          this.currentItem = {};
          this.currentItemIndex = "";
        }
      }
      this.inputVisible = false;
      this.inputValue = "";
      this.textAreaValue = "";
    },
  },
};
</script>

<style lang="sass" scoped>
.el-textarea
  margin-top: 5px !important
.el-card
  ol
    padding-inline-start: 20px !important
    .icon-control
      color: var(--label-color)
      cursor: pointer
    + .icon-control
      margin-left: 5px
    .primary:hover
      color: var(--primary-color)
    .danger:hover
      color: var(--danger-color)
    .description
      color: var(--label-color)
    .draggable-item
      cursor: move
    .phenological-states-card
      margin-top: 10px
</style>
