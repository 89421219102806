<template lang="html">
  <div
    v-loading="loader"
    class="col-md-12">
    <div
      v-if="!loader"
      class="row">
      <div class="col-md-12 text-left section-title">
        <h6>Productos que presentarán quiebres de stock</h6>
      </div>
      <div
        class="col-md-12 col-sm-12 pull-center desktop-table">
        <el-table
          :data="data"
          :empty-text="'No hay registros'"
          row-class-name=""
          class="table-striped">

          <el-table-column
            prop="category"
            label="Categoría"
            name="category"
            align="center"
            class-name="capitalize"/>

          <el-table-column
            prop="name"
            label="Producto"
            name="product"
            align="center"/>

          <el-table-column
            label="Stock Actual"
            name="computed_stock"
            align="center">
            <div slot-scope="{row}">
              {{ row.computed_stock | toLocaleStringNumber("decimal") }} {{ row.measure_unit }}
            </div>
          </el-table-column>

          <el-table-column
            label="Reservas por aplicaciones programadas"
            name="computed_reservations"
            align="center">
            <div slot-scope="{row}">
              {{ row.computed_reservations | toLocaleStringNumber("decimal") }} {{ row.measure_unit }}
            </div>
          </el-table-column>

          <el-table-column
            label="Stock Futuro"
            name="computed_future_stock"
            align="center">
            <div slot-scope="{row}">
              {{ row.computed_future_stock | toLocaleStringNumber("decimal") }} {{ row.measure_unit }}
            </div>
          </el-table-column>

          <el-table-column
            :formatter="stockShortageFormatter"
            label="Fecha del quiebre de stock"
            name="break_date"
            align="center"
            class-name="table-category"/>

        </el-table>
      </div>

      <div class="col-md-12 text-left section-title">
        <h6>Aplicaciones que producirán quiebres de stock</h6>
      </div>

      <div class="col-md-12">
        <el-table
          :data="concatProductApplications"
          empty-text="No hay aplicaciones registradas"
          class="table-striped">

          <el-table-column
            prop="status"
            label="Estado"
            align="center"
            name="status"
            column-key="status"
            class-name="table-category">
            <div
              slot-scope="{row}">
              <el-tag
                :closable="false"
                :close-transition="false"
                :type="$TASK_STATES.getColor(row.workflow.state)"
                size="small"
                class="used-product-tag">
                {{ $t(row.workflow.state) }}
              </el-tag>
            </div>
          </el-table-column>

          <el-table-column
            prop="type"
            label="Tipo de aplicación"
            align="left"/>

          <el-table-column
            prop="date"
            label="Fecha"
            align="center"
            sortable>
            <div slot-scope="{row}">
              <i class="el-icon-time"/> {{ row.date | moment("DD-MM-YYYY") }} <br> ({{ row.date | moment("dddd") }})
            </div>
          </el-table-column>

          <el-table-column
            label="Uso de productos en quiebre de stock futuro"
            align="left"
            min-width="150">

            <div
              slot-scope="{row}"
              class="text-left">
              {{ row.product.name }} ({{ row.used_product.use | toLocaleStringNumber("decimal") }} {{ row.product.measure_unit }})
            </div>
          </el-table-column>

          <el-table-column
            label="Acciones"
            align="center"
            class-name="table-category actions-column"
            width="150">

            <div
              slot-scope="{row}"
              class="text-center">
              <n-button
                type="primary"
                size="sm"
                @click.native="view(row)">
                <i class="fal fa-eye"/>
              </n-button>
            </div>
          </el-table-column>

        </el-table>
      </div>

      <div class="col-md-12 text-left section-title">
        <h6>Compras futuras o en proceso de los productos de interés</h6>
      </div>

      <div class="col-md-12">
        <el-table
          :data="concatPurchases"
          empty-text="No hay compras creadas para éstos productos"
          class="table-striped">

          <el-table-column
            prop="status"
            label="Estado"
            align="center"
            name="status"
            column-key="status"
            class-name="table-category">
            <div
              slot-scope="{row}">
              <el-tag
                :closable="false"
                :close-transition="false"
                :type="$TASK_STATES.getColor(row.workflow.state)"
                size="small"
                class="used-product-tag">
                {{ $t(row.workflow.state) }}
              </el-tag>
            </div>
          </el-table-column>

          <el-table-column
            prop="date"
            label="Fecha de la compra"
            align="center"
            sortable>
            <div slot-scope="{row}">
              <i class="el-icon-time"/> {{ row.date | moment("DD-MM-YYYY") }} <br> ({{ row.date | moment("dddd") }})
            </div>
          </el-table-column>

          <el-table-column
            prop="date"
            label="Fecha de entrega"
            align="center"
            sortable>
            <div slot-scope="{row}">
              <i class="el-icon-time"/> {{ row.arrival_date | daysFromToday | capitalize }} ({{ row.date | moment("DD-MM-YYYY") }})
            </div>
          </el-table-column>

          <el-table-column
            label="Producto de interés"
            name="products"
            align="center">
            <div slot-scope="{row}">
              {{ row.product.name }} ({{ row.purchase_item.qty }} {{ row.product.measure_unit }})
            </div>
          </el-table-column>

          <el-table-column
            label="Acciones"
            align="center"
            class-name="table-category actions-column"
            width="150">

            <div
              slot-scope="{row}"
              class="text-center">
              <n-button
                type="primary"
                size="sm"
                @click.native="view(row)">
                <i class="fal fa-eye"/>
              </n-button>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui"
import pluralize from "pluralize"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    data() {
        return {
            loader: true,
            data: []
        }
    },
    computed: {
        concatProductApplications() {
            let product_applications = []
            this.data.forEach(i => {
                let with_context = i.product_applications.map(p => {
                    p["product"] = {
                        name: i.name,
                        measure_unit: i.measure_unit
                    }
                    return p
                })
                product_applications = product_applications.concat(with_context)
            })
            return product_applications
        },
        concatPurchases() {
            let purchases = []
            this.data.forEach(i => {
                let with_context = i.purchases.map(p => {
                    p["product"] = {
                        name: i.name,
                        measure_unit: i.measure_unit
                    }
                    return p
                })
                purchases = purchases.concat(with_context)
            })
            return purchases
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Reports.getReport(farm_id, "product_stock_breaks")
                .then(response => {
                    vm.data = response.data
                    vm.$emit("countProductStockBreaks", vm.data.length)
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        stockShortageFormatter(row) {
            let break_date = this.$moment(row.computed_stock_break_date)

            return break_date.isValid()
                ? break_date.endOf("day").fromNow() +
                      " (" +
                      break_date.format("DD-MM-YYYY") +
                      ")"
                : this.$t("stock_error")
        },
        view(row) {
            this.$router.push({
                name: "continue-process",
                params: {
                    id: row._id.$oid,
                    processKey: pluralize.singular(row.process_key),
                    state: row.workflow.state,
                    backCustomTarget: {
                        name: "product-stock-shortage"
                    },
                    backText: "Volver al informe"
                }
            })
        }
    }
}
</script>

<style lang="sass">
</style>
