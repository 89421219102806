import Vue from "vue";
import Services from "@/services";

const CeresVariables = {
  data: {
    trackingType: {
      predefined: "Predefinido",
      random: "Aleatorio",
    },
    processIcon: {
      PhenologicalTrackingWorkflow: "fas fa-leaf",
      ProductApplicationWorkflow: "fas fa-eye-dropper",
      HarvestWorkflow: "fas fa-box-full",
      FarmHarvestWorkflow: "fas fa-box-full",
      WeedingWorkflow: "fas fa-seedling",
      PolinizationWorkflow: "fas fa-flower",
      PruningWorkflow: "ceres-icon scissors",
      PlagueMonitoringWorkflow: "fas fa-bug",
      IrrigationUpkeepWorkflow: "fas fa-glass-whiskey",
      PurchaseWorkflow: "fas fa-shopping-bag",
      InventoryWorkflow: "fas fa-boxes",
      WrapMaterialWorkflow: "fas fa-hand-holding-box",
      SaleWorkflow: "fas fa-handshake",
      LabReportWorkflow: "fas fa-flask",
    },
    permissions: {
      supervisors: ["admin", "agronomist", "supervisor"],
    },
    processEvents: {
      eventOrder: {
        first: ["cancel"],
        last: [
          "reject",
          "request_corrections",
          "update_task",
          "approve",
          "continue",
          "complete",
        ],
      },
      dangerEvents: ["reject", "cancel"],
    },
    processData: {
      "product-applications": {
        newText: "Aplicar producto(s)",
        cardTitle: "Registro de aplicaciones de productos",
        getRecords: Services.ProductApplications.getProductApplications,
        sidebarName: "Aplicaciones",
        route: "/labor/product-applications/records",
      },
      "farm-harvests": {
        newText: "Registrar cosecha",
        cardTitle: "Cosechas",
        sidebarName: "Cosecha Resumen",
        route: "/labor/farm-harvests/records/",
        getRecords: Services.FarmHarvests.getFarmHarvests,
      },
      harvests: {
        newText: "Registrar cosecha",
        cardTitle: "Cosechas",
        sidebarName: "Cosechas (por per.)",
        route: "/labors/harvests",
      },
      "phenological-trackings": {
        newText: "Registrar Seguimiento",
        cardTitle: "Seguimiento del Estado Fenológico",
        getRecords: Services.PhenologicalTrackings.getPhenologicalTrackings,
        sidebarName: "Estado Fenológico",
        route: "/labor/phenological-trackings/records",
      },
      "plague-monitorings": {
        sidebarName: "Plagas y enfermedad.",
        route: "/labors/plague-monitorings",
      },
      weedings: {
        newText: "Registrar Control de Malezas",
        cardTitle: "Controles de Malezas",
        getRecords: Services.Weedings.getWeedings,
        sidebarName: "Control de Malezas",
        route: "/labor/weedings/records",
      },
      prunings: {
        sidebarName: "Podas",
        route: "/labors/prunings",
      },
      polinizations: {
        newText: "Registrar Polinización",
        cardTitle: "Polinizaciones",
        getRecords: Services.Polinizations.getPolinizations,
        sidebarName: "Polinizaciones",
        route: "/labor/polinizations/records",
      },
      "irrigation-upkeeps": {
        sidebarName: "Mant. Sistema Riego",
        route: "/labors/irrigation-upkeeps",
      },
      "lab-reports": {
        newText: "Ingresar informe",
        cardTitle: "Informes de laboratorio",
        getRecords: Services.LabReports.getLabReports,
        sidebarName: "Análisis de Lab.",
        route: "/labor/lab-reports/records",
      },
      purchases: {
        newText: "Registrar compra",
        cardTitle: "Compras",
        getRecords: Services.Purchases.getPurchases,
        sidebarName: "Compras",
        route: "/labor/purchases/records",
      },
      inventories: {
        newText: "Inventariar",
        cardTitle: "Registro de inventarios",
        getRecords: Services.Inventories.getInventories,
        sidebarName: "Inventarios",
        route: "/labor/inventories/records",
      },
      "wrap-materials": {
        newText: "Empaquetar materias primas",
        cardTitle: "Registrar Empaquetamiento de materia prima",
        getRecords: Services.WrapMaterials.getWrapMaterials,
        sidebarName: "Empaquetar",
        route: "/labor/wrap-materials/records",
      },
      sales: {
        newText: "Comenzar venta",
        cardTitle: "Ventas",
        getRecords: Services.Sales.getSales,
      },
    },
  },
  getProcessIcon(icon) {
    return this.data.processIcon[icon];
  },
  install() {
    Object.defineProperty(Vue.prototype, "$CERES_VARS", {
      get() {
        return CeresVariables;
      },
    });
  },
};

export default CeresVariables;
