import EJSON from "mongodb-extjson"
import CopyObject from "./CopyObject.js"

let DataTransformation = {
    generateCamundaVariables(data) {
        const variables = {
            variables: {}
        }
        let formData = CopyObject.copy(data)
        Object.keys(formData).forEach(field => {
            let type

            switch (typeof formData[field]) {
                case "string":
                    type = "String"
                    break
                case "boolean":
                    type = "Boolean"
                    break
                case "number":
                    if (Number.isInteger(formData[field])) {
                        type = "Integer"
                    } else {
                        type = "Double"
                    }
                    break
                case "object":
                    type = "Json"
                    formData[field] = EJSON.stringify(formData[field])
                    break
                default:
                    type = "String"
            }

            variables.variables[field] = {
                value: formData[field],
                type: type
            }
        })

        return variables.variables
    },
    generateCamundaFileVariable(file, obj) {
        let reader = new FileReader()

        reader.onload = function(event) {
            obj.value = btoa(event.target.result)
            obj.type = "File"
            obj.valueInfo = {
                filename: file.name,
                mimetype: file.raw.type
            }
        }

        reader.readAsBinaryString(file.raw)

        return obj
    }
}
export default DataTransformation
