<template lang="html">
  <modal
    :show.sync="modals.processList"
    header-classes="justify-content-center"
    @close="$emit('close')"
  >
    <h4 slot="header" class="title title-up">Iniciar nueva actividad</h4>

    <div class="processes-list">
      <div
        v-for="(process, key) in processes"
        :key="key"
        class="process-element"
        @click="startProcess(process)"
      >
        <div class="process-icon">
          <i :class="$CERES_VARS.getProcessIcon(process.workflow)" />
        </div>
        <div class="process-title">
          {{ nameFormatter($t(process.workflow)) }}
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "nowui/components/Modal";
import { mapState } from "vuex";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      processKey: undefined,
      modals: {
        processList: true,
      },
      processIcon: {},
    };
  },
  computed: mapState("Processes", ["processes"]),
  methods: {
    startProcess(process) {
      let url_name = this.$HELPERS.classNameToString(process.model);
      this.$router.push({
        name: "tasklist-start-process",
        params: {
          id: url_name,
        },
      });
    },
    processIconClass(key) {
      return this.processIcon[key];
    },
    processElementClass(key) {
      let cssClass = "process-element";

      if (this.processKey == key) cssClass += " active";

      return cssClass;
    },
    nameFormatter(text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },
  },
};
</script>

<style lang="sass" scoped>
.processes-list
  display: grid
  grid-template-columns: repeat(2, 1fr)
  row-gap: 30px
  column-gap: 30px
.process-element
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  height: 80px
  box-shadow: 1px 1px 2px 1px rgba(0,0,0,.1)
  border-radius: 3px
  .process-icon
    width: 30%
    font-size: 40px
    height: 80px
    line-height: 80px
    color: #1858a7
    .ceres-icon
      margin-top: 15px
      background-color: #1858a7
      background-repeat: none
      height: 50px
      width: 50px
  .process-title
    color: #1858a7
    font-weight: normal
    font-size: 16px
    margin-right: auto
    padding-right: 16px

.process-element:hover
  background-color: #1858a7
  cursor: pointer
  .process-icon
    color: white
    .ceres-icon
      background-color: #FFFFFF !important
      filter: none !important
  .process-title
    color: white
</style>
