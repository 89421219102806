<template lang="html">
  <card class="records-view">
    <template slot="header">
      <card-controls :back="false">
        <span slot="actions">
          <n-button
            v-if="canCreate"
            type="primary"
            simple=""
            @click.native="createNewRecord()"
          >
            <i class="fas fa-plus-circle" />
            {{ processData.newText }}
          </n-button>
          <n-button
            v-if="!components.globalFilter"
            type="primary"
            simple=""
            @click.native="components.globalFilter = true"
          >
            <i class="fal fa-filter" />
          </n-button>
          <n-button
            v-if="components.globalFilter"
            type="danger"
            simple=""
            @click.native="handleCloseFilter()"
          >
            <i class="fal fa-filter" />
          </n-button>
          <n-button type="primary" simple="" @click.native="goToDiagram">
            <i class="fal fa-project-diagram" />
          </n-button>
        </span>
      </card-controls>

      <h4 class="card-title">
        <b>{{ processData.cardTitle }}</b>
      </h4>
    </template>

    <div v-loading="loader">
      <component
        v-if="components.globalFilter"
        :is="processGlobalFilter"
        :records="records"
        @close="handleCloseFilter"
        @applyFilter="applyFilter"
      />

      <template v-if="customGroupProcesses.includes($route.params.processKey)">
        <component
          :is="processGroups"
          :records="records"
          :loader="loader"
          :show-all-tab="showAllTab"
        />
      </template>
      <template v-else>
        <tabs
          v-if="!loader"
          :value="activeTab"
          type="primary"
          class="col-md-12"
        >
          <tab-pane label="En curso">
            <span slot="label">
              En curso
              <badge
                :type="'default'"
                :content="groupRecords.in_progress.length | toString"
              />
            </span>
            <component
              :show-status="true"
              :is="processTable"
              :data="groupRecords.in_progress"
            />
          </tab-pane>
          <tab-pane label="Programado">
            <span slot="label">
              Programado
              <badge
                :type="'default'"
                :content="groupRecords.scheduled.length | toString"
              />
            </span>
            <component :is="processTable" :data="groupRecords.scheduled" />
          </tab-pane>
          <tab-pane label="Realizado">
            <span slot="label">
              Realizado
              <badge
                :type="'default'"
                :content="groupRecords.done.length | toString"
              />
            </span>
            <component :is="processTable" :data="groupRecords.done" />
          </tab-pane>
          <tab-pane label="Todos">
            <span slot="label">
              Todas
              <badge :type="'default'" :content="records.length | toString" />
            </span>
            <component :is="processTable" :show-status="true" :data="records" />
          </tab-pane>
        </tabs>
      </template>
    </div>
  </card>
</template>

<script>
import { Tabs, TabPane } from "nowui/components";
import * as LaborComponents from "@/views/Labors";
import * as CommercialComponents from "@/views/Commercial";
import pluralize from "pluralize";
import camelCase from "camelcase";
import { mapGetters, mapState } from "vuex";
import ProcessesService from "@/services/Processes";

export default {
  components: {
    Tabs,
    TabPane,
    ...LaborComponents.Polinizations,
    ...LaborComponents.Weedings,
    ...LaborComponents.PhenologicalTrackings,
    ...LaborComponents.Purchases,
    ...LaborComponents.Inventories,
    ...LaborComponents.ProductApplications,
    ...LaborComponents.WrapMaterials,
    ...LaborComponents.LabReports,
    ...CommercialComponents.Sales,
    ...LaborComponents.FarmHarvests,
    ...LaborComponents.Prunings,
  },
  data() {
    return {
      loader: true,
      sectors: [],
      records: [],
      showAllTab: false,
      components: {
        globalFilter: false,
      },
      processData: {},
      customGroupProcesses: ["sales"],
    };
  },
  computed: {
    ...mapState("Auth", ["isAdmin", "isSupervisor"]),
    ...mapGetters({ farmId: "Farms/GET_FARM_ID" }),
    canCreate() {
      return this.isAdmin || this.isSupervisor;
    },
    activeTab() {
      return this.showAllTab ? "Todos" : "";
    },
    groupRecords() {
      return this.$HELPERS.groupLaborRecords(this.records);
    },
    processName() {
      return pluralize.singular(this.$route.params.processKey);
    },
    processTable() {
      return camelCase(this.processName, { pascalCase: true }) + "Table";
    },
    processGroups() {
      return camelCase(this.processName, { pascalCase: true }) + "Groups";
    },
    processGlobalFilter() {
      return camelCase(this.processName, { pascalCase: true }) + "GlobalFilter";
    },
  },
  watch: {
    "$route.params.processKey": function (newValue, oldValue) {
      if (newValue != oldValue) this.setVariables();
    },
  },
  created() {
    this.setVariables();
  },
  methods: {
    setVariables() {
      this.loader = true;
      this.processKey = this.$route.params.processKey;
      this.processData =
        this.$CERES_VARS.data.processData[this.$route.params.processKey];
      this.$emit("setWindowTitle", this.processData.cardTitle);
      this.getRecords();
    },
    createNewRecord() {
      this.$router.push({
        name: "tasklist-start-process",
        params: {
          id: this.processName,
        },
      });
    },
    async getRecords(query) {
      try {
        this.loader = true;
        let r;
        if (this.processData.getRecords != undefined) {
          r = await this.processData.getRecords(this.farmId, query);
        } else {
          r = await ProcessesService.getRecords(
            this.farmId,
            this.$route.params.processKey
          );
        }
        this.records = r.data;
        this.showAllTab = query != undefined ? true : false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loader = false;
      }
    },
    handleCloseFilter() {
      this.components.globalFilter = false;
      this.getRecords();
    },
    applyFilter(query) {
      this.loader = true;
      this.getRecords(query);
    },
    goToDiagram() {
      this.$router.push({
        name: "diagram",
        params: {
          processKey: this.processName,
          state: "new",
          customBackTarget: {
            name: "records-view",
            params: {
              processKey: this.$route.params.processKey,
            },
          },
        },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.records-view
  min-height: 200px
</style>
