<template lang="html">
  <card class="content-card">
    <template slot="header">
      <card-controls :back="false" >
        <span slot="actions">
          <n-button
            type="primary"
            simple=""
            @click.native="createNewRecord()">
            <i class="fas fa-plus-circle" />
            Registrar Mantenimiento
          </n-button>
          <n-button
            v-if="!components.globalFilter"
            type="primary"
            simple=""
            @click.native="components.globalFilter = true">
            <i class="fal fa-filter"/>
          </n-button>
          <n-button
            v-if="components.globalFilter"
            type="danger"
            simple=""
            @click.native="handleCloseFilter()">
            <i class="fal fa-filter"/>
          </n-button>
          <n-button
            type="primary"
            simple=""
            @click.native="goToDiagram">
            <i class="fal fa-project-diagram"/>
          </n-button>
        </span>
      </card-controls>

      <h4 class="card-title"><b>Mantenimiento del Sistema de Riego</b></h4>
    </template>

    <global-filter
      v-if="components.globalFilter"
      :sectors="sectors"
      @close="handleCloseFilter"
      @applyFilter="applyFilter"/>

    <div v-loading="loader">
      <record-groups
        v-if="!loader"
        :records="records"
        :show-all-tab="showAllTab"/>
    </div>

  </card>
</template>

<script>
import RecordGroups from "./Components/IrrigationUpkeepGroups"
import GlobalFilter from "./Components/GlobalFilter"

export default {
    components: {
        RecordGroups,
        GlobalFilter
    },
    data() {
        return {
            farm_id: "",
            loader: true,
            sectors: [],
            records: [],
            showAllTab: false,
            components: {
                globalFilter: false
            }
        }
    },
    created() {
        this.farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
        this.getRecords()
    },
    methods: {
        createNewRecord() {
            this.$router.push({
                name: "tasklist-start-process",
                params: {
                    id: "irrigation-upkeep"
                }
            })
        },
        getRecords(filter) {
            let vm = this
            vm.showAllTab = false
            this.Services.IrrigationUpkeeps.getIrrigationUpkeeps(
                this.farm_id,
                filter
            )
                .then(response => {
                    vm.records = response.data
                    vm.showAllTab = filter != undefined ? true : false
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        handleCloseFilter() {
            this.components.globalFilter = false
            this.getRecords()
        },
        applyFilter(query) {
            this.loader = true
            this.getRecords(query)
        },
        goToDiagram() {
            this.$router.push({
                name: "diagram",
                params: {
                    processKey: "irrigation-upkeep",
                    state: "new",
                    backTarget: "irrigation-upkeeps"
                }
            })
        }
    }
}
</script>

<style lang="css" scoped>
</style>
