<template lang="html">
  <div class="">
    <div class="offset-md-8 col-md-4 col-sm-12 text-left">
      <fg-input
        v-model="search"
        type="text"
        placeholder="Buscar..."/>
    </div>
    <el-table
      :data="filteredProducts"
      :empty-text="'Sin productos'"
      :row-class-name="productRowClassName"
      class="table-striped products-table">

      <el-table-column
        :filters="computedBrands"
        :filter-method="filterBrand"
        prop="brand"
        label="Marca"
        sortable
      />

      <el-table-column
        prop="name"
        label="Nombre comercial"
        sortable
        class="text-left"/>

      <el-table-column
        prop="use_mode"
        label="Modo de uso"
        sortable
        class="text-left"/>

      <el-table-column
        prop="active_ingredient"
        label="Ingrediente activo"
        sortable>
        <div
          slot-scope="{row}"
          class="text-left">
          <span
            v-for="(active_ingredient,key) in row.active_ingredient"
            :key="key">
            {{ active_ingredient.value }} <br>
          </span>
        </div>
      </el-table-column>

      <el-table-column
        label="Stock real"
        sortable
        align="right"
        header-align="center"
      >
        <div
          slot-scope="{row}">
          {{ row.computed_stock | toLocaleStringNumber("decimal") }} {{ row.measure_unit }}
        </div>
      </el-table-column>

      <el-table-column
        label="Stock reservado"
        align="right"
        header-align="center">
        <div
          slot-scope="{row}">
          {{ row.computed_reservations | toLocaleStringNumber("decimal") }} {{ row.measure_unit }}
        </div>
      </el-table-column>

      <el-table-column
        label="Stock Futuro"
        align="right"
        header-align="center">
        <div
          slot-scope="{row}">
          <el-tooltip
            :open-delay="300"
            content="Stock Futuro = Stock real + Compras - Stock reservado "
            placement="top"
            effect="dark">
            <div>
              {{ row.computed_future_stock | toLocaleStringNumber("decimal") }} {{ row.measure_unit }}
            </div>
          </el-tooltip>
        </div>
      </el-table-column>

      <el-table-column
        label="Acciones"
        align="center"
      >
        <div
          slot-scope="{row}"
          class="controls">
          <n-button
            type="primary"
            size="sm"
            @click.native="viewProduct(row)">
            <i class="fal fa-eye"/>

          </n-button>
        </div>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    props: {
        products: {
            type: Array,
            default: () => {
                return []
            }
        },
        applications: {
            type: Array,
            default: () => {
                return []
            }
        },
        category: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            search: ""
        }
    },
    computed: {
        computedBrands() {
            let brands = []

            for (let index in this.products) {
                if (
                    this.products[index].brand.length > 0 &&
                    this.$FIND.find(
                        brands,
                        "text",
                        this.products[index].brand
                    ) == -1
                ) {
                    brands.push({
                        text: this.products[index].brand,
                        value: this.products[index].brand
                    })
                }
            }
            return brands
        },
        computedWarehouses() {
            let warehouses = []

            for (let index in this.products) {
                if (
                    this.products[index].warehouse.name.length > 0 &&
                    this.$FIND.find(
                        warehouses,
                        "text",
                        this.products[index].warehouse.name
                    ) == -1
                ) {
                    warehouses.push({
                        text: this.products[index].warehouse.name,
                        value: this.products[index].warehouse.name
                    })
                }
            }
            return warehouses
        },
        filteredProducts() {
            let search = this.search.toLowerCase()
            let currentProducts = this.$COPY.copy(this.products)
            currentProducts = currentProducts.filter(
                p => p.category == this.category
            )

            return currentProducts.filter(data => {
                let obj = this.$HELPERS.pick(data, [
                    "brand",
                    "name",
                    "active_ingredient",
                    "use_mode"
                ])

                if (
                    obj.active_ingredient &&
                    obj.hasOwnProperty("active_ingredient")
                ) {
                    obj.active_ingredient = obj.active_ingredient
                        .map(i => i.value.toLowerCase())
                        .join("|")
                } else {
                    obj.active_ingredient = ""
                }
                return (
                    !this.search ||
                    JSON.stringify(Object.values(obj))
                        .toLowerCase()
                        .includes(search)
                )
            })
        }
    },
    methods: {
        deleteProduct(product) {
            this.$emit("deleteProduct", product)
        },
        filterBrand(value, row) {
            return row.brand === value
        },
        filterWarehouse(value, row) {
            return row.warehouse.name === value
        },
        productRowClassName({ row }) {
            return row.computed_future_stock < 0 ? "warning-row" : ""
        },
        viewProduct(row) {
            this.$router.push({
                name: "view-product",
                params: {
                    id: row._id.$oid,
                    create: false
                }
            })
        }
    }
}
</script>

<style lang="sass">
	.el-table-filter
		z-index: 10000 !important
</style>
