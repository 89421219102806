<template lang="html">
  <div class="card-controls">
    <n-button
      v-if="back"
      type="default"
      class="back"
      simple=""
      @click.native="goBack">
      <i class="fal fa-chevron-left"/>
      {{ backText }}
    </n-button>

    <slot
      name="actions"
      class="actions"/>
  </div>
</template>

<script>
export default {
    props: {
        back: {
            type: Boolean,
            default: false
        },
        backTarget: {
            type: String,
            default: ""
        },
        backText: {
            type: String,
            default: "Volver"
        },
        backTargetName: {
            type: String,
            default: ""
        },
        backCustomTarget: {
            type: Object,
            default: () => {
                return {}
            }
        },
        backParams: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    methods: {
        goBack() {
            if (this.backTarget != "") {
                this.$router.push(this.backTarget)
            } else if (Object.keys(this.backCustomTarget).length > 0) {
                this.$router.push(this.backCustomTarget)
            } else if (this.backTargetName != "") {
                this.$router.push({
                    name: this.backTargetName,
                    params: this.backParams
                })
            } else {
                this.$router.go(-1)
            }
        }
    }
}
</script>

<style lang="sass">
	.card-controls
		display: inline-block
		width: 100%
		text-align: right
		border-bottom: 1px solid #ebecf1
		padding-bottom: 15px
		.btn
			margin: 0px
		.back
			float: left
		span
			.btn
				margin-right: 5px
			.btn:last-child
				margin-right: 0px
</style>
