<template lang="html">
  <div
    v-loading="loader"
    class="row">
    <div
      v-if="!loader && Object.keys(settings).length > 0"
      class="col-md-12">

      <div class="row">
        <div class="col-md-12 text-left">
          <h5>Configuración del monitoreo</h5>
        </div>
        <div class="col-md-4 text-left">
          <fg-input
            :error="errors.first('date')"
            required
            label="Fecha del monitoreo">
            <el-date-picker
              v-validate="{required: true}"
              v-model="model.date"
              data-vv-as="Fecha del monitoreo"
              name="date"
              format="dd-MM-yyyy"
              type="date"
              placeholder="Fecha del monitoreo"/>
          </fg-input>
        </div>
        <div class="col-md-4 text-left">
          <fg-input
            :error="errors.first('assignee')"
            class="has-label">
            <label>Responsable <span>*</span></label>
            <el-select
              v-validate="{required: true}"
              v-model="model.workflow.assignee.$oid"
              :disabled="disableAssignee"
              filterable
              class="select-primary"
              placeholder="Seleccione usuario que realizará el monitoreo"
              name="assignee"
              vv-data-as="Responsable">
              <el-option
                v-for="(user,key) in users"
                :value="user._id.$oid"
                :label="user.full_name"
                :key="key"
                class="select-danger"/>
            </el-select>
          </fg-input>
        </div>
        <div class="col-md-4 text-left">
          <fg-input
            :error="errors.first('tracking_type')"
            class="has-label">
            <label>Tipo de monitoreo <span>*</span></label>
            <el-select
              v-validate="{required: true}"
              v-model="model.tracking_type"
              :disabled="['waiting_supervisor'].includes(model.workflow.state)"
              filterable
              class="select-primary"
              placeholder="Tipo de monitoreo"
              name="tracking_type"
              vv-data-as="Tipo de monitoreo">
              <el-option
                :label="$t('predefined')"
                value="predefined"
                class="select-danger"/>
              <el-option
                :label="$t('random')"
                value="random"
                class="select-danger"/>
            </el-select>
          </fg-input>
        </div>
      </div>

      <div
        v-if="model.tracking_type == 'random'"
        class="row">
        <div class="col-md-4 text-left">
          <fg-input
            :value="settings.plaguesTracking.numOfObservations.value"
            label="Observaciones por cuartel"
            disabled
          />
        </div>
        <div class="col-md-8 text-left">
          <fg-input
            :error="errors.first('sectors')"
            class="has-label">
            <label>Cuarteles <span>*</span></label>
            <el-select
              v-validate="{required: true}"
              v-model="model.sector_ids"
              filterable
              class="select-primary"
              placeholder="Cuarteles a monitorear"
              name="sectors"
              vv-data-as="Cuarteles"
              multiple=""
              @change="setRandomMonitoring">
              <el-option
                v-for="(sector,key) in sectors"
                :value="sector._id.$oid"
                :label="sector.name"
                :key="key"
                class="select-danger"/>
            </el-select>
          </fg-input>
        </div>
      </div>

      <div
        v-if="$HELPERS.isPast(model.date) || model.workflow.state == 'reporting'"
        class="row">
        <div class="col-md-12 text-left">
          <h5>Registro de observaciones</h5>
        </div>
        <div class="col-md-12">
          <el-tabs
            v-if="model.sector_ids.length > 0"
            v-model="currentSector">
            <el-tab-pane
              v-for="sector in model.sector_ids"
              :key="sector"
              :name="sector"
              :label="sector">
              <span slot="label">
                <span
                  :class="validationIcon(sector).class"
                  v-html="validationIcon(sector).icon"/>
                {{ $HELPERS.getSectorNames(sector, sectors) }}
              </span>
              <div class="row">
                <div class="col-md-12">
                  <div
                    v-for="(ob, index) in model.plague_monitoring_observations.filter(i => i.sector_id.$oid == sector)"
                    :key="sector + '_' + index"
                    class="row observation">
                    <div class="col-md-4 text-left">
                      <fg-input
                        v-validate="{ required: true, min_value: 1, decimal: false }"
                        v-model.number="ob.line_number"
                        :error="errors.first(sector + '_line_number_'+index)"
                        :name="sector + '_line_number_'+index"
                        :disabled="isPredefinedTracking"
                        required
                        type="number"
                        label="Número de hilera"
                        placeholder="Número de hilera"
                        data-vv-as="Número de hilera"/>
                    </div>
                    <div class="col-md-4 text-left">
                      <fg-input
                        v-validate="{ required: true, min_value: 1, decimal: false }"
                        v-model.number="ob.plant_number"
                        :error="errors.first(sector + '_plant_number_'+index)"
                        :name="sector + '_plant_number_'+index"
                        :disabled="isPredefinedTracking"
                        required
                        type="number"
                        label="Número de la planta"
                        placeholder="Número de la planta"
                        data-vv-as="Número de la planta"/>
                    </div>
                    <div class="col-md-4 text-left">
                      <fg-input
                        :error="errors.first(sector + '_plagues_detected_'+index)"
                        label="¿Se detectaron plagas?"
                        required>
                        <el-select
                          v-validate="{required: true}"
                          v-model="ob.plagues_detected"
                          :name="sector + '_plagues_detected_'+index"
                          data-vv-as="¿Se detectaron plagas?"
                          class="select-primary"
                          placeholder="¿Se detectaron plagas?"
                          @change="initPlagueForm(ob)">
                          <el-option
                            key="1"
                            :value="true"
                            label="Si"
                            class="select-danger"/>
                          <el-option
                            key="0"
                            :value="false"
                            label="No"
                            class="select-danger"/>
                        </el-select>
                      </fg-input>
                    </div>

                    <div
                      v-if="ob.plagues_detected"
                      class="col-md-12">
                      <div
                        v-for="(plague, plague_index) in ob.plague_detections"
                        :key="sector+'_'+plague+'_'+plague_index"
                        class="row">
                        <div class="col-md-4 text-left">
                          <fg-input
                            :error="errors.first(sector+'_ob_'+index+'_plague_id_'+plague_index)"
                            label="Plaga detectada"
                            required>
                            <el-select
                              v-validate="{required: true}"
                              v-model="plague.plague_id.$oid"
                              :name="sector+'_ob_'+index+'_plague_id_'+plague_index"
                              data-vv-as="Plaga detectada"
                              class="select-primary"
                              placeholder="Plaga detectada"
                            >
                              <el-option
                                v-for="(setting_plague, setting_index) in settings.plaguesTracking.plagues"
                                :value="setting_plague._id.$oid"
                                :key="sector+'_ob_'+index+'_plague_id_'+plague_index+setting_plague._id.$oid+setting_index"
                                :label="setting_plague.value.scientificName"
                                class="select-danger italic">
                                {{ setting_plague.value.commonName }}
                                (<i>{{ setting_plague.value.scientificName }}</i>)
                              </el-option>
                            </el-select>
                          </fg-input>
                        </div>
                        <div class="col-md-4 text-left">
                          <fg-input
                            v-validate="{ required: true, min_value: 1, decimal: false }"
                            v-model.number="plague.count"
                            :error="errors.first(sector+'_ob_'+index+'_plague_id_'+plague_index+'_count')"
                            :name="sector+'_ob_'+index+'_plague_id_'+plague_index+'_count'"
                            type="number"
                            label="Conteo"
                            placeholder="Conteo"
                            data-vv-as="Conteo"
                            required/>
                        </div>
                        <div class="col-md-4 text-left vertical-align">
                          <n-button
                            simple=""
                            type="danger"
                            size="sm"
                            @click.native="rmLine(ob, plague_index)">
                            <i class="fas fa-minus-circle"/>
                          </n-button>
                          <n-button
                            v-if="isLastRow(ob, plague_index)"
                            simple=""
                            size="sm"
                            type="primary"
                            @click.native="addLine(ob)">
                            <i class="fas fa-plus-circle" />
                          </n-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </el-tab-pane>
          </el-tabs>
          <el-alert
            v-else
            :closable="false"
            title="Selecione al menos un cuartel a monitorear"
            type="warning"
            show-icon/>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <slot
            name="actions"
            class="actions"/>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <notification
            :messages="messages"
            :close="true"
            type="error"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Tabs, TabPane, Alert } from "element-ui"

export default {
    components: {
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
        [Alert.name]: Alert
    },
    props: {
        runValidations: {
            type: Number,
            default: 0
        },
        messages: {
            type: Object,
            default: () => {
                return {}
            }
        },
        record: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            settings: {},
            farm_id: "",
            is_supervisor: false,
            render: false,
            loader: true,
            sectors: [],
            users: [],
            currentSector: "",
            model: {
                date: "",
                farm_id: "",
                tracking_type: "",
                plague_monitoring_observations: [],
                sector_ids: [],
                workflow: {
                    state: "new",
                    assignee: {
                        $oid: ""
                    }
                }
            },
            observation_model: {
                line_number: "",
                plant_number: "",
                plagues_detected: "",
                sector_id: {
                    $oid: ""
                },
                plague_detections: []
            },
            plague_detections_model: {
                count: "",
                tolerance: "",
                tolerance_exceeded: "",
                plague_id: {
                    $oid: ""
                }
            },
            validation_perfomed: false,
            sector_with_errors: []
        }
    },
    computed: {
        isPredefinedTracking() {
            return this.model.tracking_type == "predefined" ? true : false
        },
        disableAssignee() {
            return ["done", "scheduled"].includes(this.model.workflow.state) &
                this.is_supervisor
                ? true
                : false
        }
    },
    watch: {
        runValidations: function(newValue, oldValue) {
            let vm = this
            this.validation_perfomed = false
            this.sector_with_errors = []
            this.$validator.validateAll().then(isValid => {
                vm.$emit("isValid", isValid, vm.model)
                if (!isValid) {
                    this.sector_with_errors = []
                    this.sector_with_errors = this.errors.items.reduce(
                        (acc, i) => {
                            let field = i.field.split("_")
                            if (field.length > 2) {
                                if (!acc.includes(field[0])) acc.push(field[0])
                            }
                            return acc
                        },
                        []
                    )
                }
                vm.validation_perfomed = true
            })
        },
        model: {
            handler: function(newValue, oldValue) {
                this.$emit("modelUpdated", newValue)
            },
            deep: true
        },
        "model.tracking_type": function(newValue, oldValue) {
            if (Object.keys(this.record).length == 0) {
                this.model.sector_ids = []
                this.model.plague_monitoring_observations = []

                if (newValue == "predefined") {
                    this.setPredefinedMonitoring()
                }
            }
        }
    },
    created() {
        if (Object.keys(this.record).length > 0) {
            this.model = this.$COPY.copy(this.record)
            this.model.sector_ids = this.model.show_sectors.map(i => i.$oid)
            this.currentSector = this.model.sector_ids[0]
        }
        this.is_supervisor = this.$store.getters["Auth/IS_SUPERVISOR"]
        this.getSettings()
    },
    methods: {
        getSettings() {
            this.farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.model.farm_id = this.farm_id
            this.settings = this.$store.getters["Settings/GET_SETTINGS"]
            if (Object.keys(this.record).length == 0) this.initForm()
            this.getSectors()
        },
        initForm() {
            if (this.model.tracking_type == "predefined") {
                this.setPredefinedMonitoring()
            }
        },
        getSectors() {
            let vm = this
            this.Services.Sectors.getSectors(this.farm_id)
                .then(response => {
                    vm.sectors = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.getUsers()
                })
        },
        getUsers() {
            let vm = this
            this.Services.Users.getUsers()
                .then(response => {
                    vm.users = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        setPredefinedMonitoring() {
            // data from settings
            let predefined = this.settings.plaguesTracking
                .predefinedPlagueTracking
            let sector_path = "i.value.sector"
            let line_number_path = "i.value.lineNumber"
            let plant_number_path = "i.value.plantNumber"

            if (this.model.validation_schema != undefined) {
                predefined = this.model.validation_schema.predefined
                sector_path = "i.sector.$oid"
                line_number_path = "i.line_number"
                plant_number_path = "i.plant_number"
            }

            this.model.sector_ids = predefined.reduce((acc, i) => {
                if (!acc.includes(eval(sector_path)))
                    acc.push(eval(sector_path))
                return acc
            }, [])

            predefined.forEach(i => {
                let observation = this.$COPY.copy(this.observation_model)
                observation["line_number"] = eval(line_number_path)
                observation["plant_number"] = eval(plant_number_path)
                observation["sector_id"]["$oid"] = eval(sector_path)
                this.model.plague_monitoring_observations.push(observation)
            })

            this.currentSector = this.model.sector_ids[0]
        },
        setRandomMonitoring() {
            this.model.plague_monitoring_observations = []
            this.model.sector_ids.forEach(sector_id => {
                let i = 0
                while (
                    i < this.settings.plaguesTracking.numOfObservations.value
                ) {
                    let observation = this.$COPY.copy(this.observation_model)
                    observation["sector_id"]["$oid"] = sector_id
                    this.model.plague_monitoring_observations.push(observation)
                    i += 1
                }
            })
            this.currentSector = this.model.sector_ids[0]
        },
        initPlagueForm(ob) {
            let plague_model = this.$COPY.copy(this.plague_detections_model)
            if (ob.plagues_detected) {
                if (ob.plague_detections == undefined)
                    ob["plague_detections"] = []
                ob.plague_detections.push(plague_model)
            } else {
                ob.plague_detections = []
            }
        },
        addLine(plague) {
            plague.plague_detections.push(
                this.$COPY.copy(this.plague_detections_model)
            )
        },
        rmLine(ob, plague_index) {
            ob.plague_detections.splice(plague_index, 1)
            if (ob.plague_detections.length == 0) ob.plagues_detected = false
        },
        isLastRow(ob, plague_index) {
            let total = ob.plague_detections.length
            return total - 1 == plague_index ? true : false
        },
        validationIcon(sector) {
            let validation = {
                icon: "<i class='fal fa-check-circle success'></i>",
                class: "success"
            }

            if (this.validation_perfomed) {
                if (this.sector_with_errors.includes(sector)) {
                    validation.icon =
                        "<i class='fal fa-exclamation-circle danger'></i>"
                    validation.class = "danger"
                }
            } else {
                validation.icon = "<i class='fal fa-pencil'></i>"
                validation.class = ""
            }

            return validation
        }
    }
}
</script>

<style lang="sass" scoped>
    .vertical-align
      display: flex
      align-items: center
      flex-wrap: wrap
      padding-top: 15px
</style>
