import FarmsService from "../services/Farms";
import SectorsService from "../services/Sectors";
import WarehousesService from "../services/Warehouses";
import { get } from "lodash";

const Farms = {
  namespaced: true,
  state: {
    currentFarm: {},
    sectors: [],
    sector_options: [],
    warehouses: [],
    yield_type: "",
    farms: [],
  },
  mutations: {
    SET_FARM(state, value) {
      state.currentFarm = value;
      state.yield_type = value.yield_type;
    },
    setCurrentFarmData(state, { sectors, sector_options, warehouses }) {
      state.sectors = sectors;
      state.sector_options = sector_options;
      state.warehouses = warehouses;
    },
    SET_FARMS(state, farms) {
      state.farms = farms;
    },
    setInitialFarm(state) {
      if (Object.keys(state.currentFarm).length == 0) {
        state.currentFarm = JSON.parse(localStorage.getItem("farm"));
      }
    },
  },
  getters: {
    GET_FARM(state) {
      return state.currentFarm;
    },
    GET_YIELD_TYPE(state) {
      return state.yield_type;
    },
    GET_FARM_ID(state) {
      return get(state.currentFarm, "_id.$oid");
    },
    hasSectors(state) {
      return state.sectors.length > 0;
    },
    hasWarehouses(state) {
      return state.warehouses.length > 0;
    },
  },
  actions: {
    getFarms: async function (context) {
      try {
        const r = await FarmsService.getFarms();
        context.commit("SET_FARMS", r.data);
      } catch (error) {
        console.log(error);
      }
    },
    getCurrentFarmData: async function ({ commit, state }, farmId) {
      let id = farmId;
      if (id == undefined) id = state.currentFarm._id.$oid;
      const sectors = SectorsService.getSectors(id);
      const sector_options = SectorsService.getSectorOptions(id);
      const warehouses = WarehousesService.getWarehouses(id);
      try {
        const r = await Promise.all([sectors, sector_options, warehouses]);
        commit("setCurrentFarmData", {
          sectors: r[0].data,
          sector_options: r[1].data,
          warehouses: r[2].data,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};

export default Farms;
