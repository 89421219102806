<template lang="html">
  <div
    v-loading="!render"
    class="row">
    <div
      v-if="render"
      class="col-md-10 offset-md-1">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-info-circle"/></div>
                <h5 class="info-title"><span>{{ $t(model.workflow.state) }}</span></h5>
                <h6 class="stats-title">Estado</h6>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-calendar"/></div>
                <h5
                  v-if="model.hasOwnProperty('date')"
                  class="info-title">
                  <span>{{ model.date | moment("DD-MM-YYYY") }}</span>
                </h5>
                <h6 class="stats-title">Fecha</h6>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="statistics">
              <div class="info">
                <div :class="'icon icon-'+anyToleranceExceeded.class"><i class="fal fa-bug"/></div>
                <h5 class="info-title"><span>{{ anyToleranceExceeded.text }}</span></h5>
                <h6 class="stats-title">¿Tolerancia superada?</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-left">
          <h5 class="plague-title">Sectores {{ getSectorDescription }}</h5>
          <sector-tags
            :current_sectors="model.show_sectors"
            :sectors="sectors"/>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12 text-left">
          <h5>Plagas que superan la tolerancia objetivo</h5>
        </div>
        <div class="col-md-12">
          <el-table
            :data="dangerPlagues"
            empty="No hay plagas">
            <el-table-column
              label="Plaga detectada"
              min-width="200">
              <div slot-scope="{row}">
                <span v-html="getPlagueName(row.plague_id.$oid)"/>
              </div>
            </el-table-column>
            <el-table-column
              label="Cuartel"
              align="center">
              <div slot-scope="{row}">
                {{ row.sector.name }}
              </div>
            </el-table-column>
            <el-table-column
              label="Número de hilera"
              prop="line_number"
              align="center" />
            <el-table-column
              label="Número de planta"
              prop="plant_number"
              align="center" />
            <el-table-column
              label="Conteo"
              prop="count"
              align="center" />
            <el-table-column
              label="Tolerancia"
              prop="tolerance"
              align="center" />
            <el-table-column
              label="Diferencia"
              align="center">
              <div slot-scope="{row}">
                {{ row.tolerance - row.count }}
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12 text-left">
          <h5>Registro de observaciones</h5>
        </div>
        <div class="col-md-12">
          <el-tabs
            v-if="model.show_sectors.length > 0"
            v-model="currentSector"
            type="border-card">
            <el-tab-pane
              v-for="sector in model.show_sectors.map(i => i.$oid)"
              :key="sector"
              :name="sector"
              :label="sector">
              <span slot="label">
                {{ $HELPERS.getSectorNames(sector, sectors) }}
              </span>
              <div class="row">
                <div class="col-md-12">
                  <el-table
                    :data="getSectorObservations(sector)"
                    empty-text="No hay observaciones registradas">
                    <el-table-column
                      type="expand"
                      row-class-name="warning-row">
                      <template slot-scope="props">
                        <el-table
                          :data="props.row.plague_detections">
                          <el-table-column
                            label="Plaga detectada"
                            min-width="250">
                            <div slot-scope="{row}">
                              <span v-html="getPlagueName(row.plague_id.$oid)"/>
                            </div>
                          </el-table-column>
                          <el-table-column
                            label="Conteo"
                            prop="count"
                            align="center" />
                          <el-table-column
                            label="Tolerancia"
                            prop="tolerance"
                            align="center" />
                          <el-table-column
                            label="Diferencia"
                            align="center">
                            <div slot-scope="{row}">
                              {{ row.tolerance - row.count }}
                            </div>
                          </el-table-column>
                        </el-table>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="line_number"
                      label="Número de hilera"
                      align="center"
                      row-class-name="warning-row"/>
                    <el-table-column
                      prop="plant_number"
                      label="Número de planta"
                      align="center"/>
                    <el-table-column
                      :formatter="plaguesDetectedFormatter"
                      prop="plagues_detected"
                      label="¿Se detectaron plagas?"
                      align="center"/>

                  </el-table>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <slot
            name="actions"
            class="actions"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import SectorTags from "@/views/Labors/Components/SectorTags"
import { Tabs, TabPane, Table, TableColumn } from "element-ui"

export default {
    components: {
        SectorTags,
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    props: {
        model: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            sectors: [],
            currentSector: "",
            plagues: [],
            render: false
        }
    },
    computed: {
        anyToleranceExceeded() {
            let plaguesDetected = {
                text: "",
                class: ""
            }
            if (this.model["any_tolerance_exceeded?"] == true) {
                ;(plaguesDetected.text = "Si"),
                    (plaguesDetected.class = "danger")
            } else if (this.model["any_tolerance_exceeded?"] == false) {
                ;(plaguesDetected.text = "No"),
                    (plaguesDetected.class = "success")
            } else {
                ;(plaguesDetected.text = "?"),
                    (plaguesDetected.class = "default")
            }
            return plaguesDetected
        },
        getSectorDescription() {
            return ["scheduled", "reporting"].includes(
                this.model.workflow.state
            )
                ? "por monitorear"
                : "monitoreados"
        },
        dangerPlagues() {
            let vm = this
            let plagues = this.model.plague_monitoring_observations.reduce(
                (acc, i) => {
                    if (i.plague_detections != undefined) {
                        let values = []
                        i.plague_detections.forEach(p => {
                            if (p["is_tolerance_exceeded?"]) {
                                let schema = vm.$COPY.copy(i)
                                delete schema.plague_detections
                                values.push(Object.assign(schema, p))
                            }
                        })
                        acc = acc.concat(values)
                    }
                    return acc
                },
                []
            )
            return plagues
        }
    },
    created() {
        this.currentSector = this.model.show_sectors[0].$oid
        this.getSectors()
    },
    methods: {
        getSectors() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Sectors.getSectors(farm_id)
                .then(response => {
                    vm.sectors = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.getPlagues()
                })
        },
        getPlagues() {
            let settings = this.$store.getters["Settings/GET_SETTINGS"]
            this.plagues = settings.plaguesTracking.plagues
            this.render = true
        },
        plaguesDetectedFormatter(row, column, value) {
            return value ? "Si" : "No"
        },
        hasPlagueDetections(row) {
            return row.plagues_detected ? "expand" : ""
        },
        getPlagueName(plague_id) {
            let plague = this.plagues.find(i => i._id.$oid == plague_id)
            return (
                plague.value.commonName +
                "<br/>(<i>" +
                plague.value.scientificName +
                "</i>)"
            )
        },
        getSectorObservations(sector) {
            return this.model.workflow.state == "scheduled"
                ? []
                : this.model.plague_monitoring_observations.filter(
                      i => i.sector_id.$oid == sector
                  )
        }
    }
}
</script>

<style lang="sass" scoped>
  .plague-title
    margin-top: 30px
  .plague-subtitle
    margin-top: 15px
</style>
