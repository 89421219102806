const Mixin = {
    methods: {
        processMessages(data, type) {
            this.messages = []

            if (type == undefined) {
                type = "info"
            }

            if (Array.isArray(data) || data instanceof Object) {
                for (let i in data) {
                    if (Array.isArray(data[i])) {
                        for (let j in data[i]) {
                            let message = {
                                message: data[i][j],
                                type: type
                            }

                            this.messages.push(message)
                        }
                    } else {
                        let message = {
                            message: data[i],
                            type: type
                        }
                        this.messages.push(message)
                    }
                }
            } else {
                console.log("not array")
                let message = {
                    message: data,
                    type: type
                }
                this.messages.push(message)
            }
        }
    }
}

export default Mixin
