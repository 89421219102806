<template lang="html">
  <card>
    <div slot="header">
      <card-controls
        :back="false">
        <span slot="actions">
          <n-button
            type="primary"
            simple=""
            @click.native="components.new_worker = true">
            <i class="fas fa-plus-circle" />
            Nuevo trabajador
          </n-button>
        </span>
      </card-controls>

      <h4 class="card-title"><b>Trabajadores</b></h4>
    </div>

    <div class="row">
      <div class="col-md-10 offset-md-1">
        <worker-list
          ref="workerList"
          @editWorker="components.edit_worker = true"
          @setCurrentWorker="setCurrentWorker"/>
      </div>
    </div>

    <worker-new
      v-if="components.new_worker"
      @close="components.new_worker = false"
      @newWorker="$refs.workerList.getWorkers()"/>

    <worker-edit
      v-if="components.edit_worker"
      :worker="currentWorker"
      @close="components.edit_worker = false"
      @editWorker="$refs.workerList.getWorkers()"
      @workerDeleted="$refs.workerList.getWorkers()"/>


  </card>
</template>

<script>
import WorkerList from "./Worker/List"
import WorkerNew from "./Worker/New"
import WorkerEdit from "./Worker/Edit"

export default {
    components: {
        WorkerList,
        WorkerNew,
        WorkerEdit
    },
    data() {
        return {
            components: {
                new_worker: false,
                edit_worker: false,
                delete_worker: false
            },
            currentWorker: {}
        }
    },
    methods: {
        setCurrentWorker(worker) {
            this.currentWorker = worker
        }
    }
}
</script>

<style lang="css">
</style>
