import { ceres_api } from "@/axios-config.js"

const Products = {
    createProduct({ farm_id, payload }) {
        return ceres_api.post("/farms/" + farm_id + "/products", payload)
    },
    getProducts(farm_id) {
        return ceres_api.get("/farms/" + farm_id + "/products/")
    },
    getProduct({ farm_id, product_id }) {
        return ceres_api.get("/farms/" + farm_id + "/products/" + product_id)
    },
    updateProduct({ farm_id, product_id, payload }) {
        return ceres_api.put(
            "/farms/" + farm_id + "/products/" + product_id,
            payload
        )
    }
}

export default Products
