<template lang="">
  <div class="">
    <card>
      <div slot="header">
        <card-controls
          :back="true"
          :back-target="'/dashboard/tasklist'">
          <span slot="actions">
            <n-button
              type="primary"
              simple=""
              @click.native="$router.push({ name: 'new-product'})">
              <i class="fas fa-plus-circle" />
              Nuevo producto
            </n-button>
          </span>
        </card-controls>

        <h4 class="card-title"><b>{{ title }}</b></h4>
      </div>

      <tabs
        v-loading="loaders.products"
        type="primary"
        class="col-md-12 product-tabs">
        <template
          v-if="!loaders.products">
          <tab-pane
            v-for="(product_category,key) in product_categories"
            :key="key"
            :label="product_category.label">
            <span slot="label">
              {{ product_category.label }} <badge
                :type="'default'"
                :content="getTotalProductsByCategory(product_category.key).toString()"/>
            </span>
            <div class="col-md-12 col-sm-12 pull-center desktop-products">
              <table-products
                :category="product_category.key"
                :products="products"
                :applications="applications"/>
            </div>
            <div class="row mobile-product">
              <card-products
                :category="product_category.key"
                :products="products"/>
            </div>
          </tab-pane>
        </template>
      </tabs>
    </card>

  </div>
</template>

<script>
import TableProducts from "./Components/TableProducts";
import CardProducts from "./Components/CardProducts";
import { Tabs, TabPane } from "nowui/components";
import { mapGetters } from "vuex";

export default {
  components: {
    TableProducts,
    CardProducts,
    Tabs,
    TabPane,
  },
  data() {
    return {
      title: "Productos",
      loaders: {
        products: true,
      },
      warehouses: [],
      products: [],
      applications: [],
      farm: undefined,
      product_categories: [
        {
          key: "fertilizante",
          label: "Fertilizante",
        },
        {
          key: "fitosanitario",
          label: "Fitosanitario",
        },
        {
          key: "",
          label: "Sin categorizar",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ farmId: "Farms/GET_FARM_ID" }),
  },
  created() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      let vm = this;
      vm.loaders.products = true;

      this.Services.Products.getProducts(this.farmId)
        .then((response) => {
          vm.products = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.loaders.products = false;
        });
    },
    deleteProduct(product) {
      this.currentProduct = product;
      this.$refs.deleteProduct.show();
    },
    getTotalProductsByCategory(category) {
      let counter = 0;
      for (let index in this.products) {
        if (this.products[index].category == category) {
          counter++;
        }
      }

      return counter;
    },
  },
};
</script>

<style lang="sass">
.update-stock
  font-size: 10px
  font-weight: bold
  text-transform: uppercase
  margin-right: 10px
  display: inline-block
  float: right

  .big-btn
    text-align: center
    font-size: 15px
    width: 142px
    border-radius: 10px
    padding: 10px
    margin: 15cd px !important
    .btn-label
      margin-top: 15px
      font-size: 35px
      font-weight: 600
    .btn-help
      margin-top: 20px
      width: 100%
      display: inline-block
      float: left
      word-wrap: break-word
      word-break: break-all
    i
      margin: 40px 0px
      font-size: 60px
</style>
