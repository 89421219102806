<template lang="html">
  <div class="row">
    <h5 class="col-md-12 text-center">Configuración General</h5>
    <div class="col-md-8 offset-md-2">
      <div class="row setting-entry">
        <label class="col-sm-12 col-md-5 col-form-label">
          Logo de la empresa
        </label>
        <div class="col-sm-12 col-md-7">
          <el-upload
            v-loading="logoLoader"
            :show-file-list="false"
            :on-success="onLogoChange"
            :on-remove="onLogoRemove"
            :before-upload="beforeLogoUpload"
            class="picture-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
          >
            <img v-if="logoUrl" :src="logoUrl" class="logo" />
            <div v-else>
              <i class="el-icon-plus logo-uploader-icon" />
              <br />
              <label>Subir un archivo</label>
            </div>
          </el-upload>
        </div>
      </div>
      <div class="row setting-entry">
        <label class="col-md-5 col-form-label">Unidad de las dosis</label>
        <div class="col-md-7">
          <fg-input
            v-model="models.doses_unit.value"
            :error="errors.first('doses_unit')"
          >
            <el-select
              v-validate="{ required: true }"
              v-model="models.doses_unit.value"
              data-vv-as="Unidad de las dosis"
              placeholder="Unidad de las dosis"
              class="select-primary light-input"
              name="doses_unit"
            >
              <el-option
                v-for="(dose, key) in doses"
                :value="dose.value"
                :label="dose.label"
                :key="key"
                class="select-danger"
              />
            </el-select>
          </fg-input>
        </div>
      </div>
      <div class="row settings-entry">
        <label class="col-md-5">Especies</label>
        <div class="col-md-7 text-left">
          <el-tag
            v-for="(specie, index) in showSpecies"
            :key="specie.value"
            :disable-transitions="false"
            class="clean-tag me-2"
            closable
            @click.native="showEditInput(specie, index)"
            @close="confirmDeleteTag(specie, index)"
          >
            {{ specie.value }}
          </el-tag>
          <fg-input v-if="editInputVisible" :error="errors.first('editTag')">
            <el-input
              v-validate="{
                unique: models.species.map((i) => i.value.toLowerCase().trim()),
              }"
              ref="saveTagInput"
              v-model="inputValue.value"
              class="input-new-tag"
              name="editTag"
              data-vv-as="Especie"
              size="mini"
              @keyup.enter.native="handleInputConfirm"
              @keyup.escape.native="handleClose"
            />
          </fg-input>
          <fg-input v-if="inputVisible" :error="errors.first('newTag')">
            <el-input
              v-validate="{
                uniqueSpecie: models.species.map((i) =>
                  i.value.toLowerCase().trim()
                ),
              }"
              ref="saveTagInput"
              v-model="inputValue"
              name="newTag"
              data-vv-as="Especie"
              class="input-new-tag"
              size="small"
              @keyup.enter.native="handleInputConfirm('new')"
              @keyup.escape.native="handleClose"
            />
          </fg-input>
          <n-button
            v-if="!inputVisible && !editInputVisible"
            class="button-new-tag"
            simple=""
            size="sm"
            type="primary"
            @click.native="showInput"
          >
            <i class="fas fa-plus-circle" />
          </n-button>
        </div>
      </div>
      <div
        v-for="(specie, index) in models.species.filter(
          (i) => i.delete == undefined
        )"
        :key="index"
        class="row"
      >
        <label class="col-md-5">Variedades de {{ specie.value }}</label>
        <div class="col-md-7 text-left">
          <varieties
            v-if="render"
            :key="varietiesKey"
            :specie="specie"
            :data="models.varieties"
            @updateModel="updateVarietyModel"
          />
        </div>
      </div>

      <div class="row settings-actions">
        <div v-if="messages.length > 0" class="col-md-12 text-left">
          <notification
            v-for="(message, key) in messages"
            :key="key"
            :message="message.message"
            :type="message.type"
            :close="true"
          />
        </div>

        <div class="col-md-12 text-center">
          <n-button type="primary" @click.native="validate"
            >Guardar Cambios de la sección</n-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tag, Input, Button } from "element-ui";
import Swal from "sweetalert2";
import { Validator } from "vee-validate";
import Varieties from "./Components/Varieties";
export default {
  components: {
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    Varieties,
  },
  props: {
    settings: {
      type: Object,
      default: () => {
        return {};
      },
    },
    messages: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      render: false,
      inputVisible: false,
      inputValue: "",
      editInputVisible: false,
      editTagIndex: 0,
      varietiesKey: 1,
      models: {
        species: [],
        varieties: [],
        doses_unit: {
          value: "",
          category: "general",
          key: "doses_unit",
          type: "value",
        },
        logo: undefined,
      },
      doses: [
        {
          value: 100,
          label: "100 L",
        },
        {
          value: 200,
          label: "200 L",
        },
        {
          value: 1,
          label: "L",
        },
      ],
      logoUrl: false,
      newLogo: false,
      logoLoader: true,
    };
  },
  computed: {
    showSpecies() {
      return this.models.species.filter((i) => i.delete == undefined);
    },
  },
  created() {
    let vm = this;
    if (Object.keys(this.settings).length > 0) {
      if (this.settings.general != undefined) {
        Object.keys(this.settings.general).forEach((key) => {
          vm.models[key] = vm.$COPY.copy(this.settings.general[key]);
        });
      }
      if (this.settings.varieties != undefined) {
        Object.keys(this.settings.varieties).forEach((key) => {
          vm.settings.varieties[key].forEach((item) => {
            vm.models.varieties.push(item);
          });
        });
      }
    }
    this.getLogo();
    this.render = true;
  },
  mounted() {
    const isUniqueSpecie = {
      validate: (value, uniqueList) => {
        let isValid = uniqueList.includes(value.toLowerCase().trim())
          ? false
          : true;
        return {
          valid: isValid,
          data: {
            message: "La Especie debe ser única",
          },
        };
      },
      getMessage: (field, params, data) => {
        return data.message;
      },
    };
    Validator.extend("uniqueSpecie", isUniqueSpecie);
  },
  methods: {
    onLogoChange(res, file) {
      let vm = this;
      let reader = new FileReader();

      vm.logoLoader = true;
      this.logoUrl = URL.createObjectURL(file.raw);

      reader.onload = function (event) {
        let logo = {
          category: "general",
          value: event.target.result,
          key: "logo",
          type: "value",
        };

        if (vm.models.logo != undefined) {
          logo._id = vm.models.logo._id;
        }

        vm.models.logo = logo;

        vm.newLogo = true;
        vm.logoLoader = false;
      };
      reader.readAsDataURL(file.raw);
    },
    onLogoRemove() {
      this.models.logo = {};
    },
    beforeLogoUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      const isPNG = file.type === "image/png";

      return (isJPG || isPNG) && isLt2M;
    },
    getLogo() {
      let vm = this;
      let file_id;

      if (vm.models.logo != undefined) {
        file_id = vm.models.logo.value;
        this.Services.Files.getFile(file_id)
          .then((response) => {
            vm.logoUrl = vm.$HELPERS.contentToFile(response.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            vm.logoLoader = false;
          });
      } else {
        vm.logoLoader = false;
      }
    },
    confirmDeleteTag(specie, index) {
      let vm = this;
      Swal.fire({
        title: "¿Estás seguro eliminar esta especie?",
        text: "Se eliminarán los estados fenológicos y variedades asociadas",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No,<br/>Quiero conservarla",
        confirmButtonText: "Si,<br/>Quiero eliminarla",
        reverseButtons: true,
        focusCancel: true,
        cancelButtonClass: "primary",
        confirmButtonClass: "danger",
      }).then((result) => {
        if (result.value) vm.rmTag(specie, index);
      });
    },
    rmTag(tag, index) {
      if (tag._id != undefined) {
        let species = this.$COPY.copy(this.models.species);
        let currentSpecie = species.filter((i) => {
          return i.value == tag.value;
        })[0];
        currentSpecie.delete = true;
        this.models.species = this.$COPY.copy(species);

        this.cleanVarieties(currentSpecie);
      } else {
        this.models.species.splice(index, 1);
      }
    },
    cleanVarieties(specie) {
      let vm = this;
      this.models.varieties.forEach((item, index) => {
        if (item.key == specie._id.$oid)
          vm.models.varieties[index].delete = true;
      });
      this.varietiesKey += 1;
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    showEditInput(specie, index) {
      this.editTagIndex = index;
      this.inputValue = this.$COPY.copy(specie);
      this.editInputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm(action) {
      let vm = this;
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          action == "new" ? vm.saveTag() : vm.handleEditConfirm();
        }
      });
    },
    saveTag() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.models.species.push({
          global: true,
          value: inputValue,
          key: "species",
          category: "general",
          type: "array",
        });
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    handleClose() {
      this.inputVisible = false;
      this.editInputVisible = false;
      this.inputValue = "";
    },
    handleEdit(specie) {
      this.inputEditMode = true;
      this.inputValue = specie.value;
      this.showInput();
    },
    handleEditConfirm() {
      if (this.inputValue.value) {
        this.models.species.splice(this.editTagIndex, 1, this.inputValue);
      }
      this.editInputVisible = false;
      this.inputValue = "";
    },
    validate() {
      this.$emit("loader", true);
      let vm = this;
      this.$validator.validateAll().then((isValid) => {
        isValid ? vm.save() : vm.$emit("loader", false);
      });
    },
    save() {
      if (this.newLogo == false) {
        delete this.models.logo;
      }

      this.$emit("save", this.models);
    },
    updateVarietyModel(model, specie) {
      if (this.models.varieties.length > 0) {
        let currentModel = this.$COPY.copy(
          this.models.varieties.filter((i) => i.key != specie)
        );
        this.models.varieties = this.$COPY.copy(currentModel.concat(model));
      } else {
        this.models.varieties = this.$COPY.copy(model);
      }
    },
  },
};
</script>

<style lang="sass">
.setting-entry
  margin-bottom: 10px
  .logo-img
    width: 200px
    padding-top: 11px
    border: 1px solid #1858a7
    border-style: dashed
    padding: 20px
    border-radius: 10px
    text-align: center
    color: #c0c4cc
    cursor: pointer
    i
      font-size: 25px
  .logo
    padding: 10px
    width: 200px
    display: block
    margin: 0 auto
.clean-tag
  padding: 0 10px !important
  line-height: 30px !important
  margin-left: 0px !important
.button-new-tag
  margin-left: 10px !important
</style>
