import { mapState } from "vuex";

const SectorUtils = {
  computed: {
    ...mapState("Settings", ["settings"]),
  },
  methods: {
    getSectorFullName(sector) {
      const specieName = this.getSpecieName(sector.specie);
      const varietyName = this.getVarietyName(sector.specie, sector.variety);
      return `${sector.name} (${specieName} ${varietyName})`;
    },
    getSectorName(sector) {
      return sector.name;
    },
    getSpecieName(id) {
      let specie = this.settings.general.species.find(
        (i) => i._id.$oid.toString() == id
      );
      return specie != undefined ? specie.value : "";
    },
    getVarietyName(specieId, varietyId) {
      let varieties = this.settings.varieties[specieId];
      let variety = undefined;
      if (varieties != undefined) {
        variety = this.settings.varieties[specieId].find(
          (i) => i._id.$oid.toString() == varietyId
        );
      }
      return variety != undefined ? variety.value : "";
    },
  },
};

export default SectorUtils;
