<template lang="html">
  <div>
    <el-table
      :data="tasks"
      :empty-text="'No hay actividades pendientes'"
      class="table-striped products-table"
    >
      <el-table-column min-width="25">
        <div slot-scope="{ row }" class="text-left process-icon">
          <i :class="$CERES_VARS.getProcessIcon(row.workflow._type)" />
        </div>
      </el-table-column>

      <el-table-column
        v-if="taskFilter == 'own' && isSupervisor"
        label="Responsable"
        sortable
      >
        <div slot-scope="{ row }" class="text-left">
          {{
            $HELPERS.findAndgetUserFullName(row.workflow.assignee.$oid, users)
          }}
        </div>
      </el-table-column>

      <el-table-column label="Estado" sortable>
        <div slot-scope="{ row }">
          {{ $t(row.workflow.state) }}
        </div>
      </el-table-column>

      <el-table-column
        :filters="processKeyFilters"
        :filter-method="processKeyFilterHandler"
        prop="processName"
        label="Proceso"
        sortable
      >
        <div slot-scope="{ row }" class="text-left capitalize">
          {{ $t(row.workflow._type) }}
        </div>
      </el-table-column>

      <el-table-column
        :formatter="dateFomatter"
        prop="date"
        name="due"
        label="Vencimiento"
        align="center"
        sortable
      />

      <el-table-column
        label="Acciones"
        align="center"
        class-name="table-category"
      >
        <div slot-scope="{ row }" class="controls">
          <n-button type="primary" size="sm" @click.native="goToTask(row)">
            <i class="fal fa-eye" />
          </n-button>
          <n-button
            v-if="isSupervisor || allowSetAssignee"
            type="warning"
            size="sm"
            class="ms-1"
            @click.native="openSetAssignee(row)"
          >
            <i class="fal fa-user" />
          </n-button>
        </div>
      </el-table-column>
    </el-table>

    <set-assignee
      v-if="components.setAssignee"
      :users="users"
      :task-id="currentTaskId"
      :assignee="currentAssignee"
      :process-key="currentProcessKey"
      @updateTaskList="$emit('updateTaskList')"
      @close="components.setAssignee = false"
    />
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import { getProcessKey } from "../../../mixins";
import SetAssignee from "./SetAssignee";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    SetAssignee,
  },
  mixins: [getProcessKey],
  props: {
    tasks: {
      type: Array,
      default: () => {
        return [];
      },
    },
    taskFilter: {
      type: String,
      default: "own",
    },
    users: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isSupervisor: {
      type: Boolean,
      default: false,
    },
    allowSetAssignee: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      components: {
        setAssignee: false,
      },
      currentTaskId: undefined,
      currentAssignee: undefined,
      currentProcessKey: "",
    };
  },
  computed: {
    processKeyFilters() {
      let vm = this;
      let processKeys = this.tasks.map((i) => i.workflow._type);
      let processKeys_uniq = [...new Set(processKeys)];
      return processKeys_uniq.map((i) => {
        return { value: i, text: vm.$t(i) };
      });
    },
  },
  methods: {
    dateFomatter(row, column, cellValue) {
      if (cellValue !== null) {
        return this.$moment(cellValue).format("DD-MM-YYYY");
      } else {
        return "";
      }
    },
    getTaskName(row, column, cellValue) {
      return this.$t(row.workflow.state);
    },
    goToTask(task) {
      this.$router.push({
        name: "continue-process",
        params: {
          id: task._id.$oid,
          processKey: this.getProcessKey(task),
          state: task.workflow.state,
        },
      });
    },
    openSetAssignee(task) {
      this.currentTaskId = task._id.$oid;
      this.currentProcessKey = this.getProcessKey(task);
      this.currentAssignee = task.workflow.assignee.$oid;
      this.components.setAssignee = true;
    },
    processKeyFilterHandler(value, row, column) {
      return row.workflow._type == value;
    },
  },
};
</script>

<style lang="sass" scoped>
.process-icon
  font-size: 20px
  color: #1858a7
  .ceres-icon
    background-color: #1858a7
    width: 25px
    height: 25px
</style>
