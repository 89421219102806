<template lang="html">
  <card
    v-loading="loader">
    <template slot="header">
      <card-controls :back="true"/>

      <h4 class="card-title"><b>Historial de cambios de estado</b></h4>
    </template>

    <div class="row">
      <div
        v-if="!loader"
        class="row">
        <div class="offset-md-1 col-md-5 text-left">
          <h5 class="text-center">Metadatos</h5>
          <p><b>Creado</b>: {{ record.created_at | moment(momentFormat) }}</p>
          <p><b>Última actualización</b>: {{ record.updated_at | moment(momentFormat) }}</p>
          <p><b>Comenzando por</b>: {{ getUserName(record.workflow.starter.$oid) }}</p>
          <p><b>Estado actual</b>: {{ $t(record.workflow.state) }}</p>
          <p><b>Responsable actual</b>: {{ getUserName(record.workflow.assignee.$oid) }} </p>
        </div>

        <div class="col-md-5 text-left">
          <h5 class="text-center">Transiciones</h5>
          <el-timeline
            v-if="Object.keys(record).length > 0">
            <el-timeline-item
              v-for="(log, index) in cleanLog"
              :key="index"
              :type="isLast(index)"
              :timestamp="log.timestamp | moment('YYYY-MM-DD, h:mm:ss A')">

              <span
                v-if="log.event != 'update_task'">
                {{ getUserName(log.user.$oid) }} ha decidido <b>{{ $t(log.event).toLowerCase() }}</b> pasando del estado <b>{{ $t(log.from) }}</b> al estado <b>{{ $t(log.to) }}</b>
              </span>
              <span
                v-else>
                {{ getUserName(log.user.$oid) }} ha decidido <b>{{ $t(log.event).toLowerCase() }}</b> la actividad
              </span>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { Timeline, TimelineItem } from "element-ui"

export default {
    components: {
        [Timeline.name]: Timeline,
        [TimelineItem.name]: TimelineItem
    },
    props: {
        record: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            loader: true,
            users: [],
            momentFormat: "dddd DD MMMM [de] YYYY, h:mm:ss a"
        }
    },
    computed: {
        cleanLog() {
            return this.record.workflow.changelogs.reduce(
                (acc, i, index, arr) => {
                    if (acc.length == 0) {
                        acc.push(i)
                    }
                    if ((acc.length > 0) & (i.event != "start")) {
                        acc.push(i)
                    }
                    return acc
                },
                []
            )
        }
    },
    created() {
        if (Object.keys(this.record).length == 0) {
            this.$router.push({
                name: "view-" + this.$route.params.processKey,
                params: {
                    id: this.$route.params.id
                }
            })
        } else {
            this.getUsers()
        }
    },
    methods: {
        isLast(index) {
            return this.cleanLog.length == index + 1 ? "success" : "default"
        },
        getUsers() {
            let vm = this
            this.Services.Users.getUsers()
                .then(response => {
                    vm.users = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        getUserName(userId) {
            let user = this.users.find(i => i._id.$oid == userId)

            return user != undefined ? user.name + " " + user.lastname : "CERES"
        }
    }
}
</script>

<style lang="sass">
  .card-timeline .timeline > li > .timeline-panel
    box-shadow: 0px 0px 10px 2px rgba(204, 197, 185, 0.5)
</style>
