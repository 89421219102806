import { ceres_api } from "@/axios-config.js";
import Helpers from "@/utils/Helpers.js";

const Processes = {
  getProcesses(farm_id) {
    return ceres_api.get("farms/" + farm_id + "/workflows");
  },
  getProcessDefinition(farm_id, processKey) {
    return ceres_api.get("farms/" + farm_id + "/workflows/" + processKey);
  },
  getEvents(farm_id, processKey, id) {
    return ceres_api.get(
      "farms/" +
        farm_id +
        "/workflows/events/" +
        processKey.replace("-", "_") +
        "/" +
        id
    );
  },
  getVariables(farm_id, processKey, id) {
    return ceres_api.get(
      "farms/" +
        farm_id +
        "/workflows/variables/" +
        processKey.replace("-", "_") +
        "/" +
        id
    );
  },
  completeTask(farm_id, processKey, id, model, decision) {
    return ceres_api.put(
      "farms/" +
        farm_id +
        "/workflows/complete-task/" +
        processKey.replace("-", "_") +
        "/" +
        id +
        "/" +
        decision,
      model
    );
  },
  getRecords(farmId, processKey) {
    return ceres_api.get(
      `farms/${farmId}/workflows/records/${processKey.replace("-", "_")}`
    );
  },
  getTasks(farm_id, assignee) {
    let filter = "";
    if (assignee != undefined) {
      filter = "/?assignee=" + assignee;
    }
    return ceres_api.get("farms/" + farm_id + "/workflows/tasks" + filter);
  },
  setAssignee(farm_id, processKey, id, assignee) {
    let payload = [Helpers.stringToClassName(processKey), id, assignee];
    return ceres_api.put(
      "farms/" + farm_id + "/workflows/set-assignee/" + payload.join("/")
    );
  },
  bulkSetAssignee({ farm_id, assignee, new_assignee }) {
    let payload = [assignee, new_assignee];
    return ceres_api.put(
      "farms/" + farm_id + "/workflows/bulk-set-assignee/" + payload.join("/")
    );
  },
  processTimers() {
    return ceres_api.put("workflows/process-timers");
  },
  async getTaskByDateRange(farm_id, start_date, end_date) {
    const payload = {
      start_date,
      end_date,
    };
    const endpoint = `farms/${farm_id}/workflows/tasks-by-date-range/`;
    try {
      let response = await ceres_api.post(endpoint, payload);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
};

export default Processes;
