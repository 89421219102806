<template lang="html">
  <div>
    <div
      v-for="(product,key) in computedProducts"
      :key="key"
      class="col-md-12">
      <card>
        <!-- <div class="col-md-12 text-left"><b>Categoría:</b> {{ product.category }}</div> -->
        <div class="col-md-12 text-left"><b>Marca:</b> {{ product.brand }}</div>
        <div class="col-md-12 text-left"><b>Nombre Comercial:</b> {{ product.name }}</div>
        <div class="col-md-12 text-left"><b>Cantidad:</b> {{ computeQty(product) | convertToFloat }} {{ product.format }} de {{ product.units_per_format }} {{ product.measure_unit }}</div>
        <div class="col-md-12 text-left"><b>Stock:</b> {{ product.stock | convertToFloat }} {{ product.measure_unit }}</div>
        <div class="col-md-12 text-left"><b>Bodega: </b><span v-if="product.warehouse != undefined">{{ product.warehouse.name }}</span></div>

        <div class="col-md-12 text-center">


          <n-button
            class="col-md-12"
            type="primary"
            size="sm"
            @click.native="updateProduct(product)">
            <i class="now-ui-icons design-2_ruler-pencil"/>
            Editar
          </n-button>

          <n-button
            class="col-md-12"
            type="danger"
            size="sm"
            @click.native="delteProduct(product)">
            <i class="now-ui-icons ui-1_simple-remove"/>
            Eliminar
          </n-button>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        products: {
            type: Array,
            default: () => {
                return []
            }
        },
        category: {
            type: String,
            default: ""
        }
    },
    computed: {
        computedProducts() {
            let filteredProducts = []
            for (let index in this.products) {
                if (this.products[index].category == this.category) {
                    filteredProducts.push(this.products[index])
                }
            }
            return filteredProducts
        }
    },
    methods: {
        computeQty(product) {
            return product.stock / product.units_per_format
        },
        updateProduct(product) {
            this.$emit("updateProduct", product)
        },
        deleteProduct(product) {
            this.$emit("deleteProduct", product)
        }
    }
}
</script>

<style lang="css">
</style>
