<template lang="html">
  <div>
    <div class="col-md-12 d-flex flex-row align-items-center mb-3">
      <div class="d-flex flex-row align-items-center">
        <div v-if="!compareMode">
          <n-button
            simple
            size="sm"
            type="primary"
            class="me-2"
            @click.native="handleGoToday"
          >
            Hoy
          </n-button>
          <n-button
            simple
            size="sm"
            type="primary"
            @click.native="handlePrevMonth"
          >
            <i class="fal fa-chevron-left" />
          </n-button>
          <n-button
            simple
            size="sm"
            type="primary"
            class="me-2"
            @click.native="handleNextMonth"
          >
            <i class="fal fa-chevron-right" />
          </n-button>
        </div>

        <div class="ms-3">
          {{ currentDate | moment("MMMM, YYYY") | capitalize }}
        </div>
      </div>
      <div class="d-flex justify-content-center ms-auto">
        <fg-input class="filter-container">
          <el-select
            v-model="selectedCalendars"
            placeholder="Filtrar"
            class="select-primary"
            name="calendars-filter"
            multiple
            collapse-tags
            clearable
            filterable
          >
            <el-option
              v-for="calendar in calendars"
              :value="calendar.id"
              :label="calendar.name"
              :key="calendar.id"
              class="select-danger"
            />
          </el-select>
        </fg-input>
      </div>
    </div>

    <calendar
      v-loading="loader"
      ref="calendar"
      :calendars="calendars"
      :schedules="calendarEventsFiltered"
      :is-read-only="true"
      :month="calendarOptions.monthConfig"
      :template="calendarOptions.template"
      :use-detail-popup="true"
      :usage-statistics="false"
      class="col-md-12"
      style="height: 800px"
      view="month"
    />
  </div>
</template>

<script>
import { Tooltip } from "element-ui";
import "tui-calendar/dist/tui-calendar.css";
import { Calendar } from "@toast-ui/vue-calendar";
import { getFarmId } from "@/mixins";
import { getProcessKey, ProductApplicationUtils } from "../../../mixins";
import ProcessesService from "../../../services/Processes";

export default {
  components: {
    [Tooltip.name]: Tooltip,
    calendar: Calendar,
  },
  mixins: [getFarmId, getProcessKey, ProductApplicationUtils],
  props: {
    compareMode: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loader: true,
      calendarOptions: {
        monthConfig: {
          daynames: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
          startDayOfWeek: 1,
        },
        template: {
          allday: this.eventCard,
          popupDetailBody: this.composePopupDetailBody,
        },
      },
      currentDate: undefined,
      monthChangeAction: undefined,
      fetchedMonths: [],
      calendars: [],
      calendarEvents: [],
      selectedCalendars: [],
      currentTask: [],
      tasks: [],
    };
  },
  computed: {
    calendarEventsFiltered() {
      if (this.selectedCalendars.length == 0) {
        return this.calendarEvents;
      } else {
        return this.calendarEvents.filter((i) =>
          this.selectedCalendars.includes(i.raw.workflow._type)
        );
      }
    },
  },
  watch: {
    currentDate: async function (newValue, oldValue) {
      this.$emit("update:date", newValue.toISOString());

      let monthToRequest = newValue.clone();
      if (this.monthChangeAction === "next") {
        monthToRequest.add(2, "M");
      }

      if (this.monthChangeAction === "prev") {
        monthToRequest.subtract(2, "M");
      }

      if (
        (this.fetchedMonths.length > 0) &
        !this.fetchedMonths.some((i) => i.isSame(monthToRequest, "month"))
      ) {
        await this.prepareNewEvents(monthToRequest);
      }
    },
    date: function (newValue, oldValue) {
      if (this.compareMode) {
        this.currentDate = this.$moment(newValue).subtract(1, "year");
        this.$refs.calendar.invoke(
          "setDate",
          this.currentDate.format("YYYY-MM-DD")
        );
      }
    },
  },
  async created() {
    this.currentDate = this.$moment().startOf("month");
    if (this.compareMode) {
      this.currentDate = this.$moment(this.date).startOf("month");
      this.currentDate = this.currentDate.subtract(1, "year");
    }

    // Init calendar events
    const startDate = this.currentDate.clone().subtract(1, "month");
    const endDate = this.currentDate.clone().add(1, "month").endOf("month");

    const calendarData = await this.getTaskByDateRange(startDate, endDate);
    this.calendars = calendarData.calendars;
    this.calendarEvents = calendarData.events;
    this.fetchedMonths = calendarData.months;
    this.loader = false;
  },
  mounted() {
    if (this.compareMode) {
      this.$refs.calendar.invoke(
        "setDate",
        this.currentDate.format("YYYY-MM-DD")
      );
    }
  },
  methods: {
    composeCalendarData(response) {
      const calendars = response.data.reduce((acc, item) => {
        let calendar = {
          id: item.workflow._type,
          name: this.$t(item.workflow._type),
        };
        if (acc.length == 0) {
          acc.push(calendar);
        } else {
          if (acc.every((i) => i.id != item.workflow._type)) {
            acc.push(calendar);
          }
        }
        return acc;
      }, []);

      const events = response.data.map((i) => {
        const date = this.$moment(i.date);
        return {
          id: i._id.$oid,
          title: this.$t(i.workflow._type + "_calendar"),
          category: "allday",
          start: date.toISOString(),
          end: date.add(1, "h").toISOString(),
          body: "test",
          raw: i,
        };
      });

      const months = response.data.reduce((acc, item) => {
        const current = this.$moment(item.date).startOf("month");
        if (!acc.some((i) => i.isSame(current, "month"))) {
          acc.push(current);
        }
        return acc;
      }, []);

      return { calendars: calendars, events: events, months: months };
    },
    async getTaskByDateRange(startDate, endDate) {
      try {
        const response = await this.Services.Processes.getTaskByDateRange(
          this.farm_id,
          startDate,
          endDate
        );
        const result = this.composeCalendarData(response);
        return result;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async prepareNewEvents(date) {
      try {
        const startDate = date.clone();
        const endDate = date.endOf("month");
        const calendarData = await this.getTaskByDateRange(startDate, endDate);

        if (calendarData.months.length === 1) {
          this.fetchedMonths.push(calendarData.months[0]);
        }

        const currentCalendarIds = this.calendars.map((i) => i.id);
        for (let calendar of calendarData.calendars) {
          if (!currentCalendarIds.includes(calendar.id)) {
            this.calendars.push(calendar);
          }
        }
        this.calendarEvents = [...this.calendarEvents, ...calendarData.events];
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    eventCard(schedule) {
      const iconClass = this.$CERES_VARS.getProcessIcon(
        schedule.raw.workflow._type
      );
      return `<div class="${iconClass} event-icon"><span class="ms-1 tui-event-title">${schedule.title}</span></div>`;
    },
    handleNextMonth() {
      this.monthChangeAction = "next";
      let current = this.currentDate.clone();
      current.add(1, "M");
      this.currentDate = current.clone();
      this.$refs.calendar.invoke("next");
    },
    handlePrevMonth() {
      this.monthChangeAction = "prev";
      let current = this.currentDate.clone();
      current.subtract(1, "M");
      this.currentDate = current.clone();
      this.$refs.calendar.invoke("prev");
    },
    handleGoToday() {
      this.currentDate = this.$moment().startOf("month");
      this.$refs.calendar.invoke("today");
    },
    composePopupDetailBody(i) {
      const state = this.$t(i.raw.workflow.state);
      const name = this.$store.getters["Users/getUserFullName"](
        i.raw.workflow.assignee.$oid
      );
      const taskId = i.raw._id.$oid;
      const processKey = this.getProcessKey(i.raw);
      const task = this.tasks.find((task) => task._id.$oid == taskId);
      const workflowsWithDetails = [
        "ProductApplicationWorkflow",
        "PurchaseWorkflow",
        "FarmHarvestWorkflow",
      ];
      const hasDetails = workflowsWithDetails.includes(i.raw.workflow._type);
      if (hasDetails) {
        if (task) {
          this.currentTask = task;
          this.buildPopUpDetails(i.raw.workflow._type);
        } else {
          ProcessesService.getVariables(this.farm_id, processKey, taskId).then(
            (r) => {
              this.currentTask = r.data;
              this.tasks.push(r.data);
              this.buildPopUpDetails(i.raw.workflow._type);
            }
          );
        }
      }

      const details = '<div id="calendar-pop-details">...</div>';
      const base = `<span>Estado: ${state} <br/>Responsable: ${name}</span>`;
      return `${base} ${hasDetails ? "<br/>" + details : ""}`;
    },
    setCalendarBodyDetails(html) {
      this.$nextTick(() => {
        const el = document.getElementById("calendar-pop-details");
        el.innerHTML = html;
      });
    },
    buildPopUpDetails(workflowKey) {
      switch (workflowKey) {
        case "ProductApplicationWorkflow":
          this.productApplicationSummary();
          break;
        case "PurchaseWorkflow":
          this.purchaseSummary();
          break;
        case "FarmHarvestWorkflow":
          this.farmHarvestSummary();
          break;
      }
    },
    productApplicationSummary() {
      const html = [];
      html.push(
        `<span>Sectores: ${this.currentTask.sectors
          .map((i) => i.name)
          .join(", ")}</span>`
      );
      for (const p of this.currentTask.used_products) {
        const line = `<span>${p.product.name}: ${this.dose(p)}</span>`;
        html.push(line);
      }
      html.push(
        `<span>Comentarios: ${this.currentTask.comments || "-"}</span>`
      );
      this.setCalendarBodyDetails(html.join("<br/>"));
    },
    purchaseSummary() {
      const html = [];
      for (const item of this.currentTask.purchase_items) {
        const line = `${item.product.name}: ${item.containers} ${item.format}`;
        html.push(line);
      }
      this.setCalendarBodyDetails(html.join("<br/>"));
    },
    farmHarvestSummary() {
      const html = [];
      for (const item of this.currentTask.sector_harvests) {
        const line = `${item.sector.name}: ${item.qty} kg`;
        html.push(line);
      }
      this.setCalendarBodyDetails(html.join("<br/>"));
    },
  },
};
</script>

<style lang="sass">
@import "@/assets/sass/variables.sass"

.filter-container
  min-width: 500px
.tui-full-calendar-weekday-schedule-title
  text-align: left
.tui-full-calendar-weekday-schedule
  background-color: $primary-color-semi-transparent !important
  border-color: $primary-color !important
.tui-event-title
  font-family: $primary-font
  font-weight: normal
.tui-full-calendar-popup-top-line
  background-color: $primary-color !important
#calendar-pop-details
  margin-top: 8px
  padding-top: 8px
  border-top: 1px solid $medium-gray
</style>
