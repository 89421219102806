<template lang="html">
  <card class="col-md-12">
    <template slot="header">
      <card-controls
        :back="true"
        back-target-name="reports"/>

      <h4 class="card-title"><b>Boletines meteorológicos CEAZEMET</b></h4>
    </template>
    <div class="row">
      <div class="col-md-12">
        <el-tabs v-model="activeTab">
          <el-tab-pane
            label="Introducción"
            name="introduction">
            <embed
              :src="'https://ceres-ceazemet.s3.amazonaws.com/01.introduccion.pdf?t='+cacheTimeStamp"
              type="application/pdf"
              width="100%"
              height="1200px">
          </el-tab-pane>
          <el-tab-pane
            label="Resumen"
            name="summary">
            <embed
              :src="'https://ceres-ceazemet.s3.amazonaws.com/02.resumen.pdf?t='+cacheTimeStamp"
              type="application/pdf"
              width="100%"
              height="1200px">
          </el-tab-pane>
          <el-tab-pane
            label="ENOS"
            name="enos">
            <embed
              :src="'https://ceres-ceazemet.s3.amazonaws.com/03.enso.pdf?t='+cacheTimeStamp"
              type="application/pdf"
              width="100%"
              height="1200px">
          </el-tab-pane>
          <el-tab-pane
            label="Climático"
            name="climate">
            <embed
              :src="'https://ceres-ceazemet.s3.amazonaws.com/04.diag_climatico.pdf?t='+cacheTimeStamp"
              type="application/pdf"
              width="100%"
              height="1200px">
          </el-tab-pane>
          <el-tab-pane
            label="Hidrológico"
            name="hydro">
            <embed
              :src="'https://ceres-ceazemet.s3.amazonaws.com/05.diag_caudales.pdf?t='+cacheTimeStamp"
              type="application/pdf"
              width="100%"
              height="1200px">
          </el-tab-pane>
          <el-tab-pane
            label="Agroclimático"
            name="agro">
            <embed
              :src="'https://ceres-ceazemet.s3.amazonaws.com/06.diag_agro.pdf?t='+cacheTimeStamp"
              type="application/pdf"
              width="100%"
              height="1200px">
          </el-tab-pane>
          <el-tab-pane
            label="Conclusiones"
            name="conclusions">
            <embed
              :src="'https://ceres-ceazemet.s3.amazonaws.com/07.conclusiones.pdf?t='+cacheTimeStamp"
              type="application/pdf"
              width="100%"
              height="1200px">
          </el-tab-pane>
          <el-tab-pane
            label="Acerca de"
            name="about">
            <embed
              :src="'https://ceres-ceazemet.s3.amazonaws.com/08.glosario.pdf?t='+cacheTimeStamp"
              type="application/pdf"
              width="100%"
              height="1200px">
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-muted">
        Datos provistos por CEAZA, obtenidos desde www.ceazamet.cl, {{ $moment().format("Y") }}
      </div>
    </div>


  </card>
</template>

<script>
import { Tabs, TabPane } from "element-ui"
export default {
    components: {
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane
    },
    data() {
        return {
            activeTab: "introduction"
        }
    },
    computed: {
        cacheTimeStamp() {
            return this.$moment().format("YMD")
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
