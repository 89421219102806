<template lang="html">
  <collapse-transition>
    <div class="global-filter">
      <div class="row">
        <div class="col-md-12 text-left">
          <h6>Filtro Global</h6>
        </div>
        <div class="col-md-4 text-left">
          <div class="form-group">
            <fg-input
              label="Laboratorio">
              <el-select
                v-model="model.lab_id.$in"
                name="laboratories"
                multiple=""
                class="select-primary"
                placeholder="Laboratorio"
                filterable>
                <el-option
                  v-for="lab in laboratories"
                  :value="lab._id.$oid"
                  :label="lab.value.name"
                  :key="lab._id.$oid"
                  class="select-danger"/>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="col-md-4 text-left">
          <div class="form-group">
            <fg-input
              label="Categoría">
              <el-select
                v-model="model.category.$in"
                name="category"
                multiple=""
                class="select-primary"
                placeholder="Categoría"
                filterable>
                <el-option
                  v-for="category in categories"
                  :value="category"
                  :label="category"
                  :key="category"
                  class="select-danger"/>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="col-md-4 text-left">
          <fg-input
            :error="errors.first('date')"
            class="has-label"
          >
            <label>Rango de fechas <span>*</span></label>

          </fg-input>
          <el-date-picker
            v-model="model.deliver_date.dateRange"
            :picker-options="dateRangeOptions"
            type="daterange"
            placeholder="Seleccionar fecha de entrega"
            name="date"
            data-vv-as="Fecha de entrega del análisis"
            unlink-panels
            format="dd-MM-yyyy"
            start-placeholder="Inicio"
            range-separator="-"
            end-placeholder="Término"/>
        </div>
      </div>
      <div class="row controls">
        <div class="col-md-12 text-center">
          <n-button
            type="danger"
            size="sm"
            @click.native="handleCloseFilter()">
            <i class="fal fa-times-circle" />
            Cerrar
          </n-button>
          <n-button
            type="warning"
            size="sm"
            @click.native="clearFilter()">
            <i class="fal fa-trash" />
            Limpiar
          </n-button>
          <n-button
            type="success"
            size="sm"
            @click.native="applyFilter()">
            <i class="fal fa-check-circle" />
            Aplicar
          </n-button>
        </div>
      </div>
    </div>
  </collapse-transition>
</template>

<script>
import { CollapseTransition } from "vue2-transitions"

import { getFarmId } from "@/mixins/"

export default {
    components: {
        CollapseTransition
    },
    mixins: [getFarmId],
    props: {
        records: {
            type: Array,
            default: () => {
                return []
            }
        },
        sectors: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            categories: [],
            schema: {
                lab_id: {
                    $in: []
                },
                category: {
                    $in: []
                },
                deliver_date: {
                    dateRange: []
                }
            },
            model: {
                lab_id: {
                    $in: []
                },
                category: {
                    $in: []
                },
                deliver_date: {
                    dateRange: []
                }
            },
            dateRangeOptions: {
                disabledDate: this.setDates,
                shortcuts: [
                    {
                        text: "Semana pasada",
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7
                            )
                            picker.$emit("pick", [start, end])
                        }
                    },
                    {
                        text: "Mes pasado",
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30
                            )
                            picker.$emit("pick", [start, end])
                        }
                    },
                    {
                        text: "Últimos 3 meses",
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 90
                            )
                            picker.$emit("pick", [start, end])
                        }
                    },
                    {
                        text: "Temporada actual",
                        onClick: this.getCurrentSeason
                    },
                    {
                        text: "Temporada pasada",
                        onClick: this.getLastSeason
                    }
                ]
            }
        }
    },
    computed: {
        getMinMaxDates() {
            return this.$HELPERS.getMinMaxDates(this.records, "date")
        },
        uniqueLabs() {
            return this.records
                .map(i => i.general.lab)
                .reduce((unique, lab) => {
                    if (unique.indexOf(lab) < 0) unique.push(lab)
                    return unique
                }, [])
        },
        laboratories() {
            return this.$store.getters["Settings/GET_CONTACTS_BY_TYPE"](
                "laboratory"
            )
        }
    },
    created() {
        this.getCategories()
    },
    methods: {
        async getCategories() {
            try {
                const response = await this.Services.LabReports.getCategories(
                    this.farm_id
                )
                this.categories = [...response.data]
            } catch (error) {
                console.log(error)
            }
        },
        setDates(time) {
            if (
                time.getTime() > this.getMinMaxDates.max ||
                time.getTime() < this.getMinMaxDates.min
            ) {
                return true
            }
        },
        getCurrentSeason(picker) {
            const now = new Date()
            let season = this.$HELPERS.getSeasonDates(now, "current")
            picker.$emit("pick", [season.start, season.end])
        },
        getLastSeason(picker) {
            const now = new Date()
            let season = this.$HELPERS.getSeasonDates(now, "last")
            picker.$emit("pick", [season.start, season.end])
        },
        clearFilter() {
            this.model = this.$COPY.copy(this.schema)
            this.$emit("applyFilter")
        },
        handleCloseFilter() {
            this.clearFilter()
            this.$emit("close")
        },
        applyFilter() {
            let query = this.$COPY.copy(this.model)
            query = this.$HELPERS.composeMongoQuery(query)
            this.$emit("applyFilter", query)
        }
    }
}
</script>

<style lang="css" scoped>
</style>
