<template lang="html">
  <div v-loading="loader">
    <div
      v-if="!loader"
      class="row">
      <div class="col-md-12 text-left">
        <slot name="atTheBeginning"/>
      </div>

      <div
        v-if="renderCostVariable"
        class="col-md-12 text-left">
        <fg-input
          :error="errors.first('cost_variable')"
          label="Variable de costeo"
          required>
          <el-select
            v-validate="{required: true}"
            v-if="!loader"
            v-model="model.calculator.cost_variable"
            data-vv-as="Variable de costeo"
            placeholder="Seleccione la variable"
            class="select-primary"
            name="sector"
            filterable>
            <el-option
              v-for="key in Object.keys(cost_variables)"
              :key="key"
              :value="key"
              :label="cost_variables[key].name"
              class="select-danger"/>

          </el-select>
        </fg-input>
      </div>

      <div
        class="col-md-12 text-left">
        <div class="form-group has-label">
          <div class="row">
            <div class="col-md-4">
              <label>Cuartel(es) <span>*</span></label>
            </div>
            <div class="col-md-8 text-right">
              <label>
                <a
                  class="show-pointer"
                  @click="selectAllSectors()">Seleccionar todos</a> | <a
                    class="show-pointer"
                    @click="clearSectorSelection()">Limpiar selección</a>
              </label>
            </div>
          </div>
        </div>
        <fg-input
          :error="errors.first('sector')"
          required>
          <el-select
            v-validate="{required: true}"
            v-if="!loader"
            v-model="model.calculator.sector_ids"
            :multiple="true"
            data-vv-as="Cuartel(es)"
            placeholder="Seleccione el cuartel"
            class="select-primary"
            name="sector"
            filterable>
            <el-option
              v-for="sector in sectorList"
              :key="sector._id.$oid"
              :value="sector._id.$oid"
              :label="sector.name"
              class="select-danger"/>
          </el-select>
        </fg-input>
      </div>

      <div class="col-md-12 text-left">
        <fg-input
          v-validate="'required|max_value:' + maxWorkCoverage + getWorkCoverageValidation"
          v-model.number="model.calculator.work_coverage"
          :error="errors.first('work_coverage')"
          :label="work_coverageLabel"
          :data-vv-as="work_coverageLabel"
          :step="getWorkCoverageSteps"
          :placeholder="work_coverageLabel"
          name="work_coverage"
          type="number"
          required/>
      </div>

      <div class="col-md-12 text-left mb-3">
        <n-progress
          :value="sectorCalculator.percentage"
          :type="sectorCalculator.status"
          label="Cubrimiento de los cuarteles seleccionados"
          name="progress"
          show-value=""/>
      </div>

      <div
        v-if="showCostPerUnit"
        class="col-md-12 text-left mt-1">
        <fg-input
          v-validate="'required|min_value:0'"
          v-model.number="model.calculator.cost_per_unit"
          :error="errors.first('cost_per_unit')"
          :label="getCostPerUnitLabel"
          :data-vv-as="getCostPerUnitLabel"
          :placeholder="getCostPerUnitLabel"
          name="cost_per_unit"
          type="number"
          required/>
      </div>

      <div class="col-md-12 text-left">
        <slot name="beforeTotalCost"/>
      </div>

      <div class="col-md-12 mb-3 mt-3">
        <div class="row">
          <div class="col-md-4 text-left">
            <label>Subtotal</label>
            <el-alert
              :title="subTotal | toLocaleStringNumber('currency')"
              :closable="false"
              name="subTotal"
              type="warning"
              center/>
          </div>
          <div class="col-md-4 text-left">
            <label>Impuestos (IVA)</label>
            <el-alert
              :title="subTotal * 0.19 | toLocaleStringNumber('currency')"
              :closable="false"
              name="taxes"
              type="warning"
              center/>
          </div>
          <div class="col-md-4 text-left">
            <label>Total</label>
            <el-alert
              :title="subTotal * 1.19 | toLocaleStringNumber('currency')"
              :closable="false"
              name="total"
              type="warning"
              center/>
          </div>
        </div>
      </div>


      <div class="col-md-12 text-left">
        <slot name="afterTotalCost"/>
      </div>

      <div class="col-md-12 text-center">
        <slot name="actions"/>
      </div>

      <div class="col-md-12">
        <notification
          :messages="messages"
          :close="true"
          type="error"/>
      </div>

    </div>
  </div>
</template>

<script>
import { Alert, Switch } from "element-ui"
import { Progress } from "nowui/components"

export default {
    components: {
        [Progress.name]: Progress,
        [Alert.name]: Alert,
        [Switch.name]: Switch
    },
    props: {
        defaultCostVariable: {
            type: String,
            default: ""
        },
        initModel: {
            type: Object,
            default: () => {
                return {}
            }
        },
        sectors: {
            type: Array,
            default: () => {
                return []
            }
        },
        showCostPerUnit: {
            type: Boolean,
            default: true
        },
        totalValue: {
            type: Number,
            default: -1
        },
        messages: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            loader: true,
            renderCostVariable: true,
            sectorList: [],
            autoSetWorkCoverage: false,
            maxWorkCoverage: undefined,
            work_coverageInput: {
                integer: {
                    validation: "|min_value:1|decimal:false",
                    step: 1
                },
                decimal: {
                    validation: "|min_value:1",
                    step: 0.1
                }
            },
            cost_variables: {
                num_of_plants: {
                    name: "Número de plantas",
                    unit: "planta",
                    type: "integer"
                },
                area: {
                    name: "Superficie (ha)",
                    unit: "hectárea",
                    type: "decimal"
                }
            },
            model: {
                calculator: {
                    cost_variable: "num_of_plants",
                    sector_ids: [],
                    work_coverage: undefined,
                    cost_per_unit: undefined
                }
            }
        }
    },
    computed: {
        sectorCalculator() {
            let output = {
                percentage: 0,
                status: "success"
            }
            if (
                this.maxWorkCoverage != undefined &&
                this.maxWorkCoverage != 0
            ) {
                let percentage =
                    (this.model.calculator.work_coverage /
                        this.maxWorkCoverage) *
                    100
                if (percentage > 100) {
                    output.percentage = 100
                    output.status = "danger"
                } else {
                    output.percentage = percentage.toFixed(2) * 1.0
                    output.status = "success"
                }
            }
            return output
        },
        work_coverageLabel() {
            return this.cost_variables[this.model.calculator.cost_variable].name
        },
        subTotal() {
            if (this.totalValue != -1) {
                return this.totalValue
            } else {
                let vars = [
                    this.model.calculator.work_coverage,
                    this.model.calculator.cost_per_unit
                ]
                if (vars.every(i => i != undefined)) {
                    return (
                        this.model.calculator.work_coverage *
                        this.model.calculator.cost_per_unit
                    )
                } else {
                    return 0
                }
            }
        },
        getWorkCoverageValidation() {
            return this.work_coverageInput[
                this.cost_variables[this.model.calculator.cost_variable].type
            ].validation
        },
        getWorkCoverageSteps() {
            return this.work_coverageInput[
                this.cost_variables[this.model.calculator.cost_variable].type
            ].step
        },
        getCostPerUnitLabel() {
            return (
                "Costo por " +
                this.cost_variables[this.model.calculator.cost_variable].unit +
                " (CLP)"
            )
        }
    },
    watch: {
        "model.calculator": {
            handler: function() {
                this.$emit("modelUpdated", this.model)
            },
            deep: true
        },
        "model.calculator.sector_ids": function(newValue, oldValue) {
            let vm = this
            let total = 0
            newValue.forEach(item => {
                let sector = vm.sectorList.find(s => s._id.$oid == item)
                total += sector[vm.model.calculator.cost_variable]
            })
            if (this.autoSetWorkCoverage) {
                this.model.calculator.work_coverage = total
            }
            this.maxWorkCoverage = total
        },
        "model.calculator.cost_variable": function(newValue, oldValue) {
            if (newValue != undefined && newValue != oldValue) {
                this.model.calculator = {
                    cost_variable: newValue,
                    sector_ids: [],
                    work_coverage: undefined,
                    cost_per_unit: undefined
                }
            }
        }
    },
    created() {
        if (this.sectors.length == 0) {
            this.getSectors()
        } else {
            this.sectorList = this.$COPY.copy(this.sectors)
            this.passModel()
        }
        if (this.defaultCostVariable != "") {
            this.model.calculator.cost_variable = this.defaultCostVariable
            this.renderCostVariable = false
        }
    },
    updated() {
        this.autoSetWorkCoverage = true
    },
    methods: {
        getSectors() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Sectors.getSectors(farm_id)
                .then(response => {
                    vm.sectorList = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.passModel()
                })
        },
        selectAllSectors() {
            this.model.calculator.sector_ids = this.sectorList.map(
                i => i._id.$oid
            )
        },
        clearSectorSelection() {
            this.model.calculator.sector_ids = []
        },
        passModel() {
            if (Object.keys(this.initModel).length > 0) {
                let vm = this
                Object.keys(this.model.calculator).forEach(key => {
                    if (key in vm.initModel)
                        vm.model.calculator[key] = vm.initModel[key]
                })
            }
            this.loader = false
            this.$emit("render", true)
        }
    }
}
</script>

<style lang="sass" scoped>
  .show-pointer
    cursor: pointer
</style>
