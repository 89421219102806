<template lang="html">
  <card v-loading="loader">
    <div slot="header">
      <h4 class="card-title"><b>Modificar Contraseña</b></h4>
    </div>

    <div class="row">
      <div class="offset-md-4 col-md-4 col-sm-4 text-left">
        <fg-input
          v-validate="'required'"
          ref="new_password"
          v-model="model.new_password"
          :error="errors.first('new_password')"
          data-vv-as="Nueva Contraseña"
          name="new_password"
          type="password"
          label="Nueva Contraseña"
        />
      </div>
    </div>

    <div class="row">
      <div class="offset-md-4 col-md-4 col-sm-4 text-left">
        <fg-input
          v-validate="'required|confirmed:new_password'"
          ref="password_confirmation"
          v-model="model.password_confirmation"
          :error="errors.first('password_confirmation')"
          data-vv-as="Confirmar Nueva Contraseña"
          name="password_confirmation"
          type="password"
          label="Confirmar Nueva Contraseña"
        />
      </div>
    </div>

    <div class="row action-footer">
      <hr />
      <div class="col-md-12 text-center">
        <n-button
          class="me-3"
          type="primary"
          @click.native="$router.push({ name: 'user-profile' })"
        >
          Modificar Perfil
        </n-button>
        <n-button type="primary" @click.native="validate">
          <i class="fas fa-save me-1" /> Guardar cambios
        </n-button>
      </div>

      <div
        v-if="messages.length > 0"
        class="offset-md-4 col-md-4 col-sm-4 text-left"
      >
        <notification
          v-for="(message, key) in messages"
          :key="key"
          :message="message.message"
          :type="message.type"
          :close="true"
        />
      </div>
    </div>
  </card>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      model: {
        user: {},
        new_password: "",
        new_password_confirmation: "",
      },
      loader: true,
      messages: [],
    };
  },
  created() {
    let vm = this;
    let user = this.$COPY.copy(this.$store.getters["Auth/GET_USER"]);

    this.Services.Users.getUser(user._id.$oid)
      .then((response) => {
        this.model.user = response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        vm.loader = false;
      });
  },
  methods: {
    validate() {
      let vm = this;
      this.$validator.validateAll().then((isValid) => {
        if (isValid) vm.changePassword();
      });
    },
    changePassword() {
      let vm = this;
      let payload = {
        password: this.model.new_password,
        password_confirmation: this.model.password_confirmation,
      };
      let id = this.model.user._id.$oid.toString();
      this.loader = true;
      this.messages = [];

      this.Services.Users.updatePassword({ id, payload })
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
            confirmButtonText: "Volver a mi perfil",
          }).then(() => {
            vm.$router.push({ name: "user-profile" });
          });
        })
        .catch((error) => {
          vm.processMessages(error.response.data.errors, "danger");
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
};
</script>

<style lang="css" scoped></style>
