<template lang="html">
  <div>
    <div class="product row">
      <div class="offset-md-1 col-md-2 col-sm-12 text-left">
        <div class="row">
          <div class="col-md-12">
            <label class="">Imagen del Producto</label>
          </div>
          <div class="col-md-12">
            <el-upload
              v-loading="loaders.uploading"
              v-if="!viewOnly"
              :show-file-list="false"
              :http-request="() => {}"
              :on-change="onPictureChange"
              :before-upload="beforePictureUpload"
              :on-error="onPictureError"
              class="picture-uploader"
              action=""
            >
              <img v-if="pictureUrl" :src="pictureUrl" class="picture" />
              <div v-else>
                <i class="el-icon-plus logo-uploader-icon" />
                <br />
                <label>Subir una archivo</label>
              </div>
            </el-upload>
            <div v-loading="loaders.uploading" v-else>
              <img
                v-loading="loaders.uploading"
                v-if="pictureUrl != undefined"
                :src="pictureUrl"
                class="picture"
                @click="modals.previewPicture = true"
              />
              <img v-else class="picture" src="@/assets/img/default_img.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-6">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <fg-input
                v-validate="{ required: true }"
                v-if="!viewOnly"
                :error="errors.first('product_name')"
                v-model="models.product.name"
                data-vv-as="Nombre Comercial"
                name="product_name"
                label="Nombre Comercial"
                type="text"
                required
                placeholder="Nombre Comercial"
              />
              <div v-else>
                <label>Nombre Comercial</label>
                <div>
                  <label>
                    <b>{{ product.name ? product.name : "-" }}</b>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <fg-input
                v-if="!viewOnly"
                :error="errors.first('category')"
                label="Categoría"
                required
              >
                <el-select
                  v-validate="{ required: true }"
                  v-model="models.product.category"
                  data-vv-as="Categoría"
                  name="category"
                  class="select-primary"
                  placeholder="Categoría"
                >
                  <el-option
                    v-for="(value, key) in productDefines.productsSchema"
                    :value="key"
                    :label="value.name"
                    :key="key"
                    class="select-danger"
                  />
                </el-select>
              </fg-input>

              <div v-else>
                <label>Categoría</label>
                <div>
                  <label>
                    <b>{{ product.category ? product.category : "-" }}</b>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <fg-input
                v-if="!viewOnly"
                :error="errors.first('brand')"
                label="Marca"
                required
              >
                <el-select
                  v-validate="{ required: true }"
                  v-if="!viewOnly"
                  v-model="models.product.brand"
                  name="brand"
                  class="select-primary"
                  placeholder="Marca"
                  data-vv-as="Marca"
                  filterable
                >
                  <el-option
                    v-for="brand in productDefines.productsBrands"
                    :value="brand"
                    :label="brand"
                    :key="brand"
                    class="select-danger"
                  />
                </el-select>
              </fg-input>

              <div v-else>
                <label> Marca </label>
                <div>
                  <label>
                    <b>{{ product.brand ? product.brand : "-" }}</b>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <fg-input
                v-if="!viewOnly"
                :error="errors.first('warehouse')"
                label="Bodega"
                required
              >
                <el-select
                  v-validate="{ required: true }"
                  v-model="models.product.warehouse_id.$oid"
                  class="select-primary"
                  placeholder="Bodega"
                  name="warehouse"
                  value-key=""
                  data-vv-as="Bodega"
                  filterable
                >
                  <el-option
                    v-for="warehouse in warehouses"
                    :value="warehouse._id.$oid"
                    :label="getWarehouseMetadata(warehouse._id).name"
                    :key="warehouse._id.$oid"
                    class="select-danger"
                  />
                </el-select>
              </fg-input>

              <div v-else>
                <label> Bodega </label>
                <div>
                  <label>
                    <b>{{
                      getWarehouseMetadata(product.warehouse_id).name
                        ? getWarehouseMetadata(product.warehouse_id).name
                        : "-"
                    }}</b>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <fg-input
                v-if="!viewOnly"
                :error="errors.first('product_format')"
                label="Presentación"
                required
              >
                <el-select
                  v-validate="{ required: true }"
                  v-model="models.product.format"
                  data-vv-as="Presentación"
                  name="product_format"
                  class="select-primary"
                  placeholder="Presentación"
                  filterable
                >
                  <el-option
                    v-for="format in productDefines.sellFormat"
                    :value="format.value"
                    :label="format.name"
                    :key="format.value"
                    class="select-danger"
                  />
                </el-select>
              </fg-input>
              <div v-else>
                <label> Presentación </label>
                <div>
                  <label>
                    <b>{{ product.format ? product.format : "-" }}</b>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <fg-input
                v-if="!viewOnly"
                :error="errors.first('measure_unit')"
                label="Unidad de medida de la presentación"
                required
              >
                <el-select
                  v-validate="{ required: true }"
                  v-model="models.product.measure_unit"
                  data-vv-as="Unidad de medida"
                  name="measure_unit"
                  class="select-primary"
                  placeholder="Unidad de medida"
                  filterable
                >
                  <el-option
                    v-for="measureUnit in productDefines.measureUnits"
                    :value="measureUnit.value"
                    :label="measureUnit.name"
                    :key="measureUnit.value"
                    class="select-danger"
                  />
                </el-select>
              </fg-input>
              <div v-else>
                <label> Unidad de la medida de la presentación </label>
                <div>
                  <label>
                    <b>{{
                      product.measure_unit ? product.measure_unit : "-"
                    }}</b>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <fg-input
                v-validate="{ required: true, min_value: 1 }"
                v-if="!viewOnly"
                :error="errors.first('units_per_format')"
                v-model.number="models.product.units_per_format"
                :label="unitsPerFormatLabel"
                data-vv-as="Cantidad por formato"
                name="units_per_format"
                type="search"
                min="0"
                step="0.1"
                required
                placeholder="Cantidad por formato"
              />
              <div v-else>
                <label> Cantidad por formato </label>
                <div>
                  <label>
                    <b>{{
                      product.units_per_format ? product.units_per_format : "-"
                    }}</b>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <label>¿Es orgánico?</label>
              <n-checkbox v-if="!viewOnly" v-model="models.product.is_organic"
                >Orgánico</n-checkbox
              >
              <div v-else>
                <div>
                  <label>
                    <b>{{ product.is_organic ? "Sí" : "No" }}</b>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <label>Toxicidad</label>
              <el-select
                v-if="!viewOnly"
                v-model="models.product.toxicity"
                class="select-primary"
                placeholder="Toxicidad"
                filterable
              >
                <el-option
                  v-for="level in productDefines.toxicityLevels"
                  :value="level.name"
                  :label="level.name"
                  :key="level.name"
                  class="select-danger"
                >
                  <span
                    :style="{ background: level.color }"
                    style="
                      padding: 7px;
                      text-align: center;
                      display: inline-block;
                    "
                  />
                  {{ level.name }}
                </el-option>
              </el-select>
              <div v-else>
                <label>
                  <b>{{ product.toxicity ? product.toxicity : "-" }}</b>
                </label>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <fg-input
                v-if="!viewOnly"
                :error="errors.first('use_mode')"
                label="Modo de uso"
                required
              >
                <el-select
                  v-if="!viewOnly"
                  v-model="models.product.use_mode"
                  class="select-primary"
                  placeholder="Modo de uso"
                  name="use_mode"
                  filterable
                >
                  <el-option
                    v-for="(use, key) in productDefines.use_mode"
                    :value="use"
                    :label="use"
                    :key="key"
                    class="select-danger"
                  />
                </el-select>
              </fg-input>
              <div v-else>
                <label>
                  <b>{{ product.use_mode ? product.use_mode : "-" }}</b>
                </label>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <fg-input
                v-if="!viewOnly"
                v-model.number="models.product.ph"
                label="pH"
                type="search"
                min="0"
                step="0.1"
                placeholder="pH"
              />
              <div v-else>
                <label>pH</label>
                <div>
                  <label>
                    <b>{{ product.ph ? product.ph : "-" }}</b>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <fg-input
                v-if="!viewOnly"
                v-model.number="models.product.ce"
                label="CE (mmhos/cm)"
                type="search"
                min="0"
                step="0.1"
                placeholder="Conductividad Eléctrica (CE)"
              />

              <div v-else>
                <label>Conductividad Eléctrica (CE mmhos/cm)</label>
                <div>
                  <label>
                    <b>{{ product.ce ? product.ce : "-" }}</b>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-sm-12">
            <div class="form-group">
              <label>Ingredientes activos</label>

              <div v-if="!viewOnly" class="row">
                <div class="col-md-5 text-left">
                  <fg-input
                    v-model="active_ingredient"
                    :placeholder="'Ingrediente Activo'"
                    @keyup.enter.native="addActiveIngredient"
                  />
                </div>
                <div class="col-md-1 text-left">
                  <n-button
                    simple=""
                    size="sm"
                    type="primary"
                    @click.native="addActiveIngredient"
                  >
                    <i class="fas fa-plus-circle" />
                  </n-button>
                </div>

                <div class="col-md-6 mt-auto mb-auto">
                  <el-tag
                    v-for="(element, key) in models.product.active_ingredient"
                    :key="key"
                    :closable="true"
                    :close-transition="false"
                    size="small"
                    type="primary"
                    @close="rmActiveIngredient(element)"
                  >
                    {{ element.value }}
                  </el-tag>
                  <label v-if="!hasActiveIngredients">
                    No hay ingredientes activos asignados
                  </label>
                </div>
              </div>

              <div v-else class="row">
                <div class="col-md-6">
                  <el-tag
                    v-for="(
                      active_ingredient, index
                    ) in product.active_ingredient"
                    :key="index"
                    :closable="false"
                    size="small"
                    type="primary"
                  >
                    {{ active_ingredient.value }}
                  </el-tag>
                </div>
              </div>
            </div>
          </div>
          <template
            v-if="
              models.product.category != undefined &&
              Object.keys(settings).length > 0
            "
          >
            <div
              v-for="(value, key) in productDefines.productsSchema[
                models.product.category
              ]['fields']"
              :key="key"
              class="col-md-6 col-sm-12"
            >
              <div v-if="!viewOnly" class="form-group">
                <label v-if="value.type == 'select'">{{ value.label }}</label>
                <el-select
                  v-if="value.type == 'select'"
                  v-model="models.product.extra_fields[key]"
                  :placeholder="value.label"
                  :multiple="admitsMultiple(value)"
                  class="select-primary"
                  filterable
                >
                  <template v-if="value.optionsWithId == undefined">
                    <el-option
                      v-for="field_value in value.options"
                      :value="field_value"
                      :label="field_value"
                      :key="field_value"
                      class="select-danger"
                    />
                  </template>
                  <template v-else>
  <el-option
    v-for="field_value in value.options"
    :value="field_value._id.$oid"
    :label="field_value.value"
    :key="field_value.value"
    class="select-danger"
  />
</template>
                </el-select>
                <fg-input
                  v-else-if="value.type == 'number'"
                  v-model.number="models.product.extra_fields[key]"
                  :label="value.label"
                  :min="value.min"
                  :step="value.step"
                  :placeholder="value.label"
                  type="search"
                />
              </div>
              <div v-else class="form-group">
                <label>{{ value.label }}</label>
                <div>
                  <label v-if="value.optionsWithId == undefined">
                    <b>{{
                      models.product.extra_fields
                        ? models.product.extra_fields[key]
                        : "-"
                    }}</b>
                  </label>
                  <label v-else>
                    <template v-if="value.multiple">
                      <b>{{ computeExtraField(key, value.multiple) }}</b>
                    </template>
                    <template v-else />
                  </label>
                </div>
              </div>
            </div>
          </template>

          <div class="col-md-12 text-left">
            <notification
              v-for="(message, key) in messages"
              :key="key"
              :message="message.message"
              :type="message.type"
              :close="true"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="!viewOnly" slot="footer">
      <n-button
        v-if="newProduct"
        type="primary"
        @click.native="validate('save')"
        >Crear Producto</n-button
      >
      <n-button v-else type="primary" @click.native="validate('update')"
        >Actualizar Producto</n-button
      >
    </div>

    <modal
      :show.sync="modals.previewPicture"
      header-classes="justify-content-center"
    >
      <h4 slot="header" class="title title-up">Imagen del producto</h4>

      <img :src="pictureUrl" />
    </modal>
  </div>
</template>

<script>
import ProductDefines from "@/defines/Products";
import { get } from "lodash";

export default {
  props: {
    product: {
      type: Object,
      default: function () {
        return undefined;
      },
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    newProduct: {
      type: Boolean,
      default: true,
    },
    warehouses: {
      type: Array,
      default: () => {
        return [];
      },
    },
    messages: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      settings: undefined,
      loaders: {
        product: false,
        uploading: false,
      },
      modals: {
        previewPicture: false,
      },
      active_ingredient: "",
      models: {
        product: {
          warehouse_id: {
            $oid: "",
          },
          active_ingredient: [],
          extra_fields: {},
        },
        picture: undefined,
      },
      pictureUrl: undefined,
      productDefines: ProductDefines,
    };
  },
  computed: {
    unitsPerFormatLabel() {
      let measure_unit = "";
      if (
        this.models.product.measure_unit == "" ||
        this.models.product.measure_unit == undefined
      ) {
        measure_unit = "?";
      } else {
        measure_unit = this.models.product.measure_unit;
      }
      return "Cantidad por formato (" + measure_unit + ")";
    },
    hasActiveIngredients() {
      let active_ingredients = this.models.product.active_ingredient;
      if (active_ingredients == undefined) {
        return false;
      } else if (active_ingredients.length == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.settings = this.$store.getters["Settings/GET_SETTINGS"];

    if (this.product != undefined) {
      this.models.product = this.product;
      this.models.picture = this.pictureUrl;

      if (this.models.product.extra_fields == null) {
        this.models.product.extra_fields = {};
      }
    }

    this.computePlagues();
    this.getProductPicture();

    // this.$emit("created")
  },
  methods: {
    async validate(action) {
      const isValid = await this.$validator.validateAll();
      if (isValid) {
        if (action == "save") {
          this.$emit("save", this.models);
        } else if (action == "update") {
          this.$emit("update", this.models);
        }
      }
    },
    addActiveIngredient() {
      if (this.models.product.active_ingredient == undefined) {
        this.models.product.active_ingredient = [];
      }

      if (this.active_ingredient.length > 0) {
        this.models.product.active_ingredient.push({
          value: this.active_ingredient,
        });
      }

      this.active_ingredient = "";
    },
    rmActiveIngredient(active_ingredient) {
      let index = this.models.product.active_ingredient.findIndex((el) => {
        return el.value == active_ingredient.value;
      });
      this.models.product.active_ingredient.splice(index, 1);
    },
    parseWarehouseObjectId() {
      this.models.product.warehouse_id = this.$EJSON.BSON.ObjectID(
        this.models.selectedWarehouse
      );
    },
    admitsMultiple(value) {
      return value.hasOwnProperty("multiple") && value.multiple ? true : false;
    },
    beforePictureUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      const isPNG = file.type === "image/png";

      this.loaders.uploading = true;

      return (isJPG || isPNG) && isLt2M;
    },
    onPictureChange(file) {
      let vm = this;
      let reader = new FileReader();
      this.pictureUrl = URL.createObjectURL(file.raw);
      reader.onload = function (event) {
        vm.models.picture = event.target.result;
        vm.pictureUrl = vm.models.picture;
        vm.loaders.uploading = false;
      };

      reader.readAsDataURL(file.raw);
    },
    onPictureError(err) {
      console.log(err);
    },
    onPictureRemove() {
      this.model.picture = {};
    },
    getProductPicture() {
      let vm = this;
      let file_id;

      this.loaders.uploading = true;

      if (this.product != undefined && this.product.picture != undefined) {
        file_id = this.product.picture;
        this.Services.Files.getFile(file_id)
          .then((response) => {
            vm.pictureUrl = vm.$HELPERS.contentToFile(response.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loaders.uploading = false;
          });
      } else {
        this.loaders.uploading = false;
      }
    },
    getWarehouseMetadata(warehouse_id) {
      let warehouse = this.warehouses.find((w) => {
        if (w._id.$oid.toString() == warehouse_id.$oid.toString()) return w;
      });
      return warehouse;
    },
    computePlagues() {
      const plagues = get(this.settings, "plaguesTracking.plagues", []);

      this.productDefines.productsSchema.fitosanitario.fields.plagues.options =
        plagues.map(
          (p) => p.value.commonName + " (" + p.value.scientificName + ")"
        );
    },
    computeExtraField(key, multiple) {
      let computedValue;
      let returnValue;
      let index;
      let vm = this;

      if (key == "plagues") {
        index = "plaguesTracking";
      }

      if (multiple) {
        computedValue = [];
        this.models.product.extra_fields[key].forEach((value) => {
          let plague = vm.settings[index][key].find((i) => i._id.$oid == value);

          try {
            computedValue.push(
              plague.value.commonName +
                " (" +
                plague.value.scientificName +
                ") "
            );
          } catch (e) {
            // empty
          }
        });

        returnValue = computedValue.join(",");
      }

      return returnValue;
    },
  },
};
</script>

<style lang="sass">
.product
  .active-ingredient-control
    cursor: pointer
    font-size: 25px
    margin: 10px 0px 0px 10px
    .form-group
      text-align: left !important
    .uploadButton
      margin-top: 0px
    .previewPicture
      float: left
    .picture-uploader
      cursor: pointer
      .el-upload
        width: 100%
        border: 1px dashed #d9d9d9
        border-radius: 6px
        cursor: pointer
        position: relative
        overflow: hidden
        height: 170px
        div
          margin-top: 70px
          img
            margin: 10px 0px
            height: 150px

    .picture-uploader .el-upload:hover
      border-color: #409EFF

    .picture-uploader-icon
      font-size: 28px
      color: #8c939d
      width: 200px
      min-height: 80px
      line-height: 80px
      text-align: center
    .picture
      object-fit: contain
      object-position: 50% 50%
      cursor: pointer
    .logo
      padding: 10px
      width: 200px
      display: block
      margin: 0 auto
</style>
