<template lang="html">
  <div>
    <worker-new
      :edit="true"
      :worker="worker"
      @close="close"
      @editWorker="$emit('editWorker')"
      @workerDeleted="workerDeleted"/>
  </div>
</template>

<script>
import WorkerNew from "./New"

export default {
    components: {
        WorkerNew
    },
    props: {
        worker: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    methods: {
        close() {
            this.$emit("close")
        },
        workerDeleted() {
            this.$emit("workerDeleted")
            this.close()
        }
    }
}
</script>

<style lang="sass">
</style>
