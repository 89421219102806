<template lang="html">
  <div
    v-loading="loader"
    class="col-md-12">

    <template
      v-if="!loader">

      <div class="row">
        <div class="col-md-12 text-left">
          <h5>Datos generales</h5>
        </div>
        <div class="col-md-4 text-left">
          <fg-input
            :error="errors.first('assignee')"
            class="has-label">
            <label>Vendedor <span>*</span></label>
            <el-select
              v-validate="{required: true}"
              v-model="model.workflow.assignee.$oid"
              name="assignee"
              placeholder="Vendedor"
              data-vv-as="Vendedor"
              class="select-primary"
              filterable>
              <el-option
                v-for="(user,key) in users"
                :value="user._id.$oid"
                :label="user.full_name"
                :key="key"
                class="select-danger"/>
            </el-select>
          </fg-input>
        </div>
        <div class="col-md-4 text-left">
          <fg-input
            :error="errors.first('supplier_id')"
            class="has-label">
            <label>Cliente <span>*</span></label>
            <el-select
              v-validate="{required: true}"
              v-model="model.customer_id.$oid"
              name="customer_id"
              placeholder="Cliente"
              data-vv-as="Cliente"
              class="select-primary"
              filterable>
              <el-option
                v-for="(customer,index) in customers"
                :key="index"
                :value="customer._id.$oid"
                :label="customer.value"
                class="select-danger"/>
            </el-select>
          </fg-input>
        </div>
        <div class="col-md-4 text-left">
          <fg-input
            :value="getStateName"
            :disabled="true"
            label="Estado"
            type="text"
            placeholder="Estado"/>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-left">
          <h5>Detalle de productos</h5>
        </div>
        <div class="col-md-12">
          <el-table
            :data="model.sale_items"
            :error="errors.first('sale_items')"
            name="sale_items"
            data-vv-as="Detalle de productos"
            empty-text="No se han agregado ítems aún">
            <el-table-column
              label="Formato de venta">
              <div slot-scope="scope">
                <fg-input
                  :error="errors.first(scope.$index+'_package_format_id')">
                  <el-select
                    v-validate="{required: true}"
                    v-model="model.sale_items[scope.$index].package_format_id.$oid"
                    :name="scope.$index+'_package_format_id'"
                    placeholder="Formato de venta"
                    data-vv-as="Formato de venta"
                    class="select-primary"
                    filterable
                    @change="model.sale_items[scope.$index].allowed_raw_material_id.$oid = ''">
                    <el-option
                      v-for="(package_format,index) in package_formats"
                      :key="index"
                      :value="package_format._id.$oid"
                      :label="package_format.name"
                      class="select-danger"/>
                  </el-select>
                </fg-input>
              </div>
            </el-table-column>
            <el-table-column
              label="Variedad">
              <div slot-scope="scope">
                <fg-input
                  :error="errors.first(scope.$index+'_allowed_raw_material_id')">
                  <el-select
                    v-validate="{required: true}"
                    v-model="model.sale_items[scope.$index].allowed_raw_material_id.$oid"
                    :name="scope.$index+'_allowed_raw_material_id'"
                    placeholder="Variedad"
                    data-vv-as="Variedad"
                    class="select-primary"
                    no-data-text="Debe seleccionar un formato de ventas primero"
                    filterable>
                    <template
                      v-if="model.sale_items[scope.$index].package_format_id.$oid != ''">
                      <el-option
                        v-for="(raw_material,index) in package_formats.find(i => i._id.$oid == model.sale_items[scope.$index].package_format_id.$oid).allowed_raw_materials"
                        :key="index"
                        :value="raw_material._id.$oid"
                        :label="raw_material.specie_name+' '+raw_material.variety_name"
                        class="select-danger"/>
                    </template>
                  </el-select>
                </fg-input>
              </div>
            </el-table-column>
            <el-table-column
              header-align="center"
              label="Precio unitario sin IVA (CLP)"
              align="right"
              width="150">
              <template slot-scope="scope">
                <fg-input
                  v-validate="{ required: true, min_value: 0 }"
                  v-model.number="model.sale_items[scope.$index].price_per_unit"
                  :error="errors.first('price_per_unit'+scope.$index)"
                  :name="'price_per_unit'+scope.$index"
                  type="number"
                  data-vv-as="Precio unitario"/>
              </template>
            </el-table-column>
            <el-table-column
              header-align="center"
              label="Cantidad"
              align="right"
              width="150">
              <template slot-scope="scope">
                <fg-input
                  v-validate="{ required: true, min_value: 1 }"
                  v-model.number="model.sale_items[scope.$index].qty"
                  :error="errors.first('qty_'+scope.$index)"
                  :name="'qty_'+scope.$index"
                  type="number"
                  data-vv-as="Cantidad"/>
              </template>
            </el-table-column>
            <el-table-column
              header-align="center"
              label="Descuento (%)"
              align="right"
              width="150">
              <template slot-scope="scope">
                <fg-input
                  v-validate="{ required: true, min_value: 0 }"
                  v-model.number="model.sale_items[scope.$index].discount"
                  :error="errors.first('discount_'+scope.$index)"
                  :name="'discount_'+scope.$index"
                  type="number"
                  data-vv-as="Descuento"/>
              </template>
            </el-table-column>
            <el-table-column
              label="Subtotal"
              header-align="center"
              align="right"
              width="100">
              <div slot-scope="scope">
                {{ itemCost(scope) | toLocaleStringNumber | currency }}
              </div>
            </el-table-column>
            <el-table-column
              v-if="model.workflow.state != 'reporting'"
              label="Acciones"
              align="center"
              header-align="center"
              width="100">
              <template slot-scope="scope">
                <n-button
                  type="danger"
                  size="sm"
                  simple
                  @click.native="rmItem(scope.$index)">
                  <i class="fal fa-trash"/>
                </n-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div
          v-if="model.workflow.state != 'reporting'"
          class="col-md-12 text-right">
          <n-button
            simple=""
            size="sm"
            type="primary"
            @click.native="addItem()">
            <i class="fas fa-plus-circle" />
          </n-button>
        </div>
      </div>

      <div class="row mt-3 subtotal-purchase">
        <div class="col-md-12 text-right">
          <span>
            <b>Subtotal sin IVA:</b>
          </span>
          <span>
            CLP {{ subTotalWithoutDiscount | toLocaleStringNumber | currency }}
          </span>
        </div>
      </div>

      <div class="row mt-3 subtotal-purchase">
        <div class="col-md-12 text-right">
          <span>
            <b>Descuentos:</b>
          </span>
          <span>
            CLP {{ discounts | toLocaleStringNumber | currency }}
          </span>
        </div>
      </div>

      <div class="row mt-3 subtotal-purchase">
        <div class="col-md-12 text-right">
          <span>
            <b>Total sin IVA:</b>
          </span>
          <span>
            CLP {{ subTotal | toLocaleStringNumber | currency }}
          </span>
        </div>
      </div>

      <div class="row mt-3 subtotal-purchase">
        <div class="col-md-12 text-right">
          <span>
            <b>Impuestos (IVA):  </b>
          </span>
          <span>
            CLP {{ taxes | toLocaleStringNumber | currency }}
          </span>
        </div>
      </div>
      <div class="row mt-3 total-purchase subtotal-purchase">
        <div class="col-md-12 text-right">
          <span>
            <b>Total con IVA:</b>
          </span>
          <span>
            CLP {{ total | toLocaleStringNumber | currency }}
          </span>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12 text-left">
          <h5>Condiciones de pago</h5>
        </div>
        <div class="col-md-4 text-left">
          <fg-input
            :error="errors.first('date')"
            label="Fecha de pago">
            <el-date-picker
              v-model="model.payment_date"
              type="date"
              placeholder="Seleccionar fecha"
              name="date"
              format="dd-MM-yyyy"
              data-vv-as="Fecha de pago"/>
          </fg-input>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12 text-left">
          <h5>Registro de conversaciones</h5>
        </div>
        <div class="col-md-12 text-left">
          <el-table
            :data="model.sale_contact_records"
            empty-text="No hay registros de conversaciones registrados">
            <el-table-column
              label="Fecha"
              width="180">
              <div slot-scope="scope">
                <fg-input
                  :error="errors.first(scope.$index+'_date')">
                  <el-date-picker
                    v-validate="{required: true}"
                    v-model="model.sale_contact_records[scope.$index].date"
                    :name="scope.$index+'_date'"
                    type="date"
                    placeholder="Fecha de la conversación"
                    format="dd-MM-yyyy"
                    data-vv-as="Fecha de la conversación"/>
                </fg-input>
              </div>
            </el-table-column>
            <el-table-column
              label="Acuerdos de la conversación">
              <div slot-scope="scope">
                <fg-input
                  :error="errors.first(scope.$index+'_description')"
                  :name="scope.$index+'_description'"
                  v-model="model.sale_contact_records[scope.$index].description"
                  type="text"
                  placeholder="Acuerdos"/>
              </div>
            </el-table-column>
            <el-table-column
              v-if="model.workflow.state != 'reporting'"
              label="Acciones"
              align="center"
              header-align="center"
              width="100">
              <template slot-scope="scope">
                <n-button
                  type="danger"
                  size="sm"
                  simple
                  @click.native="rmConversation(scope.$index)">
                  <i class="fal fa-trash"/>
                </n-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-md-12 text-right">
          <n-button
            simple=""
            size="sm"
            type="primary"
            @click.native="addConversation()">
            <i class="fas fa-plus-circle" />
          </n-button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <slot name="actions"/>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <notification
            :messages="messages"
            :close="true"
            type="error"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Table, TableColumn, OptionGroup } from "element-ui"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [OptionGroup.name]: OptionGroup
    },
    props: {
        runValidations: {
            type: Number,
            default: 0
        },
        messages: {
            type: Object,
            default: () => {
                return {}
            }
        },
        initModel: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            loader: true,
            users: [],
            customers: [],
            package_formats: [],
            model: {
                farm_id: { $oid: "" },
                customer_id: { $oid: "" },
                sale_items: [],
                sale_contact_records: [
                    {
                        date: "",
                        description: ""
                    }
                ],
                payment_date: "",
                workflow: {
                    state: "negotiating",
                    assignee: {
                        $oid: ""
                    }
                }
            }
        }
    },
    computed: {
        subTotalWithoutDiscount() {
            return this.model.sale_items.reduce(
                (acc, i) => acc + i.qty * i.price_per_unit,
                0
            )
        },
        discounts() {
            return this.model.sale_items.reduce(
                (acc, i) => acc + (i.qty * i.price_per_unit * i.discount) / 100,
                0
            )
        },
        subTotal() {
            return this.subTotalWithoutDiscount - this.discounts
        },
        taxes() {
            return Math.round(this.subTotal * 0.19)
        },
        total() {
            return this.subTotal + this.taxes
        },
        getStateName() {
            return this.$t(this.model.workflow.state)
        }
    },
    watch: {
        runValidations: function(newValue, oldValue) {
            let vm = this
            this.$validator.validateAll().then(isValid => {
                vm.$emit("isValid", isValid, vm.model)
                vm.validation_perfomed = true
            })
        }
    },
    created() {
        if (Object.keys(this.initModel).length > 0) {
            this.model = this.$COPY.copy(this.initModel)
        }

        if (
            this.model.farm_id.$oid == undefined ||
            this.model.farm_id.$oid == ""
        ) {
            this.model.farm_id.$oid = this.$store.getters[
                "Farms/GET_FARM"
            ]._id.$oid
        }
        this.getUsers()
    },
    methods: {
        getUsers() {
            let vm = this
            this.Services.Users.getUsers()
                .then(response => {
                    vm.users = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.getCustomers()
                })
        },
        getCustomers() {
            let settings = this.$store.getters["Settings/GET_SETTINGS"]
            if (settings.contacts != undefined) {
                if (settings.contacts.customers != undefined) {
                    this.customers = this.$COPY.copy(
                        settings.contacts.customers
                    )
                }
            }
            this.getPackageFormats()
        },
        getPackageFormats() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.PackageFormats.getPackageFormats(farm_id)
                .then(response => {
                    vm.package_formats = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        addItem() {
            this.model.sale_items.push({
                package_format_id: {
                    $oid: ""
                },
                allowed_raw_material_id: {
                    $oid: ""
                },
                qty: "",
                price_per_unit: "",
                discount: ""
            })
        },
        rmItem(index) {
            this.model.sale_items.splice(index, 1)
        },
        getProductLabel(product) {
            let format =
                product.format +
                " de " +
                product.units_per_format +
                " " +
                product.measure_unit
            return [product.name, format, product.brand].join(" / ")
        },
        findProduct(product_id) {
            return this.products.find(i => i._id.$oid == product_id.$oid)
        },
        productToAdd(scope) {
            let product = this.findProduct(scope.row.product_id)
            let qty = scope.row.containers * product.units_per_format
            this.model.purchase_items[scope.$index].qty = qty
            this.model.purchase_items[scope.$index].format = product.format
            this.model.purchase_items[scope.$index].units_per_format =
                product.units_per_format
            return qty
        },
        itemCost(scope) {
            return (
                scope.row.qty * scope.row.price_per_unit -
                (scope.row.price_per_unit * scope.row.discount) / 100
            )
        },
        addConversation() {
            this.model.sale_contact_records.push({
                date: "",
                description: ""
            })
        },
        rmConversation(index) {
            this.model.sale_contact_records.splice(index, 1)
        }
    }
}
</script>

<style lang="sass" scoped>
	.subtotal-purchase
		font-size: 14px
		span
			min-width: 100px
			display: inline-block
			margin: 0px 10px
		span:first-child
			min-width: 200px
	.total-purchase
		font-size: 14px

	.qty-label
		font-size: 12px
		font-weight: bold
</style>
