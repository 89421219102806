<template lang="html">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12 text-left setting-subsection">Generales</div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 1, decimal: false }"
          v-model.number="model.phenologicalNumOfObservations.value"
          :error="errors.first('numOfObservations')"
          name="numOfObservations"
          required
          type="number"
          label="Número de observaciones por cuartel"
          placeholder="Número de observaciones por cuartel"
          data-vv-as="Número de observaciones por cuartel"
        />
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 0.1, max_value: 1 }"
          v-model.number="model.changeThreshold.value"
          :error="errors.first('changeThreshold')"
          name="changeThreshold"
          required
          type="number"
          label="Umbral de cambio de Estado Fenológico"
          placeholder="Umbral de cambio de Estado Fenológico"
          data-vv-as="Umbral de cambio de Estado Fenológico"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-left setting-subsection">
        Estados Fenológicos
      </div>
      <div
        v-for="specie in settings.general.species"
        :key="specie.value + specie._id.$oid.toString()"
        class="col-md-6"
      >
        <phenological-states
          key="specie._id.$oid.toString()"
          :name="'phenologicalStages_' + specie._id.$oid.toString()"
          :settings="settings"
          :specie="specie"
          :run-validations="runValidations"
          @modelUpdated="updateModel"
          @isValid="updateValidation"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-left setting-subsection">
        Campos condicionales de monitoreo por Estado Fenológico
      </div>
      <conditional-phenological-fields
        key="conditionalFields"
        :current-phenological-stages="model.phenologicalStages"
        :settings="settings"
        :sectors="sectors"
        :run-validations="runValidations"
        class="col-md-12"
        setting-category="phenologicalTracking"
        setting-key="conditionalFields"
        @updatedModel="updateModel"
        @isValid="updateValidation"
      />
    </div>

    <div class="row">
      <div class="col-md-12 text-left setting-subsection">
        Monitoreo dirigido
      </div>
      <predefinedTracking
        key="predefinedPhenologicalTracking"
        :settings="settings"
        :sectors="sectors"
        :run-validations="runValidations"
        class="col-md-12"
        setting-category="phenologicalTracking"
        setting-key="predefinedPhenologicalTracking"
        @updatedModel="updateModel"
        @isValid="updateValidation"
      />
    </div>
  </div>
</template>

<script>
import PredefinedTracking from "./Components/PredefinedTracking";
import PhenologicalStates from "./Components/PhenologicalStates";
import ConditionalPhenologicalFields from "./Components/ConditionalPhenologicalFields";
export default {
  components: {
    PredefinedTracking,
    PhenologicalStates,
    ConditionalPhenologicalFields,
  },
  props: {
    sectors: {
      type: Array,
      default: () => {
        return [];
      },
    },
    settings: {
      type: Object,
      default: () => {
        return {};
      },
    },
    runValidations: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validations: {
        predefinedTracking: "",
      },
      model: {
        phenologicalStages: [],
        phenologicalTracking: [],
        phenologicalNumOfObservations: {
          value: 10,
          key: "numOfObservations",
          category: "phenologicalTracking",
          global: true,
          type: "value",
        },
        changeThreshold: {
          value: 0.7,
          key: "changeThreshold",
          category: "phenologicalTracking",
          global: true,
          type: "value",
        },
      },
    };
  },
  watch: {
    runValidations: function (newValue, oldValue) {
      let vm = this;
      if (newValue) {
        Object.keys(this.validations).forEach((key) => {
          vm.validations[key] = "";
        });
      }
    },
  },
  created() {
    let vm = this;
    this.settings.general.species.forEach((specie) => {
      vm.validations["phenologicalStages_" + specie._id.$oid.toString()] = "";
    });

    let keys = ["numOfObservations", "changeThreshold"];
    keys.forEach((key) => {
      vm.initModel(vm.settings, "phenologicalTracking", key);
    });
  },
  methods: {
    initModel(setting, category, key) {
      if (setting[category] != undefined) {
        if (setting[category][key] != undefined) {
          if (key == "numOfObservations") {
            this.model.phenologicalNumOfObservations = this.$COPY.copy(
              setting[category][key]
            );
          }
          this.model[key] = this.$COPY.copy(setting[category][key]);
        }
      }
    },
    updateModel(newModel, formKey) {
      let vm = this;
      if (formKey == "phenologicalStages") {
        let inverseModel = vm.model[formKey].filter(
          (i) => i.key != newModel[0].key
        );
        newModel = inverseModel.concat(newModel);
      }
      this.model[formKey] = this.$COPY.copy(newModel);
    },
    updateValidation(isValid, formKey) {
      this.validations[formKey] = isValid;
      if (Object.values(this.validations).every((i) => typeof i == "boolean")) {
        this.$emit("updatedModel", this.model);
        this.$emit(
          "updateValidation",
          Object.values(this.validations).every((i) => i),
          "phenologicalStageTracking"
        );
      }
    },
  },
};
</script>

<style lang="sass" scoped>
h6
    margin: 30px 0px
</style>
