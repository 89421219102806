<template lang="html">
  <div v-loading="loader">
    <inventory-details v-if="view_mode" :model="init_model">
      <template slot="actions">
        <process-controls
          v-if="Object.keys(variables).length > 0"
          :record="variables"
          @action="processAction"
        />
      </template>
    </inventory-details>

    <inventory-form
      v-else
      :messages="messages"
      :init-model="init_model"
      :run-validations="runValidations"
      @isValid="isValid"
      @dateUpdated="updateDate"
    >
      <template slot="actions">
  <decisions
    :variables="variables"
    :transitionsWithUpdate="transitions_with_update"
    :decision="decision"
    :saveText="saveText"
    @discard="discardHandler"
    @validate="validate"
    @executeDecision="processAction"
  ></decisions>
</template>
    </inventory-form>
  </div>
</template>

<script>
import InventoryForm from "@/views/Labors/Inventories/InventoryForm";
import InventoryDetails from "@/views/Labors/Inventories/InventoryDetails";
import ProcessControls from "@/views/TaskList/Components/ProcessControls";
import Decisions from "../../../Labors/Components/Decisions.vue";

export default {
  components: {
    InventoryForm,
    InventoryDetails,
    ProcessControls,
    Decisions,
  },
  props: {
    variables: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isStartForm: {
      type: Boolean,
    },
  },
  data() {
    return {
      title: "Detalles del inventario",
      back_text: "Inventarios",
      back_custom_target: {
        name: "records-view",
        params: {
          processKey: "inventories",
        },
      },
      view_mode: true,
      loader: false,
      runValidations: 1,
      messages: {},
      init_model: {},
      model: {
        date: "",
        observations: {},
        workflow: {
          state: "new",
        },
      },
      decision: "",
      transitions_with_update: ["update_task"],
      states_which_start_with_form: ["reporting", "new"],
    };
  },
  computed: {
    saveText() {
      if (this.model.workflow.state != "new") {
        return "Guardar cambios";
      } else {
        if (this.$HELPERS.isFuture(this.model.date)) {
          return "Programar";
        } else if (this.$HELPERS.isPast(this.model.date)) {
          return "Registrar";
        } else {
          return "Iniciar";
        }
      }
    },
  },
  created() {
    if (Object.keys(this.variables).length > 0) {
      this.model = this.preProcessVariables();
      this.init_model = this.$COPY.copy(this.model);
    }
    if (this.states_which_start_with_form.includes(this.model.workflow.state)) {
      this.view_mode = false;
    }
    this.$emit("setBackText", this.back_text);
    this.$emit("setBackCustomTarget", this.back_custom_target);
    this.$emit("setTitle", this.title);
  },
  methods: {
    preProcessVariables() {
      let init_model = this.$COPY.copy(this.variables);
      init_model.warehouse_ids = init_model.warehouse_ids.map((i) => i.$oid);
      init_model.inventory_items.forEach((item, index) => {
        init_model.inventory_items[index].warehouse_id =
          item.product.warehouse_id;
        init_model.inventory_items[index].format = item.product.format;
        init_model.inventory_items[index].units_per_format =
          item.product.units_per_format;
        init_model.inventory_items[index].measure_unit =
          item.product.measure_unit;
        init_model.inventory_items[index].name = item.product.name;
        init_model.inventory_items[index].brand = item.product.brand;
        init_model.inventory_items[index].computed_stock =
          item.product.computed_stock;
      });
      return init_model;
    },
    processAction(action) {
      this.decision = action;
      if (this.transitions_with_update.includes(action) && this.view_mode) {
        this.view_mode = false;
      } else if (this.view_mode) {
        this.startOrCompleteTask();
      } else {
        this.validate();
      }
    },
    updateDate(date) {
      this.model.date = date;
    },
    discardHandler() {
      const { route } = this.$CERES_VARS.data.processData.inventories;
      this.$router.push(route);
    },
    validate() {
      this.loader = true;
      this.messages = {};
      this.runValidations += 1;
    },
    isValid(isValid, model) {
      this.model = this.$COPY.copy(model);
      isValid ? this.startOrCompleteTask() : (this.loader = false);
    },
    startOrCompleteTask() {
      if (this.model.workflow.hasOwnProperty("state")) {
        if (this.model.workflow.state != "new") {
          this.completeTask();
        } else {
          this.start();
        }
      } else {
        this.start();
      }
    },
    start() {
      let vm = this;
      this.loader = false;
      this.Services.Inventories.createInventory(this.model.farm_id, this.model)
        .then((response) => {
          vm.$HELPERS.processServerMessage(response.data.messages).then(() => {
            vm.$router.push({
              name: "records-view",
              params: {
                processKey: "inventories",
              },
            });
          });
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response.data != "string") {
            vm.messages = error.response.data;
          }
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    completeTask() {
      this.$emit(
        "completeTask",
        this.$route.params.processKey,
        this.$route.params.id,
        this.model,
        this.decision
      );
      this.loader = false;
    },
  },
};
</script>

<style lang="sass" scoped></style>
