const logs = [
  {
    versionName: "0.8.5",
    vreleaseDate: "2022-01-25",
    issues: {
      bugFixes: [
        {
          log: "Seguimiento de estado fenológico: se ha corregido el error que cuando no hay monitoreos predefinidos configurados.",
        },
      ],
    },
  },
  {
    versionName: "0.8.4",
    vreleaseDate: "2022-01-19",
    issues: {
      improvements: [
        {
          log: "Resumen de cosechas: se excluyen del selector de cuarteles, los cuarteles padres que contengan cuarteles con diferentes especies y/o variedades.",
        },
      ],
    },
  },
  {
    versionName: "0.8.3",
    vreleaseDate: "2022-01-18",
    issues: {
      improvements: [
        {
          log: "Resumen de cosechas: los registros pueden ser eliminados.",
        },
      ],
    },
  },
  {
    versionName: "0.8.2",
    vreleaseDate: "2022-01-14",
    issues: {
      improvements: [
        {
          log: "Resumen de cosechas: en la tabla de registros sólo se mustran los nombres de los cuarteles. Se eliminó la variedad y kg cosechados para facilitar la lectura. Éstos detalles siguen presentes en el detalle de cada registro.",
        },
        {
          log: "Informe Resumen de cosecha: se dio soporte para mostrar los cuarteles padres.",
        },
      ],
    },
  },
  {
    versionName: "0.8.1",
    releaseDate: "2022-01-11",
    issues: {
      improvements: [
        {
          log: "Inventarios: se agrega mensaje de ayuda sobre la acción de descartar producto",
        },
        {
          log: "Inventarios: se da soporte a números decimales de dos dígitos",
        },
        {
          log: "Inventarios: los productos ahora son mostrados en orden alfabético",
        },
        {
          log: "Podas: conteo de yemas son opcionales para dar soporte a podas de verano",
        },
      ],
      bugFixes: [
        {
          log: "Inventarios: al cerrar un formulario de inicio de proceso ahora se redirecciona a la lista de inventarios",
        },
      ],
    },
  },
  {
    versionName: "0.8.0",
    releaseDate: "2021-12-30",
    issues: {
      newFeatures: [
        {
          log: "Sección Actividades en Configuración: Permite al administrador ocultar actividades para todos los usuarios.",
        },
        {
          log: "Sección Permisos en Configuración: Permite asignar por usuario, a qué campos y actividades puede utilizar.",
        },
        {
          log: "Se ha agregado información adicional a mostrar en la vista calendario para las actividades de 'Cosecha Resumen', 'Compras' y 'Aplicaciones'.",
        },
      ],
      improvements: [],
      bugFixes: [
        {
          log: "Se ha corregido el error que no mostraba las actividades en la vista Calendario",
        },
        {
          id: "#15",
          log: "Se ha corregido el error con el que las tablas se ocultaban en pantallas pequeñas",
        },
      ],
    },
  },
  {
    versionName: "0.7.1",
    releaseDate: "2020-03-02",
    issues: {
      newFeatures: [],
      improvements: [
        {
          id: "#5",
          log: "Vista Calendario mejorada. Ahora puedes consultar, filtrar y comprar actividades con el año pasado.",
        },
        {
          id: "#7",
          log: "Proceso de Registro de análisis de laboratorio mejorado: Se ha simplificado, se permite agregar múltiples archivos y asignar costo.",
        },
        {
          id: "#8",
          log: "Ahora se despliega un mensaje de alerta cuando se intenta registrar un aumento de producto en los invetarios para confundirlos con los procesos de compra.",
        },
        {
          id: "#10",
          log: "Se ha agregado los campos de 'ID Factura de compra' y 'ID Orden de compra' en el proceso de comprar para futuras integraciones con ERP.",
        },
        {
          id: "#6",
          log: "Ahora se se puede asignar costos de cosecha que se verán reflejados en el flujo de caja.",
        },
        {
          id: "#9",
          log: "Mejoras al registro de clientes y proveedores. Ahora se puede agregar más información de contacto, descargar a csv, y se ha mejorado el despliegue de la información.",
        },
        {
          id: "#2",
          log: "Se ha agregado un link en 'Mi Perfil' para ir a cambiar contraseña y viceversa.",
        },
      ],
      bugFixes: [
        {
          id: "#12",
          log: "Se han corregido algunos errores ortográficos en el informe de YR.no.",
        },
        {
          id: "#4",
          log: "Se ha corregido el formato de número desplegado en la vista de detalles de la apliación de productos.",
        },
        {
          id: "#1",
          log: "Se ha corregido el mensaje de error que ocurría al cambiar la contraseña pese a que el cambio era exitoso.",
        },
      ],
    },
  },
  {
    id: "10063",
    versionName: "0.7.0",
    releaseDate: "2020-01-20",
    issues: {
      newFeatures: [
        {
          id: "CC-620",
          log: "¡Nuevos informes de clima desde el YR.no! ",
        },
        {
          id: "CC-610",
          log: "¡Nuevos informes de precio desde el USDA! ",
        },
      ],
      improvements: [],
      bugFixes: [],
    },
  },
  {
    id: "10069",
    versionName: "API 1.1.0",
    releaseDate: "2020-01-20",
    issues: {
      newFeatures: [
        {
          id: "CC-620",
          log: "¡Nuevos informes de clima desde el YR.no! ",
        },
        {
          id: "CC-610",
          log: "¡Nuevos informes de precio desde el USDA! ",
        },
      ],
      improvements: [],
      bugFixes: [],
    },
  },
  {
    id: "10070",
    versionName: "0.6.1",
    releaseDate: "2019-12-11",
    issues: {
      newFeatures: [],
      improvements: [],
      bugFixes: [
        {
          id: "CC-621",
          log: 'Se corregido el error de la vista "Mi contraseña" que no permitía editarla.',
        },
        {
          id: "CC-618",
          log: 'Se ha corregido el error que no permitía ver y editar la información del usuario en la vista "Mi Perfil".',
        },
      ],
    },
  },
  {
    id: "10042",
    versionName: "0.6.0",
    releaseDate: "2019-11-29",
    issues: {
      newFeatures: [],
      improvements: [
        { id: "CC-607", log: "Nueva página principal." },
        {
          id: "CC-589",
          log: "Las etiquetas del gráfico de flujo de caja ahora rotan en 45 grados cuando hay más de 5 meses para facilitar la lectura.",
        },
      ],
      bugFixes: [
        {
          id: "CC-615",
          log: "Ahora los usuarios no administradores puede acceder a la información del campo (pero no editarla).",
        },
      ],
    },
  },
  {
    id: "10035",
    versionName: "0.5.0",
    releaseDate: "2019-11-15",
    issues: {
      newFeatures: [
        {
          id: "CC-581",
          log: 'Nuevo Informe: "Flujo de caja" (Prototipo). Este informe se genera de forma automática considerando los registros ingresados en Ceres. Si tienes ideas de cómo mejorar esta vista ¡contáctanos!',
        },
        {
          id: "CC-573",
          log: 'Nuevo Proceso: "Inventarios". Ahora puedes programar y realizar inventarios a tus bodegas de productos. Las pérdidas o excesos de stock se sumarán/restarán al stock del producto que se genera automáticamente.',
        },
        {
          id: "CC-490",
          log: 'Nuevo Proceso: "Compras". Ahora puedes programar/registrar las compras, el stock entrante se sumará al stock del producto cuando se confirme la fecha de llegada de los productos al campo/finca. Proceso integrado con el "Flujo de caja"',
        },
      ],
      improvements: [
        {
          id: "CC-575",
          log: "Se ha mejorado el formato de números decimales.",
        },
        {
          id: "CC-483",
          log: "Las tareas programadas son automáticamente pasadas al siguiente estado del proceso cuando llega la fecha configurada. El cambio de estado queda registrado en el historial del proceso.",
        },
        {
          id: "CC-477",
          log: "Se han mejorado y estandarizado los mensajes de éxito, errores y advertencias.",
        },
        {
          id: "CC-453",
          log: 'Completa optimización del proceso "Mantención del sistema de riego".',
        },
        {
          id: "CC-452",
          log: 'Completa optimización del proceso "Podar".',
        },
        {
          id: "CC-451",
          log: 'Completa optimización del proceso "Polinizar".',
        },
        {
          id: "CC-450",
          log: 'Completa optimización del proceso "Monitorear plagas y enfermedades".',
        },
        {
          id: "CC-449",
          log: 'Completa optimización del proceso "Seguimiento del Estado Fenológico".',
        },
        {
          id: "CC-448",
          log: 'Completa optimización del proceso "Registrar análisis de laboratorio".',
        },
        {
          id: "CC-447",
          log: 'Completa optimización del proceso "Controlar malezas".',
        },
        {
          id: "CC-446",
          log: "Completa optimización de la obtención y generación de Informes.",
        },
        {
          id: "CC-445",
          log: "Completa optimización de la gestión de Trabajadores.",
        },
        {
          id: "CC-444",
          log: "Completa optimización de la gestión de Usuarios.",
        },
        {
          id: "CC-443",
          log: "Completa optimización de las Configuraciones de Ceres. Se agregan nuevas configuraciones también, como Clientes y Proveedores.",
        },
        {
          id: "CC-442",
          log: "Completa optimización de la gestión de los Sectores/Cuarteles.",
        },
        {
          id: "CC-441",
          log: "Completa optimización de la gestión de las Fincas/Campos.",
        },
        {
          id: "CC-440",
          log: "Completa optimización de la gestión de las Bodegas.",
        },
        {
          id: "CC-439",
          log: "Completa optimización de la gestión de los Productos/Insumos. El stock ahora se computa de manera automática considerando las compras, inventarios y aplicaciones de producto realizadas/programadas.",
        },
        {
          id: "CC-438",
          log: 'Completa optimización del proceso "Aplicación de productos".',
        },
        {
          id: "CC-437",
          log: 'Completa optimización de la vista "Mis Actividades".',
        },
        {
          id: "CC-436",
          log: "Se mejorado la seguridad del sistema en general.",
        },
        {
          id: "CC-435",
          log: 'Completa optimización del proceso "Cosechar".',
        },
        {
          id: "CC-326",
          log: 'Todos los procesos incluyen la opción de ver el "Historial de cambios" (ej: fechas de creación, última actualización y timeline de actualizaciones). Busca el botón en la parte superior derecha de cualquier proceso.',
        },
      ],
      bugFixes: [
        {
          id: "CC-489",
          log: "Se ha corregido el error que no permitía ver los PDF del informe meteorológico de CEAZEMET en Firefox.",
        },
        {
          id: "CC-454",
          log: "Se ha solucionado el error que no permitía ver una aplicación de productos que contenía productos que habían sido eliminados. Podrás ver el producto, pero si se edita la aplicación deberás seleccionarse un producto existente.",
        },
      ],
    },
  },
  {
    id: "10062",
    versionName: "0.4.5",
    releaseDate: "2019-09-05",
    issues: {
      newFeatures: [],
      improvements: [
        {
          id: "CC-491",
          log: "Se ha incorporado la superficie total cubierta por la aplicación, la dosis por hectárea por producto y la cantidad de producto utilizado por sector en la vista de cada aplicación.",
        },
      ],
      bugFixes: [],
    },
  },
  {
    id: "10061",
    versionName: "0.4.4",
    releaseDate: "2019-08-08",
    issues: {
      newFeatures: [
        {
          id: "CC-482",
          log: "Nuevos informes: Boletines meteorológicos de CEAZAMET ahora disponibles. Por ahora sólo compatible con Chrome",
        },
      ],
      improvements: [],
      bugFixes: [
        {
          id: "CC-488",
          log: "Se ha corregido el error de actualización del valor de cálculo de la dosis para el segundo producto al registrar una aplicación",
        },
      ],
    },
  },
  {
    id: "10052",
    versionName: "0.4.3",
    releaseDate: "2019-05-28",
    issues: {
      newFeatures: [
        {
          id: "CC-458",
          log: "Se ha configurado un nuevo informe con el historial de productos aplicados, filtrable y exportable para Excel.",
        },
      ],
      improvements: [
        {
          id: "CC-457",
          log: 'Se muestra una sola etiqueta "Todos" cuando una aplicación se ha realizado en todos los sectores o cuarteles del campo.',
        },
        {
          id: "CC-456",
          log: 'La vista de aplicaciones puede filtrarse por "Tipo de aplicación" y por Aplicaciones que contengan un producto fitosanitario y/o fertilizante.',
        },
      ],
      bugFixes: [],
    },
  },
  {
    id: "10051",
    versionName: "0.4.2",
    releaseDate: "2019-05-23",
    issues: {
      newFeatures: [],
      improvements: [
        {
          id: "CC-455",
          log: "Ahora los usuarios (no administradores) tienen permisos de lectura para ver los detalles de los campos y bodegas.",
        },
      ],
      bugFixes: [],
    },
  },
  {
    id: "10044",
    versionName: "0.4.1",
    releaseDate: "2019-04-01",
    issues: {
      newFeatures: [],
      improvements: [
        {
          id: "CC-432",
          log: "Se ha mejorado el despliegue de decimales en los informes de cosecha",
        },
        {
          id: "CC-431",
          log: 'Ahora se permite "Tolerancia = 0" en la configuración del monitoreo de plagas y enfermedades.',
        },
      ],
      bugFixes: [
        {
          id: "CC-430",
          log: "Se ha corregido el error que no permitía mostrar el nombre del cuartel en la lista de cosechas.",
        },
        {
          id: "CC-428",
          log: 'Se ha corregido el error que mostraba un valor erróneo de contenedores/envases de producto en la vista de "Actualizar Stock".',
        },
      ],
    },
  },
  {
    id: "10024",
    versionName: "0.4.0",
    releaseDate: "2019-03-25",
    issues: {
      newFeatures: [
        {
          id: "CC-408",
          log: 'Nueva sección "Sensores" con datos integrados de sensores externos, próximamente integración con IntelProduce.',
        },
        {
          id: "CC-390",
          log: "Nueva actividad agrícola agregada: Desmalezar.",
        },
        {
          id: "CC-388",
          log: "Nueva actividad agrícola agregada: Monitoreo de plagas.",
        },
        {
          id: "CC-387",
          log: "Nueva actividad agrícola agregada: Mantenimiento del sistema de riego.",
        },
        {
          id: "CC-381",
          log: "Nuevo Informe: Avance de Estados Fenológicos, que informa de las fechas en el que Estado Fenológico del cuartel cambió y la diferencia de días entre ellos.",
        },
        {
          id: "CC-378",
          log: "Nueva actividad agrícola agregada: Poda.",
        },
        {
          id: "CC-373",
          log: 'Nuevos informes de cosecha: "Cosecha del campo" y "Cajas por cosechero".',
        },
        {
          id: "CC-337",
          log: "Nueva actividad agrícola agregada: Registro de análisis de laboratorio.",
        },
        {
          id: "CC-322",
          log: 'Los administradores pueden crear y eliminar usuarios desde "Configuración > Usuarios".',
        },
      ],
      improvements: [
        {
          id: "CC-417",
          log: "Labores de mantención del sistema de riego: se han agregado campos condiciones para configurar parámetros de mantención. Puede utilizarse para configurar la cantidad de cloro a aplicar por ejemplo.",
        },
        {
          id: "CC-416",
          log: 'Monitoreo de plagas y enfermedades: Se agrega campo "Tolerancia" y ahora se ejecuta tarea de notificación cuando una plaga o enfermedad excede el nivel de tolerancia configurado.',
        },
        {
          id: "CC-411",
          log: "Ahora puedes ver el diagrama de procesos para en la vista de cada Actividad.",
        },
        {
          id: "CC-410",
          log: "Productos: Se ha agregado la columna de Modo de Uso a la vista.",
        },
        {
          id: "CC-405",
          log: 'Campo "Modo de uso" agregado a productos.',
        },
        {
          id: "CC-397",
          log: "Se ha mejorado la disposición de los títulos y botones de las vistas.",
        },
        {
          id: "CC-396",
          log: 'Se han agregado las columnas "Stock Real", "Stock Reservado" y "Stock Disponible" a las vista de Productos.',
        },
        {
          id: "CC-391",
          log: 'Los "Tipos de formulación" de los Fitosanitarios ahora sigue la nomenclatura de AFIPA.',
        },
        {
          id: "CC-385",
          log: "Monitoreo de Estado Fenológico: Ahora se pueden configurar variables a medir por cada Estado Fenológico.",
        },
        {
          id: "CC-383",
          log: "Monitoreo de Estado Fenológico: Ahora se puede configurar el umbral de cambio de estado fenológico para un cuartel (ej: Ceres considerá que el cuartel está en el Estado de Floración cuando el 70% de las plantas lo estén).",
        },
        {
          id: "CC-382",
          log: "Monitoreo de Estado Fenológico: Ahora puedes configurar las especies, variedades y estados fenológicos por especie que Ceres utilizará.",
        },
        {
          id: "CC-380",
          log: "Monitoreo de Estado Fenológico: Ahora el número de observaciones a realizar en los monitoreos aleatorios se parametriza en configuraciones.",
        },
        {
          id: "CC-379",
          log: "Configuración de monitoreo aleatorio y dirigido para Monitoreo de Estado Fenológico y Plagas.",
        },
        {
          id: "CC-374",
          log: 'Informe "Cajas por cosechero": se ha agregado un filtro local para filtrar los cosecheros con un umbral mínimo/máximo de cajas.',
        },
        {
          id: "CC-371",
          log: "Mejoras en parametrización de plagas y enfermedades, y ahora se puede seleccionar más de una por fitosanitario.",
        },
        {
          id: "CC-365",
          log: 'Mejoras generales a la sección de "Configuración".',
        },
        {
          id: "CC-319",
          log: "Monitoreo de Estado Fenológico: Ahora puedes adjuntar y ver las fotografías tomadas en terreno.",
        },
        {
          id: "CC-313",
          log: 'Monitoreo de Estado Fenológico: Ahora puedes consultar los monitoreos en curso e históricos desde el menú "Actividades".',
        },
      ],
      bugFixes: [],
    },
  },
  {
    id: "10037",
    versionName: "0.3.2",
    releaseDate: "2019-02-15",
    issues: {
      newFeatures: [],
      improvements: [
        { id: "CC-403", log: "Mejoras a la interfaz gráfica." },
        {
          id: "CC-398",
          log: "Se han generado nuevos grupos (Por confirmar, Programadas, Realizadas y Todas) en la vista de Aplicaciones.",
        },
        {
          id: "CC-377",
          log: 'Se ha reemplazado el botón "Eliminar y copiar" de la vista de Aplicación por "Editar".',
        },
        {
          id: "CC-375",
          log: 'Se ha agregado una columna con los Ingredientes activos al informe de "Consumo anual de productos".',
        },
      ],
      bugFixes: [
        {
          id: "CC-399",
          log: "Se ha corregido el error que aumentaba el stock de los productos al eliminar o editar una aplicación programada.",
        },
      ],
    },
  },
  {
    id: "10036",
    versionName: "0.3.1",
    releaseDate: "2019-01-21",
    issues: {
      newFeatures: [],
      improvements: [
        {
          id: "CC-386",
          log: "Los cuarteles ahora se ordenan de forma automática por alfabeto. Las listas desplegables permiten la búsqueda de texto.",
        },
        {
          id: "CC-384",
          log: 'Se ha agregado la opción de "Aplicación granular" como "Tipo de Aplicación" en "Registrar Aplicación".',
        },
        {
          id: "CC-376",
          log: 'El Informe "Consumo anual por producto" se ordena por defecto por año y por consumo anual proyectado (consumo actual + consumo futuro) del producto.',
        },
        {
          id: "CC-372",
          log: 'Los nombres científicos ahora son mostrados en cursiva en la sección "Configuración".',
        },
        {
          id: "CC-370",
          log: "Filtro global en Aplicaciones que permite filtrar las aplicaciones históricas y futuras. Presiona el icono de filtro en la esquina superior derecha para abrirlo o eliminarlo.",
        },
        {
          id: "CC-366",
          log: "Mejoras en el despliegue de números decimales en la vista de cosecha.",
        },
      ],
      bugFixes: [
        {
          id: "CC-368",
          log: 'Ahora se muestra la dosis correcta en "Ver Aplicación".',
        },
      ],
    },
  },
  {
    id: "10020",
    versionName: "0.3.0",
    releaseDate: "2019-01-16",
    issues: {
      newFeatures: [
        {
          id: "CC-358",
          log: "Nuevo Informe: Consumo anual por producto.",
        },
        {
          id: "CC-357",
          log: "Nuevo Informe: Cantidad de cajas/potes cosechados por colaborador por mes.",
        },
      ],
      improvements: [
        {
          id: "CC-360",
          log: "Mejoras en el acceso para iniciar nuevas actividades.",
        },
        {
          id: "CC-356",
          log: "Se ha mejorado el mensaje de error cuando una aplicación utiliza más stock de un producto que el disponible.",
        },
        {
          id: "CC-334",
          log: "Se han agregado los campos CE y pH a las Aplicaciones",
        },
      ],
      bugFixes: [],
    },
  },
  {
    id: "10034",
    versionName: "0.2.3",
    releaseDate: "2019-01-11",
    issues: {
      newFeatures: [],
      improvements: [],
      bugFixes: [
        {
          id: "CC-355",
          log: "Se corrigieron errores en la validación de los mínimos valores a ingresar en una aplicación",
        },
      ],
    },
  },
  {
    id: "10031",
    versionName: "0.2.2",
    releaseDate: "2019-01-07",
    issues: {
      newFeatures: [],
      improvements: [
        {
          id: "CC-348",
          log: "Se han estandarizado los números en la vista de Productos",
        },
        {
          id: "CC-347",
          log: "Se ha mejorado la validación de los todos los formularios",
        },
        {
          id: "CC-333",
          log: "Se han agregado los campos CE y pH a los productos",
        },
        {
          id: "CC-332",
          log: "Se han eliminado los campos de relacionados a los costos de los cosecheros en Cosechas",
        },
        {
          id: "CC-330",
          log: "En Registrar Cosecha puede indicarse la unidad de cosecha (caja o pote) y su peso estimado, que se utilizará para estimar kg cosechados totales",
        },
        {
          id: "CC-328",
          log: 'En información del Cuartel, se ha cambiado "Plantas/ha" por "Densidad de Plantación (Plantas/ha)" y se ha agregado el campo de "Área aparente por planta" y "Elipse Aparente/Planta"',
        },
      ],
      bugFixes: [
        {
          id: "CC-314",
          log: "Ahora se muestra en nombre del colaborador en lugar de su ID al editar una cosecha",
        },
      ],
    },
  },
  {
    id: "10023",
    versionName: "0.2.1",
    releaseDate: "2018-12-28",
    issues: {
      newFeatures: [],
      improvements: [{ id: "CC-346", log: "Actualización de seguridad" }],
      bugFixes: [],
    },
  },
  {
    id: "10018",
    versionName: "0.2.0",
    releaseDate: "2018-12-21",
    issues: {
      newFeatures: [],
      improvements: [
        {
          id: "CC-311",
          log: "Las Aplicaciones futuras ahora se pueden gestionar. Llegada la fecha de programada, prodrás validar si la aplicación ocurrió, ocurrió con modificaciones o no ocurrió. En base a esto se descuentan o reversan los stocks de los productos",
        },
      ],
      bugFixes: [],
    },
  },
  {
    id: "10022",
    versionName: "0.1.2",
    releaseDate: "2018-12-07",
    issues: {
      newFeatures: [],
      improvements: [{ log: "Mejoras a la gestión de procesos" }],
      bugFixes: [],
    },
  },
  {
    id: "10019",
    versionName: "0.1.1",
    releaseDate: "2018-12-03",
    issues: {
      newFeatures: [],
      improvements: [{ log: "Mejoras a la gestión de procesos" }],
      bugFixes: [],
    },
  },
  {
    id: "10017",
    versionName: "0.1.0",
    releaseDate: "2018-11-29",
    issues: {
      newFeatures: [{ log: "App inicial" }],
      improvements: [],
      bugFixes: [],
    },
  },
];

export default logs;
