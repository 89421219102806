const Products = {
    productsSchema: {
        fertilizante: {
            name: "Fertilizante",
            fields: {
                composition: {
                    label: "Composición",
                    type: "select",
                    options: ["Químico", "Orgánico", "Inorgánico"]
                },
                n_percent: {
                    label: "N (%)",
                    type: "number",
                    min: 0,
                    step: 0.1
                },
                p_percent: {
                    label: "P (%)",
                    type: "number",
                    min: 0,
                    step: 0.1
                },
                k_percent: {
                    label: "K (%)",
                    type: "number",
                    min: 0,
                    step: 0.1
                },
                density: {
                    label: "Densidad",
                    type: "number",
                    min: 0,
                    step: 0.1
                }
            }
        },
        fitosanitario: {
            name: "Fitosanitario",
            fields: {
                goal: {
                    label: "Finalidad",
                    type: "select",
                    options: [
                        "Insecticida",
                        "Acaricida",
                        "Herbicida",
                        "Nematicida",
                        "Funguicida",
                        "Bactericida",
                        "Molusquicida",
                        "Rodenticida",
                        "Desinfectante de suelo",
                        "Repelente",
                        "Atrayente"
                    ]
                },
                behavior: {
                    label: "Modo de acción",
                    type: "select",
                    options: [
                        "Sistémico",
                        "Penetrantes",
                        "De contacto o de superficie"
                    ]
                },
                range: {
                    label: "Espectro",
                    type: "select",
                    options: ["Amplio espectro", "Específico"]
                },
                plagues: {
                    label: "Plaga/Enfermedad",
                    type: "select",
                    multiple: true,
                    optionsWithId: true,
                    options: []
                },
                format_type: {
                    label: "Tipo de formulación",
                    type: "select",
                    options: [
                        "(DC) Concentrado dispersable",
                        "(EC) Concentrado emulsionable",
                        "(SL) Concentrado soluble",
                        "(PC) Gel o pasta concentrada",
                        "(GR) Granulado",
                        "(WG) Granulado dispersable",
                        "(EG) Granulos emulsionables",
                        "(GP) Polvo fluído",
                        "(WP) Polvo mojable",
                        "(DP) Polvo seco",
                        "(SP) Polvo soluble",
                        "(ST) Tabletas solubles",
                        "(SC) Suspensión concentrada"
                    ]
                }
            }
        }
    },
    productsBrands: [
        "A.C. Baldrich-Chile",
        "Agritecno Fertilizantes",
        "Agroas",
        "Agrogestion Vitra Ltda",
        "Agrotex  S.A.",
        "Alliance Semillas S.A.",
        "Anagra S.A.",
        "Anasac Chile S.A.",
        "Asp Chile S.A.",
        "Avance Biotechnologies Chile S.A.",
        "Basf Chile S.A.",
        "Bayer Cropscience S.A.",
        "Bioamerica S.A.",
        "Cereplas Ltda.",
        "Chemie S.A.",
        "Chile Agro Comercial S.A.",
        "Cia.Industrial El Volcan S.A.",
        "Cis Semillas  S.A.",
        "Com. Y Prod.De Insumos Ecofos Ltda",
        "Comercial Sna-E (Chile) Ltda.",
        "Connexion Ltda.",
        "Dow Agrosciences Chile S.A.",
        "Dupont Chile S.A.",
        "Fertilizantes Compo Agro Chile Ltda",
        "Garmendia Macus S.A.",
        "Gerdau Aza S.A.",
        "Hemisferio Sur S.A.",
        "Impac S.A.",
        "Import. Com. Itc S.A.",
        "Ind.Y Com. Marienberg Ltda.",
        "Innoagrok S.A.",
        "Inst.Investigaciones Agropecuarias",
        "Inv. Vigo Ltda",
        "Metaltec S.A.",
        "Metalurgica Sudamericana S.A.",
        "Monsanto Chile S. A.",
        "Mosaic De Chile Fertilizantes Ltda.",
        "New Tech Agro S.A. (Superazufre)",
        "Nunhems Chile S.A.",
        "Pacx South America S.A.",
        "Pfenniger Herram. Agricolas  S.A.",
        "Plastar San Luis S.A.",
        "Plasticos Filmamerica Limitada",
        "Plasticos Montesa Ltda.",
        "Polytex S.A.",
        "Prodalam S.A.",
        "Quimetal Industrial S.A.",
        "Rautop Ltda.",
        "Sargent Agricola Ltda.",
        "Semillas Agrical S.A.",
        "Semillas Baer Ltda",
        "Semillas Generacion 2000 Ltda",
        "Semillas Music Ltda.",
        "Semillas Pioneer Chile Ltda.",
        "Semillas Tracy Ltda.",
        "Semillas Tuniche Ltda.",
        "Sigdotek  S.A.",
        "Soc. Agricola Rinconada De Chena",
        "Sociedad Hibridos Winter Seed Limit",
        "Solo Chile Ltda.",
        "Soprocal Calerias E Industrias  S.A",
        "Soquimich Comercial S.A.",
        "Syngenta  S.A",
        "Wenco S.A.",
        "Por definir"
    ],
    measureUnits: [
        {
            name: "Centímetros cúbicos (cc)",
            value: "cc"
        },
        {
            name: "Litros (L)",
            value: "L"
        },
        {
            name: "Kilogramos (kg)",
            value: "kg"
        }
    ],
    sellFormat: [
        {
            name: "Envases",
            value: "Envases"
        },
        {
            name: "Sacos",
            value: "Sacos"
        },
        {
            name: "Bolsas",
            value: "Bolsas"
        },
        {
            name: "Cajas",
            value: "Cajas"
        }
    ],

    toxicityLevels: [
        {
            name: "Nivel I",
            color: "#87af19"
        },
        {
            name: "Nivel II",
            color: "#ece317"
        },
        {
            name: "Nivel III",
            color: "#2960a8"
        },
        {
            name: "Nivel IV",
            color: "#c60000"
        }
    ],
    use_mode: [
        "Aplicación vía riego",
        "Aplicación foliar",
        "Aplicación granular"
    ],
    data: {
        categories: [
            {
                id: "fertilizante",
                name: "Fertilizante"
            },
            {
                id: "fitosanitario",
                name: "Fitosanitario"
            }
        ]
    }
}

export default Products
