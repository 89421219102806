<template lang="html">
  <section class="section">
    <div class="container">
      <header class="section-header">
        <small v-html="data.service_features_title"/>
        <h2 v-html="data.service_features_title"/>
        <hr>
      </header>

      <div class="row gap-y text-center">

        <div
          v-for="(feature, index) in data.service_features.slices"
          :key="index"
          class="col-md-6 col-xl-4 feature-1">
          <p :class="'feature-icon text-'+feature.non_repeat.color">
            <i :class="feature.non_repeat.icon"/>
          </p>
          <h5 v-html="feature.non_repeat.service_feature_title"/>
          <span v-html="feature.non_repeat.service_feature_description"/>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
