<template lang="html">
  <section
    id="features"
    class="section bg-gray">
    <div class="container">
      <header class="section-header">
        <small v-html="data.functional_features_header_title"/>
        <h2 v-html="data.functional_features_header_title"/>
        <hr >
      </header>

      <span 
        v-for="(feature, index) in data.functional_features.slices" 
        :key="index">
        <div

          class="row gap-y align-items-center">
          <div :class="'col-md-6 text-left ' + getTextAlignment(index)">
            <h4>{{ feature.non_repeat.name }}</h4>
            <span v-html="feature.non_repeat.description"/>
          </div>


          <div :class="'col-md-5 ' + getImageAlignment(index)">
            <el-carousel >
              <el-carousel-item
                v-for="(item, index) in feature.repeat"
                :key="index"
                indicator-position="none">
                <prismic-image :field="item.image.main" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <hr
          class="my-8" >
      </span>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    isEven(index) {
      return index % 2 === 0;
    },
    getImageAlignment(index) {
      return this.isEven(index) ? "order-md-first" : "";
    },
    getTextAlignment(index) {
      return this.isEven(index) ? "ms-auto" : "me-auto";
    },
  },
};
</script>

<style lang="css" scoped></style>
