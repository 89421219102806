<template lang="html">
  <div>
    <user-profile-form
      ref="userProfileForm"
      :user-profile="model.profile"
      :edit-mode="editMode"
      @userProfileChanged="updateProfile"/>
    <user-password-form
      v-if="showCredentials"
      ref="userPasswordForm"
      @userCredentialsChanged="udpateCredentials"/>
  </div>

</template>

<script>
import UserProfileForm from "./UserProfileForm"
import UserPasswordForm from "./UserPasswordForm"
export default {
    components: {
        UserProfileForm,
        UserPasswordForm
    },
    props: {
        userProfile: {
            type: Object,
            default: () => {
                return {}
            }
        },
        showCredentials: {
            type: Boolean,
            default: false
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            model: {
                profile: {
                    id: "",
                    name: "",
                    lastname: "",
                    email: ""
                },
                credentials: {
                    password: "",
                    password_confirmation: ""
                }
            }
        }
    },
    watch: {
        "model.profile": {
            handler: function() {
                this.$emit("userDataChanged", this.model)
            },
            deep: true
        },
        "model.credentials": {
            handler: function() {
                this.$emit("userDataChanged", this.model)
            },
            deep: true
        }
    },
    created() {
        if (Object.keys(this.userProfile).length > 0) {
            this.model.profile = this.$COPY.copy(this.userProfile)
        }
    },
    methods: {
        updateProfile(userProfile) {
            this.model.profile = userProfile
        },
        udpateCredentials(userCredentials) {
            this.model.credentials = userCredentials
        }
    }
}
</script>

<style lang="css" scoped>
</style>
