<template lang="html">
  <div
    v-loading="loader"
    class="row">
    <div class="col-md-6 text-left">
      <fg-input
        :error="errors.first('date')"
        class="has-label"
      >
        <label>Fecha de la cosecha <span>*</span></label>
        <el-date-picker
          v-validate="{required: true}"
          v-model="model.date"
          type="date"
          placeholder="Seleccionar fecha"
          name="date"
          format="dd-MM-yyyy"
          data-vv-as="Fecha de la cosecha"/>
      </fg-input>
    </div>

    <div
      class="col-md-6 text-left">
      <fg-input
        :error="errors.first('sector')"
        :label="sectorLabel"
        required>
        <el-select
          v-validate="{required: true}"
          v-if="!loader"
          v-model="model.sector_id.$oid"
          :data-vv-as="sectorLabel"
          placeholder="Seleccione el cuartel"
          class="select-primary"
          name="sector"
          filterable>
          <el-option
            v-for="sector in sectors"
            :value="sector._id.$oid"
            :label="sector.name"
            :key="sector._id.$oid"
            class="select-danger"/>

        </el-select>
      </fg-input>
    </div>

    <div class="col-md-4 text-left">
      <fg-input
        :error="errors.first('harvest_unit')"
        label="Unidad de cosecha"
        required>
        <el-select
          v-validate="{required: true}"
          v-model="model.harvest_unit"
          data-vv-as="Unidad de cosecha"
          placeholder="Unidad de coscha"
          class="select-primary"
          name="harvest_unit">
          <el-option
            v-for="(unit, index) in form.harvest_units"
            :value="unit"
            :label="unit"
            :key="unit+index"
            class="select-danger"/>

        </el-select>
      </fg-input>
    </div>

    <div class="col-md-4 text-left">
      <fg-input
        v-validate="{ required: true, min_value: 0.1, decimal: false }"
        v-model.number="model.harvest_unit_weight"
        :error="errors.first('harvest_unit_weight')"
        :label="weightLabel"
        data-vv-as="Peso aproximado"
        name="harvest_unit_weight"
        type="number"
        placeholder="Peso aproximado"
        required/>
    </div>
    
    <div class="col-md-4 text-left">
      <fg-input
        v-validate="{ required: true, min_value: 0.1, decimal: true }"
        v-model.number="model.cost_per_unit"
        :error="errors.first('cost_per_unit')"
        :label="costPerUnitLabel"
        data-vv-as="Pago por pote"
        name="cost_per_unit"
        type="number"
        placeholder="Valor"
        required/>
    </div>

    <div
      v-if="!loader && isWorkersRequired == 'required'"
      class="col-md-12 text-left">
      <div class="form-group">
        <label>Detalle de Cosecher@s</label>
      </div>

      <div
        v-for="(worker,index) in model.harvest_workers"
        :key="index"
        class="row">
        <div class="col-md-8 col-sm-8 mt-auto mb-auto">
          <fg-input
            :error="errors.first('worker_id'+index)"
            class="mt-0"
          >
            <el-select
              v-validate="{required: true}"
              v-model="model.harvest_workers[index].worker_id.$oid"
              :name="'worker_id'+index"
              class="select-primary"
              data-vv-as="Cosecher@"
              placeholder="Cosecher@"
              
              filterable>
              <el-option
                v-for="(item, workerIndex) in workers"
                :value="item._id.$oid"
                :label="getWorkerName(item._id.$oid)"
                :key="'worker_option_'+workerIndex"
                class="select-danger">
                {{ getWorkerName(item._id.$oid) }}
              </el-option>
            </el-select>
          </fg-input>
        </div>

        <div class="col-md-3 col-sm-4 mt-auto mb-auto">
          <fg-input
            v-validate="{ required: true, min_value: 1, decimal: false }"
            v-model.number="worker.boxes"
            :name="'boxes'+index"
            :error="errors.first('boxes'+index)"
            type="number"
            placeholder="Unidades"
            class="mt-0"
            data-vv-as="Unidades"/>
        </div>
        
        <div class="col-md-1 col-sm-12 text-right">
          <n-button
            simple
            size="sm"
            type="danger"
            @click.native="rmWorker(index)">
            <i class="fas fa-minus-circle" />
          </n-button>
        </div>
      </div>
    </div>

    <div
      v-if="isWorkersRequired == 'required'"
      class="col-md-12 text-right">
      <n-button
        simple=""
        size="sm"
        type="primary"
        @click.native="addWorker">
        <i class="fas fa-plus-circle" /> Agregar cosecher@
      </n-button>
    </div>

    <div class="col-md-6 text-left">
      <fg-input
        :error="errors.first('assignee')"
        label="Responsable"
        required
      >
        <el-select
          v-validate="{required: true}"
          v-model="model.workflow.assignee.$oid"
          name="assignee"
          class="select-primary"
          data-vv-as="Responsable"
          placeholder="Responsable"
          filterable>
          <el-option
            v-for="(user, index) in users"
            :value="user._id.$oid"
            :label="user.name + ' ' + user.lastname"
            :key="index"
            class="select-danger" />
        </el-select>
      </fg-input>
    </div>

    <div
      v-if="mode == 'workflow'"
      class="col-md-12 action-footer">
      <slot name="actions"/>
    </div>
    <div
      v-else-if="mode == 'start'"
      class="col-md-12 action-footer">
      <n-button
        simple=""
        @click.native="$router.go(-1)">
        Cerrar
      </n-button>
      <n-button
        type="primary"
        @click.native="validate()"
      > {{ saveText }}</n-button>
    </div>
    <div
      v-else-if="mode == 'edit'"
      class="col-md-12 action-footer">
      <n-button
        type="primary"
        @click.native="validate()"
      > Guardar</n-button>
    </div>

    <div class="col-md-12">
      <notification
        :messages="messages"
        :close="true"
        type="error"/>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        record: {
            type: Object,
            default: () => {
                return {}
            }
        },
        mode: {
            type: String,
            default: "workflow"
        },
        messages: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            loader: true,
            farm_id: "",
            sectors: [],
            workers: [],
            users: [],
            currentUser: {},
            saveText: "Registrar",
            model: {
                date: null,
                sector_id: { $oid: "" },
                harvest_unit: "",
                harvest_unit_weight: "",
                harvest_unit_weight_unit: "kg",
                harvest_workers: [
                    {
                        worker_id: {
                            $oid: ""
                        },
                        boxes: ""
                    }
                ],
                cost_per_unit: 0,
                workflow: {
                    starter: "",
                    assignee: {
                        $oid: ""
                    }
                }
            },
            form: {
                harvest_units: ["Caja", "Pote"]
            }
        }
    },
    computed: {
        isWorkersRequired() {
            if (this.mode == "start") {
                return !this.$HELPERS.isFuture(this.model.date)
                    ? "required"
                    : ""
            } else {
                return "required"
            }
        },
        weightLabel() {
            let unit = ""
            if (
                this.model.harvest_unit == undefined ||
                this.model.harvest_unit == ""
            ) {
                unit = "?"
            } else {
                unit = this.model.harvest_unit
            }
            return "Kg approx. por " + unit
        },
        costPerUnitLabel() {
            let unit = ""
            if (
                this.model.harvest_unit == undefined ||
                this.model.harvest_unit == ""
            ) {
                unit = "?"
            } else {
                unit = this.model.harvest_unit
            }
            return "Pago por " + unit
        },
        sectorLabel() {
            return this.$HELPERS.isFuture(this.model.date) &
                (this.model.date != null)
                ? "Cuartel a cosechar"
                : "Cuartel cosechado"
        }
    },
    watch: {
        "model.date": function() {
            if (
                !this.$HELPERS.isFuture(this.model.date) ||
                this.model.date == undefined
            ) {
                this.saveText = "Registrar"
            } else {
                this.saveText = "Programar"
                if (this.mode == "start") {
                    this.model.harvest_workers = []
                }
            }
            this.$emit("titlePrefix", this.saveText)
        },
        model: {
            handler: function(newValue, oldValue) {
                this.$emit("modelUpdated", newValue)
            },
            deep: true
        }
    },
    created() {
        this.farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
        this.currentUser = this.$store.getters["Auth/GET_USER"]
        if (Object.keys(this.record).length > 0) {
            let model = this.$COPY.copy(this.record)
            if (model.harvest_workers == undefined) {
                model.harvest_workers = []
            }

            this.model = model
        } else {
            this.model.workflow.starter = this.currentUser._id.$oid
            this.model.workflow.assignee = this.currentUser._id.$oid
        }

        this.getSectors()
    },
    methods: {
        validate() {
            let vm = this
            this.$validator.validateAll().then(isValid => {
                if (isValid) vm.$emit("save", this.model)
            })
        },
        addWorker() {
            this.model.harvest_workers.push({
                worker_id: {
                    $oid: ""
                },
                boxes: 0
            })
        },
        rmWorker(index) {
            this.model.harvest_workers.splice(index, 1)
        },
        getSectors() {
            let vm = this
            this.Services.Sectors.getSectors(this.farm_id)
                .then(response => {
                    vm.sectors = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.getWorkers()
                })
        },
        getWorkers() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Workers.getWorkers({ farm_id })
                .then(response => {
                    vm.workers = response.data.filter(w =>
                        w.skills.includes("Cosechador")
                    )
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.getUsers()
                })
        },
        getUsers() {
            let vm = this
            this.Services.Users.getUsers()
                .then(response => {
                    vm.users = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        getWorkerName(workerId) {
            let worker = this.workers.find(i => i._id.$oid == workerId)
            return worker.name + " " + worker.lastname
        }
    }
}
</script>

<style lang="sass">
	.used-product-controls
		font-size: 25px
		i
			cursor: pointer
			margin-right: 5px
</style>
