<template lang="html">
  <div
    v-loading="loader"
    :element-loading-text="loading_text"
    class="col-md-12 global-filter"
  >
    <div v-if="!loader" class="row">
      <div
        v-for="(option, index) in options"
        :key="option.value + '_' + index"
        class="col-md-4 mb-3 text-left"
      >
        <fg-input
          :error="errors.first(option.value)"
          :label="option.text"
          required
        >
          <el-select
            v-validate="{ required: true }"
            v-model="model.level1[option.value]"
            :data-vv-as="option.text"
            :name="option.value"
            class="select-primary"
            filterable
            @change="cleanOtherOptions"
          >
            <el-option
              v-for="(sub_option, sub_index) in option.options"
              :key="sub_option.value + '_' + sub_index"
              :value="sub_option.value"
              :label="sub_option.text"
              class="select-danger"
            />
          </el-select>
        </fg-input>
      </div>

      <template v-if="model.level1.type != undefined">
        <template
          v-for="(level2, level2_idx) in options[0].options.find(
            (i) => i.value == model.level1.type
          ).options"
        >
          <div :key="level2 + '_' + level2_idx" class="col-md-4 mb-3 text-left">
            <fg-input
              :error="errors.first(level2.value)"
              :label="level2.text"
              required
            >
              <el-select
                v-validate="{ required: true }"
                v-model="model.level2[level2.value]"
                :data-vv-as="level2.text"
                :name="level2.value"
                class="select-primary"
                filterable
                @change="clearConditional(level2)"
              >
                <el-option
                  v-for="(sub_level2, sub_level2_idx) in level2.options"
                  :key="sub_level2.value + '_' + sub_level2_idx"
                  :value="sub_level2.value"
                  :label="sub_level2.text"
                  class="select-danger"
                />
              </el-select>
            </fg-input>
          </div>

          <template v-if="model.level2[level2.value] != undefined">
            <template
              v-for="(
                conditional, conditional_index
              ) in level2.conditional_options"
            >
              <div
                v-if="checkConditions(conditional)"
                :key="'conditional_' + conditional_index"
                class="col-md-4 mb-3 text-left"
              >
                <fg-input
                  v-if="conditional.type == 'select'"
                  :error="errors.first(conditional.value)"
                  :label="conditional.text"
                  required
                >
                  <el-select
                    v-validate="{ required: true }"
                    v-model="model.level2[conditional.value]"
                    :data-vv-as="conditional.text"
                    :name="conditional.value"
                    class="select-primary"
                    filterable
                  >
                    <el-option
                      v-for="(
                        conditional_option, conditional_option_index
                      ) in conditional.options"
                      :key="
                        conditional_option.value +
                        '_' +
                        conditional_option_index
                      "
                      :value="conditional_option.value"
                      :label="conditional_option.text"
                      class="select-danger"
                    />
                  </el-select>
                </fg-input>
                <fg-input
                  v-if="conditional.type == 'date'"
                  :error="errors.first(conditional.value)"
                  class="has-label"
                >
                  <label>{{ conditional.text }}<span>*</span></label>
                  <el-date-picker
                    v-validate="{ required: true }"
                    v-model="model.level2[conditional.value]"
                    :name="conditional.value"
                    :data-vv-as="conditional.text"
                    type="date"
                    placeholder="Seleccionar fecha"
                    format="dd-MM-yyyy"
                  />
                </fg-input>
                <fg-input
                  v-validate="{ required: true }"
                  v-if="conditional.type == 'input'"
                  v-model.number="model.level2[conditional.value]"
                  :name="conditional.value"
                  :data-vv-as="conditional.text"
                  :error="errors.first(conditional.value)"
                  :label="conditional.text"
                  placeholder="Ingresar valor"
                  type="number"
                  required
                />
              </div>
            </template>
          </template>
        </template>
      </template>

      <div class="col-md-12 text-right mt-auto mb-auto">
        <n-button type="primary" size="sm" @click.native="addReport()">
          <i class="fa fa-plus-circle me-1" />
          Agregar informe
        </n-button>
      </div>
    </div>

    <div v-if="reports.length > 0" class="row mt-3">
      <div class="col-md-12 text-left">
        <p>Informes agregados:</p>
      </div>

      <div class="col-md-12 text-left">
        <el-table
          :data="reports"
          empty-text="Todavía no se ha agregado un informe."
        >
          <el-table-column label="Informe">
            <div slot-scope="{ row }">
              {{ parseUsdaReportConfig(options, row) }}
            </div>
          </el-table-column>
          <el-table-column label="Acciones" align="center" width="200">
            <div slot-scope="scope">
              <n-button
                type="danger"
                size="sm"
                @click.native="rmReport(scope.$index)"
              >
                <i class="fas fa-minus-circle" />
              </n-button>
              <n-button
                type="primary"
                size="sm"
                class="ms-1"
                @click.native="getReport(scope.$index)"
              >
                <i class="fas fa-play" /> Ver
              </n-button>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-else class="row mt-3">
      <div class="col-md-12">
        <p class="text-muted text-center">
          Todavía no se ha agregado un informe
        </p>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-12 text-center">
        <n-button
          :disabled="reports.length == 0"
          type="primary"
          class="pull-left"
          @click.native="saveConfig()"
        >
          <i class="fas fa-save me-1" />
          Guardar configuración
        </n-button>
        <n-button
          :disabled="reports.length == 0"
          type="primary"
          class="pull-right"
          @click.native="getReport()"
        >
          <i class="fas fa-play me-1" />
          Ver todos
        </n-button>
      </div>
    </div>

    <div v-if="Object.keys(messages).length > 0" class="row mt-3">
      <div class="col-md-12">
        <notification :messages="messages" :close="true" type="error" />
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loader: true,
      model: {
        level1: {},
        level2: {},
      },
      reports: [],
      options: [],
      messages: {},
      loading_text: "",
    };
  },
  created() {
    this.getReportOptions();
  },
  methods: {
    getReportOptions() {
      let vm = this;
      vm.loader = true;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Reports.getReportOptions(farm_id, "usda")
        .then((response) => {
          vm.options = response.data.options;
          vm.$emit("setOptions", vm.options);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.getSavedReports();
        });
    },
    getSavedReports() {
      this.loader = true;
      if (this.config.config != undefined) {
        this.reports = [...this.config.config.reports];
        this.loader = false;
      } else {
        let vm = this;
        let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
        this.Services.Reports.getConfig(farm_id, "usda")
          .then((response) => {
            vm.reports = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            vm.loader = false;
          });
      }
    },
    cleanOtherOptions() {
      this.model.level2 = {};
    },
    clearConditional(options) {
      let vm = this;
      options.conditional_options.forEach(
        (i) => delete vm.model.level2[i.value]
      );
    },
    checkConditions(conditional) {
      return conditional.conditions.every((i) =>
        i.values.includes(this.model.level2[i.target])
      );
    },
    addReport() {
      let vm = this;
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          vm.reports.push(this.$COPY.copy(vm.model));
          vm.model.level2 = {};
          vm.model.level1 = {};
        }
      });
    },
    rmReport(index) {
      this.reports.splice(index, 1);
    },
    saveConfig() {
      let vm = this;
      vm.loader = true;
      let report_key = "usda";
      vm.messages = {};
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      let config = {
        report_key: report_key,
        farm_id: farm_id,
        config: {
          reports: vm.reports,
        },
      };
      this.Services.Reports.saveConfig(farm_id, report_key, config)
        .then((response) => {
          vm.$HELPERS.processServerMessage(response.data.messages);
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response.data != "string") {
            vm.messages = error.response.data;
          }
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    simplifyModel(model) {
      return Object.assign(model.level1, model.level2);
    },
    getReport(index) {
      let vm = this;
      vm.$emit("cleanData");
      vm.loading_text = "Esperando respuesta del USDA";
      let queries;

      if (index != undefined) {
        queries = [this.simplifyModel(this.reports[index])];
      } else {
        queries = this.reports.map((i) => vm.simplifyModel(i));
      }

      let payload = {
        queries: queries,
      };

      vm.loader = true;
      vm.$emit("showErrors", {});

      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Reports.getReport(farm_id, "usda", payload)
        .then((response) => {
          vm.$emit("data", response.data);
        })
        .catch((error) => {
          console.log(error);
          vm.$emit("showErrors", error.response.data);
        })
        .finally(() => {
          vm.loader = false;
          vm.loading_text = "";
        });
    },
    parseUsdaReportConfig(options, config) {
      const customTranslation = {
        lastX: "Last X Days",
        dateRangeType: "Date Range Type",
      };
      const convertToDate = ["repDate", "endDate"];
      const reportConfig = Object.assign(config.level1, config.level2);
      let result = [];
      let parsed = null;
      for (let key in reportConfig) {
        parsed = this.$HELPERS.deepSearch(
          options,
          "value",
          reportConfig[key],
          "text"
        );

        if (!parsed) {
          parsed = reportConfig[key];
        }

        if (Object.keys(customTranslation).includes(key)) {
          parsed = customTranslation[key] + ": " + parsed;
        }

        if (convertToDate.includes(key)) {
          parsed = this.$moment(parsed).format("DD-MM-YYYY");
        }
        result.push(parsed);
      }
      return result.join(" | ");
    },
  },
};
</script>

<style lang="sass" scoped></style>
