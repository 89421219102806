const Settings = {
  namespaced: true,
  state: {
    settings: undefined,
  },
  mutations: {
    SET_SETTINGS(state, value) {
      state.settings = value;
    },
  },
  getters: {
    GET_SETTINGS(state) {
      return state.settings;
    },
    GET_CONTACTS_BY_TYPE: (state) => (type) => {
      if (state.settings) {
        if (state.settings.contacts) {
          return state.settings.contacts.suppliers.filter(
            (i) => i.value.category == type
          );
        }
      }
      return [];
    },
    GET_CONTACT: (state) => (type, id) => {
      let contact = {};
      if (state.settings) {
        if (state.settings.contacts) {
          contact = state.settings.contacts[type].find((i) => i._id.$oid == id);
        }
      }
      return contact;
    },
    GET_CONTACT_NAME: (state, getters) => (type, id) => {
      let name = "";
      const contact = getters.GET_CONTACT(type, id);
      if (contact) name = contact.value.name;
      return name;
    },
  },
  actions: {},
};

export default Settings;
