<template lang="html">
  <div class="col-md-10 offset-md-1">
    <pruning-form
      ref="pruningForm"
      :messages="messages"
      :run-validation="runValidation"
      :init-model="initModel"
      @validForm="validForm"
      @modelUpdated="updateModel">

      <template slot="actions">
        <process-controls
          v-if="Object.keys(variables).length > 0"
          :record="variables"
          @action="validate"/>
      </template>
    </pruning-form>
  </div>

</template>

<script>
import PruningForm from "@/views/Labors/Prunings/Components/PruningForm"
import ProcessControls from "@/views/TaskList/Components/ProcessControls"

export default {
    components: {
        PruningForm,
        ProcessControls
    },
    props: {
        variables: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            loader: false,
            runValidation: false,
            messages: {},
            model: {},
            initModel: {},
            decision: ""
        }
    },
    created() {
        let title = "Poda programada"
        if (this.variables.workflow.state == "reporting")
            title = "Reportar poda"
        this.$emit("setTitle", title)

        this.model = this.Services.Prunings.simplifyPruning(this.variables)
        this.initModel = this.$COPY.copy(this.model)
    },
    methods: {
        updateModel(model) {
            this.model = this.$COPY.copy(model)
            this.saveButton = true
        },
        validate(decision) {
            // this.loader = true
            this.decision = decision
            this.messages = {}
            this.runValidation = true
        },
        validForm(isValid, model) {
            this.runValidation = false
            isValid ? this.submit(model) : (this.loader = false)
        },
        submit(model) {
            this.$emit(
                "completeTask",
                this.$route.params.processKey,
                this.$route.params.id,
                model,
                this.decision
            )
        }
    }
}
</script>

<style lang="css" scoped>
</style>
