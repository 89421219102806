<template>
  <el-card shadow="hover">
    <h6 class="text-muted text-left">{{ contact.value.name }}</h6>
    <div class="card-text text-left">
      <div class="d-flex flex-column">
        <div class="mb-1">
          <i class="fal fa-user me-1" />
          {{ contact.value.contact_name | emptyText }}
        </div>
        <div class="mb-1">
          <i class="fal fa-at me-1" /> {{ contact.value.email | emptyText }}
        </div>
        <div class="mb-1">
          <i class="fal fa-phone me-1" /> {{ contact.value.phone | emptyText }}
        </div>
        <div class="mb-1 fixHeight">
          <i class="fal fa-address-card me-1" />
          {{ contact.value.address | emptyText }}
        </div>
        <div class="d-flex flex-row align-items-center mt-3">
          <div>
            <i class="fal fa-tag me-1" />
            {{ contact.value.category | parseCategory }}
          </div>
          <div v-if="editMode" class="ms-auto">
            <n-button
              simple
              size="sm"
              type="primary"
              class="me-1 mb-0 mt-0"
              @click.native="$emit('edit')"
            >
              <i class="fal fa-edit" />
            </n-button>
            <n-button
              simple
              size="sm"
              type="danger"
              class="mb-0 mt-0"
              @click.native="$emit('delete')"
            >
              <i class="fal fa-trash" />
            </n-button>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import { Card } from "element-ui";
import { contactTypes } from "@/constants";

export default {
  components: {
    [Card.name]: Card,
  },
  filters: {
    parseCategory(category) {
      let translation = "-";
      if (category) {
        translation = contactTypes.find((i) => i.value == category).text;
      }
      return translation;
    },
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contactTypes: contactTypes,
    };
  },
};
</script>

<style></style>
