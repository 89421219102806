<template lang="html">
  <div>
    <div v-if="specie._id != undefined">
      <el-tag
        v-for="(variety, index) in showVarieties"
        :key="variety.value"
        :disable-transitions="false"
        class="clean-tag variety me-2"
        type="primary"
        closable
        @click.native="showEditInput(variety, index)"
        @close="confirmDeleteTag(variety, index)"
      >
        {{ variety.value }}
      </el-tag>
      <fg-input v-if="editInputVisible" :error="errors.first('editTag')">
        <el-input
          v-validate="{ required: true, uniqueVariety: uniquesVarieties }"
          ref="saveTagInput"
          v-model="inputValue.value"
          class="input-new-tag"
          name="editTag"
          data-vv-as="Especie"
          size="mini"
          @keyup.enter.native="handleInputConfirm"
          @keyup.escape.native="handleClose"
        />
      </fg-input>
      <fg-input v-if="inputVisible" :error="errors.first('newTag')">
        <el-input
          v-validate="{ required: true, uniqueVariety: uniquesVarieties }"
          ref="saveTagInput"
          v-model="inputValue"
          name="newTag"
          data-vv-as="Especie"
          class="input-new-tag"
          size="small"
          @keyup.enter.native="handleInputConfirm('new')"
          @keyup.escape.native="handleClose"
        />
      </fg-input>
      <n-button
        v-if="!inputVisible && !editInputVisible"
        class="button-new-tag"
        simple=""
        size="sm"
        type="primary"
        @click.native="showInput"
      >
        <i class="fas fa-plus-circle" />
      </n-button>
    </div>

    <div v-else>
      <el-alert
        :closable="false"
        title="Primero debe guardar los cambios antes de agregar variedades"
        type="warning"
      />
    </div>
  </div>
</template>

<script>
import { Tag, Input, Button, Alert } from "element-ui";
import { Validator } from "vee-validate";
import Swal from "sweetalert2";
export default {
  components: {
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Alert.name]: Alert,
  },
  props: {
    specie: {
      type: Object,
      default: () => {
        return {};
      },
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      inputVisible: false,
      inputValue: "",
      editInputVisible: false,
      editTagIndex: 0,
      uniquesVarieties: {},
      model: [],
      toDelete: [],
    };
  },
  computed: {
    showVarieties() {
      return this.model.filter((i) => i.delete == undefined);
    },
  },
  watch: {
    model: {
      handler: function (newValue, oldValue) {
        let newModel = this.$COPY.copy(newValue);
        let toDelete = this.$COPY.copy(this.toDelete);
        this.uniquesVarieties = newValue.reduce((agg, i) => {
          let value = i.value.toLowerCase().trim();
          agg[value] = (agg[value] || 0) + 1;
          return agg;
        }, {});
        this.$emit(
          "updateModel",
          newModel.concat(toDelete),
          this.specie._id.$oid.toString()
        );
      },
      deep: true,
    },
  },
  created() {
    try {
      let varieties = [];
      if (this.specie._id != undefined) {
        varieties = this.data.filter(
          (i) => i.key == this.specie._id.$oid.toString()
        );
      }

      if (varieties.length > 0) {
        this.model = this.$COPY.copy(varieties);
      }
    } catch (e) {
      console.log(e);
    }
  },
  mounted() {
    const isUniqueVariety = (value, uniqueCount) => {
      let isValid = undefined;
      let search = uniqueCount[value.toLowerCase().trim()];

      if (search == undefined) {
        isValid = true;
      } else {
        isValid = search < 1 ? true : false;
      }
      return {
        valid: isValid,
        data: {
          message: "La variedad debe ser única",
        },
      };
    };
    Validator.extend("uniqueVariety", {
      validate: isUniqueVariety,
      getMessage: (field, params, data) => {
        return data.message;
      },
    });
  },
  methods: {
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    showEditInput(data, index) {
      this.editTagIndex = index;
      this.inputValue = this.$COPY.copy(data);
      this.editInputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    confirmDeleteTag(specie, index) {
      let vm = this;
      Swal.fire({
        title: "¿Estás seguro eliminar esta variedad?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No,<br/>Quiero conservarla",
        confirmButtonText: "Si,<br/>Quiero eliminarla",
        reverseButtons: true,
        focusCancel: true,
        cancelButtonClass: "primary",
        confirmButtonClass: "danger",
      }).then((result) => {
        if (result.value) vm.rmTag(specie, index);
      });
    },
    rmTag(tag, index) {
      if (tag._id != undefined) {
        this.model[index].delete = true;
        this.toDelete.push(this.model[index]);
      }
      this.model.splice(index, 1);
    },
    handleInputConfirm(action) {
      let vm = this;
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          action == "new" ? vm.saveTag() : vm.handleEditConfirm();
        }
      });
    },
    saveTag() {
      if (this.inputValue) {
        this.model.push({
          global: true,
          value: this.inputValue,
          key: this.specie._id.$oid.toString(),
          category: "varieties",
          type: "array",
        });
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    handleEditConfirm() {
      if (this.inputValue.value) {
        this.model.splice(this.editTagIndex, 1, this.inputValue);
      }
      this.editInputVisible = false;
      this.inputValue = "";
    },
    handleClose() {
      this.inputVisible = false;
      this.editInputVisible = false;
      this.inputValue = "";
    },
  },
};
</script>

<style lang="sass" scoped>
.el-tag
  text-transform: unset !important
</style>
