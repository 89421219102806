<template lang="html">
  <card v-loading="loader">
    <template slot="header">
      <card-controls :back="true" back-target-name="irrigation-upkeeps">
        <span slot="actions">
          <n-button type="primary" simple="" @click.native="goToDiagram">
            <i class="fal fa-project-diagram" />
          </n-button>
          <template v-if="Object.keys(model).length > 0">
            <n-button
              v-if="manageControls"
              type="primary"
              simple=""
              @click.native="components.setAssignee = true"
            >
              <i class="fal fa-user" />
            </n-button>
          </template>
          <n-button
            type="primary"
            simple=""
            @click.native="goToWorkflowHistory"
          >
            <i class="fal fa-history" />
          </n-button>
        </span>
      </card-controls>

      <h4 class="card-title">
        <b>Tarea de Mantenimiento del Sistema de Riego</b>
      </h4>
    </template>

    <div class="row">
      <div class="col-md-10 offset-md-1">
        <irrigation-upkeep-form
          v-if="Object.keys(model).length > 0"
          :record="model"
          :record-id="$route.params.id"
          :view-mode="true"
        >
          <template slot="actions">
  <log-process-controls
    :record="model"
    :messages="messages"
    process-key="irrigation-upkeep"
    @delete="deleteRecord"
  />
</template>
        </irrigation-upkeep-form>
      </div>
    </div>

    <set-assignee
      v-if="components.setAssignee"
      :task-id="$route.params.id"
      :assignee="model.workflow.assignee.$oid"
      process-key="IrrigationUpkeep"
      @updateTaskList="getRecord"
      @close="components.setAssignee = false"
    />
  </card>
</template>

<script>
import IrrigationUpkeepForm from "@/views/Labors/IrrigationUpkeeps/Components/IrrigationUpkeepForm";
import LogProcessControls from "@/views/Labors/Components/LogProcessControls";
import SetAssignee from "@/views/TaskList/Components/SetAssignee";

import Swal from "sweetalert2";

export default {
  components: {
    IrrigationUpkeepForm,
    LogProcessControls,
    SetAssignee,
  },
  props: {
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loader: true,
      farm_id: "",
      model: {},
      messages: {},
      components: {
        setAssignee: false,
      },
    };
  },
  computed: {
    manageControls() {
      return this.$HELPERS.showOptionalControls(
        this.model.workflow.starter.$oid
      );
    },
  },
  created() {
    this.farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
    this.getRecord();
  },
  methods: {
    getRecord() {
      let vm = this;
      vm.loader = true;
      vm.model = {};
      this.Services.IrrigationUpkeeps.getIrrigationUpkeep(
        this.farm_id,
        this.$route.params.id
      )
        .then((response) => {
          vm.model = response.data;
        })
        .catch((error) => {
          console.log(error);
          vm.$router.push({ name: "not-found" });
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    goToDiagram() {
      this.$router.push({
        name: "diagram",
        params: {
          processKey: "irrigation-upkeep",
          id: this.$route.params.id,
          state: this.model.workflow.state,
          customBackTarget: {
            name: "view-irrigation-upkeep",
            params: {
              id: this.$route.params.id,
            },
          },
        },
      });
    },
    goToWorkflowHistory() {
      this.$router.push({
        name: "workflow-history",
        params: {
          id: this.$route.params.id,
          processKey: "irrigation-upkeep",
          record: this.model,
        },
      });
    },
    deleteRecord() {
      let vm = this;
      vm.loader = true;
      vm.messages = {};
      this.Services.IrrigationUpkeeps.deleteIrrigationUpkeep(
        this.farm_id,
        this.model._id.$oid
      )
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          }).then((result) => {
            vm.$router.push({ name: "irrigation-upkeeps" });
          });
        })
        .catch((error) => {
          console.log(error);
          vm.messages = error.response.data;
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    edit() {
      this.$router.push({
        name: "edit-irrigation-upkeep",
        params: {
          record: this.model,
        },
      });
    },
  },
};
</script>

<style lang="sass" scoped></style>
