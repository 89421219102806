import { ceres_api } from "@/axios-config.js";

const Users = {
  getUsers() {
    return ceres_api.get("/users");
  },
  getUser(id) {
    return ceres_api.get("/users/" + id);
  },
  updateUser({ id, payload }) {
    let endpoint = "/users/";

    if (id != undefined) {
      endpoint += id;
    }

    return ceres_api.put(endpoint, payload);
  },
  updatePassword({ id, payload }) {
    let endpoint = "/users/" + id + "/passwords";
    return ceres_api.put(endpoint, payload);
  },
  createUser(payload) {
    return ceres_api.post("/users/", payload);
  },
  deleteUser(id) {
    return ceres_api.delete("/users/" + id);
  },
  addUserRole({ id, role_id }) {
    let endpoint = "/users/" + id + "/roles/" + role_id;
    return ceres_api.post(endpoint);
  },
  removeUserRole({ id, role_id }) {
    let endpoint = "/users/" + id + "/roles/" + role_id;
    return ceres_api.delete(endpoint);
  },
  updateFarmAccess(userId, farmIds) {
    const endpoint = `/users/${userId}/attach_farms`;
    return ceres_api.put(endpoint, { farm_ids: farmIds });
  },
  updateWorkflowAccess(userId, workflowIds) {
    const endpoint = `/users/${userId}/attach_workflows`;
    return ceres_api.put(endpoint, { workflow_ids: workflowIds });
  },
};

export default Users;
