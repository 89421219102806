import { ceres_api } from "@/axios-config.js";

const Sectors = {
  getSectors(farm_id, unscoped = false) {
    let endpoint = "/farms/" + farm_id + "/sectors";
    if (unscoped == true) endpoint += "?unscoped=true";
    return ceres_api.get(endpoint);
  },
  createSector({ farm_id, payload }) {
    let endpoint = "/farms/" + farm_id + "/sectors";
    return ceres_api.post(endpoint, payload);
  },
  updateSector({ farm_id, sector_id, payload }) {
    let endpoint = "/farms/" + farm_id + "/sectors/" + sector_id;
    return ceres_api.put(endpoint, payload);
  },
  deleteSector({ farm_id, sector_id }) {
    let endpoint = "/farms/" + farm_id + "/sectors/" + sector_id;
    return ceres_api.delete(endpoint);
  },
  getSectorOptions(farm_id) {
    return ceres_api.get(`/farms/${farm_id}/sectors/options`);
  },
};

export default Sectors;
