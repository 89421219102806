<template lang="html">
  <modal
    :show.sync="modals.deleteUser"
    header-classes="justify-content-center"
    @close="$emit('close')"
  >
    <h4 slot="header" class="title title-up">Eliminar Usuario</h4>

    <div v-loading="loader">
      <div class="row">
        <div class="col-md-12 text-left">
          El usuario "{{ model.profile.name }} {{ model.profile.lastname }}"
          posee {{ model.tasks.length }} tareas activas.
        </div>
      </div>
      <div v-if="model.tasks.length > 0" class="row">
        <div class="col-md-12 text-left">
          <b
            >Transferir {{ model.tasks.length }} tarea(s) de "{{
              model.profile.name
            }}
            {{ model.profile.lastname }}" a:</b
          >
        </div>
        <div class="col-md-12 text-left">
          <fg-input
            :error="errors.first('newAssignee')"
            label="Nuevo responsable"
            required
          >
            <el-select
              v-validate="{ required: true }"
              v-if="!loader"
              v-model="model.newAssignee"
              data-vv-as="Nuevo response"
              placeholder="Seleccione un usuario"
              class="select-primary"
              name="newAssignee"
              filterable
            >
              <el-option
                v-for="user in usersList"
                :value="user._id.$oid.toString()"
                :label="showUserName(user)"
                :key="user.id"
                class="select-danger"
              />
            </el-select>
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <n-button type="danger" @click.native="validate()">
            Eliminar Usuario
          </n-button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "nowui/components/Modal";
import Swal from "sweetalert2";
export default {
  components: {
    Modal,
  },
  props: {
    profile: {
      type: Object,
      default: () => {
        return {};
      },
    },
    users: {
      type: Array,
      default: () => {
        return [];
      },
    },
    farms: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      modals: {
        deleteUser: true,
      },
      loader: false,
      model: {
        profile: {
          id: "",
          firstName: "",
          lastName: "",
          email: "",
        },
        tasks: [],
        newAssignee: "",
      },
      usersList: [],
    };
  },
  created() {
    let vm = this;
    let index = 0;

    if (Object.keys(this.profile).length > 0) {
      this.model.profile = this.$COPY.copy(this.profile);
    }
    if (Object.keys(this.users).length > 0) {
      this.usersList = this.$COPY.copy(this.users);
      this.usersList = this.usersList.filter(
        (u) => u._id.$oid.toString() != this.model.profile._id.$oid.toString()
      );
    }

    this.loader = true;
    this.farms.forEach((farm) => {
      vm.Services.Processes.getTasks(
        farm._id.$oid.toString(),
        vm.model.profile._id.$oid.toString()
      )
        .then((response) => {
          vm.model.tasks = vm.model.tasks.concat(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          index++;

          if (index == this.farms.length) {
            vm.loader = false;
          }
        });
    });
  },
  methods: {
    showUserName(user) {
      return user.name + " " + user.lastname;
    },
    validate() {
      let vm = this;
      this.$validator.validateAll().then((isValid) => {
        if (isValid) vm.reAssignTasks();
      });
    },
    reAssignTasks() {
      let vm = this;
      let user_id = this.model.profile._id.$oid.toString();
      let counter = 0;
      this.loader = true;

      if (this.model.tasks.length > 0) {
        this.farms.forEach((farm) => {
          let farm_id = farm._id.$oid.toString();
          let assignee = user_id;
          let new_assignee = vm.model.newAssignee.toString();

          vm.Services.Processes.bulkSetAssignee({
            farm_id,
            assignee,
            new_assignee,
          })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              counter++;
              if (counter == vm.farms.length) {
                vm.deleteUser();
              }
            });
        });
      } else {
        vm.deleteUser();
      }
    },
    deleteUser() {
      let user_id = this.model.profile._id.$oid.toString();

      // Delete user
      this.Services.Users.deleteUser(user_id)
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;

          this.$emit("updateUserList");
          this.$emit("close");
        });

      // let payload = {
      //     userId: this.model.profile.id
      // }
      // let message = {
      //     title: "Usuario eliminado correctamente",
      //     type: "success"
      // }
      // if (this.model.tasks.length > 0) {
      //     let newAssignee = vm.usersList.filter(
      //         u => u.id == vm.model.newAssignee
      //     )[0]
      //     let numOfTasks = this.model.tasks.length
      //     message.text =
      //         "Las " +
      //         numOfTasks +
      //         " tareas se han asignado a " +
      //         vm.showUserName(newAssignee)
      // }
      // this.$store
      //     .dispatch("Users/deleteUser", { payload })
      //     .then(response => {
      //         console.log(response)
      //         swal(message)
      //     })
      //     .catch(error => {
      //         console.log(error)
      //     })
      //     .finally(() => {

      //     })
    },
  },
};
</script>

<style lang="sass" scoped></style>
