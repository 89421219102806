<template lang="html">
  <span>
    <modal :show.sync="modals.deleteFarm">
      <h4 slot="header" class="title title-up center">¡Cuidado!</h4>

      ¿Estás seguro eliminar el campo "{{ farm.name }}"?

      <br />
      <div v-loading="loaders.deleteFarm">
        <n-button class="big-btn" type="primary" @click.native="deleteFarm">
          <div class="btn-label">Sí</div>

          <div class="btn-help"><br />Eliminar<br /></div>
        </n-button>
        <n-button class="big-btn" type="primary" @click.native="close()">
          <div class="btn-label">No</div>
          <div class="btn-help">
            <br />
            Mantenerlo
          </div>
        </n-button>
      </div>
    </modal>
  </span>
</template>

<script>
import Modal from "nowui/components/Modal";
import Swal from "sweetalert2";

export default {
  components: {
    Modal,
  },
  props: {
    farm: {
      type: Object,
      default: function () {
        return undefined;
      },
    },
  },
  data() {
    return {
      loaders: {
        deleteFarm: false,
      },
      modals: {
        deleteFarm: true,
      },
    };
  },
  created() {},
  methods: {
    close() {
      this.$emit("close");
    },
    deleteFarm() {
      let vm = this;
      let payload = {
        farm_id: vm.farm._id.toString(),
        farm_name: vm.farm.name,
      };

      this.loaders.deleteFarm = true;

      this.$store
        .dispatch("Farms/deleteFarm", { payload })
        .then((response) => {
          console.log(response);
          Swal.fire({
            title: "Campo eliminado exitósamente",
            icon: "success",
          });
          this.$emit("farmDeleted");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="css"></style>
