import { mapGetters, mapState } from "vuex";

const getProcessKey = {
  computed: {
    ...mapState("Processes", ["processes"]),
    ...mapGetters({
      farm_id: "Farms/GET_FARM_ID",
    }),
  },
  methods: {
    getProcessKey(task) {
      try {
        const model = this.processes.find(
          (i) => i.workflow == task.workflow._type
        ).model;
        return this.$HELPERS.classNameToString(model);
      } catch (error) {
        throw Error("Process key failed");
      }
    },
  },
};

export default getProcessKey;
