<template lang="html">
  <card
    v-loading="loader">
    <template slot="header">
      <card-controls
        :back="true"
        back-target-name="reports">
        <div slot="actions">
          <n-button
            :type="filterColor"
            simple=""
            @click.native="filterToggle()">
            <i class="fal fa-filter"/>
          </n-button>
        </div>
      </card-controls>

      <h4 class="card-title"><b>Historial de Aplicación de Productos</b></h4>
    </template>

    <collapse-transition
      v-if="show_filter">
      <div class="global-filter">
        <div class="row">
          <div class="col-md-12 text-left">
            <h6>Filtro Global</h6>
          </div>

          <div class="col-md-4 text-left">
            <div class="form-group">
              <fg-input
                label="Tipo de producto">
                <el-select
                  v-model="filter.product_type"
                  name="product_type"
                  multiple=""
                  class="select-primary"
                  placeholder="Seleccionar..."
                  filterable>
                  <el-option
                    v-for="(item,key) in productTypes"
                    :value="item.value"
                    :label="item.text"
                    :key="key"
                    class="select-danger"/>
                </el-select>
              </fg-input>
            </div>
          </div>

          <div class="col-md-4 text-left">
            <div class="form-group">
              <fg-input
                label="Producto">
                <el-select
                  v-model="filter.product_name"
                  name="product_type"
                  multiple=""
                  class="select-primary"
                  placeholder="Seleccionar..."
                  filterable>
                  <el-option
                    v-for="(item,key) in products"
                    :value="item"
                    :label="item"
                    :key="key"
                    class="select-danger"/>
                </el-select>
              </fg-input>
            </div>
          </div>

          <div class="col-md-4 text-left">
            <div class="form-group">
              <fg-input
                label="Cuartel">
                <el-select
                  v-model="filter.sectors"
                  name="sectorFilter"
                  multiple=""
                  class="select-primary"
                  placeholder="Cuartel"
                  filterable>
                  <el-option
                    v-for="(sector,key) in sectors"
                    :value="sector.name"
                    :label="sector.name"
                    :key="key"
                    class="select-danger"/>
                </el-select>
              </fg-input>
            </div>
          </div>

          <div class="col-md-4 text-left">
            <fg-input
              :error="errors.first('date')"
              class="has-label"
            >
              <label>Rango de fechas <span>*</span></label>

            </fg-input>
            <el-date-picker
              v-model="filter.date"
              :picker-options="dateRangeOptions"
              type="daterange"
              placeholder="Seleccionar fecha"
              name="date"
              data-vv-as="Fecha de la aplicación"
              unlink-panels
              format="dd-MM-yyyy"
              start-placeholder="Inicio"
              range-separator="-"
              end-placeholder="Término"/>
          </div>

        </div>

        <div class="row controls">
          <div class="col-md-12 text-center">
            <n-button
              type="danger"
              size="sm"
              @click.native="filterToggle()">
              <i class="fal fa-times-circle" />
              Cerrar
            </n-button>
            <n-button
              type="warning"
              size="sm"
              @click.native="clearFilter()">
              <i class="fal fa-trash" />
              Limpiar
            </n-button>
          </div>
        </div>
      </div>
    </collapse-transition>

    <div
      v-if="!loader"
      class="row">
      <div class="col-md-12 text-right">
        <json-excel
          :data="filteredRecords"
          :fields="csv_fields"
          :name="csvFilename"
          type="csv"
          class="csv-download btn btn-simple btn-primary">
          <i class="fal fa-download"/> Descargar data
        </json-excel>
      </div>
    </div>

    <div
      v-if="!loader"
      class="row">
      <div class="col-md-12">
        <el-table
          :data="filteredRecords"
          empty-text="No hay aplicaciones de productos que coincidan con la búsqueda">

          <el-table-column
            prop="date"
            label="Fecha"
            align="center"
            sortable>
            <div slot-scope="{row}">
              <i class="el-icon-time"/> {{ row.date | moment("DD-MM-YYYY") }}
            </div>
          </el-table-column>

          <el-table-column
            prop="product_type"
            label="Tipo de producto"
            class-name="capitalize"
            sortable/>

          <el-table-column
            prop="product_name"
            label="Producto"
            sortable />

          <el-table-column
            prop="sectors"
            label="Sectores"/>

          <el-table-column
            label="Dosis"
            align="right"
            header-align="center">
            <div slot-scope="{row}">
              {{ row.input_dose | toLocaleStringNumber('decimal') }} {{ row.input_dose_unit }}
            </div>
          </el-table-column>

          <el-table-column
            label="Cantidad"
            align="right"
            header-align="center">
            <div
              slot-scope="{row}">
              {{ row.use | toLocaleStringNumber('decimal') }} {{ row.product_measure_unit }}
            </div>
          </el-table-column>

        </el-table>
      </div>

    </div>

  </card>
</template>

<script>
import JsonExcel from "vue-json-excel"
import { Table, TableColumn } from "element-ui"
import { CollapseTransition } from "vue2-transitions"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        JsonExcel,
        CollapseTransition
    },
    data() {
        return {
            loader: true,
            show_filter: false,
            sectors: [],
            records: [],
            productTypes: [
                {
                    value: "fitosanitario",
                    text: "Fitosanitorios"
                },
                {
                    value: "fertilizante",
                    text: "Fertilizantes"
                }
            ],
            filter: {
                product_type: [],
                product_name: [],
                sectors: [],
                date: []
            },
            csv_fields: {
                Fecha: "date",
                "Tipo de producto": "product_type",
                "Nombre comercial": "product_name",
                "Mojamiento (L)": "wetting",
                Sectores: "sectors",
                Dosis: "input_dose",
                "Unidad de la dosis": "input_dose_unit",
                "Cantidad aplicada": "use",
                "Unidad de medida del producto": "product_measure_unit"
            },
            dateRangeOptions: {
                disabledDate: this.setDates,
                shortcuts: [
                    {
                        text: "Semana pasada",
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7
                            )
                            picker.$emit("pick", [start, end])
                        }
                    },
                    {
                        text: "Mes pasado",
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30
                            )
                            picker.$emit("pick", [start, end])
                        }
                    },
                    {
                        text: "Últimos 3 meses",
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 90
                            )
                            picker.$emit("pick", [start, end])
                        }
                    },
                    {
                        text: "Temporada actual",
                        onClick: this.getCurrentSeason
                    },
                    {
                        text: "Temporada pasada",
                        onClick: this.getLastSeason
                    }
                ]
            }
        }
    },
    computed: {
        products() {
            let product_array = this.records.map(i => i.product_name)
            return [...new Set(product_array)]
        },
        filterColor() {
            return this.show_filter ? "danger" : "primary"
        },
        csvFilename() {
            let now = this.$moment().format("DD-MM-YYYY")
            return "Historial_de_aplicacion_de_products_" + now + ".csv"
        },
        getMinMaxDates() {
            return this.$HELPERS.getMinMaxDates(this.records, "date")
        },
        filteredRecords() {
            let vm = this
            let hasFilter = Object.values(vm.filter).some(i => i.length > 0)
            if (hasFilter) {
                let filterToApply = Object.entries(vm.filter).filter(
                    i => i[1].length > 0
                )
                return this.records.filter(record => {
                    return filterToApply.every(i => {
                        if (i[1].length > 0) {
                            if (i[0] == "sectors") {
                                if (record[i[0]].includes(i[1])) return true
                            } else if (i[0] == "date") {
                                let record_date = vm.$moment(record.date)
                                let end = vm.$moment(i[1][1])
                                let start = vm.$moment(i[1][0])
                                let before = record_date.isSameOrBefore(end)
                                let after = record_date.isSameOrAfter(start)
                                return before & after
                            } else {
                                if (i[1].includes(record[i[0]])) return true
                            }
                        }
                    })
                })
            } else {
                return vm.records
            }
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Reports.getReport(farm_id, "product_applications")
                .then(response => {
                    vm.records = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.getSectors()
                })
        },
        getSectors() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Sectors.getSectors(farm_id)
                .then(response => {
                    vm.sectors = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        clearFilter() {
            for (let key in this.filter) {
                this.filter[key] = []
            }
        },
        setDates(time) {
            if (
                time.getTime() > this.getMinMaxDates.max ||
                time.getTime() < this.getMinMaxDates.min
            ) {
                return true
            }
        },
        getCurrentSeason(picker) {
            const now = new Date()
            let season = this.$HELPERS.getSeasonDates(now, "current")
            picker.$emit("pick", [season.start, season.end])
        },
        getLastSeason(picker) {
            const now = new Date()
            let season = this.$HELPERS.getSeasonDates(now, "last")
            picker.$emit("pick", [season.start, season.end])
        },
        filterToggle() {
            this.show_filter = !this.show_filter
            if (!this.show_filter) this.clearFilter()
        }
    }
}
</script>

<style lang="sass" scoped>
  .capitalize
    text-transform: capitalize
</style>
