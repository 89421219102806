<template lang="html">
  <modal
    :show.sync="modals.createUser"
    header-classes="justify-content-center"
    @close="$emit('close')"
  >
    <h4 slot="header" class="title title-up">Crear usuario</h4>
    <div v-loading="loader">
      <user-form
        ref="userForm"
        :show-credentials="true"
        @userDataChanged="updateUserModel"
      />

      <div class="row">
        <div class="col-md-12">
          <div v-if="messages.length > 0" class="text-left">
            <notification
              v-for="(message, key) in messages"
              :key="key"
              :message="message.message"
              :type="message.type"
              :close="true"
            />
          </div>
          <n-button type="primary" @click.native="validate()">
            Crear usuario
          </n-button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "nowui/components/Modal";
import UserForm from "./UserForm";
import Swal from "sweetalert2";
export default {
  components: {
    Modal,
    UserForm,
  },
  data() {
    return {
      modals: {
        createUser: true,
      },
      model: {
        profile: {
          id: "",
          name: "",
          lastname: "",
          email: "",
          password: "",
        },
      },
      messages: [],
      loader: false,
    };
  },
  methods: {
    updateUserModel(newUserModel) {
      // this.model = newUserModel
      this.model = {
        ...newUserModel.profile,
        ...newUserModel.credentials,
      };
    },
    validate() {
      let vm = this;
      let validations = {
        userProfileForm: undefined,
        userPasswordForm: undefined,
      };
      this.$refs.userForm.$refs.userProfileForm.$validator
        .validateAll()
        .then((isValid) => {
          validations.userProfileForm = isValid;
        })
        .finally(() => {
          validateCredentials();
        });

      function validateCredentials() {
        vm.$refs.userForm.$refs.userPasswordForm.$validator
          .validateAll()
          .then((isValid) => {
            validations.userPasswordForm = isValid;
          })
          .finally(() => {
            finalValidation();
          });
      }

      function finalValidation() {
        if (Object.values(validations).every((item) => item)) {
          vm.createUser();
        }
      }
    },
    createUser() {
      let vm = this;
      let payload = this.model;
      console.log(payload);

      this.loader = true;
      this.messages = [];

      this.Services.Users.createUser(payload)
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          });

          vm.$emit("updateUserList");
          vm.$emit("close");
        })
        .catch((error) => {
          vm.processMessages(error.response.data.errors, "danger");
        })
        .then(() => {
          this.loader = false;
        });
    },
    assignToGroup() {
      let vm = this;
      let groupId = "user";
      let userId = this.model.profile.id;

      this.$store
        .dispatch("Users/addUserToAGroup", { groupId, userId })
        .then((response) => {
          Swal.fire({
            title: "Usuario creado correctamente",
            icon: "success",
          });

          vm.$emit("updateUserList");
          vm.$emit("close");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="css" scoped></style>
