<template>
  <div>
    <div class="row">
      <div class="col-md-12 text-left">
        <el-table
          v-loading="userLoading || updateAccessLoading"
          :data="localUsers"
          :empty-text="'Sin usuarios'"
          class="align-content-top"
        >
          <el-table-column label="Usuario" class="text-left">
            <div slot-scope="{ row }">
              {{ `${row.full_name} (${row.email})` }}
            </div>
          </el-table-column>

          <el-table-column label="Campos">
            <div slot-scope="{ row }">
              <el-checkbox-group
                v-if="!row['admin?']"
                v-model="row.farm_ids"
                class="vertical"
                @change="saveFarmAccess(row)"
              >
                <el-checkbox
                  v-for="farm in farms"
                  :key="farm._id.$oid"
                  :label="farm._id.$oid"
                >
                  {{ farm.name }}
                </el-checkbox>
              </el-checkbox-group>
              <div v-else>Todos</div>
            </div>
          </el-table-column>

          <el-table-column label="Actividades">
            <div slot-scope="{ row }">
              <el-checkbox-group
                v-if="!row['admin?']"
                v-model="row.workflow_ids"
                class="vertical"
                @change="saveWorkflowAccess(row)"
              >
                <el-checkbox
                  v-for="item in processes"
                  :key="item._id.$oid"
                  :label="item._id.$oid"
                >
                  {{ $t(item.workflow) }}
                </el-checkbox>
              </el-checkbox-group>
              <div v-else>Todas</div>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="row mt-3 text-left">
      <div class="col-md-12">
        <p class="text-muted">
          Nota: Los Administradores tiene acceso a todos los campos y
          actividades
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Checkbox, CheckboxGroup } from "element-ui";
import { mapActions, mapState } from "vuex";
import { cloneDeep } from "lodash";

export default {
  name: "PermissionsSettings",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  data() {
    return {
      checked: [],
      localUsers: [],
      updateAccessLoading: false,
    };
  },
  created() {
    this.getUsers();
    this.getProcesses();
  },
  computed: {
    ...mapState("Users", ["users"]),
    ...mapState({
      userLoading: (state) => state.Users.loading,
    }),
    ...mapState("Farms", ["farms"]),
    ...mapState("Processes", { processes: "allProcesses" }),
  },
  watch: {
    users: function (newVal) {
      this.localUsers = cloneDeep(newVal);
    },
  },
  methods: {
    ...mapActions("Users", ["getUsers"]),
    ...mapActions("Processes", ["getProcesses"]),
    saveFarmAccess: async function (user) {
      try {
        this.updateAccessLoading = true;
        await this.Services.Users.updateFarmAccess(
          user._id.$oid,
          user.farm_ids
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.updateAccessLoading = false;
      }
    },
    saveWorkflowAccess: async function (user) {
      try {
        this.updateAccessLoading = true;
        await this.Services.Users.updateWorkflowAccess(
          user._id.$oid,
          user.workflow_ids
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.updateAccessLoading = false;
      }
    },
  },
};
</script>
