<template lang="html">
  <div>
    <div class="row">
      <div class="col-md-12 text-right">
        <n-button type="primary" @click.native="components.createUser = true">
          <i class="fas fa-plus-circle" />
          Crear Usuario
        </n-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-left">
        <el-table
          v-loading="loaders.userTable"
          :data="users"
          :empty-text="'Sin usuarios'"
          class="table-striped products-table"
        >
          <el-table-column
            prop="name"
            label="Nombre"
            class="text-left"
            width="100"
          />

          <el-table-column
            prop="lastname"
            label="Apellido"
            class="text-left"
            width="100"
          />

          <el-table-column prop="email" label="Email" class="text-left" />

          <el-table-column
            prop=""
            label="Administrador"
            align="center"
            width="120"
          >
            <div slot-scope="{ row }">
              <el-checkbox
                :value="isAdmin(row)"
                :disabled="isLoggedUser(row._id.$oid)"
                @change="groupToggle(row, 'admin', isAdmin(row))"
              />
            </div>
          </el-table-column>

          <el-table-column
            prop=""
            label="Supervisor"
            align="center"
            width="120"
          >
            <div slot-scope="{ row }">
              <el-checkbox
                :value="isAgronomist(row)"
                @change="groupToggle(row, 'agronomist', isAgronomist(row))"
              />
            </div>
          </el-table-column>

          <el-table-column
            label="Acciones"
            align="center"
            class-name="table-category"
          >
            <div slot-scope="{ row }" class="controls">
              <n-button
                type="primary"
                size="sm"
                @click.native="editUserProfile(row)"
              >
                <i class="fal fa-edit" />
              </n-button>
              <n-button
                type="warning"
                size="sm"
                class="ms-1"
                @click.native="resetUserPassword(row)"
              >
                <i class="fal fa-key" />
              </n-button>
              <n-button
                :disabled="disabledForLoggedUser(row)"
                type="danger"
                size="sm"
                class="ms-1"
                @click.native="deleteUser(row)"
              >
                <i class="fal fa-trash" />
              </n-button>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="row mt-3 text-left">
      <div class="col-md-12">
        <p class="text-muted">
          Nota: Sólo los Administradores y Supervisores pueden iniciar y
          eliminar procesos.
        </p>
      </div>
    </div>

    <create-user
      v-if="components.createUser"
      @updateUserList="getUsers()"
      @close="components.createUser = false"
    />

    <edit-user
      v-if="components.editUser"
      :profile="currentUserProfile"
      @updateUserList="getUsers()"
      @close="components.editUser = false"
    />

    <reset-user-password
      v-if="components.resetUserPassword"
      :profile="currentUserProfile"
      @close="components.resetUserPassword = false"
    />

    <delete-user
      v-if="components.deleteUser"
      :profile="currentUserProfile"
      :users="users"
      :farms="farms"
      @updateUserList="getUsers()"
      @close="components.deleteUser = false"
    />
  </div>
</template>

<script>
import { Table, TableColumn, Checkbox } from "element-ui";
import CreateUser from "./Components/CreateUser";
import EditUser from "./Components/EditUser";
import ResetUserPassword from "./Components/ResetUserPassword";
import DeleteUser from "./Components/DeleteUser";
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Checkbox.name]: Checkbox,
    CreateUser,
    EditUser,
    ResetUserPassword,
    DeleteUser,
  },
  props: {
    farms: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      components: {
        createUser: false,
        editUser: false,
        resetUserPassword: false,
        deleteUser: false,
      },
      loaders: {
        userTable: true,
      },
      roles: [],
      users: [],
      adminUsers: [],
      agronomistUsers: [],
      loggedUser: {},
      currentUserProfile: {},
    };
  },
  created() {
    this.getUsers();
    this.getRoles();
    this.loggedUser = this.$store.getters["Auth/GET_USER"];
  },
  methods: {
    getUsers() {
      let vm = this;

      vm.loaders.userTable = true;
      this.Services.Users.getUsers()
        .then((response) => {
          vm.users = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.loaders.userTable = false;
        });
    },
    getRoles() {
      let vm = this;
      this.Services.Roles.getRoles()
        .then((response) => {
          vm.roles = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    disabledForLoggedUser(user) {
      if (user._id.$oid == this.loggedUser._id.$oid) {
        return true;
      } else {
        return false;
      }
    },
    editUserProfile(user) {
      this.currentUserProfile = user;
      this.components.editUser = true;
    },
    resetUserPassword(user) {
      this.currentUserProfile = user;
      this.components.resetUserPassword = true;
    },
    deleteUser(user) {
      this.currentUserProfile = user;
      this.components.deleteUser = true;
    },
    getAdminUsers() {
      let vm = this;
      let groupId = "admin";
      this.$store
        .dispatch("Users/getGroupMembers", { groupId })
        .then((response) => {
          vm.adminUsers = response.data.map((i) => i.id);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.getAgronomists();
        });
    },
    getAgronomists() {
      let vm = this;
      let groupId = "agronomist";
      this.$store
        .dispatch("Users/getGroupMembers", { groupId })
        .then((response) => {
          vm.agronomistUsers = response.data.map((i) => i.id);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.loaders.userTable = false;
        });
    },
    isAdmin(row) {
      let value = row.roles.find((element) => {
        return element.role_id == "admin" ? true : false;
      });
      return value != undefined ? true : false;
    },
    isAgronomist(row) {
      let value = row.roles.find((element) => {
        return element.role_id == "agronomist" ? true : false;
      });
      return value != undefined ? true : false;
    },
    groupToggle(user, groupId, value) {
      let vm = this;
      let id = user._id.$oid.toString();
      let role = vm.roles.find((element) => {
        return element.role_id == groupId ? true : false;
      });
      let role_id = role._id.$oid.toString();

      this.loaders.userTable = true;

      if (value) {
        this.Services.Users.removeUserRole({ id, role_id })
          .then((response) => {})
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            vm.getUsers();
          });
      } else {
        this.Services.Users.addUserRole({ id, role_id })
          .then((response) => {})
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            vm.getUsers();
          });
      }
    },
    isLoggedUser(userId) {
      return this.loggedUser._id.$oid == userId ? true : false;
    },
  },
};
</script>

<style lang="css" scoped></style>
