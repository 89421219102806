<template>
  <component
    :is="tag"
    :class="`badge-${type}`"
    class="badge">

    {{ content }}
  </component>
</template>
<script>
export default {
    name: "Badge",
    props: {
        content: {
            type: String,
            default: ""
        },
        tag: {
            type: String,
            default: "span"
        },
        type: {
            type: String,
            default: "default",
            validator: value => {
                let acceptedValues = [
                    "primary",
                    "info",
                    "success",
                    "warning",
                    "danger",
                    "default"
                ]
                return acceptedValues.indexOf(value) !== -1
            }
        }
    },
    watch: {
        content: function(newVal, oldVal) {
            // watch it
            console.log("Prop changed: ", newVal, " | was: ", oldVal)
        }
    }
}
</script>
<style>
</style>
