<template lang="html">
  <card v-loading="loaders.warehouses">
    <div slot="header">
      <card-controls :back="true" :back-target-name="'products'" />

      <h4 class="card-title">
        <b>{{ title }}</b>
      </h4>
    </div>
    <product-form
      v-loading="loaders.product"
      v-if="!loaders.warehouses"
      :messages="messages"
      :warehouses="warehouses"
      @save="save"
    />
  </card>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import ProductForm from "./Components/ProductForm";

export default {
  components: {
    ProductForm,
  },
  props: {
    product: {
      type: Object,
      default: function () {
        return undefined;
      },
    },
  },
  data() {
    return {
      title: "Nuevo Producto",
      loaders: {
        warehouses: true,
        product: false,
      },
      warehouses: [],
      messages: [],
    };
  },
  computed: {
    ...mapGetters({ farmId: "Farms/GET_FARM_ID" }),
  },
  created() {
    this.getWarehouses();
  },
  methods: {
    getWarehouses() {
      let vm = this;
      this.Services.Warehouses.getWarehouses(this.farmId)
        .then((response) => {
          vm.warehouses = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.loaders.warehouses = false;
        });
    },
    save(payload) {
      let vm = this;
      payload.product.farm_id = { $oid: this.farmId };

      vm.loaders.product = true;
      vm.messages = [];
      this.Services.Products.createProduct({ farm_id: this.farmId, payload })
        .then((response) => {
          vm.processMessages(response.data.messages, "success");
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          }).then(() => {
            vm.$router.push({ name: "products" });
          });
        })
        .catch((error) => {
          console.log(error);
          vm.processMessages(error.response.data.errors, "danger");
        })
        .then(() => {
          vm.loaders.product = false;
        });
    },
  },
};
</script>

<style lang="sass">
.product
  .active-ingredient-control
    cursor: pointer
    font-size: 25px
    margin: 10px 0px 0px 10px
    .form-group
      text-align: left !important
</style>
