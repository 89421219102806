import Vue from "vue"

const PhenologicalStages = {
    data: {
        blueberry: [
            {
                id: "inveral-shoot",
                name: "Yema invernal, Dormancia (Receso)",
                description:
                    "No se observa hinchamiento de yema, las brácteas (escamas) cubren totalmente la yema"
            },
            {
                id: "bloated-shoot",
                name: "Yema Hinchada",
                description:
                    "Las escamas se están separando, la inflorescencia aún está cerrada."
            },
            {
                id: "separated-bracts",
                name: "Brácteas separadas",
                description: "Los ápices florales son visibles"
            },
            {
                id: "pre-blomming",
                name: "Pre-Floración",
                description:
                    "Las flores son distinguibles individualmente, las escamas se han caído."
            },
            {
                id: "blomming-started",
                name: "Inicio de floración",
                description:
                    "Las flores aún están cerradas, con corolas no expandidas."
            },
            {
                id: "blomming",
                name: "Plena floración",
                description: "Corolas completamente expandidas y abiertas"
            },
            {
                id: "blomming-ended",
                name: "Fin de floración",
                description: "Caída de corolas"
            },
            {
                id: "settle",
                name: "Cuaja",
                description: "Detección de nuevas bayas (fruta)"
            },
            {
                id: "pint",
                name: "Pinta",
                description:
                    "Comienzo de madurez de bayas con pérdida del color verde. Cambio a color violáceo."
            },
            {
                id: "second-settle",
                name: "Bayas maduras para cosecha (cuaja)",
                description: "Detección de nuevas bayas (frutas)"
            },
            {
                id: "foliage-fall-over",
                name: "Inicio de caída de hojas",
                description: "Caída de hojas"
            }
        ]
    },
    install() {
        Object.defineProperty(Vue.prototype, "$PHENOLOGICAL_STAGES", {
            get() {
                return PhenologicalStages
            }
        })
    }
}

export default PhenologicalStages
