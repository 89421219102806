<template>
  <fg-input :error="errors.first(name)" class="has-label">
    <label>{{ label }} <span v-if="isRequired">*</span></label>
    <el-select
      v-validate="rules"
      :value="value"
      filterable
      class="select-primary"
      :placeholder="placeholder"
      :name="name"
      :vv-data-as="label"
      :multiple="multiple"
      @change="$emit('input', $event)"
    >
      <el-option
        v-for="(item, index) in options"
        :value="get(item, itemValue)"
        :label="get(item, itemText)"
        :key="index"
        class="select-danger"
      />
    </el-select>
  </fg-input>
</template>

<script>
import formControls from "../mixins/formControl";
import { get } from "lodash";

export default {
  mixins: [formControls],
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    itemValue: {
      type: String,
      default: "value",
    },
    itemText: {
      type: String,
      default: "text",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    get: get,
  },
};
</script>
