<template lang="html">
  <div v-loading="loader" class="task-list">
    <task-list
      v-if="!loader"
      :tasks="tasks"
      :users="users"
      :is-admin="isAdmin"
      :is-supervisor="isSupervisor"
      @updateTaskList="updateTaskList"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TaskList from "./TaskList";
import { getFarmId } from "@/mixins/";

export default {
  components: {
    TaskList,
  },
  mixins: [getFarmId],
  data() {
    return {
      tasks: [],
      loader: true,
      isSupervisor: false,
    };
  },
  computed: {
    ...mapState("Users", ["users"]),
    ...mapGetters({ isAdmin: "Auth/IS_ADMIN" }),
  },
  async created() {
    this.$emit("setTitle", "Mis actividades");
    let current_user = this.$store.getters["Auth/GET_USER"];
    this.isSupervisor = this.$HELPERS.hasRole(
      current_user,
      this.$CERES_VARS.data.permissions.supervisors
    );
    this.getTasks();
  },
  methods: {
    getTasks() {
      let vm = this;
      vm.loader = true;
      this.Services.Processes.getTasks(this.farm_id)
        .then((response) => {
          vm.tasks = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    updateTaskList() {
      this.getTasks();
    },
  },
};
</script>

<style lang="sass" scoped>
.task-list
  min-height: 100px
</style>
