var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[(!_vm.loader)?_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-4 text-left"},[_c('base-date-picker',{attrs:{"label":"Fecha del monitoreo","name":"date","required":true},model:{value:(_vm.model.date),callback:function ($$v) {_vm.$set(_vm.model, "date", $$v)},expression:"model.date"}})],1),_c('div',{staticClass:"col-md-4 text-left"},[_c('base-select',{attrs:{"name":"assignee","label":"Responsable","required":true,"placeholder":"Seleccione usuario que realizará el monitoreo","options":_vm.users,"item-value":"_id.$oid","item-text":"full_name"},model:{value:(_vm.model.workflow.assignee.$oid),callback:function ($$v) {_vm.$set(_vm.model.workflow.assignee, "$oid", $$v)},expression:"model.workflow.assignee.$oid"}})],1),_c('div',{staticClass:"col-md-4 text-left"},[_c('base-select',{attrs:{"name":"tracking_type","label":"Tipo de monitoreo","placeholder":"Tipo de monitoreo","required":true,"options":_vm.trackingOptions},model:{value:(_vm.model.tracking_type),callback:function ($$v) {_vm.$set(_vm.model, "tracking_type", $$v)},expression:"model.tracking_type"}})],1)]),(_vm.model.tracking_type == 'random')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 text-left"},[_c('base-input',{attrs:{"value":_vm.settings.phenologicalTracking.numOfObservations.value,"label":"Observaciones por cuartel","disabled":""}})],1),_c('div',{staticClass:"col-md-8 text-left"},[_c('sectors-picker',{attrs:{"name":"sectors","placeholder":"Cuarteles a monitorear","required":"","multiple":"","no-parents":""},model:{value:(_vm.model.sector_ids),callback:function ($$v) {_vm.$set(_vm.model, "sector_ids", $$v)},expression:"model.sector_ids"}})],1)]):_vm._e(),(
        _vm.$HELPERS.isPast(_vm.model.date) & (_vm.model.date != undefined) ||
        _vm.model.workflow.state == 'reporting'
      )?_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-12"},[(_vm.model.sector_ids.length > 0)?_c('el-tabs',{model:{value:(_vm.current_sector),callback:function ($$v) {_vm.current_sector=$$v},expression:"current_sector"}},_vm._l((_vm.model.sector_ids),function(sector){return _c('el-tab-pane',{key:sector,attrs:{"name":sector,"label":sector}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('span',{class:_vm.validationIcon(sector).class,domProps:{"innerHTML":_vm._s(_vm.validationIcon(sector).icon)}}),_vm._v(" "+_vm._s(_vm.$HELPERS.getSectorNames(sector, _vm.sectors))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},_vm._l((_vm.model.observations.filter(
                    function (i) { return i.sector_id.$oid == sector; }
                  )),function(ob,index){return _c('div',{key:sector + '_' + index,staticClass:"row observation"},[_c('div',{staticClass:"col-md-3 text-left"},[_c('fg-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                        required: true,
                        min_value: 1,
                        decimal: false,
                      }),expression:"{\n                        required: true,\n                        min_value: 1,\n                        decimal: false,\n                      }"}],attrs:{"error":_vm.errors.first(
                          'sector_' + sector + '_line_number_' + index
                        ),"name":'sector_' + sector + '_line_number_' + index,"disabled":_vm.isPredefinedTracking,"required":"","type":"number","label":"Número de hilera","placeholder":"Número de hilera","data-vv-as":"Número de hilera"},model:{value:(ob.line_number),callback:function ($$v) {_vm.$set(ob, "line_number", _vm._n($$v))},expression:"ob.line_number"}})],1),_c('div',{staticClass:"col-md-3 text-left"},[_c('fg-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                        required: true,
                        min_value: 1,
                        decimal: false,
                      }),expression:"{\n                        required: true,\n                        min_value: 1,\n                        decimal: false,\n                      }"}],attrs:{"error":_vm.errors.first(
                          'sector_' + sector + '_plant_number_' + index
                        ),"name":'sector_' + sector + '_plant_number_' + index,"disabled":_vm.isPredefinedTracking,"required":"","type":"number","label":"Número de la planta","placeholder":"Número de la planta","data-vv-as":"Número de la planta"},model:{value:(ob.plant_number),callback:function ($$v) {_vm.$set(ob, "plant_number", _vm._n($$v))},expression:"ob.plant_number"}})],1),_c('div',{staticClass:"col-md-3 text-left"},[_c('fg-input',{attrs:{"error":_vm.errors.first(
                          'sector_' + sector + '_phenological_state_' + index
                        ),"label":"Estado Fenológico","required":""}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"select-primary",attrs:{"name":'sector_' + sector + '_phenological_state_' + index,"data-vv-as":"Estado Fenológico","placeholder":"Estado Fenológico"},on:{"change":function($event){return _vm.initExtraMeasures(
                            sector,
                            index,
                            ob.phenological_stage
                          )}},model:{value:(ob.phenological_stage.$oid),callback:function ($$v) {_vm.$set(ob.phenological_stage, "$oid", $$v)},expression:"ob.phenological_stage.$oid"}},_vm._l((_vm.getPhenologicalStates(
                            sector
                          )),function(state,key){return _c('el-option',{key:key,staticClass:"select-danger",attrs:{"value":state._id.$oid,"label":state.value}})}),1)],1)],1),_c('div',{staticClass:"col-md-3"},[_c('picture-uploader',{key:'sector_' + sector + '_observation_' + index,attrs:{"sector_id":sector,"index":index,"photo":ob.photo},on:{"pictureProcessed":_vm.attachPicture}})],1),_vm._l((ob.extra_measures),function(extra,extra_index){return _c('div',{key:'sector_' +
                      sector +
                      '_observation_' +
                      index +
                      '_extra_measure_' +
                      extra_index,staticClass:"col-3 text-left"},[_c('fg-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true, min_value: 1 }),expression:"{ required: true, min_value: 1 }"}],attrs:{"error":_vm.errors.first(
                          'sector_' +
                            sector +
                            '_observation_' +
                            index +
                            '_extra_measure_' +
                            extra_index
                        ),"name":'sector_' +
                        sector +
                        '_observation_' +
                        index +
                        '_extra_measure_' +
                        extra_index,"label":extra.variable_name,"placeholder":extra.variable_name,"data-vv-as":extra.variable_name,"required":"","type":"number"},model:{value:(ob.extra_measures[extra_index].value),callback:function ($$v) {_vm.$set(ob.extra_measures[extra_index], "value", _vm._n($$v))},expression:"ob.extra_measures[extra_index].value"}})],1)})],2)}),0)])])}),1):_c('el-alert',{attrs:{"closable":false,"title":"Selecione al menos un cuartel y el tipo de monitoreo","type":"warning","show-icon":""}})],1)]):_vm._e()]):_vm._e(),_c('div',{staticClass:"col-md-12 mt-3"},[_vm._t("actions")],2),_c('div',{staticClass:"col-md-12"},[_c('notification',{attrs:{"messages":_vm.messages,"close":true,"type":"error"}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 text-left"},[_c('h5',[_vm._v("Configuración del monitoreo")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 text-left"},[_c('h5',[_vm._v("Registro de observaciones")])])}]

export { render, staticRenderFns }