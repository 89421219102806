import { render, staticRenderFns } from "./Roadmap.vue?vue&type=template&id=fb2c6282&scoped=true&lang=html&"
import script from "./Roadmap.vue?vue&type=script&lang=js&"
export * from "./Roadmap.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb2c6282",
  null
  
)

export default component.exports