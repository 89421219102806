<template lang="html">
  <card v-loading="loader">
    <div slot="header">
      <card-controls
        :back="true"
        :back-target="'/dashboard/products'">
        <span slot="actions">
          <n-button
            type="primary"
            simple=""
            @click.native="editProduct">
            <i class="fas fa-edit" />
            Editar
          </n-button>
        </span>
      </card-controls>
      <h4 class="card-title"><b>{{ title }}</b></h4>
    </div>

    <product-form
      v-if="dependencies"
      :view-only="true"
      :warehouses="warehouses"
      :product="product"
    />
  </card>
</template>

<script>
import ProductForm from "./Components/ProductForm"

export default {
    components: {
        ProductForm
    },
    data() {
        return {
            title: "Información del producto",
            loader: true,
            product: {},
            warehouses: [],
            farm: undefined,
            dependencies: false
        }
    },
    created() {
        this.farm = this.$store.getters["Farms/GET_FARM"]
        this.getWarehouses()
    },
    methods: {
        getProduct() {
            let vm = this
            let product_id = this.$route.params.id
            let farm_id = this.farm._id.$oid.toString()

            this.Services.Products.getProduct({ farm_id, product_id })
                .then(response => {
                    vm.product = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                    vm.dependencies = true
                })
        },
        getWarehouses() {
            let vm = this
            let farm_id = this.farm._id.$oid.toString()
            this.Services.Warehouses.getWarehouses(farm_id)
                .then(response => {
                    vm.warehouses = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.getProduct()
                })
        },
        editProduct() {
            this.$router.push({
                name: "edit-product",
                params: {
                    id: this.product._id.$oid.toString(),
                    create: false
                }
            })
        }
    }
}
</script>

<style lang="sass">
	.product-detail
		margin-top: 40px
</style>
