<template lang="html">
  <div v-if="render">
    <div v-if="hasAllSectors">
      <el-tag
        :closable="false"
        :close-transition="false"
        size="small"
        type="primary"
        class="used-product-tag">
        Todos
      </el-tag>
    </div>
    <div v-else>
      <el-tag
        v-for="(sector_id,key) in current_sectors_list"
        :key="key"
        :closable="false"
        :close-transition="false"
        size="small"
        type="primary"
        class="used-product-tag">
        {{ sectorList.find(i => i._id.$oid == sector_id).name }}
      </el-tag>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        current_sectors: {
            type: Array,
            default: () => {
                return []
            }
        },
        sectors: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            render: false,
            sectorList: [],
            current_sectors_list: []
        }
    },
    computed: {
        hasAllSectors() {
            let vm = this
            return this.sectorList.every(i =>
                vm.current_sectors_list.includes(i._id.$oid)
            )
        }
    },
    created() {
        if (this.current_sectors.length > 0) {
            if (this.current_sectors[0].$oid != undefined) {
                this.current_sectors_list = this.current_sectors.map(
                    i => i.$oid
                )
            } else {
                this.current_sectors_list = this.current_sectors
            }
        }

        if (this.sectors.length > 0) {
            this.sectorList = this.sectors
            this.render = true
        } else {
            this.getSectors()
        }
    },
    methods: {
        getSectors() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Sectors.getSectors(farm_id)
                .then(response => {
                    vm.sectorList = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.render = true
                })
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
