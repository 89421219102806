import { render, staticRenderFns } from "./SectorInformation.vue?vue&type=template&id=12230e05&lang=html&"
import script from "./SectorInformation.vue?vue&type=script&lang=js&"
export * from "./SectorInformation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports