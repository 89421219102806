import { ceres_api } from "@/axios-config.js"
import CopyObject from "@/utils/CopyObject.js"

const Prunings = {
    createPruning(farm_id, model) {
        let endpoint = "/farms/" + farm_id + "/prunings"
        return ceres_api.post(endpoint, model)
    },
    getPrunings(farm_id, filter) {
        let endpoint = "/farms/" + farm_id + "/prunings"
        if (filter == undefined) {
            return ceres_api.get(endpoint)
        } else {
            return ceres_api.post(endpoint + "/filter", { filter: filter })
        }
    },
    getPruning(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/prunings/"
        return ceres_api.get(endpoint + id)
    },
    deletePruning(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/prunings/"
        return ceres_api.delete(endpoint + id)
    },
    updatePruning(farm_id, id, model) {
        let endpoint = "/farms/" + farm_id + "/prunings/"
        return ceres_api.put(endpoint + id, model)
    },
    simplifyPruning(data) {
        let model = CopyObject.copy(data)
        model.sector_ids = model.sector_ids.map(i => i.$oid)
        model.worker_ids = model.worker_ids.map(i => i.$oid)
        delete model.sectors
        delete model.workers
        delete model.updated_at
        delete model.created_at
        return model
    }
}

export default Prunings
