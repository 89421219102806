<template lang="html">
  <irrigation-upkeep-form
    v-loading="loader"
    :record="model"
    :messages="messages"
    :run-validations="runValidations"
    @isValid="isValid">
    <div slot="actions">
      <process-controls
        v-if="Object.keys(model).length > 0"
        :record="model"
        :is-form="true"
        @action="validate"/>
    </div>
  </irrigation-upkeep-form>
</template>

<script>
import IrrigationUpkeepForm from "@/views/Labors/IrrigationUpkeeps/Components/IrrigationUpkeepStartForm"
import ProcessControls from "@/views/TaskList/Components/ProcessControls"

export default {
    components: {
        IrrigationUpkeepForm,
        ProcessControls
    },
    props: {
        variables: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            loader: false,
            messages: {},
            model: {},
            decision: "",
            runValidations: 1
        }
    },
    created() {
        this.$emit(
            "setTitle",
            "Detalles del mantenimiento del sistema de riego programado"
        )
        let model = this.$COPY.copy(this.variables)
        model.irrigation_upkeep_labors = model.irrigation_upkeep_labors.map(
            i => i.labor_id.$oid
        )

        model.irrigation_upkeep_sectors = model.irrigation_upkeep_sectors.reduce(
            (out, i) => {
                i.sector_id = i.sector_id.$oid
                out.push(i)
                return out
            },
            []
        )

        if (model.hasOwnProperty("irrigation_upkeep_parameters")) {
            model.irrigation_upkeep_parameters = model.irrigation_upkeep_parameters.reduce(
                (out, i) => {
                    i.labor_id = i.labor_id.$oid
                    i.parameter_id = i.parameter_id.$oid
                    out.push(i)
                    return out
                },
                []
            )
        }

        model.sector_ids = model.sector_ids.map(i => i.$oid)

        model.farm_id = model.farm_id.$oid

        this.model = this.$COPY.copy(model)
    },
    methods: {
        validate(decision) {
            this.decision = decision
            this.messages = {}
            this.runValidations += 1
        },
        isValid(result, newModel) {
            this.model = this.$COPY.copy(newModel)
            result ? this.submit() : (this.loader = false)
        },
        submit() {
            this.$emit(
                "completeTask",
                this.$route.params.processKey,
                this.$route.params.id,
                this.model,
                this.decision
            )
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
