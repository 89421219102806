<template lang="html">
  <card>
    <template slot="header">
      <card-controls :back="false">
        <span slot="actions">
          <n-button
            v-if="$store.getters['Auth/IS_ADMIN']"
            type="primary"
            simple=""
            @click.native="$router.push('/dashboard/farm/new')"
          >
            <i class="fas fa-plus-circle" />
            Nuevo Campo
          </n-button>
        </span>
      </card-controls>

      <h4 class="card-title"><b>Mis campos</b></h4>
    </template>

    <div class="row">
      <div v-for="(farm, index) in farms" :key="index" class="col-md-4 mb-3">
        <el-card shadow="hover">
          <h6 class="card-subtitle mb-2 text-muted">{{ farm.name }}</h6>
          <div class="card-text">
            <n-button type="info" simple="" @click.native="view(farm._id.$oid)">
              Administrar
            </n-button>
          </div>
        </el-card>
      </div>
    </div>
  </card>
</template>

<script>
import { Card } from "element-ui";

export default {
  components: {
    [Card.name]: Card,
  },
  data() {
    return {
      loaders: {
        farms: true,
      },
      farms: [],
    };
  },
  created() {
    let vm = this;

    this.Services.Farms.getFarms()
      .then((response) => {
        vm.farms = response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        vm.loaders.farms = false;
      });
  },
  methods: {
    view(farm_id) {
      this.$router.push("/dashboard/farm/view/" + farm_id);
    },
  },
};
</script>

<style lang="css"></style>
