import Vue from "vue"
import { ceres_api } from "@/axios-config.js"

const Auth = {
    login(credentials) {
        Vue.cookie.delete("ceres")
        return ceres_api.post("/auth/login", credentials)
    }
}

export default Auth
