<template>
  <div class="text-left">
    <fg-input :error="errors.first('assignee')" :label="label" required>
      <el-select
        v-validate="{ required: true }"
        v-if="users"
        :value="value"
        :data-vv-as="label"
        :placeholder="label"
        name="assignee"
        class="select-primary"
        filterable
        @change="$emit('change', $event)"
      >
        <el-option
          v-for="user in users"
          :key="user._id.$oid"
          :value="user._id.$oid"
          :label="user.full_name"
          class="select-danger"
        />
      </el-select>
    </fg-input>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    value: { type: String },
    label: {
      type: String,
      default: "Responsable",
    },
  },
  model: {
    event: "change",
    prop: "value",
  },
  computed: {
    ...mapState("Users", ["users"]),
  },
};
</script>
