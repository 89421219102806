import Harvests from "./Harvests";
import FarmHarvests from "./FarmHarvests";
import ProductApplications from "./ProductApplications";
import Processes from "./Processes";
import Auth from "./Auth";
import Farms from "./Farms";
import Settings from "./Settings";
import Users from "./Users";
import Roles from "./Roles";
import Sectors from "./Sectors";
import Workers from "./Workers";
import Warehouses from "./Warehouses";
import Products from "./Products";
import Files from "./Files";
import PlagueMonitorings from "./PlagueMonitorings";
import IrrigationUpkeeps from "./IrrigationUpkeeps";
import Prunings from "./Prunings";
import Polinizations from "./Polinizations";
import Weedings from "./Weedings";
import PhenologicalTrackings from "./PhenologicalTrackings";
import LabReports from "./LabReports";
import Reports from "./Reports";
import Inventories from "./Inventories";
import Purchases from "./Purchases";
import PackageFormats from "./PackageFormats";
import WrapMaterials from "./WrapMaterials";
import Sales from "./Sales";
import Changelogs from "./Changelogs";
import Content from "./Content";
import Contact from "./Contact";
import Utils from "./Utils";

const Services = {
  Harvests,
  FarmHarvests,
  ProductApplications,
  Processes,
  Auth,
  Farms,
  Settings,
  Users,
  Roles,
  Sectors,
  Workers,
  Warehouses,
  Products,
  Files,
  PlagueMonitorings,
  IrrigationUpkeeps,
  Prunings,
  Polinizations,
  Weedings,
  PhenologicalTrackings,
  LabReports,
  Reports,
  Inventories,
  Purchases,
  PackageFormats,
  WrapMaterials,
  Sales,
  Changelogs,
  Content,
  Contact,
  Utils,
};

export default Services;
