import { mapGetters } from "vuex"

const getSectors = {
    data() {
        return {
            sectors: []
        }
    },
    computed: mapGetters({ farm_id: "Farms/GET_FARM_ID" }),
    created() {
        this.getSectors()
    },
    methods: {
        async getSectors() {
            let vm = this
            try {
                const response = await this.Services.Sectors.getSectors(
                    this.farm_id
                )
                vm.sectors = response.data
            } catch (error) {
                console.log(error)
            }
        }
    }
}

export default getSectors
