<template lang="html">
  <div>
    <card v-loading="loader">
      <template slot="header">
        <card-controls :back="true" back-target-name="harvests">
          <span slot="actions">
            <n-button type="primary" simple="" @click.native="goToDiagram">
              <i class="fal fa-project-diagram" />
            </n-button>
            <template v-if="Object.keys(model).length > 0">
              <n-button
                v-if="manageControls"
                type="primary"
                simple=""
                @click.native="components.setAssignee = true"
              >
                <i class="fal fa-user" />
              </n-button>
            </template>
            <n-button
              type="primary"
              simple=""
              @click.native="goToWorkflowHistory"
            >
              <i class="fal fa-history" />
            </n-button>
          </span>
        </card-controls>

        <h4 v-if="Object.keys(model).length > 0" class="card-title">
          <b>Detalle de la Cosecha ({{ $t(model.workflow.state) }})</b>
        </h4>
      </template>

      <harvest-details :key="key" @updateModel="updateModel">
        <template slot="actions">
          <log-process-controls
            v-if="Object.keys(model).length > 0"
            :record="model"
            process-key="harvest"
            @delete="deleteHarvest"
          />
        </template>
      </harvest-details>

      <set-assignee
        v-if="components.setAssignee"
        :task-id="$route.params.id"
        :assignee="model.workflow.assignee.$oid"
        process-key="harvest"
        @updateTaskList="getHarvest"
        @close="components.setAssignee = false"
      />

      <div v-if="messages.length > 0" class="col-md-12 text-left">
        <notification
          v-for="(message, key) in messages"
          :key="key"
          :message="message.message"
          :type="message.type"
          :close="true"
        />
      </div>
    </card>
  </div>
</template>

<script>
import HarvestDetails from "./Components/HarvestDetailsView";
import LogProcessControls from "@/views/Labors/Components/LogProcessControls";
import SetAssignee from "@/views/TaskList/Components/SetAssignee";
import Swal from "sweetalert2";

export default {
  components: {
    HarvestDetails,
    LogProcessControls,
    SetAssignee,
  },
  data() {
    return {
      title: "Detalle de la Cosecha",
      loader: true,
      key: 1,
      farm: undefined,
      components: {
        setAssignee: false,
      },
      model: {},
      messages: {},
    };
  },
  computed: {
    manageControls() {
      return this.$HELPERS.showOptionalControls(
        this.model.workflow.starter.$oid
      );
    },
  },
  created() {
    this.farm = this.$store.getters["Farms/GET_FARM"];
  },
  methods: {
    getHarvest() {
      this.loader = true;
      this.key += 1;
    },
    updateModel(newModel) {
      this.model = this.$COPY.copy(newModel);
      this.loader = false;
    },
    deleteHarvest() {
      let vm = this;
      vm.messages = [];
      this.loader = true;

      this.Services.Harvests.deleteHarvest(
        this.farm._id.$oid,
        this.$route.params.id
      )
        .then((response) => {
          if (response.status == 200) {
            vm.loader = false;
            Swal.fire({
              title: response.data.messages,
              icon: "success",
            }).then((result) => {
              vm.$router.push({ name: "harvests" });
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
          vm.processMessages(error.response.data, "danger");
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    goToDiagram() {
      this.$router.push({
        name: "diagram",
        params: {
          processKey: "harvest",
          id: this.$route.params.id,
          state: this.model.workflow.state,
          customBackTarget: {
            name: "view-harvest",
            params: {
              id: this.$route.params.id,
            },
          },
        },
      });
    },
    goToWorkflowHistory() {
      this.$router.push({
        name: "workflow-history",
        params: {
          id: this.$route.params.id,
          processKey: "harvest",
          record: this.model,
        },
      });
    },
  },
};
</script>

<style lang="css"></style>
