<template>
  <div id="login">
    <card v-loading="loaders.login" class="card-login card-plain">
      <div slot="header">
        <div class="logo-container">
          <img src="@/assets/img/ceres_logo.png" />
        </div>
      </div>

      <div>
        <form name="auth" method="post" @submit.prevent>
          <fg-input
            v-model="model.email"
            name="email"
            class="no-border form-control-lg"
            placeholder="Email"
            tabindex="1"
            type="text"
            autofocus
            addon-left-icon="now-ui-icons users_circle-08"
          />

          <fg-input
            v-model="model.password"
            name="password"
            class="no-border form-control-lg"
            placeholder="Contraseña"
            addon-left-icon="now-ui-icons objects_key-25"
            tabindex="2"
            type="password"
          />

          <notification
            v-if="message"
            :message="'Usuario y/o contraseña incorrectos'"
            :type="'danger'"
          />
          <n-button
            type="primary"
            round
            block
            tabindex="3"
            @click.native="login"
          >
            Iniciar sesión
          </n-button>
        </form>
      </div>

      <!-- <div slot="footer">
        <div class="pull-left">
          <h6><a
            href="#"
            class="link footer-link">Recuperar contraseña</a></h6>
        </div>
        <div class="pull-right">
          <h6><a
            href="#"
            class="link footer-link">¿Necesitas ayuda?</a></h6>
        </div>
      </div> -->
    </card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      message: false,
      loaders: {
        login: false,
      },
      model: {
        email: "",
        password: "",
      },
    };
  },
  created() {
    if (this.$router.history.current.query.status == "unauthorized") {
      this.message = true;
    }
  },
  methods: {
    login() {
      this.message = false;
      this.loaders.login = true;
      let vm = this;
      let credentials = {
        email: this.model.email,
        password: this.model.password,
      };
      this.Services.Auth.login(credentials)
        .then((response) => {
          sessionStorage.setItem("user", JSON.stringify(response.data));
          vm.$store.commit("Auth/SET_USER", response.data);
          vm.$cookie.set("ceres", response.data.token, {
            expires: response.data.exp,
          });
          vm.setFarm();
        })
        .catch((error) => {
          console.log(error);
          vm.stopLogin();
        });
    },
    stopLogin() {
      this.message = true;
      this.loaders.login = false;
    },
    setFarm() {
      let vm = this;
      this.Services.Farms.getFarms()
        .then((response) => {
          let currentFarm = localStorage.getItem("farm");

          try {
            currentFarm = JSON.parse(currentFarm);
          } catch (e) {
            // empty
          }

          if (currentFarm == undefined || currentFarm == "undefined") {
            currentFarm = response.data[0];
          } else {
            currentFarm = response.data.find(
              (i) => i._id.$oid == currentFarm._id.$oid
            );
            if (currentFarm == undefined) currentFarm = response.data[0];
          }
          localStorage.setItem("farm", JSON.stringify(currentFarm));
          vm.$store.commit("Farms/SET_FARM", currentFarm);
        })
        .catch((error) => {
          console.log(error);
          vm.stopLogin();
        })
        .finally(() => {
          vm.getSettings();
        });
    },
    getSettings() {
      let vm = this;
      this.Services.Settings.getSettings()
        .then((response) => {
          vm.$store.commit(
            "Settings/SET_SETTINGS",
            vm.$HELPERS.compressSettings(response.data)
          );
        })
        .catch((error) => {
          console.log(error);
          vm.stopLogin();
        })
        .finally(() => {
          vm.loaders.login = false;
          vm.$router.push({
            name: "tasklist",
            params: { view: "list-view" },
          });
        });
    },
  },
};
</script>
<style lang="scss">
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
#login {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
