<template lang="html">
  <modal
    :show.sync="modal"
    header-classes="justify-content-center"
    @close="$emit('close')">

    <h4
      slot="header"
      class="title title-up">Calculadora de Polinización</h4>

    <polinization-calc-form :calc-mode="true"/>

  </modal>
</template>

<script>
import Modal from "nowui/components/Modal"
import PolinizationCalcForm from "@/views/Labors/Polinizations/PolinizationForm"

export default {
    components: {
        Modal,
        PolinizationCalcForm
    },
    data() {
        return {
            modal: true
        }
    }
}
</script>

<style lang="css" scoped>
</style>
