<template lang="html">
  <el-card
    shadow="hover"
    class="mt-3">
    <div slot="header">
      <h6 class="text-left">{{ sector.name }}</h6>
    </div>
    <div v-if="sector.subsectors.length == 0"/>

    <sector-information
      v-if="sector.subsectors.length == 0"
      :sector="sector"
      :farm="farm"
      @sectorUpdated="$emit('sectorUpdated')"/>
    <sector-information
      v-for="(subsector,key) in sector.subsectors"
      v-else
      :key="key"
      :farm="farm"
      :sector="subsector"
      :subsector="true"
      class="subsectors"
      @sectorUpdated="$emit('sectorUpdated')" />
  </el-card>
</template>

<script>
import { Card } from "element-ui"
import SectorInformation from "./SectorInformation"
import Modal from "nowui/components/Modal"

export default {
    components: {
        [Card.name]: Card,
        SectorInformation,
        Modal
    },
    props: {
        farm: {
            type: Object,
            required: true
        },
        sector: {
            type: Object,
            default: () => {
                return {}
            }
        },
        sectors: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            modals: {
                editSector: false
            },
            components: {
                editSector: false
            }
        }
    },
    computed: {
        computedSpecie() {
            let specie = ""
            if (this.$SPECIES.data[this.sector.specie] != undefined)
                specie = this.$SPECIES.data[this.sector.specie].name
            return specie
        }
    },
    created() {},
    methods: {
        updateSector() {
            this.modals.editSector = false
            this.$emit("farmUpdate")
        }
    }
}
</script>

<style lang="sass">
	.subsectors
		padding: 20px 0px
		border-bottom: 1px solid rgba(0, 0, 0, 0.1)
</style>
