<template lang="html">
  <div
    v-loading="loader"
    class="row">
    <div
      v-if="!loader"
      class="col-md-12 text-left">
      <div class="row">
        <div class="col md-12">
          <h5>Definiciones generales</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <fg-input
            v-validate="'required'"
            v-model="model.name"
            :error="errors.first('name')"
            name="name"
            data-vv-as="Nombre del formato"
            type="text"
            placeholder="Nombre del formato"
            label="Nombre del formato"
            required/>
        </div>
        <div class="col-md-6">
          <fg-input
            v-validate="'required'"
            v-model="model.code"
            :error="errors.first('code')"
            name="code"
            data-vv-as="Código del formato"
            type="text"
            placeholder="Código del formato"
            label="Código del formato"
            required/>
        </div>
        <div class="col-md-6">
          <fg-input
            :error="errors.first('allowed_raw_materials')"
            label="Materias primas posibles"
            required>
            <el-select
              v-validate="{required: true}"
              v-model="model.allowed_raw_materials"
              :disabled="edit_mode"
              data-vv-as="Materia prima posibles"
              placeholder="Seleccione las materias primas posibles"
              class="select-primary"
              name="allowed_raw_materials"
              multiple
              filterable>
              <el-option
                v-for="(raw_material, index) in raw_materials"
                :key="index"
                :label="raw_material.specie_name + ' ' + raw_material.variety_name"
                :value="raw_material.variety"
                class="select-danger">
                {{ raw_material.specie_name }} {{ raw_material.variety_name }}
              </el-option>
            </el-select>
          </fg-input>
        </div>
        <div class="col-md-6">
          <fg-input
            v-validate="{ required: true, min_value: 0.1, decimal: true }"
            :error="errors.first('qty_per_format')"
            v-model.number="model.qty_per_format"
            :label="$t(yield_type+'_qty_per_format')"
            :placeholder="$t(yield_type+'_qty_per_format')"
            :data-vv-as="$t(yield_type+'_qty_per_format')"
            :disabled="edit_mode"
            name="qty_per_format"
            type="number"
            min="1"
            step="0.1"
            required/>
        </div>
        <div class="col-md-6">
          <fg-input
            v-validate="{ required: true, min_value: 0, decimal: false }"
            :error="errors.first('lifetime')"
            v-model.number="model.lifetime"
            :disabled="edit_mode"
            name="lifetime"
            type="number"
            min="0"
            step="1"
            label="Vida útil del formato (días)"
            placeholder="Vida útil del formato (días)"
            data-vv-as="Vida útil del formato (días)"
            required/>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col md-12">
          <h5>Especificación de características</h5>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <el-table
            :data="model.package_characteristics"
            empty-text="Aún no se han definido características para este formato">
            <el-table-column
              label="Característica">
              <div slot-scope="scope">
                <fg-input
                  v-validate="'required'"
                  v-model="model.package_characteristics[scope.$index].characteristic"
                  :name="'characteristic_'+scope.$index"
                  :error="errors.first('characteristic_'+scope.$index)"
                  data-vv-as="Característica"
                  type="text"
                  placeholder="Característica"
                  required/>
              </div>
            </el-table-column>
            <el-table-column
              label="Valor">
              <div slot-scope="scope">
                <fg-input
                  v-validate="'required'"
                  v-model="model.package_characteristics[scope.$index].value"
                  :name="'characteristic_value_'+scope.$index"
                  :error="errors.first('characteristic_value_'+scope.$index)"
                  data-vv-as="Valor"
                  type="text"
                  placeholder="Valor"
                  required/>
              </div>
            </el-table-column>
            <el-table-column
              label="Acciones"
              align="center"
              header-align="center"
              width="100">
              <template slot-scope="scope">
                <n-button
                  type="danger"
                  size="sm"
                  simple
                  @click.native="rmItem(scope.$index)">
                  <i class="fal fa-trash"/>
                </n-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div
          class="col-md-12 text-right">
          <n-button
            simple=""
            size="sm"
            type="primary"
            @click.native="addItem()">
            <i class="fas fa-plus-circle" />
          </n-button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-left">
          <fg-input
            v-model="model.additional_description"
            type="text"
            label="Descripción adicional"/>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12 text-center">
          <slot name="actions"/>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <notification
            :messages="messages"
            :close="true"
            type="error"/>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    props: {
        runValidations: {
            type: Number,
            default: 1
        },
        messages: {
            type: Object,
            default: () => {
                return {}
            }
        },
        record: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            loader: true,
            raw_materials: [],
            yield_type: undefined,
            edit_mode: false,
            model: {
                farm_id: undefined,
                code: undefined,
                name: undefined,
                allowed_raw_materials: [],
                additional_description: undefined,
                qty_per_format: undefined,
                lifetime: undefined,
                package_characteristics: []
            }
        }
    },
    watch: {
        runValidations: function(newValue, oldValue) {
            this.validate()
        }
    },
    created() {
        this.initModel()
    },
    methods: {
        initModel() {
            this.yield_type = this.$store.getters["Farms/GET_YIELD_TYPE"]

            if (Object.keys(this.record).length > 0) {
                this.model = this.$COPY.copy(this.record)
                this.edit_mode = true
            }

            if (this.model.farm_id == undefined) {
                this.model.farm_id = this.$store.getters[
                    "Farms/GET_FARM"
                ]._id.$oid
            }
            this.getRawMaterials()
        },
        getRawMaterials() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.PackageFormats.getRawMaterials(farm_id)
                .then(response => {
                    vm.raw_materials = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        rmItem(index) {
            this.model.package_characteristics.splice(index, 1)
        },
        addItem() {
            this.model.package_characteristics.push({
                characteristic: "",
                value: ""
            })
        },
        validate() {
            let vm = this
            this.$validator.validateAll().then(isValid => {
                let model = vm.$COPY.copy(vm.model)
                model.allowed_raw_materials = model.allowed_raw_materials.map(
                    i => {
                        return { variety: i }
                    }
                )
                vm.$emit("isValid", isValid, model)
            })
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
