<template>
  <div>
    <calendar :date.sync="date" />
    <calendar
      v-if="secondCalendar"
      :compare-mode="true"
      :date="date"
      class="mt-5"
    />
    <n-button
      :type="secondCalendar ? 'danger' : 'primary'"
      class="pull-right"
      simple=""
      @click.native="toggleComparison"
    >
      <i
        :class="[
          'fal',
          { 'fa-columns': !secondCalendar },
          { 'fa-times-circle': secondCalendar },
          'me-1',
        ]"
      />
      {{
        secondCalendar
          ? "Cerrar calendario adicional"
          : "Comparar con año pasado"
      }}
    </n-button>
  </div>
</template>

<script>
import Calendar from "./Components/CalendarView";

export default {
  components: {
    Calendar,
  },
  data() {
    return {
      date: "",
      secondCalendar: false,
    };
  },
  created() {
    this.$emit("setTitle", "Vista Calendario");
  },
  // computed:{
  //     secondDateCalendar(){
  //
  //         return this.$moment(this.date).subtract(1,"year").toISOString()
  //     }
  // },
  methods: {
    toggleComparison() {
      this.secondCalendar = !this.secondCalendar;
    },
  },
};
</script>

<style>
</style>
