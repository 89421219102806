<template lang="html">
  <div
    v-loading="Object.keys(model).length == 0"
    class="col-md-12">

    <harvest-form
      v-if="Object.keys(model).length > 0"
      :record="model"
      @modelUpdated="updateModel">

      <div slot="actions">
        <process-controls
          v-if="Object.keys(model).length > 0"
          :record="model"
          @action="submit"/>
      </div>
    </harvest-form>

  </div>
</template>

<script>
import HarvestForm from "@/views/Labors/Harvests/Components/HarvestForm"
import ProcessControls from "@/views/TaskList/Components/ProcessControls"

export default {
    components: {
        HarvestForm,
        ProcessControls
    },
    props: {
        variables: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            model: {}
        }
    },
    created() {
        this.$emit("setTitle", "Detalles de la cosecha programada")
        this.model = this.$COPY.copy(this.variables)
    },
    methods: {
        updateModel(newModel) {
            this.model = this.$COPY.copy(newModel)
        },
        submit(decision) {
            this.$emit(
                "completeTask",
                "harvest",
                this.$route.params.id,
                this.model,
                decision
            )
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
