<template>
  <footer class="app-info">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <div class="copyright">
        &copy; {{ year }}, Desarrollado por
        <a href="http://www.dynamiclab.cl/">DynamicLab</a>.
        <br>
        Version {{ $store.getters["GET_VERSION"] }}. <span @click="$router.push('/changelogs')">Ver registro de cambios</span>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
    data() {
        return {
            year: new Date().getFullYear()
        }
    },
    created() {}
}
</script>
<style lang="sass">
	.app-info
		margin-top: 50px
		text-align: center
		font-size: 12px
		// position: absolute
		// bottom: 50px
		display: flex
		justify-content: flex-end
		// a
		// 	color: white !important
		span
			cursor: pointer
			font-weight: bold
			// color: rgb(44, 44, 44)
			color: white
	// 	span:hover
	// 		color: black
	// 		text-decoration: underline
	// 	.copyright
	// 		color: rgb(44, 44, 44)
</style>
