<template lang="html">
  <div v-loading="loader" :element-loading-text="loadingText">
    <component
      v-if="components.GenericForm"
      ref="formsChild"
      :is="formKey"
      :form-key="formKey"
      :process-key="processKey"
      :start-variables="startVariables"
      :variables="variables"
      :task-id="taskId"
      :is-start-form="isStartForm"
      @setLoader="loader = $event"
      @setLoadingText="loadingText = $event"
      @newTask="newTask"
      @setTitle="setTitle"
      @setSubtitle="setSubtitle"
      @setBackText="setBackText"
      @setBackCustomTarget="setBackCustomTarget"
      @completeTask="completeTask"
    />

    <div v-if="messages.length > 0" class="col-md-12 text-left">
      <notification
        v-for="(message, key) in messages"
        :key="key"
        :message="message.message"
        :type="message.type"
        :close="true"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import * as ProcessForms from "./ProcessForms";
import Swal from "sweetalert2";
import { Alert } from "element-ui";
import pluralize from "pluralize";

export default Vue.component("GenericForm", {
  components: {
    [Alert.name]: Alert,
    // Add forms for every process
    ...ProcessForms.ProductApplications,
    ...ProcessForms.Harvests,
    ...ProcessForms.Weedings,
    ...ProcessForms.Polinizations,
    ...ProcessForms.Prunings,
    ...ProcessForms.PlagueMonitoring,
    ...ProcessForms.IrrigationUpkeeps,
    ...ProcessForms.PhenologicalTrackings,
    ...ProcessForms.Purchases,
    ...ProcessForms.Inventories,
    ...ProcessForms.WrapMaterials,
    ...ProcessForms.Sales,
    ...ProcessForms.LabReports,
    ...ProcessForms.FarmHarvests,
  },
  props: {
    processKey: {
      type: String,
      default: undefined,
    },
    formKey: {
      type: String,
      default: undefined,
    },
    taskId: {
      type: String,
      default: undefined,
    },
    startVariables: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isStartForm: {
      type: Boolean,
    },
  },
  data() {
    return {
      title: "Iniciar una nueva actividad",
      variables: undefined,
      loader: false,
      loadingText: "",
      components: {
        GenericForm: false,
      },
      messages: [],
      decision: "",
      model_date: "",
    };
  },
  computed: {
    normalizeProcessKey() {
      return this.$options.filters.normalizeKey(this.processKey);
    },
  },
  watch: {
    "$route.params.id": function (newValue, oldValue) {
      this.$emit("newTask");
    },
  },
  mounted() {
    if (this.taskId != undefined) {
      this.loadVariables();
    } else {
      this.components.GenericForm = true;
    }
  },
  methods: {
    loadVariables: function () {
      let vm = this;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Processes.getVariables(
        farm_id,
        this.$route.params.processKey,
        this.$route.params.id
      )
        .then((response) => {
          vm.variables = response.data;
          vm.$emit("variables", vm.variables);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.components.GenericForm = true;
        });
    },
    setTitle(title) {
      this.$emit("setTitle", title);
    },
    setSubtitle(text) {
      this.$emit("setSubtitle", text);
    },
    setBackText(value) {
      this.$emit("setBackText", value);
    },
    setBackCustomTarget(value) {
      this.$emit("setBackCustomTarget", value);
    },
    newTask(task) {
      this.$emit("newTask", task);
    },
    completeTask(processKey, id, model, decision) {
      let vm = this;
      vm.loader = true;
      vm.messages = [];
      vm.decision = decision;
      vm.model_date = model.date;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Processes.completeTask(
        farm_id,
        processKey,
        id,
        model,
        decision
      )
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          }).then((response) => {
            const back = {
              name: "records-view",
              params: {
                processKey: pluralize(processKey),
              },
            };
            vm.$router.push(back);
          });
        })
        .catch((error) => {
          console.log(error.response);
          vm.processMessages(error.response.data, "error");
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
});
</script>

<style lang="sass"></style>
