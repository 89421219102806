<template lang="html">
  <div v-loading="loaders.warehouses" class="col-md-12">
    <div class="row section-header">
      <div class="col-md-6 text-left">
        <h5>
          <b>{{ title }}</b>
        </h5>
      </div>
      <div class="col-md-6 text-right">
        <n-button
          v-if="$store.getters['Auth/IS_ADMIN']"
          type="primary"
          @click.native="newWarehouse()"
        >
          <i class="fas fa-plus-circle" />
          Nueva Bodega
        </n-button>
      </div>
    </div>

    <div class="row">
      <div v-if="warehouses.length == 0" class="col-lg-12 no-data">
        No hay bodegas registradas
        <br />
        <br />
      </div>

      <div
        v-for="(warehouse, key) in warehouses"
        :key="key"
        class="col-lg-4 col-md-6 col-sm-12 warehouse"
      >
        <stats-card
          :title="warehouse.name"
          :sub-title="warehouse.used_for"
          type="warning"
          class="warehouse-card"
        >
          <span slot="icon">
            <i class="fas fa-warehouse-alt" />
          </span>
          <span v-if="$store.getters['Auth/IS_ADMIN']" slot="footer">
            <div class="row text-center">
              <div class="col-md-6 col-sm-4 col-xs-6">
                <n-button
                  size="sm"
                  type="danger"
                  simple
                  @click.native="showDeleteWarehouse(warehouse)"
                >
                  <i class="fas fa-trash" />
                  Eliminar
                </n-button>
                <br />
                <br />
              </div>
              <div class="col-md-6 col-sm-4 col-xs-6">
                <n-button
                  size="sm"
                  type="primary"
                  simple
                  @click.native="editWarehouse(warehouse)"
                >
                  <i class="fas fa-edit" />
                  Editar
                </n-button>
              </div>
            </div>
          </span>
        </stats-card>
      </div>
    </div>

    <!-- Warehouse Modal -->
    <modal
      :show.sync="modals.warehouse"
      header-classes="justify-content-center"
      class="warehouse-modal"
    >
      <h4 v-if="create" slot="header" class="title title-up">Nueva Bodega</h4>
      <h4 v-else slot="header" class="title title-up">Editar Bodega</h4>
      <warehouse
        v-if="modals.warehouse"
        ref="newWarehouse"
        :farm="farm"
        :warehouse="current_warehouse"
        @warehouseUpdated="getWarehouses"
        @warehouseAdded="getWarehouses"
      />

      <template slot="footer">
        <n-button simple @click.native="modals.warehouse = false"
          >Cerrar</n-button
        >
        <n-button
          v-if="create"
          type="primary"
          @click.native="$refs.newWarehouse.validate('create')"
          >Crear Bodega</n-button
        >
        <n-button
          v-if="!create"
          type="primary"
          @click.native="$refs.newWarehouse.validate('update')"
          >Actualizar Bodega</n-button
        >
      </template>
    </modal>

    <modal :show.sync="modals.deleteWarehouse" class="warehouse-modal-delete">
      <h4 slot="header" class="title title-up center">¡Cuidado!</h4>
      <div v-loading="loaders.deleteWarehouse">
        ¿Estás seguro de eliminar la bodega "{{ current_warehouse.name }}"?

        <br />
        <n-button
          class="big-btn"
          type="primary"
          @click.native="deleteWarehouse"
        >
          <div class="btn-label">Sí</div>
          <div class="btn-help">Quiero <br />eliminarla</div>
        </n-button>
        <n-button
          class="big-btn"
          type="primary"
          @click.native="modals.deleteWarehouse = false"
        >
          <div class="btn-label">No</div>
          <div class="btn-help">
            Quiero <br />
            eliminarla
          </div>
        </n-button>
      </div>
    </modal>
  </div>
</template>

<script>
import Warehouse from "./Warehouse";
import Modal from "nowui/components/Modal";
import { mapActions } from "vuex";

export default {
  components: {
    Modal,
    Warehouse,
  },
  props: {
    farm: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      title: "Bodegas",
      modals: {
        warehouse: false,
        deleteWarehouse: false,
      },
      loaders: {
        warehouses: true,
        deleteWarehouse: false,
      },
      warehouses: {},
      current_warehouse: {},
      farms: [],
      create: false,
    };
  },
  created() {
    this.getWarehouses();
  },
  methods: {
    ...mapActions("Farms", ["getCurrentFarmData"]),
    getWarehouses() {
      let vm = this;
      let farm_id = this.farm._id.$oid.toString();

      this.loaders.warehouses = true;
      this.modals.warehouse = false;
      this.modals.deleteWarehouse = false;

      this.Services.Warehouses.getWarehouses(farm_id)
        .then((response) => {
          vm.warehouses = response.data;
          vm.getCurrentFarmData();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.loaders.warehouses = false;
        });
    },
    newWarehouse() {
      this.current_warehouse = {};
      this.modals.warehouse = true;
      this.create = true;
    },
    editWarehouse(warehouse) {
      this.current_warehouse = warehouse;
      this.modals.warehouse = true;
      this.create = false;
    },
    showDeleteWarehouse(warehouse) {
      this.modals.deleteWarehouse = true;
      this.current_warehouse = warehouse;
    },
    deleteWarehouse() {
      let vm = this;
      let farm_id = this.farm._id.$oid.toString();
      let warehouse_id = this.current_warehouse._id.$oid.toString();

      this.loaders.deleteWarehouse = true;
      this.Services.Warehouses.deleteWarehouse({ farm_id, warehouse_id })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loaders.deleteWarehouse = false;
          this.modals.deleteWarehouse = false;
          vm.getWarehouses();
        });
    },
  },
};
</script>

<style lang="sass">
.warehouse-modal
  .modal-dialog
    max-width: 400px
.warehouse-modal-delete
  .modal-dialog
    max-width: 500px

.warehouse
  h3
    font-size: 18px
.warehouse-card
  .card-body
    min-height: 150px
</style>
