<template lang="html">
  <div>
    <lab-report-details
      v-if="viewMode"
      :model="variables"
      @setTitle="setTitle">

      <template slot="actions">
        <process-controls
          v-if="Object.keys(variables).length > 0"
          :record="variables"
          @action="processAction"/>
      </template>
    </lab-report-details>

    <lab-report-form
      v-if="!viewMode"
      ref="labReportForm"
      :record="model"
      :run-validations="runValidations"
      @isValid="validForm"
      @setTitle="setTitle"
      @setLoadingText="$emit('setLoadingText', $event)">

      <template slot="actions">
  <div v-if="showSaveButtons" class="flex-row">
    <n-button simple="" class="me-3" @click.native="viewMode = true">
      Cerrar
    </n-button>
    <n-button type="primary" @click.native="validate()">
      {{ saveText }}
    </n-button>
  </div>
  <process-controls
    v-else
    :record="variables"
    :is-form="true"
    @action="processAction"
  />
</template>

      <div slot="messages">
        <notification
          :messages="messages"
          :close="true"
          type="error"/>
      </div>
    </lab-report-form>

  </div>
</template>

<script>
import LabReportDetails from "@/views/Labors/LabReports/LabReportDetails";
import LabReportForm from "@/views/Labors/LabReports/LabReportForm";
import ProcessControls from "@/views/TaskList/Components/ProcessControls";
import { workflowHandler } from "@/mixins";

export default {
  components: {
    LabReportDetails,
    LabReportForm,
    ProcessControls,
  },
  mixins: [workflowHandler],
  data() {
    return {
      back_text: "Informes de Laboratorio",
      startMethod: this.Services.LabReports.createLabReport,
      statesWhichStarWithForm: ["new"],
    };
  },
};
</script>

<style lang="sass" scoped>
</style>
