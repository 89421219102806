<template lang="html">
  <cost-per-sector-calc-form
    ref="costPerSectorCalcForm"
    :init-model="initModel"
    :show-cost-per-unit="false"
    :total-value="totalCost"
    :messages="messages"
    default-cost-variable="num_of_plants"
    @modelUpdated="updateModel"
    @render="render = true">

    <span slot="atTheBeginning">
      <div
        v-if="!calcMode"
        class="row">
        <div class="col-md-6">
          <fg-input
            :error="errors.first('date')"
            class="has-label"
          >
            <label>Fecha de la polinización <span>*</span></label>
            <el-date-picker
              v-validate="{required: true}"
              v-model="model.date"
              type="date"
              placeholder="Seleccionar fecha"
              name="date"
              data-vv-as="Fecha de la polinización"/>
          </fg-input>
        </div>
        <div class="col-md-6">
          <fg-input
            :error="errors.first('assignee')"
            label="Responsable"
          >
            <el-select
              v-validate="{required: true}"
              v-model="model.workflow.assignee.$oid"
              name="assignee"
              class="select-primary"
              data-vv-as="Responsable"
              filterable>
              <el-option
                v-for="(user, key) in users"
                :value="user._id.$oid"
                :label="user.full_name"
                :key="key"
                class="select-danger" />
            </el-select>
          </fg-input>
        </div>
      </div>
    </span>

    <span slot="beforeTotalCost">
      <div class="row">
        <div class="col-md-6 text-left">
          <fg-input
            v-validate="'required|min_value:0'"
            v-model.number="model.flowers_per_plant"
            :error="errors.first('flowers_per_plant')"
            label="Flores por planta"
            data-vv-as="Flores por planta"
            placeholder="Flores por planta"
            name="flowers_per_plant"
            type="number"
            required/>
        </div>

        <div class="col-md-6 text-left mb-auto mt-auto pt-3 text-muted">
          = {{ totalFlowers | toLocaleStringNumber }} flores totales
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12 text-left">
          <div class="form-group has-label">
            <label>Detalle de colmenas <span>*</span></label>
          </div>
          <div
            v-for="(hive, key) in model.hives"
            :key="key"
            class="row">
            <div class="col-md-3 text-left">
              <fg-input
                v-validate="'required'"
                v-model="hive.name"
                :name="'name'+key"
                :error="errors.first('name'+key)"
                data-vv-as="Identificador"
                type="text"
                placeholder="Identificador"
                label="Identificador"
                required/>
            </div>
            <div class="col-md-3 text-left">
              <fg-input
                v-validate="'required|min_value:1'"
                v-model.number="hive.flower_capacity"
                :error="errors.first('flower_capacity'+key)"
                :name="'flower_capacity'+key"
                label="Capacidad de flores"
                data-vv-as="Capacidad de flores"
                placeholder="Capacidad de flores"
                type="number"
                required/>
            </div>
            <div class="col-md-3 text-left">
              <fg-input
                v-validate="'required|min_value:1'"
                v-model.number="hive.cost_per_hive"
                :error="errors.first('cost_per_hive'+key)"
                :name="'cost_per_hive'+key"
                label="$/Colmena (sin IVA)"
                data-vv-as="$/Colmena (sin IVA)"
                placeholder="CLP sin IVA"
                type="number"
                required/>
            </div>
            <div class="col-md-3 text-left">
              <fg-input
                v-validate="'required|min_value:1'"
                v-model.number="hive.qty"
                :error="errors.first('qty'+key)"
                :name="'qty'+key"
                label="Cantidad"
                data-vv-as="Cantidad"
                placeholder="Cantidad"
                type="number"
                required/>
            </div>

          </div>
        </div>
        <div class="col-md-12 text-right used-product-controls">
          <i
            v-if="model.hives.length > 1"
            class="fal fa-minus-circle used-product-control"
            @click="rmHive"/>
          <i
            class="fal fa-plus-circle used-product-control"
            @click="addHive"/>
        </div>
        <div class="col-md-12 text-left">
          <n-progress
            :value="flowersCoverage.percentage"
            :type="flowersCoverage.type"
            label="Cubrimiento de flores"
            name="progress"
            show-value=""/>
        </div>
      </div>
    </span>

    <span slot="actions">
      <slot name="actions"/>
    </span>
  </cost-per-sector-calc-form>

</template>

<script>
import CostPerSectorCalcForm from "@/views/Tools/CostPerSectorCalc/CostPerSectorCalcForm"
import { Progress } from "nowui/components"

export default {
    components: {
        [Progress.name]: Progress,
        CostPerSectorCalcForm
    },
    props: {
        runValidation: {
            type: Boolean,
            default: false
        },
        initModel: {
            type: Object,
            default: () => {
                return {}
            }
        },
        messages: {
            type: Object,
            default: () => {
                return {}
            }
        },
        calcMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            users: [],
            model: {
                date: "",
                cost_variable: "num_of_plants",
                flowers_per_plant: 0,
                total_flowers: 0,
                work_coverage: 0,
                hives: [
                    {
                        name: "",
                        flower_capacity: "",
                        cost_per_hive: "",
                        qty: ""
                    }
                ],
                workflow: {
                    assignee: {
                        $oid: ""
                    }
                }
            }
        }
    },
    computed: {
        totalFlowers() {
            return this.model.work_coverage * this.model.flowers_per_plant
        },
        flowersCoverage() {
            let output = {
                percentage: 0,
                type: "success"
            }
            let flowersCoverage = this.model.hives.reduce((sum, i) => {
                return sum + i.flower_capacity * i.qty
            }, 0)
            let total_flowers = this.totalFlowers
            let percentage = 0
            if (total_flowers > 0 && flowersCoverage > 0) {
                percentage = (flowersCoverage / total_flowers) * 100
                if (percentage > 100) {
                    output.percentage = 100
                    output.type = "danger"
                } else {
                    output.percentage = percentage.toFixed(2) * 1.0
                }
            }
            return output
        },
        totalCost() {
            let totalCost = this.model.hives.reduce((sum, i) => {
                return sum + i.qty * i.cost_per_hive
            }, 0)
            if (totalCost > 0) {
                return totalCost
            } else {
                return 0
            }
        }
    },
    watch: {
        model: {
            handler: function() {
                let model = this.$COPY.copy(this.model)
                if (!model.hasOwnProperty("farm_id")) {
                    model.farm_id = this.$store.getters[
                        "Farms/GET_FARM"
                    ]._id.$oid
                }
                this.$emit("modelUpdated", model)
            },
            deep: true
        },
        runValidation: function(newValue, oldValue) {
            if (newValue) this.validateForm()
        }
    },
    created() {
        if (Object.keys(this.initModel).length > 0) {
            let vm = this
            Object.keys(this.initModel).forEach(key => {
                if (key in vm.model) vm.model[key] = this.initModel[key]
            })
        }
        this.getUsers()
    },
    methods: {
        getUsers() {
            let vm = this
            this.Services.Users.getUsers()
                .then(response => {
                    vm.users = response.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        addHive() {
            this.model.hives.push({
                flower_capacity: ""
            })
        },
        rmHive() {
            this.model.hives.pop()
        },
        updateModel(model) {
            this.model = Object.assign(this.model, model.calculator)
            this.$emit("modelUpdated", this.model)
        },
        validateForm() {
            let vm = this
            let validations = {
                polinizationForm: undefined,
                costPerSectorCalcForm: undefined
            }
            vm.$validator
                .validateAll()
                .then(isValid => {
                    validations.polinizationForm = isValid
                })
                .finally(() => {
                    secondValidation()
                })

            function secondValidation() {
                vm.$refs.costPerSectorCalcForm.$validator
                    .validateAll()
                    .then(isValid => {
                        validations.costPerSectorCalcForm = isValid
                    })
                    .finally(() => {
                        finalValidation()
                    })
            }

            function finalValidation() {
                let isValid = Object.values(validations).every(i => i)
                vm.$emit("validForm", isValid)
            }
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
