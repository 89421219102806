<template lang="html">
  <div class="col-md-12 pull-center desktop-table">
    <el-table
      :data="data"
      empty-text="No hay seguimientos de estado fenológico registrados"
      class="table-striped">

      <el-table-column
        v-if="showStatus"
        prop="status"
        label="Estado"
        align="center"
        name="status"
        column-key="status"
        class-name="table-category">
        <div
          slot-scope="{row}">
          <el-tag
            :closable="false"
            :close-transition="false"
            :type="$TASK_STATES.getColor(row.workflow.state)"
            size="small"
            class="used-product-tag">
            {{ $t(row.workflow.state) }}
          </el-tag>
        </div>
      </el-table-column>

      <el-table-column
        label="Última actualización"
        align="center"
        header-align="center"
        name="updated_at"
        sortable>
        <div
          slot-scope="{row}">
          <i class="el-icon-time"/> {{ row.updated_at | moment("DD-MM-YYYY") }} <br> ({{ row.updated_at | moment("YYYY-MM-DD") | daysFromToday("startOf") }})
        </div>
      </el-table-column>

      <el-table-column
        prop="customer_name"
        label="Cliente"
        align="center"/>

      <el-table-column
        label="Valor (IVA incl.)"
        align="center">

        <div
          slot-scope="{row}">
          {{ row.total | toLocaleStringNumber | currency }}
        </div>
      </el-table-column>

      <el-table-column
        v-if="showStockIndicator"
        label="Stock disponible"
        align="center">

        <div
          slot-scope="{row}">
          <i 
            v-if="row.sale_items.every(i => i['has_stock?'])" 
            class="fas fa-thumbs-up success"/>
          <i 
            v-else 
            class="fas fa-exclamation-circle danger"/>
        </div>
      </el-table-column>

      <el-table-column
        label="Acciones"
        align="center"
        class-name="table-category actions-column">

        <div
          slot-scope="{row}"
          class="text-center">
          <n-button
            type="primary"
            size="sm"
            @click.native="view(row)">
            <i class="fal fa-eye"/>
          </n-button>
        </div>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui"
import pluralize from "pluralize"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    props: {
        data: {
            type: Array,
            default: () => {
                return []
            }
        },
        showStatus: {
            type: Boolean,
            default: false
        },
        showStockIndicator: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        view(row) {
            this.$router.push({
                name: "continue-process",
                params: {
                    id: row._id.$oid,
                    processKey: pluralize.singular(
                        this.$route.params.processKey
                    ),
                    state: row.workflow.state
                }
            })
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
