<template lang="html">
  <section
    id="faq"
    class="section bg-gray">
    <div class="container">
      <header class="section-header">
        <small>F.A.Q</small>
        <h2 v-html="data.faq_title" />
        <hr >
        <span v-html="data.faq_description"/>
      </header>

      <div class="row gap-y">
        <div class="col-md-8 offset-md-2">
          <el-collapse accordion >
            <el-collapse-item
              v-for="(faq, index) in data.faqs"
              :key="index"
              :title="faq.question"
              :name="index">
              <div
                class="card-body text-left"
                v-html="faq.answer"/>
            </el-collapse-item>
          </el-collapse>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    methods: {
        showFaq(index) {
            return index == 0 ? "show" : ""
        }
    }
}
</script>

<style lang="css" scoped></style>
