<template lang="html">
  <card
    v-loading="loader">
    <template slot="header">
      <card-controls :back="false">
        <span slot="actions">
          <n-button
            type="primary"
            simple=""
            @click.native="$router.push({name: 'new-package-format'})">
            <i class="fas fa-plus-circle" />
            Crear formato de venta
          </n-button>
        </span>
      </card-controls>

      <h4 class="card-title"><b>Formatos de venta</b></h4>
    </template>

    <div
      v-if="!loader"
      class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <el-table
            :data="package_formats"
            empty-text="No se han definido formatos de venta aún">

            <el-table-column
              prop="code"
              label="Código"
              width="100"/>

            <el-table-column
              prop="name"
              label="Nombre del formato"/>

            <el-table-column
              label="Variedades permitidas y stock"
              align="left"
              header-align="center">
              <div slot-scope="{row}">
                <span
                  v-for="(raw_material,index) in row.allowed_raw_materials"
                  :key="row._id.$oid+'_'+index">
                  {{ raw_material.specie_name }} {{ raw_material.variety_name }}
                  ({{ raw_material.stock }} unidades = {{ raw_material.stock * row.qty_per_format }} {{ $store.getters["Farms/GET_YIELD_TYPE"] }})
                  <br>
                </span>
              </div>
            </el-table-column>

            <el-table-column
              label="Acciones"
              align="center"
              width="100">

              <div
                slot-scope="{row}"
                class="text-center">
                <n-button
                  type="primary"
                  size="sm"
                  @click.native="view(row)">
                  <i class="fal fa-eye"/>
                </n-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { Table, TableColumn } from "element-ui"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    data() {
        return {
            loader: true,
            package_formats: []
        }
    },
    created() {
        this.getPackageFormats()
    },
    methods: {
        getPackageFormats() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.PackageFormats.getPackageFormats(farm_id)
                .then(response => {
                    vm.package_formats = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        view(row) {
            this.$router.push({
                name: "package-format",
                params: {
                    id: row._id.$oid
                }
            })
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
