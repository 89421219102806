import { ceres_api } from "@/axios-config.js"

const Files = {
    getFile(file_id, resource) {
        let endpoint = "/files/" + file_id + "?resource=" + resource
        return ceres_api.get(endpoint)
    }
}

export default Files
