<template lang="html">
  <card v-loading="loader">
    <template slot="header">
      <card-controls :back="true" />

      <h4 class="card-title"><b>Editar Formato de Venta</b></h4>
    </template>

    <div v-if="render" class="col-md-10 offset-md-1">
      <div class="row">
        <div class="col-md-12">
          <package-format-form
            :record="model"
            :run-validations="runValidations"
            :messages="messages"
            @isValid="isValid"
          >
            <template slot="actions">
              <n-button
                type="danger"
                class="me-3"
                @click.native="confirmDelete()"
              >
                Eliminar
              </n-button>
              <n-button type="primary" @click.native="validate()">
                Guardar cambios
              </n-button>
            </template>
          </package-format-form>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import PackageFormatForm from "./PackageFormatForm";
import Swal from "sweetalert2";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    PackageFormatForm,
  },
  data() {
    return {
      loader: true,
      render: false,
      runValidations: 1,
      messages: {},
      model: {},
    };
  },
  created() {
    this.getPackageFormat();
  },
  methods: {
    getPackageFormat() {
      let vm = this;
      vm.render = false;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.PackageFormats.getPackageFormat(
        farm_id,
        this.$route.params.id
      )
        .then((response) => {
          let model = response.data;
          model.allowed_raw_materials = model.allowed_raw_materials.map(
            (i) => i.variety.$oid
          );
          vm.model = model;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 404) {
            vm.$router.push({ name: "not-found" });
          }
        })
        .finally(() => {
          vm.loader = false;
          vm.render = true;
        });
    },
    confirmDelete() {
      let vm = this;
      Swal.fire({
        title: "¿Estás seguro de eliminar este formato de venta?",
        text: "¡La accción no podrá revertirse!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No,<br/>Quiero conservarlo",
        confirmButtonText: "Si,<br/>Quiero eliminarlo",
        reverseButtons: true,
        focusCancel: true,
        cancelButtonClass: "primary",
        confirmButtonClass: "danger",
      }).then((result) => {
        if (result.value) vm.deleteRecord();
      });
    },
    deleteRecord() {
      let vm = this;
      vm.loader = true;
      vm.messages = {};
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.PackageFormats.deletePackageFormat(
        farm_id,
        this.model._id.$oid
      )
        .then((response) => {
          vm.$HELPERS.processServerMessage(response.data.messages).then(() => {
            vm.$router.push({ name: "package-formats" });
          });
        })
        .catch((error) => {
          console.log(error);
          vm.messages = error.response.data;
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    validate() {
      let vm = this;
      vm.loader = true;
      this.runValidations += 1;
    },
    isValid(isValid, model) {
      isValid ? this.updateRecord(model) : (this.loader = false);
    },
    updateRecord(model) {
      let vm = this;
      vm.loader = true;
      vm.messages = {};
      this.Services.PackageFormats.updatePackageFormat(
        model.farm_id.$oid,
        model
      )
        .then((response) => {
          vm.$HELPERS.processServerMessage(response.data.messages).then(() => {
            vm.getPackageFormat();
          });
        })
        .catch((error) => {
          console.log(error);
          vm.messages = error.response.data;
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped></style>
