import UsersActions from "../services/Users";

const Users = {
  namespaced: true,
  state: {
    users: [],
    loading: false,
  },
  mutations: {
    setUsers: (state, users) => {
      state.users = users.map((user) => {
        return {
          ...user,
          farm_ids: user.farm_ids.map((i) => i.$oid),
          workflow_ids: user.workflow_ids.map((i) => i.$oid),
        };
      });
    },
    setLoading: (state, loading) => (state.loading = loading),
  },
  getters: {
    getUserFullName: (state) => (id) => {
      let notFoundString = "";
      const user = state.users.find((i) => i._id.$oid == id);
      return user ? user.full_name : notFoundString;
    },
  },
  actions: {
    getUsers: async ({ commit }) => {
      try {
        commit("setLoading", true);
        const response = await UsersActions.getUsers();
        commit("setUsers", response.data);
      } catch (error) {
        console.log(error);
      } finally {
        commit("setLoading", false);
      }
    },
  },
};

export default Users;
