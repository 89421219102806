<template lang="html">
  <div class="row report-body">
    <div class="col-md-12">
      <div class="row global-filter">
        <div class="col-md-4 text-left">
          <fg-input
            :error="errors.first('date')"
            class="has-label"
          >
            <label>Rango de fechas <span>*</span></label>

          </fg-input>
          <el-date-picker
            v-model="payload.date_range"
            type="daterange"
            placeholder="Seleccionar fecha"
            name="date"
            data-vv-as="Rango de fechas"
            unlink-panels
            format="dd-MM-yyyy"
            start-placeholder="Inicio"
            range-separator="-"
            end-placeholder="Término"/>
        </div>
        <div class="col-md-8 text-right mt-auto mb-auto">
          <n-button
            type="primary"
            size="sm"
            @click.native="getReport()">
            <i class="fa fa-play me-1"/>
            Generar informe
          </n-button>
        </div>
      </div>

      <div
        v-loading="loader"
        class="row">
        <div
          v-if="!loader && data.length > 0"
          class="col-md-12">
          <div class="row mt-4">
            <div class="col-md-12 text-left">
              <h5>Resumen</h5>
            </div>
            <div class="col-md-12">
              <apex-charts
                :height="350"
                :options="cashflowSummaryChartData.options"
                :series="cashflowSummaryChartData.series"
                type="line"/>
            </div>
            <div class="col-md-12">
              <cashflow-table
                :data="data.filter(i => ['totals', 'balance'].includes(i.category))"
                :summary="true"/>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12 text-left">
              <h5>Egresos (Sin impuestos)</h5>
            </div>
            <div class="col-md-12">
              <cashflow-table
                :data="data.filter(i => i.category == 'outflows' && i.variable == 'sub_total')"
                :summary-row="data.filter(i => i.category == 'totals' && i.item == 'outflows' && i.variable == 'sub_total')"/>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12 text-left">
              <h5>Impuestos</h5>
            </div>
            <div class="col-md-12">
              <cashflow-table
                :data="data.filter(i => i.category == 'outflows' && i.variable == 'taxes')"
                :summary-row="data.filter(i => i.category == 'totals' && i.item == 'outflows' && i.variable == 'taxes')"/>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CashflowTable from "./Components/CashflowTable";
import ApexCharts from "vue-apexcharts";

export default {
  components: {
    CashflowTable,
    ApexCharts,
  },
  data() {
    return {
      loader: false,
      payload: {
        date_range: [],
      },
      data: [],
    };
  },
  computed: {
    cashflowSummaryChartData() {
      let payload = {};

      let outflows = this.data.find(
        (i) =>
          (i.category == "totals") &
          (i.item == "outflows") &
          (i.variable == "total")
      );
      let outflowsValues = Object.values(outflows).filter(
        (i) => typeof i == "number"
      );

      let incomes = this.data.find(
        (i) =>
          (i.category == "totals") &
          (i.item == "incomes") &
          (i.variable == "total")
      );
      let incomeValues = Object.values(incomes).filter(
        (i) => typeof i == "number"
      );

      let balanceCumsum = this.data.find(
        (i) =>
          (i.category == "balance") &
          (i.item == "balance.cumsum") &
          (i.variable == "total")
      );
      let balanceCumsumValues = Object.values(balanceCumsum).filter(
        (i) => typeof i == "number"
      );

      let months = Object.keys(incomes).filter((i) => /^\d/.test(i));
      months = months.map((i) => this.$moment(i).format("MMM YYYY"));

      payload = {
        options: {
          xaxis: {
            title: {
              text: "Mes",
            },
            categories: months,
          },
          yaxis: {
            title: {
              text: "CLP",
            },
            labels: {
              formatter: (value) => {
                let number = this.$options.filters.toLocaleStringNumber(value);
                return this.$options.filters.currency(number);
              },
            },
          },
          dataLabels: {
            enabled: false,
            formatter: function (value) {
              return value;
            },
          },
        },
        series: [
          {
            name: "Egresos Totales",
            type: "column",
            data: outflowsValues,
          },
          {
            name: "Ingresos Totales",
            type: "column",
            data: incomeValues,
          },
          {
            name: "Balance Acumulado",
            type: "line",
            data: balanceCumsumValues,
          },
        ],
      };

      if (months.length > 5) {
        payload.options.xaxis["labels"] = {
          show: true,
          rotate: -45,
          rotateAlways: true,
          minHeight: 100,
        };
      }

      return payload;
    },
  },
  created() {
    this.$emit("setTitle", "Flujo de caja");
  },
  methods: {
    getReport() {
      let vm = this;
      vm.loader = true;
      vm.$emit("showErrors", {});
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Reports.getReport(farm_id, "cashflow", this.payload)
        .then((response) => {
          vm.data = response.data;
          if (vm.data.length == 0) {
            vm.$emit("showInfo", {
              "no-data": this.$t("noDataFromDateRange"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          vm.$emit("showErrors", error.response.data);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.report-body
  min-height: 200px
</style>
