import { ceres_api } from "@/axios-config.js"

const Workers = {
    getWorkers({ farm_id, unscoped, fields }) {
        let endpoint = "/farms/" + farm_id + "/workers"
        if (unscoped != undefined || fields != undefined) {
            endpoint += "?"
        }
        if (unscoped != undefined) {
            endpoint += "unscoped=true"
        }
        if (fields != undefined) {
            endpoint += "fields=" + fields.join(",")
        }
        return ceres_api.get(endpoint)
    },
    createWorker({ farm_id, worker }) {
        let endpoint = "/farms/" + farm_id + "/workers"
        return ceres_api.post(endpoint, worker)
    },
    updateWorker({ farm_id, worker }) {
        let endpoint = "/farms/" + farm_id + "/workers/" + worker._id.$oid
        return ceres_api.put(endpoint, worker)
    },
    deleteWorker({ farm_id, worker_id }) {
        let endpoint = "/farms/" + farm_id + "/workers/" + worker_id
        return ceres_api.delete(endpoint)
    }
}

export default Workers
