<template lang="html">
  <card class="farm-metadata">
    <template slot="header">
      <card-controls :back="true" />

      <h4 class="card-title"><b>Nuevo campo agrícola</b></h4>
    </template>

    <div class="row">
      <div class="col-md-6 text-left">
        Ubicación del campo
        <br />
        <br />
        <div class="farm-location">
          <div class="row">
            <div v-if="locationControl" class="col-md-12 text-center">
              Para registrar la ubicación de tu campo puedes:
              <br />
              <br />
            </div>
            <div v-if="locationControl" class="col-md-12 text-center">
              <n-button
                class="big-btn"
                type="primary"
                @click.native="components.googleMaps = true"
              >
                <i class="now-ui-icons location_pin" />
                <div>Indicarlo en <br />el mapa</div>
              </n-button>
              <br />
              <div class="help">
                Indica dónde se encuentra tu campo utilizando un mapa para fijar
                su ubicación.
              </div>
            </div>

            <!-- <div
                  v-if="locationControl"
                  class="col-md-6 text-center">
                  <n-button
                    class="big-btn"
                    type="default"
                    disabled
                  >
                    <i class="now-ui-icons arrows-1_cloud-upload-94"/>
                    <div>subir mi archivo <br>georeferenciado</div>
                  </n-button>
                  <br>
                  <div class="help">Sube tu propio archivo georeferencial con la superficie de tu campo lista.</div>
                </div> -->

            <google-maps
              v-if="components.googleMaps"
              @create="locationControl = false"
              @areaUpdated="setFarmPolygon"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6 text-left">
        Datos del campo
        <br />
        <br />
        <div class="row">
          <div class="col-md-12 text-left">
            <fg-input
              v-validate="validations.name"
              v-model="models.newFarm.name"
              :error="errors.first('name')"
              data-vv-as="Nombre del campo"
              name="name"
              label="Nombre del campo"
              type="text"
              placeholder="Nombre del campo"
              required
            />
          </div>

          <div class="col-md-6 text-left">
            <fg-input
              v-validate="validations.business_name"
              v-model="models.newFarm.business_name"
              :error="errors.first('business_name')"
              data-vv-as="Razón social"
              name="business_name"
              label="Razón social"
              type="text"
              placeholder="Razón social"
              required
            />
          </div>

          <div class="col-md-6 text-left">
            <fg-input
              v-validate="validations.business_id"
              v-model="models.newFarm.business_id"
              :error="errors.first('business_id')"
              data-vv-as="RUT"
              name="business_id"
              label="RUT"
              type="text"
              placeholder="RUT"
              required
            />
          </div>

          <div class="col-md-12 text-left">
            <fg-input
              v-validate="validations.address"
              v-model="models.newFarm.address"
              :error="errors.first('address')"
              data-vv-as="Dirección del campo"
              name="address"
              label="Dirección del campo"
              type="text"
              placeholder="Dirección del campo"
              required
            />
          </div>

          <div class="col-md-6 text-left">
            <fg-input
              v-validate="validations.area"
              v-model.number="models.newFarm.area"
              :error="errors.first('area')"
              data-vv-as="Tamaño del campo (ha)"
              name="area"
              label="Tamaño del campo (ha)"
              type="search"
              placeholder="Tamaño del campo (ha)"
              required
            />
          </div>

          <div class="col-md-12 text-left">
            <div class="form-group has-label">
              <label>
                Logotipo del campo
                <span>*</span>
              </label>
              <el-upload
                :on-change="onLogoChange"
                :on-remove="onLogoRemove"
                :multiple="false"
                :auto-upload="false"
                action="https://jsonplaceholder.typicode.com/posts/"
                class="logo-upload"
              >
                <n-button type="primary">
                  Subir imagen de mi logotipo
                </n-button>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div slot="footer" class="text-right">
      <div v-if="messages.length > 0" class="text-left">
        <notification
          v-for="(message, key) in messages"
          :key="key"
          :message="message.message"
          :type="message.type"
          :close="true"
        />
      </div>
      <n-button type="primary" @click.native="validate"> Crear campo </n-button>
    </div>
  </card>
  <!-- <div class="farm-metadata">
    <card v-loading="loaders.newFarm">
      <div slot="header">
        <h3>Nuevo campo agrícola</h3>
        <div class="pull-right header-button">
          <n-button
            type="default"
            @click.native="$router.push('/dashboard/farms')"
          >
            <i class="fas fa-chevron-left"/>
            Mis campos
          </n-button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 text-left">
          Ubicación del campo
          <br>
          <br>
          <div class="farm-location">
            <div class="row">
              <div
                v-if="locationControl"
                class="col-md-12 text-center">
                Para registrar la ubicación de tu campo puedes:
                <br>
                <br>
              </div>
              <div
                v-if="locationControl"
                class="col-md-6 text-center">
                <n-button
                  class="big-btn"
                  type="primary"
                  @click.native="components.googleMaps = true"
                >
                  <i class="now-ui-icons location_pin"/>
                  <div>Indicarlo en <br>el mapa</div>
                </n-button>
                <br>
                <div class="help">Indica dónde se encuentra tu campo utilizando un mapa para fijar su ubicación.</div>
              </div>

              <div
                v-if="locationControl"
                class="col-md-6 text-center">
                <n-button
                  class="big-btn"
                  type="default"
                  disabled
                >
                  <i class="now-ui-icons arrows-1_cloud-upload-94"/>
                  <div>subir mi archivo <br>georeferenciado</div>
                </n-button>
                <br>
                <div class="help">Sube tu propio archivo georeferencial con la superficie de tu campo lista.</div>
              </div>


              <google-maps
                v-if="components.googleMaps"
                @create="locationControl = false"
                @areaUpdated="setFarmPolygon"/>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-left">
          Datos del campo
          <br>
          <br>
          <div class="row">
            <div class="col-md-12 text-left">
              <fg-input
                v-validate="validations.name"
                v-model="models.newFarm.name"
                :error="errors.first('name')"
                data-vv-as="Nombre del campo"
                name="name"
                label="Nombre del campo"
                type="text"
                placeholder="Nombre del campo"
                required/>
            </div>

            <div class="col-md-6 text-left">
              <fg-input
                v-validate="validations.business_name"
                v-model="models.newFarm.business_name"
                :error="errors.first('business_name')"
                data-vv-as="Razón social"
                name="business_name"
                label="Razón social"
                type="text"
                placeholder="Razón social"
                required/>
            </div>

            <div class="col-md-6 text-left">
              <fg-input
                v-validate="validations.business_id"
                v-model="models.newFarm.business_id"
                :error="errors.first('business_id')"
                data-vv-as="RUT"
                name="business_id"
                label="RUT"
                type="text"
                placeholder="RUT"
                required/>
            </div>

            <div class="col-md-12 text-left">
              <fg-input
                v-validate="validations.address"
                v-model="models.newFarm.address"
                :error="errors.first('address')"
                data-vv-as="Dirección del campo"
                name="address"
                label="Dirección del campo"
                type="text"
                placeholder="Dirección del campo"
                required/>
            </div>

            <div class="col-md-6 text-left">
              <fg-input
                v-validate="validations.area"
                v-model.number="models.newFarm.area"
                :error="errors.first('area')"
                data-vv-as="Tamaño del campo (ha)"
                name="area"
                label="Tamaño del campo (ha)"
                type="search"
                placeholder="Tamaño del campo (ha)"
                required/>
            </div>

            <div class="col-md-12 text-left">
              <div class="form-group has-label">
                <label>
                  Logotipo del campo
                  <span>*</span>
                </label>
                <el-upload
                  :on-change="onLogoChange"
                  :on-remove="onLogoRemove"
                  :multiple="false"
                  :auto-upload="false"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  class="logo-upload">
                  <n-button
                    type="primary"
                  >
                    Subir imagen de mi logotipo
                  </n-button>
                </el-upload>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        slot="footer"
        class="text-right">
        <div
          v-if="messages.length > 0"
          class="text-left">
          <notification
            v-for="(message,key) in messages"
            :key="key"
            :message="message.message"
            :type="message.type"
            :close="true"/>
        </div>
        <n-button
          type="primary"
          @click.native="validate">
          Crear campo
        </n-button>
      </div>
    </card>
  </div> -->
</template>

<script>
import GoogleMaps from "@/Components/GoogleMaps";
import Swal from "sweetalert2";
import { mapActions } from "vuex";

export default {
  components: {
    GoogleMaps,
  },
  data() {
    return {
      components: {
        googleMaps: false,
      },
      loaders: {
        newFarm: false,
      },
      models: {
        newFarm: {
          name: "",
          business_id: "",
          business_name: "",
          address: "",
          area: 0,
          pp_farm_sum: 0,
          pp_farm_avg: 0,
          georeference: [],
          logo: undefined,
        },
      },
      validations: {
        name: {
          required: true,
        },
        business_name: {
          required: true,
        },
        business_id: {
          required: true,
        },
        address: {
          required: true,
        },
        area: {
          required: true,
          min_value: 0,
        },
      },
      messages: [],
      locationControl: true,
    };
  },
  methods: {
    ...mapActions("Farms", ["getFarms"]),
    setFarmPolygon(polygon) {
      this.models.newFarm.georeference = polygon;
    },
    validate() {
      let vm = this;
      this.$validator.validateAll().then((isValid) => {
        if (isValid) vm.newFarm();
      });
    },
    async newFarm() {
      try {
        this.messages = [];
        this.loaders.newFarm = true;
        await this.Services.Farms.createFarm(this.models.newFarm);
        this.getFarms();
        Swal.fire({
          title: "Campo creado correctamente",
          icon: "success",
        });
        this.$router.push("/dashboard/farms");
      } catch (error) {
        this.processMessages(error.response.data.errors, "danger");
      } finally {
        this.loaders.newFarm = false;
      }
    },
    onLogoChange(file) {
      let vm = this;
      let reader = new FileReader();

      reader.onload = function (event) {
        vm.models.newFarm.logo = {
          name: file.name,
          content: event.target.result,
        };
      };
      reader.readAsDataURL(file.raw);
    },
    onLogoRemove() {
      this.models.newFarm.logo = undefined;
    },
  },
};
</script>

<style lang="sass">
.farm-metadata

  h3
    text-transform: uppercase
    .farm-location
      border: 1px dashed #F96332
      padding: 30px
    .big-btn
      text-align: center
      font-size: 15px
      width: 200px
      border-radius: 10px
      div
        width: 100%
        display: inline-block
        float: left
        word-wrap: break-word
        word-break: break-all
      i
        margin: 40px 0px
        font-size: 60px

    .help
      font-weight: bold
      font-size: 10px
      width: 160px
      margin: 0 auto

    .logo-upload
      .el-upload-list
        width: 40%
</style>
