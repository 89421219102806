<template lang="html">
  <div class="new-farm-wrapper">
    <h1>¡Bienvenido a Ceres!</h1>

    <h4>Comienza añadiendo tu primer campo</h4>

    <div 
      class="new-farm" 
      @click="newFarm()">
      <i class="now-ui-icons ui-1_simple-add"/>

      <span>NUEVO CAMPO</span>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
        newFarm() {
            this.$router.push("/dashboard/farm/new")
        }
    }
}
</script>

<style lang="sass">
	.new-farm-wrapper
		h1
			margin-top: 40px
		h4
			margin-top: 100px
		.new-farm
			color: white
			display: inline-block
			background-color: #F96332
			border-radius: 10px
			font-size: 12px
			padding: 40px 10px 10px
			cursor: pointer
			width: 125px
			height: 130px
			span
				display: inline-block
				width: 100%
				margin-top: 20px
			i
				font-size: 30px
</style>
