<template lang="html">
  <section
    id="pricing"
    class="section">
    <div class="container">
      <div class="row gap-y align-items-center">

        <div class="col-md-4  text-left">
          <p
            class="lead-7 text-dark fw-600 lh-2"
            v-html="data.pricing_title"/>
          <p
            class="lead"
            v-html="data.pricing_description"/>
        </div>

        <div
          v-for="(plan, index) in data.plans.slices"
          :key="index"
          class="col-md-4">
          <div
            class="card text-center shadow-1 hover-shadow-9">
            <div
              class="card-img-top text-white bg-img h-200 d-flex align-items-center blur"
              style="background-image: url(https://thetheme.io/thesaas/assets/img/thumb/3.jpg)"
              data-overlay="1">
              <div class="veil" />
              <div class="position-relative w-100">
                <p
                  class="lead-4 text-uppercase fw-600 ls-1 mb-0"
                  v-html="plan.non_repeat.name"/>
                <p>&nbsp;</p>
              </div>
            </div>
            <div
              :style="getStyle(index)"
              class="card-body py-6">

              <p class="lead-7 fw-600 text-dark mb-neg-10">US ${{ plan.non_repeat.amount }}/mo</p>
              <p
                class="small text-lighter"
                v-html="plan.non_repeat.payment_condition"/>
              <p>
                <span 
                  v-for="(item, item_index) in plan.repeat" 
                  :key="item_index">
                  <span

                    v-html="item.plan_feature" />
                  <br>
                </span>
              </p>
              <br>
              <div>
                <a
                  class="btn btn-round btn-secondary w-200"
                  href="#contact">Probar gratis</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    methods: {
        getStyle(index) {
            if (index == 1) {
                return {
                    filter: "blur(30px)",
                    "user-select": "none"
                }
            }
            return {}
        }
    }
}
</script>

<style lang="sass" scoped>
    .mb-neg-10
        margin-bottom: -10px
</style>
