<template>
  <div :class="{ 'nav-open': $sidebar.showSidebar }" class="wrapper">
    <side-bar
      :data-color="'orange'"
      :title="'Agricola Los Silos'"
      :settings-ready="settingsReady"
    >
      <template slot="links">
        <user-menu :title="user.full_name" />
        <sidebar-item
          :link="{
            name: 'Mis actividades',
            path: '/tasklist/list-view',
            icon: 'fas fa-tasks',
          }"
        />
        <sidebar-item
          :link="{
            name: 'calendario',
            path: '/tasklist/calendar-view',
            icon: 'fas fa-calendar',
          }"
        />
        <sidebar-item :link="{ name: 'Actividades', icon: 'fas fa-tasks-alt' }">
          <sidebar-item
            v-for="(value, key) in workflows"
            :key="key"
            :link="{
              name: value.sidebarName,
              path: value.route,
            }"
          />
        </sidebar-item>
        <sidebar-item :link="{ name: 'Recursos', icon: 'fas fa-boxes' }">
          <sidebar-item
            :link="{ name: 'Productos', path: '/dashboard/products' }"
          />
          <sidebar-item
            v-for="(value, key) in resources"
            :key="key"
            :link="{
              name: value.sidebarName,
              path: value.route,
            }"
          />
          <sidebar-item
            :link="{
              name: 'Trabajadores',
              path: '/dashboard/human-resources/workers',
            }"
          />
        </sidebar-item>
        <!-- <sidebar-item :link="{name: 'Comercial', icon: 'fas fa-dollar-sign'}">
          <sidebar-item :link="{name: 'Formatos de venta', path: '/commercial/package-formats'}"/>
          <sidebar-item :link="{name: 'Ventas', path: '/commercial/sales/records'}"/>
        </sidebar-item> -->
        <!-- <sidebar-item :link="{name: 'Sensores', path: '/sensors', icon: 'fas fa-wifi'}"/> -->
        <sidebar-item
          :link="{
            name: 'Informes',
            path: '/reports',
            icon: 'fas fa-chart-pie',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Calculadoras',
            path: '/tools',
            icon: 'fas fa-calculator-alt ',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Mis Campos',
            path: '/dashboard/farms',
            icon: 'fas fa-globe-americas',
          }"
        />
        <sidebar-item
          v-if="isAdmin"
          :link="{
            name: 'Configuración',
            path: '/dashboard/settings',
            icon: 'fas fa-cog',
          }"
        />
        <app-info />
      </template>
    </side-bar>
    <div class="main-panel">
      <el-alert
        v-if="!BROWSER_IS_SUPPORTED"
        :closable="false"
        title="Este navegador no está soportado, prefiera Chrome o Firefox para que Ceres funcione correctamente"
        type="error"
        effect="dark"
        class="alert-fixed"
      />
      <top-navbar
        :class="[{ 'browser-not-supported': !BROWSER_IS_SUPPORTED }]"
      />
      <div
        v-loading="!ready"
        :class="['content', { 'browser-not-supported': !BROWSER_IS_SUPPORTED }]"
      >
        <template v-if="ready">
          <global-alerts></global-alerts>
          <zoom-center-transition
            :duration="pageTransitionDuration"
            mode="out-in"
          >
            <router-view @setWindowTitle="setWindowTitle" />
          </zoom-center-transition>

          <!-- <support /> -->
        </template>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import TopNavbar from "./partials/TopNavbar.vue";
import AppInfo from "./partials/AppInfo.vue";
import UserMenu from "./partials/UserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import { Alert } from "element-ui";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { kebabCase, intersection, pick, isEmpty, get } from "lodash";
import pluralize from "pluralize";
import GlobalAlerts from "../Components/GlobalAlerts.vue";

export default {
  components: {
    TopNavbar,
    AppInfo,
    UserMenu,
    ZoomCenterTransition,
    [Alert.name]: Alert,
    GlobalAlerts,
  },
  data() {
    return {
      pageTransitionDuration: 200,
      user: "",
      workflows: [],
      resources: [],
    };
  },
  computed: {
    ...mapGetters({ isAdmin: "Auth/IS_ADMIN" }),
    ...mapGetters(["BROWSER_IS_SUPPORTED"]),
    ...mapState("Processes", ["processes"]),
    ...mapGetters({ farmId: "Farms/GET_FARM_ID" }),
    ...mapState("Settings", ["settings"]),
    ready() {
      return this.farmId != undefined && this.processes.length > 0;
    },
    settingsReady() {
      return isEmpty(this.settings);
    },
  },
  created() {
    let user = this.$store.getters["Auth/GET_USER"];

    if (user == undefined) {
      user = JSON.parse(sessionStorage.getItem("user"));
      this.$store.commit("Auth/SET_USER", user);
    }
    this.user = user;
    this.setup();
  },
  mounted() {
    let docClasses = document.body.classList;
    let isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar("sidebar");
      initScrollbar("sidebar-wrapper");

      docClasses.add("perfect-scrollbar-on");
    } else {
      docClasses.add("perfect-scrollbar-off");
    }
  },
  methods: {
    ...mapActions("Farms", ["getFarms"]),
    ...mapActions("Processes", ["getProcesses"]),
    ...mapMutations("Farms", ["setInitialFarm"]),
    ...mapActions("Users", ["getUsers"]),
    ...mapActions("Farms", ["getCurrentFarmData"]),
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    setWindowTitle(text) {
      document.title = text + " | " + process.env.VUE_APP_NAME;
    },
    async setup() {
      await this.getFarms();
      this.setInitialFarm();
      await this.getProcesses();
      this.getCurrentFarmData();
      this.getUsers();

      const processMeta = this.$CERES_VARS.data.processData;
      const allowed = this.processes.map((i) => kebabCase(pluralize(i.model)));
      const processMetaKeys = Object.keys(processMeta);
      const supportedKeys = intersection(processMetaKeys, allowed);
      const resourceKeys = ["inventories", "purchases"];
      this.workflows = pick(
        processMeta,
        supportedKeys.filter((i) => !resourceKeys.includes(i))
      );
      this.resources = pick(
        processMeta,
        supportedKeys.filter((i) => resourceKeys.includes(i))
      );
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}

.alert-fixed {
  position: fixed !important;
  z-index: 9999 !important;
}

.browser-not-supported {
  margin-top: 30px !important;
}
</style>
