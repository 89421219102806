<template>
  <navbar :show-navbar="showNavbar">
    <div class="navbar-wrapper">
      <div :class="{ toggled: $sidebar.showSidebar }" class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar" />
      </div>
    </div>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navigation"
      aria-controls="navigation-index"
      aria-expanded="false"
      aria-label="Toggle navigation"
      @click="toggleNavbar"
    >
      <span class="navbar-toggler-bar navbar-kebab" />
      <span class="navbar-toggler-bar navbar-kebab" />
      <span class="navbar-toggler-bar navbar-kebab" />
    </button>

    <template slot="navbar-menu">
      <ul class="navbar-nav farm-selector">
        <li class="nav-item">
          <drop-down
            :title="''"
            tag="li"
            position="right"
            class="nav-item"
            icon="now-ui-icons location_world"
          >
            <template slot="title">
              <a class="dropdown-toggle nav-link" data-toggle="dropdown">
                <span class="no-icon"
                  >Trabajando en campo: <b>{{ currentFarm.name }}</b></span
                >
              </a>
            </template>
            <div v-loading="true">
              <a
                v-for="(farm, key) in farms"
                :key="key"
                class="dropdown-item"
                href="#"
                @click="setCurrentFarm(farm)"
              >
                <i class="fas fa-th" />{{ farm.name }}</a
              >
            </div>
          </drop-down>
        </li>

        <li class="nav-item">
          <span
            class="nav-link"
            href="#"
            title="Mi Perfil"
            @click="$router.push({ name: 'user-profile' })"
          >
            <i class="fal fa-user" style="color: white" />
          </span>
        </li>

        <li class="nav-item">
          <span
            class="nav-link"
            href="#"
            title="Salir de Ceres"
            @click="logout"
          >
            <i class="fal fa-sign-out" style="color: white" />
          </span>
        </li>
      </ul>
    </template>

    <process-list
      v-if="components.processList"
      :category="'activities'"
      @close="components.processList = false"
    />
  </navbar>
</template>
<script>
import { Navbar, NavbarToggleButton } from "nowui/components";
import ProcessList from "@/views/TaskList/ProcessList";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    Navbar,
    NavbarToggleButton,
    ProcessList,
  },
  data() {
    return {
      components: {
        processList: false,
      },
      activeNotifications: false,
      showNavbar: false,
    };
  },
  computed: {
    ...mapState("Farms", ["farms", "currentFarm"]),
    ...mapGetters({ currentFarmId: "Farms/GET_FARM_ID" }),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  watch: {
    currentFarmId: function (newValue) {
      this.getCurrentFarmData(newValue);
    },
  },
  methods: {
    ...mapActions("Farms", ["getCurrentFarmData"]),
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    setCurrentFarm(farm) {
      localStorage.setItem("farm", JSON.stringify(farm));
      this.$store.commit("Farms/SET_FARM", farm);
      this.$router.go();
    },
    logout() {
      sessionStorage.removeItem("user");
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style lang="sass">
.farm-selector
  font-size: 18px
.nav-item
  span
    cursor: pointer
</style>
