import { ceres_api } from "@/axios-config.js";

const Reports = {
  getReport(farm_id, id, payload) {
    let endpoint = "/farms/" + farm_id + "/reports/" + id;
    if (payload == undefined) {
      return ceres_api.get(endpoint);
    } else {
      return ceres_api.post(endpoint, payload);
    }
  },
  getReportOptions(farm_id, id) {
    let endpoint = "/farms/" + farm_id + "/reports/options/" + id;
    return ceres_api.get(endpoint);
  },
  getPlaces(farm_id, query) {
    let endpoint = "/farms/" + farm_id + "/reports/get_places/" + query;
    return ceres_api.get(endpoint);
  },
  saveConfig(farm_id, report_key, config) {
    let endpoint = "/farms/" + farm_id + "/reports/save_config/" + report_key;
    return ceres_api.post(endpoint, config);
  },
  getConfig(farm_id, report_key) {
    let endpoint = "/farms/" + farm_id + "/reports/get_config/" + report_key;
    return ceres_api.get(endpoint);
  },
};

export default Reports;
