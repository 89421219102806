<template lang="html">
  <div class="row">
    <div class="col-md-12">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-info-circle"/></div>
                <h5 class="info-title"><span>{{ $t(model.workflow.state) }}</span></h5>
                <h6 class="stats-title">Estado</h6>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-calendar"/></div>
                <h5
                  v-if="model.hasOwnProperty('date')"
                  class="info-title">
                  <span>{{ model.date | moment("DD-MM-YYYY") }}</span>
                </h5>
                <h6 class="stats-title">Fecha</h6>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-leaf"/></div>
                <h5 class="info-title"><span>{{ model.work_coverage | toLocaleStringNumber }}</span></h5>
                <h6 class="stats-title">{{ 'Plantas ' + getWorkCoverageTitle }}</h6>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-coins"/></div>
                <h5 class="info-title"><span>{{ model.total | toLocaleStringNumber | currency }}</span></h5>
                <h6 class="stats-title">Costo CLP (IVA Incl.)</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-md-12 col-sm-12 text-left">
          <h5>Detalles</h5>
        </div>
      </div>

      <div class="row">
        <div class="offset-md-2 col-md-8 pull-center desktop-table">
          <el-table
            :data="modelArray"
            :empty-text="'No hay podas registradas'"
            class="table-striped">

            <el-table-column
              label="Variable"
              prop="name"
              align="center"
              class-name="table-category"/>

            <el-table-column
              label="Valor"
              prop="value"
              align="center"
              class-name="table-category">
              <div
                slot-scope="{row}">
                <span
                  v-if="row.key == 'sectors'">
                  <el-tag
                    v-for="sector in model.sectors"
                    :key="sector._id.$oid"
                    :closable="false"
                    :close-transition="false"
                    size="small"
                    type="primary"
                    class="used-product-tag">
                    {{ sector.name }}
                  </el-tag>
                </span>
                <span
                  v-else-if="row.key == 'workers' && model.workers.length > 0">
                  <el-tag
                    v-for="worker in model.workers"
                    :key="worker._id.$oid"
                    :closable="false"
                    :close-transition="false"
                    size="small"
                    type="primary"
                    class="used-product-tag">
                    {{ worker.full_name }}
                  </el-tag>
                </span>
                <span
                  v-else-if="row.key == 'workers' && model.workers.length == 0">
                  -
                </span>
                <span
                  v-else-if="row.type == 'number'">
                  {{ row.value | toLocaleStringNumber }}
                </span>
                <span
                  v-else-if="row.type == 'currency'">
                  {{ row.value | toLocaleStringNumber | currency }}
                </span>
              </div>
            </el-table-column>

          </el-table>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <cost-summary :record="model" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 pull-center">
          <slot name="actions"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui"
import CostSummary from "@/views/Labors/Components/CostSummary"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        CostSummary
    },
    props: {
        model: {
            type: Object,
            default: () => {
                return {}
            }
        },
        sectors: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            elements: {
                sectors: false
            },
            workers: [],
            sectorList: [],
            modelArray: [],
            detailData: [
                {
                    id: "sectors",
                    name: "Cuarteles"
                },
                {
                    id: "workers",
                    name: "Podadores"
                },
                {
                    id: "buds_per_plant",
                    name: "Yemas por planta",
                    type: "number"
                },
                {
                    id: "total_buds",
                    name: "Yemas totales",
                    type: "number"
                },
                {
                    id: "costPerUnit",
                    name: "Costo por planta (CLP)",
                    type: "currency"
                }
            ]
        }
    },
    computed: {
        getWorkCoverageTitle() {
            return this.model.workflow.state == "done" ? "Podadas" : "a Podar"
        },
        getHiveTitle() {
            return this.model.workflow.state == "done"
                ? "utilizadas"
                : "a utilizar"
        }
    },
    created() {
        let vm = this
        let modelArray = []
        Object.keys(this.model).forEach(key => {
            let schema = {
                key: key,
                value: this.model[key]
            }
            modelArray.push(schema)
        })
        this.modelArray = modelArray.reduce((out, i) => {
            let targetKeys = vm.detailData.map(i => i.id)
            if (targetKeys.includes(i.key)) {
                let metadata = vm.detailData.filter(
                    field => field.id == i.key
                )[0]
                i["name"] = metadata.name
                i["type"] = metadata.type
                out.push(i)
            }
            return out
        }, [])
    },
    methods: {}
}
</script>

<style lang="css" scoped>
</style>
