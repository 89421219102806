import Vue from "vue"
import axios from "axios"

/* Axios configuration */
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.headers.get["Content-Type"] = "application/json"
axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.headers.put["Content-Type"] = "application/json"

axios.interceptors.request.use(config => {
    let token = Vue.cookie.get("ceres")

    if (token != null) {
        config.headers.Authorization = "Basic " + token
    }

    return config
})

axios.interceptors.response.use(
    res => {
        return res
    },
    function(error) {
        if (error.response.status == 401) {
            // router.push("/auth/login?status=unauthorized")
        }
    }
)

const ceres_api = axios.create({
    baseURL: process.env.VUE_APP_CERES_API_BASE_URL,
    headers: {
        "Content-Type": "application/json"
    }
})

ceres_api.interceptors.request.use(config => {
    // set JWT
    let user = JSON.parse(sessionStorage.getItem("user"))
    if (user != undefined) config.headers["Authorization"] = user.token

    return config
})

export { ceres_api }
export default axios
