<template lang="html">
  <div
    v-loading="loader">
    <div
      v-if="render"
      class="row">
      <div class="col-md-12">
        <div
          v-if="viewMode"
          class="row">
          <div class="col-md-4">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-info-circle"/></div>
                <h5 class="info-title"><span>{{ $t(model.workflow.state) }}</span></h5>
                <h6 class="stats-title">Estado</h6>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-calendar"/></div>
                <h5
                  v-if="model.hasOwnProperty('date')"
                  class="info-title">
                  <span>{{ model.date | moment("DD-MM-YYYY") }}</span>
                </h5>
                <h6 class="stats-title">Fecha</h6>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info"><i class="fal fa-user"/></div>
                <h5 class="info-title">
                  <span>{{ $HELPERS.findAndgetUserFullName(model.workflow.assignee.$oid, users) }}</span>
                </h5>
                <h6 class="stats-title">Responsable</h6>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!viewMode"
          class="row">
          <div class="col-md-12 text-left">
            <h5>Configuración general</h5>
          </div>
          <div class="col-md-6 text-left">
            <fg-input
              :error="errors.first('date')"
              required
              label="Fecha del monitoreo">
              <el-date-picker
                v-validate="{required: true}"
                v-model="model.date"
                data-vv-as="Fecha del monitoreo"
                name="date"
                format="dd-MM-yyyy"
                type="date"
                placeholder="Fecha del monitoreo"/>
            </fg-input>
          </div>
          <div class="col-md-6 text-left">
            <fg-input
              :error="errors.first('assignee')"
              class="has-label">
              <label>Responsable <span>*</span></label>
              <el-select
                v-validate="{required: true}"
                v-model="model.workflow.assignee.$oid"
                filterable
                class="select-primary"
                placeholder="Seleccione usuario que realizará el monitoreo"
                name="assignee"
                vv-data-as="Responsable"
                disabled>
                <el-option
                  v-for="(user,key) in users"
                  :value="user._id.$oid"
                  :label="user.full_name"
                  :key="key"
                  class="select-danger"/>
              </el-select>
              <div
                v-if="!viewMode && $HELPERS.isSupervisor"
                class="text-muted pt-1 small">Para editar el responsable utilice el control <i class="fal fa-user"/> de la esquina supervisor derecha. </div>
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 text-left">
            <h5>Parámetros</h5>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 text-left">
            <template
              v-if="model.hasOwnProperty('irrigation_upkeep_parameters')">
              <p
                v-for="(value, index) in model.irrigation_upkeep_parameters"
                :key="index">
                {{ model.irrigation_upkeep_parameters[index].name }} :
                {{ model.irrigation_upkeep_parameters[index].value }}
              </p>
            </template>
            <template
              v-else>
              <p>-</p>
            </template>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 text-left">
            <h5>Labores de mantención</h5>
          </div>
        </div>

        <div class="row">
          <div
            v-for="(sector, index) in model.irrigation_upkeep_sectors"
            :key="index"
            class="col-md-4 text-left">
            <el-card shadow="hover">
              <div slot="header">
                <fg-input
                  :key="sector._id.$oid"
                  :error="errors.first(sector._id.$oid+'_'+sector.sector_id.$oid)">
                  <n-checkbox
                    v-validate="'laborChecked'"
                    :name="sector._id.$oid+'_'+sector.sector_id.$oid"
                    :key="sector._id.$oid+'_'+sector.sector_id.$oid"
                    v-model="model.irrigation_upkeep_sectors[index].checked"
                    :disabled="viewMode"
                    data-vv-as="Labor de Mantenimiento">
                    {{ sector.sector.name }}
                  </n-checkbox>
                </fg-input>
              </div>
              <p>Labores a realizar:</p>
              <ul>
                <li
                  v-for="(labor, index) in model.irrigation_upkeep_labors"
                  :key="sector._id.$oid+'_'+index+'_'+labor.labor_id.$oid">
                  {{ labor.name }}
                </li>
              </ul>
            </el-card>
          </div>

        </div>

        <div class="row">
          <div class="col-md-12 action-footer">
            <slot name="actions"/>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <notification
              :messages="messages"
              :close="true"
              type="error"/>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, Card } from "element-ui"
import { Validator } from "vee-validate"
export default {
    components: {
        [Checkbox.name]: Checkbox,
        [Card.name]: Card
    },
    props: {
        record: {
            type: Object,
            default: () => {
                return {}
            }
        },
        runValidations: {
            type: Number,
            default: 0
        },
        viewMode: {
            type: Boolean,
            default: false
        },
        messages: {
            type: Object,
            default: () => {
                return {}
            }
        },
        getModel: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            loader: true,
            render: false,
            settings: {},
            users: [],
            sectors: [],
            model: {
                workflow: {
                    assignee: {
                        $oid: ""
                    }
                }
            }
        }
    },
    watch: {
        model: {
            handler: function(newValue, oldValue) {
                this.$emit("updateModel", newValue)
            },
            deep: true
        },
        runValidations: function(newValue, oldValue) {
            let vm = this
            this.$validator.validateAll().then(isValid => {
                vm.$emit("isValid", isValid, vm.model)
            })
        },
        getModel: function(newValue, oldValue) {
            this.$emit("updateModel", this.model)
        }
    },
    created() {
        this.settings = this.$store.getters["Settings/GET_SETTINGS"]
        this.model = this.$COPY.copy(this.record)
        this.getUsers()
    },
    mounted() {
        const laborChecked = {
            validate: value => {
                let isValid = value == true ? true : false
                return {
                    valid: isValid,
                    data: {
                        message: "Debe realizar la labor de mantenimiento"
                    }
                }
            },
            getMessage: (field, params, data) => {
                return data.message
            }
        }
        Validator.extend("laborChecked", laborChecked)
    },
    methods: {
        getUsers() {
            let vm = this
            this.Services.Users.getUsers()
                .then(response => {
                    vm.users = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                    vm.render = true
                })
        }
    }
}
</script>

<style lang="sass" scoped>
  .el-card
    margin-top: 20px
</style>
