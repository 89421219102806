const ProductApplicationUtils = {
  methods: {
    dose(item) {
      const dose = this.$options.filters.toLocaleStringNumber(
        item.input_dose,
        "decimal"
      );
      return `${dose} ${item.input_dose_unit}`;
    },
    dosePerHa(model, product, { as_value = false } = {}) {
      const dose = product.use / model.total_area;
      if (as_value) return dose;
      const value = this.$options.filters.toLocaleStringNumber(dose, "decimal");
      return `${value} ${product.product_measure_unit}/Ha`;
    },
    productUseQuantity(product) {
      const value = this.$options.filters.toLocaleStringNumber(
        product.use,
        "decimal"
      );
      return `${value} ${product.product_measure_unit}`;
    },
    productUseQuantityPerArea(model, product, area) {
      const dose = this.dosePerHa(model, product, { as_value: true });
      const value = this.$options.filters.toLocaleStringNumber(
        dose * area,
        "decimal"
      );
      return `${value} ${product.product_measure_unit}`;
    },
  },
};

export default ProductApplicationUtils;
