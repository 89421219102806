var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"col-md-12 col-sm-12 pull-center desktop-table"},[_c('el-table',{staticClass:"table-striped",attrs:{"data":_vm.data,"empty-text":'No hay cosechas registradas'}},[(_vm.showStatus)?_c('el-table-column',{attrs:{"prop":"status","label":"Estado","align":"center","name":"status","column-key":"status","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-tag',{attrs:{"closable":false,"close-transition":false,"type":_vm.$TASK_STATES.getColor(row.workflow.state),"size":"small"}},[_vm._v(" "+_vm._s(_vm.$t(row.workflow.state))+" ")])],1)}}],null,false,3533313077)}):_vm._e(),_c('el-table-column',{attrs:{"label":"Año","align":"left","name":"date","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("moment")(row.date,"YYYY"))+" ")])}}])}),_c('el-table-column',{attrs:{"label":"Mes","align":"left","name":"date","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("moment")(row.date,"MM"))+" ")])}}])}),_c('el-table-column',{attrs:{"label":"Semana","align":"left","name":"date","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("moment")(row.date,"WW"))+" ")])}}])}),_c('el-table-column',{attrs:{"prop":"date","label":"Fecha","align":"left","name":"date","column-key":"date","class-name":"table-category","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('i',{staticClass:"el-icon-time"}),_vm._v(" "+_vm._s(_vm._f("moment")(row.date,"DD-MM-YYYY"))+" ("+_vm._s(_vm._f("moment")(row.date,"dddd"))+") ")])}}])}),_c('el-table-column',{attrs:{"label":"Cuarteles","name":"sectors","align":"center","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},_vm._l((row.sector_harvests),function(item,index){return _c('div',{key:'sector_name_' + index + '_' + item.sector._id.$oid},[_vm._v(" "+_vm._s(_vm.getSectorName(item.sector))+" ")])}),0)}}])}),_c('el-table-column',{attrs:{"prop":"kg","label":"Cosecha Total (kg)","name":"kg","header-align":"center","align":"right","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("toLocaleStringNumber")(row.total_harvest,"decimal"))+" ")])}}])}),_c('el-table-column',{attrs:{"label":"Acciones","align":"center","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('n-button',{attrs:{"type":"primary","size":"sm"},nativeOn:{"click":function($event){return _vm.viewHarvest(row)}}},[_c('i',{staticClass:"fal fa-eye"})])],1)}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }