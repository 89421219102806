<template lang="html">
  <div class="farm-metadata">
    <card v-loading="loaders.updateFarm">
      <div slot="header">
        <h3>Editar campo agrícola</h3>
        <div class="pull-right header-button">
          <n-button
            type="default"
            simple
            @click.native="
              $router.push(
                '/dashboard/farm/view/' + models.farm._id.$oid.toString()
              )
            "
          >
            <i class="fas fa-chevron-left" />
            Volver
          </n-button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 text-left">
          Ubicación del campo
          <br />
          <br />
          <div class="farm-location">
            <div class="row">
              <div v-if="locationControl" class="col-md-12 text-center">
                Para registrar la ubicación de tu campo puedes:
                <br />
                <br />
              </div>
              <div v-if="locationControl" class="col-md-12 text-center">
                <n-button
                  class="big-btn"
                  type="primary"
                  @click.native="components.googleMaps = true"
                >
                  <i class="now-ui-icons location_pin" />
                  <div>Indicarlo en <br />el mapa</div>
                </n-button>
                <br />
                <div class="help">
                  Indica dónde se encuentra tu campo utilizando un mapa para
                  fijar su ubicación.
                </div>
              </div>

              <!-- <div
                v-if="locationControl"
                class="col-md-6 text-center">
                <n-button
                  class="big-btn"
                  type="default"
                  disabled
                >
                  <i class="now-ui-icons arrows-1_cloud-upload-94"/>
                  <div>subir mi archivo <br>georeferenciado</div>
                </n-button>
                <br>
                <div class="help">Sube tu propio archivo georeferencial con la superficie de tu campo lista.</div>
              </div> -->

              <google-maps
                v-if="components.googleMaps"
                :polygon="computedGeoreference"
                @create="locationControl = false"
                @areaUpdated="setFarmPolygon"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6 text-left">
          Datos del campo
          <br />
          <br />
          <div class="row">
            <div class="col-md-12 text-left">
              <fg-input
                v-validate="validations.name"
                v-model="models.farm.name"
                :error="errors.first('name')"
                data-vv-as="Nombre del campo"
                name="name"
                label="Nombre del campo"
                type="text"
                placeholder="Nombre del campo"
                required
              />
            </div>

            <div class="col-md-6 text-left">
              <fg-input
                v-validate="validations.business_name"
                v-model="models.farm.business_name"
                :error="errors.first('business_name')"
                data-vv-as="Razón social"
                name="business_name"
                label="Razón social"
                type="text"
                placeholder="Razón social"
                required
              />
            </div>

            <div class="col-md-6 text-left">
              <fg-input
                v-validate="validations.business_id"
                v-model="models.farm.business_id"
                :error="errors.first('business_id')"
                data-vv-as="RUT"
                name="business_id"
                label="RUT"
                type="text"
                placeholder="RUT"
                required
              />
            </div>

            <div class="col-md-12 text-left">
              <fg-input
                v-validate="validations.address"
                v-model="models.farm.address"
                :error="errors.first('address')"
                data-vv-as="Dirección del campo"
                name="address"
                label="Dirección del campo"
                type="text"
                placeholder="Dirección del campo"
                required
              />
            </div>

            <div class="col-md-6 text-left">
              <fg-input
                v-validate="validations.area"
                v-model.number="models.farm.area"
                :error="errors.first('area')"
                data-vv-as="Tamaño del campo (ha)"
                name="area"
                label="Tamaño del campo (ha)"
                type="number"
                placeholder="Tamaño del campo (ha)"
                required
                disabled
              />
            </div>

            <div class="col-md-12 text-left">
              <div class="form-group has-label">
                <label>
                  Logotipo del campo
                  <span>*</span>
                </label>
                <el-upload
                  :on-change="onLogoChange"
                  :on-remove="onLogoRemove"
                  :multiple="false"
                  :auto-upload="false"
                  class="logo-upload"
                  action="https://jsonplaceholder.typicode.com/posts/"
                >
                  <n-button type="primary">
                    Subir imagen de mi logotipo
                  </n-button>
                </el-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="messages.length > 0" class="text-left">
        <notification
          v-for="(message, key) in messages"
          :key="key"
          :message="message.message"
          :type="message.type"
          :close="true"
        />
      </div>

      <div v-if="showFooter" slot="footer" class="text-right">
        <n-button type="primary" @click.native="validate"> Guardar </n-button>
      </div>
    </card>
  </div>
</template>

<script>
import GoogleMaps from "@/Components/GoogleMaps";
import Swal from "sweetalert2";

export default {
  components: {
    GoogleMaps,
  },
  props: {
    showFooter: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      components: {
        googleMaps: false,
      },
      loaders: {
        updateFarm: false,
      },
      models: {
        farm: {
          name: "",
          business_id: "",
          business_name: "",
          address: "",
          area: 0,
          logo: undefined,
        },
      },
      validations: {
        name: {
          required: true,
        },
        business_name: {
          required: true,
        },
        business_id: {
          required: true,
        },
        address: {
          required: true,
        },
      },
      messages: [],
      locationControl: true,
    };
  },
  computed: {
    computedGeoreference() {
      if (this.models.farm.georeference != undefined) {
        return this.models.farm.georeference;
      } else if (this.models.farm.map != undefined) {
        if (this.models.farm.map.polygon != undefined) {
          return this.models.farm.map.polygon;
        }
      }

      return [];
    },
  },
  created() {
    let vm = this;
    let farm_id = this.$route.params.id;

    vm.loaders.updateFarm = true;

    this.Services.Farms.getFarm(farm_id)
      .then((response) => {
        vm.models.farm = response.data;
      })
      .catch((error) => {})
      .finally(() => {
        vm.loaders.updateFarm = false;
        this.models.farm.logo = undefined;
      });
  },
  methods: {
    setFarmPolygon(polygon) {
      this.models.farm.georeference = polygon;
    },
    validate() {
      let vm = this;
      this.$validator.validateAll().then((isValid) => {
        if (isValid) vm.update();
      });
    },
    update() {
      let vm = this;
      let payload = this.models.farm;
      let id = this.models.farm._id.$oid.toString();

      vm.messages = [];
      vm.loaders.updateFarm = true;

      this.Services.Farms.updateFarm({ id, payload })
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          });

          vm.loaders.updateFarm = false;
          vm.$emit("farmUpdate");
        })
        .catch((error) => {
          console.log(error);
          vm.processMessages(error.response.data.errors, "danger");
        })
        .finally(() => {
          vm.loaders.updateFarm = false;
        });
    },
    onLogoChange(file) {
      let vm = this;
      let reader = new FileReader();

      reader.onload = function (event) {
        vm.models.farm.logo = {
          name: file.name,
          content: event.target.result,
        };
      };
      reader.readAsDataURL(file.raw);
    },
    onLogoRemove() {
      this.models.farm.logo = undefined;
    },
  },
};
</script>

<style lang="sass">
.no-card-style
  .card
    box-shadow: none
  .farm-metadata

    h3
      text-transform: uppercase
    .farm-location
      border: 1px dashed #F96332
      padding: 30px
    .big-btn
      text-align: center
      font-size: 15px
      width: 200px
      border-radius: 10px
      div
        width: 100%
        display: inline-block
        float: left
        word-wrap: break-word
        word-break: break-all
      i
        margin: 40px 0px
        font-size: 60px

    .help
      font-weight: bold
      font-size: 10px
      width: 160px
      margin: 0 auto

    .logo-upload
      .el-upload-list
        width: 40%
</style>
