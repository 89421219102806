<template>
  <div class="user">
    <div class="info">
      <a
        :aria-expanded="!isClosed"
        data-toggle="collapse"
        href="#"
        @click.stop="toggleMenu">
        <span class="info">
          <i class="fas fa-user-circle" />
          {{ title }}
          <b class="caret"/>
        </span>
      </a>
      <div class="clearfix"/>
      <div>
        <collapse-transition>
          <ul
            v-show="!isClosed"
            class="nav">
            <slot>
              <li><router-link :to="{ name: 'user-profile' }"><i class="fas fa-user" />Mi Perfil</router-link></li>
              <li><router-link :to="{ name: 'change-password' }"><i class="fas fa-key" />Contraseña</router-link></li>
            </slot>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions"

export default {
    components: {
        CollapseTransition
    },
    props: {
        title: {
            type: String,
            default: "James Amos"
        }
    },
    data() {
        return {
            isClosed: true
        }
    },
    methods: {
        toggleMenu() {
            this.isClosed = !this.isClosed
        }
    }
}
</script>
<style lang="sass">
	.collapsed
		transition: opacity 1s
	.user
		.info
			.info
				padding-left: 5px
				height: 30px
				line-height: 30px
				.caret
					margin-top: 5px
</style>
