<template lang="html">
  <div>
    <div class="row">
      <div class="col-md-12 text-right">
        <n-button
          simple=""
          size="sm"
          type="primary"
          @click.native="sortTable">
          <i class="fal fa-sort-alpha-down"/> Reordenar
        </n-button>
      </div>
    </div>
    <div class="row">
      <el-table
        ref="conditionalFields"
        :data="model.data"
        empty-text="No hay datos"
        class="col-md-12 products-table">

        <slot name="customColumns" />

        <el-table-column
          label="Variable a registrar"
          prop="value.variableName">
          <div slot-scope="scope">
            <fg-input
              v-validate="{required: true}"
              v-model="scope.row.value.variableName"
              :name="'variableName' + scope.$index"
              :error="errors.first('variableName' + scope.$index)"
              type="text"
              data-vv-as="Variable a medir"
              placeholder="Variable a medir"/>
          </div>
        </el-table-column>

        <el-table-column
          label="Acciones"
          align="right"
          class-name="controls"
          fixed="right">
          <div slot-scope="scope">
            <n-button
              simple=""
              size="sm"
              type="danger"
              @click.native="rmLine(scope)">
              <i class="fas fa-minus-circle" />
            </n-button>

            <n-button
              v-if="isLastRow(scope)"
              simple=""
              size="sm"
              type="primary"
              class="ms-1"
              @click.native="addLine(scope)">
              <i class="fas fa-plus-circle" />
            </n-button>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <n-button
          v-if="model.data.length == 0"
          simple=""
          size="sm"
          type="primary"
          @click.native="addLine">
          <i class="fas fa-plus-circle" />
        </n-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <slot name="bottom" />
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    settings: {
      type: Object,
      default: () => {
        return {};
      },
    },
    sectors: {
      type: Array,
      default: () => {
        return [];
      },
    },
    runValidations: {
      type: Boolean,
      default: false,
    },
    settingCategory: {
      type: String,
      default: "setMe",
    },
    settingKey: {
      type: String,
      default: "setMe",
    },
  },
  data() {
    return {
      model: {
        data: [],
        toDelete: [],
      },
    };
  },
  watch: {
    model: {
      handler: function (newValue, oldValue) {
        this.$emit(
          "updatedModel",
          newValue.data.concat(newValue.toDelete),
          this.settingKey
        );
      },
      deep: true,
    },
    runValidations: function (newValue, oldValue) {
      let vm = this;
      if (newValue) {
        this.$validator.validateAll().then((isValid) => {
          vm.$emit("isValid", isValid, "predefinedTracking");
        });
      }
    },
  },
  created() {
    if (this.settings[this.settingCategory] != undefined) {
      if (this.settings[this.settingCategory][this.settingKey] != undefined) {
        this.model.data = this.$COPY.copy(
          this.settings[this.settingCategory][this.settingKey]
        );
      }
    }
  },
  methods: {
    rmLine(scope) {
      let item = this.model.data[scope.$index];
      if (item._id != undefined) {
        item.delete = true;
        this.model.toDelete.push(item);
      }
      this.model.data.splice(scope.$index, 1);
    },
    addLine(scope) {
      this.model.data.push({
        value: {
          attachedTo: "",
          variableName: "",
        },
        global: true,
        category: this.settingCategory,
        key: this.settingKey,
        type: "array",
      });
    },
    sortTable() {
      this.model.data.sort((a, b) => {
        if (a.value.attachedTo > b.value.attachedTo) return 1;
        if (a.value.attachedTo < b.value.attachedTo) return -1;
        if (a.value.attachedTo == b.value.attachedTo) return 0;
      });
    },
    isLastRow(scope) {
      return this.model.data.length - 1 == scope.$index ? true : false;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
