<template lang="">
  <modal
    :show.sync="modals.editSector"
    class=""
    header-classes="justify-content-center"
    @close="$emit('close')">

    <h4
      slot="header"
      class="title title-up"> {{ title }} </h4>
    <div
      v-loading="loader"
      class="row">
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true}"
          v-model="model.sector.name"
          :error="errors.first('name')"
          data-vv-as="Nombre"
          name="name"
          label="Nombre"
          type="text"
          placeholder="Nombre"
          required/>
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 0, numeric: true}"
          v-model.number="model.sector.establishment_year"
          :error="errors.first('establishment_year')"
          data-vv-as="Año plantación"
          name="establishment_year"
          label="Año plantación"
          type="number"
          placeholder="Año plantación"
          required/>
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          :error="errors.first('specie')"
          label="Especie"
          required>
          <el-select
            v-validate="{ required: true}"
            v-model="model.sector.specie"
            data-vv-as="Especie"
            placeholder="Especie"
            class="select-primary"
            name="specie">
            <el-option
              v-for="(specie,key) in settings.general.species"
              :value="specie._id.$oid.toString()"
              :label="specie.value"
              :key="key"
              class="select-danger"/>

          </el-select>
        </fg-input>
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          :error="errors.first('variety')"
          label="Variedad"
          required>
          <el-select
            v-validate="{ required: true}"
            v-model="model.sector.variety"
            data-vv-as="Variedad"
            placeholder="Variedad"
            class="select-primary"
            name="variety"
            collapse-tags="">
            <el-option
              v-for="(variety,key) in computedVariety"
              :value="variety._id.$oid.toString()"
              :label="variety.value"
              :key="key"
              class="select-danger"
            />
          </el-select>

        </fg-input>
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 0, decimal: true}"
          v-model.number="model.sector.area"
          :error="errors.first('area')"
          data-vv-as="Superficie (ha)"
          name="area"
          label="Superficie (ha)"
          type="number"
          placeholder="Superficie (ha)"
          required/>
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 0, numeric: true}"
          v-model.number="model.sector.num_of_plants"
          :error="errors.first('num_of_plants')"
          data-vv-as="Número de plantas"
          name="num_of_plants"
          label="Número de plantas"
          type="number"
          placeholder="Número de plantas"
          required/>
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 0, decimal: true}"
          v-model.number="model.sector.distance_between_lines"
          :error="errors.first('distance_between_lines')"
          data-vv-as="Distancia entre hileras (m)"
          name="distance_between_lines"
          label="Distancia entre hileras (m)"
          type="number"
          placeholder="Distancia entre hileras (m)"
          required/>
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 0, decimal: true}"
          v-model.number="model.sector.distance_between_plants"
          :error="errors.first('distance_between_plants')"
          data-vv-as="Distancia entre plantas (m)"
          name="distance_between_plants"
          label="Distancia entre plantas (m)"
          type="number"
          placeholder="Distancia entre plantas (m)"
          required/>
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 0, numeric: true}"
          v-model.number="model.sector.droppers_per_plant"
          :error="errors.first('droppers_per_plant')"
          data-vv-as="Goteros por planta"
          name="droppers_per_plant"
          label="Goteros por planta"
          type="number"
          placeholder="Goteros por planta"
          required/>
      </div>
      <div class="col-md-6 text-left">
        <fg-input
          v-validate="{ required: true, min_value: 0, decimal: true}"
          v-model.number="model.sector.caudal_per_dropper"
          :error="errors.first('caudal_per_dropper')"
          data-vv-as="Caudal por gotero (L)"
          name="caudal_per_dropper"
          label="Caudal por gotero (L)"
          type="number"
          placeholder="Caudal por gotero (L)"
          required/>
      </div>

      <div class="col-md-12 text-left">
        <notification
          v-if="notification.show"
          :message="notification.message"
          :type="notification.type"
          :close="true"
          @onClose="notification.show = false"/>
      </div>
    </div>
    <template slot="footer">
      <div class="d-flex align-items-center w-100">
        <n-button
          :disabled="loader"
          @click.native="$emit('close')">
          Cerrar
        </n-button>
      <div class="ms-auto">
        <n-button
          :disabled="loader"
          type="danger"
          @click.native="components.deleteSector = true">
          Eliminar
        </n-button>
        <n-button
          :disabled="loader"
          type="primary"
          class="ms-1"
          @click.native="validate()">
          Actualizar
        </n-button>
      </div>
      </div>
    </template>

    <delete-sector
      v-if="components.deleteSector"
      :sector="sector"
      :farm="farm"
      @close="components.deleteSector = false"
      @deleted="deleted()" />
  </modal>
</template>

<script>
import Modal from "nowui/components/Modal"
import Swal from "sweetalert2"
import DeleteSector from "./DeleteSector"

export default {
    components: {
        Modal,
        DeleteSector
    },
    props: {
        sector: {
            type: Object,
            default: () => {
                return {}
            }
        },
        farm: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            render: false,
            title: "Datos del cuartel",
            notification: {
                show: false,
                message: "",
                type: "success"
            },
            loader: false,
            modals: {
                editSector: true
            },
            settings: {},
            model: {
                sector: {
                    variety: []
                }
            },
            components: {
                deleteSector: false
            }
        }
    },
    computed: {
        computedVariety() {
            console.log(this.model)
            let variety = []
            if (
                this.settings.varieties[this.model.sector.specie] != undefined
            ) {
                variety = this.settings.varieties[this.model.sector.specie]
            }
            return variety
        }
    },
    created() {
        this.settings = this.$store.getters["Settings/GET_SETTINGS"]
        this.model.sector = this.$COPY.copy(this.sector)
    },
    methods: {
        clearVariety() {
            this.model.variety = ""
        },
        validate() {
            let vm = this
            this.$validator.validateAll().then(isValid => {
                if (isValid) vm.update()
            })
        },
        update() {
            let vm = this
            let payload = this.$COPY.copy(this.model.sector)
            let farm_id = this.farm._id.$oid.toString()
            let sector_id = this.model.sector._id.$oid.toString()

            delete payload.subsectors

            vm.loader = true
            this.Services.Sectors.updateSector({ farm_id, sector_id, payload })
                .then(response => {
                    Swal.fire({
                        title: response.data.messages,
                        icon: "success"
                    })

                    vm.$emit("close")
                    vm.$emit("sectorUpdated", vm.sector)
                    vm.$emit("updateFarm")
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        deleted() {
            this.$emit("close")
            this.$emit("sectorUpdated")
            this.$emit("updateFarm")
        }
    }
}
</script>

<style lang="css">
</style>
