import Vue from "vue";
import Router from "vue-router";
// Variables
import CeresVariables from "./defines/CeresVariables";
// Layouts
import AuthLayout from "./layouts/AuthLayout";
import DashboardLayout from "./layouts/DashboardLayout";
// General
// import Page404 from "./views/System/Page404"
import LandingPage from "./views/LandingPage/LandingPage";

// Auth Views
import Login from "./views/Auth/Login";
// Profile Views
import Profile from "./views/Profile/Profile";
import ChangePassword from "./views/Profile/ChangePassword";
// Welcome Page
import Welcome from "./views/Welcome";
// Product Views
import Products from "./views/Products/Products";
import Product from "./views/Products/Components/ViewProduct";
import NewProduct from "./views/Products/Components/NewProduct";
import EditProduct from "./views/Products/Components/EditProduct";
// Farms Views
import Farms from "./views/Farms/Farms";
import Farm from "./views/Farms/Farm";
import NewFarm from "./views/Farms/NewFarm";
import EditFarm from "./views/Farms/EditFarm";
// HumanResources Views
import Workers from "./views/HumanResources/Workers";
// Labors
import Harvests from "./views/Labors/Harvests/Harvests";
import ViewHarvest from "./views/Labors/Harvests/ViewHarvest";
import EditHarvest from "./views/Labors/Harvests/EditHarvest";
import Prunings from "./views/Labors/Prunings/Prunings";
import ViewPruning from "./views/Labors/Prunings/ViewPruning";
import EditPruning from "./views/Labors/Prunings/EditPruning";
import PlagueMonitorings from "./views/Labors/PlagueMonitorings/PlagueMonitorings";
import ViewPlagueMonitoring from "./views/Labors/PlagueMonitorings/ViewPlagueMonitoring";
import EditPlagueMonitoring from "./views/Labors/PlagueMonitorings/EditPlagueMonitoring";
import IrrigationUpkeeps from "./views/Labors/IrrigationUpkeeps/IrrigationUpkeeps";
import ViewIrrigationUpkeep from "./views/Labors/IrrigationUpkeeps/ViewIrrigationUpkeep";
import EditIrrigationUpkeep from "./views/Labors/IrrigationUpkeeps/EditIrrigationUpkeep";

// Changelog
import Changelogs from "./views/Changelogs/Changelogs";
// TaskList
import BpmnDiagram from "./views/TaskList/BpmnDiagram";
import Processes from "./views/TaskList/Processes";
import StartProcess from "./views/TaskList/StartProcess";
import ContinueProcess from "./views/TaskList/ContinueProcess";
import WorkflowHistory from "./views/TaskList/Components/WorkflowHistory";
// Record views
import RecordsView from "./views/Labors/Components/RecordsView";
// Commercial views
import PackageFormats from "./views/Commercial/PackageFormats/PackageFormats";
import NewPackageFormat from "./views/Commercial/PackageFormats/NewPackageFormat";
import PackageFormatView from "./views/Commercial/PackageFormats/PackageFormatView";
// Reports
import Reports from "./views/Reports/Reports";
import ReportRender from "./views/Reports/ReportRender";
import BoxesPerWorker from "./views/Reports/Harvests/BoxesPerWorker";
import HarvestFarmStats from "./views/Reports/Harvests/HarvestFarmStats";
import ProductUses from "./views/Reports/Products/ProductUses";
import ProductStocks from "./views/Reports/Products/ProductStocks";
import ProductStockShortage from "./views/Reports/Products/ProductsStockShortage/ProductsStockShortage";
import PhenologicalStagesReport from "./views/Reports/PhenologicalStages/PhenologicalStages";
import Ceazamet from "./views/Reports/Weather/Ceazamet";
import ApplicationsByProduct from "./views/Reports/ProductApplications/ByProduct";
import FarmHarvestSummary from "./views/Reports/FarmHarvests/FarmHarvetSummary.vue";
// Tools
import Tools from "./views/Tools/Tools";
// Settings
import Settings from "./views/Settings/Settings";
// Sensors
import Sensors from "./views/Sensors/Sensors";

Vue.use(Router);

let landingPage = {
  path: "/",
  component: LandingPage,
  children: [
    {
      path: "/",
      name: "landing",
      component: LandingPage,
      meta: {
        title: "CERES",
      },
    },
  ],
};

let authPages = {
  path: "/auth",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/auth/login",
      name: "login",
      component: Login,
      meta: {
        title: "Login",
      },
    },
  ],
};

// let systemPages = {
//     path: "/",
//     component: DashboardLayout,
//     name: "Ups!",
//     children: [
//         {
//             path: "/not-found",
//             name: "not-found",
//             component: Page404,
//             meta: {
//                 title: "Ups!"
//             }
//         }
//     ]
// }

let dashboardPages = {
  path: "/dashboard",
  component: DashboardLayout,
  name: "Panel de Control",
  children: [
    {
      path: "/dashboard/profile",
      name: "user-profile",
      component: Profile,
      meta: {
        title: "Mi Perfil",
      },
    },
    {
      path: "/dashboard/password",
      name: "change-password",
      component: ChangePassword,
      meta: {
        title: "Cambiar Contraseña",
      },
    },
    {
      path: "/dashboard/products",
      name: "products",
      component: Products,
      meta: {
        title: "Inventario de Productos",
      },
    },
    {
      path: "/dashboard/product/new",
      name: "new-product",
      component: NewProduct,
      props: true,
      meta: {
        title: "Nuevo Producto",
      },
    },
    {
      path: "/dashboard/product/edit/:id",
      name: "edit-product",
      component: EditProduct,
      props: true,
      meta: {
        title: "Editar Producto",
      },
    },
    {
      path: "/dashboard/product/view/:id",
      name: "view-product",
      component: Product,
      meta: {
        title: "Detalle de Producto",
      },
    },
    {
      path: "/dashboard/welcome",
      name: "Bienvenido",
      component: Welcome,
      meta: {
        title: "Bienvenido",
      },
    },
    {
      path: "/dashboard/farms",
      name: "Mis Campos",
      component: Farms,
      meta: {
        title: "Mis Campos",
      },
    },
    {
      path: "/dashboard/farm/new",
      name: "Nuevo Campo",
      component: NewFarm,
      meta: {
        title: "Nuevo Campo",
        roles: ["admin"],
      },
    },
    {
      path: "/dashboard/farm/view/:id",
      name: "Administrar mi Campo",
      component: Farm,
      meta: {
        title: "Configurar mi Campo",
      },
    },
    {
      path: "/dashboard/farm/edit/:id",
      name: "Editar Campo",
      component: EditFarm,
      meta: {
        title: "Editar Campo",
        roles: ["admin"],
      },
    },
    {
      path: "/dashboard/human-resources/workers",
      name: "Colaboradores",
      component: Workers,
      meta: {
        title: "Colaboradores",
      },
    },
    {
      name: "Changelogs",
      path: "/changelogs",
      component: Changelogs,
      meta: {
        title: "Registro de Cambios",
      },
    },
    {
      path: "/tasklist/:view",
      name: "tasklist",
      props: true,
      component: Processes,
      meta: {
        title: "Mis Actividades",
      },
    },
    {
      path: "/tasklist/start-process/:id",
      name: "tasklist-start-process",
      component: StartProcess,
      props: true,
      meta: {
        title: "Iniciar Nueva Actividad",
        roles: CeresVariables.data.permissions.supervisors,
      },
    },
    {
      path: "/tasklist/continue-process/:processKey/:id/:state",
      name: "continue-process",
      component: ContinueProcess,
      props: true,
      meta: {
        title: "Continuar Actividad",
      },
    },
    {
      path: "/tasklist/:processKey/:id?/diagram/:state",
      name: "diagram",
      component: BpmnDiagram,
      props: true,
      meta: {
        title: "Diagrama BPMN",
      },
    },
    {
      path: "/tasklist/task/:processKey/:id/history",
      name: "workflow-history",
      component: WorkflowHistory,
      props: true,
      meta: {
        title: "Historial de cambios de estado",
      },
    },
    {
      path: "/labors/harvests",
      name: "harvests",
      component: Harvests,
      meta: {
        title: "Cosechas",
      },
    },
    {
      path: "/labors/harvest/view/:id",
      name: "view-harvest",
      component: ViewHarvest,
      props: true,
      meta: {
        title: "Detalle de Cosecha",
      },
    },
    {
      path: "/labors/harvest/edit/:id",
      name: "edit-harvest",
      component: EditHarvest,
      props: true,
      meta: {
        title: "Editar Cosecha",
      },
    },
    {
      path: "/dashboard/settings",
      name: "settings",
      component: Settings,
      meta: {
        title: "Configuración",
        roles: ["admin"],
      },
    },
  ],
};

let laborPages = {
  path: "/labors",
  component: DashboardLayout,
  name: "Labores",
  children: [
    {
      path: "/labor/:processKey/records/",
      name: "records-view",
      component: RecordsView,
      props: true,
    },
    {
      path: "/labors/prunings",
      name: "prunings",
      component: Prunings,
      meta: {
        title: "Podas",
      },
    },
    {
      path: "/labors/pruning/view/:id",
      name: "view-pruning",
      component: ViewPruning,
      props: true,
      meta: {
        title: "Detalle de la Poda",
      },
    },
    {
      path: "/labors/pruning/edit/:id",
      name: "edit-pruning",
      component: EditPruning,
      props: true,
      meta: {
        title: "Editar Poda",
      },
    },
    {
      path: "/labors/plague-monitorings",
      name: "plague-monitorings",
      component: PlagueMonitorings,
      meta: {
        title: "Monitoreo de Plagas",
      },
    },
    {
      path: "/labors/plague-monitoring/view/:id",
      name: "view-plague-monitoring",
      component: ViewPlagueMonitoring,
      props: true,
      meta: {
        title: "Informe de Monitoreo de Plagas",
      },
    },
    {
      path: "/labors/plague-monitoring/edit/:id",
      name: "edit-plague-monitoring",
      component: EditPlagueMonitoring,
      props: true,
      meta: {
        title: "Editar Informe de Monitoreo de Plagas",
      },
    },
    {
      path: "/labors/irrigation-upkeeps",
      name: "irrigation-upkeeps",
      component: IrrigationUpkeeps,
      meta: {
        title: "Mantenimiento del Sistema de Riego",
      },
    },
    {
      path: "/labors/irrigation-upkeep/view/:id",
      name: "view-irrigation-upkeep",
      component: ViewIrrigationUpkeep,
      meta: {
        title: "Mantenimiento del Sistema de Riego",
      },
    },
    {
      path: "/labors/irrigation-upkeep/edit/:id",
      name: "edit-irrigation-upkeep",
      component: EditIrrigationUpkeep,
      props: true,
      meta: {
        title: "Editar Tarea de Mantenimiento del Sistema de Riego",
      },
    },
  ],
};

let commercialPages = {
  path: "/commercial",
  component: DashboardLayout,
  name: "Comercial",
  children: [
    {
      path: "/commercial/:processKey/records",
      name: "commercial-record-views",
      component: RecordsView,
      meta: {
        title: "Ventas",
      },
    },
    {
      path: "/commercial/package-formats",
      name: "package-formats",
      component: PackageFormats,
      meta: {
        title: "Empaquetar",
      },
    },
    {
      path: "/commercial/package-format/new",
      name: "new-package-format",
      component: NewPackageFormat,
      meta: {
        title: "Crear formato de venta",
      },
    },
    {
      path: "/commercial/package-format/view/:id",
      name: "package-format",
      component: PackageFormatView,
      meta: {
        title: "Formato de venta",
      },
    },
  ],
};

let reportPages = {
  path: "/reports",
  component: DashboardLayout,
  name: "Informes",
  children: [
    {
      path: "/reports",
      name: "reports",
      component: Reports,
      meta: {
        title: "Informes",
      },
    },
    {
      path: "/report/:id",
      name: "report",
      component: ReportRender,
      meta: {
        title: "Informe",
      },
    },
    {
      path: "boxes-per-worker",
      name: "boxes-per-worker",
      component: BoxesPerWorker,
      props: true,
      meta: {
        title: "Informe de Cosecha por Cosechero",
      },
    },
    {
      path: "farm-harvests-summary",
      name: "farm-harvests-summary",
      component: FarmHarvestSummary,
      props: true,
      meta: {
        title: "Informe de Cosecha Global",
      },
    },
    {
      path: "harvest-farm-stats",
      name: "harvest-farm-stats",
      component: HarvestFarmStats,
      props: true,
      meta: {
        title: "Informe de Cosecha Global",
      },
    },
    {
      path: "product-uses",
      name: "product-uses",
      component: ProductUses,
      props: true,
      meta: {
        title: "Informe de Uso de Productos",
      },
    },
    {
      path: "product-stocks",
      name: "product-stocks",
      component: ProductStocks,
      props: true,
      meta: {
        title: "Informe de stock de productos",
      },
    },
    {
      path: "product-stock-shortage",
      name: "product-stock-shortage",
      component: ProductStockShortage,
      props: true,
      meta: {
        title: "Informe de Quiebres de Stock",
      },
    },
    {
      path: "product-applications-by-product",
      name: "product-applications-by-product",
      component: ApplicationsByProduct,
      props: true,
      meta: {
        title: "Informe de aplicaciones por producto",
      },
    },
    {
      path: "phenological-stages-report",
      name: "phenological-stages-report",
      component: PhenologicalStagesReport,
      props: true,
      meta: {
        title: "Informe de Estados Fenológicos",
      },
    },
    {
      path: "weather-ceazemet",
      name: "weather-ceazemet",
      component: Ceazamet,
      meta: {
        title: "Boletines meteorológicos generados por CEAZEMET",
      },
    },
  ],
};

let toolPages = {
  path: "/tools",
  component: DashboardLayout,
  name: "Herramientas",
  children: [
    {
      path: "/tools",
      name: "tools",
      component: Tools,
      meta: {
        title: "Calculadoras",
      },
    },
  ],
};

let sensorPages = {
  path: "/sensors",
  component: DashboardLayout,
  name: "Sensores",
  children: [
    {
      path: "/sensors",
      name: "sensors",
      component: Sensors,
      meta: {
        title: "Sensores",
      },
    },
  ],
};

let defaultPath = {
  path: "*",
  redirect: "/",
};

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    authPages,
    dashboardPages,
    reportPages,
    toolPages,
    laborPages,
    commercialPages,
    sensorPages,
    landingPage,
    defaultPath,
  ],
});
