const formControls = {
  props: {
    value: { type: [String, Date, Number, Array] },
    name: { type: String },
    label: { type: String, required: true },
    placeholder: { type: String },
    required: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    event: "input",
    prop: "value",
  },
  computed: {
    rules() {
      return { required: this.required };
    },
    isRequired() {
      return this.rules["required"];
    },
  },
};

export default formControls;
