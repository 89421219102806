<template lang="html">
  <card
    v-loading="loader">
    <template slot="header">
      <card-controls
        :back="true"
        back-target-name="reports"/>

      <h4 class="card-title"><b>Informe <br>Consumo Anual Por Producto</b></h4>
    </template>

    <div
      v-if="!loader"
      class="row stat-cards">
      <div class="col-md-6 col-sm-12">
        <stats-card
          :title="getBestProduct"
          class="report-card"
          type="success"
          sub-title="Producto Más Utilizado del Año"
          icon="fal fa-thumbs-up"/>
      </div>
      <div class="col-md-6 col-sm-12">
        <stats-card
          :title="stockShortagesCount | toString"
          class="report-card"
          type="warning"
          sub-title="Productos con quiebres de stock futuro"
          icon="fas fa-exclamation"/>
      </div>
    </div>

    <div
      v-if="!loader"
      class="row">

      <tabs
        type="primary"
        class="col-md-12 product-tabs">
        <tab-pane
          label="Consumo anual por producto">
          <div class="col-md-12 col-sm-12 pull-center desktop-table">
            <el-table
              ref="productUsesTable"
              :data="reportData"
              :empty-text="'No hay registros'"
              :row-class-name="productRowClassName"
              class="table-striped">

              <el-table-column
                :filters="yearsToFilter"
                :filter-method="filterByYear"
                sortable
                label="Año"
                name="year"
                prop="_id.year"
                align="center"
                class-name="table-category"/>

              <el-table-column
                :formatter="productCategoryFormatter"
                label="Categoría"
                name="category"
                align="center"
                class-name="table-category"/>

              <el-table-column
                :formatter="productNameFormatter"
                :filters="productsToFilter"
                :filter-method="filterByProduct"
                label="Producto"
                prop="_id.productId.toString()"
                name="product"
                align="center"
                class-name="table-category"/>

              <el-table-column
                label="Ingrediente activo"
                prop="_id.productId.toString()"
                name="active_ingredient"
                align="center"
                class-name="table-category">
                <div
                  slot-scope="{row}"
                  class="text-left">
                  <span
                    v-for="(active_ingredient,key) in findProduct(row._id.productId).active_ingredient"
                    :key="key">
                    {{ active_ingredient.value }} <br>
                  </span>
                </div>
              </el-table-column>

              <el-table-column
                :formatter="productUseFormatter"
                label="Consumo actual"
                name="actualUse"
                prop="actualUse"
                align="center"
                class-name="table-category"/>

              <el-table-column
                :formatter="productUseFormatter"
                label="Consumo Reservado"
                name="scheduledUse"
                prop="scheduledUse"
                align="center"
                class-name="table-category"/>

              <el-table-column
                :formatter="productUseFormatter"
                label="Consumo Anual Proyectado"
                name="yearUse"
                prop="yearUse"
                align="center"
                class-name="table-category"/>

              <el-table-column
                :formatter="productStockFormatter"
                label="Stock Actual"
                name="stock"
                align="center"
                class-name="table-category"/>

              <el-table-column
                :formatter="futureStockFormatter"
                label="Stock Futuro"
                name="future_stock"
                align="center"
                class-name="table-category"/>

            </el-table>
          </div>
        </tab-pane>

        <tab-pane
          label="Quiebres de stock">
          <span slot="label">
            Quiebres de stock <badge
              :type="'warning'"
              :content="stockShortagesCount | toString"/>
          </span>

          <products-stock-shortage-content
            v-if="!loader"
            :products="products"
            :applications="applications"
            :sectors="sectors"
            :report-data="reportData"/>
        </tab-pane>

      </tabs>
    </div>
  </card>
</template>

<script>
import { Table, TableColumn, Tooltip } from "element-ui"
import { Tabs, TabPane } from "nowui/components"
import ProductsStockShortageContent from "./ProductsStockShortage/Components/Content"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Tooltip.name]: Tooltip,
        Tabs,
        TabPane,
        ProductsStockShortageContent
    },
    data() {
        return {
            loader: true,
            reportData: [],
            products: [],
            applications: [],
            sectors: []
        }
    },
    computed: {
        getBestProduct() {
            let currentYear = this.$moment().format("YYYY")
            let products = this.reportData.filter(
                item => item._id.year == currentYear
            )
            let winner = products.reduce((prev, current) => {
                if (Object.keys(prev).length == 0) {
                    let prevTotal = prev.actualUse + prev.scheduledUse
                    let currentTotal = current.actualUse + current.scheduledUse
                    if (prevTotal > currentTotal) {
                        return prevTotal
                    } else {
                        currentTotal
                    }
                } else {
                    return current
                }
            })
            return this.findProduct(winner._id.productId).name
        },
        yearsToFilter() {
            let years = [...new Set(this.reportData.map(item => item._id.year))]
            return years.map(item => {
                return {
                    value: item,
                    text: item
                }
            })
        },
        productCategoriesToFilter() {
            let vm = this
            let products = [
                ...new Set(
                    this.reportData.map(item => item._id.product.toString())
                )
            ]
            let productCategories = products.reduce((categories, item) => {
                let product = vm.findProduct(item)
                if (
                    categories.map(c => c.value).indexOf(product.category) ===
                    -1
                ) {
                    let category = product.category
                    categories.push({
                        value: category,
                        text:
                            category.charAt(0).toUpperCase() + category.slice(1)
                    })
                }
                return categories
            }, [])
            return productCategories
        },
        productsToFilter() {
            let vm = this
            let products = [
                ...new Set(
                    this.reportData.map(item => item._id.productId.toString())
                )
            ]
            let productList = products.map(product_id => {
                return {
                    value: product_id,
                    text: vm.findProduct(product_id).name
                }
            })
            return productList.sort(
                (a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0)
            )
        },
        activeIngredientsToFilter() {
            let vm = this
            let products = [
                ...new Set(
                    this.reportData.map(item => item._id.productId.toString())
                )
            ]
            let activeIngredientsList = []
            products.map(product_id => {
                let activeIngredients = vm.findProduct(product_id)
                    .active_ingredient
                if (activeIngredients != undefined) {
                    if (activeIngredients.length > 0) {
                        activeIngredients.map(i => i.value).forEach(item => {
                            if (item != "") {
                                activeIngredientsList.push({
                                    value: product_id,
                                    text: item
                                })
                            }
                        })
                    }
                }
            })
            return activeIngredientsList.sort(
                (a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0)
            )
        },
        stockShortagesCount() {
            let shortageCout = 0
            this.reportData.forEach(item => {
                let product = this.findProduct(item._id.productId)
                let total = product.stock - item.scheduledUse
                if (total < 0) {
                    shortageCout += 1
                }
            })
            return shortageCout
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            let vm = this
            this.$store
                .dispatch("Reports/productUses")
                .then(response => {
                    vm.reportData = vm.$EJSON.parse(
                        response.data.variables.report.value,
                        {
                            strict: false
                        }
                    )
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.getProducts()
                })
        },
        getProducts() {
            let vm = this
            this.$store
                .dispatch("Warehouse/getProducts", {})
                .then(response => {
                    vm.products = vm.$EJSON.parse(
                        response.data.variables.products.value,
                        { strict: false }
                    )
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.getApplications()
                })
        },
        getApplications() {
            let vm = this

            this.$store
                .dispatch("Labors/getApplications", {})
                .then(response => {
                    vm.applications = vm.$EJSON.parse(
                        response.data.variables.applications.value,
                        { relaxed: true }
                    )
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.getSectors()
                })
        },
        getSectors() {
            let vm = this
            let payload = {
                farm_id: vm.$store.getters["Farms/GET_FARM"]._id.toString()
            }

            this.$store
                .dispatch("Sectors/getSectors", { payload })
                .then(response => {
                    vm.sectors = vm.$EJSON.parse(
                        response.data.variables.sectors.value,
                        {
                            strict: false
                        }
                    )
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        getActiveIngredients(row) {
            this.findProduct(row._id.productId).active_ingredient
        },
        // filter methods
        clearFilter() {
            this.$refs.productUsesTable.clearFilter()
        },
        filterByYear(value, row, column) {
            return row._id.year == value
        },
        filterByProduct(value, row, column) {
            console.log(value)
            return row._id.productId.toString() == value
        },
        filterByProductCategory(value, row, column) {
            let productsInCategory = this.products
                .filter(product => product.category == value)
                .map(product => product._id.toString())
            productsInCategory.includes(row._id.product.toString())
        },
        // column formatters
        findProduct(product_id) {
            return this.products.filter(
                item => item._id.toString() === product_id.toString()
            )[0]
        },
        productNameFormatter(row, column) {
            return this.findProduct(row._id.productId).name
        },
        productCategoryFormatter(row, column) {
            return this.findProduct(row._id.productId).category
        },
        productUseFormatter(row, column, cellValue, index) {
            return (
                cellValue.toFixed(2) +
                " " +
                this.findProduct(row._id.productId).measure_unit
            )
        },
        productStockFormatter(row, column, cellValue) {
            let product = this.findProduct(row._id.productId)
            return product.stock.toFixed(2) + " " + product.measure_unit
        },
        futureStockFormatter(row) {
            let product = this.findProduct(row._id.productId)
            let total = product.stock - row.scheduledUse
            return total.toFixed(2) + " " + product.measure_unit
        },
        stockShortageFormatter(row) {
            let shortageDate = ""
            let product = this.findProduct(row._id.product)
            let currentStock = product.stock
            let futureApplications = []
            product.future_applications.forEach(item => {
                let application = this.applications.filter(
                    a => a._id.toString() == item.toString()
                )[0]
                futureApplications.push(application)
            })
            futureApplications = futureApplications.sort(
                (a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0)
            )
            futureApplications.forEach(item => {
                let application = item.used_products.filter(
                    p => p.product_id.toString() == product._id.toString()
                )[0]
                let tempStock = currentStock - application.use
                if (tempStock < 0 && shortageDate == "") {
                    shortageDate = item.date
                } else {
                    currentStock = tempStock
                }
            })
            shortageDate = this.$moment(shortageDate)
            return (
                shortageDate.endOf("day").fromNow() +
                "\n (" +
                shortageDate.format("DD-MM-YYYY") +
                ")"
            )
        },
        // row formatter
        productRowClassName({ row }) {
            let product = this.findProduct(row._id.productId)
            let total = product.stock - row.scheduledUse
            if (total < 0) {
                return "warning-row"
            } else {
                return ""
            }
        }
    }
}
</script>

<style lang="sass" scoped>
  .card-title
    margin-top: 20px !important
  .section-title
    margin-top: 50px
</style>
