var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 col-sm-12 pull-center desktop-table"},[_c('el-table',{staticClass:"table-striped",attrs:{"data":_vm.data,"empty-text":"No hay aplicaciones registradas"}},[(_vm.showStatus)?_c('el-table-column',{attrs:{"prop":"status","label":"Estado","align":"center","name":"status","column-key":"status","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-tag',{staticClass:"used-product-tag",attrs:{"closable":false,"close-transition":false,"type":_vm.$TASK_STATES.getColor(row.workflow.state),"size":"small"}},[_vm._v(" "+_vm._s(_vm.$t(row.workflow.state))+" ")])],1)}}],null,false,1504213643)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"type","label":"Tipo de aplicación","align":"left"}}),_c('el-table-column',{attrs:{"prop":"date","label":"Fecha","align":"center","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('i',{staticClass:"el-icon-time"}),_vm._v(" "+_vm._s(_vm._f("moment")(row.date,"DD-MM-YYYY"))+" "),_c('br'),_vm._v(" ("+_vm._s(_vm._f("moment")(row.date,"dddd"))+") ")])}}])}),_c('el-table-column',{attrs:{"label":"Cuarteles","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[(_vm.hasAllSectors(row.sectors))?[_c('el-tag',{staticClass:"used-product-tag",attrs:{"closable":false,"close-transition":false,"size":"small","type":"primary"}},[_vm._v(" Todos ")])]:_vm._l((row.sectors),function(sector,index){return _c('el-tag',{key:index,staticClass:"used-product-tag",attrs:{"closable":false,"close-transition":false,"size":"small","type":"primary"}},[_vm._v(" "+_vm._s(sector.name)+" ")])})],2)}}])}),_c('el-table-column',{attrs:{"label":"Productos utilizados","align":"left","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-left"},_vm._l((row.used_products),function(used_product){return _c('div',{key:row._id.$oid+'_'+used_product.product._id.$oid},[_vm._v(" "+_vm._s(used_product.product.name)+" ("+_vm._s(_vm._f("toLocaleStringNumber")(used_product.use,"decimal"))+" "+_vm._s(used_product.product.measure_unit)+") ")])}),0)}}])}),_c('el-table-column',{attrs:{"label":"Acciones","align":"center","class-name":"table-category actions-column","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-center"},[_c('n-button',{attrs:{"type":"primary","size":"sm"},nativeOn:{"click":function($event){return _vm.view(row)}}},[_c('i',{staticClass:"fal fa-eye"})])],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }