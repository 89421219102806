<template lang="html">
  <card class="report-card">
    <template slot="header">
      <card-controls
        :back="true"
        back-target-name="reports"/>

      <h4 class="card-title"><b>Informe Quiebres de Stock</b></h4>
    </template>

    <products-stock-shortage-content />

  </card>
</template>

<script>
import ProductsStockShortageContent from "./Components/Content.vue"
export default {
    components: {
        ProductsStockShortageContent
    }
}
</script>

<style lang="sass" scoped>
	.report-card
		min-height: 200px
</style>
