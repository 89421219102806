import API from "@/services/Processes";
import Helpers from "@/utils/Helpers";
import { get } from "lodash";

const Processes = {
  namespaced: true,
  state: {
    processes: [],
    allProcesses: [],
    loading: false,
  },
  mutations: {
    setProcesses: (state, { processes, enabled = [] }) => {
      state.allProcesses = processes;
      state.processes = processes.filter((i) => enabled.includes(i.model));
    },
    setLoading: (state, value) => (state.loading = value),
  },
  getters: {
    getModelName: (state) => (workflowName) => {
      const process = state.processes.find((i) => i.workflow == workflowName);
      return process ? Helpers.classNameToString(process.model) : "";
    },
  },
  actions: {
    getProcesses: async ({ commit, rootGetters, rootState }) => {
      try {
        commit("setLoading", true);
        const farmId = rootGetters["Farms/GET_FARM_ID"];
        const enabled = get(
          rootState,
          "Settings.settings.activities.enabled.value",
          []
        );
        if (farmId) {
          const response = await API.getProcesses(farmId);
          commit("setProcesses", { processes: response.data, enabled });
        }
      } catch (error) {
        console.log(error);
      } finally {
        commit("setLoading", false);
      }
    },
  },
};

export default Processes;
