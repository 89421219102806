import { ceres_api } from "@/axios-config.js";

const FarmHarvests = {
  getFarmHarvests(farm_id, filter) {
    let endpoint = "/farms/" + farm_id + "/farm_harvests";
    if (filter == undefined) {
      return ceres_api.get(endpoint);
    } else {
      return ceres_api.post(endpoint + "/filter", { filter: filter });
    }
  },
  getFarmHarvest(farm_id, id) {
    let endpoint = "/farms/" + farm_id + "/farm_harvests/";
    return ceres_api.get(endpoint + id);
  },
  createFarmHarvest(farm_id, harvest) {
    let endpoint = "/farms/" + farm_id + "/farm_harvests";
    return ceres_api.post(endpoint, harvest);
  },
  updateFarmHarvest(farm_id, id, harvest) {
    let endpoint = "/farms/" + farm_id + "/farm_harvests/";
    return ceres_api.put(endpoint + id, harvest);
  },
  deleteFarmHarvest(farm_id, id) {
    let endpoint = "/farms/" + farm_id + "/farm_harvests/";
    return ceres_api.delete(endpoint + id);
  },
};

export default FarmHarvests;
