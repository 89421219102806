var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.render),expression:"!render"}],staticClass:"row"},[(_vm.render)?_c('div',{staticClass:"col-md-10 offset-md-1"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"info"},[_vm._m(0),_c('h5',{staticClass:"info-title"},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.model.workflow.state)))])]),_c('h6',{staticClass:"stats-title"},[_vm._v("Estado")])])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"info"},[_vm._m(1),(_vm.model.hasOwnProperty('date'))?_c('h5',{staticClass:"info-title"},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.model.date,"DD-MM-YYYY")))])]):_vm._e(),_c('h6',{staticClass:"stats-title"},[_vm._v("Fecha")])])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"info"},[_c('div',{class:'icon icon-'+_vm.anyToleranceExceeded.class},[_c('i',{staticClass:"fal fa-bug"})]),_c('h5',{staticClass:"info-title"},[_c('span',[_vm._v(_vm._s(_vm.anyToleranceExceeded.text))])]),_c('h6',{staticClass:"stats-title"},[_vm._v("¿Tolerancia superada?")])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-left"},[_c('h5',{staticClass:"plague-title"},[_vm._v("Sectores "+_vm._s(_vm.getSectorDescription))]),_c('sector-tags',{attrs:{"current_sectors":_vm.model.show_sectors,"sectors":_vm.sectors}})],1)]),_c('div',{staticClass:"row mt-4"},[_vm._m(2),_c('div',{staticClass:"col-md-12"},[_c('el-table',{attrs:{"data":_vm.dangerPlagues,"empty":"No hay plagas"}},[_c('el-table-column',{attrs:{"label":"Plaga detectada","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getPlagueName(row.plague_id.$oid))}})])}}],null,false,3668683659)}),_c('el-table-column',{attrs:{"label":"Cuartel","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.sector.name)+" ")])}}],null,false,3333561162)}),_c('el-table-column',{attrs:{"label":"Número de hilera","prop":"line_number","align":"center"}}),_c('el-table-column',{attrs:{"label":"Número de planta","prop":"plant_number","align":"center"}}),_c('el-table-column',{attrs:{"label":"Conteo","prop":"count","align":"center"}}),_c('el-table-column',{attrs:{"label":"Tolerancia","prop":"tolerance","align":"center"}}),_c('el-table-column',{attrs:{"label":"Diferencia","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.tolerance - row.count)+" ")])}}],null,false,2400669660)})],1)],1)]),_c('div',{staticClass:"row mt-4"},[_vm._m(3),_c('div',{staticClass:"col-md-12"},[(_vm.model.show_sectors.length > 0)?_c('el-tabs',{attrs:{"type":"border-card"},model:{value:(_vm.currentSector),callback:function ($$v) {_vm.currentSector=$$v},expression:"currentSector"}},_vm._l((_vm.model.show_sectors.map(function (i) { return i.$oid; })),function(sector){return _c('el-tab-pane',{key:sector,attrs:{"name":sector,"label":sector}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$HELPERS.getSectorNames(sector, _vm.sectors))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('el-table',{attrs:{"data":_vm.getSectorObservations(sector),"empty-text":"No hay observaciones registradas"}},[_c('el-table-column',{attrs:{"type":"expand","row-class-name":"warning-row"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('el-table',{attrs:{"data":props.row.plague_detections}},[_c('el-table-column',{attrs:{"label":"Plaga detectada","min-width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getPlagueName(row.plague_id.$oid))}})])}}],null,true)}),_c('el-table-column',{attrs:{"label":"Conteo","prop":"count","align":"center"}}),_c('el-table-column',{attrs:{"label":"Tolerancia","prop":"tolerance","align":"center"}}),_c('el-table-column',{attrs:{"label":"Diferencia","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.tolerance - row.count)+" ")])}}],null,true)})],1)]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"line_number","label":"Número de hilera","align":"center","row-class-name":"warning-row"}}),_c('el-table-column',{attrs:{"prop":"plant_number","label":"Número de planta","align":"center"}}),_c('el-table-column',{attrs:{"formatter":_vm.plaguesDetectedFormatter,"prop":"plagues_detected","label":"¿Se detectaron plagas?","align":"center"}})],1)],1)])])}),1):_vm._e()],1)]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-12"},[_vm._t("actions")],2)])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon icon-info"},[_c('i',{staticClass:"fal fa-info-circle"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon icon-info"},[_c('i',{staticClass:"fal fa-calendar"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 text-left"},[_c('h5',[_vm._v("Plagas que superan la tolerancia objetivo")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 text-left"},[_c('h5',[_vm._v("Registro de observaciones")])])}]

export { render, staticRenderFns }