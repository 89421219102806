<template lang="html">
  <card>
    <template slot="header">
      <card-controls :back="true" back-target-name="reports" />

      <h4 class="card-title">
        <b>Informe <br />{{ title }}</b>
      </h4>
    </template>

    <div class="row">
      <div class="col-lg-10 offset-lg-1">
        <component
          :is="$route.params.id"
          @setTitle="setTitle"
          @showErrors="setErrors"
          @showInfo="setInfo"
        />
      </div>
      <div class="col-lg-10 offset-lg-1">
        <notification :messages="messages" :close="true" :type="messagesType" />
      </div>
    </div>
  </card>
</template>

<script>
import * as Reports from "@/views/Reports";
export default {
  components: {
    ...Reports.FinanceReports,
    ...Reports.MarketReports,
    ...Reports.WeatherReports,
  },
  data() {
    return {
      title: "",
      messages: {},
      messagesType: "error",
    };
  },
  methods: {
    setTitle(value) {
      this.title = value;
    },
    setErrors(errors) {
      this.messagesType = "error";
      this.messages = errors;
    },
    setInfo(info) {
      this.messagesType = "info";
      this.messages = info;
    },
  },
};
</script>

<style lang="sass" scoped></style>
