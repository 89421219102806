<template lang="html">
  <card>
    <div slot="header">
      <h4 class="card-title"><b>Calculadoras</b></h4>
    </div>

    <div class="row">
      <template v-for="(tool, key) in tools">
        <div
          v-if="isAllowed(tool)"
          :key="key"
          class="col-md-4"
          @click="openTool(tool)"
        >
          <el-card shadow="hover">
            <h6>{{ tool.name }}</h6>
            <p>{{ tool.description }}</p>
          </el-card>
        </div>
      </template>
    </div>

    <cost-per-sector-calc
      v-if="components.costPerSectorCalc"
      @close="components.costPerSectorCalc = false"
    />

    <polinization-calc
      v-if="components.polinizationCalc"
      @close="components.polinizationCalc = false"
    />
  </card>
</template>

<script>
import { Card } from "element-ui";
import { mapState } from "vuex";
import CostPerSectorCalc from "./CostPerSectorCalc/CostPerSectorCalc";
import PolinizationCalc from "./PolinizationCalc/PolinizationCalc";
import { intersection } from "lodash";

export default {
  components: {
    [Card.name]: Card,
    CostPerSectorCalc,
    PolinizationCalc,
  },
  data() {
    return {
      components: {
        costPerSectorCalc: false,
        polinizationCalc: false,
      },
      tools: [
        {
          name: "Costo por cuartel o campo",
          urlName: "costPerSectorCalc",
          description:
            "Estima costos utilizando el número de plantas o la superficie por cuartel",
        },
        {
          name: "Polinización",
          process: ["Polinization"],
          urlName: "polinizationCalc",
          description:
            "Estima el costo y las colmenas necesarias para una polinización",
        },
      ],
    };
  },
  computed: {
    ...mapState("Processes", ["processes"]),
  },
  methods: {
    openTool(tool) {
      this.components[tool.urlName] = true;
    },
    isAllowed(tool) {
      if (tool.process == undefined) return true;

      const allowed = this.processes.map((i) => i.model);
      return intersection(allowed, tool.process).length > 0;
    },
  },
};
</script>

<style lang="sass" scoped>
.tool
  cursor: pointer
</style>
