import { render, staticRenderFns } from "./WeedingTable.vue?vue&type=template&id=3ce477a8&scoped=true&lang=html&"
import script from "./WeedingTable.vue?vue&type=script&lang=js&"
export * from "./WeedingTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ce477a8",
  null
  
)

export default component.exports