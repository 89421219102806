<template lang="html">
  <card
    v-loading="loader">
    <template slot="header">
      <card-controls
        :back="true"
        back-target-name="reports"/>

      <h4 class="card-title"><b>Informe Stocks de Productos</b></h4>
    </template>

    <div
      v-if="!loader"
      class="row">

      <tabs
        type="primary"
        class="col-md-12 product-tabs">
        <tab-pane
          label="Stocks por producto">
          <div class="col-md-12 col-sm-12 pull-center desktop-table">
            <el-table
              :data="products"
              :empty-text="'No hay registros'"
              :row-class-name="productRowClassName"
              max-height="600"
              class="table-striped">

              <el-table-column
                label="Categoría"
                name="category"
                prop="category"
                align="center"
                class-name="capitalize"
                sortable />

              <el-table-column
                label="Producto"
                name="name"
                prop="name"
                align="center"
                sortable />

              <el-table-column
                :formatter="stockFormatter"
                label="Stock actual"
                name="computed_stock"
                prop="computed_stock"
                align="center"
                sortable />

              <el-table-column
                :formatter="stockFormatter"
                label="Compras totales"
                name="computed_purchases"
                prop="computed_purchases"
                align="center"
                sortable />

              <el-table-column
                :formatter="stockFormatter"
                label="Pérdidas totales"
                name="computed_losses"
                prop="computed_losses"
                align="center"
                sortable />

              <el-table-column
                :formatter="stockFormatter"
                label="Reservas por aplicaciones"
                name="computed_reservations"
                prop="computed_reservations"
                align="center"
                sortable />

              <el-table-column
                :formatter="stockFormatter"
                label="Ingresos futuros"
                name="computed_future_arrivals"
                prop="computed_future_arrivals"
                align="center"
                sortable />

              <el-table-column
                :formatter="stockFormatter"
                label="Stock futuro"
                name="computed_future_stock"
                prop="computed_future_stock"
                align="center"
                sortable />

            </el-table>
          </div>
        </tab-pane>

        <tab-pane
          label="Quiebres de stock">
          <span slot="label">
            Quiebres de stock <badge
              v-if="products.length > 0"
              :content="products.filter(i => i.computed_future_stock < 0).length | toString"
              type="warning"/>
          </span>

          <products-stock-shortage-content />
        </tab-pane>

      </tabs>
    </div>
  </card>
</template>

<script>
import { Table, TableColumn, Tooltip } from "element-ui"
import { Tabs, TabPane } from "nowui/components"
import ProductsStockShortageContent from "./ProductsStockShortage/Components/Content"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Tooltip.name]: Tooltip,
        Tabs,
        TabPane,
        ProductsStockShortageContent
    },
    data() {
        return {
            loader: true,
            products: [],
            countProductStockBreaks: 0
        }
    },
    created() {
        this.getProducts()
    },
    methods: {
        getProducts() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Products.getProducts(farm_id)
                .then(response => {
                    vm.products = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    vm.loader = false
                })
        },
        productRowClassName({ row }) {
            if (row.computed_future_stock < 0) {
                return "warning-row"
            } else {
                return ""
            }
        },
        stockFormatter(row, column, cellValue) {
            return (
                this.$options.filters.toLocaleStringNumber(
                    cellValue,
                    "decimal"
                ) +
                " " +
                row.measure_unit
            )
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
