<template lang="html">
  <card v-loading="loader">
    <template slot="header">
      <card-controls :back="true" :back-custom-target="viewRoute" />

      <h4 class="card-title"><b>Editar de la Poda</b></h4>
    </template>

    <div class="col-md-10 offset-md-1">
      <pruning-form
        ref="pruningForm"
        :messages="messages"
        :run-validation="runValidation"
        :init-model="initModel"
        @validForm="validForm"
        @modelUpdated="updateModel"
      >
        <template slot="actions">
          <n-button simple="" @click.native="$router.go(-1)"> Cerrar </n-button>
          <n-button type="primary" @click.native="validate()">
            Guardar Cambios
          </n-button>
        </template>
      </pruning-form>
    </div>
  </card>
</template>

<script>
import PruningForm from "@/views/Labors/Prunings/Components/PruningForm";
import Swal from "sweetalert2";

export default {
  components: {
    PruningForm,
  },
  props: {
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loader: false,
      runValidation: false,
      messages: {},
      model: {},
      initModel: {},
      viewRoute: {
        name: "view-pruning",
        params: {
          id: this.$route.params.id,
        },
      },
    };
  },
  created() {
    if (Object.keys(this.record).length == 0) {
      this.$router.push({
        name: "view-pruning",
        params: {
          id: this.$route.params.id,
        },
      });
    } else {
      this.model = this.Services.Prunings.simplifyPruning(this.record);
      this.initModel = this.$COPY.copy(this.model);
    }
  },
  methods: {
    updateModel(model) {
      this.model = this.$COPY.copy(model);
      this.saveButton = true;
    },
    validate() {
      this.loader = true;
      this.messages = {};
      this.runValidation = true;
    },
    validForm(isValid, model) {
      this.runValidation = false;
      isValid ? this.saveEditedModel(model) : (this.loader = false);
    },
    saveEditedModel(model) {
      let vm = this;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      console.log(model);
      this.Services.Prunings.updatePruning(
        farm_id,
        this.initModel._id.$oid,
        model
      )
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          }).then(() => {
            vm.$router.push(vm.viewRoute);
          });
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response.data != "string") {
            vm.messages = error.response.data;
          }
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
};
</script>

<style lang="css" scoped></style>
