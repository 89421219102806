import { ceres_api } from "@/axios-config.js"

const Purchases = {
    createPurchase(farm_id, model) {
        let endpoint = "/farms/" + farm_id + "/purchases"
        return ceres_api.post(endpoint, model)
    },
    getPurchases(farm_id, filter) {
        let endpoint = "/farms/" + farm_id + "/purchases"
        if (filter == undefined) {
            return ceres_api.get(endpoint)
        } else {
            return ceres_api.post(endpoint + "/filter", { filter: filter })
        }
    },
    getPurchase(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/purchases/"
        return ceres_api.get(endpoint + id)
    }
}

export default Purchases
