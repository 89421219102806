<template lang="html">
  <div v-loading="loader" class="row">
    <div v-if="!loader" class="col-md-12 text-center">
      <el-alert
        v-if="isFuture"
        :closable="false"
        name="isFuture"
        title="No se puede completar una tarea futura"
        type="info"
        center
        show-icon
      />

      <el-alert
        v-if="isFuture & (record.workflow.state == 'scheduled')"
        :closable="false"
        name="timer"
        title="Completar esta tarea hará que la tarea pase al siguiente estado"
        type="info"
        center
        show-icon
      />

      <el-alert
        v-if="!isMyTask"
        :closable="false"
        :title="
          'Esta tarea está asignada a: ' +
          getUserName(record.workflow.assignee.$oid)
        "
        :description="getDescription"
        name="isMyTask"
        type="warning"
        center
        show-icon
      />
    </div>
    <div v-if="!loader" class="col-md-12">
      <span class="ms-2 me-2">
        <slot name="extra-buttons" />
      </span>
      <n-button
        v-for="(action, key) in actions"
        :key="key"
        :type="getType(action)"
        :disabled="disabledAction(action)"
        class="ms-2 me-2"
        @click.native="sendAction(action)"
      >
        {{ $t(getActionName(action)) }}
      </n-button>
    </div>
    <div v-if="isSupervisor" class="col-md-12">
      <span class="text-muted small">{{
        $t("processControlsEnabledForSupervisors")
      }}</span>
    </div>
  </div>
</template>

<script>
import { Alert } from "element-ui";
import Swal from "sweetalert2";

export default {
  components: {
    [Alert.name]: Alert,
  },
  props: {
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loader: true,
      actions: [],
      users: [],
      currentUser: undefined,
      isSupervisor: false,
    };
  },
  computed: {
    isFuture() {
      return this.$moment(this.record.date) > this.$moment();
    },
    isMyTask() {
      return this.record.workflow.assignee.$oid == this.currentUser;
    },
    getDescription() {
      return this.record.workflow.starter == this.currentUser
        ? "Esta tarea fue iniciada por ti, por lo que puedes reasignar el responsable"
        : "";
    },
  },
  created() {
    this.currentUser = this.$store.getters["Auth/GET_USER"]._id.$oid;
    this.isSupervisor = this.$store.getters["Auth/IS_SUPERVISOR"];
    this.getEvents();
  },
  methods: {
    getEvents() {
      let vm = this;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Processes.getEvents(
        farm_id,
        this.$route.params.processKey,
        this.$route.params.id
      )
        .then((response) => {
          let actions = vm.sortEvents(response.data);

          if (this.$route.params.state == "scheduled") {
            actions = actions.filter((i) => i != "complete");
          }

          vm.actions = actions;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.getUsers();
        });
    },
    getUsers() {
      let vm = this;
      this.Services.Users.getUsers()
        .then((response) => {
          vm.users = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    getUserName(userId) {
      let user = this.users.find((i) => i._id.$oid == userId);

      if (user == undefined) return "CERES";
      else return user.full_name;
    },
    getType(value) {
      return this.$CERES_VARS.data.processEvents.dangerEvents.includes(value)
        ? "danger"
        : "primary";
    },
    getActionName(action) {
      return (action == "update_task") & this.isForm
        ? action + "_save"
        : action;
    },
    disabledAction(action) {
      if (this.isSupervisor) {
        return false;
      } else if (["cancel", "update_task"].includes(action) && this.isMyTask) {
        return false;
      } else {
        return this.isFuture || !this.isMyTask;
      }
    },
    sendAction(action) {
      let vm = this;
      if (action == "cancel") {
        Swal.fire({
          title: "¿Estás seguro de cancelar esta labor?",
          text: "¡La accción no podrá revertirse!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "No,<br/>Quiero conservarla",
          confirmButtonText: "Si,<br/>Quiero cancelarla",
          reverseButtons: true,
          focusCancel: true,
          cancelButtonClass: "primary",
          confirmButtonClass: "danger",
        }).then((result) => {
          if (result.value) vm.$emit("action", action);
        });
      } else {
        this.$emit("action", action);
      }
    },
    sortEvents(events) {
      let sortedEvents = this.$COPY.copy(events);
      let firstEvents = this.$CERES_VARS.data.processEvents.eventOrder.first;
      let lastEvents = this.$CERES_VARS.data.processEvents.eventOrder.last;
      firstEvents.forEach((i) => {
        let index = sortedEvents.indexOf(i);
        if (index > -1) {
          sortedEvents.splice(index, 1);
          sortedEvents.splice(0, 0, i);
        }
      });

      lastEvents.forEach((i) => {
        let index = sortedEvents.indexOf(i);
        if (index > -1) {
          sortedEvents.splice(index, 1);
          sortedEvents.splice(sortedEvents.length, 0, i);
        }
      });
      return sortedEvents;
    },
  },
};
</script>

<style lang="sass" scoped>
.el-alert + .el-alert
  margin-top: 10px
</style>
