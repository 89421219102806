<template lang="html">
  <card v-loading="!haveDependencies || loader">
    <template slot="header">
      <card-controls :back="true" back-target-name="prunings">
        <span slot="actions">
          <n-button type="primary" simple="" @click.native="goToDiagram">
            <i class="fal fa-project-diagram" />
          </n-button>
          <template v-if="Object.keys(record).length > 0">
            <n-button
              v-if="$HELPERS.showOptionalControls(record.workflow.starter.$oid)"
              type="primary"
              simple=""
              @click.native="components.setAssignee = true"
            >
              <i class="fal fa-user" />
            </n-button>
          </template>
          <n-button
            type="primary"
            simple=""
            @click.native="goToWorkflowHistory"
          >
            <i class="fal fa-history" />
          </n-button>
        </span>
      </card-controls>

      <h4 class="card-title"><b>Detalle de la Poda</b></h4>
    </template>

    <div>
      <div v-if="haveDependencies" class="col-md-10 offset-md-1">
        <record-details :model="record">
          <template slot="actions">
  <log-process-controls
    :record="record"
    :messages="messages"
    process-key="pruning"
    @delete="deleteRecord"
  />
</template>
        </record-details>

        <set-assignee
          v-if="components.setAssignee"
          :task-id="$route.params.id"
          :assignee="record.workflow.assignee.$oid"
          process-key="Pruning"
          @updateTaskList="getRecord"
          @close="components.setAssignee = false"
        />
      </div>
    </div>
  </card>
</template>

<script>
import RecordDetails from "./Components/PruningDetails";
import LogProcessControls from "@/views/Labors/Components/LogProcessControls";
import SetAssignee from "@/views/TaskList/Components/SetAssignee";
import Swal from "sweetalert2";

export default {
  components: {
    RecordDetails,
    SetAssignee,
    LogProcessControls,
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
    sectors: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      farm_id: "",
      loader: false,
      dependencies: {
        model: false,
      },
      components: {
        setAssignee: false,
      },
      messages: {},
      record: {},
    };
  },
  computed: {
    haveDependencies() {
      return Object.values(this.dependencies).every((i) => i);
    },
  },
  created() {
    this.farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
    this.getRecord();
  },
  methods: {
    getRecord() {
      let vm = this;
      this.Services.Prunings.getPruning(this.farm_id, this.$route.params.id)
        .then((response) => {
          vm.record = response.data;
        })
        .catch((error) => {
          console.log(error);
          vm.$router.push({ name: "not-found" });
        })
        .finally(() => {
          vm.dependencies.model = true;
        });
    },
    goToDiagram() {
      this.$router.push({
        name: "diagram",
        params: {
          processKey: "pruning",
          id: this.$route.params.id,
          state: this.record.workflow.state,
          customBackTarget: {
            name: "view-pruning",
            params: {
              id: this.$route.params.id,
            },
          },
        },
      });
    },
    goToWorkflowHistory() {
      this.$router.push({
        name: "workflow-history",
        params: {
          id: this.$route.params.id,
          processKey: "pruning",
          record: this.record,
        },
      });
    },
    editRecord() {
      this.$router.push({
        name: "edit-pruning",
        params: {
          id: this.$route.params.id,
          model: this.record,
        },
      });
    },
    deleteRecord() {
      let vm = this;
      vm.loader = true;
      vm.messages = {};
      this.Services.Prunings.deletePruning(this.farm_id, this.record._id.$oid)
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          }).then((result) => {
            vm.$router.push({ name: "prunings" });
          });
        })
        .catch((error) => {
          console.log(error);
          vm.messages = error.response.data;
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped></style>
