<template lang="html">
  <div>
    <cost-per-sector-calc-form
      ref="costPerSectorCalcForm"
      :init-model="initModel"
      default-cost-variable="num_of_plants"
      @modelUpdated="updateModel"
      @render="render = true"
    >
      <span slot="atTheBeginning">
        <div class="row">
          <div class="col-md-6">
            <fg-input :error="errors.first('date')" class="has-label">
              <label>Fecha de la poda <span>*</span></label>
              <el-date-picker
                v-validate="{ required: true }"
                v-model="model.date"
                type="date"
                placeholder="Seleccionar fecha"
                name="date"
                data-vv-as="Fecha de la poda"
              />
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input :error="errors.first('assignee')" label="Responsable">
              <el-select
                v-validate="{ required: true }"
                v-model="model.workflow.assignee.$oid"
                name="assignee"
                class="select-primary"
                data-vv-as="Responsable"
                filterable
              >
                <el-option
                  v-for="(user, key) in users"
                  :value="user._id.$oid"
                  :label="user.full_name"
                  :key="key"
                  class="select-danger"
                />
              </el-select>
            </fg-input>
          </div>
        </div>
      </span>

      <span slot="beforeTotalCost">
        <div class="row mt-3">
          <div class="col-md-6 text-left">
            <el-checkbox v-model="model.count_buds">
              ¿Registrar conteo de yemas por planta?
            </el-checkbox>
          </div>
        </div>
        <div v-if="model.count_buds" class="row">
          <div class="col-md-6 text-left">
            <fg-input
              v-validate="'min_value:0'"
              v-model.number="model.buds_per_plant"
              :error="errors.first('buds_per_plant')"
              label="Yemas por planta"
              data-vv-as="Yemas por planta"
              step="1"
              placeholder="Yemas por planta"
              name="buds_per_plant"
              type="number"
            />
          </div>
          <div class="col-md-6 text-left text-muted mt-auto mb-auto pt-3">
            = {{ model.total_buds | toLocaleStringNumber }} Yemas totales
          </div>
        </div>
      </span>
    </cost-per-sector-calc-form>
    <div v-if="render && workersReady" class="row">
      <div class="col-md-12 text-left">
        <fg-input :error="errors.first('worker_id')" label="Podadores">
          <el-select
            v-validate="areWorkersRequired"
            v-model="model.worker_ids"
            :multiple="true"
            name="worker_id"
            class="select-primary"
            data-vv-as="Podadores"
            filterable
          >
            <el-option
              v-for="(worker, key) in workers"
              :value="worker._id.$oid"
              :label="worker.full_name"
              :key="key"
              class="select-danger"
            />
          </el-select>
        </fg-input>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <slot name="actions" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <notification :messages="messages" :close="true" type="error" />
      </div>
    </div>
  </div>
</template>

<script>
import CostPerSectorCalcForm from "@/views/Tools/CostPerSectorCalc/CostPerSectorCalcForm";
import { Checkbox } from "element-ui";

export default {
  components: {
    CostPerSectorCalcForm,
    [Checkbox.name]: Checkbox,
  },
  props: {
    runValidation: {
      type: Boolean,
      default: false,
    },
    initModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    messages: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      requestChildModel: false,
      workers: [],
      users: [],
      render: false,
      workersReady: false,
      model: {
        date: "",
        count_buds: false,
        buds_per_plant: 0,
        total_buds: 0,
        worker_ids: [],
        sector_ids: [],
        workflow: {
          assignee: {
            $oid: "",
          },
        },
      },
    };
  },
  computed: {
    areWorkersRequired() {
      return this.$HELPERS.isFuture(this.model.date) ? "" : "required";
    },
  },
  watch: {
    model: {
      handler: function () {
        this.requestChildModel = true;
        let model = this.$COPY.copy(this.model);
        if (!model.hasOwnProperty("farm_id")) {
          model.farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
        }
        this.$emit("modelUpdated", model);
      },
      deep: true,
    },
    runValidation: function (newValue, oldValue) {
      if (newValue) this.validateForm();
    },
    "model.buds_per_plant": function (newValue, oldValue) {
      let dependencies = [this.model.work_coverage, this.model.buds_per_plant];
      if (dependencies.every((i) => i != undefined)) {
        this.model.total_buds =
          this.model.buds_per_plant * this.model.work_coverage;
      }
    },
  },
  created() {
    this.getUsers();
    if (Object.keys(this.initModel).length > 0) {
      let vm = this;
      Object.keys(this.initModel).forEach((key) => {
        if (key in vm.model) vm.model[key] = vm.initModel[key];
      });
    }
  },
  methods: {
    getUsers() {
      let vm = this;
      this.Services.Users.getUsers()
        .then((response) => {
          vm.users = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.getWorkers();
        });
    },
    getWorkers() {
      let vm = this;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      let fields = ["_id", "full_name", "skills"];
      this.Services.Workers.getWorkers({ farm_id, fields })
        .then((response) => {
          vm.workers = response.data.filter((w) =>
            w.skills.includes("Podador")
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.workersReady = true;
        });
    },
    updateModel(model) {
      this.requestChildModel = false;
      this.model = Object.assign(this.model, model.calculator);
      this.$emit("modelUpdated", this.model);
    },
    validateForm() {
      let vm = this;
      let validations = {
        thisForm: undefined,
        costPerSectorCalcForm: undefined,
      };
      vm.$validator
        .validateAll()
        .then((isValid) => {
          validations.thisForm = isValid;
        })
        .finally(() => {
          secondValidation();
        });

      function secondValidation() {
        vm.$refs.costPerSectorCalcForm.$validator
          .validateAll()
          .then((isValid) => {
            validations.costPerSectorCalcForm = isValid;
          })
          .finally(() => {
            finalValidation();
          });
      }

      function finalValidation() {
        let isValid = Object.values(validations).every((i) => i);
        vm.$emit("validForm", isValid, vm.model);
      }
    },
  },
};
</script>

<style lang="sass" scoped></style>
