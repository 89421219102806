<template lang="html">
  <div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info"><i class="fal fa-info-circle"/></div>
              <h3 class="info-title"><span>{{ $t(model.workflow.state) }}</span></h3>
              <h6 class="stats-title">Estado</h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info"><i class="fal fa-calendar"/></div>
              <h3
                v-if="model.hasOwnProperty('date')"
                class="info-title">
                <span>{{ model.date | moment("DD-MM-YYYY") }}</span>
              </h3>
              <h6 class="stats-title">Fecha de la compra</h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info"><i class="fal fa-calendar"/></div>
              <h3
                v-if="model.hasOwnProperty('arrival_date')"
                class="info-title">
                <span>{{ model.arrival_date | moment("DD-MM-YYYY") }}</span>
              </h3>
              <h6 class="stats-title">Fecha de entrega</h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info"><i class="fal fa-user"/></div>
              <h3 class="info-title"><span>{{ model.purchase_workflow.assignee_full_name }}</span></h3>
              <h6 class="stats-title">Responsable</h6>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-left">
          <h5>Datos generales</h5>
        </div>
        <div class="col-md-12 text-left">
          <p>Proveedor: {{ model.supplier_name }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-left">
          <h5>Detalle de productos</h5>
        </div>
        <div class="col-md-12">
          <el-table
            :data="model.purchase_items"
            :error="errors.first('purchase_items')"
            name="purchase_items"
            data-vv-as="Detalle de productos"
            empty-text="No se han agregado ítems aún">
            <el-table-column
              label="Producto">
              <template slot-scope="scope">
                <!-- {{ getProductLabel(scope.row.product) }} -->
                {{ scope.row.product.name }}
              </template>
            </el-table-column>
            <el-table-column
              label="Precio unitario sin IVA (CLP)"
              header-align="center"
              align="right">
              <template slot-scope="{row}">
                {{ row.price_per_unit | toLocaleStringNumber | currency }}
              </template>
            </el-table-column>
            <el-table-column
              label="Cantidad"
              header-align="center"
              align="right">
              <template slot-scope="{row}">
                {{ row.containers | toLocaleStringNumber }} {{ parseFormat(row) }} de {{ row.units_per_format }} {{ row.product.measure_unit }}  ({{ row.qty }} {{ row.product.measure_unit }})
              </template>
            </el-table-column>
            <el-table-column
              label="Subtotal"
              header-align="center"
              align="right">
              <div slot-scope="{row}">
                {{ row.cost | toLocaleStringNumber | currency }}
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12 text-right">
          Total sin IVA: CLP {{ model.sub_total | toLocaleStringNumber | currency }}
        </div>
        <div class="col-md-12 text-right">
          Impuestos (IVA): CLP {{ model.taxes | toLocaleStringNumber | currency }}
        </div>
        <div class="col-md-12 text-right">
          Total con IVA: CLP {{ model.total | toLocaleStringNumber | currency }}
        </div>
      </div>


      <div class="row">
        <div class="col-md-12 mt-3">
          <slot name="actions"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui"
import pluralize from "pluralize"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    props: {
        model: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    methods: {
        getProductLabel(product) {
            let format =
                product.format +
                " de " +
                product.units_per_format +
                " " +
                product.measure_unit
            return [product.name, format, product.brand].join(" / ")
        },
        parseFormat(row) {
            // return pluralize(row.product.format, row.containers)
            return pluralize(row.format, row.containers)
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
