import { ceres_api } from "@/axios-config.js"

const PhenologicalTrackings = {
    createPhenologicalTracking(farm_id, model) {
        let endpoint = "/farms/" + farm_id + "/phenological_trackings"
        return ceres_api.post(endpoint, model)
    },
    getPhenologicalTrackings(farm_id, filter) {
        let endpoint = "/farms/" + farm_id + "/phenological_trackings"
        if (filter == undefined) {
            return ceres_api.get(endpoint)
        } else {
            return ceres_api.post(endpoint + "/filter", { filter: filter })
        }
    },
    getPhenologicalTracking(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/phenological_trackings/"
        return ceres_api.get(endpoint + id)
    }
}

export default PhenologicalTrackings
