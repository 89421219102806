import { ceres_api } from "@/axios-config.js";

const Warehouses = {
  getWarehouses(farm_id) {
    return ceres_api.get("/farms/" + farm_id + "/warehouses");
  },
  getWarehousesItems({ farm_id, warehouse_ids }) {
    let endpoint = "/farms/" + farm_id + "/warehouses/items";
    return ceres_api.post(endpoint, { warehouse_ids: warehouse_ids });
  },
  getWarehouse({ farm_id, warehouse_id }) {},
  createWarehouse({ farm_id, payload }) {
    let endpoint = "/farms/" + farm_id + "/warehouses";
    return ceres_api.post(endpoint, payload);
  },
  updateWarehouse({ farm_id, warehouse_id, payload }) {
    let endpoint = "/farms/" + farm_id + "/warehouses/" + warehouse_id;
    return ceres_api.put(endpoint, payload);
  },
  deleteWarehouse({ farm_id, warehouse_id }) {
    let endpoint = "/farms/" + farm_id + "/warehouses/" + warehouse_id;
    return ceres_api.delete(endpoint);
  },
};

export default Warehouses;
