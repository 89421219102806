var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 pull-center desktop-table"},[_c('el-table',{staticClass:"table-striped",attrs:{"data":_vm.data,"empty-text":'No hay controles de malezas registrados'}},[(_vm.showStatus)?_c('el-table-column',{attrs:{"prop":"status","label":"Estado","align":"center","name":"status","column-key":"status","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-tag',{staticClass:"used-product-tag",attrs:{"closable":false,"close-transition":false,"type":_vm.$TASK_STATES.getColor(row.workflow.state),"size":"small"}},[_vm._v(" "+_vm._s(_vm.$t(row.workflow.state))+" ")])],1)}}],null,false,1504213643)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"date","label":"Fecha","align":"center","name":"date","column-key":"date","class-name":"table-category","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('i',{staticClass:"el-icon-time"}),_vm._v(" "+_vm._s(_vm._f("moment")(row.date,"DD-MM-YYYY"))+" "),_c('br'),_vm._v(" ("+_vm._s(_vm._f("moment")(row.date,"dddd"))+") ")])}}])}),_c('el-table-column',{attrs:{"label":"Cuarteles","align":"center","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},_vm._l((row.sectors),function(sector){return _c('el-tag',{key:sector._id.$oid,staticClass:"used-product-tag",attrs:{"closable":false,"close-transition":false,"size":"small","type":"primary"}},[_vm._v(" "+_vm._s(sector.name)+" ")])}),1)}}])}),_c('el-table-column',{attrs:{"prop":"work_coverage","label":"Número de plantas","header-align":"center","align":"right","name":"workCoverage","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("toLocaleStringNumber")(row.work_coverage))+" ")])}}])}),_c('el-table-column',{attrs:{"prop":"cost_per_unit","label":"Costo por planta (CLP)","header-align":"center","align":"right","name":"costPerUnit","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm._f("toLocaleStringNumber")(row.cost_per_unit)))+" ")])}}])}),_c('el-table-column',{attrs:{"prop":"total_cost","label":"Costo total (CLP)","header-align":"center","align":"right","name":"total_cost","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm._f("toLocaleStringNumber")(row.total)))+" ")])}}])}),_c('el-table-column',{attrs:{"label":"Acciones","align":"center","class-name":"table-category actions-column"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-center"},[_c('n-button',{attrs:{"type":"primary","size":"sm"},nativeOn:{"click":function($event){return _vm.view(row)}}},[_c('i',{staticClass:"fal fa-eye"})])],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }