<template lang="html">
  <div v-loading="loader">
    <phenological-tracking-details
      v-if="view_mode"
      :model="init_model">

      <template slot="actions">
        <process-controls
          v-if="Object.keys(variables).length > 0"
          :record="variables"
          @action="processAction"/>
      </template>
    </phenological-tracking-details>
    <phenological-tracking-form
      v-if="!view_mode"
      ref="PhenologicalTrackingForm"
      :messages="messages"
      :init-model="init_model"
      :run-validations="runValidations"
      :validate-model="validateModel"
      @isValid="isValid"
      @modelUpdated="updateModel">
      <template slot="actions">
  <process-controls
    v-if="Object.keys(variables).length > 0"
    :record="variables"
    @action="processAction"
  >
    <template slot="extra-buttons">
      <n-button
        v-if="
          Object.keys(variables).length == 0 ||
          transitions_with_update.includes(decision)
        "
        simple=""
        class="ms-2 me-2"
        @click.native="view_mode = true"
      >
        Cerrar
      </n-button>
    </template>
  </process-controls>
  <n-button
    v-if="Object.keys(variables).length == 0"
    class="ms-2 me-2"
    simple=""
    @click.native="view_mode = true"
  >
    Cerrar
  </n-button>
  <n-button
    v-if="Object.keys(variables).length == 0"
    class="ms-2 me-2"
    type="primary"
    @click.native="validate()"
  >
    {{ saveText }}
  </n-button>
</template>
    </phenological-tracking-form>

  </div>
</template>

<script>
import PhenologicalTrackingForm from "@/views/Labors/PhenologicalTrackings/PhenologicalTrackingForm";
import PhenologicalTrackingDetails from "@/views/Labors/PhenologicalTrackings/PhenologicalTrackingDetails";
import ProcessControls from "@/views/TaskList/Components/ProcessControls";

export default {
  components: {
    PhenologicalTrackingForm,
    PhenologicalTrackingDetails,
    ProcessControls,
  },
  props: {
    variables: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      title: "Detalles del seguimiento del estado fenológico",
      back_text: "Seguimientos de Estado Fenológico",
      back_custom_target: {
        name: "records-view",
        params: {
          processKey: "phenological-trackings",
        },
      },
      view_mode: true,
      loader: false,
      runValidations: 1,
      validateModel: true,
      messages: {},
      init_model: {},
      model: {
        observations: [],
        workflow: {
          state: "new",
        },
      },
      decision: "",
      transitions_with_update: ["update_task"],
      states_which_start_with_form: ["reporting", "new"],
    };
  },
  computed: {
    saveText() {
      if (this.model.workflow.state != "new") {
        return "Guardar cambios";
      } else {
        if (this.$HELPERS.isFuture(this.model.date)) {
          return "Programar";
        } else if (this.$HELPERS.isPast(this.model.date)) {
          return "Registrar";
        } else {
          return "Iniciar";
        }
      }
    },
  },
  created() {
    if (Object.keys(this.variables).length > 0) {
      this.model = this.preProcessVariables();
      this.init_model = this.$COPY.copy(this.model);
    }
    if (this.states_which_start_with_form.includes(this.model.workflow.state)) {
      this.view_mode = false;
    }
    this.$emit("setBackText", this.back_text);
    this.$emit("setBackCustomTarget", this.back_custom_target);
    this.$emit("setTitle", this.title);
  },
  methods: {
    preProcessVariables() {
      let init_model = this.$COPY.copy(this.variables);
      init_model.sector_ids = init_model.sector_ids.map((i) => i.$oid);

      if (init_model.observations == undefined) init_model.observations = [];

      init_model.observations.forEach((i) => {
        if (
          i.phenological_stage == undefined ||
          typeof i.phenological_stage != "object"
        ) {
          i.phenological_stage = {};
        }
      });
      return init_model;
    },
    processAction(action) {
      this.decision = action;

      if (this.transitions_with_update.includes(action) && this.view_mode) {
        this.view_mode = false;
      } else if (this.view_mode) {
        this.startOrCompleteTask();
      } else if (action == "save_progress") {
        this.validateModel = false;
        this.validate();
      } else if (action == "cancel") {
        this.validateModel = false;
        this.validate();
      } else {
        this.validate();
      }
    },
    updateModel(model) {
      this.model = this.$COPY.copy(model);
      if (!this.model.hasOwnProperty("farm_id")) {
        this.model.farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      }
    },
    validate() {
      this.loader = true;
      this.messages = {};
      this.runValidations += 1;
    },
    isValid(isValid, model) {
      this.updateModel(model);

      isValid ? this.startOrCompleteTask() : (this.loader = false);
      // if(this.decision == "save_progress"){
      //     // this.startOrCompleteTask()
      // }else{
      // }
    },
    startOrCompleteTask() {
      if (this.model.workflow.hasOwnProperty("state")) {
        if (this.model.workflow.state != "new") {
          this.completeTask();
        } else {
          this.start();
        }
      } else {
        this.start();
      }
    },
    start() {
      let vm = this;
      this.Services.PhenologicalTrackings.createPhenologicalTracking(
        this.model.farm_id,
        this.model
      )
        .then((response) => {
          vm.$HELPERS.processServerMessage(response.data.messages).then(() => {
            vm.$router.push({
              name: "records-view",
              params: { processKey: "phenological-trackings" },
            });
          });
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response.data != "string") {
            vm.messages = error.response.data;
          }
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    completeTask() {
      this.$emit(
        "completeTask",
        this.$route.params.processKey,
        this.$route.params.id,
        this.model,
        this.decision
      );
      this.loader = false;
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
