<template lang="html">
  <card v-loading="loader">
    <template slot="header">
      <card-controls :back="true" back-target-name="plague-monitorings">
        <span slot="actions">
          <n-button type="primary" simple="" @click.native="goToDiagram">
            <i class="fal fa-project-diagram" />
          </n-button>
          <template v-if="Object.keys(model).length > 0">
            <n-button
              v-if="manageControls"
              type="primary"
              simple=""
              @click.native="components.setAssignee = true"
            >
              <i class="fal fa-user" />
            </n-button>
          </template>
          <n-button
            type="primary"
            simple=""
            @click.native="goToWorkflowHistory"
          >
            <i class="fal fa-history" />
          </n-button>
        </span>
      </card-controls>

      <h4 class="card-title">
        <b>Detalle del Monitoreo de Plagas y Enfermedades</b>
      </h4>
    </template>

    <div v-if="render">
      <record-details :model="model" />

      <log-process-controls
        v-if="Object.keys(model).length > 0"
        :record="model"
        :messages="messages"
        process-key="plague-monitoring"
        @delete="deleteRecord"
      />
    </div>

    <set-assignee
      v-if="components.setAssignee"
      :task-id="$route.params.id"
      :assignee="model.workflow.assignee.$oid"
      process-key="PlagueMonitoring"
      @updateTaskList="getRecord"
      @close="components.setAssignee = false"
    />
  </card>
</template>

<script>
import LogProcessControls from "@/views/Labors/Components/LogProcessControls";
import RecordDetails from "./Components/PlagueMonitoringDetails";
import SetAssignee from "@/views/TaskList/Components/SetAssignee";
import Swal from "sweetalert2";

export default {
  components: {
    LogProcessControls,
    RecordDetails,
    SetAssignee,
  },
  data() {
    return {
      render: false,
      loader: true,
      farm_id: "",
      messages: {},
      model: {},
      components: {
        setAssignee: false,
      },
    };
  },
  computed: {
    manageControls() {
      return this.$HELPERS.showOptionalControls(
        this.model.workflow.starter.$oid
      );
    },
  },
  created() {
    this.farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
    this.getRecord();
  },
  methods: {
    getRecord() {
      let vm = this;
      this.Services.PlagueMonitorings.getPlagueMonitoring(
        this.farm_id,
        this.$route.params.id
      )
        .then((response) => {
          vm.model = response.data;
        })
        .catch((error) => {
          console.log(error);
          vm.$router.push({ name: "not-found" });
        })
        .finally(() => {
          vm.loader = false;
          vm.render = true;
        });
    },
    goToDiagram() {
      this.$router.push({
        name: "diagram",
        params: {
          processKey: "plague-monitoring",
          id: this.$route.params.id,
          state: this.model.workflow.state,
          customBackTarget: {
            name: "view-plague-monitoring",
            params: {
              id: this.$route.params.id,
            },
          },
        },
      });
    },
    goToWorkflowHistory() {
      this.$router.push({
        name: "workflow-history",
        params: {
          id: this.$route.params.id,
          processKey: "plague-monitoring",
          record: this.model,
        },
      });
    },
    editRecord() {
      this.$router.push({
        name: "edit-plague-monitoring",
        params: {
          id: this.$route.params.id,
          model: this.model,
        },
      });
    },
    deleteRecord() {
      let vm = this;
      vm.messages = {};
      this.loader = true;
      this.Services.PlagueMonitorings.deletePlagueMonitoring(
        this.farm_id,
        this.$route.params.id
      )
        .then((response) => {
          if (response.status == 200) {
            vm.loader = false;
            Swal.fire({
              title: response.data.messages,
              icon: "success",
            }).then((result) => {
              vm.$router.push({ name: "plague-monitorings" });
            });
          }
        })
        .catch((error) => {
          console.log(error);
          vm.messages = error.response.data;
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
};
</script>

<style lang="css" scoped></style>
