import { ceres_api } from "@/axios-config.js"

const Utils = {
    downloadJsonAsCsv(data) {
        return ceres_api.post("/utils/download_json_as_csv", data)
    },
    downloadContacts(schemaKey) {
        return ceres_api.get("utils/download_contacts?schemaKey=" + schemaKey)
    }
}

export default Utils
