<template>
  <div class="mb-3">
    <el-alert
      v-if="!isGeneralSettingsComplete"
      title="Complete la configuración de Dosis, Especies y Variedades en Configuración > General"
      :closable="false"
      type="error"
      class="mb-1"
    />
    <el-alert
      v-if="!hasEnabledActivities"
      title="Complete la configuración de actividades habilitadas en Configuración > Actividades"
      :closable="false"
      type="error"
      class="mb-1"
    />
    <el-alert
      v-if="!hasSectors"
      title="Agregue cuarteles al campo en Mis Campos para que las actividades funcionen correctamente"
      :closable="false"
      type="error"
      class="mb-1"
    />
    <el-alert
      v-if="!hasWarehouses"
      title="Agregue bodegas al campo en Mis Campos para que las actividades funcionen correctamente"
      :closable="false"
      type="error"
      class="mb-1"
    />
    <el-alert
      v-for="(item, index) in alerts"
      :key="item.id"
      :closable="item.closable"
      :title="item.title"
      :type="item.type"
      effect="dark"
      :class="{ 'mb-1': alerts.length > 1 && alerts.length != index - 1 }"
    />
  </div>
</template>

<script>
import { Alert } from "element-ui";
import { mapGetters, mapMutations, mapState } from "vuex";
import { has } from "lodash";

export default {
  name: "GlobalAlerts",
  components: {
    [Alert.name]: Alert,
  },
  computed: {
    ...mapState("Layout", ["alerts"]),
    ...mapState("Settings", ["settings"]),
    ...mapGetters("Farms", ["hasSectors", "hasWarehouses"]),
    isGeneralSettingsComplete() {
      if (this.settings == undefined) return true;
      const keys = ["general.doses_unit", "general.species", "varieties"];
      return keys.every((i) => has(this.settings, i));
    },
    hasEnabledActivities() {
      return has(this.settings, "activities.enabled");
    },
  },
  methods: {
    ...mapMutations("Layout", ["addAlert", "removeAlert"]),
  },
};
</script>
