<template>
  <div class="text-left">
    <fg-input
      :error="errors.first('sector')"
      :label="label">
      <el-select
        v-validate="{required: false}"
        v-model="sector_ids"
        :multiple="allowMultiple"
        data-vv-as="Cuarteles"
        placeholder="Seleccione el cuartel"
        class="select-primary"
        name="sector"
        filterable
        @change="$emit('onChange', $event)">
        <el-option
          v-for="sector in sectors"
          :value="sector._id.$oid"
          :label="sector.name"
          :key="sector._id.$oid"
          class="select-danger"/>

      </el-select>
    </fg-input>
  </div>
</template>

<script>
import { getSectors } from "../../../mixins"

export default {
    name: "UserSelect",
    mixins: [getSectors],
    props: {
        defaultSectors: {
            type: Array,
            default: () => []
        },
        defaultSector: {
            type: String,
            default: ""
        },
        allowMultiple: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: "Cuarteles"
        }
    },
    data() {
        return {
            sector_ids: []
        }
    },
    created() {
        if (this.defaultSectors) this.sector_ids = this.defaultSectors
        if (this.defaultSector) this.sector_ids = this.defaultSector
        if (this.sector_ids.length > 0) this.$emit("onChange", this.sector_ids)
    }
}
</script>

<style>
</style>
