import Vue from "vue"

const ServerMessages = {
    serverMessages: {
        negativeError: "El stock final no puede ser menor a 0"
    },
    get(key) {
        return this.serverMessages[key]
    },
    install() {
        Object.defineProperty(Vue.prototype, "$SERVER_MESSAGES", {
            get() {
                return ServerMessages
            }
        })
    }
}

export default ServerMessages
