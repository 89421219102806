<template lang="html">
  <div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info"><i class="fal fa-info-circle"/></div>
              <h5 class="info-title"><span>{{ $t(model.workflow.state) }}</span></h5>
              <h6 class="stats-title">Estado</h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info"><i class="fal fa-calendar"/></div>
              <h5
                v-if="model.hasOwnProperty('date')"
                class="info-title">
                <span>{{ model.date | moment("DD-MM-YYYY") }}</span>
              </h5>
              <h6 class="stats-title">Fecha del inventario</h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info"><i class="fal fa-minus-circle"/></div>
              <h5 class="info-title">
                <span>{{ (model.has_losses) ? "Si" : "No" }}</span>
              </h5>
              <h6 class="stats-title">¿Se encontraron pérdidas?</h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info"><i class="fal fa-user"/></div>
              <h5 class="info-title"><span>{{ model.inventory_workflow.assignee_full_name }}</span></h5>
              <h6 class="stats-title">Responsable</h6>
            </div>
          </div>
        </div>
      </div>

      <div class="row text-left">
        <div class="col-md-12">
          <h5>Inventario por bodega</h5>
        </div>
        <div
          v-if="model.inventory_items.length > 0"
          class="col-md-12">
          <el-collapse accordion>
            <el-collapse-item
              v-for="(warehouse, index) in model.warehouses"
              :key="index">
              <template slot="title">
                {{ getWarehouseTitle(warehouse) }}
              </template>
              <el-table
                :data="model.inventory_items.filter(i => i.product.warehouse_id.$oid == warehouse._id.$oid)"
                empty-text="No hay productos o herramientas registradas en esta bodega">
                <el-table-column
                  label="Marca">
                  <div slot-scope="{row}">
                    {{ row.product.brand }}
                  </div>
                </el-table-column>
                <el-table-column
                  label="Nombre comercial">
                  <div slot-scope="{row}">
                    {{ row.product.name }}
                  </div>
                </el-table-column>
                <el-table-column
                  label="Formato">
                  <div slot-scope="{row}">
                    {{ row.product.format }} de {{ row.product.units_per_format }} {{ row.product.measure_unit }}
                  </div>
                </el-table-column>
                <el-table-column
                  prop="system_stock"
                  label="Stock en sistema (al momento del inventario)"
                  align="right"
                  header-align="center">
                  <div slot-scope="{row}">
                    {{ row.system_stock | toLocaleStringNumber("decimal") }}
                  </div>
                </el-table-column>
                <el-table-column
                  label="Stock real"
                  align="right"
                  header-align="center">
                  <div slot-scope="{row}">
                    {{ row.actual_stock | toLocaleStringNumber("decimal") }}
                  </div>
                </el-table-column>
                <el-table-column
                  label="Diferencia"
                  align="right"
                  header-align="center">
                  <div slot-scope="{row}">
                    <template>
                      {{ row.loss | toLocaleStringNumber("decimal") }}
                    </template>
                  </div>
                </el-table-column>

              </el-table>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div
          v-else
          class="col-md-12">
          <el-alert
            :closable="false"
            title="Selecione al menos una bodega a inventariar y presione el botón 'Obtener items'"
            type="warning"
            show-icon/>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mt-3">
          <slot name="actions"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Collapse, CollapseItem, Table, TableColumn, Alert } from "element-ui"

export default {
    components: {
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Alert.name]: Alert
    },
    props: {
        model: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    methods: {
        getWarehouseTitle(warehouse) {
            let count_of_products = this.model.inventory_items.filter(
                i => i.product.warehouse_id.$oid == warehouse._id.$oid
            ).length
            let item_or_items = count_of_products > 1 ? "items" : "item"
            return (
                warehouse.name +
                " (" +
                count_of_products +
                " " +
                item_or_items +
                ")"
            )
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
