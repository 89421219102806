import { mapGetters } from "vuex";

const getFarmId = {
  computed: {
    ...mapGetters({
      farm_id: "Farms/GET_FARM_ID",
    }),
  },
};

export default getFarmId;
