<template lang="html">
  <div
    v-loading="loader">
    <div v-if="!loader">
      <div class="row">
        <div class="col-md-12 text-left">
          <h5>Datos generales</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-12 text-left">
          <fg-input
            :error="errors.first('date')"
            required
            label="Fecha de aplicación">
            <el-date-picker
              v-validate="{required: true}"
              v-model="model.date"
              data-vv-as="Fecha de aplicación"
              name="date"
              format="dd-MM-yyyy"
              type="date"
              placeholder="Fecha de aplicación"/>
          </fg-input>
        </div>
        <div class="col-md-3 col-sm-12 text-left">
          <div class="form-group">
            <fg-input
              :error="errors.first('type')"
              required
              label="Tipo de aplicación">
              <el-select
                v-validate="{required: true}"
                v-model="model.type"
                data-vv-as="Tipo de aplicación"
                name="type"
                class="select-primary"
                placeholder="Tipo de aplicación">
                <el-option
                  v-for="(type,key) in types"
                  :value="type"
                  :label="type"
                  :key="key"
                  class="select-danger"/>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="col-md-3 col-sm-12 text-left">
          <fg-input
            v-validate="{ required: true, min_value: 1, decimal: true }"
            v-model.number="model.wetting"
            :name="'wetting'"
            :error="errors.first('wetting')"
            required
            type="number"
            label="Mojamiento (L)"
            placeholder="Mojamiento (L)"
            data-vv-as="Mojamiento (L)"/>
        </div>
        <div class="col-md-3 text-left">
          <fg-input
            :error="errors.first('assignee')"
            class="has-label">
            <label>Responsable <span>*</span></label>
            <el-select
              v-validate="{required: true}"
              v-model="model.workflow.assignee.$oid"
              :disabled="model.workflow.state == 'reporting'"
              name="assignee"
              placeholder="Responsable"
              data-vv-as="Responsable"
              class="select-primary"
              filterable>
              <el-option
                v-for="(user,key) in users"
                :value="user._id.$oid"
                :label="user.full_name"
                :key="key"
                class="select-danger"/>
            </el-select>
          </fg-input>
        </div>

        <div class="col-md-12 col-sm-12 text-left">
          <div class="form-group has-label">
            <label>Cuartel(es) <span>*</span> <a
              class="inline-control ps-3"
              @click="selectAllSectors()">Todos</a> | <a
                class="inline-control"
                @click="clearSectorSelection()">Limpiar</a></label>
          </div>
          <div class="form-group">
            <fg-input
              :error="errors.first('sector')">
              <el-select
                v-validate="{required: true}"
                v-model="model.sector_ids"
                data-vv-as="Cuartel"
                name="sector"
                multiple=""
                class="select-primary"
                placeholder="Cuartel"
                filterable>
                <el-option
                  v-for="(sector,key) in sectors"
                  :value="sector._id.$oid"
                  :label="sector.name"
                  :key="key"
                  class="select-danger"/>
              </el-select>
            </fg-input>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-left mt-3">
          <h5>Detalle de productos</h5>
        </div>

        <div class="col-md-12 col-sm-12 text-left">
          <div
            v-for="(used_product,key) in model.used_products"
            :key="key"
            class="row">

            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <fg-input
                  :error="errors.first('product_category'+key)">
                  <el-select
                    v-validate="{required: true}"
                    v-model="used_product.category_id"
                    :name="'product_category'+key"
                    filterable
                    class="select-primary"
                    placeholder="Categoría"
                    data-vv-as="Categoría"
                    @change="cleanProductSelects(used_product)">
                    <el-option
                      v-for="(category,key) in ProductsData.productsSchema"
                      :value="key"
                      :label="category.name"
                      :key="key"
                      class="select-danger"/>
                  </el-select>
                </fg-input>
              </div>
            </div>

            <div
              class="col-md-4 col-sm-12">
              <div class="form-group">
                <fg-input
                  :error="errors.first('product'+key)"
                >
                  <el-select
                    v-validate="{required: true}"
                    v-model="used_product.product_id.$oid"
                    :name="'product'+key"
                    :disabled="used_product.category_id == ''"
                    filterable
                    class="select-primary"
                    placeholder="Producto"
                    data-vv-as="Producto"
                  >
                    <el-option
                      v-for="(product,key) in products.filter(i => i.category == used_product.category_id)"
                      :value="product._id.$oid"
                      :label="product.name"
                      :key="'product_id'+key"
                      class="select-danger" />
                  </el-select>
                </fg-input>
              </div>
            </div>

            <div
              class="col-md-2 col-sm-12">
              <fg-input
                v-validate="{ required: true, min_value: 0, decimal: true }"
                v-model.number="used_product.input_dose"
                :name="'dose'+key"
                :error="errors.first('dose'+key)"
                :disabled="used_product.product_id.$oid == ''"
                type="number"
                placeholder="Dosis"
                data-vv-as="Dosis"/>

            </div>
            <div
              class="col-md-3 col-sm-12">
              <div class="form-group">
                <p
                  v-if="used_product.input_dose != ''"
                  class="form-control-static input-dose">
                  {{ getDose(used_product) }}
                </p>
                <p
                  v-else
                  class="form-control-static input-dose">
                  -
                </p>
              </div>
            </div>

            <div
              v-if="key + 1 == model.used_products.length"
              class="col-md-12 text-right">
              <n-button
                v-if="key > 0"
                simple=""
                size="sm"
                type="danger"
                @click.native="rmUsedProduct">
                <i class="fas fa-minus-circle" />
              </n-button>

              <n-button
                v-if="key + 1 > 0"
                simple=""
                size="sm"
                type="primary"
                @click.native="addUsedProduct">
                <i class="fas fa-plus-circle" />
              </n-button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-left mt-3">
          <h5>Datos adicionales</h5>
        </div>
        <div class="col-md-6 col-sm-12 text-left">
          <fg-input
            v-validate="{ min_value: 0.1, decimal: true }"
            :error="errors.first('pH')"
            v-model.number="model.ph"
            name="pH"
            type="number"
            min="0"
            step="0.1"
            label="pH de la Solución"
            placeholder="pH de la Solución"
            data-vv-as="pH de la Solución"/>
        </div>
        <div class="col-md-6 col-sm-12 text-left">
          <fg-input
            v-validate="{ min_value: 0.1, decimal: true }"
            :error="errors.first('CE')"
            v-model.number="model.ce"
            name="CE"
            type="number"
            min="0"
            step="0.1"
            label="CE de la Solución (mmhos/cm)"
            placeholder="CE de la Solución"
            data-vv-as="CE de la Solución"/>
        </div>
        <div class="col-md-12 col-sm-12 text-left">
          <fg-input
            v-model="model.comments"
            type="text"
            label="Comentarios"/>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <slot name="actions"/>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <notification
            :messages="messages"
            :close="true"
            type="error"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ProductsData from "@/defines/Products";
import NP from "number-precision";

export default {
  props: {
    runValidations: {
      type: Number,
      default: 0,
    },
    messages: {
      type: Object,
      default: () => {
        return {};
      },
    },
    initModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loader: true,
      ProductsData: ProductsData,
      products: [],
      sectors: [],
      settings: {},
      users: [],
      model: {
        farm_id: { $oid: "" },
        date: "",
        wetting: null,
        sector_ids: [],
        used_products: [
          {
            category_id: "",
            product_id: { $oid: "" },
            input_dose: "",
            dose: "",
            use: "",
          },
        ],
        comments: "",
        workflow: {
          state: "new",
          assignee: {
            $oid: "",
          },
        },
      },
      valid_doses: {
        cc: "cc/L",
        L: "cc/L",
        kg: "g/L",
      },
      types: [
        "Aplicación vía riego",
        "Aplicación foliar",
        "Aplicación granular",
      ],
    };
  },
  watch: {
    "model.date": function (newValue, oldValue) {
      this.$emit("dateUpdated", newValue);
    },
    runValidations: function (newValue, oldValue) {
      if (newValue) this.validateForm();
    },
  },
  created() {
    if (Object.keys(this.initModel).length > 0) {
      this.model = this.$COPY.copy(this.initModel);
    }
    this.getProducts();
  },
  methods: {
    validateForm() {
      let vm = this;
      this.$validator.validateAll().then((isValid) => {
        vm.$emit("isValid", isValid, vm.model);
      });
    },
    addUsedProduct() {
      this.model.used_products.push({
        product_id: { $oid: "" },
        input_dose: "",
        dose_unit: "",
      });
    },
    rmUsedProduct() {
      this.model.used_products.pop();
    },
    getProducts() {
      let vm = this;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Products.getProducts(farm_id)
        .then((response) => {
          vm.products = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.getSectors();
          vm.cleanArchivedProducts();
        });
    },
    getSectors() {
      let vm = this;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Sectors.getSectors(farm_id)
        .then((response) => {
          vm.sectors = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.getSettings();
        });
    },
    getSettings() {
      this.settings = this.$store.getters["Settings/GET_SETTINGS"];
      this.getUsers();
    },
    getUsers() {
      let vm = this;
      this.Services.Users.getUsers()
        .then((response) => {
          vm.users = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    getDose(used_product) {
      let use = 0;
      let product;
      let measure_unit;
      let input_dose_unit;
      let base_dose = 0;

      product = this.products.find(
        (i) => i._id.$oid == used_product.product_id.$oid
      );

      if (product == undefined) {
        product = { measure_unit: "L" };
      }

      measure_unit = product.measure_unit;
      input_dose_unit = this.$options.filters.dosesFormatter(
        this.valid_doses[product.measure_unit],
        this.settings.general.doses_unit.value
      );

      if (measure_unit == "kg" || measure_unit == "L") {
        base_dose =
          used_product.input_dose /
          1000 /
          this.settings.general.doses_unit.value;
      } else {
        base_dose =
          used_product.input_dose / this.settings.general.doses_unit.value;
      }

      base_dose = NP.strip(base_dose);
      use = NP.strip(base_dose * this.model.wetting);

      used_product.use = use;
      used_product.dose = base_dose;
      used_product.input_dose_unit = input_dose_unit;
      used_product.product_measure_unit = measure_unit;

      return (
        input_dose_unit +
        " = " +
        this.$options.filters.toLocaleStringNumber(use, "decimal") +
        " " +
        measure_unit
      );
    },
    cleanProductSelects(used_product) {
      used_product.input_dose = "";
      used_product.product_id.$oid = "";
    },
    selectAllSectors() {
      this.model.sector_ids = this.sectors.map((i) => i._id.$oid);
    },
    clearSectorSelection() {
      this.model.sector_ids = [];
    },
    cleanArchivedProducts() {
      let vm = this;
      this.model.used_products.forEach((item, index) => {
        let product = vm.products.find(
          (i) => i._id.$oid == item.product_id.$oid
        );

        if (product == undefined) {
          vm.model.used_products[index].product_id.$oid = undefined;
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.inline-control
  cursor: pointer
</style>
