<template lang="html">
  <record-details
    v-loading="Object.keys(model).length == 0"
    :model="model">

    <div slot="actions">
      <process-controls
        v-if="Object.keys(model).length > 0"
        :record="model"
        @action="submit"/>
    </div>
  </record-details>
</template>

<script>
import RecordDetails from "@/views/Labors/PlagueMonitorings/Components/PlagueMonitoringDetails"
import ProcessControls from "@/views/TaskList/Components/ProcessControls"

export default {
    components: {
        RecordDetails,
        ProcessControls
    },
    props: {
        variables: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            farm_id: "",
            messages: {},
            model: {}
        }
    },
    computed: {
        manageControls() {
            return this.$HELPERS.showOptionalControls(
                this.model.workflow.starter.$oid
            )
        }
    },
    created() {
        // this.$emit("setTitle", "Decidir próximas acciones")
        this.$emit("setTitle", "Confirmar lectura")
        this.model = this.$COPY.copy(this.variables)
        this.farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
    },
    methods: {
        submit(decision) {
            this.$emit(
                "completeTask",
                this.$route.params.processKey,
                this.$route.params.id,
                this.model,
                decision
            )
        }
    }
}
</script>

<style lang="css" scoped>
</style>
