<template lang="html">
  <div class="col-md-12 data">
    <div class="row">
      <div class="col-md-12 text-left">
        <h5>Informes del USDA</h5>
      </div>
    </div>

    <div
      v-if="data.length > 0"
      class="row">
      <div class="col-md-12">
        <el-tabs type="border-card">
          <el-tab-pane
            v-for="(report, index) in reportData"
            :key="index"
            :label="getTabPaneName(report)">
            <div
              v-if="report.result.blank_cols.length > 0"
              class="row text-left">
              <div class="col-md-12">
                <h5>Configuración del informe</h5>
                <p>{{ getReportConfigTranslation(report) }}</p>
              </div>
              <div class="col-md-12">
                <h5>Columnas sin datos</h5>
                <p>Las siguientes columnas no contenían datos: {{ report.result.blank_cols.join(", ") }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-left">
                <h5>Resultado del Informe</h5>
              </div>
              <div
                v-if="report.chart.show"
                class="col-md-12 text-left">
                <apex-charts
                  :height="400"
                  :options="report.chart.chartOptions.options"
                  :series="report.chart.chartOptions.series"
                  type="line"/>
              </div>
              <div class="col-md-12 text-right">
                <fg-input
                  v-model="textSearch"
                  :error="errors.first('textSearch')"
                  :clearable="true"
                  data-vv-as="Filtro de texto"
                  name="textSearch"
                  type="text"
                  placeholder="Buscar texto..."
                  class="col-md-6 pull-right"
                  addon-right-icon="fa fa-search"/>
              </div>
              <div class="col-md-12">
                <el-table
                  :data="filteredReportData(report)"
                  size="mini"
                  empty-text="No hay datos disponibles"
                  max-height="500">
                  <template
                    v-for="(value, key, index) in report.result.data[0]">
                    <el-table-column
                      :label="key"
                      :key="index"
                      min-width="180">
                      <div slot-scope="{row}">
                        <span v-if="key === 'Date'">
                          {{ $moment(row[key]).format("DD-MM-YYYY") }}
                        </span>
                        <span v-else>{{ row[key] }}</span>
                      </div>
                    </el-table-column>
                  </template>
                </el-table>
              </div>
              <div
                v-if="report.result.status_code == 503"
                class="col-md-12 text-left mt-3">
                <el-alert
                  :closable="false"
                  :title="report.result.messages"
                  type="error"
                  show-icon/>
              </div>
              <div
                v-else
                class="col-md-12 text-left mt-3">
                <el-alert
                  :closable="false"
                  title="Disclaimer: Los datos han sido extraídos directamente desde la página del USDA sin efectuar modificaciones"
                  type="warning"
                  show-icon/>
              </div>
              <div
                v-if="report.messages.length > 0"
                class="col-md-12 text-left">
                <el-alert
                  v-for="(message, messageIndex) in report.messages"
                  :key="messageIndex"
                  :closable="false"
                  :title="message"
                  type="warning"
                  class="mt-1"
                  show-icon/>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div 
      v-else 
      class="row">
      <div class="col-md-12">
        <p class="text-muted">No se ha solicitado ni un informe aún.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Tabs, TabPane, Alert } from "element-ui"
import ApexCharts from "vue-apexcharts"
import es from "@/assets/apex-charts/es.json"
import UsdaService from "./UsdaService"

export default {
    components: {
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
        [Alert.name]: Alert,
        ApexCharts
    },
    props: {
        data: {
            type: Array,
            default: () => {
                return []
            }
        },
        options: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            reportOptions: null,
            textSearch: null,
            reportData: [],
            chartSchema: {
                options: {
                    chart: {
                        locales: [es],
                        defaultLocale: "es"
                    },
                    title: {
                        text: ""
                    },
                    xaxis: {
                        title: {
                            text: "Fecha/Hora"
                        },
                        type: "datetime",
                        categories: [],
                        labels: {
                            datetimeFormatter: {
                                year: "yyyy",
                                month: "MMM 'yy",
                                day: "dd MMM",
                                hour: "HH:mm"
                            }
                        }
                    },
                    yaxis: {
                        title: {
                            text: ""
                        },
                        labels: {
                            formatter: value => {
                                return value.toFixed(2)
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false,
                        formatter: function(value) {
                            return value
                        }
                    },
                    tooltip: {
                        x: {
                            format: "dd MMM yyyy"
                        }
                    }
                },
                series: []
            }
        }
    },
    created() {
        if (Object.keys(this.options).length == 0) {
            this.getReportOptions()
        } else {
            this.reportOptions = { ...this.options }
        }

        let dataCopy = this.$COPY.copy(this.data)
        dataCopy.forEach(i => {
            if (i.result.status_code == 200) {
                i["chart"] = this.buildChart(i)
            } else {
                i["chart"] = { show: false }
            }
        })
        this.reportData = this.$COPY.copy(dataCopy)
    },
    methods: {
        getReportOptions() {
            let vm = this
            let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid
            this.Services.Reports.getReportOptions(farm_id, "usda")
                .then(response => {
                    vm.reportOptions = response.data.options
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getTabPaneName(report) {
            const selectedKeys = ["type", "commAbr", "repType"]
            let filteredReportConfig = { ...report.query }
            for (let prop in filteredReportConfig) {
                if (!selectedKeys.includes(prop)) {
                    delete filteredReportConfig[prop]
                }
            }

            return UsdaService.parseUsdaReportConfig(
                this,
                this.reportOptions,
                filteredReportConfig
            )
        },
        getReportConfigTranslation(report) {
            return UsdaService.parseUsdaReportConfig(
                this,
                this.reportOptions,
                report.query
            )
        },
        filteredReportData(report) {
            let vm = this
            let result = report.result.data

            if (this.textSearch) {
                result = report.result.data.filter(i => {
                    return JSON.stringify(Object.values(i))
                        .toLowerCase()
                        .includes(vm.textSearch)
                })
            }

            return result
        },
        buildChart(report) {
            let vm = this
            const priceKeys = [
                "Low Price",
                "High Price",
                "Mostly Low",
                "Mostly High"
            ]
            const targetKeys = Object.keys(report.result.data[0]).filter(i =>
                priceKeys.includes(i)
            )

            let aggregateData = report.result.data.reduce((acc, current) => {
                const index = acc.findIndex(item => item.Date === current.Date)
                if (index == -1) {
                    let record = { ...current }
                    let push = false
                    targetKeys.forEach(key => {
                        if (record[key] != undefined) {
                            record[key + "_sum"] = parseFloat(record[key])
                            record[key + "_length"] = 1
                            push = true
                        }
                    })
                    if (push) acc.push(record)
                    return acc
                } else {
                    targetKeys.forEach(key => {
                        if (current[key] != undefined) {
                            acc[index][key + "_sum"] += parseFloat(current[key])
                            acc[index][key + "_length"] += 1
                            acc[index][key] =
                                acc[index][key + "_sum"] /
                                acc[index][key + "_length"]
                        }
                    })
                    return acc
                }
            }, [])

            aggregateData = vm.$HELPERS.sortByDate(
                aggregateData,
                "Date",
                "MM/DD/YYYY",
                false
            )

            let chartOptions = { ...this.chartSchema }
            chartOptions.options.title.text = "Precios agregados por día"

            chartOptions.options.xaxis.categories = aggregateData.map(
                i => i.Date
            )

            chartOptions.options.yaxis.title.text = "Precio"
            targetKeys.forEach(key => {
                chartOptions.series.push({
                    name: key,
                    type: "line",
                    data: aggregateData.map(i => i[key])
                })
            })

            return {
                keys: targetKeys,
                show: targetKeys.length > 0,
                chartOptions: chartOptions
            }
        }
    }
}
</script>

<style lang="sass" scoped>
    .data
        min-height: 100px
</style>
