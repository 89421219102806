import { render, staticRenderFns } from "./PlagueMonitoringGroups.vue?vue&type=template&id=21d26229&scoped=true&lang=html&"
import script from "./PlagueMonitoringGroups.vue?vue&type=script&lang=js&"
export * from "./PlagueMonitoringGroups.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21d26229",
  null
  
)

export default component.exports