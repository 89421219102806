<template>
  <card>
    <template slot="header">
      <card-controls :back="true" back-target-name="reports" />

      <h4 class="card-title">
        <b>Informe <br />Resumen de cosecha</b>
      </h4>
    </template>

    <div class="row">
      <div class="col-lg-10 offset-lg-1">
        <div class="row global-filter">
          <div class="col-md-4 text-left">
            <div class="row">
              <div class="col-12">
                <el-checkbox v-model="payload.restricted_by_dates">
                  Acotar por rango de fechas
                </el-checkbox>
              </div>
            </div>
            <div v-if="payload.restricted_by_dates" class="row">
              <div class="col-12">
                <el-date-picker
                  v-model="payload.date_range"
                  type="daterange"
                  placeholder="Seleccionar fecha"
                  name="date"
                  data-vv-as="Rango de fechas"
                  unlink-panels
                  format="dd-MM-yyyy"
                  start-placeholder="Inicio"
                  range-separator="-"
                  end-placeholder="Término"
                />
              </div>
            </div>
          </div>
          <div class="col-md-8 text-right mt-auto mb-auto">
            <n-button type="primary" size="sm" @click.native="getReport()">
              <i class="fa fa-play me-1" />
              Generar informe
            </n-button>
          </div>
        </div>

        <div class="row" v-loading="loading">
          <div class="col-12" v-if="ready">
            <div class="row subtitle">
              <div class="col-12">Kg totales cosechados</div>
            </div>

            <div class="row mt-6">
              <div class="col-4 text-left">
                <fg-input
                  :error="errors.first('harvestBySectorTimeUnit')"
                  label="Unidad de tiempo"
                  required
                >
                  <el-select
                    v-validate="{ required: true }"
                    v-model="harvestByTimeUnit"
                    data-vv-as="Unidad de tiempo"
                    placeholder="Unidad de tiempo"
                    name="harvestBySectorTimeUnit"
                    class="select-primary"
                  >
                    <el-option
                      value="season"
                      label="Temporada"
                      class="select-danger"
                    />
                    <el-option
                      value="week"
                      label="Semana"
                      class="select-danger"
                    />
                    <el-option
                      value="month"
                      label="Mes"
                      class="select-danger"
                    />
                  </el-select>
                </fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <apex-charts
                  :height="350"
                  :options="harvestByTimeChartData.options"
                  :series="harvestByTimeChartData.series"
                  type="line"
                />
              </div>
            </div>

            <div class="row subtitle">
              <div class="col-12">Kg totales cosechados por cuartel</div>
            </div>
            <div class="row">
              <div class="col-12">
                <apex-charts
                  :height="350"
                  :options="harvestBySectorChartData.options"
                  :series="harvestBySectorChartData.series"
                  type="line"
                />
              </div>
            </div>

            <div class="row subtitle">
              <div class="col-12">Rendimiento por planta</div>
            </div>
            <div class="row">
              <div class="col-12">
                <apex-charts
                  :height="350"
                  :options="yieldBySectorChartData.options"
                  :series="yieldBySectorChartData.series"
                  type="line"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { mapGetters } from "vuex";
import ApexCharts from "vue-apexcharts";
import { range } from "lodash";
import { Checkbox } from "element-ui";

export default {
  components: {
    ApexCharts,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      loading: false,
      ready: false,
      data: [],
      sectors: [],
      seasons: [],
      years: [],
      months: [],
      payload: {
        restricted_by_dates: false,
        date_range: [],
      },
      harvestByTimeUnit: "season",
    };
  },
  computed: {
    ...mapGetters({ farmId: "Farms/GET_FARM_ID" }),
    weeks() {
      return range(1, 54);
    },
    harvestBySectorChartData() {
      const categories = this.sectors.map((i) => i.name);

      const series = [];

      for (const season of this.seasons) {
        const schema = {
          name: `Temporada ${season}`,
          data: [],
        };

        for (const sector of this.sectors) {
          const kg = this.data.reduce((total, i) => {
            if (i.sector_id == sector.id && i.season == season) {
              total += i.harvest;
            }
            return total;
          }, 0);
          schema.data.push(kg);
        }
        series.push(schema);
      }

      let payload = {
        series,
        options: {
          xaxis: {
            title: {
              text: "Cuartel",
            },
            categories,
          },
          yaxis: {
            title: {
              text: "Kg cosechados",
            },
            decimalsInFloat: 2,
          },
        },
      };

      return payload;
    },
    harvestByTimeChartData() {
      const series = [];
      const unit = {
        week: {
          data: this.weeks,
          name: "Semana",
          categories: this.weeks,
        },
        month: {
          data: range(1, 13),
          name: "Mes",
          categories: range(1, 13),
        },
        season: {
          data: this.seasons,
          name: "Temporada",
          categories: this.seasons,
        },
      };
      const selected = unit[this.harvestByTimeUnit];

      if (this.harvestByTimeUnit == "season") {
        const schema = {
          name: "Temporada",
          data: [],
        };
        for (const season of this.seasons) {
          const kg = this.data.reduce((total, item) => {
            if (item.season == season) {
              total += item.harvest;
            }
            return total;
          }, 0);
          schema.data.push(kg);
        }
        series.push(schema);
      } else {
        for (const season of this.seasons) {
          const schema = {
            name: season,
            data: [],
          };
          for (const value of selected.data) {
            const kg = this.data.reduce((total, item) => {
              if (
                item.season == season &&
                item[this.harvestByTimeUnit] == value
              ) {
                total += item.harvest;
              }
              return total;
            }, 0);
            schema.data.push(kg);
          }
          series.push(schema);
        }
      }

      let payload = {
        series,
        options: {
          xaxis: {
            title: {
              text: selected.name,
            },
            categories: selected.categories,
          },
          yaxis: {
            title: {
              text: "Kg cosechados",
            },
            decimalsInFloat: 2,
          },
        },
      };

      return payload;
    },
    yieldBySectorChartData() {
      const series = [];

      for (const season of this.seasons) {
        const schema = {
          name: `Temporada ${season}`,
          data: [],
        };

        for (const sector of this.sectors) {
          const kg = this.data.reduce((total, i) => {
            if (i.sector_id == sector.id && i.season == season) {
              total += i.harvest;
            }
            return total;
          }, 0);
          schema.data.push(kg / sector.num_of_plants);
        }
        series.push(schema);
      }

      let payload = {
        series,
        options: {
          xaxis: {
            title: {
              text: "Cuartel",
            },
            categories: this.sectors.map((i) => i.name),
          },
          yaxis: {
            title: {
              text: "Rendimiento por planta (kg)",
            },
            decimalsInFloat: 2,
          },
          // dataLabels: {
          //   enabled: true,
          //   formatter: function (val) {
          //     return val.toFixed(2);
          //   },
          // },
        },
      };

      return payload;
    },
  },
  methods: {
    async getReport() {
      try {
        this.loading = true;
        const r = await this.Services.Reports.getReport(
          this.farmId,
          "farm_harvest_summary",
          this.payload
        );
        const { data, sectors, seasons, years, months } = r.data;
        this.data = data;
        this.sectors = sectors;
        this.seasons = seasons;
        this.years = years;
        this.months = months;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.ready = true;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.subtitle
  margin-top: 30px
  font-size: 14px
  font-weight: 900
  text-align: center
</style>
