<template lang="html">
  <div>
    <div class="row">
      <div class="col-md-6 col-sm-4 text-left">
        <fg-input
          v-validate="{required: true}"
          v-model="model.profile.name"
          :error="errors.first('name')"
          data-vv-as="Nombre"
          name="name"
          type="text"
          label="Nombre"/>
      </div>
      <div class="col-md-6 col-sm-4 text-left">
        <fg-input
          v-validate="{required: true}"
          v-model="model.profile.lastname"
          :error="errors.first('lastname')"
          data-vv-as="Apellido"
          name="lastname"
          type="text"
          label="Apellido"/>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-8 text-left">
        <fg-input
          v-validate="{required: true, email: true}"
          v-model="model.profile.email"
          :error="errors.first('email')"
          data-vv-as="E-mail"
          name="email"
          type="text"
          label="E-mail"/>
      </div>
    </div>

  </div>

</template>

<script>
export default {
    props: {
        userProfile: {
            type: Object,
            default: () => {
                return {}
            }
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            model: {
                profile: {
                    id: "",
                    name: "",
                    lastname: "",
                    email: ""
                }
            }
        }
    },
    watch: {
        "model.profile": {
            handler: function() {
                if (!this.editMode) {
                    this.model.profile.id = this.model.profile.email
                        .split("@")[0]
                        .split(".")
                        .join("")
                }
                this.$emit("userProfileChanged", this.model.profile)
            },
            deep: true
        }
    },
    created() {
        if (Object.keys(this.userProfile).length > 0) {
            this.model.profile = this.$COPY.copy(this.userProfile)
        }
    }
}
</script>

<style lang="css" scoped>
</style>
