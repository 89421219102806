import { ceres_api } from "@/axios-config.js"

const WrapMaterials = {
    createWrapMaterial(farm_id, model) {
        let endpoint = "/farms/" + farm_id + "/wrap_materials"
        return ceres_api.post(endpoint, model)
    },
    getWrapMaterials(farm_id, filter) {
        let endpoint = "/farms/" + farm_id + "/wrap_materials"
        if (filter == undefined) {
            return ceres_api.get(endpoint)
        } else {
            return ceres_api.post(endpoint + "/filter", { filter: filter })
        }
    },
    getWrapMaterial(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/wrap_materials/" + id
        return ceres_api.get(endpoint)
    },
    updateWrapMaterial(farm_id, model) {
        let endpoint = "/farms/" + farm_id + "/wrap_materials/" + model._id.$oid
        return ceres_api.put(endpoint, model)
    },
    deleteWrapMaterial(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/wrap_materials/" + id
        return ceres_api.delete(endpoint)
    }
}

export default WrapMaterials
