<template lang="html">
  <card v-loading="loader">
    <div slot="header">
      <h4 class="card-title"><b>Mi Perfil</b></h4>
    </div>

    <div class="row">
      <div class="offset-md-2 col-md-4 col-sm-4 text-left">
        <fg-input
          v-validate="{ required: true }"
          v-model="model.user.name"
          :error="errors.first('firstName')"
          data-vv-as="Nombre"
          name="firstName"
          type="text"
          label="Nombre"
        />
      </div>
      <div class="col-md-4 col-sm-4 text-left">
        <fg-input
          v-validate="{ required: true }"
          v-model="model.user.lastname"
          :error="errors.first('lastName')"
          data-vv-as="Apellido"
          name="lastName"
          type="text"
          label="Apellido"
        />
      </div>
    </div>

    <div class="row">
      <div class="offset-md-2 col-md-8 col-sm-8 text-left">
        <fg-input
          v-validate="{ required: true, email: true }"
          v-model="model.user.email"
          :error="errors.first('email')"
          data-vv-as="E-mail"
          name="email"
          type="text"
          label="Email"
        />
      </div>
    </div>

    <div class="row action-footer">
      <hr >
      <div class="col-md-12 text-center">
        <n-button
          class="me-3"
          type="primary"
          @click.native="$router.push({ name: 'change-password' })"
        >
          Modificar contraseña
        </n-button>
        <n-button 
          type="primary" 
          @click.native="validate()">
          <i class="fas fa-save me-1" /> Guardar cambios
        </n-button>
      </div>

      <div
        v-if="messages.length > 0"
        class="offset-md-2 col-md-8 col-sm-8 text-left"
      >
        <notification
          v-for="(message, key) in messages"
          :key="key"
          :message="message.message"
          :type="message.type"
          :close="true"
        />
      </div>
    </div>
  </card>
</template>

<script>
export default {
  data() {
    return {
      model: {
        user: {},
        initUser: {},
      },
      loader: true,
      messages: [],
    };
  },
  created() {
    let vm = this;
    let user = this.$COPY.copy(this.$store.getters["Auth/GET_USER"]);

    this.Services.Users.getUser(user._id.$oid)
      .then((response) => {
        this.model.user = response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        vm.loader = false;
      });
  },
  methods: {
    validate() {
      let vm = this;
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          vm.loader = true;
          vm.messages = [];

          let payload = this.$COPY.copy(vm.model.user);
          let id = payload._id.$oid;

          vm.Services.Users.updateUser({ id, payload })
            .then((response) => {
              vm.processMessages(response.data.messages, "success");
            })
            .catch((error) => {
              vm.processMessages(error.response.data.errors, "danger");
            })
            .then(() => {
              vm.loader = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped></style>
