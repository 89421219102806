import { ceres_api } from "@/axios-config.js"

const Inventories = {
    createInventory(farm_id, model) {
        let endpoint = "/farms/" + farm_id + "/inventories"
        return ceres_api.post(endpoint, model)
    },
    getInventories(farm_id, filter) {
        let endpoint = "/farms/" + farm_id + "/inventories"
        if (filter == undefined) {
            return ceres_api.get(endpoint)
        } else {
            return ceres_api.post(endpoint + "/filter", { filter: filter })
        }
    },
    getInventory(farm_id, id) {
        let endpoint = "/farms/" + farm_id + "/inventories/"
        return ceres_api.get(endpoint + id)
    }
}

export default Inventories
