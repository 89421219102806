<template lang="html">
  <tabs
    v-if="!loader"
    :value="activeTab"
    type="primary"
    class="col-md-12">
    <tab-pane label="En negociación">
      <span slot="label">
        En negociación
        <badge
          :type="'default'"
          :content="groupRecords.negotiating.length | toString "/>
      </span>
      <sale-table
        :show-status="true"
        :show-stock-indicator="true"
        :data="groupRecords.negotiating"/>
    </tab-pane>
    <tab-pane label="Ganado">
      <span slot="label">
        Ganado
        <badge
          :type="'default'"
          :content="groupRecords.won.length | toString "/>
      </span>
      <sale-table
        :show-status="true"
        :data="groupRecords.won"/>
    </tab-pane>
    <tab-pane label="Perdidas">
      <span slot="label">
        Perdidas
        <badge
          :type="'default'"
          :content="groupRecords.lost.length | toString "/>
      </span>
      <sale-table
        :show-status="true"
        :data="groupRecords.lost"/>
    </tab-pane>
    <tab-pane label="Todos">
      <span slot="label">
        Todas
        <badge
          :type="'default'"
          :content="records.length | toString "/>
      </span>
      <sale-table
        :show-status="true"
        :data="records"/>
    </tab-pane>
  </tabs>
</template>

<script>
import { Tabs, TabPane } from "nowui/components"
import SaleTable from "./SaleTable"

export default {
    components: {
        Tabs,
        TabPane,
        SaleTable
    },
    props: {
        records: {
            type: Array,
            default: () => {
                return []
            }
        },
        showAllTab: {
            type: Boolean,
            default: false
        },
        loader: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        activeTab() {
            return this.showAllTab ? "Todos" : ""
        },
        groupRecords() {
            return {
                negotiating: this.records.filter(
                    i => i.workflow.state == "negotiating"
                ),
                won: this.records.filter(i => i.workflow.state == "won"),
                lost: this.records.filter(i => i.workflow.state == "lost")
            }
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
