import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VeeValidate from "vee-validate";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueMeta from "vue-meta";
import globalComponents from "./globalComponents";
import defines from "./defines";
import * as VueGoogleMaps from "vue2-google-maps";
import EJSON from "mongodb-extjson";
import CopyObject from "./utils/CopyObject";
import FindWithAttr from "./utils/FindWithAttr";
import Helpers from "./utils/Helpers";
import { version } from "../package.json";
import "./filters/Filters.js";
import DataTransformation from "./utils/DataTransformation";
import "./polyfills";
import Services from "./services";
import mixin from "./mixins/mixin";
import { detect } from "detect-browser";
import i18n from "./i18n";

// Global css imports
import "bootstrap/scss/bootstrap.scss";
import "element-ui/lib/theme-chalk/index.css";
import "sweetalert2/dist/sweetalert2.css";
import "./assets/sass/ceres.sass";
import "./assets/sass/landing.sass";
// import "@fortawesome/fontawesome-pro/css/all.css";
import "../vendors/fontawesome-pro-5.15.3-web/css/all.css";
import "./assets/sass/element_variables.scss";

// Set app version
store.commit("SET_VERSION", version);

// Set EJSON as a property for every Vue Component
Object.defineProperty(Vue.prototype, "$EJSON", {
  value: EJSON,
});
// Set CopyObject Util
Object.defineProperty(Vue.prototype, "$COPY", {
  value: CopyObject,
});
Object.defineProperty(Vue.prototype, "$FIND", {
  value: FindWithAttr,
});
Object.defineProperty(Vue.prototype, "$HELPERS", {
  value: Helpers,
});

// Set Services to use ceres_api
Object.defineProperty(Vue.prototype, "Services", {
  value: Services,
});

// Google map component configure
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB2IbrsIYD9VTBorhzgAef9RpEI8mJ-lvg",
    libraries: "places",
  },
});

// Set Camunda Data Transformation
Object.defineProperty(Vue.prototype, "$CAMUNDA", {
  value: DataTransformation,
});

Vue.config.productionTip = false;
Vue.use(globalComponents);
Vue.use(defines);

// Set global mixin
Vue.mixin(mixin);

// Vee Validate Config
const VueValidationEs = require("vee-validate/dist/locale/es");
Vue.use(VeeValidate, {
  locale: "es",
  dictionary: {
    es: VueValidationEs,
  },
});

// Prismic
import PrismicVue from "prismic-vue";
import linkResolver from "./utils/link-resolver";

const accessToken = process.env.VUE_APP_CMS_SECRET;

Vue.use(PrismicVue, {
  endpoint: "https://ceres.cdn.prismic.io/api/v2",
  linkResolver,
  apiOptions: {
    accessToken,
  },
});

// Recaptcha
Vue.use(VueReCaptcha, { siteKey: "6LdoTcUUAAAAANSWH_tcPCm7BsZJBdzZ6vPdy5_9" });

// Metatags
Vue.use(VueMeta);

/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
Number.prototype.format = function (n, x, s, c) {
  var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
    num = this.toFixed(Math.max(0, ~~n));

  return (c ? num.replace(".", c) : num).replace(
    new RegExp(re, "g"),
    "$&" + (s || ",")
  );
};

// Deep clone objects and arrays
const clone = require("rfdc")();
Vue.prototype.$clone = clone;

// Detect browser
const browser = detect();
store.commit("SET_BROWSER", browser.name);

// Vue router before hook
router.beforeEach((to, from, next) => {
  document.title = to.meta.title + " | " + process.env.VUE_APP_NAME;

  let user = store.getters["Auth/GET_USER"];

  if (
    store.getters["Settings/GET_SETTINGS"] == undefined &&
    !["login", "landing"].includes(to.name)
  ) {
    Services.Settings.getSettings()
      .then((response) => {
        store.commit(
          "Settings/SET_SETTINGS",
          Helpers.compressSettings(response.data)
        );
      })
      .catch((error) => {
        console.log(error);
        next({
          name: "login",
        });
      });
  }

  if (to.meta.roles != undefined) {
    let havePermission = to.meta.roles.some((i) =>
      user.roles.map((r) => r.role_id).includes(i)
    );
    if (havePermission) {
      next();
    } else {
      next({
        name: "tasklist",
        params: { view: "list-view" },
      });
    }
  } else {
    next();
  }
});

// Vue app init
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
