var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-10 offset-md-1"},[(Object.keys(_vm.model).length > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"info"},[_vm._m(0),_c('h5',{staticClass:"info-title"},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.model.date,"DD-MM-YYYY")))])]),_c('h6',{staticClass:"stats-title"},[_vm._v("Fecha")])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"info"},[_vm._m(1),_c('h5',{staticClass:"info-title"},[_c('span',[_vm._v(_vm._s(_vm.model.sector.name))])]),_c('h6',{staticClass:"stats-title"},[_vm._v("Cuartel")])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"info"},[_vm._m(2),_c('h5',{staticClass:"info-title"},[_c('span',[_vm._v(_vm._s(_vm.model.harvest_unit)+" de "+_vm._s(_vm._f("toLocaleStringNumber")(parseFloat(_vm.model.harvest_unit_weight),"decimal"))+" "+_vm._s(_vm.model.harvest_unit_weight_unit))])]),_c('h6',{staticClass:"stats-title"},[_vm._v("Unidad de Cosecha")])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"info"},[_vm._m(3),_c('h5',{staticClass:"info-title"},[_c('span',[_vm._v(_vm._s(_vm._f("toLocaleStringNumber")(_vm.model.total_harvest)))])]),_c('h6',{staticClass:"stats-title"},[_vm._v("Kg Cosechados Approx.")])])])])]):_vm._e(),_c('hr'),_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-md-12 col-sm-12 pull-center desktop-table"},[_c('el-table',{staticClass:"table-striped",attrs:{"data":_vm.model.harvest_workers,"empty-text":'No hay cosecheros asociados'}},[_c('el-table-column',{attrs:{"label":"Cosecher@","align":"left","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(row.worker.full_name)+" ")])}}])}),_c('el-table-column',{attrs:{"formatter":_vm.boxesFormatter,"prop":"boxes","label":"Unidades Cosechadas","header-align":"center","align":"right","class-name":"table-category"}}),_c('el-table-column',{attrs:{"label":"Kg Cosechados Approx.","header-align":"center","align":"right","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("toLocaleStringNumber")((row.boxes * _vm.model.harvest_unit_weight),"decimal"))+" ")])}}])}),_c('el-table-column',{attrs:{"label":"Pago por Unidad","header-align":"center","align":"right","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("toLocaleStringNumber")(_vm.model.cost_per_unit,"decimal"))+" ")])}}])}),_c('el-table-column',{attrs:{"label":"Pago Cosechero","header-align":"center","align":"right","class-name":"table-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("toLocaleStringNumber")((_vm.model.cost_per_unit * row.boxes),"decimal"))+" ")])}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('cost-summary',{attrs:{"record":_vm.model}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_vm._t("actions")],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon icon-info"},[_c('i',{staticClass:"far fa-calendar-alt"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon icon-info"},[_c('i',{staticClass:"fal fa-map-marked"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon icon-success"},[_c('i',{staticClass:"far fa-box-open"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon icon-success"},[_c('i',{staticClass:"far fa-weight-hanging"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 text-left"},[_c('h5',[_vm._v("Detalle por cosecher@")])])}]

export { render, staticRenderFns }