import { ceres_api } from "@/axios-config.js"

const Farms = {
    createFarm(payload) {
        return ceres_api.post("/farms", payload)
    },
    getFarms() {
        return ceres_api.get("/farms")
    },
    getFarm(id) {
        return ceres_api.get("/farms/" + id)
    },
    updateFarm({ id, payload }) {
        return ceres_api.put("/farms/" + id, payload)
    }
}

export default Farms
