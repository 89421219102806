<template lang="html">
  <modal
    :show.sync="modal"
    class="processOwnerModal"
    header-classes="justify-content-center"
    @close="$emit('close')"
  >
    <h4 slot="header" class="title title-up">Cambiar Responsable</h4>

    <div v-loading="loader" class="col-md-12">
      <div class="row">
        <div class="col-md-12 text-left">
          <fg-input
            :error="errors.first('newAssignee')"
            label="Nuevo responsable"
            required
          >
            <el-select
              v-validate="{ required: true }"
              v-if="dependencies.userList"
              v-model="newAssignee"
              data-vv-as="Nuevo responsable"
              placeholder="Seleccione un usuario"
              class="select-primary"
              name="newAssignee"
              filterable
            >
              <template v-if="dependencies.userList">
                <el-option
                  v-for="user in userList.filter((i) => i._id.$oid != assignee)"
                  :value="user._id.$oid"
                  :label="showUserName(user)"
                  :key="user._id.$oid"
                  class="select-danger"
                />
              </template>
            </el-select>
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div v-if="newAssignee != ''" class="col-md-12 text-center">
          Cambiar de responsable <b>{{ showChange.initialAssignee }}</b> a
          <b>{{ showChange.newAssignee }}</b>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <n-button type="primary" @click.native="validate()">
            Guardar
          </n-button>
        </div>
      </div>
    </div>

    <!-- <div
      v-if="messages.length > 0"
      class="col-md-12 text-left">
      <notification
        v-for="(message,key) in messages"
        :key="key"
        :message="message.message"
        :type="message.type"
        :close="true"/>
    </div> -->
    <div class="row mt-3">
      <div class="col-md-12">
        <notification :messages="messages" :close="true" type="error" />
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "nowui/components/Modal";
import Swal from "sweetalert2";
export default {
  components: {
    Modal,
  },
  props: {
    users: {
      type: Array,
      default: () => {
        return [];
      },
    },
    taskId: {
      type: String,
      default: "",
    },
    assignee: {
      type: String,
      default: "",
    },
    processKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loader: true,
      modal: true,
      newAssignee: "",
      userList: [],
      dependencies: {
        userList: false,
      },
      messages: {},
    };
  },
  computed: {
    showChange() {
      let initialAssignee = this.userList.find(
        (i) => i._id.$oid == this.assignee
      );
      let newAssignee = this.userList.find(
        (i) => i._id.$oid == this.newAssignee
      );
      return {
        initialAssignee: this.showUserName(initialAssignee),
        newAssignee: this.showUserName(newAssignee),
      };
    },
  },
  created() {
    if (this.users.length > 0) {
      this.userList = this.users;
      this.dependencies.userList = true;
      this.loader = false;
    } else {
      this.getUsers();
    }
  },
  methods: {
    getUsers() {
      let vm = this;
      vm.dependencies.userList = false;
      this.Services.Users.getUsers()
        .then((response) => {
          vm.userList = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.dependencies.userList = true;
          vm.loader = false;
        });
    },
    showUserName(user) {
      try {
        return user.full_name;
      } catch (e) {
        return user;
      }
    },
    validate() {
      let vm = this;
      this.$validator.validateAll().then((isValid) => {
        if (isValid) vm.update();
      });
    },
    update() {
      let vm = this;
      vm.loader = true;
      vm.messages = {};
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      this.Services.Processes.setAssignee(
        farm_id,
        this.processKey,
        this.taskId,
        this.newAssignee
      )
        .then((response) => {
          Swal.fire({
            title: response.data.messages,
            icon: "success",
          }).then(() => {
            vm.$emit("updateTaskList");
            vm.$emit("close");
          });
        })
        .catch((error) => {
          console.log(error);
          vm.messages = error.response.data;
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
};
</script>

<style lang="sass">
.processOwnerModal
  .modal-dialog
    max-width: 500px !important
</style>
