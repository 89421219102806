<template lang="html">
  <el-table
    :data="tableData"
    :row-class-name="getRowClassName"
    empty-text="No hay registros para construir el flujo de caja"
    sum-text="Total">

    <el-table-column
      label="Ítem"
      min-width="150"
      fixed>
      <div slot-scope="{row}">
        <template v-if="row.category == 'totals' & summary">
          {{ $t(row.item) }} {{ $t(row.variable) }}
        </template>
        <template v-else-if="row.category == 'totals' & !summary">
          Total
        </template>
        <template v-else>
          {{ $t(row.item) }}
        </template>
      </div>
    </el-table-column>

    <template v-if="data.length > 0">
      <el-table-column
        v-for="(key, index) in Object.keys(data[0]).filter(i => /^\d/.test(i))"
        :key="key+'_'+index"
        :label="getLabel(key)"
        :prop="key"
        min-width="130"
        header-align="center">
        <div slot-scope="{row}">
          <span>$</span> <span class="float-right">{{ row[key] | toLocaleStringNumber }}</span>
        </div>
      </el-table-column>
    </template>
  </el-table>
</template>

<script>
import { Table, TableColumn } from "element-ui"

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    props: {
        data: {
            type: Array,
            default: () => {
                return []
            }
        },
        summaryRow: {
            type: Array,
            default: () => {
                return []
            }
        },
        summary: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tableData: []
        }
    },
    created() {
        if (this.summaryRow.length > 0) {
            this.tableData = this.$COPY
                .copy(this.data)
                .concat(this.$COPY.copy(this.summaryRow))
        } else {
            this.tableData = this.$COPY.copy(this.data)
        }
    },
    methods: {
        getLabel(value) {
            return this.$moment(value).format("MMM YYYY")
        },
        getRowClassName({ row }) {
            if (this.summary) {
                if (
                    (row.category == "totals") & (row.variable == "total") ||
                    row.category == "balance"
                ) {
                    return "summary-row"
                }
            } else {
                if (row.category == "totals") {
                    return "summary-row"
                }
                return ""
            }
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
