<template>
  <div class="d-flex flex-column">
    <div class="setting-section align-self-start">
      <i :class="sectionIcon" /> {{ sectionTitle }} (<span
        v-if="textSearch.length > 0"
        >{{ filteredContacts.length }} de </span
      >{{ contacts.length }})
    </div>
    <div v-if="hasContacts" class="d-flex align-items-baseline mt-3">
      <div class="flex-fill">
        <fg-input
          v-model="textSearch"
          type="text"
          addon-left-icon="fal fa-search"
          placeholder="Filtrar contactos"
        />
      </div>
      <div class="ms-3 text-right">
        <n-button simple type="primary" @click.native="downloadContacts">
          <i
            :class="
              downloading
                ? 'fas fa-spinner fa-pulse me-1'
                : 'fal fa-download me-1'
            "
          />
          Descargar
        </n-button>
      </div>
    </div>
    <div v-if="hasContacts">
      <div class="d-flex flex-nowrap mt-3 cards-container">
        <div
          v-for="(contact, index) in filteredContacts"
          :key="index"
          class="col-md-5 col-sm-12 mb-3 me-3"
        >
          <contact-card
            :edit-mode="true"
            :contact="contact"
            @edit="editContact(contact, index)"
            @delete="$emit('deleteContact', schemaKey, index)"
          />
        </div>
      </div>
    </div>
    <div v-else class="mt-3">
      <p class="text-muted">No se han agregado contactos aún</p>
    </div>
    <div v-if="filteredContacts.length == 0 && hasContacts" class="mt-3">
      <p class="text-muted">
        No se ha encontrado ni un contacto que cumpla con los requisitos de
        búsqueda
      </p>
    </div>
    <div
      :class="[
        { 'align-self-end': hasContacts, 'align-self-center': !hasContacts },
      ]"
    >
      <n-button simple="" type="primary" @click.native="openCreateContactForm">
        <i class="fas fa-plus-circle me-1" /> Crear contacto
      </n-button>
    </div>
    <el-dialog :visible.sync="openDialog" title="Crear contacto" width="50%">
      <contact-form
        :contact="currentContact"
        :mode="contactFormMode"
        :new-contact-schema="newContactSchema"
        @updateContact="updateContact"
        @createContact="createContact"
      />
    </el-dialog>
  </div>
</template>

<script>
import { Card, Dialog } from "element-ui";
import ContactForm from "./ContactForm";
import { ContactCard } from "@/Components";

export default {
  components: {
    [Card.name]: Card,
    [Dialog.name]: Dialog,
    ContactForm,
    ContactCard,
  },
  props: {
    contacts: {
      type: Array,
      required: true,
    },
    schemaKey: {
      type: String,
      required: true,
    },
    sectionTitle: {
      type: String,
      required: true,
    },
    sectionIcon: {
      type: String,
      required: true,
    },
    newContactSchema: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      textSearch: "",
      currentContact: null,
      currentIndex: null,
      openDialog: false,
      contactFormMode: "new",
      downloading: false,
    };
  },
  computed: {
    hasContacts() {
      return this.contacts.length > 0 ? true : false;
    },
    filteredContacts() {
      if (this.textSearch == "" || this.textSearch == undefined) {
        return this.contacts;
      }
      const vm = this;
      return this.contacts.filter((contact) => {
        return Object.values(contact.value)
          .join("")
          .toLowerCase()
          .includes(vm.textSearch);
      });
    },
  },
  methods: {
    editContact(contact, index) {
      this.currentContact = this.$clone(contact);
      this.currentIndex = index;
      this.contactFormMode = "edit";
      this.openDialog = true;
    },
    openCreateContactForm() {
      this.currentContact = this.$clone(this.newContactSchema);
      this.currentIndex = null;
      this.contactFormMode = "new";
      this.openDialog = true;
    },
    updateContact(updatedContact) {
      this.$emit("updateContact", this.schemaKey, {
        updatedContact: updatedContact,
        index: this.currentIndex,
      });
      this.openDialog = false;
    },
    createContact(contactData) {
      this.$emit("createContact", this.schemaKey, contactData);
      this.openDialog = false;
    },
    async downloadContacts() {
      this.downloading = true;
      try {
        const response = await this.Services.Utils.downloadContacts(
          this.schemaKey
        );
        this.$HELPERS.downloadFile(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        this.downloading = false;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.cards-container
  overflow-x: auto
.el-dialog__footer
  padding: 0px !important
.fixHeight
  height: 3em
</style>
