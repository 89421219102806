import { render, staticRenderFns } from "./WrapMaterialForm.vue?vue&type=template&id=2a8e91d4&scoped=true&lang=html&"
import script from "./WrapMaterialForm.vue?vue&type=script&lang=js&"
export * from "./WrapMaterialForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a8e91d4",
  null
  
)

export default component.exports