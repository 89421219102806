<template lang="html">
  <div class="">
    <harvest-form
      v-loading="loaders.newHarvest"
      :record="model.new_harvest"
      :messages="messages"
      mode="start"
      @titlePrefix="setTitle"
      @save="save"
    />
  </div>
</template>

<script>
import HarvestForm from "@/views/Labors/Harvests/Components/HarvestForm";
import Swal from "sweetalert2";

export default {
  components: {
    HarvestForm,
  },
  data() {
    return {
      title: "Crear una nueva cosecha",
      messages: {},
      loaders: {
        newHarvest: false,
      },
      model: {
        new_harvest: {
          date: null,
          sector_id: {
            $oid: "",
          },
          harvest_unit: "",
          harvest_unit_weight: "",
          harvest_unit_weight_unit: "kg",
          harvest_workers: [
            {
              worker_id: {
                $oid: "",
              },
              boxes: 0,
            },
          ],
          workflow: {
            starter: "",
            assignee: {
              $oid: "",
            },
          },
        },
      },
      swal_text: "registrada",
    };
  },
  created() {
    this.$emit("setTitle", this.title);
  },
  methods: {
    setTitle(prefix) {
      this.$emit("setTitle", prefix + " una nueva cosecha");
    },
    save(harvest) {
      let vm = this;
      vm.loaders.newHarvest = true;
      let farm_id = this.$store.getters["Farms/GET_FARM"]._id.$oid;
      let new_harvest = vm.$COPY.copy(harvest);
      new_harvest.farm_id = farm_id;
      vm.messages = {};
      this.Services.Harvests.createHarvest(farm_id, new_harvest)
        .then((response) => {
          if (response.status == 201) {
            Swal.fire({
              title: response.data.messages,
              icon: "success",
            }).then(() => {
              vm.$router.push({ name: "harvests" });
            });
          }
        })
        .catch((error) => {
          console.log(error);
          if (typeof error.response.data != "string") {
            vm.messages = error.response.data;
          }
        })
        .finally(() => {
          vm.loaders.newHarvest = false;
        });
    },
  },
};
</script>

<style lang="css"></style>
