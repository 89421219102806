import CeresVariables from "@/defines/CeresVariables";

const Auth = {
  namespaced: true,
  state: {
    currentUser: undefined,
    isAdmin: undefined,
    isSupervisor: undefined,
  },
  mutations: {
    SET_USER(state, value) {
      state.currentUser = value;
      state.isAdmin = value.roles.some((i) => i.role_id == "admin");
      state.isSupervisor = value.roles.some((i) =>
        CeresVariables.data.permissions.supervisors.includes(i.role_id)
      );
    },
  },
  getters: {
    GET_USER(state) {
      let user = state.currentUser;
      if (user == undefined) {
        user = JSON.parse(sessionStorage.getItem("user"));
      }
      return user;
    },
    GET_USER_ID(state, getters) {
      return getters.GET_USER._id.$oid;
    },
    IS_ADMIN(state) {
      let user = state.currentUser;
      if (user == undefined) {
        user = JSON.parse(sessionStorage.getItem("user"));
      }
      state.isAdmin = user.roles.some((i) => i.role_id == "admin");
      return state.isAdmin;
    },
    IS_SUPERVISOR(state) {
      let user = state.currentUser;
      if (user == undefined) {
        user = JSON.parse(sessionStorage.getItem("user"));
      }
      state.isSupervisor = user.roles.some((i) =>
        CeresVariables.data.permissions.supervisors.includes(i.role_id)
      );
      return state.isSupervisor;
    },
  },
  actions: {},
};

export default Auth;
