<template lang="html">
  <collapse-transition>
    <div class="global-filter">
      <div class="row">
        <div class="col-md-12 text-left">
          <h6>Filtro Global</h6>
        </div>
        <div class="col-md-4 text-left">
          <div class="form-group">
            <fg-input label="Cuartel">
              <el-select
                v-model="model['sector_harvests.sector_id'].$in"
                name="sectorFilter"
                multiple=""
                class="select-primary"
                placeholder="Cuartel"
                filterable
              >
                <el-option
                  v-for="(sector, key) in sectors"
                  :value="sector._id.$oid"
                  :label="getSectorFullName(sector)"
                  :key="key"
                  class="select-danger"
                />
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="col-md-4 text-left">
          <fg-input :error="errors.first('date')" class="has-label">
            <label>Rango de fechas <span>*</span></label>
          </fg-input>
          <el-date-picker
            v-model="model.date.dateRange"
            :picker-options="dateRangeOptions"
            type="daterange"
            placeholder="Seleccionar fecha"
            name="date"
            data-vv-as="Fecha de la cosecha"
            unlink-panels
            format="dd-MM-yyyy"
            start-placeholder="Inicio"
            range-separator="-"
            end-placeholder="Término"
          />
        </div>
      </div>
      <div class="row controls">
        <div class="col-md-12 text-center">
          <n-button type="danger" size="sm" @click.native="handleCloseFilter()">
            <i class="fal fa-times-circle" />
            Cerrar
          </n-button>
          <n-button type="warning" size="sm" @click.native="clearFilter()">
            <i class="fal fa-trash" />
            Limpiar
          </n-button>
          <n-button type="success" size="sm" @click.native="applyFilter()">
            <i class="fal fa-check-circle" />
            Aplicar
          </n-button>
        </div>
      </div>
    </div>
  </collapse-transition>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";
import { mapState } from "vuex";
import { SectorUtils } from "../../../../mixins";

export default {
  components: {
    CollapseTransition,
  },
  mixins: [SectorUtils],
  props: {
    records: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      schema: {
        "sector_harvests.sector_id": {
          $in: [],
          isObjectID: true,
        },
        date: {
          dateRange: [],
        },
      },
      model: {
        "sector_harvests.sector_id": {
          $in: [],
          isObjectID: true,
        },
        date: {
          dateRange: [],
        },
      },
      dateRangeOptions: {
        disabledDate: this.setDates,
        shortcuts: [
          {
            text: "Semana pasada",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Mes pasado",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Últimos 3 meses",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Temporada actual",
            onClick: this.getCurrentSeason,
          },
          {
            text: "Temporada pasada",
            onClick: this.getLastSeason,
          },
        ],
      },
    };
  },
  computed: {
    getMinMaxDates() {
      return this.$HELPERS.getMinMaxDates(this.records, "date");
    },
    ...mapState("Farms", ["sectors"]),
  },
  methods: {
    setDates(time) {
      if (
        time.getTime() > this.getMinMaxDates.max ||
        time.getTime() < this.getMinMaxDates.min
      ) {
        return true;
      }
    },
    getCurrentSeason(picker) {
      const now = new Date();
      let season = this.$HELPERS.getSeasonDates(now, "current");
      picker.$emit("pick", [season.start, season.end]);
    },
    getLastSeason(picker) {
      const now = new Date();
      let season = this.$HELPERS.getSeasonDates(now, "last");
      picker.$emit("pick", [season.start, season.end]);
    },
    clearFilter() {
      this.model = this.$COPY.copy(this.schema);
      this.$emit("applyFilter");
    },
    handleCloseFilter() {
      this.clearFilter();
      this.$emit("close");
    },
    applyFilter() {
      let query = this.$COPY.copy(this.model);
      query = this.$HELPERS.composeMongoQuery(query);
      this.$emit("applyFilter", query);
    },
  },
};
</script>

<style lang="css" scoped></style>
